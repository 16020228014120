import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint.ts'
import { dataLine } from '../utils.ts'

export const milkAnimalsInventorySummarySheet2024Blueprint = {
  hideReferenceLabel: true,
  sections: [
    {
      id: 'milk-animals.inventory-summary',
      noHeader: true,
      children: [
        dataLine('milk.animals.movement.investment.total', { unit: 'currency', variant: 'subTotal' }),
        dataLine('milk.animals.movement.end-balance.total', { unit: 'currency', variant: 'total' }),
        dataLine('milk.animals.movement.quantity-over-end-balance', {
          unit: 'currency',
          variant: 'subTotal',
        }),
        dataLine('milk.animals.movement.price-over-starting-quantity', {
          unit: 'currency',
          variant: 'subTotal',
        }),
      ],
    },
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true, className: 'w-84' },
    {
      id: 'selected-reference',
      type: 'reference',
      sticky: true,
      className: 'w-[7.5rem]',
      topHeaderClassName: 'w-[7.5rem] h-14 border-t border-t-gray-400',
      withDividerRight: true,
      showUnitInCell: true,
    },
    {
      id: 'divider',
      type: 'divider',
      className: 'w-1',
    },
    {
      id: 'projections',
      type: 'projections',
      withDividerRight: true,
      className: 'w-[7.5rem]',
      topHeaderVariant: 'head',
      topHeaderClassName: 'w-[7.5rem] h-14',
      showUnitInCell: true,
    },
  ],
} as const satisfies Except<BlueprintTable, 'version'>
