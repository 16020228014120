import { useMemo } from 'react'

import { type RxDocument, type RxQuery } from 'rxdb'
import { useRxQuery } from 'rxdb-hooks'

import { rxDocumentData } from './utils/rxDocumentData.ts'
import { type RxCollectionDocumentType, type RxOutput } from './types.ts'

export const useRxDocumentData = <C, T = RxCollectionDocumentType<C>>(
  query: RxQuery<T, RxDocument<T> | null>
): RxOutput<T> | null => {
  const { result } = useRxQuery(query)
  return useMemo(() => {
    const value = result?.[0]
    return value ? (rxDocumentData(value) as RxOutput<T>) : null
  }, [result])
}
