import React from 'react'
import { useController, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { type LoanCategory, PaymentFrequencies } from '@via/schema'
import { max, min, sumBy } from 'lodash-es'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { Select } from '../../atoms/Select/Select'
import { Textarea } from '../../atoms/Textarea/Textarea'
import { useBudget } from '../../context'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { cn } from '../../lib/utils'
import { type MonetaryLoanData } from '../../model'
import { DateInputFormField } from '../../molecules/DateInputFormField/DateInputFormField'
import { DateInputWithLabel } from '../../molecules/DateInputWithLabel/DateInputWithLabel'
import { FormatedNumberReadonlyInputFormField } from '../../molecules/FormatedNumberReadonlyInputFormField/FormatedNumberReadonlyInputFormField'
import { FormatedNumberReadonlyInputWithLabel } from '../../molecules/FormatedNumberReadonlyInputWithLabel/FormatedNumberReadonlyInputWithLabel'
import { InputFormField } from '../../molecules/InputFormField/InputFormField'
import { PercentageInputFormField } from '../../molecules/PercentageInputFormField/PercentageInputFormField'
import {
  SwitchAccordion,
  SwitchAccordionContent,
  SwitchAccordionItem,
  SwitchAccordionTrigger,
} from '../../molecules/SwitchAccordion/SwitchAccordion'

import { LoadImportedValues } from './LoadImportedValues'
import { LoanChangeRateForm } from './LoanChangeRateForm'
import { LoanDurationWithoutCapitalForm } from './LoanDurationWithoutCapitalForm'
import { LoanDurationWithoutInterestForm } from './LoanDurationWithoutInterestForm'
import { LoanEarlyRepayment } from './LoanEarlyRepaymentForm'
import { LoanFixedCapitalPaymentAmountForm } from './LoanFixedCapitalPaymentAmountForm'
import { LoanFormHeader, type LoanFormHeaderProps } from './LoanFormHeader'
import { type LoanDetailFormData, LoanFormProvider, type LoanFormProviderProps } from './LoanFormProvider'
import { LoanInternalValues } from './LoanInternalValues'
import { LoanNewCapitalForm } from './LoanNewCapitalForm'
import { LoanShareholderDueRepaymentForm } from './LoanShareholderDueRepaymentForm'

const getPaymentAmount = (
  loanData: MonetaryLoanData | undefined,
  formatNumber: (value: number, type: 'currency') => string
): string => {
  if (loanData?.paymentPlan?.amount?.baseValue) {
    return formatNumber(loanData.paymentPlan.amount.baseValue, 'currency')
  }

  const computedPaymentAmount = Number(loanData?.computed?.payment)
  if (Number.isFinite(computedPaymentAmount)) {
    return formatNumber(computedPaymentAmount, 'currency')
  }

  return ''
}

const PaymentPlanInputs: React.FC<{ readonly loanData?: MonetaryLoanData }> = ({ loanData }) => {
  const intl = useIntl()
  const { formatNumber } = useFormatNumber()

  return (
    <>
      <PercentageInputFormField<LoanDetailFormData, 'interestRate'>
        name="interestRate"
        placeholder={loanData?.interestRate?.baseValue ? formatNumber(loanData.interestRate.baseValue, 'interest') : ''}
        label={intl.formatMessage({ id: 'loan.form.interestRate' })}
      />
      <InputFormField<LoanDetailFormData, 'paymentPlan.amount'>
        name="paymentPlan.amount"
        type="number"
        placeholder={getPaymentAmount(loanData, formatNumber)}
        label={intl.formatMessage({ id: 'loan.form.paymentPlanAmount' })}
      />
    </>
  )
}

const MainContent: React.FC<{ readonly loanData?: MonetaryLoanData; readonly category: LoanCategory }> = ({
  loanData,
  category,
}) => {
  const intl = useIntl()
  const { referenceYears } = useBudget()
  const [source, enabledOptions, newCapitalBorrowed] = useWatch<
    LoanDetailFormData,
    ['source', 'enabledOptions', 'newCapitalBorrowed']
  >({
    name: ['source', 'enabledOptions', 'newCapitalBorrowed'],
  })
  const { formatNumber } = useFormatNumber()
  const lastReferenceYear = max(referenceYears)

  const isNewLoan = source === 'scenario'
  const isFixedDuration = enabledOptions.includes('fixed-capital')
  const useNewCapital = enabledOptions.includes('new-capital')
  const newCapitalBorrowedTotal = sumBy(Object.values(newCapitalBorrowed), (v) => Number(v.value))
  const newCapitalBorrowedDate = min(Object.values(newCapitalBorrowed).map((v) => v.date))

  return (
    <div className="grid grid-cols-4 items-start gap-4">
      <InputFormField<LoanDetailFormData, 'name'>
        name="name"
        className="col-span-3"
        disabled={!isNewLoan}
        label={intl.formatMessage({ id: 'loan.form.name' })}
      />
      {!isNewLoan ? (
        <FormatedNumberReadonlyInputFormField<LoanDetailFormData, 'amount'>
          name="amount"
          displayType="currency"
          variant="gray"
          label={intl.formatMessage({ id: 'loan.form.amount' })}
        />
      ) : useNewCapital ? (
        <FormatedNumberReadonlyInputWithLabel
          value={newCapitalBorrowedTotal}
          displayType="currency"
          variant="gray"
          label={intl.formatMessage({ id: 'loan.form.new.amount' })}
        />
      ) : (
        <InputFormField<LoanDetailFormData, 'amount'>
          name="amount"
          type="number"
          label={intl.formatMessage({ id: 'loan.form.new.amount' })}
        />
      )}
      {useNewCapital ? (
        <DateInputWithLabel
          value={newCapitalBorrowedDate}
          className="col-start-1"
          disabled
          label={intl.formatMessage({ id: 'loan.form.startDate' })}
        />
      ) : (
        <DateInputFormField<LoanDetailFormData, 'startDate'>
          name="startDate"
          className="col-start-1"
          disabled={!isNewLoan}
          label={intl.formatMessage({ id: 'loan.form.startDate' })}
        />
      )}
      {category === 'shareholders-due' ? null : isFixedDuration ? (
        <FormatedNumberReadonlyInputFormField<LoanDetailFormData, 'computed.duration'>
          name="computed.duration"
          variant="gray"
          label={intl.formatMessage({ id: 'loan.form.duration' })}
        />
      ) : (
        <InputFormField<LoanDetailFormData, 'duration'>
          name="duration"
          type="number"
          step="1"
          label={intl.formatMessage({ id: 'loan.form.duration' })}
          placeholder={loanData?.duration?.baseValue ? formatNumber(loanData?.duration?.baseValue) : ''}
        />
      )}

      {category !== 'shareholders-due' && (
        <FormField<LoanDetailFormData, 'paymentPlan.frequency'>
          name="paymentPlan.frequency"
          render={({ field }) => (
            <FormItem className="col-span-2 pt-6">
              <FormControl>
                <Select
                  placeholder={intl.formatMessage({ id: 'loan.form.paymentPlanFrequency.placeholder' })}
                  options={PaymentFrequencies.map((frequency) => ({
                    value: frequency,
                    text: intl.formatMessage({ id: `loan.form.paymentPlanFrequency.option.${frequency}` }),
                  }))}
                  onValueChange={(v) => {
                    field.onChange(v)
                  }}
                  name={field.name}
                  disabled={field.disabled}
                  value={field.value}
                  initialValue={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      {category === 'shareholders-due' ? null : isNewLoan ? (
        <PaymentPlanInputs loanData={loanData} />
      ) : (
        <div className="col-span-4 col-start-1 grid grid-cols-4 items-start gap-x-4 gap-y-2 rounded-2xl bg-gray-50 px-4 py-6">
          <p className="col-span-4 col-start-1 font-bold italic">
            {intl.formatMessage({ id: 'loan.form.imported.date' }, { year: lastReferenceYear })}
          </p>
          <PaymentPlanInputs loanData={loanData} />
        </div>
      )}
      {import.meta.env.VITE_SHOW_LOAN_INTERNAL_VALUES === 'true' && <LoanInternalValues />}
      <div className="col-span-4 col-start-1">
        <LoadImportedValues />
      </div>
      <FormField<LoanDetailFormData, 'note'>
        name="note"
        render={({ field }) => (
          <FormItem className="col-span-4 col-start-1 pt-1">
            <FormControl>
              <Textarea {...field} placeholder={intl.formatMessage({ id: 'loan.form.note' })} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}

const FooterContent: React.FC<{ readonly loanData?: MonetaryLoanData; readonly category: LoanCategory }> = ({
  loanData,
  category,
}) => {
  const intl = useIntl()
  const {
    field: { onChange, ...field },
  } = useController<LoanDetailFormData, 'enabledOptions'>({ name: 'enabledOptions' })
  return (
    <SwitchAccordion type="multiple" {...field} onValueChange={onChange}>
      {category !== 'shareholders-due' && (
        <SwitchAccordionItem value="duration-without-capital">
          <SwitchAccordionTrigger
            id="duration-without-capital"
            label={intl.formatMessage({ id: 'loan.form.without-capital' })}
          />
          <SwitchAccordionContent>
            <LoanDurationWithoutCapitalForm />
          </SwitchAccordionContent>
        </SwitchAccordionItem>
      )}

      <SwitchAccordionItem value="new-capital">
        <SwitchAccordionTrigger
          id="new-capital"
          label={
            category === 'shareholders-due'
              ? intl.formatMessage({ id: 'loan.form.new-capital.shareholders-due' })
              : intl.formatMessage({ id: 'loan.form.new-capital' })
          }
        />
        <SwitchAccordionContent>
          <LoanNewCapitalForm category={category} />
        </SwitchAccordionContent>
      </SwitchAccordionItem>

      <SwitchAccordionItem value="early-repayment">
        <SwitchAccordionTrigger
          id="early-repayment"
          label={intl.formatMessage({
            id: category === 'shareholders-due' ? 'loan.form.shareholders-due.repayment' : 'loan.form.early-repayment',
          })}
        />
        <SwitchAccordionContent>
          {category === 'shareholders-due' ? (
            <LoanShareholderDueRepaymentForm />
          ) : (
            <LoanEarlyRepayment loanData={loanData} />
          )}
        </SwitchAccordionContent>
      </SwitchAccordionItem>

      {category !== 'shareholders-due' && (
        <SwitchAccordionItem value="interest-rate-change">
          <SwitchAccordionTrigger
            id="interest-rate-change"
            label={intl.formatMessage({ id: 'loan.form.interest-rate-change' })}
          />
          <SwitchAccordionContent>
            <LoanChangeRateForm />
          </SwitchAccordionContent>
        </SwitchAccordionItem>
      )}

      {category !== 'shareholders-due' && (
        <SwitchAccordionItem value="fixed-capital">
          <SwitchAccordionTrigger id="fixed-capital" label={intl.formatMessage({ id: 'loan.form.fixed-capital' })} />
          <SwitchAccordionContent>
            <LoanFixedCapitalPaymentAmountForm />
          </SwitchAccordionContent>
        </SwitchAccordionItem>
      )}

      {category !== 'shareholders-due' && (
        <SwitchAccordionItem value="duration-without-interest">
          <SwitchAccordionTrigger
            id="duration-without-interest"
            label={intl.formatMessage({ id: 'loan.form.without-interest' })}
          />
          <SwitchAccordionContent>
            <LoanDurationWithoutInterestForm loanData={loanData} />
          </SwitchAccordionContent>
        </SwitchAccordionItem>
      )}
    </SwitchAccordion>
  )
}

export interface LoanDetailProps extends LoanFormProviderProps, Pick<LoanFormHeaderProps, 'onDelete'> {}

export const LoanDetail = React.forwardRef<HTMLFormElement, LoanDetailProps>(
  ({ className, data, category, onDelete, disabled, ...props }, ref) => {
    const intl = useIntl()

    return (
      <LoanFormProvider
        ref={ref}
        data={data}
        category={category}
        className={cn('flex min-h-screen w-[614px] flex-col border-l-4 border-l-gray-400 bg-white', className)}
        disabled={disabled}
        {...props}>
        <header className="w-full bg-gray-100 py-4 pb-0 pl-6 pr-4">
          <LoanFormHeader
            disabled={disabled}
            onDelete={onDelete}
            defaultLoanLabel={
              category === 'shareholders-due'
                ? intl.formatMessage({ id: 'loan.form.shareholders-due.new' })
                : intl.formatMessage({ id: 'loan.form.new' })
            }
          />
        </header>
        <div className="h-screen overflow-y-auto pb-12">
          <main className="bg-gray-100 pb-6 pl-6 pr-4 pt-4">
            <MainContent loanData={data.loan} category={category} />
          </main>

          <footer className="flex flex-col gap-6 px-6 py-4">
            <FooterContent loanData={data.loan} category={category} />
          </footer>
        </div>
      </LoanFormProvider>
    )
  }
)
