import { useContext } from 'react'

import { CurrentBudgetProductionsContext } from './CurrentBudgetProductionsProvider.tsx'

export const useCurrentBudgetProductions = () => {
  const currentProductions = useContext(CurrentBudgetProductionsContext)
  if (!currentProductions) {
    throw new Error('useCurrentBudgetProductions must be used within a CurrentBudgetProductionsProvider')
  }

  return currentProductions
}
