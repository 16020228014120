import { z } from 'zod'

// Tables that should be watched for transactional changes
export const ViagritelSynchronizedTables = [
  'dossier',
  'dossier_annee',
  'dossier_production',
  'groupe_travail',
  'groupe_utilisateur',
  'groupe_travail_dossier',
  'utilisateur_dossier_restricted',
  'utilisateur',
] as const
export type ViagritelSynchronizedTable = (typeof ViagritelSynchronizedTables)[number]

// Tables for which a pubsub message should be published when a change is detected
export const ViagritelDenormalizedModels = ['dossier', 'utilisateur', 'groupe_travail'] as const

export const ViagritelSynchronizedTablesZod = z.enum(ViagritelSynchronizedTables)

export const ViagritelDenormalizedModelsZod = z.enum(ViagritelDenormalizedModels)

export const ViagritelSynchronizedModelStatusZod = z.object({
  lastSynchronizedTxId: z.number(),
  lastSynchronizedTxIdDate: z.date(),
})
export type ViagritelSynchronizedTableStatus = z.infer<typeof ViagritelSynchronizedModelStatusZod>

export const ViagritelSynchronizedTableStatusObjectZod = z.record(
  ViagritelSynchronizedTablesZod,
  ViagritelSynchronizedModelStatusZod
)
export type ViagritelSynchronizedTableStatusObject = z.infer<typeof ViagritelSynchronizedTableStatusObjectZod>

export const ViagritelDatabaseZod = z.object({
  instanceConnectionName: z.string(),
  database: z.string(),
  synchronizationStatus: ViagritelSynchronizedTableStatusObjectZod.optional().default({}),
})
