import { Timestamp } from 'firebase/firestore'

export type DeepDateToString<T> = T

export const deepDateToString = <T>(value: T): DeepDateToString<T> => {
  switch (true) {
    case value instanceof Date:
      return value.toISOString() as DeepDateToString<T>
    case value instanceof Timestamp:
      return value.toDate().toISOString() as DeepDateToString<T>
    case typeof value === 'string':
      return value satisfies DeepDateToString<string> as DeepDateToString<T>
    case Array.isArray(value):
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return value.map((v) => deepDateToString(v)) as DeepDateToString<T>
    case value instanceof Object:
      return Object.fromEntries(
        Object.entries(value).map(([key, v]) => [key, deepDateToString(v)])
      ) as DeepDateToString<T>
    default:
      return value
  }
}
