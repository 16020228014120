import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react'

import type * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cva, type VariantProps } from 'class-variance-authority'

import { Checkbox } from '../../atoms/Checkbox/Checkbox'
import { FormLabel } from '../../atoms/FormLabel/FormLabel'
import { cn } from '../../lib/utils'

const checkboxWithLabelVariants = cva('flex items-center gap-1.5', {
  variants: {
    variant: {
      default: '',
      outline: 'px-4 py-2 border rounded-lg border-blue-200',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export interface CheckboxWithLabelProps
  extends ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    VariantProps<typeof checkboxWithLabelVariants> {
  readonly label: string
}

export const CheckboxWithLabel = forwardRef<ElementRef<typeof CheckboxPrimitive.Root>, CheckboxWithLabelProps>(
  ({ className, id, label, variant, ...props }, ref) => (
    <div className={cn(checkboxWithLabelVariants({ variant }), className)}>
      <Checkbox id={id} ref={ref} {...props} />
      <FormLabel htmlFor={id} className="pt-1 font-medium">
        {label}
      </FormLabel>
    </div>
  )
)
