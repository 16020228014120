import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint.ts'
import { dataLine, dividerLine } from '../utils.ts'

export type MilkAnimalsViewMovementsTabs = 'balance' | 'movement'

export const milkAnimalsMovementsSheet2024Blueprint = {
  hideReferenceLabel: true,
  sections: [
    {
      id: 'animals.movements.section',
      titleKey: 'table.milk.animals.movements.section',
      collapsible: false,
      showBottomHeader: false,
      children: [
        dividerLine({ type: 'default' }, 'milk.animals.movements.section'),
        dataLine('milk.animals.movement.cows.head-count', { unit: 'head-count' }),
        dataLine('milk.animals.movement.bred-heifers.head-count', { unit: 'head-count' }),
        dataLine('milk.animals.movement.unbred-heifers.head-count', { unit: 'head-count' }),
        dataLine('milk.animals.movement.heifers.head-count', { unit: 'head-count' }),
        dataLine('milk.animals.movement.bulls.head-count', { unit: 'head-count' }),
        dataLine('milk.animals.movement.calves.head-count', { unit: 'head-count' }),
        dataLine('milk.animals.movement.other.head-count', { unit: 'head-count' }),
      ],
    },
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true, className: 'w-84' },
    {
      id: 'selected-reference',
      type: 'reference',
      sticky: true,
      className: 'w-[7.5rem]',
      topHeaderClassName: 'w-[7.5rem] h-14 border-t border-t-gray-400',
      withDividerRight: true,
      showUnitInCell: true,
    },
    {
      id: 'divider',
      type: 'divider',
      className: 'w-1',
    },
    {
      id: 'projections',
      type: 'projections',
      withDividerRight: true,
      className: 'w-[7.5rem]',
      topHeaderVariant: 'head',
      topHeaderClassName: 'w-[7.5rem] h-14',
      showUnitInCell: true,
    },
  ],
  tabs: [
    { labelKey: 'table.milkAnimals.tabs.variation', value: 'movement' },
    { labelKey: 'table.milkAnimals.tabs.heads', value: 'balance' },
  ],
  defaultTab: 'movement',
} as const satisfies Except<BlueprintTable<never, MilkAnimalsViewMovementsTabs>, 'version'>
