import { scenarioData } from '@via/components'
import { collections } from '@via/frontend-schema'

export const fetchOrThrowFirestoreScenarioData = async (budgetId: string, scenarioId: string, stateId: string) => {
  const [scenario, state] = await Promise.all([
    collections.budgets(budgetId).scenarios.findByIdOrThrow(scenarioId),
    collections.budgets(budgetId).scenarios(scenarioId).states.findByIdOrThrow(stateId),
  ])

  const reference = state.referenceId
    ? await collections.budgets(budgetId).references.findByIdOrThrow(state.referenceId)
    : undefined

  return scenarioData(scenario, state, reference)
}
