import React from 'react'

import * as Accordion from '@radix-ui/react-accordion'

import { Icons } from '../../atoms'
import { cn } from '../../lib/utils'

export const CaretAccordion = Accordion.Root

export const CaretAccordionItem = React.forwardRef<
  React.ElementRef<typeof Accordion.Item>,
  React.ComponentPropsWithoutRef<typeof Accordion.Item>
>((props, ref) => <Accordion.Item ref={ref} {...props} />)

export const CaretAccordionTrigger = React.forwardRef<
  React.ElementRef<typeof Accordion.Trigger>,
  React.ComponentPropsWithoutRef<typeof Accordion.Trigger> & { readonly label: string }
>(({ id, label, className, ...props }, ref) => (
  <Accordion.Header>
    <Accordion.Trigger asChild ref={ref} className={className} {...props}>
      <button type="button" className={cn('group flex items-center gap-1')}>
        <Icons.CaretRightFill
          className={cn('size-3 transition-transform duration-200 group-data-[state=open]:rotate-90')}
        />
        {label}
      </button>
    </Accordion.Trigger>
  </Accordion.Header>
))

export const CaretAccordionContent = React.forwardRef<
  React.ElementRef<typeof Accordion.Content>,
  React.ComponentPropsWithoutRef<typeof Accordion.Content>
>(({ children, ...props }, ref) => (
  <Accordion.Content ref={ref} {...props}>
    {children}
  </Accordion.Content>
))
