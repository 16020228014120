import { forwardRef, type HtmlHTMLAttributes, useState } from 'react'
import { useIntl } from 'react-intl'

import { useEventListener } from '@react-hookz/web/useEventListener/index.js'
import { Link, useParams, useRouterState } from '@tanstack/react-router'
import { cva } from 'class-variance-authority'

import { Divider } from '../../atoms/Divider/Divider'
import { cn } from '../../lib/utils'

const reportNavigationItemsVariants = cva('flex rounded border border-sage-400 px-2 py-1.5 select-none', {
  variants: {
    active: {
      true: 'bg-sage-50',
      false: '',
    },
  },
  defaultVariants: {
    active: false,
  },
})

export interface ReportNavigationItemsProps extends HtmlHTMLAttributes<HTMLDivElement> {
  readonly disabled?: boolean
}

type NavigationItems = 'type' | 'preamble' | 'content' | 'annex' | undefined

export const ReportNavigationItems = forwardRef<HTMLDivElement, ReportNavigationItemsProps>(
  ({ className, disabled = false }, ref) => {
    const { budgetId, reportId } = useParams({ strict: false })
    const intl = useIntl()
    const { location } = useRouterState()
    const [active, setActive] = useState<NavigationItems>(
      location.pathname.endsWith('/list') ? undefined : ((location.hash || 'content') as NavigationItems)
    )

    useEventListener(document, 'scroll', () => {
      const absoluteTop = -132

      const typeElement = document.querySelector('#type')
      const typeSectionTop = typeElement?.getBoundingClientRect().top
      const typeSectionVisible =
        typeSectionTop !== undefined && typeSectionTop < window.innerHeight && typeSectionTop >= absoluteTop

      if (typeSectionVisible) {
        setActive('type')
        return
      }

      const preambleElement = document.querySelector('#preamble')
      const preambleSectionTop = preambleElement?.getBoundingClientRect().top
      const preambleSectionVisible =
        preambleSectionTop !== undefined && preambleSectionTop < window.innerHeight && preambleSectionTop >= absoluteTop

      if (preambleSectionVisible) {
        setActive('preamble')
        return
      }

      const contentElement = document.querySelector('#content')
      const contentSectionTop = contentElement?.getBoundingClientRect().top
      const contentSectionVisible =
        contentSectionTop !== undefined && contentSectionTop < window.innerHeight && contentSectionTop >= absoluteTop

      if (contentSectionVisible) {
        setActive('content')
        return
      }

      const annexElement = document.querySelector('#annex')
      const annexSectionTop = annexElement?.getBoundingClientRect().top
      const annexSectionVisible =
        annexSectionTop !== undefined && annexSectionTop < window.innerHeight && annexSectionTop >= absoluteTop

      if (annexSectionVisible) {
        setActive('annex')
        return
      }

      setActive(undefined)
    })

    return (
      <div
        className={cn('flex min-h-full w-32 flex-col items-stretch gap-2 bg-white pl-3 pr-5 pt-4', className)}
        ref={ref}>
        <Link
          to="/budgets/$budgetId/reports/$reportId"
          className={reportNavigationItemsVariants({ active: active === 'type' })}
          disabled={disabled}
          hash="type"
          params={{
            budgetId: budgetId!,
            reportId: reportId!,
          }}
          id="type-link">
          <p className="text-left text-xs font-medium leading-none tracking-[-0.42px] text-gray-800">
            {intl.formatMessage({ id: 'reportNav.type' })}
          </p>
        </Link>
        <Link
          to="/budgets/$budgetId/reports/$reportId"
          hash="preamble"
          className={reportNavigationItemsVariants({ active: active === 'preamble' })}
          disabled={disabled}
          params={{
            budgetId: budgetId!,
            reportId: reportId!,
          }}
          id="preamble-link">
          <p className="text-left text-xs font-medium leading-none tracking-[-0.42px] text-gray-800">
            {intl.formatMessage({ id: 'reportNav.preamble' })}
          </p>
        </Link>
        <Link
          to="/budgets/$budgetId/reports/$reportId"
          disabled={disabled}
          hash="content"
          params={{
            budgetId: budgetId!,
            reportId: reportId!,
          }}
          id="content-link">
          <div
            className={reportNavigationItemsVariants({
              active: active === 'content',
            })}>
            <p className="text-left text-xs font-medium leading-none tracking-[-0.42px] text-gray-800">
              {intl.formatMessage({ id: 'reportNav.content' })}
            </p>
          </div>
        </Link>
        <Link
          to="/budgets/$budgetId/reports/$reportId"
          disabled={disabled}
          hash="annex"
          params={{
            budgetId: budgetId!,
            reportId: reportId!,
          }}
          id="annex-link">
          <div
            className={reportNavigationItemsVariants({
              active: active === 'annex',
            })}>
            <p className="text-left text-xs font-medium leading-none tracking-[-0.42px] text-gray-800">
              {intl.formatMessage({ id: 'reportNav.annex' })}
            </p>
          </div>
        </Link>
        <Divider />
        <Link
          to="/budgets/$budgetId/reports/$reportId/files"
          disabled={disabled}
          params={{
            budgetId: budgetId!,
            reportId: reportId!,
          }}
          activeOptions={{ exact: true }}
          id="list-link">
          {({ isActive }) => (
            <div className={reportNavigationItemsVariants({ active: isActive })}>
              <p className="text-left text-xs font-medium leading-none tracking-[-0.42px] text-gray-800">
                {intl.formatMessage({ id: 'reportNav.list' })}
              </p>
            </div>
          )}
        </Link>
      </div>
    )
  }
)
