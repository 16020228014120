import { collections } from '@via/frontend-schema'
import { EMPTY, from, map, of, switchMap, tap } from 'rxjs'

import { fromDocumentRef } from '../rxjs/fromDocumentRef.ts'

export interface FirestoreScenarioDataParams {
  readonly budgetId: string
  readonly scenarioId: string
}

export const firestoreScenarioData$ = ({ budgetId, scenarioId }: FirestoreScenarioDataParams) =>
  fromDocumentRef(collections.budgets(budgetId).scenarios.read.doc(scenarioId)).pipe(
    switchMap((snapshot) => (snapshot.exists() ? of(snapshot.data()) : EMPTY)),
    tap((scenario) => {
      if (import.meta.env.DEV) {
        performance.mark(`firestore-scenario-data:${scenario.currentStateId}:start`)
      }
    }),
    switchMap((scenario) =>
      from(collections.budgets(budgetId).scenarios(scenarioId).states.findById(scenario.currentStateId)).pipe(
        switchMap((scenarioState) => (scenarioState ? of(scenarioState) : EMPTY)),
        map((scenarioState) => ({ scenario, scenarioState }))
      )
    ),
    switchMap(({ scenario, scenarioState }) =>
      !scenarioState.referenceId
        ? of({ scenario, scenarioState, reference: undefined })
        : from(collections.budgets(budgetId).references.findById(scenarioState.referenceId)).pipe(
            switchMap((reference) => (reference ? of(reference) : EMPTY)),
            map((reference) => ({ scenario, scenarioState, reference }))
          )
    )
  )
