import { type FC, type PropsWithChildren, useContext } from 'react'

import { Navigate } from '@tanstack/react-router'
import {
  BudgetContext,
  type BudgetData,
  BudgetReferenceUpdateDialogProvider,
  ScenarioTabListContext,
} from '@via/components'

import { BudgetReferenceChangelog } from '../../firestore/budgetReferenceUpdate/BudgetReferenceChangelog.tsx'
import { BudgetRequestConfirmationDialogProvider } from '../../requests/BudgetRequestConfirmationDialogProvider.tsx'
import { BudgetUserRequestsDialogProvider } from '../../requests/BudgetUserRequestsDialogProvider.tsx'
import { AppRxDatabaseContext } from '../../rxdb/contexts/AppRxDatabaseContext.ts'
import { useRxDocumentData } from '../../rxdb/useRxDocumentData.ts'
import { useRxDocumentDeleted } from '../../rxdb/useRxDocumentDeleted.ts'
import { CurrentBudgetReportListProvider } from '../currentBudgetReportList/CurrentBudgetReportListProvider.tsx'

interface RxdbCurrentBudgetProviderProps {
  readonly budget: BudgetData
}

export const RxdbCurrentBudgetProvider: FC<PropsWithChildren<RxdbCurrentBudgetProviderProps>> = ({
  children,
  budget,
}) => {
  const appDatabase = useContext(AppRxDatabaseContext)

  const rxBudget = useRxDocumentData(appDatabase.budgets.findOne(budget._id))
  const isDeleted = useRxDocumentDeleted(appDatabase.budgets.findOne(budget._id))

  if (isDeleted) {
    return <Navigate to="/budgets" />
  }

  return (
    <BudgetContext.Provider value={rxBudget ?? budget}>
      <ScenarioTabListContext.Provider value={rxBudget?.scenarios ?? budget.scenarios}>
        <BudgetRequestConfirmationDialogProvider budget={rxBudget ?? budget}>
          <BudgetUserRequestsDialogProvider budget={rxBudget ?? budget}>
            <BudgetReferenceUpdateDialogProvider body={<BudgetReferenceChangelog />}>
              <CurrentBudgetReportListProvider budgetId={budget._id}>{children}</CurrentBudgetReportListProvider>
            </BudgetReferenceUpdateDialogProvider>
          </BudgetUserRequestsDialogProvider>
        </BudgetRequestConfirmationDialogProvider>
      </ScenarioTabListContext.Provider>
    </BudgetContext.Provider>
  )
}
