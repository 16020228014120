import { forwardRef, type HtmlHTMLAttributes } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const headerLabelVariants = cva(
  'flex pt-3 pb-2.5 justify-center items-center w-24 h-8 shrink-0 text-black text-center',
  {
    variants: {
      variant: {
        default: 'bg-gray-100',
        head: 'bg-sky-50',
        total: 'bg-orange-200',
        alternate: 'bg-white',
      },
      dimension: {
        xxs: 'text-xxs font-bold leading-tight tracking-[-0.1px]',
        sm: 'text-sm font-normal leading-none tracking-[-0.28px]',
      },
      withDividerTop: {
        false: '',
        true: 'border-t border-t-gray-400',
      },
      withDividerLeft: {
        false: 'pl-0.5',
        true: 'pl-px border-l border-l-gray-400',
      },
      withDividerRight: {
        false: 'pr-0.5',
        true: 'pr-px last:border-r border-r-gray-400',
      },
    },
    defaultVariants: {
      variant: 'default',
      dimension: 'xxs',
      withDividerTop: false,
      withDividerLeft: true,
      withDividerRight: false,
    },
  }
)

export interface HeaderLabelProps extends HtmlHTMLAttributes<HTMLDivElement>, VariantProps<typeof headerLabelVariants> {
  readonly label: string
}

export const HeaderLabel = forwardRef<HTMLDivElement, HeaderLabelProps>(
  ({ className, label, variant, dimension, withDividerTop, withDividerLeft, withDividerRight, ...props }, ref) => (
    <div
      className={cn(
        headerLabelVariants({ variant, dimension, withDividerTop, withDividerLeft, withDividerRight }),
        className
      )}
      ref={ref}
      {...props}>
      <p className="select-none overflow-hidden text-ellipsis">{label}</p>
    </div>
  )
)
