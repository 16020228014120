import { z } from 'zod'

import { CalculationDataKeys, DataKeyZod, keysToTuple } from '../data-keys'
import {
  AssetAttributesJson,
  AssetInventoryAttributesJson,
  AssetTotalAttributesJson,
  InventoryAttributesJson,
  InventoryTotalAttributesJson,
  InvestmentAttributesJson,
  InvestmentInventoryAttributesJson,
  InvestmentTotalAttributesJson,
  MovementOverridableAttributesJson,
  MovementTotalAttributesJson,
  OverridableAttributesJson,
} from '../data-keys/generated'
import { PaymentFrequencyZod } from '../enums'

import { CellDataZod, OverridableCellDataZod } from './cell-data-zod'
import { DurationValueZod, GrowthValueZod, MonetaryValueZod } from './monetary-value-zod'
import { ScenarioInvestmentStateZod } from './scenario-investment-state-zod'
import { ScenarioLoanStateZod } from './scenario-loan-state-zod'
import { type YearKey, YearKeyZod } from './year-key-zod'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ZodTypeString = z.ZodType<string, any, any>

const BaseRow = <Z extends z.ZodTypeAny>(CellData: Z) =>
  z.object({
    references: z.record(YearKeyZod, CellData).optional().default({}),
    values: z.record(YearKeyZod, CellData).optional().default({}),
    reference: CellData.optional(),
    total: CellData.optional(),
  })

export interface BaseRowData<CellData> {
  readonly references: Partial<Record<YearKey, CellData>>
  readonly values: Partial<Record<YearKey, CellData>>
  readonly reference?: CellData
  readonly total?: CellData
}

const BaseWeightedRow = <ZCellData extends z.ZodTypeAny>(CellData: ZCellData) =>
  BaseRow(CellData).extend({
    weighted: z.record(BaseRow(CellData)).optional(),
  })

export interface BaseWeightedRowData<CellData> extends BaseRowData<CellData> {
  readonly weighted?: Partial<Record<string, BaseRowData<CellData>>>
}

const Row = <ZCellData extends z.ZodTypeAny, ZDataKey extends ZodTypeString, ZComputationType extends ZodTypeString>(
  CellData: ZCellData,
  key: ZDataKey,
  computationType: ZComputationType
) => BaseRow(CellData).extend({ key, computationType })

const WeightedRow = <
  ZCellData extends z.ZodTypeAny,
  ZDataKey extends ZodTypeString,
  ZComputationType extends ZodTypeString,
>(
  CellData: ZCellData,
  key: ZDataKey,
  computationType: ZComputationType
) => BaseWeightedRow(CellData).extend({ key, computationType })

/**
 * Grow Rate based Forecasting
 */

const GrowthRateBasedForecastingRowDataZod = WeightedRow(OverridableCellDataZod, DataKeyZod, z.literal('grbf')).extend({
  growth: OverridableCellDataZod.optional(),
})

/**
 * Loan
 */

const LoanProjectionValuesZod = z.object({
  annuity: CellDataZod, // capital + interest
  interest: CellDataZod,
  projectedCapital: CellDataZod,
  capital: CellDataZod,
  earlyRepayment: CellDataZod, // capital - projectedCapital
  remainingDueAmount: CellDataZod,
  newCapitalBorrowed: CellDataZod,
  exigibleCapital: CellDataZod,
})

export const LoanRowDataZod = ScenarioLoanStateZod.omit({ paymentAmount: true, paymentFrequency: true }).extend({
  loanId: z.string(),
  key: DataKeyZod,
  computationType: z.enum(['finance-loan', 'finance-loan-investment']),
  source: z.enum(['imported', 'scenario']),
  values: z.record(YearKeyZod, LoanProjectionValuesZod).optional().default({}),
  name: z.string().optional().default(''),
  startingBalance: CellDataZod.optional(),
  amount: OverridableCellDataZod.optional(),
  interestRate: OverridableCellDataZod.optional(),
  duration: OverridableCellDataZod.optional(),
  paymentPlan: z
    .object({
      amount: OverridableCellDataZod.optional(),
      frequency: PaymentFrequencyZod.optional(),
      paymentAmounts: z.record(MonetaryValueZod).optional(),
    })
    .optional()
    .default({}),
  earlyRepaymentRemainingDueAmount: z.record(MonetaryValueZod).optional(),
  imported: z
    .object({
      beginCapital: MonetaryValueZod.optional(),
      newCapital: MonetaryValueZod.optional(),
      endCapital: MonetaryValueZod.optional(),
      paidReimbursement: MonetaryValueZod.optional(),
      projectedReimbursement: MonetaryValueZod.optional(),
      paidInterests: MonetaryValueZod.optional(),
      beginDueAmount: MonetaryValueZod.optional(),
      endDueAmount: MonetaryValueZod.optional(),
    })
    .optional()
    .default({}),
  estimated: z
    .object({
      interestRate: GrowthValueZod.optional(),
      payment: MonetaryValueZod.optional(), // period are paymentPlan.frequency if available else 'monthly'
    })
    .optional()
    .default({}),
  computed: z
    .object({
      interestRate: GrowthValueZod.optional(),
      payment: MonetaryValueZod.optional(), // period are paymentPlan.frequency if available else 'monthly'
      duration: DurationValueZod.optional(),
    })
    .optional()
    .default({}),
  investment: ScenarioInvestmentStateZod.optional(),
})

const LoanTotalProjectionValuesZod = LoanProjectionValuesZod.extend({
  netNewCapital: CellDataZod, // newCapitalBorrowed - earlyRepayment
  liquidityMovement: CellDataZod, // newCapitalBorrowed - annuity
})

const LoanTotalRowDataZod = z.object({
  key: DataKeyZod,
  startingBalance: CellDataZod.optional(),
  amount: CellDataZod.optional(),
  computationType: z.literal('finance-loan-total'),
  references: z.record(YearKeyZod, LoanTotalProjectionValuesZod).optional().default({}),
  values: z.record(YearKeyZod, LoanTotalProjectionValuesZod).optional().default({}),
})

/**
 * Movement Row
 */

export type MovementCellData<CellData, Base = CellData> = Base & {
  readonly startingBalance?: CellData
  readonly balance?: CellData
}

const MovementRow = <ZDataKey extends ZodTypeString, ZComputationType extends ZodTypeString>(
  CellData: z.AnyZodObject,
  DataKey: ZDataKey,
  ComputationType: ZComputationType
) =>
  Row(
    CellData.extend({
      startingBalance: CellDataZod.optional(),
      balance: CellDataZod.optional(),
    }),
    DataKey,
    ComputationType
  )

/**
 * Inventory Row
 */

const InventoryValuesZod = z.object({
  startingUnitBalance: CellDataZod,
  startingUnitValue: CellDataZod,
  startingValue: CellDataZod,
  unitBalance: CellDataZod,
  unitValue: CellDataZod,
  value: CellDataZod,
  unitMovement: CellDataZod,
  valueMovementUnitBalance: CellDataZod,
  valueMovementUnitValue: CellDataZod,
  valueMovement: CellDataZod,
  purchase: CellDataZod,
  purchaseCost: CellDataZod,
  sale: CellDataZod,
  saleCost: CellDataZod,
  disbursement: CellDataZod,
})

const InventoryTotalValuesZod = z.object({
  startingValue: CellDataZod,
  value: CellDataZod,
  valueMovementUnitBalance: CellDataZod,
  valueMovementUnitValue: CellDataZod,
  valueMovement: CellDataZod,
  disbursement: CellDataZod,
})

/**
 * Investments
 */

const InvestmentProjectionValuesZod = z.object({
  value: CellDataZod,
  disbursement: CellDataZod,
  subvention: CellDataZod,
  actualDisbursement: CellDataZod,
})

export const InvestmentRowDataZod = Row(
  InvestmentProjectionValuesZod,
  z.enum([...keysToTuple(InvestmentAttributesJson), ...keysToTuple(InvestmentInventoryAttributesJson)]),
  z.enum(['finance-investment', 'finance-investment-inventory'])
).extend({
  investmentId: z.string(),
  name: z.string(),
  category: z.enum(['purchase', 'sale', 'financing', 'capital', 'shares-purchase', 'shares-sale']),
  subvention: BaseRow(CellDataZod).optional(),
})

/**
 * Assets
 */

const AssetProjectionValuesZod = z.object({
  value: CellDataZod,
  valueMovement: CellDataZod,
  valueBalanceMovement: CellDataZod,
  amortization: CellDataZod,
  contributoryValue: CellDataZod,
  disbursement: CellDataZod,
  subsidies: CellDataZod,
  actualDisbursement: CellDataZod,
  inflation: CellDataZod,
})

const AssetRowDataZod = Row(
  AssetProjectionValuesZod,
  z.enum([...keysToTuple(AssetAttributesJson), ...keysToTuple(AssetInventoryAttributesJson)]),
  z.enum(['finance-asset', 'finance-asset-inventory'])
).extend({
  assetId: z.string(),
  name: z.string(),
})

/**
 * Milk Production
 */

const MilkQuotaValuesZod = z.object({
  startingBalance: CellDataZod,
  purchase: CellDataZod,
  purchaseCost: CellDataZod,
  sale: CellDataZod,
  saleCost: CellDataZod,
  disbursement: CellDataZod,
  adjustment: CellDataZod,
  adjustmentAmount: CellDataZod,
  movement: CellDataZod,
  balance: CellDataZod,
  assetValue: CellDataZod,
  assetValueMovement: CellDataZod,
  startUp: CellDataZod,
  rental: CellDataZod,
  rentalCost: CellDataZod,
  rentalTotalCost: CellDataZod,
  effective: CellDataZod,
  loan: CellDataZod,
  loanAmount: CellDataZod,
  federationExtensionDays: CellDataZod,
  federationExtensionUsage: CellDataZod,
  federationExtension: CellDataZod,
  allowedKilogram: CellDataZod,
  allowedProduction: CellDataZod,
  toleranceBalance: CellDataZod,
  toleranceMovement: CellDataZod,
  toleranceExcess: CellDataZod,
  toleranceShortage: CellDataZod,
})

const MilkIncomeValuesZod = z.object({
  nonFatSolidRatio: CellDataZod,
  proteinExcessLevel1: CellDataZod,
  solidExcessLevel1: CellDataZod,
  proteinExcessLevel2: CellDataZod,
  solidExcessLevel2: CellDataZod,
  basePrice: CellDataZod,
  applicableBonusBio: CellDataZod,
  applicableBonusQuality: CellDataZod,
  applicableBonusOther: CellDataZod,
  applicableBonus: CellDataZod,
  price: CellDataZod,
  salesRevenue: CellDataZod,
  grossDividends: CellDataZod,
  dividendsRevenueProportion: CellDataZod,
  dividendsRevenue: CellDataZod,
  dividendsAssetValue: CellDataZod,
  dividendsTax: CellDataZod,
  total: CellDataZod,
})

/**
 * RowDataZod
 */

export const RowDataZod = z.discriminatedUnion('computationType', [
  GrowthRateBasedForecastingRowDataZod,
  WeightedRow(CellDataZod, DataKeyZod, z.literal('total')),
  WeightedRow(CellDataZod, DataKeyZod, z.literal('composite')),
  WeightedRow(CellDataZod, z.enum(CalculationDataKeys), z.literal('calculation')),
  WeightedRow(OverridableCellDataZod, z.enum(keysToTuple(OverridableAttributesJson)), z.literal('overridable')),
  MovementRow(
    OverridableCellDataZod,
    z.enum(keysToTuple(MovementOverridableAttributesJson)),
    z.literal('movement-overridable')
  ),
  MovementRow(CellDataZod, z.enum(keysToTuple(MovementTotalAttributesJson)), z.literal('movement-total')),
  Row(MilkQuotaValuesZod, z.literal('milk-quota'), z.literal('milk-quota')),
  Row(MilkIncomeValuesZod, z.literal('milk-income'), z.literal('milk-income')),
  LoanRowDataZod,
  LoanTotalRowDataZod,
  Row(InventoryValuesZod, z.enum(keysToTuple(InventoryAttributesJson)), z.literal('inventory')),
  Row(InventoryTotalValuesZod, z.enum(keysToTuple(InventoryTotalAttributesJson)), z.literal('inventory-total')),
  InvestmentRowDataZod,
  Row(
    InvestmentProjectionValuesZod,
    z.enum(keysToTuple(InvestmentTotalAttributesJson)),
    z.literal('finance-investment-total')
  ),
  AssetRowDataZod,
  Row(AssetProjectionValuesZod, z.enum(keysToTuple(AssetTotalAttributesJson)), z.literal('finance-asset-total')),
])
