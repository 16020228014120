import { type FC, useCallback, useContext, useState } from 'react'

import { nanoid } from 'nanoid'

import { AddItemButton } from '../../atoms/AddItemButton/AddItemButton'
import { Sheet, SheetContent, SheetTrigger } from '../../atoms/Sheet/Sheet'
import { InvestmentOperationsContext, useScenario } from '../../context'
import { MonetaryTableEditingContext } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import { type InvestmentFormSubmitData } from '../InvestmentForm'
import { InvestmentForm } from '../InvestmentForm/InvestmentForm.tsx'

interface AddInvestmentState {
  open: boolean
  created: boolean
  id: string
}

export const AddInvestmentSheet: FC = () => {
  const [state, setState] = useState<AddInvestmentState>({ open: false, created: false, id: nanoid() })
  const { onAddInvestment, onDeleteInvestment } = useContext(InvestmentOperationsContext)
  const { blueprintVersion, enabledProductions } = useScenario()
  const { setEditing } = useContext(MonetaryTableEditingContext)

  const setOpen = useCallback(
    (open = true) => {
      setEditing(open)
      setState((prevState) => ({ ...prevState, open, created: false, ...(open ? { id: nanoid() } : {}) }))
    },
    [setEditing]
  )

  const onApply = useCallback(
    async (params: InvestmentFormSubmitData) => {
      await onAddInvestment(params)
      setState((prevState) => ({ ...prevState, created: true }))
    },
    [onAddInvestment]
  )

  const onSubmit = useCallback(
    async (params: InvestmentFormSubmitData) => {
      await onAddInvestment(params)
      setState({ open: false, created: false, id: nanoid() })
    },
    [onAddInvestment]
  )

  const onDelete = useCallback(async () => {
    await onDeleteInvestment(state.id, () => {
      setState({ open: false, created: false, id: nanoid() })
    })
  }, [onDeleteInvestment, state.id])

  return (
    <Sheet open={state.open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <AddItemButton />
      </SheetTrigger>
      <SheetContent side="right" className="w-fit p-0">
        <InvestmentForm
          investmentId={state.id}
          blueprintVersion={blueprintVersion}
          enabledProductions={enabledProductions}
          onSubmit={onSubmit}
          onApply={onApply}
          onDelete={state.created ? onDelete : undefined}
        />
      </SheetContent>
    </Sheet>
  )
}
