import { scenarioData } from '@via/components'
import { collections } from '@via/frontend-schema'

export interface FetchFirestoreScenarioDataParams {
  readonly budgetId: string
  readonly scenarioId: string
}

export const fetchFirestoreScenarioData = async ({ budgetId, scenarioId }: FetchFirestoreScenarioDataParams) => {
  const scenario = await collections.budgets(budgetId).scenarios.findByIdOrThrow(scenarioId)
  if (!scenario) {
    return scenario
  }
  const scenarioState = await collections
    .budgets(budgetId)
    .scenarios(scenarioId)
    .states.findByIdOrThrow(scenario.currentStateId)
  const reference = scenarioState.referenceId
    ? await collections.budgets(budgetId).references.findByIdOrThrow(scenarioState.referenceId)
    : undefined

  return scenarioData(scenario, scenarioState, reference)
}
