import { type OfflineReportViewProps, useBudget } from '@via/components'

import { useAppNavigation } from '../../navigation/useAppNavigation.ts'

export const useOfflineReportsPage = (): OfflineReportViewProps => {
  const budget = useBudget()
  const { onNavigationChange } = useAppNavigation()

  return {
    budget,
    onNavigationChange,
  }
}
