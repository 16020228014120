import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { type UserAvatarProps } from '../../molecules/UserAvatar/UserAvatar'
import { UserDialog } from '../../molecules/UserDialog/UserDialog'

interface RequesterUserDialogProps {
  readonly open: boolean
  readonly onConfirm: () => Promise<void>
  readonly onCancel: () => Promise<void> | void
  readonly userAvatar: UserAvatarProps
}

export const RequesterUserDialog: FC<RequesterUserDialogProps> = ({ onConfirm, onCancel, userAvatar, ...props }) => {
  const intl = useIntl()

  return (
    <UserDialog
      {...props}
      userAvatar={{ ...userAvatar, order: 'avatar-last' }}
      confirmAction={onConfirm}
      confirmLabel={intl.formatMessage({ id: 'budget.requestOwnership.requesterUserDialog.confirm' })}
      cancelAction={onCancel}
      cancelLabel={intl.formatMessage({ id: 'budget.requestOwnership.requesterUserDialog.close' })}
      title={intl.formatMessage({ id: 'budget.requestOwnership.requesterUserDialog.title' })}
      subtitlePrefix={intl.formatMessage({ id: 'budget.requestOwnership.requesterUserDialog.subtitlePrefix' })}
      subtitleSuffix={intl.formatMessage({ id: 'budget.requestOwnership.requesterUserDialog.subtitleSuffix' })}
    />
  )
}
