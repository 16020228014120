import { type ProductionType } from '@via/schema'

import { type ApiMutationOptions, useApi } from './mutation/useApi.ts'

export interface CreateBudgetReferenceVariables {
  readonly budgetId: string
}

export const useCreateBudgetReference = (
  options?: ApiMutationOptions<
    { id: string; currentProductions: Record<string, ProductionType[]> },
    CreateBudgetReferenceVariables
  >
) =>
  useApi<{ id: string; currentProductions: Record<string, ProductionType[]> }, CreateBudgetReferenceVariables>({
    type: 'createBudgetReference',
    ...options,
  })
