import { type FC, type PropsWithChildren, useState } from 'react'

import { ScenarioLayoutFooterHeightContext } from './ScenarioLayoutFooterHeightContext'
import { ScenarioLayoutFooterHeightSetterContext } from './ScenarioLayoutFooterHeightSetterContext.ts'

export const ScenarioLayoutFooterHeightProvider: FC<PropsWithChildren> = ({ children }) => {
  const [height, setHeight] = useState(0)

  return (
    <ScenarioLayoutFooterHeightContext.Provider value={height}>
      <ScenarioLayoutFooterHeightSetterContext.Provider value={setHeight}>
        {children}
      </ScenarioLayoutFooterHeightSetterContext.Provider>
    </ScenarioLayoutFooterHeightContext.Provider>
  )
}
