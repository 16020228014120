import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { dataLine, headerLine } from '../utils'

export const balanceSheet2024Blueprint = {
  sections: [
    {
      id: 'balance.section',
      titleKey: 'table.balance.section',
      children: [
        {
          ...headerLine('table.assets.section'),
          children: [
            {
              ...dataLine('balance.assets.short'),
              children: [
                dataLine('balance.assets.short.liquidities', { displayAsProjection: true }),
                dataLine('balance.assets.short.programs'),
                dataLine('balance.assets.short.receivables'),
                {
                  ...dataLine('balance.assets.short.inventory'),
                  children: [
                    dataLine('balance.assets.short.inventory.forage.amount'),
                    dataLine('balance.assets.short.inventory.cereals.amount'),
                    dataLine('balance.assets.short.inventory.slaughter.amount'),
                    dataLine('balance.assets.short.inventory.supplies.amount'),
                    dataLine('balance.assets.short.inventory.miscellaneous.amount'),
                  ],
                },
              ],
            },
            {
              ...dataLine('balance.assets.long'),
              children: [
                dataLine('balance.assets.long.breeders.amount', { displayAsProjection: true }),
                dataLine('balance.assets.long.machinery.amount', { displayAsProjection: true }),
                dataLine('balance.assets.long.buildings.amount', { displayAsProjection: true }),
                dataLine('balance.assets.long.land.amount', { displayAsProjection: true }),
                dataLine('balance.assets.long.quotas.amount', { displayAsProjection: true }),
                dataLine('balance.assets.long.nonfarming.amount', { displayAsProjection: true }),
              ],
            },
          ],
        },
        dataLine('balance.assets.total', { variant: 'total', closingSection: true }),
        {
          ...headerLine('table.liabilities.section', { symbol: 'minus' }),
          children: [
            {
              ...dataLine('balance.liabilities.short'),
              children: [
                dataLine('balance.liabilities.short.banking', { displayAsProjection: true }),
                dataLine('balance.liabilities.short.credit'),
                dataLine('balance.liabilities.short.payables'),
                dataLine('balance.liabilities.short.loans', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('balance.liabilities.loans'),
              children: [
                dataLine('balance.liabilities.loans.medium', { displayAsProjection: true }),
                dataLine('balance.liabilities.loans.long', { displayAsProjection: true }),
              ],
            },
          ],
        },
        dataLine('balance.liabilities.total', { variant: 'total', closingSection: true }),
        {
          ...dataLine('balance.net.total', { variant: 'finalTotal', symbol: 'total' }),
          children: [
            {
              ...dataLine('balance.validation', { variant: 'validatePass' }),
              children: [
                dataLine('balance.validation.profit', { variant: 'subValidatePass' }),
                dataLine('balance.validation.inflation', { variant: 'subValidatePass' }),
                dataLine('balance.validation.withdrawals', { variant: 'subValidatePass', negated: true }),
                dataLine('balance.validation.contributions', { variant: 'subValidatePass' }),
                dataLine('balance.validation.other-movements', { variant: 'subValidatePass' }),
              ],
            },
          ],
        },
        dataLine('balance.owners.assets.total', { variant: 'finalTotal' }),
      ],
    },
    {
      id: 'balance.exclusions.section',
      children: [
        {
          ...headerLine('table.balance.exclusions', { symbol: 'none' }),
          children: [
            dataLine('balance.exclusions.residence.amount', { variant: 'group' }),
            dataLine('balance.exclusions.nonfarming.assets.amount', { variant: 'group' }),
            dataLine('balance.exclusions.buildings.loss.amount', {
              variant: 'group',
              closingSection: true,
              negated: true,
            }),
          ],
        },
      ],
    },
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true },
    {
      id: 'historic',
      type: 'historic',
      sticky: true,
      withDividerRight: true,
      displayOnTableState: ['selected-historic', 'print-with-reference'],
    },
    {
      id: 'all-historic',
      type: 'all-historic',
      sticky: true,
      withDividerRight: true,
      displayOnTableState: ['historic'],
    },
    {
      id: 'divider',
      type: 'divider',
      className: 'w-1',
      displayOnTableState: ['selected-historic', 'historic', 'print-with-reference'],
    },
    {
      id: 'projections',
      type: 'projections-with-proportions',
      withDividerRight: true,
      topHeaderClassName: 'w-36',
      className: 'w-24',
    },
  ],
  defaultState: 'selected-historic',
  expendedState: 'historic',
} as const satisfies Except<
  BlueprintTable<'selected-historic' | 'historic' | 'print-with-reference' | 'print-without-reference'>,
  'version'
>
