import { type ButtonHTMLAttributes, forwardRef } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'
import { type NavigationHandler, type NavigationItem } from '../../navigation/types'

const tabTriggerVariants = cva(
  'group h-[40px] text-left min-w-36 flex pt-2 pb-1.5 pl-4 pr-6 shrink leading-3 rounded-t-lg w-fit select-none focus-visible:outline-none transition-all disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-sage-200',
        blue: 'bg-sky-200',
      },
      active: {
        true: 'bg-white border border-white',
        false: 'border',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
    compoundVariants: [
      {
        variant: 'default',
        active: true,
        class: 'ring-offset-sage-400',
      },
      {
        variant: 'blue',
        active: true,
        class: 'ring-offset-sky-400',
      },
      {
        variant: 'default',
        active: false,
        class: 'border-sage-100',
      },
      {
        variant: 'blue',
        active: false,
        class: 'border-sky-100',
      },
    ],
  }
)

export interface TabTriggerProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof tabTriggerVariants> {
  readonly title: string
  readonly item: NavigationItem
  readonly onNavigationChange: NavigationHandler
}

export const TabTrigger = forwardRef<HTMLButtonElement, TabTriggerProps>(
  ({ className, variant = 'default', active = false, title, item, onNavigationChange, ...props }, ref) => (
    <button
      type="button"
      className={cn(tabTriggerVariants({ variant, active }), className)}
      onClick={active ? undefined : () => onNavigationChange(item.route)}
      ref={ref}
      translate="no"
      {...props}>
      <div className="max-w-44">
        <p className="text-left text-xxs font-light text-black">{title}</p>
        <p className="truncate text-sm font-medium leading-none text-black">{item.label}</p>
      </div>
    </button>
  )
)
