import React, { type PropsWithChildren } from 'react'

import { cn } from '../../lib/utils'

export interface TextTabTriggerProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, PropsWithChildren {
  readonly title: string
  readonly active: boolean
}

export const TextTabTrigger = React.forwardRef<HTMLDivElement, TextTabTriggerProps>(
  ({ className, active = false, title, children, ...props }, ref) => (
    <div className="flex flex-col gap-2">
      <div
        className={cn(
          'group flex h-11 w-fit min-w-32 shrink select-none flex-col gap-1 pb-1.5 pr-4 pt-2 transition-all focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50',
          className
        )}
        ref={ref}>
        <div className="w-full px-2">
          <button type="button" onClick={() => {}} className="w-full" {...props}>
            <div>
              <p className="text-center text-base font-bold leading-none tracking-tight text-black">{title}</p>
            </div>
          </button>
        </div>
        {active && <div className="h-2 bg-blue-700" />}
      </div>
    </div>
  )
)
