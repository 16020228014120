import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { max } from 'lodash-es'

import { Label } from '../../atoms/Label/Label.tsx'
import { type MilkAnimalsViewMovementsTabs } from '../../blueprints/milkAnimalsMovementsBlueprint.ts'
import { MonetaryTableEditingStateProvider } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import { type NavigationHandler } from '../../navigation'
import { MonetaryTable, type MonetaryTableActions } from '../../organisms'
import {
  ScenarioMainLayout,
  type ScenarioMainLayoutProps,
} from '../../organisms/ScenarioMainLayout/ScenarioMainLayout.tsx'
import { type BudgetData, type ScenarioData } from '../../types.ts'

import { useMilkAnimalsBlueprint } from './useMilkAnimalsBlueprint.ts'

export interface MilkAnimalsViewProps
  extends Pick<
      ScenarioMainLayoutProps,
      | 'onCloseBudget'
      | 'onRequestOwnership'
      | 'onRefreshScenarioReference'
      | 'onUpdateBudget'
      | 'syncRequired'
      | 'onUpdateScenarioReferences'
    >,
    Required<Pick<MonetaryTableActions, 'onUpdateCell'>> {
  readonly budget: BudgetData
  readonly scenario: ScenarioData
  readonly onNavigationChange: NavigationHandler
  readonly onTabChange?: (tab: MilkAnimalsViewMovementsTabs) => void
  readonly loading: boolean
  readonly offline: boolean
}

export const MilkAnimalsView: FC<MilkAnimalsViewProps> = ({
  budget,
  scenario,
  syncRequired,
  offline,
  loading,
  onCloseBudget,
  onNavigationChange,
  onRequestOwnership,
  onRefreshScenarioReference,
  onTabChange,
  onUpdateScenarioReferences,
  onUpdateBudget,
  onUpdateCell,
  ...tableProps
}) => {
  const intl = useIntl()
  const { averagesBlueprint, animalsBlueprint, movementsBlueprint, inventorySummaryBlueprint } =
    useMilkAnimalsBlueprint(scenario)

  return (
    <MonetaryTableEditingStateProvider>
      <ScenarioMainLayout
        currentScenarioNavigationId="milk-animals"
        budget={budget}
        scenario={scenario}
        syncRequired={syncRequired}
        offline={offline ?? false}
        onCloseBudget={onCloseBudget}
        onRequestOwnership={onRequestOwnership}
        onUpdateBudget={onUpdateBudget}
        onNavigationChange={onNavigationChange}
        onRefreshScenarioReference={onRefreshScenarioReference}
        onUpdateScenarioReferences={onUpdateScenarioReferences}
        loading={loading}>
        <div className="pb-8 pl-32">
          <div className="sticky left-36 pl-8 pt-6">
            <div className="flex flex-col pr-8">
              <Label className="text-2xl font-normal text-black">
                {intl.formatMessage({ id: 'milk.animals.title' })}
              </Label>
              <Label className="text-2xl font-bold text-black">
                {intl.formatMessage({ id: 'milk.animals.subTitle' })}
              </Label>
            </div>
          </div>
          <MonetaryTable
            selectedReferenceYear={scenario.selectedReferenceYear}
            references={scenario.referenceYears}
            projections={scenario.period.projectionYears}
            readonly={budget.readonly}
            blueprint={averagesBlueprint}
            onUpdateCell={onUpdateCell}
            className="pt-5"
          />
          <MonetaryTable
            referenceLabel={
              scenario.referenceType === 'empty'
                ? (scenario.customReferenceName ?? '')
                : (scenario.selectedReferenceYear ?? '')
            }
            selectedReferenceYear={scenario.selectedReferenceYear}
            references={scenario.referenceYears}
            projections={scenario.period?.projectionYears}
            readonly={budget.readonly}
            readonlyReferences
            blueprint={animalsBlueprint}
            onUpdateCell={onUpdateCell}
            className="pt-5"
            {...tableProps}
          />
          <MonetaryTable
            selectedReferenceYear={scenario.selectedReferenceYear}
            referenceLabel={
              scenario.referenceType === 'empty'
                ? (scenario.customReferenceName ?? '')
                : (max(scenario.referenceYears) ?? '')
            }
            references={scenario.referenceYears}
            projections={scenario.period.projectionYears}
            readonly={budget.readonly}
            blueprint={movementsBlueprint}
            onTabChange={onTabChange}
            onUpdateCell={onUpdateCell}
            className="pt-5"
          />
          <MonetaryTable
            selectedReferenceYear={scenario.selectedReferenceYear}
            referenceLabel={
              scenario.referenceType === 'empty'
                ? (scenario.customReferenceName ?? '')
                : (scenario.selectedReferenceYear ?? '')
            }
            references={scenario.referenceYears}
            projections={scenario.period.projectionYears}
            readonly={budget.readonly}
            blueprint={inventorySummaryBlueprint}
            className="pt-1"
          />
        </div>
      </ScenarioMainLayout>
    </MonetaryTableEditingStateProvider>
  )
}
