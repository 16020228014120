import type React from 'react'

import { type NavigationHandler } from '../../navigation'
import { ScenarioMainHeader } from '../../organisms/ScenarioMainHeader/ScenarioMainHeader'
import { type BudgetData } from '../../types'

interface ScenarioLoadingViewProps {
  readonly budget: BudgetData
  readonly onNavigationChange: NavigationHandler
}

export const ScenarioLoadingView: React.FC<ScenarioLoadingViewProps> = ({ budget, onNavigationChange }) => (
  <ScenarioMainHeader
    budget={budget}
    offline={false}
    onUpdateBudget={() => Promise.resolve()}
    currentScenarioNavigationId="create"
    onNavigationChange={onNavigationChange}
    loading
  />
)
