import { type BlueprintVersion } from '../../../enums'
import { DataAttributes } from '../attributes'
import { type ComputationTypeDataKey } from '../computation-type-data-key'
import { type DataAttribute } from '../types'

import { isResolvedComputationTypeDataKey } from './is-resolved-computation-type-data-key'

export const isGrowthEnabled = (
  key: string,
  blueprintVersion?: BlueprintVersion
): key is ComputationTypeDataKey<'grbf'> => {
  if (!isResolvedComputationTypeDataKey(key, blueprintVersion, 'grbf')) {
    return false
  }
  const projectionAttribute = DataAttributes[key] as Extract<DataAttribute, { type: 'grbf' }>
  return projectionAttribute.growth ?? true
}
