import { useMemo } from 'react'

import {
  dataKeyDependenciesRecord,
  type InventoryComputationType,
  type InventoryDataKey,
  type YearKey,
} from '@via/schema'
import { mapValues, omit } from 'lodash-es'

import { useMonetaryRowsProjectionCellData } from '../../context/monetary/useMonetaryRowsProjectionCellData.ts'
import { useScenarioSingleRowData } from '../../context/scenario/useScenarioSingleRowData.ts'

interface UseInventoryProjectionDataOptions {
  readonly dataKey: InventoryDataKey
  readonly year: YearKey
}

export type UseInventoryProjectionDataResult = Partial<Record<InventoryComputationType | 'note', string>>

export const useInventoryProjectionData = ({
  dataKey,
  year,
}: UseInventoryProjectionDataOptions): UseInventoryProjectionDataResult => {
  const { unitValue } = useMonetaryRowsProjectionCellData({
    year,
    keys: dataKeyDependenciesRecord(dataKey),
  })
  const inventoryRow = useScenarioSingleRowData<'inventory'>(dataKey)
  return useMemo(() => {
    const cell = inventoryRow?.values[year]
    return cell
      ? {
          ...mapValues(omit(cell, 'unitValue'), (value) => (value.value !== '0' ? value.value : '')),
          unitValue: unitValue?.override?.value,
          note: cell?.value?.note,
        }
      : {}
  }, [inventoryRow?.values, unitValue?.override?.value, year])
}
