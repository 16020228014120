import type React from 'react'

import { FinanceView } from '@via/components'

import { useScenarioEditableFinancePage } from './useScenarioEditableFinancePage.ts'
import { useScenarioReadonlyFinancePage } from './useScenarioReadonlyFinancePage.ts'

const ScenarioEditableFinancePage: React.FC = () => {
  const props = useScenarioEditableFinancePage()

  return <FinanceView key={props.scenario._id} {...props} />
}

const ScnearioReadonlyFinancePage: React.FC = () => {
  const props = useScenarioReadonlyFinancePage()

  return <FinanceView key={props.scenario._id} snapshot {...props} />
}

export const ScenarioFinancePage: React.FC<{ readonly readonly: boolean }> = ({ readonly }) =>
  readonly ? <ScnearioReadonlyFinancePage /> : <ScenarioEditableFinancePage />
