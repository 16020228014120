import { DataAttributes, type DataKey } from '@via/schema'

import { type BlueprintDataRow, type BlueprintDividerRow, type BlueprintHeaderRow } from './blueprint'

export const dataLine = (
  id: DataKey,
  { variant = 'default', titleKey = `table.${id}`, ...rest }: Partial<Omit<BlueprintDataRow, 'id' | 'children'>> = {}
): BlueprintDataRow => {
  const attributes = DataAttributes[id]
  return {
    id,
    variant,
    titleKey,
    ...rest,
    ...attributes,
  }
}

export const headerLine = (
  titleKey: BlueprintHeaderRow['titleKey'],
  { symbol = 'empty', ...rest }: Partial<Pick<BlueprintHeaderRow, 'symbol' | 'addMonetaryItemButtonType'>> = {}
): BlueprintHeaderRow => ({
  id: titleKey,
  variant: 'head',
  titleKey,
  symbol,
  ...rest,
})

export const dividerLine = (
  props?: Pick<BlueprintDividerRow, 'type' | 'border'>,
  id = 'divider'
): BlueprintDividerRow => ({
  id,
  variant: 'divider',
  ...props,
})
