import { Fragment, type ReactNode, useContext } from 'react'

import { cn } from '../../lib/utils'

import { MonetaryTablePropsContext } from './contexts/MonetaryTablePropsContext.ts'
import { type MonetaryTableColumn } from './types'

export const MonetaryTableStickyColumns = <TableStates extends string>({
  columns,
  className,
  children,
}: {
  readonly columns: ReadonlyArray<MonetaryTableColumn<TableStates>>
  readonly className?: string
  readonly children: (column: MonetaryTableColumn<TableStates>) => ReactNode
}) => {
  const { stickyLeft } = useContext(MonetaryTablePropsContext)

  return (
    <div
      className={cn('sticky z-10 flex shrink-0 bg-white print:static', className)}
      style={{ left: stickyLeft }}
      id="sticky-monetary-table-left-elements">
      <div className={cn('w-8', className)} />
      {columns.map((column) => (
        <Fragment key={column.id}>{children(column)}</Fragment>
      ))}
    </div>
  )
}
