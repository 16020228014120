import { type FC, useState } from 'react'

import { Editor, type IAllProps } from '@tinymce/tinymce-react'
import { type Except } from 'type-fest'

import './index.css'

export interface BundledEditorProps extends Except<IAllProps, 'onChange'> {
  onChange: (content: string) => void
  onImageUpload?: (fileBlob: File) => Promise<string>
  height?: number
}

// From tinymce-react, as it's not exported
interface BlobInfo {
  id: () => string
  name: () => string
  filename: () => string
  blob: () => Blob
  base64: () => string
  blobUri: () => string
  uri: () => string | undefined
}

export const BundledEditor: FC<BundledEditorProps> = ({ onChange, onImageUpload, value, height, ...props }) => {
  const [initialValue] = useState(value)

  const init = {
    plugins: 'advlist quickbars image lists',
    language: 'fr_FR',
    menubar: 'edit insert format image',
    toolbar:
      'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | quickimage',
    quickbars_insert_toolbar: false, // Disables the insert image or table in the editor (quickbars plugin)
    object_resizing: true,
    menu: {
      format: {
        title: 'Format',
        items:
          'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontsize align lineheight | forecolor backcolor | removeformat',
      },
    },
    branding: false,
    promotion: false,
    height: height ?? 792,
    images_upload_handler: (blobInfo: BlobInfo) => {
      if (!onImageUpload) {
        throw new Error('An onImageUpload property must be defined to support image uploads')
      }

      // Inspiration: https://github.com/tinymce/tinymce/issues/8212
      // Solely using blobInfo.blob() doesn't work, the uploaded image is one of a "broken" file of 9 bytes only
      // @ts-expect-error In reality, name is in blob
      const fileName = 'name' in blobInfo.blob() ? String(blobInfo.blob().name) : blobInfo.name()
      const file = new File([blobInfo.blob()], fileName, { type: blobInfo.blob().type })
      return onImageUpload(file)
    },
  } as const

  return (
    <Editor
      inline
      licenseKey="gpl"
      init={init}
      onEditorChange={onChange}
      onInit={
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Typing the handler's parameters makes TS realize it does not comply to EEventHandler<'init'>, which is not exported by tinymce
        (_event, editor) => {
          editor.on('OpenWindow', () => {
            const imageDialogButtons = document.querySelectorAll('.tox-dialog__body-nav-item.tox-tab')
            const selectedElement = editor.selection.getSel()
            if (!selectedElement || selectedElement.type === 'Caret') {
              // No image selected, click on "Télécharger" tab of image upload dialog
              // eslint-disable-next-line unicorn/no-lonely-if
              if (imageDialogButtons.length === 2 && imageDialogButtons[1] instanceof HTMLDivElement) {
                imageDialogButtons[1].click()
              }
            }
          })
        }
      }
      initialValue={initialValue}
      {...props}
    />
  )
}
