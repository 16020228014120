import { type BlueprintVersion, type DataKey, type OverridableReferenceDataKey, type YearKey } from '@via/schema'
import { type Except } from 'type-fest'

import { type NumberDisplayType } from '../../hooks/useFormatNumber'
import {
  type MonetaryCellData,
  type MonetaryRowData,
  type MonetaryValueCellType,
  type OverridableMonetaryCellData,
} from '../../model'

import { EditableGrowthValueCell } from './EditableGrowthValueCell'
import { EditableInventoryProjectionCell } from './EditableInventoryProjectionCell.tsx'
import { EditableMovementProjectionCell } from './EditableMovementProjectionCell.tsx'
import { EditableOverridableReferenceValueCell } from './EditableOverridableReferenceValueCell.tsx'
import { EditableProjectionValueCell } from './EditableProjectionValueCell'
import { EditableReferenceValueCell } from './EditableReferenceValueCell'
import { InvestmentDetailValueCell } from './InvestmentDetailValueCell'
import { LoanDetailValueCell } from './LoanDetailValueCell'
import { MilkExpensesValueCell } from './MilkExpensesValueCell.tsx'
import { MilkIncomeValueCell } from './MilkIncomeValueCell.tsx'
import { MilkQuotaValueCell } from './MilkQuotaValueCell.tsx'
import { MonetaryReadonlyValueCell, type MonetaryReadonlyValueCellProps } from './MonetaryReadonlyValueCell'
import { type UpdateCellValue } from './UpdateCellValue.ts'

type OverridableMonetaryValueCellProps<Type extends MonetaryValueCellType, OnUpdate> = {
  readonly type: Type
  readonly weight?: DataKey
} & (
  | {
      readonly editable: true
      readonly cellData: OverridableMonetaryCellData
      readonly onUpdate: OnUpdate
    }
  | {
      readonly editable?: boolean
      readonly cellData?: OverridableMonetaryCellData
      readonly onUpdate?: OnUpdate
    }
  | {
      readonly editable: false
      readonly cellData?: OverridableMonetaryCellData
      readonly onUpdate?: never
    }
)

export type MonetaryValueCellProps = {
  readonly dataKey: DataKey
  readonly rowData: MonetaryRowData
  readonly blueprintVersion: BlueprintVersion
  readonly weight?: string
  readonly year?: YearKey
} & Except<MonetaryReadonlyValueCellProps, 'cellData'> &
  (
    | OverridableMonetaryValueCellProps<'reference', UpdateCellValue<'reference'>>
    | OverridableMonetaryValueCellProps<'overridable-reference', UpdateCellValue<'overridable-reference'>>
    | OverridableMonetaryValueCellProps<'growth', UpdateCellValue<'growth'>>
    | OverridableMonetaryValueCellProps<'result-projections', UpdateCellValue<'result-projections'>>
    | OverridableMonetaryValueCellProps<'movement-overridable', UpdateCellValue<'movement-overridable'>>
    | OverridableMonetaryValueCellProps<'computation-details', UpdateCellValue<'computation-details'>>
    | {
        readonly type: 'inventory'
        readonly editable: boolean
        readonly cellData?: MonetaryCellData
        readonly onUpdate?: UpdateCellValue<'inventory'>
        readonly year: YearKey
      }
    | {
        readonly type:
          | 'finance-investment'
          | 'finance-loan-investment'
          | 'finance-loan'
          | 'finance-investment-inventory'
        readonly editable: boolean
        readonly cellData?: MonetaryCellData
        readonly onUpdate?: never
      }
    | {
        readonly type: 'milk-quota' | 'milk-income' | 'milk-expenses'
        readonly editable: boolean
        readonly cellData?: MonetaryCellData
        readonly onUpdate?: never
        readonly year: YearKey
      }
    | {
        readonly type: 'readonly'
        readonly editable: false
        readonly cellData?: MonetaryCellData
        readonly onUpdate?: never
        readonly numberDisplayType?: NumberDisplayType
      }
    | {
        readonly type: 'proportion'
        readonly editable: boolean
        readonly cellData?: MonetaryCellData
        readonly onUpdate?: never
        readonly numberDisplayType?: NumberDisplayType
      }
  )

export const MonetaryValueCell = ({
  dataKey,
  blueprintVersion,
  editable,
  cellData,
  rowData,
  type,
  onUpdate,
  weight,
  year,
  ...props
}: MonetaryValueCellProps) => {
  switch (true) {
    case type === 'finance-investment':
    case type === 'finance-loan-investment':
    case type === 'finance-investment-inventory':
      return (
        <InvestmentDetailValueCell rowData={rowData} cellData={cellData} disabled={!editable} type={type} {...props} />
      )
    case type === 'finance-loan':
      return <LoanDetailValueCell rowData={rowData} cellData={cellData} disabled={!editable} {...props} />
    case type === 'milk-quota':
      return <MilkQuotaValueCell year={year} cellData={cellData} {...props} />
    case type === 'milk-income':
      return <MilkIncomeValueCell year={year} cellData={cellData} {...props} />
    case type === 'milk-expenses':
      return <MilkExpensesValueCell year={year} cellData={cellData} {...props} />
    case !editable:
    case !onUpdate:
    case !cellData:
      return <MonetaryReadonlyValueCell cellData={cellData} {...props} />
    case type === 'inventory':
      return (
        <EditableInventoryProjectionCell
          year={year}
          rowData={rowData}
          cellData={cellData}
          onUpdateCell={onUpdate}
          disabled={!editable}
          {...props}
        />
      )
    case type === 'growth':
      return <EditableGrowthValueCell cellData={cellData} onUpdateCell={onUpdate} dataKey={dataKey} {...props} />
    case type === 'result-projections':
      return (
        <EditableProjectionValueCell
          cellData={cellData}
          onUpdateCell={onUpdate}
          dataKey={dataKey}
          blueprintVersion={blueprintVersion}
          weight={weight}
          {...props}
        />
      )
    case type === 'reference':
      return <EditableReferenceValueCell rowData={rowData} cellData={cellData} onUpdateCell={onUpdate} {...props} />
    case type === 'overridable-reference':
      return (
        <EditableOverridableReferenceValueCell
          dataKey={dataKey as OverridableReferenceDataKey}
          cellData={cellData}
          onUpdateCell={onUpdate}
          {...props}
        />
      )
    case type === 'movement-overridable':
      return <EditableMovementProjectionCell rowData={rowData} cellData={cellData} onUpdateCell={onUpdate} {...props} />
    default:
      return <MonetaryReadonlyValueCell cellData={cellData} {...props} />
  }
}
