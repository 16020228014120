import type React from 'react'
import { useWatch } from 'react-hook-form'

import { Icons } from '../../atoms'
import { Button } from '../../atoms/Button/Button'
import { FormSubmitButtons } from '../../atoms/Form/FormSubmitButtons'

import { type LoanDetailFormData } from './LoanFormProvider'

export interface LoanFormHeaderProps {
  readonly defaultLoanLabel: string
  readonly disabled?: boolean
  readonly onDelete?: () => Promise<void> | void
}

export const LoanFormHeader: React.FC<LoanFormHeaderProps> = ({ defaultLoanLabel, disabled, onDelete }) => {
  const [name, source] = useWatch<LoanDetailFormData, ['name', 'source']>({ name: ['name', 'source'] })

  return (
    <div className="flex justify-between">
      <p className="select-none text-2xl font-light leading-tight tracking-[-0.48px] text-black">
        {source === 'scenario' && !name ? defaultLoanLabel : name}
      </p>
      <div className="flex items-center gap-6">
        {onDelete && !disabled && (
          <Button dimension="xs" shape="iconOnly" variant="outline" onClick={onDelete}>
            <Icons.Trash className="size-4" />
          </Button>
        )}
        <FormSubmitButtons disabled={disabled} className="flex gap-2" />
      </div>
    </div>
  )
}
