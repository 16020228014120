import { Suspense, useContext } from 'react'

import { createFileRoute, Outlet } from '@tanstack/react-router'

import { useOfflineState } from '../contexts/offlineState/useOfflineState.ts'
import { RxdbScenarioListProvider } from '../contexts/scenarioList/RxdbScenarioListProvider.tsx'
import { FirestoreBudgetReportSnapshotContext } from '../firestore/snapshot/FirestoreBudgetReportSnaphotProvider.tsx'
import { LoadingReportsPage } from '../pages/loadingReports/LoadingReportsPage.tsx'
import { OfflineReportsPage } from '../pages/offlineReports/OfflineReportsPage.tsx'

export const ReportsComponent = () => {
  const { budgetId } = Route.useParams()
  const { isLoading } = useContext(FirestoreBudgetReportSnapshotContext)
  const { offline } = useOfflineState()

  switch (true) {
    case offline:
      return <OfflineReportsPage />
    case isLoading:
      return <LoadingReportsPage />
    default:
      return (
        <Suspense fallback={<LoadingReportsPage />}>
          <RxdbScenarioListProvider budgetId={budgetId}>
            <Outlet />
          </RxdbScenarioListProvider>
        </Suspense>
      )
  }
}

export const Route = createFileRoute('/budgets/$budgetId/reports')({
  component: ReportsComponent,
})
