import { type QueryClient } from '@tanstack/react-query'
import { createFileRoute, redirect } from '@tanstack/react-router'
import { collections } from '@via/frontend-schema'
import { sortBy } from 'lodash-es'

import { type QuerySnapshotState } from '../firestore/hooks/types.ts'
import { type FirestoreBudgetReportDocument } from '../firestore/snapshot/FirestoreBudgetReportSnaphotProvider.tsx'

const findFirstReport = async (queryClient: QueryClient, budgetId: string) => {
  const queryData = queryClient.getQueryData<QuerySnapshotState<FirestoreBudgetReportDocument>>(['reports', budgetId])
  if (queryData && !queryData.isLoading && queryData.data.length > 0) {
    const [report] = sortBy(queryData.data, 'creationTimestamp')
    return report
  }

  return collections.budgets(budgetId).reports.findUnique({
    name: `budget ${budgetId} report list`,
    orderBy: [['creationTimestamp']],
    limit: 1,
  })
}

export const Route = createFileRoute('/budgets/$budgetId/reports/')({
  beforeLoad: async ({ context: { queryClient, budget, budgetReportsHandler }, params: { budgetId } }) => {
    const firstReport = await findFirstReport(queryClient, budgetId)

    if (firstReport) {
      throw redirect({
        from: '/budgets/$budgetId/reports/',
        to: '/budgets/$budgetId/reports/$reportId',
        params: { reportId: firstReport._id },
        replace: true,
      })
    }

    const scenarios = Object.keys(budget.scenarios)
    const report = await budgetReportsHandler.createBudgetReport(budgetId, {
      name: '',
      scenarios,
      years: [],
      includesReference: false,
    })

    throw redirect({
      from: '/budgets/$budgetId/reports/',
      to: '/budgets/$budgetId/reports/$reportId',
      params: { reportId: report._id },
      replace: true,
    })
  },
})
