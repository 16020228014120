import { useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Icons } from '../../atoms'
import { Divider } from '../../atoms/Divider/Divider'
import { Label } from '../../atoms/Label/Label'
import { cn } from '../../lib/utils'
import { CheckBoxWithLabelFormField } from '../../molecules/CheckboxWithLabel/CheckboxFormField'

import { type ReportFormData } from './ReportFormZod.ts'

type SubSectionStates =
  | { display: false; subsection: '' }
  | { display: true; subsection: 'loans' }
  | { display: true; subsection: 'shortTerms' }

export const ReportConfigurationFormSections = () => {
  const intl = useIntl()
  const [subsectionState, setSubsectionState] = useState<SubSectionStates>({ display: false, subsection: '' })

  const [financeSectionEnabled, loansEnabled, shortTermsEnabled] = useWatch<
    ReportFormData,
    ['sections.finance.enabled', 'sections.finance.loans.enabled', 'sections.finance.shortTerms.enabled']
  >({
    name: ['sections.finance.enabled', 'sections.finance.loans.enabled', 'sections.finance.shortTerms.enabled'],
  })

  return (
    <div className="flex gap-6 pb-24 pt-12">
      <div className="flex w-80 flex-col gap-4">
        <p className="text-xl font-bold">{intl.formatMessage({ id: 'reportConfiguration.form.sectionsTitle' })}</p>
        <CheckBoxWithLabelFormField<ReportFormData, 'sections.results.enabled'>
          name="sections.results.enabled"
          id="sections.results.enabled"
          label={intl.formatMessage({ id: 'reportConfiguration.form.resultsSection' })}
        />
        <Divider />
        <CheckBoxWithLabelFormField<ReportFormData, 'sections.repayment.enabled'>
          name="sections.repayment.enabled"
          id="sections.repayment.enabled"
          label={intl.formatMessage({ id: 'reportConfiguration.form.repaymentSection' })}
        />
        <Divider />
        <CheckBoxWithLabelFormField<ReportFormData, 'sections.balance.enabled'>
          name="sections.balance.enabled"
          id="sections.balance.enabled"
          label={intl.formatMessage({ id: 'reportConfiguration.form.balanceSection' })}
        />
        <Divider />
        <div>
          <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.enabled'>
            name="sections.finance.enabled"
            id="sections.finance.enabled"
            label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection' })}
            onCheckedChange={(state) => {
              if ((state === false || state === 'indeterminate') && subsectionState.display) {
                setSubsectionState({ display: false, subsection: '' })
              }
            }}
          />
          <div
            className={cn(
              'duration-250 flex max-h-0 flex-col gap-2 pt-3 opacity-0 transition-all',
              financeSectionEnabled ? 'max-h-36 opacity-100' : ''
            )}>
            <div className="flex items-center justify-between rounded border border-blue-200 p-2">
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.investments.enabled'>
                name="sections.finance.investments.enabled"
                id="sections.finance.investments.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection.investments' })}
              />
            </div>
            <div
              className={cn(
                'duration-250 flex items-center justify-between rounded border border-blue-200 p-2 transition-all',
                subsectionState.subsection === 'loans' ? 'bg-blue-200' : ''
              )}>
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.loans.enabled'>
                name="sections.finance.loans.enabled"
                id="sections.finance.loans.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection.loans' })}
                onCheckedChange={(state) => {
                  if ((state === false || state === 'indeterminate') && subsectionState.subsection === 'loans') {
                    setSubsectionState({ display: false, subsection: '' })
                  } else if (state) {
                    setSubsectionState({ display: true, subsection: 'loans' })
                  }
                }}
              />
              <div
                onClick={() => {
                  setSubsectionState({ display: true, subsection: 'loans' })
                }}
                className={cn(
                  'duration-250 max-w-0 cursor-pointer opacity-0 transition-all',
                  loansEnabled ? 'max-w-8 opacity-100' : ''
                )}>
                <Icons.ChevronRight className="text-black" />
              </div>
            </div>
            <div
              className={cn(
                'duration-250 flex items-center justify-between rounded border border-blue-200 p-2 transition-all',
                subsectionState.subsection === 'shortTerms' ? 'bg-blue-200' : ''
              )}>
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.shortTerms.enabled'>
                name="sections.finance.shortTerms.enabled"
                id="sections.finance.shortTerms.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.shortTerms.invesments' })}
                onCheckedChange={(state) => {
                  if ((state === false || state === 'indeterminate') && subsectionState.subsection === 'shortTerms') {
                    setSubsectionState({ display: false, subsection: '' })
                  } else if (state) {
                    setSubsectionState({ display: true, subsection: 'shortTerms' })
                  }
                }}
              />
              <div
                onClick={() => {
                  setSubsectionState({ display: true, subsection: 'shortTerms' })
                }}
                className={cn(
                  'duration-250 max-w-0 cursor-pointer opacity-0 transition-all',
                  shortTermsEnabled ? 'max-w-8 opacity-100' : ''
                )}>
                <Icons.ChevronRight className="text-black" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn(
          'flex w-80 flex-col gap-4 border border-gray-300 p-4 opacity-0',
          subsectionState.display ? 'opacity-100' : ''
        )}>
        {subsectionState.subsection === 'loans' && (
          <>
            <Label className="text-xl font-bold">
              {intl.formatMessage({ id: 'reportConfiguration.form.financeSection.loans' })}
            </Label>
            <div className="flex flex-col gap-6">
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.loans.disbursements.enabled'>
                name="sections.finance.loans.disbursements.enabled"
                id="sections.finance.loans.disbursements.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection.loans.disbursements' })}
              />
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.loans.repayment.enabled'>
                name="sections.finance.loans.repayment.enabled"
                id="sections.finance.loans.repayment.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection.loans.repayment' })}
              />
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.loans.annuity.enabled'>
                name="sections.finance.loans.annuity.enabled"
                id="sections.finance.loans.annuity.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection.loans.anuity' })}
              />
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.loans.interest.enabled'>
                name="sections.finance.loans.interest.enabled"
                id="sections.finance.loans.interest.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection.loans.interest' })}
              />
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.loans.capital.enabled'>
                name="sections.finance.loans.capital.enabled"
                id="sections.finance.loans.capital.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection.loans.capital' })}
              />
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.loans.projectedCapital.enabled'>
                name="sections.finance.loans.projectedCapital.enabled"
                id="sections.finance.loans.projectedCapital.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection.loans.projectedCapital' })}
              />
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.loans.exigible.enabled'>
                name="sections.finance.loans.exigible.enabled"
                id="sections.finance.loans.exigible.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection.loans.exigible' })}
              />
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.loans.endingBalance.enabled'>
                name="sections.finance.loans.endingBalance.enabled"
                id="sections.finance.loans.endingBalance.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection.loans.endingBalance' })}
              />
            </div>
          </>
        )}

        {subsectionState.subsection === 'shortTerms' && (
          <>
            <Label className="text-xl font-bold">
              {intl.formatMessage({ id: 'reportConfiguration.form.shortTerms.invesments' })}
            </Label>
            <div className="flex flex-col gap-6">
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.shortTerms.movement.enabled'>
                name="sections.finance.shortTerms.movement.enabled"
                id="sections.finance.shortTerms.movement.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection.shortTerm.movement' })}
              />
              <CheckBoxWithLabelFormField<ReportFormData, 'sections.finance.shortTerms.balance.enabled'>
                name="sections.finance.shortTerms.balance.enabled"
                id="ections.finance.shortTerms.balance.enabled"
                label={intl.formatMessage({ id: 'reportConfiguration.form.financeSection.shortTerm.balance' })}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
