import { createFileRoute } from '@tanstack/react-router'

import { ScenarioRepaymentCapacityPage } from '../pages/scenarioRepaymentCapacity/ScenarioRepaymentCapacityPage.tsx'
import { setScenarioCurrentPage } from '../pages/scenarioUpdate/utils/scenarioPage.ts'

export const Route = createFileRoute('/budgets/$budgetId/scenarios/$scenarioId/repayment')({
  component: ScenarioRepaymentCapacityPage,
  loader: ({ params }) => {
    setScenarioCurrentPage(params.budgetId, params.scenarioId, 'repayment')
  },
})
