import { type OverridableCellData } from '@via/compute'
import { isEmpty, sortBy } from 'lodash-es'

import { type MonetaryCellStatus, type OverridableMonetaryCellData } from '../MonetaryRowData.ts'

import { overridableMonetaryCellData } from './cell'

export const reduceGrbfValueOverridableCellData = (
  cells: Record<string, OverridableCellData>,
  defaultStatus: MonetaryCellStatus = 'default',
  computeState?: (
    cellData: OverridableCellData,
    previousValue?: OverridableMonetaryCellData
  ) => Partial<OverridableMonetaryCellData>
) => {
  const { values } = sortBy(Object.entries(cells), ([key]) => key).reduce<{
    values: Record<string, OverridableMonetaryCellData>
    isPreviouslyOverridden: boolean
    previousValue?: OverridableMonetaryCellData
  }>(
    (acc, [key, cellData]) => {
      const isOverridden = !isEmpty(cellData.override)
      const value = {
        ...overridableMonetaryCellData(cellData),
        ...computeState?.(cellData, acc.previousValue),
        status: isOverridden ? 'overridden' : acc.isPreviouslyOverridden ? 'impacted' : defaultStatus,
      }
      return {
        values: {
          ...acc.values,
          [key]: value,
        },
        isPreviouslyOverridden: acc.isPreviouslyOverridden || isOverridden,
        previousValue: value,
      }
    },
    {
      isPreviouslyOverridden: false,
      values: {},
    }
  )
  return values
}
