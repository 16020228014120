import { useContext } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { RouterProvider } from '@tanstack/react-router'

import { useFirebaseAuth } from './auth/useFirebaseAuth.ts'
import { OfflineToastProvider } from './contexts/offlineState/OfflineToastProvider.tsx'
import { FirestoreAppSnapshotProvider } from './firestore/snapshot/FirestoreAppSnapshotProvider.tsx'
import { LoginPage } from './pages/signIn/LoginPage.tsx'
import { AppRxDatabaseContext } from './rxdb/contexts/AppRxDatabaseContext.ts'
import { AppRxStateContext } from './rxdb/contexts/AppRxStateContext.ts'
import { AppWorkerContext } from './worker/AppWorkerContext.ts'
import { useIsAppWorkerReady } from './worker/useIsAppWorkerReady.ts'
import { router } from './router.ts'

export const AppRouter = () => {
  const queryClient = useQueryClient()
  const appDatabase = useContext(AppRxDatabaseContext)
  const appRxState = useContext(AppRxStateContext)
  const appWorker = useContext(AppWorkerContext)
  const auth = useFirebaseAuth()
  const isAppWorkerReady = useIsAppWorkerReady()

  if (!auth.initialized || !isAppWorkerReady) {
    // TODO loading screen
    return null
  }

  if (!auth.user) {
    return <LoginPage />
  }

  return (
    <FirestoreAppSnapshotProvider>
      <OfflineToastProvider>
        <RouterProvider
          router={router}
          context={{
            queryClient,
            auth,
            appDatabase,
            appRxState,
            appWorker,
            budgetHandler: appWorker.budgetHandler,
            scenarioHandler: appWorker.scenarioHandler,
            budgetReportsHandler: appWorker.budgetReportsHandler,
          }}
        />
      </OfflineToastProvider>
    </FirestoreAppSnapshotProvider>
  )
}
