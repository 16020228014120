import type React from 'react'

import { ScenarioCreationFormProvider } from '../../organisms/ScenarioCreationForm/ScenarioCreationFormProvider'

import { ScenarioCreateViewLayout, type ScenarioCreateViewLayoutProps } from './ScenarioCreateViewLayout.tsx'

export type ScenarioCreateViewProps = ScenarioCreateViewLayoutProps

export const ScenarioCreateView: React.FC<ScenarioCreateViewProps> = ({ offline, currentProductions, ...props }) => (
  <ScenarioCreationFormProvider currentProductions={currentProductions} referenceType={offline ? 'empty' : 'viagritel'}>
    <ScenarioCreateViewLayout currentProductions={currentProductions} offline={offline} {...props} />
  </ScenarioCreationFormProvider>
)
