import { type ScenarioState } from '@via/compute'

import { milkAnimalsMovementsSheet2024Blueprint } from './2024/milkAnimalsMovementsSheet2024Blueprint.ts'

export const milkAnimalsMovementsBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...milkAnimalsMovementsSheet2024Blueprint }
  }
}

export { type MilkAnimalsViewMovementsTabs } from './2024/milkAnimalsMovementsSheet2024Blueprint.ts'
