import React from 'react'

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

import { cn } from '../../lib/utils'

export const RadioGroup = RadioGroupPrimitive.Root

export const Radio = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => (
  <RadioGroupPrimitive.Item
    ref={ref}
    className={cn(
      'h-4 w-4 rounded-full border border-gray-400 bg-white text-white shadow-md-inner hover:bg-gray-100 focus:outline-none focus-visible:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-0 data-[state=checked]:bg-blue-600 data-[state=checked]:hover:bg-blue-800 data-[state=checked]:focus-visible:bg-blue-800',
      className
    )}
    {...props}>
    <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
      <div className="size-1.5 rounded-full bg-white" />
    </RadioGroupPrimitive.Indicator>
  </RadioGroupPrimitive.Item>
))
