import { type FC, type PropsWithChildren } from 'react'

import { dataKeyDependenciesRecord, type YearKey } from '@via/schema'

import { useMonetaryRowsProjectionCellData } from '../../context/monetary/useMonetaryRowsProjectionCellData.ts'

import { MilkIncomeFormDependenciesContext } from './MilkIncomeFormDependenciesContext.ts'

export interface MilkIncomeFormDependenciesProviderProps {
  readonly year: YearKey
}

export const MilkIncomeFormDependenciesProvider: FC<PropsWithChildren<MilkIncomeFormDependenciesProviderProps>> = ({
  year,
  children,
}) => {
  const state = useMonetaryRowsProjectionCellData({
    year,
    keys: dataKeyDependenciesRecord('milk-income'),
  })

  return (
    <MilkIncomeFormDependenciesContext.Provider value={state}>{children}</MilkIncomeFormDependenciesContext.Provider>
  )
}
