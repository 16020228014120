import { type ProductionType } from '../../../enums'
import { type DataKey } from '../../data-key-zod'
import { type CompositeAttributesJson, OverridableAttributesJson } from '../../generated'

export interface OverridableDataKeyAttribute<Source extends DataKey = DataKey> {
  readonly type: 'overridable'
  readonly readonly: false
  readonly source: Source
  readonly production?: ProductionType
  readonly weights?: ReadonlyArray<DataKey>
}

export const OverridableDataKeyAttributes = OverridableAttributesJson as Record<
  keyof typeof OverridableAttributesJson,
  OverridableDataKeyAttribute<keyof typeof CompositeAttributesJson>
>

export type OverridableDataKey = keyof typeof OverridableDataKeyAttributes
