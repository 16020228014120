import { z } from 'zod'

import { UserRoleZod } from './user-role-zod'

export const UserUpdateZod = z.object({
  timestamp: z.date(),
  userId: z.string(),
  userName: z.string(),
  userRole: UserRoleZod,
})

export type UserUpdate = z.infer<typeof UserUpdateZod>
