import React from 'react'

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

import { DropdownMenuContent, type DropdownMenuOption } from '../../atoms/DropdownMenuContent/DropdownMenuContent'
import { Icons } from '../../atoms/Icons/Icons'
import { cn } from '../../lib/utils'
import { UserAvatar, type UserAvatarProps } from '../UserAvatar/UserAvatar'

export interface UserAvatarDropdownMenuTriggerProps extends Pick<UserAvatarProps, 'userName' | 'userRole'> {}

export const UserAvatarDropdownMenuTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger> & UserAvatarDropdownMenuTriggerProps
>(({ className, children, userName, userRole, disabled, ...props }, ref) => (
  <DropdownMenuPrimitive.Trigger
    ref={ref}
    disabled={disabled}
    className={cn('flex select-none items-center gap-0.5 outline-none', className)}
    {...props}>
    <UserAvatar dimension="lg" userName={userName} userRole={userRole} />
    {!disabled && <Icons.CaretDownFill className="text-gray-700" />}
  </DropdownMenuPrimitive.Trigger>
))

export interface UserAvatarDropdownMenuOption<T extends string = string> extends DropdownMenuOption<T> {
  onItemSelect(): void
}

export type UserAvatarDropdownMenuProps<T extends string = string> = UserAvatarDropdownMenuTriggerProps & {
  readonly options: Array<UserAvatarDropdownMenuOption<T>>
  readonly disabled?: boolean
}

export const UserAvatarDropdownMenu = <T extends string = string>({
  userName,
  userRole,
  options,
  disabled,
  ...props
}: UserAvatarDropdownMenuProps<T> & React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Root>) => (
  <DropdownMenuPrimitive.Root {...props}>
    <UserAvatarDropdownMenuTrigger userName={userName} userRole={userRole} disabled={disabled} />
    <DropdownMenuContent
      options={options}
      onItemSelect={(selected) => options.find(({ value }) => selected === value)?.onItemSelect()}
    />
  </DropdownMenuPrimitive.Root>
)
