import { z } from 'zod'

import {
  AnimalsInvestmentTypingZod,
  BuildingInvestmentTypingZod,
  LandInvestmentTypingZod,
  MachineryInvestmentTypingZod,
  OtherInvestmentTypingZod,
  PaymentFrequencyZod,
  QuotaInvestmentTypingZod,
} from '../enums'

import { DateStringZod } from './date-string-zod'
import { MonetaryValueZod } from './monetary-value-zod'

export const ScenarioStateInvestmentSubsidiesZod = z.record(
  z.object({
    amount: MonetaryValueZod,
    date: DateStringZod,
  })
)

export const ScenarioStateDisbursementZod = z.record(
  z.object({
    amount: MonetaryValueZod,
    date: DateStringZod,
  })
)

export const ScenarioInvestmentBaseStateZod = z.object({
  description: z.string().optional(),
  payment: MonetaryValueZod.optional(),
  transactionDate: DateStringZod.or(z.literal('')).optional(),
  note: z.string().optional(),
  createdAt: z.date().optional(),
})

export const AssetScenarioInvestmentStateZod = ScenarioInvestmentBaseStateZod.extend({
  category: z.enum(['purchase', 'sale']),
  subvention: ScenarioStateInvestmentSubsidiesZod.optional(),
  disbursement: ScenarioStateDisbursementZod.optional(),
})

export const LandScenarioInvestmentStateZod = AssetScenarioInvestmentStateZod.extend({
  type: LandInvestmentTypingZod,
  units: z.string().optional(),
  pricePerUnit: MonetaryValueZod.optional(),
})

export const BuildingScenarioInvestmentStateZod = AssetScenarioInvestmentStateZod.extend({
  type: BuildingInvestmentTypingZod,
  amortization: z.string().optional(),
})

export const AnimalsScenarioInvestmentStateZod = AssetScenarioInvestmentStateZod.extend({
  type: AnimalsInvestmentTypingZod,
  units: z.string().optional(),
  pricePerUnit: MonetaryValueZod.optional(),
})

export const QuotaScenarioInvestmentStateZod = AssetScenarioInvestmentStateZod.extend({
  type: QuotaInvestmentTypingZod,
})

export const MachineryScenarioInvestmentStateZod = AssetScenarioInvestmentStateZod.extend({
  type: MachineryInvestmentTypingZod,
  amortization: z.string().optional(),
})

export const OtherScenarioInvestmentStateZod = AssetScenarioInvestmentStateZod.extend({
  type: OtherInvestmentTypingZod,
})

export const FinancingScenarioInvestmentStateZod = ScenarioInvestmentBaseStateZod.extend({
  category: z.literal('financing'),
  amortization: z.string().optional(),
  type: z.discriminatedUnion('type', [
    AnimalsInvestmentTypingZod,
    BuildingInvestmentTypingZod,
    LandInvestmentTypingZod,
    MachineryInvestmentTypingZod,
    QuotaInvestmentTypingZod,
  ]),
  duration: z.string(),
  paymentFrequency: PaymentFrequencyZod.optional(),
})

export const CapitalScenarioInvestmentStateZod = ScenarioInvestmentBaseStateZod.extend({
  category: z.enum(['capital', 'shares-purchase', 'shares-sale']),
})

export const ScenarioInvestmentStateZod = z.union([
  LandScenarioInvestmentStateZod,
  BuildingScenarioInvestmentStateZod,
  AnimalsScenarioInvestmentStateZod,
  QuotaScenarioInvestmentStateZod,
  MachineryScenarioInvestmentStateZod,
  FinancingScenarioInvestmentStateZod,
  CapitalScenarioInvestmentStateZod,
  OtherScenarioInvestmentStateZod,
])

export type ScenarioInvestmentState = z.infer<typeof ScenarioInvestmentStateZod>
export type InvestmentCategory = ScenarioInvestmentState['category']
