import { type FC, type PropsWithChildren } from 'react'

import { ScenarioEditableMainLayout, type ScenarioEditableMainLayoutProps } from './ScenarioEditableMainLayout.tsx'
import { ScenarioSnapshotMainLayout, type ScenarioSnapshotMainLayoutProps } from './ScenarioSnapshotMainLayout.tsx'

export type ScenarioMainLayoutProps = ScenarioEditableMainLayoutProps &
  ScenarioSnapshotMainLayoutProps & { readonly snapshot?: boolean }

export const ScenarioMainLayout: FC<PropsWithChildren<ScenarioMainLayoutProps>> = ({ snapshot, ...props }) =>
  snapshot ? <ScenarioSnapshotMainLayout {...props} /> : <ScenarioEditableMainLayout {...props} />
