import { InvestmentSubTypeZod, InvestmentTypeZod, PaymentFrequencyZod } from '@via/schema'
import { z } from 'zod'

import { FormFiniteNumberValueZod } from '../../atoms/Form/FormFiniteNumberValueZod.ts'

export const InvestmentFormZod = z.object({
  description: z.string().optional().default(''),
  payment: FormFiniteNumberValueZod,
  transactionDate: z.string().optional().default(''),
  note: z.string().optional().default(''),
  category: z
    .enum(['purchase', 'sale', 'financing', 'capital', 'shares-purchase', 'shares-sale'])
    .optional()
    .default('purchase'),
  type: InvestmentTypeZod.optional(),
  subtype: InvestmentSubTypeZod.optional(),

  amortization: FormFiniteNumberValueZod,
  units: FormFiniteNumberValueZod,
  pricePerUnit: FormFiniteNumberValueZod,

  duration: FormFiniteNumberValueZod,
  paymentFrequency: PaymentFrequencyZod.optional().default('monthly'),

  rate: FormFiniteNumberValueZod,

  disbursement: z
    .record(
      z.object({
        index: z.number(),
        date: z
          .string()
          .regex(/(^\d{4}-\d{2}-\d{2}$|^$)/)
          .optional()
          .default(''),
        value: FormFiniteNumberValueZod,
      })
    )
    .optional()
    .default({}),
  subvention: z
    .record(
      z.object({
        index: z.number(),
        date: z
          .string()
          .regex(/(^\d{4}-\d{2}-\d{2}$|^$)/)
          .optional()
          .default(''),
        value: FormFiniteNumberValueZod,
      })
    )
    .optional()
    .default({}),
  enabledOptions: z.enum(['subvention', 'disbursement']).array().optional().default([]),
})

export type InvestmentFormData = z.infer<typeof InvestmentFormZod>
