import React from 'react'

import { cn } from '../../lib/utils'

import { FormItemContext } from './FormFieldItemContext'

export const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const id = React.useId()
    const value = React.useMemo(() => ({ id }), [id])

    return (
      <FormItemContext.Provider value={value}>
        <div ref={ref} className={cn('space-y-0.5', className)} {...props} />
      </FormItemContext.Provider>
    )
  }
)
