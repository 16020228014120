type ScenarioPage =
  | 'balance'
  | 'configuration'
  | 'finance'
  | 'repayment'
  | 'results'
  | 'milk-results'
  | 'milk-configuration'
  | 'milk-animals'
  | 'crops-results'

export const getScenarioPreviousPages = (budgetId: string, scenarioId: string) =>
  JSON.parse(
    sessionStorage.getItem(`via-scenario-page-state-${budgetId}-${scenarioId}`) ?? JSON.stringify(['results'])
  ) as ScenarioPage[]

export const getScenarioPreviousPage = (budgetId: string, scenarioId: string) => {
  const items = getScenarioPreviousPages(budgetId, scenarioId)

  return items[0] ?? 'results'
}

export const setScenarioCurrentPage = (budgetId: string, scenarioId: string, page: ScenarioPage) => {
  const items = [page, ...getScenarioPreviousPages(budgetId, scenarioId)].slice(0, 10)

  sessionStorage.setItem(`via-scenario-page-state-${budgetId}-${scenarioId}`, JSON.stringify(items))
}
