import {
  type AssetComputationType,
  type BlueprintVersion,
  type InventoryComputationType,
  type InventoryTotalComputationType,
  type InvestmentComputationType,
  type LoanTotalComputationType,
  type MilkIncomeComputationType,
  type MilkQuotaComputationType,
  type MovementComputationType,
  type ProductionType,
} from '../../../enums'
import { type DataKey } from '../../data-key-zod'
import { CompositeAttributesJson } from '../../generated'
import { type DataKeysOfComputationType } from '../utils'

export type CompositeSource =
  | 'finance-asset-total'
  | 'finance-loan-total'
  | 'finance-investment'
  | 'finance-investment-total'
  | 'inventory'
  | 'inventory-total'
  | 'movement-overridable'
  | 'movement-total'
  | 'milk-income'
  | 'milk-quota'

type CompositeValue<Source extends CompositeSource = CompositeSource> = Source extends 'finance-loan-total'
  ? LoanTotalComputationType
  : Source extends 'finance-investment-total'
    ? InvestmentComputationType
    : Source extends 'finance-asset-total'
      ? AssetComputationType
      : Source extends 'movement-overridable' | 'movement-total'
        ? MovementComputationType
        : Source extends 'milk-quota'
          ? MilkQuotaComputationType
          : Source extends 'milk-income'
            ? MilkIncomeComputationType
            : Source extends 'inventory'
              ? InventoryComputationType
              : Source extends 'inventory-total'
                ? InventoryTotalComputationType
                : never

export interface CompositeDataAttribute<Source extends CompositeSource = CompositeSource> {
  readonly type: 'composite'
  readonly readonly: true
  readonly source: DataKeysOfComputationType<Source>
  readonly multipleValues?: boolean
  readonly value: CompositeValue<Source>
  readonly reference?: 'selected' | 'latest' | 'none'
  readonly total?: true
  readonly weights?: ReadonlyArray<DataKey>
  readonly blueprintVersions?: ReadonlyArray<BlueprintVersion>
  readonly production?: ProductionType
}

export const CompositeDataKeyAttributes = CompositeAttributesJson as Record<
  keyof typeof CompositeAttributesJson,
  CompositeDataAttribute
>

export type CompositeDataKey = keyof typeof CompositeDataKeyAttributes
