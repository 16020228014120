import type React from 'react'

import { captureException } from '@sentry/react'
import { createFileRoute, notFound, Outlet } from '@tanstack/react-router'
import { type BudgetData } from '@via/components'
import { collections } from '@via/frontend-schema'

import { FirestoreCurrentBudgetProvider } from '../contexts/currentBudget/FirestoreCurrentBudgetProvider.tsx'
import { RxdbCurrentBudgetProvider } from '../contexts/currentBudget/RxdbCurrentBudgetProvider.tsx'
import { waitForRxBudget } from '../rxdb/rxjs/waitForRxBudget.ts'
import { rxDocumentData } from '../rxdb/utils/rxDocumentData.ts'

export const CurrentBudgetComponent: React.FC = () => {
  const { budget } = Route.useRouteContext()

  if (budget.readonly) {
    return (
      <FirestoreCurrentBudgetProvider budget={budget}>
        <Outlet />
      </FirestoreCurrentBudgetProvider>
    )
  }

  return (
    <RxdbCurrentBudgetProvider budget={budget}>
      <Outlet />
    </RxdbCurrentBudgetProvider>
  )
}

export const Route = createFileRoute('/budgets/$budgetId')({
  beforeLoad: async ({ context, params }) => {
    const budgetRxDocument = await context.appDatabase.budgets.findOne(params.budgetId).exec()
    if (budgetRxDocument) {
      return { budget: rxDocumentData(budgetRxDocument) }
    }

    if (context.isOffline) {
      throw notFound()
    }

    const firestoreBudget = await collections.budgets.findById(params.budgetId)
    if (!firestoreBudget) {
      throw notFound()
    }

    if (firestoreBudget.owner) {
      const currentUserIsOwner = firestoreBudget.owner?.userId === context.auth.user.uid
      if (currentUserIsOwner) {
        const loadedBudgetRxDocument = await waitForRxBudget(context.appDatabase, params.budgetId)
        return { budget: loadedBudgetRxDocument }
      }

      return { budget: { ...firestoreBudget, readonly: true, currentUserIsOwner } }
    }

    let acquiredBudget: BudgetData
    try {
      acquiredBudget = await context.budgetHandler.acquireBudget(params.budgetId)
    } catch (error) {
      captureException(error, {
        contexts: { params },
        tags: { route: '/budgets/$budgetId' },
      })
      return { budget: { ...firestoreBudget, readonly: true } }
    }
    if (!acquiredBudget) {
      throw notFound()
    }
    return { budget: acquiredBudget }
  },
  component: CurrentBudgetComponent,
})
