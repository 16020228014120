import { type ChangeEvent, type FC, useCallback } from 'react'
import { useIntl } from 'react-intl'

import { Label } from '../../atoms/Label/Label'
import { GroupsInformation } from '../../molecules/GroupsInformation/GroupsInformation'
import { UserReadonlyInformation } from '../../molecules/UserReadonlyInformation/UserReadonlyInformation'
import { MainLayout, type MainLayoutProps } from '../../organisms/MainLayout/MainLayout'
import { UserProfileForm } from '../../organisms/UserProfileForm/UserProfileForm'
import { type UserProfileFormData } from '../../organisms/UserProfileForm/UserProfileFormData'
import { type GroupData, type UserData } from '../../types'

export interface ProfileViewProps extends Pick<MainLayoutProps, 'version' | 'avatar' | 'offline'> {
  readonly user: UserData | null
  readonly onUserUpdate: (user: UserProfileFormData) => Promise<void>

  readonly userSignatureUrl?: string
  readonly onSignatureSelect: (file: File) => Promise<void>

  readonly userGroups: GroupData[]
}

export const ProfileView: FC<ProfileViewProps> = ({
  version,
  avatar,
  offline,
  user,
  onUserUpdate,
  userGroups,
  userSignatureUrl,
  onSignatureSelect,
}) => {
  const intl = useIntl()

  const onFileInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]

      if (!file) {
        return
      }

      await onSignatureSelect(file)
    },
    [onSignatureSelect]
  )

  if (!user) {
    return null
  }

  return (
    <MainLayout version={version} avatar={avatar} offline={offline}>
      <div className="mx-10 mt-6 flex flex-1 flex-col rounded bg-white">
        <div className="border-b border-b-gray-200 px-6 py-5">
          <Label className="text-xl font-bold">{intl.formatMessage({ id: 'profileView.title' })}</Label>
        </div>
        <div className="flex">
          <div className="w-[530px] pl-20">
            <div className="flex max-w-[325px] flex-col gap-5 py-8">
              <UserReadonlyInformation user={user} />
              <UserProfileForm data={user} onValueChange={onUserUpdate} />
            </div>
            <div className="pb-8">
              <GroupsInformation groups={userGroups} currentUserId={user._id} />
            </div>
          </div>
          <div className="flex flex-col pt-8">
            <div className="flex">
              <Label className="pr-2.5 text-2xl font-bold">
                {intl.formatMessage({ id: 'profileView.signature.title' })}
              </Label>
              <label
                htmlFor="soil-file-upload"
                className="flex h-8 cursor-pointer items-center rounded bg-blue-600 px-1.5 text-white">
                <input
                  onChange={onFileInputChange}
                  type="file"
                  id="soil-file-upload"
                  className="hidden"
                  accept="image/*"
                />
                {intl.formatMessage({ id: 'profileView.signature.upload' })}
              </label>
            </div>
            {userSignatureUrl && <img className="h-[160px] w-[200px]" src={userSignatureUrl} />}
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
