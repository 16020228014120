import { type FC, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { BundledEditor, type BundledEditorProps } from '@via/frontend-tinymce'

import { Icons } from '../../../atoms'
import { SettingDropdownMenu } from '../../../atoms/SettingDropdownMenu/SettingDropdownMenu'

export type SortableBundledEditorProps = BundledEditorProps & {
  readonly id: string
  readonly isFirst: boolean
  readonly isLast: boolean
  readonly onItemDelete: () => void
  readonly onItemMoveUp: () => void
  readonly onItemMoveDown: () => void
}

export const SortableBundledEditor: FC<SortableBundledEditorProps> = ({
  id,
  isFirst,
  isLast,
  onItemDelete,
  onItemMoveUp,
  onItemMoveDown,
  ...props
}) => {
  const intl = useIntl()

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = useMemo(
    () => ({
      transform: CSS.Translate.toString(transform),
      transition,
    }),
    [transform, transition]
  )

  return (
    <div key={`${id}-container`} style={style} ref={setNodeRef} className="flex rounded bg-gray-50 px-2 py-3">
      <BundledEditor key={id} id={id} {...props} />
      <div className="flex pl-4">
        <div className="pt-1" {...attributes} {...listeners}>
          <Icons.ChevronExpand />
        </div>
        <div>
          <SettingDropdownMenu
            options={[
              { value: 'delete', text: intl.formatMessage({ id: 'reportConfiguration.form.deleteItem' }) },
              {
                value: 'moveUp',
                text: intl.formatMessage({ id: 'reportConfiguration.form.moveUp' }),
                disabled: isFirst,
              },
              {
                value: 'moveDown',
                text: intl.formatMessage({ id: 'reportConfiguration.form.moveDown' }),
                disabled: isLast,
              },
            ]}
            onItemSelect={(option) => {
              switch (option) {
                case 'delete':
                  onItemDelete()
                  break
                case 'moveUp':
                  onItemMoveUp()
                  break
                case 'moveDown':
                  onItemMoveDown()
                  break
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}
