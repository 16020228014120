import { z } from 'zod'

import { UserUpdateZod } from '../common'

export const ScenarioZod = z.object({
  budgetId: z.string(),
  currentStateId: z.string(),
  name: z.string(),
  description: z.string().optional(),
  customReferenceName: z.string().optional(),
  creation: UserUpdateZod,
  lastModification: UserUpdateZod,
  archived: z.boolean(),
})
