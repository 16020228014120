import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { mapNonNullValues, type ScenarioState } from '@via/compute'
import { type DataKey, resolveAlias, resolveAttributesOrThrow } from '@via/schema'
import { mapValues, omit } from 'lodash-es'

import { type BlueprintDataRow, type BlueprintSection, type BlueprintTable } from '../blueprints/blueprint'
import { type MonetaryRowData } from '../model'
import { type MonetaryTableBlueprint, type MonetaryTableLine, type MonetaryTableValueLine } from '../organisms'

const preprocessInvestments = <Key extends string>(
  rows: ReadonlyArray<MonetaryRowData<Key>>
): ReadonlyArray<MonetaryRowData<Key>> =>
  rows.flatMap((row) => {
    if (!row.subvention) {
      return [row]
    }
    const { key, blueprint, subvention, stateDataId } = row

    return [
      row,
      {
        computationType: 'finance-investment',
        id: `${stateDataId}-subvention`,
        stateDataId,
        key,
        blueprint,
        label: 'investment.table.subvention.title',
        variant: 'default',
        typeLabel: 'investment.table.category.subvention',
        ...subvention,
      },
    ]
  })

const preprocessMonetaryRowData = <Key extends string>(
  lineId: string,
  rows: ReadonlyArray<MonetaryRowData<Key>>
): ReadonlyArray<MonetaryRowData<Key>> => {
  switch (lineId) {
    case 'finance.investment.plan':
      return preprocessInvestments(rows)
    default:
      return rows
  }
}

const addReferenceCellVariants = (
  variant: Pick<MonetaryTableValueLine, 'variant' | 'cellVariants'>,
  referenceType: 'viagritel' | 'empty'
): Pick<MonetaryTableValueLine, 'variant' | 'cellVariants'> => {
  switch (variant.variant) {
    case 'group':
    case 'default':
      return {
        ...variant,
        cellVariants: {
          ...(referenceType === 'viagritel'
            ? {
                reference: 'referenceValue',
                'all-references': 'referenceValue',
                historic: 'referenceValue',
                'all-historic': 'referenceValue',
              }
            : {
                reference: 'growthValue',
                'all-references': 'growthValue',
                historic: 'referenceValue',
                'all-historic': 'referenceValue',
              }),
          ...variant.cellVariants,
        },
      }
    default:
      return variant
  }
}

export const useMonetaryTableBlueprint = <TableStates extends string, TabValues extends string, Weight extends string>(
  blueprint: BlueprintTable<TableStates, TabValues, Weight>,
  { blueprintVersion, referenceType }: Pick<ScenarioState, 'blueprintVersion' | 'referenceType'>
) => {
  const intl = useIntl()

  return useMemo<MonetaryTableBlueprint<DataKey, TableStates, TabValues, Weight>>(() => {
    const monetaryTableValueLine = (line: BlueprintDataRow, dept: number): MonetaryTableValueLine => {
      const attributes = resolveAttributesOrThrow(line.id, blueprintVersion)
      const readonly = 'readonly' in attributes && (attributes.readonly ?? false)
      const multipleValues = 'multipleValues' in attributes && (attributes.multipleValues ?? false)

      const evaluateVariant = (): Pick<MonetaryTableValueLine, 'variant' | 'cellVariants'> => {
        const { variant, cellVariants, displayAsProjection } = line
        if (variant !== 'default') {
          return { variant, cellVariants }
        }
        switch (true) {
          case attributes.type === 'grbf':
            return {
              variant: dept <= 1 ? 'group' : 'default',
              cellVariants: {
                growth: 'growthValue',
                'result-projections': 'grbfValue',
                ...cellVariants,
              },
            }
          case attributes.type === 'overridable':
            return {
              variant: dept <= 1 ? 'group' : 'default',
              cellVariants: {
                growth: 'emptyCell',
                'result-projections': 'overridableValue',
                ...cellVariants,
              },
            }
          case displayAsProjection:
            return {
              variant: dept <= 1 ? 'group' : 'default',
              cellVariants: {
                'result-projections': 'editableValue',
                ...cellVariants,
              },
            }
          case attributes.type === 'total':
            return {
              variant: dept <= 1 ? 'section' : 'subSectionProduction',
              cellVariants,
            }
          case attributes.type === 'finance-loan':
          case attributes.type === 'finance-investment':
          case attributes.type === 'aggregate':
            return { variant: 'group', cellVariants }
          default:
            return { variant, cellVariants }
        }
      }

      return {
        name: (line.negated ? '(-)\u00A0' : '') + intl.formatMessage({ id: line.titleKey ?? `table.${line.id}` }),
        ...omit(line, ['children', 'titleKey', 'displayAsProjection']),
        ...addReferenceCellVariants(evaluateVariant(), referenceType),
        readonly,
        multipleValues,
      }
    }

    const toMonetaryTableLine = (
      { children, ...line }: BlueprintSection['children'][number],
      dept: number
    ): MonetaryTableLine => {
      const lines = children?.map((child) => toMonetaryTableLine(child, dept + 1)) ?? []

      switch (line.variant) {
        case 'head':
          return {
            id: line.id,
            variant: 'head',
            name: intl.formatMessage({ id: line.titleKey }),
            addMonetaryItemButtonType: line.addMonetaryItemButtonType,
            symbol: line.symbol,
            lines,
          }
        case 'divider':
          return {
            id: line.id,
            variant: 'divider',
            type: line.type,
            border: line.border,
            lines,
          }
        default:
          return {
            ...monetaryTableValueLine(line, dept),
            lines,
          }
      }
    }

    return {
      ...blueprint,
      sections: blueprint.sections.map(({ titleKey, children, ...section }) => ({
        ...section,
        title: titleKey ? intl.formatMessage({ id: titleKey }) : '',
        lines: children.map((line) => toMonetaryTableLine(line, 0)),
      })),
      summary: blueprint.summary?.map((line) => toMonetaryTableLine(line, 0)),
      columns: blueprint.columns.map(({ titleKey, ...column }) => ({
        ...column,
        title: titleKey ? intl.formatMessage({ id: titleKey }) : '',
        valueMeasureUnit: column.valueMeasureUnitKey && intl.formatMessage({ id: column.valueMeasureUnitKey }),
      })),
      tabs: blueprint.tabs?.map(({ labelKey, ...tab }) => ({
        ...tab,
        label: intl.formatMessage({ id: labelKey }),
      })),
      weights:
        blueprint.weights && mapNonNullValues(blueprint.weights, (value) => resolveAlias(value, blueprintVersion)),
      weightsTitle:
        blueprint.weightsTitle && mapValues(blueprint.weightsTitle, (value) => intl.formatMessage({ id: value })),
      callouts: blueprint.callouts && mapValues(blueprint.callouts, (value) => intl.formatMessage({ id: value })),
      preprocessMonetaryRowData,
    } as MonetaryTableBlueprint<DataKey, TableStates, TabValues, Weight>
  }, [intl, blueprint, blueprintVersion, referenceType])
}
