import type React from 'react'

import { ScenarioUpdateView } from '@via/components'

import { useScenarioUpdatePage } from './useScenarioUpdatePage.ts'

export const ScenarioUpdatePage: React.FC = () => {
  const props = useScenarioUpdatePage()
  return <ScenarioUpdateView key={props.scenario._id} {...props} />
}
