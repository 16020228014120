import { forwardRef, type HtmlHTMLAttributes } from 'react'

import { cn } from '../../lib/utils'

export interface HeaderTitleProps extends HtmlHTMLAttributes<HTMLDivElement> {
  readonly label: string
}

export const HeaderTitle = forwardRef<HTMLDivElement, HeaderTitleProps>(({ className, label, ...props }, ref) => (
  <div className={cn('flex shrink-0', className)} ref={ref} {...props}>
    <p className="select-none text-xl font-bold leading-none tracking-[-0.48px] text-black">{label}</p>
  </div>
))
