import { type BlueprintVersion } from '../../../enums'
import { keysToTuple } from '../../keys-to-tuple'

export interface MilkQuotaDataKeyAttribute {
  readonly type: 'milk-quota'
  readonly readonly: false
  readonly blueprintVersions: ReadonlyArray<BlueprintVersion>
  readonly production: 'milk'
  readonly dependencies: [
    'milk.production.sale.kilogram-of-fat-per-day',
    'milk.quota.asset.cost',
    'milk.quota.asset.value',
  ]
  readonly referenceDependencies: ['milk.quota.imported.kilogram-of-fat-per-day.start-up']
}

export interface MilkIncomeDataKeyAttribute {
  readonly type: 'milk-income'
  readonly readonly: false
  readonly blueprintVersions: ReadonlyArray<BlueprintVersion>
  readonly production: 'milk'
  readonly dependencies: {
    volumeSale: 'milk.sale.hectoliter'
    fatContent: 'milk.production.content.kilogram-per-hectoliter.fat'
    proteinContent: 'milk.production.content.kilogram-per-hectoliter.protein'
    lactoseContent: 'milk.production.content.kilogram-per-hectoliter.lactose'
    fatPrice: 'milk.price.fat'
    proteinLev1Price: 'milk.price.level-1.protein'
    lactoseLev1Price: 'milk.price.level-1.lactose'
    proteinLev2Price: 'milk.price.level-2.protein'
    lactoseLev2Price: 'milk.price.level-2.lactose'
    bonusBio: 'milk.price.bonus.bio.currency-per-hectoliter'
    bonusBioPercentage: 'milk.price.bonus.bio.percentage.applicable'
    bonusQuality: 'milk.price.bonus.quality.currency-per-hectoliter'
    bonusQualityPercentage: 'milk.price.bonus.quality.percentage.applicable'
    bonusOther: 'milk.price.bonus.other.currency-per-hectoliter'
    bonusOtherPercentage: 'milk.price.bonus.other.percentage.applicable'
    dividendsRate: 'milk.price.dividends.currency-per-kilogram.rate'
    dividendsShareProportion: 'milk.sale.dividends.percentage.share'
    dividendsTaxProportion: 'milk.sale.dividends.percentage.tax'
  }
  readonly referenceDependencies: []
}

export type MilkProductionDataKeyAttribute = MilkQuotaDataKeyAttribute | MilkIncomeDataKeyAttribute

export const MilkProductionDataKeyAttributes = {
  'milk-quota': {
    type: 'milk-quota',
    readonly: false,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.production.sale.kilogram-of-fat-per-day', 'milk.quota.asset.cost', 'milk.quota.asset.value'],
    referenceDependencies: ['milk.quota.imported.kilogram-of-fat-per-day.start-up'],
  },
  'milk-income': {
    type: 'milk-income',
    readonly: false,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: {
      volumeSale: 'milk.sale.hectoliter',
      fatContent: 'milk.production.content.kilogram-per-hectoliter.fat',
      proteinContent: 'milk.production.content.kilogram-per-hectoliter.protein',
      lactoseContent: 'milk.production.content.kilogram-per-hectoliter.lactose',
      fatPrice: 'milk.price.fat',
      proteinLev1Price: 'milk.price.level-1.protein',
      lactoseLev1Price: 'milk.price.level-1.lactose',
      proteinLev2Price: 'milk.price.level-2.protein',
      lactoseLev2Price: 'milk.price.level-2.lactose',
      bonusBio: 'milk.price.bonus.bio.currency-per-hectoliter',
      bonusBioPercentage: 'milk.price.bonus.bio.percentage.applicable',
      bonusQuality: 'milk.price.bonus.quality.currency-per-hectoliter',
      bonusQualityPercentage: 'milk.price.bonus.quality.percentage.applicable',
      bonusOther: 'milk.price.bonus.other.currency-per-hectoliter',
      bonusOtherPercentage: 'milk.price.bonus.other.percentage.applicable',
      dividendsRate: 'milk.price.dividends.currency-per-kilogram.rate',
      dividendsShareProportion: 'milk.sale.dividends.percentage.share',
      dividendsTaxProportion: 'milk.sale.dividends.percentage.tax',
    },
    referenceDependencies: [],
  },
} as const satisfies Record<string, MilkProductionDataKeyAttribute>

export const MilkProductionDataKeys = keysToTuple(MilkProductionDataKeyAttributes)
