import { type ScenarioState } from '@via/compute'
import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { dataLine, headerLine } from '../utils'

export const repaymentCapacity2024Blueprint = ({ blueprintVersion }: ScenarioState) =>
  ({
    sections: [
      {
        id: 'repayment.balance',
        titleKey: 'table.repayment.balance',
        children: [
          {
            ...headerLine('table.repayment.margin'),
            children: [
              dataLine('repayment.margin.income.raw', { variant: 'group' }),
              dataLine('repayment.margin.expenses.operational', { variant: 'group' }),
            ],
          },
          dataLine('repayment.margin.total', { variant: 'total', closingSection: true }),
          {
            ...headerLine('table.repayment.charges', { symbol: 'minus' }),
            children: [
              dataLine('repayment.charges.salaries', { variant: 'group' }),
              {
                ...dataLine('repayment.charges.withdrawals.total', { variant: 'subSectionProduction' }),
                children: [
                  dataLine('repayment.charges.withdrawals.loan', { displayAsProjection: true }),
                  dataLine('repayment.charges.withdrawals.other', { displayAsProjection: true }),
                ],
              },
              ...(blueprintVersion === 'simple-2024'
                ? [dataLine('repayment.charges.tax', { variant: 'group' })]
                : [
                    {
                      ...dataLine('repayment.charges.tax.total', { variant: 'subSectionProduction' }),
                      children: [
                        dataLine('repayment.charges.tax', { displayAsProjection: true }),
                        dataLine('repayment.charges.tax.milk.dividends', { displayAsProjection: true }),
                      ],
                    },
                  ]),
            ],
          },
          dataLine('repayment.charges.total', { variant: 'total', closingSection: true }),
          {
            ...dataLine('repayment.capacity.maximum', { variant: 'calculateTotal', symbol: 'total' }),
            children: [],
          },
          {
            ...headerLine('table.payments.section', { symbol: 'minus' }),
            children: [
              dataLine('repayment.debt.payments.capital', { variant: 'group' }),
              dataLine('repayment.debt.payments.interest', { variant: 'group' }),
            ],
          },
          dataLine('repayment.debt.total', { variant: 'total', closingSection: true }),
          {
            ...dataLine('repayment.residual.balance.basis', { variant: 'finalTotal', symbol: 'total' }),
            children: [dataLine('repayment.residual.balance.without.inventory', { variant: 'basic' })],
          },
        ],
      },
      {
        id: 'repayment.changes',
        titleKey: 'table.repayment.changes',
        children: [
          {
            ...headerLine('table.repayment.inflows'),
            children: [
              dataLine('repayment.inflows.balance.basis', { variant: 'group' }),
              {
                ...dataLine('repayment.inflows.loans.agricultural.total', {
                  variant: 'subSectionProduction',
                }),
                children: [
                  dataLine('repayment.inflows.loans.agricultural.new-capital'),
                  dataLine('repayment.inflows.loans.agricultural.early-repayment', { negated: true }),
                ],
              },
              dataLine('repayment.inflows.short.margin.increase', { variant: 'group' }),
              dataLine('repayment.inflows.receivables.decrease', { variant: 'group' }),
              dataLine('repayment.inflows.contributions', { variant: 'group' }),
              dataLine('repayment.inflows.inventory.decrease', { variant: 'group' }),
              dataLine('repayment.inflows.shares.sales', { variant: 'group' }),
            ],
          },
          dataLine('repayment.inflows.total', { variant: 'total', closingSection: true }),
          {
            ...headerLine('table.repayment.outflows', { symbol: 'minus' }),
            children: [
              {
                ...dataLine('repayment.outflows.investment.total', {
                  variant: 'subSectionProduction',
                }),
                children: [
                  dataLine('repayment.outflows.investment.machinery', { displayAsProjection: true }),
                  dataLine('repayment.outflows.investment.buildings', { displayAsProjection: true }),
                  dataLine('repayment.outflows.investment.land', { displayAsProjection: true }),
                  dataLine('repayment.outflows.investment.quota', { displayAsProjection: true }),
                  dataLine('repayment.outflows.investment.assets.farming', { displayAsProjection: true }),
                  dataLine('repayment.outflows.investment.subsidies', { displayAsProjection: true, negated: true }),
                ],
              },
              dataLine('repayment.outflows.investment.programs', { variant: 'group' }),
              dataLine('repayment.outflows.investment.assets.other', { variant: 'group' }),
              dataLine('repayment.outflows.short.margin.decrease', { variant: 'group' }),
              dataLine('repayment.outflows.receivables.increase', { variant: 'group' }),
              dataLine('repayment.outflows.inventory.increase', { variant: 'group' }),
              dataLine('repayment.outflows.shares.buys', { variant: 'group' }),
            ],
          },
          dataLine('repayment.outflows.total', { variant: 'total', closingSection: true }),
          {
            ...dataLine('repayment.current.accounts.balance', { variant: 'finalTotal', symbol: 'total' }),
            // children: [
            //   {
            //     ...dataLine('repayment.balance.sheet.validation', { variant: 'validateAlert' }),
            //     children: [dataLine('repayment.balance.sheet.difference', { variant: 'subValidateAlert' })],
            //   },
            // ],
          },
        ],
      },
    ],
    columns: [
      { id: 'title', type: 'title', sticky: true },
      {
        id: 'historic',
        type: 'historic',
        sticky: true,
        withDividerRight: true,
        displayOnTableState: ['selected-historic', 'print-with-reference'],
      },
      {
        id: 'all-historic',
        type: 'all-historic',
        sticky: true,
        withDividerRight: true,
        displayOnTableState: ['historic'],
      },
      {
        id: 'divider',
        type: 'divider',
        className: 'w-1',
        displayOnTableState: ['selected-historic', 'historic', 'print-with-reference'],
      },
      {
        id: 'projections',
        type: 'projections-with-proportions',
        withDividerRight: true,
        topHeaderClassName: 'w-36',
        className: 'w-24',
      },
    ],
    defaultState: 'selected-historic',
    expendedState: 'historic',
  }) as const satisfies Except<
    BlueprintTable<'selected-historic' | 'historic' | 'print-with-reference' | 'print-without-reference'>,
    'version'
  >
