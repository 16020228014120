import { type FC, type PropsWithChildren } from 'react'

import { ScenarioListContext } from '@via/components'
import { useObservable, useObservableState } from 'observable-hooks'
import { switchMap } from 'rxjs'

import { rxdbBudgetScenarioList$ } from '../../rxdb/app/budgets/rxdbBudgetScenarioList.ts'

export interface RxdbScenarioListProviderProps {
  readonly budgetId: string
}

export const RxdbScenarioListProvider: FC<PropsWithChildren<RxdbScenarioListProviderProps>> = ({
  budgetId,
  children,
}) => {
  const rxScenarioListObservableState$ = useObservable(
    (inputs$) => inputs$.pipe(switchMap(([id]) => rxdbBudgetScenarioList$(id))),
    [budgetId]
  )

  const value = useObservableState(rxScenarioListObservableState$, [])
  return <ScenarioListContext.Provider value={value}>{children}</ScenarioListContext.Provider>
}
