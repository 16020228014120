import { type FC, type PropsWithChildren } from 'react'

import { type YearKey } from '@via/schema'

import { useMonetaryRowsProjectionCellData } from '../../context/monetary/useMonetaryRowsProjectionCellData.ts'

import {
  MilkExpensesFormDependencies,
  MilkExpensesFormDependenciesContext,
} from './MilkExpensesFormDependenciesContext.ts'

export interface MilkExpensesFormDependenciesProviderProps {
  readonly year: YearKey
}

export const MilkExpensesFormDependenciesProvider: FC<PropsWithChildren<MilkExpensesFormDependenciesProviderProps>> = ({
  year,
  children,
}) => {
  const state = useMonetaryRowsProjectionCellData({
    year,
    keys: MilkExpensesFormDependencies,
  })

  return (
    <MilkExpensesFormDependenciesContext.Provider value={state}>
      {children}
    </MilkExpensesFormDependenciesContext.Provider>
  )
}
