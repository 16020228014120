import type React from 'react'

import { ZodForm } from '../../atoms/Form/ZodForm'
import { type BudgetConfigurationData } from '../BudgetConfigurationForm/BudgetConfigurationData'

import { BudgetUpdateFormZod } from './BudgetUpdateFormZod.ts'

export type BudgeUpdateFormProviderProps = React.PropsWithChildren<{
  readonly data: BudgetConfigurationData
}>

export const BudgetUpdateFormProvider: React.FC<BudgeUpdateFormProviderProps> = ({ data, children }) => (
  <ZodForm
    schema={BudgetUpdateFormZod}
    defaultValues={{
      ...data,
      users: { agronomist: null, technician: null, collaborator: null, ...data.users },
    }}>
    {children}
  </ZodForm>
)
