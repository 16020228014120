import { type PropsWithChildren } from 'react'
import { type DefaultValues, type Mode, useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { type z } from 'zod'

import { Form } from './Form'

export interface ZodFormProps<Z extends z.ZodTypeAny> {
  readonly defaultValues?: DefaultValues<z.infer<Z>>
  readonly disabled?: boolean
  readonly mode?: Mode
  readonly schema: Z
  readonly values?: z.infer<Z>
}

export const ZodForm = <Z extends z.ZodTypeAny>({
  defaultValues,
  disabled,
  mode,
  schema,
  values,
  children,
}: PropsWithChildren<ZodFormProps<Z>>) => {
  const form = useForm<z.infer<Z>>({
    resolver: zodResolver(schema),
    defaultValues,
    disabled,
    mode,
    values,
  })

  return <Form {...form}>{children}</Form>
}
