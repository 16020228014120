import { createRxDatabase, type RxStorage } from 'rxdb'

import { registerRxCollectionBreadcrumbs } from '../registerRxCollectionBreadcrumbs.ts'
import { ScenarioEventRxSchema } from '../schema/scenarioEventRxSchema.ts'
import { ScenarioReferenceRxSchema } from '../schema/scenarioReferenceRxSchema.ts'
import { ScenarioRxSchema } from '../schema/scenarioRxSchema.ts'
import { ScenarioStateRxSchema } from '../schema/scenarioStateRxSchema.ts'
import { type ScenarioRxDatabase } from '../types.ts'

export const initScenarioRxDatabase = async <Internals, InstanceCreationOptions>(
  name: string,
  storage: RxStorage<Internals, InstanceCreationOptions>
) => {
  const db = await createRxDatabase<ScenarioRxDatabase<Internals, InstanceCreationOptions>>({
    name,
    storage,
  })
  await db.addCollections<ScenarioRxDatabase['collections']>({
    scenario: { schema: ScenarioRxSchema },
    reference: { schema: ScenarioReferenceRxSchema },
    events: { schema: ScenarioEventRxSchema },
    'scenario-states': { schema: ScenarioStateRxSchema },
  })
  registerRxCollectionBreadcrumbs(db.scenario)
  registerRxCollectionBreadcrumbs(db.reference)
  registerRxCollectionBreadcrumbs(db.events)
  registerRxCollectionBreadcrumbs(db['scenario-states'])

  return db
}
