import { type PaymentFrequency } from '@via/schema'
import { BigNumber } from 'bignumber.js'

import { estimateLoanCapital } from './estimate-loan-capital'
import { type LoanBigNumberImportedData } from './loan-big-number-imported-data'
import { PaymentFrequencyToAnnualEventCount } from './payment-frequency-to-annual-event-count'

export const estimatePaymentAmount = (
  importeLoanData: LoanBigNumberImportedData | null,
  interestRate: BigNumber,
  frequency: PaymentFrequency
): BigNumber | null => {
  if (!importeLoanData?.current?.paidReimbursement?.gt(0)) {
    return null
  }
  const paidInterests =
    estimateLoanCapital(importeLoanData)?.multipliedBy(interestRate.shiftedBy(-2)) ?? new BigNumber(0)
  const annuity = importeLoanData.current.paidReimbursement.plus(paidInterests)
  return annuity.dividedBy(PaymentFrequencyToAnnualEventCount[frequency])
}
