import { type FC, useContext } from 'react'

import { type YearKey } from '@via/schema'
import { type DistributedOmit } from 'type-fest'

import { MonetaryTableActionsContext } from '../contexts/MonetaryTableActionsContext.ts'

import { type MonetaryTableCellProps } from './MonetaryTableCellProps.ts'
import { MonetaryTableValueCell, type MonetaryTableValueCellProps } from './MonetaryTableValueCell.tsx'
import { valueCellType } from './valueCellType.tsx'

export interface MonetaryTableProjectionCellProp extends MonetaryTableCellProps {
  readonly year: YearKey
}

export const MonetaryTableProjectionCell: FC<
  Omit<MonetaryTableProjectionCellProp, 'rowData'> & DistributedOmit<MonetaryTableValueCellProps, 'type'>
> = ({ column, line, rowData, loading, year }) => {
  const { onUpdateCell } = useContext(MonetaryTableActionsContext)
  const type = valueCellType(rowData, line)

  switch (type) {
    case 'finance-investment':
    case 'finance-investment-inventory':
    case 'finance-loan-investment':
    case 'finance-loan':
      return (
        <MonetaryTableValueCell
          year={year}
          type={type}
          column={column}
          line={line}
          rowData={rowData}
          loading={loading}
          cellData={rowData.values?.[year]}
          withDividerRight
        />
      )
    case 'milk-quota':
    case 'milk-income':
    case 'milk-expenses':
      return (
        <MonetaryTableValueCell
          year={year}
          type={type}
          column={column}
          line={line}
          rowData={rowData}
          loading={loading}
          cellData={rowData.values?.[year]}
          withDividerRight
        />
      )
    case 'movement-overridable':
      return (
        <MonetaryTableValueCell
          year={year}
          type={type}
          column={column}
          line={line}
          rowData={rowData}
          loading={loading}
          cellData={rowData.values?.[year]}
          withDividerRight
          onUpdate={onUpdateCell && ((value) => onUpdateCell({ year, ...value }))}
        />
      )
    case 'inventory':
      return (
        <MonetaryTableValueCell
          year={year}
          type={type}
          column={column}
          line={line}
          rowData={rowData}
          loading={loading}
          cellData={rowData.values?.[year]}
          withDividerRight
          onUpdate={onUpdateCell}
        />
      )
    case 'result-projections':
      return (
        <MonetaryTableValueCell
          year={year}
          type="result-projections"
          column={column}
          line={line}
          rowData={rowData}
          loading={loading}
          cellData={rowData.values?.[year]}
          withDividerRight
          onUpdate={onUpdateCell && ((value) => onUpdateCell({ year, ...value }))}
        />
      )
    default:
      return (
        <MonetaryTableValueCell
          year={year}
          type="readonly"
          column={column}
          line={line}
          rowData={rowData}
          loading={loading}
          cellData={rowData.values?.[year]}
          withDividerRight
        />
      )
  }
}
