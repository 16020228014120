import { type RowData, rowId } from '@via/compute'
import { type BlueprintVersion, type DataKey, dataKeyAttributes, findFractionOf, resolveAlias } from '@via/schema'
import { orderBy } from 'lodash-es'

import { computeMonetaryRowProportions } from './compute'
import { monetaryRowData, type MonetaryRowDataOptions } from './conversion'
import { type MonetaryRowData } from './MonetaryRowData'

export const monetaryRowFactory = <Weight extends string>(
  dataKeys: ReadonlyArray<DataKey>,
  rows: ReadonlyArray<RowData>,
  blueprintVersion: BlueprintVersion,
  options?: MonetaryRowDataOptions<Weight>
): ReadonlyArray<MonetaryRowData> =>
  dataKeys.flatMap((dataKey) => {
    const fractionOf = findFractionOf(dataKey, blueprintVersion)
    const denominator = fractionOf && (rows.find((row) => row.key === fractionOf) as RowData<'total' | 'composite'>)

    const resolvedDataKey = resolveAlias(dataKey, blueprintVersion)
    if (!resolvedDataKey) {
      return []
    }
    const attribute = dataKeyAttributes(resolvedDataKey)

    const toMonetaryRowData = (selectedRows: ReadonlyArray<RowData>, specifiedOptions = options) =>
      selectedRows
        .map((row) => monetaryRowData(rowId(row), row, blueprintVersion, specifiedOptions))
        .map((row) => (denominator ? computeMonetaryRowProportions(row, denominator) : row))

    if (attribute.type === 'aggregate') {
      const aggregatedRows = toMonetaryRowData(
        rows.filter((row) => (attribute.dependencies as ReadonlyArray<DataKey>).includes(row.key))
      )

      return attribute.sortKey ? orderBy(aggregatedRows, attribute.sortKey, attribute.sortDirection) : aggregatedRows
    }
    if (attribute.type === 'computation-reference') {
      return toMonetaryRowData(
        rows.filter((row) => row.key === attribute.source),
        { computation: attribute.value }
      )
    }

    return toMonetaryRowData(rows.filter((row) => row.key === resolvedDataKey))
  })
