import { forwardRef, type HtmlHTMLAttributes } from 'react'

import { AddItemButton, type AddItemButtonProps } from '../../atoms/AddItemButton/AddItemButton'
import { TabTrigger } from '../../atoms/TabTrigger/TabTrigger'
import { cn } from '../../lib/utils'
import { type NavigationHandler, type NavigationItem } from '../../navigation/types'

export interface TabListData {
  readonly tabs: Array<NavigationItem & { title: string }>
  readonly activeTabId?: string
}

export interface TabListActions {
  readonly onAddClick?: AddItemButtonProps['onClick']
  readonly onNavigationChange: NavigationHandler
  readonly variant?: 'default' | 'blue'
}

export type TabListProps = TabListData & TabListActions & HtmlHTMLAttributes<HTMLDivElement>

export const TabList = forwardRef<HTMLDivElement, TabListProps>(
  ({ className, tabs, activeTabId, variant, onAddClick, onNavigationChange, ...props }, ref) => (
    <div className={cn('flex min-h-8 items-center gap-2', className)} ref={ref} {...props}>
      <div className="flex items-start gap-1">
        {tabs.map((tab) => (
          <TabTrigger
            key={tab.id}
            item={tab}
            title={tab.title}
            variant={variant}
            active={tab.id === activeTabId}
            onNavigationChange={onNavigationChange}
          />
        ))}
      </div>
      {onAddClick && <AddItemButton onClick={onAddClick} />}
    </div>
  )
)
