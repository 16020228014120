import type React from 'react'
import { useIntl } from 'react-intl'

import { InputFormField } from '../../molecules/InputFormField/InputFormField'

import { type LoanDetailFormData } from './LoanFormProvider'

export const LoanDurationWithoutCapitalForm: React.FC = () => {
  const intl = useIntl()
  return (
    <div className="grid grid-cols-4 items-end gap-4">
      <InputFormField<LoanDetailFormData, 'durationWithoutCapitalPaid'>
        name="durationWithoutCapitalPaid"
        type="number"
        label={intl.formatMessage({ id: 'loan.form.without-capital.duration' })}
      />
    </div>
  )
}
