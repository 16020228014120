import React, { useState } from 'react'

import { TextTabTrigger } from '../../atoms/TextTabTrigger/TextTabTrigger'
import { cn } from '../../lib/utils'

export interface TextTabData {
  title: string
  tabContent: React.ReactNode
}

export interface TextTabListProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  readonly tabs: ReadonlyArray<TextTabData>
  readonly activeIndex?: number
}

export const TextTabList = React.forwardRef<HTMLDivElement, TextTabListProps>(
  ({ className, tabs, activeIndex, ...props }, ref) => {
    const [currentActiveIndex, setCurrentActiveIndex] = useState<number>(activeIndex ?? 0)

    return (
      <div className={cn('flex w-full flex-col gap-2', className)}>
        <div className={cn('inline-flex items-start justify-start gap-1', className)} ref={ref} {...props}>
          {tabs.map((tab, index) => (
            <TextTabTrigger
              key={tab.title}
              active={currentActiveIndex === index}
              title={tab.title}
              onClick={() => {
                setCurrentActiveIndex(index)
              }}>
              {tab.tabContent}
            </TextTabTrigger>
          ))}
        </div>
        <div className="mt-4 w-full">
          {tabs.map((tab, index) => (
            <div key={tab.title} className={cn('w-full', currentActiveIndex === index ? 'visible' : 'hidden')}>
              {tab.tabContent}
            </div>
          ))}
        </div>
      </div>
    )
  }
)
