import { type PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { type FieldValues, useFormContext, useWatch } from 'react-hook-form'

import { useDeepCompareEffect } from '@react-hookz/web/useDeepCompareEffect/index.js'
import { handleNotAwaitedPromise } from '@via/frontend-sentry'
import { useDebouncedCallback } from 'use-debounce'

import { FormSubmitContext } from './FormSubmitContext'
import { useFormSubmit } from './useFormSubmit'

export type AutomaticFormSubmitProviderProps = PropsWithChildren<{
  readonly wait?: number
}>

export const AutomaticFormSubmitProvider = <
  TFieldValues extends FieldValues,
  TContext,
  TransformedValues extends FieldValues,
>({
  children,
  wait = 1000,
}: AutomaticFormSubmitProviderProps) => {
  const { formState } = useFormContext<TFieldValues, TContext, TransformedValues>()
  const formData = useWatch<TFieldValues>()
  const { submit } = useFormSubmit()

  const debouncedSubmit = useDebouncedCallback(() => {
    handleNotAwaitedPromise(submit())
  }, wait)

  useEffect(
    () => () => {
      debouncedSubmit.flush()
    },
    [debouncedSubmit]
  )

  useDeepCompareEffect(() => {
    if (formState.isDirty && formState.isValid) {
      debouncedSubmit()
    } else {
      debouncedSubmit.cancel()
    }
  }, [formData, formState.isDirty, formState.isValid])

  const immediateSubmit = useCallback(() => {
    debouncedSubmit.cancel()
    return submit()
  }, [debouncedSubmit, submit])

  const formSubmitContext = useMemo(
    () => ({
      submit: immediateSubmit,
    }),
    [immediateSubmit]
  )

  return <FormSubmitContext.Provider value={formSubmitContext}>{children}</FormSubmitContext.Provider>
}
