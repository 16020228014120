import { scenarioSyncState } from '../app/sync/scenarioSyncState.ts'
import { type AppRxDatabase } from '../types.ts'

import { waitForRxDocument } from './waitForRxDocument.ts'

export const waitForRxScenario = async (
  appDatabase: AppRxDatabase,
  budgetId: string,
  scenarioId: string,
  timeoutInMs = 20_000
) =>
  waitForRxDocument(
    scenarioSyncState(appDatabase, budgetId, scenarioId),
    `Timeout waiting for RxScenario ${budgetId} - ${scenarioId}`,
    timeoutInMs
  )
