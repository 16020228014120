import { type UseFormReturn } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { type MonetaryYearFormFieldTableBlueprint } from '../MonetaryYearFormFieldTable'

import { type MilkIncomeFormComputationState } from './MilkIncomeFormComputationProvider.tsx'

const MILK_INCOME_BLUEPRINT = {
  sections: [
    {
      id: 'references',
      rows: [
        { id: 'kilogram-per-hectoliter-start', type: 'divider', variant: 'default', border: 'top' },
        {
          id: 'fatContent',
          type: 'readonly',
          titleKey: 'table.milk.production.content.kilogram-per-hectoliter.fat',
          unit: 'kilogram-per-hectoliter',
          variant: 'group',
        },
        {
          id: 'proteinContent',
          type: 'readonly',
          titleKey: 'table.milk.production.content.kilogram-per-hectoliter.protein',
          unit: 'kilogram-per-hectoliter',
          variant: 'group',
        },
        {
          id: 'lactoseContent',
          type: 'readonly',
          titleKey: 'table.milk.production.content.kilogram-per-hectoliter.lactose',
          unit: 'kilogram-per-hectoliter',
          variant: 'group',
        },
        { id: 'kilogram-per-hectoliter-end', type: 'divider', variant: 'default', border: 'bottom' },
        { id: 'reference-start', type: 'divider', variant: 'reference', border: 'top' },
        {
          id: 'nonFatSolidRatio',
          type: 'readonly',
          titleKey: 'milk.income.form.table.fat.ratio',
          unit: 'none',
          variant: 'groupReference',
        },
        { id: 'reference-ratio', type: 'divider', variant: 'reference' },
        {
          id: 'proteinExcessLevel1',
          type: 'readonly',
          titleKey: 'milk.income.form.table.protein.excess.2',
          unit: 'kilogram-per-hectoliter',
          variant: 'groupReference',
        },
        {
          id: 'solidExcessLevel1',
          type: 'readonly',
          titleKey: 'milk.income.form.table.solid.excess.2',
          unit: 'kilogram-per-hectoliter',
          variant: 'groupReference',
        },
        {
          id: 'proteinExcessLevel2',
          type: 'readonly',
          titleKey: 'milk.income.form.table.protein.excess.22',
          unit: 'kilogram-per-hectoliter',
          variant: 'groupReference',
        },
        {
          id: 'solidExcessLevel2',
          type: 'readonly',
          titleKey: 'milk.income.form.table.solid.excess.22',
          unit: 'kilogram-per-hectoliter',
          variant: 'groupReference',
        },
        { id: 'reference-end', type: 'divider', variant: 'reference', border: 'bottom' },
      ],
    },
    {
      id: 'levels',
      rows: [
        {
          id: 'basePrice',
          type: 'readonly',
          titleKey: 'table.milk.price.currency-per-hectoliter.base',
          unit: 'currency-per-hectoliter',
          variant: 'head',
        },
        {
          id: 'level-1',
          type: 'title',
          variant: 'subSection',
          titleKey: 'milk.income.form.table.level-1',
        },
        {
          id: 'fatPrice',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-kilogram',
          titleKey: 'table.milk.price.currency-per-kilogram.fat',
        },
        {
          id: 'proteinLev1Price',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-kilogram',
          titleKey: 'table.milk.price.level-1.currency-per-kilogram.protein',
        },
        {
          id: 'lactoseLev1Price',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-kilogram',
          titleKey: 'table.milk.price.level-1.currency-per-kilogram.lactose',
        },
        {
          id: 'level-2',
          type: 'title',
          variant: 'subSection',
          titleKey: 'milk.income.form.table.level-2',
        },
        {
          id: 'proteinLev2Price',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-kilogram',
          titleKey: 'table.milk.price.level-2.currency-per-kilogram.protein',
        },
        {
          id: 'lactoseLev2Price',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-kilogram',
          titleKey: 'table.milk.price.level-2.currency-per-kilogram.lactose',
          bottomBorder: 'close',
        },
      ],
    },
    {
      id: 'bonus',
      rows: [
        {
          id: 'applicableBonus',
          type: 'readonly',
          titleKey: 'table.milk.price.currency-per-hectoliter.bonus',
          unit: 'currency-per-hectoliter',
          variant: 'head',
        },
        {
          id: 'applicableBonusBio',
          type: 'data',
          variant: 'subSection',
          unit: 'currency-per-hectoliter',
          titleKey: 'table.milk.price.bonus.bio.currency-per-hectoliter.applicable',
        },
        {
          id: 'bonusBio',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-hectoliter',
          titleKey: 'table.milk.price.bonus.bio.currency-per-hectoliter',
        },
        {
          id: 'bonusBioPercentage',
          type: 'data',
          variant: 'default',
          unit: 'percent',
          titleKey: 'table.milk.price.bonus.bio.percentage.applicable',
        },
        {
          id: 'applicableBonusQuality',
          type: 'data',
          variant: 'subSection',
          unit: 'currency-per-hectoliter',
          titleKey: 'table.milk.price.bonus.quality.currency-per-hectoliter.applicable',
        },
        {
          id: 'bonusQuality',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-hectoliter',
          titleKey: 'table.milk.price.bonus.quality.currency-per-hectoliter',
        },
        {
          id: 'bonusQualityPercentage',
          type: 'data',
          variant: 'default',
          unit: 'percent',
          titleKey: 'table.milk.price.bonus.quality.percentage.applicable',
        },
        {
          id: 'applicableBonusOther',
          type: 'data',
          variant: 'subSection',
          unit: 'currency-per-hectoliter',
          titleKey: 'table.milk.price.bonus.other.currency-per-hectoliter.applicable',
        },
        {
          id: 'bonusOther',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-hectoliter',
          titleKey: 'table.milk.price.bonus.other.currency-per-hectoliter',
        },
        {
          id: 'bonusOtherPercentage',
          type: 'data',
          variant: 'default',
          unit: 'percent',
          titleKey: 'table.milk.price.bonus.other.percentage.applicable',
          bottomBorder: 'close',
        },
      ],
    },
    {
      id: 'dividends',
      rows: [
        {
          id: 'grossDividends',
          type: 'readonly',
          titleKey: 'table.milk.sale.dividends.total',
          unit: 'currency',
          variant: 'head',
        },
        {
          id: 'dividendsRate',
          type: 'data',
          variant: 'group',
          unit: 'currency-per-100-kilogram',
          titleKey: 'table.milk.price.dividends.currency-per-kilogram.rate',
        },
        {
          id: 'split',
          type: 'title',
          variant: 'subSection',
          titleKey: 'milk.income.form.table.dividends.split',
        },
        {
          id: 'dividendsShareProportion',
          type: 'data',
          variant: 'default',
          unit: 'percent',
          titleKey: 'table.milk.sale.dividends.percentage.share',
          onChange: (value: string | number | undefined, formContext: UseFormReturn) => {
            formContext.setValue('dividendsRevenueProportion', value ? String(100 - Number(value)) : '100')
          },
          min: 0,
          max: 100,
        },
        {
          id: 'dividendsRevenueProportion',
          type: 'readonly',
          variant: 'default',
          unit: 'percent',
          titleKey: 'table.milk.sale.dividends.percentage.revenue',
          onChange: (value: string | number | undefined, formContext: UseFormReturn) => {
            formContext.setValue('dividendsShareProportion', value ? String(100 - Number(value)) : '100')
          },
          min: 0,
          max: 100,
        },
        {
          id: 'tax',
          type: 'title',
          variant: 'subSection',
          titleKey: 'milk.income.form.table.section.tax',
        },
        {
          id: 'dividendsTaxProportion',
          type: 'data',
          variant: 'default',
          unit: 'percent',
          titleKey: 'table.milk.sale.dividends.percentage.tax',
          bottomBorder: 'close',
          min: 0,
          max: 100,
        },
      ],
    },
  ],
} as const

export type MilkIncomeFormTableBlueprintKeys = keyof MilkIncomeFormComputationState

export const useMilkIncomeBlueprint = () => {
  const intl = useIntl()

  return {
    sections: MILK_INCOME_BLUEPRINT.sections.map((section) => ({
      ...section,
      rows: section.rows.map((row) => {
        switch (row.type) {
          case 'divider':
            return row
          default: {
            const { titleKey, ...data } = row
            return {
              ...data,
              title: intl.formatMessage({ id: titleKey }),
            }
          }
        }
      }),
    })),
  } as const satisfies MonetaryYearFormFieldTableBlueprint<MilkIncomeFormTableBlueprintKeys>
}
