import { z } from 'zod'

import { PaymentFrequencyZod } from '../enums/payment-frequency-zod'

import { MonetaryValueZod } from './monetary-value-zod'

const CapitalizationTypes = ['monthly', 'biannual'] as const
export const CapitalizationTypeZod = z.enum(CapitalizationTypes)
export type CapitalizationType = z.infer<typeof CapitalizationTypeZod>

const LoanCategories = [
  'agricultural.medium-term',
  'agricultural.long-term',
  'agricultural.shareholders-due',
  'other.short-term',
  'other.medium-term',
  'other.long-term',
] as const
export const ImportedLoanCategoryZod = z.enum(LoanCategories)
export type ImportedLoanCategory = z.infer<typeof ImportedLoanCategoryZod>

const ImportedLoanTypes = ['annuity', 'capital'] as const
export const ImportedLoanTypeZod = z.enum(ImportedLoanTypes)
export type ImportedLoanType = z.infer<typeof ImportedLoanTypeZod>

export const LoanImportedDataZod = z.object({
  current: z
    .object({
      beginCapital: MonetaryValueZod, // Viagritel: Début
      newCapital: MonetaryValueZod, // Viagritel: Nouveau
      endCapital: MonetaryValueZod, // Viagritel: Fin
      paidReimbursement: MonetaryValueZod, // Viagritel: Remboursement capital
      projectedReimbursement: MonetaryValueZod, // Viagritel: Remboursement réel 🙃
      paidInterests: MonetaryValueZod, // Viagritel: Intérêts payés
      beginDueAmount: MonetaryValueZod, // Viagritel: Exigible début
      endDueAmount: MonetaryValueZod, // Viagritel: Exigible fin
    })
    .partial(),
  description: z.string(),
  displayOrder: z.number(),
  initial: z
    .object({
      amortizationMonths: z.string(),
      annualInterestRate: z.string(),
      capitalizationType: CapitalizationTypeZod,
      disbursementAmount: MonetaryValueZod,
      disbursementDate: z.string(),
      loanType: ImportedLoanTypeZod,
    })
    .partial(),
  lender: z.string().optional(),
  loanCategory: ImportedLoanCategoryZod.optional(),
  paymentPlan: z
    .object({
      amount: MonetaryValueZod,
      frequency: PaymentFrequencyZod,
    })
    .partial(),
})
