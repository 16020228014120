import React from 'react'

import { cn } from '../../lib/utils'
import { Icons } from '../Icons/Icons'

export interface CalloutProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  readonly label: string
}

export const Callout = React.forwardRef<HTMLDivElement, CalloutProps>(({ label, className, ...props }, ref) => (
  <div className={cn('flex items-center gap-[-5px]', className)} ref={ref} {...props}>
    <Icons.LeftArrow className="text-yellow-200" />
    <div className="-ml-px flex w-full items-center gap-2 rounded-md border-2 border-yellow-200 bg-yellow-50 p-3">
      <div className="flex h-5 items-center gap-2">
        <Icons.Info className="text-yellow-800" />
      </div>
      <p className="w-40 select-none text-sm font-medium leading-none tracking-[-0.42px] text-yellow-900">{label}</p>
    </div>
  </div>
))
