import { useEffect } from 'react'

import { useAsync } from '@react-hookz/web/useAsync/index.js'
import { type BudgetData } from '@via/components'
import { handleNotAwaitedPromise } from '@via/frontend-sentry'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'

import { readConfiguration } from '../../firestore/helper/readConfiguration'

import { type Image } from './type'

export interface UseBudgetGroupLogoImagesParams {
  budget: BudgetData
}

export const useBudgetGroupLogoImages = ({ budget }: UseBudgetGroupLogoImagesParams): Image[] => {
  const [{ result: images }, { execute }] = useAsync(async () => {
    const groupImageConfiguration = await readConfiguration('ReportGroupsImages')
    const groupIds = Object.keys(budget.client.groups)
    const storage = getStorage()

    const allGroupImages = groupIds.flatMap((groupId) => {
      const { images: groupImages } = groupImageConfiguration.value[groupId] ?? { images: [] }

      return groupImages
    })

    return await Promise.all(
      allGroupImages.map(async ({ name, path }) => {
        const groupImageRef = ref(storage, path)
        const url = await getDownloadURL(groupImageRef)
        return { url, name, fullPath: path }
      })
    )
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleNotAwaitedPromise(execute()), [])

  return images
}
