import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { type ScenarioCreateData, type ScenarioCreateViewProps, useBudget, useLoadingDialog } from '@via/components'
import { nanoid } from 'nanoid'

import { useAuthenticatedFirebaseAuth } from '../../auth/useFirebaseAuth.ts'
import { useCurrentBudgetHandler } from '../../contexts/currentBudget/useCurrentBudgetHandler.ts'
import { useCurrentBudgetProductions } from '../../contexts/currentBudgetProductions/useCurrentBudgetProductions.ts'
import { useOfflineState } from '../../contexts/offlineState/useOfflineState.ts'
import { useAppNavigation } from '../../navigation/useAppNavigation.ts'

import { useCreateScenario } from './useCreateScenario.ts'

export const useScenarioCreatePage = (): ScenarioCreateViewProps => {
  const scenarioId = useMemo(() => nanoid(), [])

  const intl = useIntl()
  const { offline } = useOfflineState()
  const { onNavigationChange } = useAppNavigation()
  const { close: onCloseBudget, update: onUpdateBudget } = useCurrentBudgetHandler()

  const budget = useBudget()
  const { currentProductions, reSyncCurrentProductions } = useCurrentBudgetProductions()

  const { createScenario } = useCreateScenario({ budget, scenarioId })
  const { setLoadingDialogState } = useLoadingDialog()

  const { userDocument } = useAuthenticatedFirebaseAuth()

  const onConfirm = async (data: ScenarioCreateData) => {
    setLoadingDialogState({ open: true, label: intl.formatMessage({ id: 'scenario.wait.syncingReference' }) })
    try {
      const scenario = await createScenario(data)
      setLoadingDialogState({ open: false, label: '' })

      await onNavigationChange({
        to: '/budgets/$budgetId/scenarios/$scenarioId',
        params: { budgetId: budget._id, scenarioId: scenario._id },
        replace: true,
      })
    } finally {
      setLoadingDialogState({ open: false, label: '' })
    }
  }

  const onRefreshScenarioReference = async () => {
    setLoadingDialogState({ open: true, label: intl.formatMessage({ id: 'scenario.wait.syncingReference' }) })
    try {
      await reSyncCurrentProductions()
      setLoadingDialogState({ open: false, label: '' })
    } finally {
      setLoadingDialogState({ open: false, label: '' })
    }
  }

  return {
    offline,
    budget,
    currentProductions,
    onNavigationChange,
    onConfirm,
    onCancel: () => onNavigationChange({ to: '/budgets/$budgetId', params: { budgetId: budget._id } }),
    onCloseBudget,
    onUpdateBudget,
    onRefreshScenarioReference,
    newScenarioId: scenarioId,
    canCreateCompleteScenario: userDocument?.canCreateCompleteScenario ?? false,
  }
}
