import { type FC, type PropsWithChildren, useMemo } from 'react'

import { Navigate } from '@tanstack/react-router'
import { ScenarioContext, type ScenarioData, ScenarioRowsContext } from '@via/components'
import { useObservable, useObservableState } from 'observable-hooks'
import { map, switchMap } from 'rxjs'

import { type FirestoreScenarioDataParams } from '../../firestore/scenarios/firestoreScenarioData.ts'
import { firestoreScenarioDataWithRows$ } from '../../firestore/scenarios/firestoreScenarioDataWithRows.ts'

import { type RxdbCurrentScenarioObservableState } from './RxdbCurrentScenarioProvider.tsx'

export interface FirestoreCurrentScenarioProviderProps {
  readonly scenario: ScenarioData
}

const state$ = (params: FirestoreScenarioDataParams) =>
  firestoreScenarioDataWithRows$(params).pipe(
    map((data) => ({
      ...data,
      isLoading: false,
    }))
  )

export const FirestoreCurrentScenarioProvider: FC<PropsWithChildren<FirestoreCurrentScenarioProviderProps>> = ({
  scenario: initialScenarioData,
  children,
}) => {
  const scenarioObservableState$ = useObservable(
    (inputs$) => inputs$.pipe(switchMap(([budgetId, scenarioId]) => state$({ budgetId, scenarioId }))),
    [initialScenarioData.budgetId, initialScenarioData._id]
  )

  const { scenario, rows, isLoading } = useObservableState<RxdbCurrentScenarioObservableState>(
    scenarioObservableState$,
    { scenario: initialScenarioData, rows: [], isLoading: false }
  )

  const rowsContextValue = useMemo(() => ({ rows, loading: isLoading }), [rows, isLoading])

  if (scenario?.archived) {
    return <Navigate to="/budgets/$budgetId" params={{ budgetId: initialScenarioData.budgetId }} />
  }

  return (
    <ScenarioContext.Provider value={scenario}>
      <ScenarioRowsContext.Provider value={rowsContextValue}>{children}</ScenarioRowsContext.Provider>
    </ScenarioContext.Provider>
  )
}
