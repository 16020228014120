import { type RowData } from '@via/compute'
import { type InvestmentComputationType } from '@via/schema'

import { monetaryFromCellRowData, monetaryFromRecordRowData } from './utils.ts'

export const investmentMonetaryRowData = (
  { subvention, ...rowData }: RowData<'finance-investment' | 'finance-investment-inventory'>,
  investmentType: InvestmentComputationType
) => ({
  ...monetaryFromRecordRowData(rowData, investmentType),
  ...(subvention ? { subvention: monetaryFromCellRowData(subvention) } : {}),
  label: rowData.name,
  typeLabel: `investment.table.category.${rowData.category}`,
})
