import { type DataKeyDependenciesRecordKey, type MilkIncomeComputationType } from '@via/schema'
import { BigNumber } from 'bignumber.js'

import { protectedDivision } from '../../utils'

export const computeMilkIncomeValues = ({
  volumeSale = new BigNumber(0),
  fatContent = new BigNumber(0),
  proteinContent = new BigNumber(0),
  lactoseContent = new BigNumber(0),
  fatPrice = new BigNumber(0),
  proteinLev1Price = new BigNumber(0),
  lactoseLev1Price = new BigNumber(0),
  proteinLev2Price = new BigNumber(0),
  lactoseLev2Price = new BigNumber(0),
  bonusBio = new BigNumber(0),
  bonusBioPercentage = new BigNumber(0),
  bonusQuality = new BigNumber(0),
  bonusQualityPercentage = new BigNumber(0),
  bonusOther = new BigNumber(0),
  bonusOtherPercentage = new BigNumber(0),
  dividendsRate = new BigNumber(0),
  dividendsShareProportion = new BigNumber(0),
  dividendsTaxProportion = new BigNumber(0),
}: Partial<Record<DataKeyDependenciesRecordKey<'milk-income'>, BigNumber>>): Record<
  MilkIncomeComputationType,
  BigNumber
> => {
  const solidNonFatContent = BigNumber.sum(proteinContent, lactoseContent)
  const nonFatSolidRatio = protectedDivision(solidNonFatContent, fatContent)

  const proteinExcessLevel1 = BigNumber.max(
    0,
    protectedDivision(solidNonFatContent.minus(fatContent.times(2)).times(proteinContent), solidNonFatContent)
  )
  const solidExcessLevel1 = BigNumber.max(
    0,
    protectedDivision(solidNonFatContent.minus(fatContent.times(2)).times(lactoseContent), solidNonFatContent)
  )
  const proteinExcessLevel2 = BigNumber.max(
    0,
    protectedDivision(solidNonFatContent.minus(fatContent.times(2.2)).times(proteinContent), solidNonFatContent)
  )
  const solidExcessLevel2 = BigNumber.max(
    0,
    protectedDivision(solidNonFatContent.minus(fatContent.times(2.2)).times(lactoseContent), solidNonFatContent)
  )

  const basePrice = BigNumber.sum(
    fatPrice.times(fatContent),
    proteinLev1Price.times(proteinContent.minus(proteinExcessLevel1)),
    lactoseLev1Price.times(lactoseContent.minus(solidExcessLevel1)),
    proteinLev2Price.times(proteinExcessLevel1.minus(proteinExcessLevel2)),
    lactoseLev2Price.times(solidExcessLevel1.minus(solidExcessLevel2))
  )

  const applicableBonusBio = bonusBio.times(bonusBioPercentage.shiftedBy(-2))
  const applicableBonusQuality = bonusQuality.times(bonusQualityPercentage.shiftedBy(-2))
  const applicableBonusOther = bonusOther.times(bonusOtherPercentage.shiftedBy(-2))
  const applicableBonus = BigNumber.sum(applicableBonusBio, applicableBonusQuality, applicableBonusOther)
  const price = basePrice.plus(applicableBonus)
  const salesRevenue = price.times(volumeSale)

  const grossDividends = dividendsRate.times(
    BigNumber.sum(
      fatContent,
      proteinContent,
      proteinExcessLevel2.negated(),
      lactoseContent,
      solidExcessLevel2.negated()
    )
      .times(volumeSale)
      .shiftedBy(-2)
  )
  const dividendsTax = grossDividends.times(dividendsTaxProportion.shiftedBy(-2))
  const dividendsAssetValue = grossDividends.times(dividendsShareProportion.shiftedBy(-2))
  const netDividends = grossDividends.minus(dividendsTax)
  const dividendsRevenue = netDividends.minus(dividendsAssetValue)
  const dividendsRevenueProportion = protectedDivision(dividendsRevenue, grossDividends).shiftedBy(2)

  const total = salesRevenue.plus(grossDividends)

  return {
    nonFatSolidRatio,
    proteinExcessLevel1,
    solidExcessLevel1,
    proteinExcessLevel2,
    solidExcessLevel2,
    basePrice,
    applicableBonusBio,
    applicableBonusQuality,
    applicableBonusOther,
    applicableBonus,
    price,
    salesRevenue,
    grossDividends,
    dividendsTax,
    dividendsRevenueProportion,
    dividendsRevenue,
    dividendsAssetValue,
    total,
  }
}
