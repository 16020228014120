import { type ProductionType } from '../../../enums'
import { AssetAttributesJson, AssetInventoryAttributesJson, AssetTotalAttributesJson } from '../../generated'

import { type InventoryDataKey } from './inventory-attributes'

export interface AssetTotalDataKeyAttribute<
  Deps extends AssetDataKey | AssetTotalDataKey = AssetDataKey | AssetTotalDataKey,
> {
  readonly type: 'finance-asset-total'
  readonly readonly: true
  readonly dependencies: [Deps, ...ReadonlyArray<Deps>]
}

export interface AssetDataKeyAttribute {
  readonly type: 'finance-asset'
  readonly readonly: false
  readonly multipleValues: boolean
  readonly dependencies?: ['milk-quota'] | ['milk-income'] | []
  readonly production: ProductionType
  readonly blueprintVersions?: ['complete-2024']
}

export interface AssetInventoryDataKeyAttribute<Deps extends InventoryDataKey = InventoryDataKey> {
  readonly type: 'finance-asset-inventory'
  readonly readonly: true
  readonly multipleValues: false
  readonly production: ProductionType
  readonly dependencies: [Deps]
  readonly inventory: Deps
  readonly blueprintVersions?: ['complete-2024']
}

export const AssetDataKeyAttributes = {
  ...AssetAttributesJson,
  'finance.asset.quota.milk': {
    ...AssetAttributesJson['finance.asset.quota.milk'],
    dependencies: ['milk-quota'],
  },
  'finance.asset.other.agricultural.milk.dividends': {
    ...AssetAttributesJson['finance.asset.other.agricultural.milk.dividends'],
    blueprintVersions: ['complete-2024'],
    dependencies: ['milk-income'],
  },
} as Record<keyof typeof AssetAttributesJson, AssetDataKeyAttribute>

export const AssetTotalDataKeyAttributes = AssetTotalAttributesJson as Record<
  keyof typeof AssetTotalAttributesJson,
  AssetTotalDataKeyAttribute
>

export const AssetInventoryDataKeyAttributes = AssetInventoryAttributesJson as Record<
  keyof typeof AssetInventoryAttributesJson,
  AssetInventoryDataKeyAttribute
>

export type AssetDataKey = keyof typeof AssetAttributesJson
export type AssetTotalDataKey = keyof typeof AssetTotalAttributesJson
