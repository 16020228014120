import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { addBreadcrumb } from '@sentry/react'
import {
  type ReportConfigurationViewProps,
  type ReportFileData,
  type ReportFileListViewProps,
  sortScenarios,
  useBudget,
  useReport,
  useReports,
} from '@via/components'
import { collections } from '@via/frontend-schema'
import { YearKeyZod } from '@via/schema'
import dayjs from 'dayjs'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { map, max, times } from 'lodash-es'
import { nanoid } from 'nanoid'

import { useInitiateDraftReportGeneration } from '../../api/useInitiateDraftReportGeneration.ts'
import { useInitiateFinalReportGeneration } from '../../api/useInitiateFinalReportGeneration.ts'
import { useRetryReportGeneration } from '../../api/useRetryReportGeneration.ts'
import { currentUser } from '../../auth/currentUser.ts'
import { useCurrentUserId } from '../../auth/useCurrentUserId.ts'
import { useCurrentBudgetHandler } from '../../contexts/currentBudget/useCurrentBudgetHandler.ts'
import { useOfflineState } from '../../contexts/offlineState/useOfflineState.ts'
import { useReportHandler } from '../../contexts/reports/useReportHandler.ts'
import { useCollection } from '../../firestore/hooks/useCollection.ts'
import { useAppNavigation } from '../../navigation/useAppNavigation.ts'
import { useBudgetGroupLogoImages } from '../../storage/hooks/useBudgetGroupLogoImages.ts'
import { useCoverImages } from '../../storage/hooks/useCoverImages.ts'
import { useBudgetReportsHandler } from '../../worker/report/useBudgetReportsHandler.ts'

const computeYears = (startingYear: number, numberOfYears: number[]) =>
  YearKeyZod.array().parse(times(max(numberOfYears) ?? 0, (number) => startingYear + number).map(String))

export const useReportsPage = (): ReportConfigurationViewProps & ReportFileListViewProps => {
  const intl = useIntl()
  const budget = useBudget()
  const budgetReportsHandler = useBudgetReportsHandler()
  const currentUserId = useCurrentUserId()
  const { offline } = useOfflineState()
  const {
    close: onCloseBudget,
    requestOwnership: onRequestOwnership,
    update: onUpdateBudget,
  } = useCurrentBudgetHandler()
  const { onNavigationChange } = useAppNavigation()

  const { mutateAsync: initiateDraftReportGeneration } = useInitiateDraftReportGeneration()
  const { mutateAsync: initiateFinalReportGeneration } = useInitiateFinalReportGeneration()
  const { mutateAsync: retryReportsGeneration } = useRetryReportGeneration()

  const handlers = useReportHandler()

  const currentReport = useReport()
  const { data: reports } = useReports()

  const currentReportFilesQueryState = useCollection<ReportFileData>({
    query: collections
      .budgets(budget._id)
      .reports(currentReport._id)
      .files.prepare({
        name: `budget ${budget._id}, report ${currentReport._id} files`,
        where: [['archived', '==', false]],
        orderBy: [['timestamp', 'desc']],
      }),
  })
  const currentReportFiles = useMemo(() => currentReportFilesQueryState.data, [currentReportFilesQueryState.data])

  const onDeleteReportClick = useCallback(
    async (fileId: string) => {
      await budgetReportsHandler.archiveReportFile(budget._id, currentReport._id, fileId)
    },
    [budgetReportsHandler, budget._id, currentReport._id]
  )

  const onDownloadReportClick = useCallback(async (fileUrl: string) => {
    const storage = getStorage()
    globalThis.location.href = await getDownloadURL(ref(storage, fileUrl))
  }, [])

  const onImageUpload = useCallback(
    (file: File): Promise<string> => {
      const uploadTimestamp = dayjs().format('YYYYMMDDTHHmmssSSS')
      const extension = file.type.split('/')[1]
      const nanoIdForUniqueness = nanoid(6)
      const uploadFilePath = `budgets/${budget._id}/reports/${currentReport._id}/user-uploads/file-${currentUser().userId}-${uploadTimestamp}-${nanoIdForUniqueness}.${extension}`

      const storage = getStorage()
      const fileRef = ref(storage, uploadFilePath)

      return new Promise((resolve, reject) => {
        const fileMetadata = {
          contentType: file.type,
          customMetadata: {
            originalFileName: file.name,
          },
        }
        uploadBytes(fileRef, file, fileMetadata)
          .then(async (uploadResult) => {
            try {
              return resolve(await getDownloadURL(uploadResult.ref))
            } catch (error) {
              addBreadcrumb({
                category: 'report',
                message: 'Error while obtaining download URL for an uploaded image',
                level: 'error',
                data: { error },
              })

              return reject({
                message: intl.formatMessage({ id: 'reportNav.image.upload.error' }),
                remove: true,
              })
            }
          })
          .catch((error) => {
            addBreadcrumb({
              category: 'report',
              message: 'Error while uploading an image',
              level: 'error',
              data: { error },
            })

            reject({ message: intl.formatMessage({ id: 'reportNav.image.upload.error' }), remove: true })
          })
      })
    },
    [budget, currentReport, intl]
  )

  const onGenerateDraftPdfClick = async () => {
    await initiateDraftReportGeneration({ budgetId: budget._id, reportId: currentReport._id })
  }

  const onGenerateFinalPdfClick = async () => {
    await initiateFinalReportGeneration({ budgetId: budget._id, reportId: currentReport._id })
  }

  const onRetryGeneratePdfClick = async (fileId: string) => {
    await retryReportsGeneration({ budgetId: budget._id, reportId: currentReport._id, fileId })
  }

  const coverImages = useCoverImages()
  const groupImages = useBudgetGroupLogoImages({ budget })

  return {
    budget,
    onCloseBudget,
    onRequestOwnership,
    onNavigationChange,

    scenarios: sortScenarios(budget.scenarios),
    selectableYears: computeYears(budget.year, map(budget.scenarios, 'numberOfYears')),

    onUpdateBudget,

    offline,
    currentReport,
    currentReportFiles,
    reports,
    currentUserId,
    onDeleteReportClick,
    onDownloadReportClick,
    onGenerateDraftPdfClick,
    onGenerateFinalPdfClick,
    onRetryGeneratePdfClick,
    onImageUpload,

    coverImages,
    groupImages,

    ...handlers,
  }
}
