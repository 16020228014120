import { mapNonNullValues, type RowData } from '@via/compute'
import { isGrowthEnabled } from '@via/schema'

import { overridableMonetaryCellData } from './cell'
import { reduceGrbfValueOverridableCellData } from './reduceGrbfValueOverridableCellData.ts'

export const grbfMonetaryRowData = ({ references, values, reference, growth, total, ...rowData }: RowData<'grbf'>) => ({
  references: mapNonNullValues(references, overridableMonetaryCellData),
  values: reduceGrbfValueOverridableCellData(
    values,
    (reference?.baseValue || reference?.override) && (!isGrowthEnabled(rowData.key) || growth?.override)
      ? 'default'
      : 'imported',
    (cellData, previousValue) => {
      if (cellData.override?.growth) {
        return { growth: Number(cellData.override.growth) }
      }
      if (previousValue?.growth) {
        return { growth: previousValue.growth }
      }
      if (growth?.value) {
        return { growth: Number(growth.value) }
      }
      return {}
    }
  ),
  ...(reference ? { reference: overridableMonetaryCellData(reference, 'reference') } : {}),
  ...(growth ? { growth: overridableMonetaryCellData(growth, 'reference') } : {}),
  ...(total ? { total: overridableMonetaryCellData(total, 'total') } : {}),
  ...rowData,
})

export const weightedGrbfMonetaryRowData = ({ weighted, ...rowData }: RowData<'grbf'>, weight?: string) =>
  grbfMonetaryRowData({
    ...rowData,
    ...(weight ? weighted?.[weight] : {}),
  })
