import { type InputHTMLAttributes } from 'react'
import { type FieldPathByValue, type FieldValues } from 'react-hook-form'

import { type Unit } from '@via/schema'
import { type Except } from 'type-fest'

import { FormControl } from '../../atoms/Form/FormControl.tsx'
import { FormField, type FormFieldProps } from '../../atoms/Form/FormField.tsx'
import { FormItem } from '../../atoms/Form/FormItem.tsx'
import { UnitLabel } from '../../atoms/UnitLabel/UnitLabel.tsx'
import { cn } from '../../lib/utils.ts'

export interface InlineEditingCellFormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends Except<FormFieldProps<TFieldValues, TName>, 'render'>,
    Except<InputHTMLAttributes<HTMLInputElement>, 'disabled' | 'type' | 'value' | 'defaultValue' | 'onBlur' | 'name'> {
  readonly unit?: Unit
}

export const InlineEditingCellFormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  disabled,
  className,
  onChange,
  unit,
  step = 0.01,
  tabIndex = 0,
  ...props
}: InlineEditingCellFormFieldProps<TFieldValues, TName>) => (
  <FormField<TFieldValues, TName>
    name={name}
    rules={rules}
    shouldUnregister={shouldUnregister}
    defaultValue={defaultValue}
    control={control}
    disabled={disabled}
    render={({ field: { onChange: formFieldOnChange, ...field } }) => (
      <FormItem className="w-full">
        <FormControl>
          <div className="relative">
            <input
              className={cn(
                'flex h-6 w-full shrink-0 flex-col items-end justify-end overflow-hidden border border-dashed border-black p-1 text-right text-sm font-normal leading-none tracking-[-0.28px] text-black outline-none focus:border-black focus:ring-0 focus-visible:outline-none focus-visible:ring-0',
                unit ? 'pr-[3rem]' : '',
                className
              )}
              type="number"
              step={step}
              tabIndex={tabIndex}
              onChange={(e) => {
                formFieldOnChange(e)
                onChange?.(e)
              }}
              {...props}
              {...field}
            />
            {unit && (
              <div className="absolute inset-y-0 right-[calc(0.5rem-4px)] select-none">
                <UnitLabel unit={unit} dimension="xs" value={field.value} />
              </div>
            )}
          </div>
        </FormControl>
      </FormItem>
    )}
  />
)
