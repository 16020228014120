import { z } from 'zod'

import { FormFiniteNumberValueZod } from '../atoms/Form/FormFiniteNumberValueZod.ts'
import { AdjustmentZod } from '../molecules/AdjustmentInput/AdjustmentZod.ts'

export const MonetaryCellFormZod = z.object({
  growth: FormFiniteNumberValueZod,
  override: AdjustmentZod,
  note: z.string().optional().default(''),
})

export type MonetaryCellFormData = z.infer<typeof MonetaryCellFormZod>

export const applyMonetaryCellFormOverride = (
  override: MonetaryCellFormData['override'],
  startingBalance: number
): number => {
  switch (true) {
    case !override.value:
      return startingBalance
    case override.operator === '+' && override.value !== '0':
      return startingBalance + Number(override.value)
    case override.operator === '-' && override.value !== '0':
      return startingBalance - Number(override.value)
    case override.operator === '=':
      return Number(override.value)
    default:
      return startingBalance
  }
}
