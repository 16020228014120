import { z } from 'zod'

import { MonetaryValueZod, ProportionValueZod } from './monetary-value-zod'

export const MilkQuotaPeriodZod = z.string().regex(/^\d{4}-\d{2}$/)
const MilkQuotaProductionValueZod = z.string()

export const MilkQuotaMonthStateZod = z.object({
  purchase: MilkQuotaProductionValueZod.optional(), // kgMG/jr
  sale: MilkQuotaProductionValueZod.optional(), // kgMG/jr
  adjustment: ProportionValueZod.optional(), // %
  startUp: MilkQuotaProductionValueZod.optional(), // kgMG/jr
  rental: MilkQuotaProductionValueZod.optional(), // kgMG/jr
  rentalCost: MonetaryValueZod.optional(), // $/kgMG/jr
  loan: ProportionValueZod.optional(), // %
  federationExtensionDays: MilkQuotaProductionValueZod.optional(), // days
  federationExtensionUsage: ProportionValueZod.optional(), // %
})

export const ScenarioMilkQuotaStateZod = z.record(MilkQuotaPeriodZod, MilkQuotaMonthStateZod)
