import { ScenarioStateComputedSnapshotZod } from '@via/schema'
import { getBlob, getStorage, ref } from 'firebase/storage'

export const getComputedScenarioData = async (budgetId: string, scenarioId: string, stateId: string) => {
  const storage = getStorage()
  const computedRef = ref(storage, `budgets/${budgetId}/scenarios/${scenarioId}/states/${stateId}/computed.json`)
  const computedBlob = await getBlob(computedRef)

  const computedJson = await computedBlob.text()
  return ScenarioStateComputedSnapshotZod.parse(JSON.parse(computedJson))
}
