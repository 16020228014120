import { type BlueprintVersion } from '../../../enums'
import { isDataKey } from '../is-data-key'
import { type DataAttribute } from '../types'

import { resolveAttributes } from './resolve-attributes'

export const resolveAttributesOrThrow = (key: string, blueprintVersion?: BlueprintVersion): DataAttribute => {
  if (!isDataKey(key)) {
    throw new Error(`Invalid data key: ${key}`)
  }
  const attributes = resolveAttributes(key, blueprintVersion)
  if (!attributes) {
    throw new Error(`Could not resolve attributes: ${key}`)
  }
  return attributes
}
