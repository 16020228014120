import { ReferenceAttributesJson } from '../../generated'

export interface ReferenceDataAttribute {
  readonly type: 'reference'
  readonly readonly: true
  readonly previousKeyNames?: ReadonlyArray<string>
}

const MilkReferenceDataKeyAttributes = {
  'milk.sales.total.amount': { type: 'reference', readonly: true },
  'milk.sales.total.quantity': { type: 'reference', readonly: true },
  'milk.subsidies.amount': { type: 'reference', readonly: true },
  'milk.dividends.amount': { type: 'reference', readonly: true },
  'milk.deductions.family.allowance.quantity': { type: 'reference', readonly: true },
  'milk.marketing.quota.amount': { type: 'reference', readonly: true },
  'milk.marketing.export.amount': { type: 'reference', readonly: true },
  'milk.sales.cows.breed.amount': { type: 'reference', readonly: true },
  'milk.sales.cows.calf.amount': { type: 'reference', readonly: true },
  'milk.sales.cows.cull.amount': { type: 'reference', readonly: true },
  'milk.sales.cows.embryos.amount': { type: 'reference', readonly: true },
  'milk.sales.livestock.amount': { type: 'reference', readonly: true },
} as const satisfies Record<string, ReferenceDataAttribute>

export const ReferenceDataKeyAttributes = {
  ...(ReferenceAttributesJson as Record<keyof typeof ReferenceAttributesJson, ReferenceDataAttribute>),
  ...MilkReferenceDataKeyAttributes,
}

export type ReferenceDataKey = keyof typeof ReferenceDataKeyAttributes
