import { type BlueprintVersion } from '../../../enums'
import { type ComputationType, type ComputationTypeDataKey } from '../computation-type-data-key'
import { resolveAlias } from '../utils/resolve-alias'

import { isComputationTypeDataKey } from './is-computation-type-data-key'

export const isResolvedComputationTypeDataKey = <Computation extends ComputationType>(
  key: string,
  blueprintVersion?: BlueprintVersion,
  ...types: Computation[]
): key is ComputationTypeDataKey<Computation> => {
  if (isComputationTypeDataKey(key, 'alias', 'blueprint-alias')) {
    const aliasKey = resolveAlias(key, blueprintVersion)
    return aliasKey ? isComputationTypeDataKey(aliasKey, ...types) : false
  }
  return isComputationTypeDataKey(key, ...types)
}
