import type React from 'react'

import { RepaymentCapacityView } from '@via/components'

import { useScenarioEditableRepaymentCapacityPage } from './useScenarioEditableRepaymentCapacityPage.ts'
import { useScenarioReadonlyRepaymentCapacityPage } from './useScenarioReadonlyRepaymentCapacityPage.ts'

const ScenarioEditableRepaymentCapacityPage: React.FC = () => {
  const props = useScenarioEditableRepaymentCapacityPage()

  return <RepaymentCapacityView key={props.scenario._id} {...props} />
}

const ScnearioReadonlyRepaymentCapacityPage: React.FC = () => {
  const props = useScenarioReadonlyRepaymentCapacityPage()

  return <RepaymentCapacityView key={props.scenario._id} snapshot {...props} />
}

export const ScenarioRepaymentCapacityPage: React.FC<{ readonly readonly: boolean }> = ({ readonly }) =>
  readonly ? <ScnearioReadonlyRepaymentCapacityPage /> : <ScenarioEditableRepaymentCapacityPage />
