import { type FC, useCallback, useState } from 'react'

import { Button } from '../../atoms/Button/Button'
import { Label } from '../../atoms/Label/Label'
import { Dialog, DialogContent, DialogDescription, DialogOverlay, DialogPortal, DialogTitle } from '../Dialog/Dialog'

export interface ConfirmationDialogProps {
  readonly open: boolean
  onConfirm(): Promise<void>
  readonly confirmLabel: string
  onCancel?(): Promise<void> | void
  readonly cancelLabel: string
  readonly title: string
  readonly subtitle?: string
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  onConfirm,
  confirmLabel,
  onCancel,
  cancelLabel,
  title,
  subtitle,
}) => {
  const [cancelLoading, setCancelLoading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const cancel = useCallback(async () => {
    setCancelLoading(true)
    try {
      await onCancel?.()
    } finally {
      setCancelLoading(false)
    }
  }, [onCancel, setCancelLoading])

  const confirm = useCallback(async () => {
    setConfirmLoading(true)
    try {
      await onConfirm()
    } finally {
      setConfirmLoading(false)
    }
  }, [onConfirm, setConfirmLoading])

  return (
    <Dialog open={open}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          onEscapeKeyDown={async (event) => {
            await cancel()
            event.preventDefault()
          }}>
          <div className="flex flex-col items-center gap-6 px-12 py-16">
            <div className="flex flex-col items-center justify-center gap-2">
              <DialogTitle asChild>
                <Label className="text-2xl font-bold">{title}</Label>
              </DialogTitle>
              {subtitle ? (
                <div className="flex flex-col items-center gap-2">
                  <DialogDescription asChild>
                    <Label className="text-center text-base font-light">{subtitle}</Label>
                  </DialogDescription>
                </div>
              ) : (
                <DialogDescription />
              )}
            </div>
            <div className="flex gap-4">
              <Button
                variant="outline"
                className="focus:outline-none"
                onClick={cancel}
                loading={cancelLoading}
                disabled={cancelLoading || confirmLoading}>
                {cancelLabel}
              </Button>
              <Button
                className="focus:outline-none"
                onClick={confirm}
                loading={confirmLoading}
                disabled={cancelLoading || confirmLoading}>
                {confirmLabel}
              </Button>
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}
