import { createContext } from 'react'

import { type DataKey } from '@via/schema'

export interface MonetaryTableStateSetterState<TableStates extends string = string, TabValues extends string = string> {
  readonly onExpandReference?: (expanded: boolean) => void
  readonly onBlueprintStateChange?: (tab: TableStates) => void
  readonly onTabChange?: (tab: TabValues) => void
  readonly onWeightChange?: (weight?: DataKey) => void
}

export const MonetaryTableStateSetterContext = createContext<MonetaryTableStateSetterState>({})
