import { type ApiMutationOptions, useApi } from './mutation/useApi.ts'

export interface DuplicateBudgetVariables {
  readonly budgetId: string
  readonly budgetName: string
}

export const useDuplicateBudget = (options?: ApiMutationOptions<void, DuplicateBudgetVariables>) =>
  useApi<void, DuplicateBudgetVariables>({
    type: 'duplicateBudget',
    ...options,
  })
