import { createContext, type FC, type PropsWithChildren, useMemo } from 'react'

import { type NavigateOptions, useNavigate } from '@tanstack/react-router'
import { type NavigationHandler } from '@via/components'

export interface AppNavigation {
  readonly onNavigationChange: NavigationHandler
}

export const AppNavigationContext = createContext<AppNavigation>({
  onNavigationChange() {
    throw new Error('AppNavigationContext not implemented')
  },
})

export const AppNavigationProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const value = useMemo<AppNavigation>(
    () => ({
      onNavigationChange: (options) => navigate(options as NavigateOptions),
    }),
    [navigate]
  )
  return <AppNavigationContext.Provider value={value}>{children}</AppNavigationContext.Provider>
}
