import type React from 'react'

import { ScenarioResultsView } from '@via/components'

import { useScenarioEditableResultsPage } from './useScenarioEditableResultsPage.ts'
import { useScenarioReadonlyResultsPage } from './useScenarioReadonlyResultsPage.ts'

export interface ScenarioResultsPageProps {
  readonly readonly?: boolean
}

const ScenarioReadonlyResultsPage: React.FC = () => {
  const readonlyProps = useScenarioReadonlyResultsPage()

  return <ScenarioResultsView key={readonlyProps.scenario._id} snapshot {...readonlyProps} />
}

const ScenarioEditableResultsPage: React.FC = () => {
  const editableProps = useScenarioEditableResultsPage()

  return <ScenarioResultsView key={editableProps.scenario._id} {...editableProps} />
}

export const ScenarioResultsPage: React.FC<ScenarioResultsPageProps> = ({ readonly = false }) =>
  readonly ? <ScenarioReadonlyResultsPage /> : <ScenarioEditableResultsPage />
