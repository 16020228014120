import { type Except } from 'type-fest'

import { type DataKey } from '../../data-key-zod'
import { MovementOverridableAttributesJson, MovementTotalAttributesJson } from '../../generated'

import { type ArithmeticAttribute } from './arithmetic-attribute'
import { type OverridableReferenceDataKey } from './overridable-reference-attributes'
import { type ReferenceDataKey } from './reference-attributes'

export interface MovementOverridableDataAttribute {
  readonly type: 'movement-overridable'
  readonly readonly: false
  readonly referenceDependencies?: {
    readonly balance?: ReadonlyArray<ReferenceDataKey>
    readonly movement?: ReadonlyArray<ReferenceDataKey>
  }
}

export interface MovementTotalDataAttribute<Deps extends DataKey = DataKey> {
  readonly type: 'movement-total'
  readonly readonly: true
  readonly dependencies: [Deps, ...ReadonlyArray<Deps>]
  readonly arithmetic?: ArithmeticAttribute<Deps>
  readonly referenceDependencies?: {
    readonly balance?: ReadonlyArray<ReferenceDataKey | OverridableReferenceDataKey>
    readonly movement?: ReadonlyArray<ReferenceDataKey>
  }
}

export type MovementOverridableDataKey = keyof typeof MovementOverridableAttributesJson

export const MovementOverridableDataKeyAttributes = MovementOverridableAttributesJson as Record<
  MovementOverridableDataKey,
  MovementOverridableDataAttribute
>

export type MovementTotalDataKey = keyof typeof MovementTotalAttributesJson

const totalAttributes = <K extends MovementTotalDataKey, Deps extends DataKey = DataKey>(
  key: K,
  data: Pick<MovementTotalDataAttribute<Deps>, 'dependencies' | 'arithmetic' | 'referenceDependencies'>
) =>
  ({
    [key]: {
      ...(MovementTotalAttributesJson[key] as Except<
        MovementTotalDataAttribute,
        'dependencies' | 'referenceDependencies'
      >),
      ...data,
    },
  }) as Record<K, MovementTotalDataAttribute<Deps>>

export const MovementTotalDataKeyAttributes = {
  // ...MovementTotalAttributesJson,
  ...totalAttributes('finance.asset.inventory.breeders', {
    dependencies: ['finance.inventory.breeders.movement.total'],
    referenceDependencies: {
      balance: ['finance.asset.inventory.imported.breeders.amount'],
    },
  }),
  ...totalAttributes('finance.asset.short.inventory.cereals', {
    dependencies: ['finance.inventory.crops.movement.total', 'finance.inventory.vegetal.movement.total'],
    referenceDependencies: {
      balance: [
        'finance.asset.inventory.imported.crops.amount',
        'finance.asset.inventory.imported.other-vegetal.amount',
      ],
    },
  }),
  ...totalAttributes('finance.asset.short.inventory.forage', {
    dependencies: ['finance.inventory.forage.movement.total'],
    referenceDependencies: {
      balance: ['finance.asset.inventory.imported.forage.amount'],
    },
  }),
  ...totalAttributes('finance.asset.short.inventory.miscellaneous', {
    dependencies: ['finance.inventory.commercial.movement.total', 'finance.inventory.maple.movement.total'],
    referenceDependencies: {
      balance: ['finance.asset.inventory.imported.miscellaneous.amount'],
    },
  }),
  ...totalAttributes('finance.asset.short.inventory.slaughter', {
    dependencies: ['finance.inventory.slaughter.movement.total'],
    referenceDependencies: {
      balance: ['finance.asset.inventory.imported.slaughter.amount'],
    },
  }),
  ...totalAttributes('finance.short.liquidities', {
    dependencies: [
      'finance.short.assets.programs',
      'finance.short.assets.receivables',
      'finance.loan.total.liquidity-movement',
      'finance.short.liabilities.credit',
      'finance.short.liabilities.payables',
      'finance.investment.total.disbursement',
      'finance.net-margin.total',
    ],
    arithmetic: {
      'finance.short.assets.programs': { multiplier: -1 },
      'finance.short.assets.receivables': { multiplier: -1 },
      'finance.investment.total.disbursement': { multiplier: -1 },
    },
    referenceDependencies: {
      balance: ['finance.short.imported.liquidities.balance'],
    },
  }),
  ...totalAttributes('milk.quota.tolerance.kilogram-of-fat', {
    dependencies: ['milk-quota.tolerance-movement'],
    referenceDependencies: {
      balance: ['milk.quota.tolerance.kilogram-of-fat.reference.balance'],
    },
  }),
} as Record<MovementTotalDataKey, MovementTotalDataAttribute>
