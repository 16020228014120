import { type FC, Fragment, useCallback } from 'react'
import { useController } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { useControllableState } from '@radix-ui/react-use-controllable-state'
import { size, sortBy } from 'lodash-es'
import { nanoid } from 'nanoid'

import { AddItemButton } from '../../atoms/AddItemButton/AddItemButton'
import { FormFieldContext } from '../../atoms/Form/FormFieldContext'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { Input } from '../../atoms/Input/Input'
import { Label } from '../../atoms/Label/Label'
import { DateInput } from '../../molecules/DateInput/DateInput'

import { type LoanDetailFormData } from './LoanFormProvider'

const FieldContext = { name: 'newCapitalBorrowed' } as const

export const LoanShareholderDueRepaymentForm: FC = () => {
  const intl = useIntl()
  const { field } = useController<LoanDetailFormData, 'earlyRepayment'>({ name: 'earlyRepayment' })
  const [state, setState] = useControllableState({ prop: field.value, onChange: field.onChange })

  const addNewItem = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      [nanoid()]: {
        index: size(prevState),
        date: '',
        value: '',
        withdrawal: '',
      },
    }))
  }, [setState])

  return (
    <FormFieldContext.Provider value={FieldContext}>
      <>
        <div className="grid grid-cols-11 gap-0.5">
          <Label className="col-span-2 bg-gray-100 p-1.5 text-end font-bold">
            {intl.formatMessage({ id: 'loan.form.shareholders-due.repayment.index' })}
          </Label>
          <Label className="col-span-3 bg-gray-100 p-1.5 text-end font-bold">
            {intl.formatMessage({ id: 'loan.form.shareholders-due.repayment.date' })}
          </Label>
          <Label className="col-span-3 bg-gray-100 p-1.5 text-end font-bold">
            {intl.formatMessage({ id: 'loan.form.shareholders-due.repayment.value' })}
          </Label>
          <Label className="col-span-3 bg-gray-100 p-1.5 text-end font-bold">
            {intl.formatMessage({ id: 'loan.form.shareholders-due.repayment.withdrawals' })}
          </Label>

          {sortBy(Object.entries(state ?? {}), '[1].index').map(([id, row], index) => (
            <Fragment key={id}>
              <div className="col-span-2 self-center justify-self-center">{index + 1}</div>
              <div className="col-span-3">
                <DateInput
                  align="right"
                  onChange={(event) => {
                    setState((prevState) => ({ ...prevState, [id]: { ...row, date: event.target.value } }))
                  }}
                  value={row.date}
                  className="rounded-none border-0 border-b border-b-gray-200 shadow-none focus:border-0 focus:border-b focus:border-blue-600 focus:ring-0 focus-visible:border-0 focus-visible:border-b focus-visible:border-blue-600 focus-visible:ring-0"
                />
              </div>
              <Input
                align="right"
                type="number"
                onChange={(event) => {
                  setState((prevState) => ({ ...prevState, [id]: { ...row, value: event.target.value } }))
                }}
                value={row.value}
                className="col-span-3 rounded-none border-0 border-b border-b-gray-200 bg-white shadow-none focus:border-0 focus:border-b focus:border-blue-600 focus:ring-0 focus-visible:border-0 focus-visible:border-b focus-visible:border-blue-600 focus-visible:ring-0"
              />
              <Input
                align="right"
                type="number"
                onChange={(event) => {
                  setState((prevState) => ({ ...prevState, [id]: { ...row, withdrawal: event.target.value } }))
                }}
                value={row.withdrawal}
                className="col-span-3 rounded-none border-0 border-b border-b-gray-200 shadow-none focus:border-0 focus:border-b focus:border-blue-600 focus:ring-0 focus-visible:border-0 focus-visible:border-b focus-visible:border-blue-600 focus-visible:ring-0"
              />
            </Fragment>
          ))}
        </div>
        <FormMessage className="pt-2" />
        <AddItemButton className="m-2" onClick={addNewItem} />
      </>
    </FormFieldContext.Provider>
  )
}
