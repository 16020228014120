import { type FC, Fragment, useCallback, useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { useControllableState } from '@radix-ui/react-use-controllable-state'
import { captureException } from '@sentry/react'
import { computeLoanRemainingDueAmount, startOfProjectionsDate } from '@via/compute'
import dayjs from 'dayjs'
import { size, sortBy } from 'lodash-es'
import { nanoid } from 'nanoid'

import { AddItemButton } from '../../atoms/AddItemButton/AddItemButton'
import { FormFieldContext } from '../../atoms/Form/FormFieldContext'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { Input } from '../../atoms/Input/Input'
import { Label } from '../../atoms/Label/Label'
import { useScenario } from '../../context'
import { type MonetaryLoanData } from '../../model'
import { DateInput } from '../../molecules/DateInput/DateInput'
import { FormatedNumberReadonlyInput } from '../../molecules/FormatedNumberReadonlyInput/FormatedNumberReadonlyInput'

import { convertLoanFormToScenarioState } from './convertLoanFormToScenarioState'
import { type LoanDetailFormData, type LoanFormSubmitData } from './LoanFormProvider'

const FieldContext = { name: 'newCapitalBorrowed' } as const

export const LoanEarlyRepayment: FC<{ readonly loanData?: MonetaryLoanData }> = ({ loanData }) => {
  const intl = useIntl()
  const { field } = useController<LoanDetailFormData, 'earlyRepayment'>({ name: 'earlyRepayment' })
  const [state, setState] = useControllableState({ prop: field.value, onChange: field.onChange })
  const scenario = useScenario()
  const startProjectionsDate = useMemo(() => startOfProjectionsDate(scenario), [scenario])

  const addNewItem = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      [nanoid()]: {
        index: size(prevState),
        date: '',
        value: '',
      },
    }))
  }, [setState])

  const { getValues } = useFormContext<LoanDetailFormData>()

  const computeRemainingDueAmount = useCallback(
    (itemId: string, targetDate: string) => {
      if (!startProjectionsDate) {
        return ''
      }

      const values = getValues()
      const duration = values.duration || (loanData?.duration?.baseValue ? String(loanData?.duration?.baseValue) : '')
      const interestRate =
        values.interestRate || (loanData?.interestRate?.baseValue ? String(loanData?.interestRate?.baseValue) : '')
      const paymentAmount =
        values.paymentPlan.amount ||
        (loanData?.paymentPlan?.amount?.baseValue ? String(loanData?.paymentPlan?.amount?.baseValue) : '')
      const loanState = {
        ...convertLoanFormToScenarioState(values),
        interestRate,
        duration,
        paymentAmount,
        earlyRepayment: Object.entries(values.earlyRepayment)
          .filter(([, { date, value }]) => date && value)
          .filter(([id]) => itemId !== id)
          .reduce(
            (acc, [id, { date, value }]) => ({
              ...acc,
              [id]: { date, value },
            }),
            {} as LoanFormSubmitData['earlyRepayment']
          ),
      }
      try {
        return (
          computeLoanRemainingDueAmount(
            loanState,
            startProjectionsDate,
            dayjs(targetDate, 'YYYY-MM-DD').toDate()
          )?.toString() ?? ''
        )
      } catch (error) {
        captureException(error, { data: { loanState } })
        return ''
      }
    },
    [getValues, loanData, startProjectionsDate]
  )

  return (
    <FormFieldContext.Provider value={FieldContext}>
      <>
        <div className="grid grid-cols-11 gap-0.5">
          <Label className="col-span-2 bg-gray-100 p-1.5 text-end font-bold">
            {intl.formatMessage({ id: 'loan.form.early-repayment.index' })}
          </Label>
          <Label className="col-span-3 bg-gray-100 p-1.5 text-end font-bold">
            {intl.formatMessage({ id: 'loan.form.early-repayment.date' })}
          </Label>
          <Label className="col-span-3 bg-gray-100 p-1.5 text-end font-bold">
            {intl.formatMessage({ id: 'loan.form.early-repayment.remaining-due-amount' })}
          </Label>
          <Label className="col-span-3 bg-gray-100 p-1.5 text-end font-bold">
            {intl.formatMessage({ id: 'loan.form.early-repayment.value' })}
          </Label>

          {sortBy(Object.entries(state ?? {}), '[1].index').map(([id, row], index) => (
            <Fragment key={id}>
              <div className="col-span-2 self-center justify-self-center">{index + 1}</div>
              <div className="col-span-3">
                <DateInput
                  align="right"
                  onChange={(event) => {
                    setState((prevState) => ({
                      ...prevState,
                      [id]: {
                        ...row,
                        date: event.target.value,
                        remainingDueAmount: computeRemainingDueAmount(id, event.target.value),
                      },
                    }))
                  }}
                  value={row.date}
                  className="rounded-none border-0 border-b border-b-gray-200 shadow-none focus:border-0 focus:border-b focus:border-blue-600 focus:ring-0 focus-visible:border-0 focus-visible:border-b focus-visible:border-blue-600 focus-visible:ring-0"
                />
              </div>
              <FormatedNumberReadonlyInput
                align="right"
                value={row.remainingDueAmount}
                displayType="currency-with-symbol"
                className="col-span-3 rounded-none border-0 border-b border-b-gray-200 bg-white shadow-none focus:border-0 focus:border-b focus:border-blue-600 focus:ring-0 focus-visible:border-0 focus-visible:border-b focus-visible:border-blue-600 focus-visible:ring-0"
              />
              <Input
                align="right"
                type="number"
                onChange={(event) => {
                  setState((prevState) => ({ ...prevState, [id]: { ...row, value: event.target.value } }))
                }}
                value={row.value}
                className="col-span-3 rounded-none border-0 border-b border-b-gray-200 shadow-none focus:border-0 focus:border-b focus:border-blue-600 focus:ring-0 focus-visible:border-0 focus-visible:border-b focus-visible:border-blue-600 focus-visible:ring-0"
              />
            </Fragment>
          ))}
        </div>
        <FormMessage className="pt-2" />
        <AddItemButton className="m-2" onClick={addNewItem} />
      </>
    </FormFieldContext.Provider>
  )
}
