import React from 'react'

import { type UseNetworkState, useNetworkState } from '@react-hookz/web/useNetworkState/index.js'

export const NetworkStateContext = React.createContext<UseNetworkState | null>(null)

export const NetworkStateProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const state = useNetworkState()
  return <NetworkStateContext.Provider value={state}>{children}</NetworkStateContext.Provider>
}
