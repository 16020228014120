import { type RxDocument } from 'rxdb'

import { type RxCollectionDocumentType, type RxOutput } from '../types.ts'

import { deepStringToDate } from './deepStringToDate.ts'

export const rxDocumentData = <C, T = RxCollectionDocumentType<C>>(value: RxDocument<T>): RxOutput<T> => {
  switch (value.collection.name) {
    case 'scenario-sync-states':
    case 'reference':
    case 'values':
      return value.toJSON() as RxOutput<T>
    default:
      return deepStringToDate<RxOutput<T>, T>(value.toJSON())
  }
}
