import { useContext } from 'react'

import { LoadingDialogContext } from './LoadingDialogProvider'

export const useLoadingDialog = () => {
  const setLoadingDialogState = useContext(LoadingDialogContext)
  if (!setLoadingDialogState) {
    throw new Error('useGlobalLoadingDialog must be used within a GlobalLoadingDialogContextProvider')
  }

  return setLoadingDialogState
}
