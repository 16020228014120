import { useCallback } from 'react'

import { type ScenarioResultsViewProps, useBudget, useScenario } from '@via/components'
import { type YearKey } from '@via/schema'

import { useCurrentBudgetHandler } from '../../contexts/currentBudget/useCurrentBudgetHandler.ts'
import { useCurrentScenarioHandler } from '../../contexts/currentScenario/useCurrentScenarioHandler.ts'
import { useOfflineState } from '../../contexts/offlineState/useOfflineState.ts'
import { useSyncRequired } from '../../contexts/syncRequired/useSyncRequired.ts'
import { useAppNavigation } from '../../navigation/useAppNavigation.ts'

export const useScenarioEditableResultsPage = (): ScenarioResultsViewProps => {
  const { offline } = useOfflineState()
  const { onNavigationChange } = useAppNavigation()
  const {
    close: onCloseBudget,
    requestOwnership: onRequestOwnership,
    update: onUpdateBudget,
  } = useCurrentBudgetHandler()
  const budget = useBudget()
  const scenario = useScenario()
  const syncRequired = useSyncRequired()
  const { updateScenarioReferences: onUpdateScenarioReferences } = useCurrentBudgetHandler()

  const {
    updateReference: onRefreshScenarioReference,
    updateCell: onUpdateCell,
    addEvent,
  } = useCurrentScenarioHandler()

  const onReferenceChange = useCallback(
    async (referenceYear: YearKey) => {
      if (referenceYear !== scenario.selectedReferenceYear) {
        await addEvent({
          type: 'updateSelectedReferenceYear',
          year: referenceYear,
        })
      }
    },
    [addEvent, scenario.selectedReferenceYear]
  )

  return {
    loading: false,
    offline,
    budget,
    scenario,
    syncRequired,
    onNavigationChange,
    onUpdateCell,
    onReferenceChange,
    onRefreshScenarioReference,
    onUpdateScenarioReferences,
    onCloseBudget,
    onRequestOwnership,
    onUpdateBudget,
  }
}
