import type React from 'react'

import { addBreadcrumb, captureException } from '@sentry/react'
import { createFileRoute, notFound, Outlet } from '@tanstack/react-router'
import { scenarioData } from '@via/components'

import { FirestoreCurrentScenarioProvider } from '../contexts/currentScenario/FirestoreCurrentScenarioProvider.tsx'
import { RxdbCurrentScenarioProvider } from '../contexts/currentScenario/RxdbCurrentScenarioProvider.tsx'
import { fetchFirestoreScenarioData } from '../firestore/scenarios/fetchFirestoreScenarioData.ts'
import { scenarioSyncState } from '../rxdb/app/sync/scenarioSyncState.ts'
import { waitForRxScenario } from '../rxdb/rxjs/waitForRxScenario.ts'
import { rxFetchOrThrowDocumentData } from '../rxdb/utils/rxFetchDocumentData.ts'

export const BudgetScenarioListComponent: React.FC = () => {
  const { scenarioRxDatabase, initialScenarioData } = Route.useRouteContext()

  if (!scenarioRxDatabase) {
    return (
      <FirestoreCurrentScenarioProvider key={initialScenarioData._id} scenario={initialScenarioData}>
        <Outlet />
      </FirestoreCurrentScenarioProvider>
    )
  }

  return (
    <RxdbCurrentScenarioProvider
      key={initialScenarioData._id}
      scenarioDatabase={scenarioRxDatabase}
      scenario={initialScenarioData}>
      <Outlet />
    </RxdbCurrentScenarioProvider>
  )
}

export const Route = createFileRoute('/budgets/$budgetId/scenarios/$scenarioId')({
  beforeLoad: async ({ params: { budgetId, scenarioId }, context: { scenarioHandler, appDatabase, budget } }) => {
    if (budget.readonly) {
      const firestoreScenario = await fetchFirestoreScenarioData({ budgetId, scenarioId })
      if (!firestoreScenario || firestoreScenario.archived) {
        throw notFound()
      }
      return { scenarioRxDatabase: null, initialScenarioData: firestoreScenario }
    }
    const syncState = await scenarioSyncState(appDatabase, budgetId, scenarioId).exec()
    if (!syncState) {
      addBreadcrumb({
        message: `scenario ${scenarioId} is not yet synced`,
        category: 'rxdb',
        data: { budgetId, scenarioId },
      })
      await waitForRxScenario(appDatabase, budgetId, scenarioId)
    }

    try {
      const scenarioRxDatabase = await scenarioHandler.loadScenarioDatabase(budgetId, scenarioId)
      const scenario = await rxFetchOrThrowDocumentData(scenarioRxDatabase.scenario.findOne(scenarioId))
      const scenarioState = await rxFetchOrThrowDocumentData(
        scenarioRxDatabase['scenario-states'].findOne(scenario.currentStateId)
      )
      const reference = scenarioState.referenceId
        ? await rxFetchOrThrowDocumentData(scenarioRxDatabase.reference.findOne(scenarioState.referenceId))
        : undefined

      return {
        scenarioRxDatabase,
        initialScenarioData: scenarioData(scenario, scenarioState, reference),
      }
    } catch (error) {
      captureException(error, {
        contexts: { params: { budgetId, scenarioId } },
        tags: { route: '/budgets/$budgetId/scenarios/$scenarioId' },
      })
      throw notFound()
    }
  },
  component: BudgetScenarioListComponent,
})
