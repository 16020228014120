import { useObservable, useObservableState } from 'observable-hooks'
import { map, switchMap } from 'rxjs'

import { appRxState } from '../../rxdb/app/appRxState.ts'

const isComputing$ = (scenarioId: string) =>
  appRxState.get$(`computing.${scenarioId}`).pipe(map((isComputing) => !!isComputing))

export const useIsComputingScenario = (scenarioId: string) => {
  const state$ = useObservable((inputs$) => inputs$.pipe(switchMap(([id]) => isComputing$(id))), [scenarioId])
  return useObservableState(state$, false)
}
