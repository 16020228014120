import { useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { isEmpty } from 'lodash-es'

import {
  CaretAccordion,
  CaretAccordionContent,
  CaretAccordionItem,
  CaretAccordionTrigger,
} from '../../molecules/CaretAccordion/CaretAccordion'
import { FormatedNumberReadonlyInputFormField } from '../../molecules/FormatedNumberReadonlyInputFormField/FormatedNumberReadonlyInputFormField'

import { type LoanDetailFormData } from './LoanFormProvider'

export const LoadImportedValues = () => {
  const intl = useIntl()
  const imported = useWatch<LoanDetailFormData, 'imported'>({ name: 'imported' })
  if (isEmpty(imported)) {
    return null
  }

  return (
    <CaretAccordion type="multiple">
      <CaretAccordionItem value="imported">
        <CaretAccordionTrigger id="imported" label={intl.formatMessage({ id: 'viagritel' })} />
        <CaretAccordionContent className="grid grid-cols-4 items-end gap-4">
          <FormatedNumberReadonlyInputFormField<LoanDetailFormData, 'imported.beginCapital'>
            className="col-start-1"
            name="imported.beginCapital"
            displayType="currency"
            variant="gray"
            dimension="xs"
            label={intl.formatMessage({ id: 'loan.form.imported.beginCapital' })}
          />
          <FormatedNumberReadonlyInputFormField<LoanDetailFormData, 'imported.newCapital'>
            name="imported.newCapital"
            displayType="currency"
            variant="gray"
            dimension="xs"
            label={intl.formatMessage({ id: 'loan.form.imported.newCapital' })}
          />
          <FormatedNumberReadonlyInputFormField<LoanDetailFormData, 'imported.endCapital'>
            name="imported.endCapital"
            displayType="currency"
            variant="gray"
            dimension="xs"
            label={intl.formatMessage({ id: 'loan.form.imported.endCapital' })}
          />
          <FormatedNumberReadonlyInputFormField<LoanDetailFormData, 'imported.paidInterests'>
            name="imported.paidInterests"
            displayType="currency"
            variant="gray"
            dimension="xs"
            label={intl.formatMessage({ id: 'loan.form.imported.paidInterests' })}
          />
          <FormatedNumberReadonlyInputFormField<LoanDetailFormData, 'imported.paidReimbursement'>
            name="imported.paidReimbursement"
            displayType="currency"
            variant="gray"
            dimension="xs"
            label={intl.formatMessage({ id: 'loan.form.imported.paidReimbursement' })}
          />
          <FormatedNumberReadonlyInputFormField<LoanDetailFormData, 'imported.projectedReimbursement'>
            name="imported.projectedReimbursement"
            displayType="currency"
            variant="gray"
            dimension="xs"
            label={intl.formatMessage({ id: 'loan.form.imported.projectedReimbursement' })}
          />
          <FormatedNumberReadonlyInputFormField<LoanDetailFormData, 'imported.beginDueAmount'>
            name="imported.beginDueAmount"
            displayType="currency"
            variant="gray"
            dimension="xs"
            label={intl.formatMessage({ id: 'loan.form.imported.beginDueAmount' })}
          />
          <FormatedNumberReadonlyInputFormField<LoanDetailFormData, 'imported.endDueAmount'>
            name="imported.endDueAmount"
            displayType="currency"
            variant="gray"
            dimension="xs"
            label={intl.formatMessage({ id: 'loan.form.imported.endDueAmount' })}
          />
        </CaretAccordionContent>
      </CaretAccordionItem>
    </CaretAccordion>
  )
}
