import { type MonetaryRowData } from '../../../model'
import { type MonetaryTableValueLine } from '../types.ts'

import { type MonetaryTableValueCellProps } from './MonetaryTableValueCell.tsx'

export const valueCellType = (
  rowData: MonetaryRowData,
  line: MonetaryTableValueLine
): MonetaryTableValueCellProps['type'] => {
  if (['milk.marketing.currency-per-hectoliter', 'milk.marketing.total'].includes(line.id)) {
    return 'milk-expenses'
  }

  switch (rowData.computationType) {
    case 'finance-investment':
    case 'finance-investment-inventory':
    case 'finance-loan-investment':
    case 'finance-loan':
    case 'milk-quota':
    case 'milk-income':
    case 'movement-overridable':
    case 'inventory':
      return rowData.computationType
    case 'grbf':
    case 'overridable':
      return 'result-projections'
    default:
      return 'readonly'
  }
}
