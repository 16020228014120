import { type FC, Fragment, useContext } from 'react'

import { type YearKey } from '@via/schema'

import { LineDivider, type LineDividerProps } from '../../../atoms/LineDivider/LineDivider.tsx'
import { LineFormulaStrip } from '../../../atoms/LineTitle/LineFormulaStrip.tsx'
import { LineTitle } from '../../../atoms/LineTitle/LineTitle.tsx'
import { cn } from '../../../lib/utils.ts'
import { AddMonetaryItemButton } from '../../AddMonetaryItem/AddMonetaryItemButton.tsx'
import { MonetaryTablePropsContext } from '../contexts/MonetaryTablePropsContext.ts'
import {
  MonetaryTableBlueprintContext,
  MonetaryTableCurrentBlueprintColumnsContext,
} from '../contexts/MonetaryTableStateProvider.tsx'
import { MonetaryTableStickyColumns } from '../MonetaryTableStickyColumns.tsx'
import { type MonetaryTableColumn, type MonetaryTableDividerLine, type MonetaryTableHeaderLine } from '../types.ts'

import { MonetaryTableDividerCell } from './MonetaryTableDividerCell.tsx'

const dividerVariant = (line: MonetaryTableDividerLine | MonetaryTableHeaderLine): LineDividerProps['variant'] => {
  if (line.variant === 'head') {
    return 'head'
  }
  return line.type ?? 'default'
}

interface MonetaryTableDividerTitleCellProps {
  readonly line: MonetaryTableDividerLine | MonetaryTableHeaderLine
  readonly column: MonetaryTableColumn
}

const MonetaryTableDividerTitleCell: FC<MonetaryTableDividerTitleCellProps> = ({ line, column }) => {
  const blueprint = useContext(MonetaryTableBlueprintContext)
  const { readonly } = useContext(MonetaryTablePropsContext)

  switch (line.variant) {
    case 'head':
      return (
        <LineTitle
          variant={line.variant}
          symbol={line.symbol}
          label={line.name}
          className={cn('w-84', column.className)}>
          {!readonly && line.addMonetaryItemButtonType && (
            <AddMonetaryItemButton
              className="self-center"
              blueprintVersion={blueprint.version}
              monetaryItemType={line.addMonetaryItemButtonType}
            />
          )}
        </LineTitle>
      )
    default: {
      const { border = 'top' } = line
      return (
        <div className={cn('flex', column.className)}>
          <LineFormulaStrip variant="empty" />
          <LineDivider
            variant={dividerVariant(line)}
            border={border}
            first
            withDividerLeft
            withDividerRight={column.withDividerRight}
            className={column.className}
          />
        </div>
      )
    }
  }
}

interface MonetaryTableDividerRowCellProps extends MonetaryTableDividerTitleCellProps {
  readonly references: ReadonlyArray<YearKey>
  readonly projections: ReadonlyArray<YearKey>
}

const MonetaryTableDividerRowCell: FC<MonetaryTableDividerRowCellProps> = ({
  line,
  column,
  references,
  projections,
}) => {
  const { border = 'top' } = line
  const { withDividerRight, className } = column

  switch (column.type) {
    case 'title':
    case 'title-with-add':
    case 'title-with-add-investment':
    case 'title-with-selected-tab':
      return <MonetaryTableDividerTitleCell line={line} column={column} />
    case 'divider':
      return <MonetaryTableDividerCell className={className} />
    case 'all-references':
      return references.map((year, index) => (
        <LineDivider
          key={year}
          variant={dividerVariant(line)}
          border={border}
          withDividerLeft
          withDividerRight={withDividerRight && index === references.length - 1}
          className={className}
        />
      ))
    case 'historic':
      return (
        <>
          <LineDivider variant={dividerVariant(line)} border={border} withDividerLeft className="w-24" />
          <LineDivider
            variant={dividerVariant(line)}
            border={border}
            withDividerLeft
            withDividerRight={withDividerRight}
            className="w-12"
          />
        </>
      )
    case 'all-historic':
      return references.map((year, index) => (
        <Fragment key={year}>
          <LineDivider variant={dividerVariant(line)} border={border} withDividerLeft className="w-24" />
          <LineDivider
            variant={dividerVariant(line)}
            border={border}
            withDividerLeft
            withDividerRight={withDividerRight && index === references.length - 1}
            className="w-12"
          />
        </Fragment>
      ))
    case 'result-projections':
    case 'projections':
      return projections.map((year, index) => (
        <LineDivider
          key={year}
          variant={dividerVariant(line)}
          border={border}
          withDividerLeft
          withDividerRight={withDividerRight && index === projections.length - 1}
          className={className}
        />
      ))
    case 'projections-with-proportions':
      return projections.map((year, index) => (
        <Fragment key={year}>
          <LineDivider variant={dividerVariant(line)} border={border} withDividerLeft className="w-24" />
          <LineDivider
            variant={dividerVariant(line)}
            border={border}
            withDividerLeft
            withDividerRight={withDividerRight && index === projections.length - 1}
            className="w-12"
          />
        </Fragment>
      ))
    default:
      return (
        <LineDivider
          variant={dividerVariant(line)}
          border={border}
          withDividerLeft
          withDividerRight={withDividerRight}
          className={className}
        />
      )
  }
}

interface MonetaryTableDividerRowProps extends Pick<MonetaryTableDividerRowCellProps, 'line'> {
  readonly className?: string
}

export const MonetaryTableDividerRow: FC<MonetaryTableDividerRowProps> = ({ line, className }) => {
  const blueprintColumns = useContext(MonetaryTableCurrentBlueprintColumnsContext)
  const { references, projections } = useContext(MonetaryTablePropsContext)
  const stickyColumns = blueprintColumns.filter((col) => col.sticky)
  const scrollableColumns = blueprintColumns.filter((col) => !col.sticky)

  return (
    <div className="flex shrink-0">
      <MonetaryTableStickyColumns columns={stickyColumns} className={className}>
        {(column) => (
          <MonetaryTableDividerRowCell
            key={column.id}
            line={line}
            column={column}
            references={references}
            projections={projections}
          />
        )}
      </MonetaryTableStickyColumns>

      {scrollableColumns.map((column) => (
        <MonetaryTableDividerRowCell
          key={column.id}
          line={line}
          column={column}
          references={references}
          projections={projections}
        />
      ))}
    </div>
  )
}
