import {
  type AssetType,
  type CapitalizationType,
  type ImportedLoanCategory,
  type ImportedLoanType,
  type PaymentFrequency,
  type ReferenceDataKey,
} from '@via/schema'
import { type BigNumber } from 'bignumber.js'
import { z } from 'zod'

import {
  type ViagritelAlimentColonne,
  type ViagritelCourtTermeType,
  type ViagritelCourtTermeTypeLigne,
  type ViagritelProduction,
  type ViagritelTypeLigneCompteExploitation,
  type ViagritelTypeLigneFichesTechnique,
  type ViagritelTypeLigneInventaireApprovisionnement,
  type ViagritelTypeProduction,
  type ViagritelTypeProductionMaraichere,
} from './values'

export type ComputationContext = {
  dossierId: number
  year: number
  testResolverFunctions: TestResolverFunctions
  databaseResolverFunctions: DatabaseResolverFunctions
  debug?: boolean
}

export type ComputationRecord = {
  details: {
    test?: BigNumber | null | false
    database?: BigNumber
    dependencies?: BigNumber
  }
  value: BigNumber
}

export type ComputationValues = Partial<Record<ReferenceDataKey, ComputationRecord>>

export type CriReference = `${ViagritelProduction}${number}`
export type CriReferenceFunction = (criReference: CriReference) => BigNumber

export type TestResolverFunctions = {
  cri: CriReferenceFunction
}

type TestResolverFunction = (options: TestResolverFunctions) => BigNumber

export type ConditionalTestResolverFunction = (context: { dossierId: number; year: number }) => boolean

export type TestResolver = CriReference | TestResolverFunction | ConditionalTestResolver[] | false

export type TestResolverWithMetadata = {
  test: TestResolver
  only?: boolean
}

export type TestResolverWithPossibleMetadata = TestResolver | TestResolverWithMetadata

export function isTestResolverWithMetadata(value: TestResolverWithPossibleMetadata): value is TestResolverWithMetadata {
  return typeof value === 'object' && value !== null && 'test' in value
}

type ConditionalTestResolver = {
  condition: ConditionalTestResolverFunction
  expectedValue: BigNumber
}

export type DatabaseResolverFnAnimaux = {
  (criteria: {
    production: ViagritelProduction | ViagritelProduction[]
    ligne: number | number[]
  }): ViagritelRowAnimal[]
  (criteria: {
    production: ViagritelProduction
    ligne: number
    type_ligne: ViagritelTypeLigneCompteExploitation
  }): ViagritelRowAnimal
  (criteria: {
    ligne: number | number[]
    type_ligne?: ViagritelTypeLigneCompteExploitation | ViagritelTypeLigneCompteExploitation[]
  }): ViagritelRowAnimal[]
  (criteria: {
    production: ViagritelProduction | ViagritelProduction[]
    type: ViagritelAnimalType | ViagritelAnimalType[]
  }): ViagritelRowAnimal[]
  (criteria: { production: ViagritelProduction; type: ViagritelAnimalType; ligne: number }): ViagritelRowAnimal
  (criteria: {
    production: ViagritelProduction | ViagritelProduction[]
    type: ViagritelAnimalType | ViagritelAnimalType[]
    ligne: number | number[]
  }): ViagritelRowAnimal[]
  (criteria: { type: ViagritelAnimalType | ViagritelAnimalType[] }): ViagritelRowAnimal[]
}

export type DatabaseResolverFnCompteExploitation = {
  (criteria: {
    production: ViagritelProduction | ViagritelProduction[]
    ligne: number | number[]
  }): ViagritelRowMontantQuantite[]
  (criteria: {
    ligne: number | number[]
    type_ligne: ViagritelTypeLigneCompteExploitation | ViagritelTypeLigneCompteExploitation[]
  }): ViagritelRowMontantQuantite[]
  (criteria: {
    production: ViagritelProduction
    ligne: number
    type_ligne: ViagritelTypeLigneCompteExploitation
  }): ViagritelRowMontantQuantite
}

export type DatabaseResolverFnFicheTechnique = {
  (criteria: { ligne: number | number[] }): ViagritelRowFicheTechnique[]
  (criteria: {
    ligne: number | number[]
    type_ligne: ViagritelTypeLigneFichesTechnique | ViagritelTypeLigneFichesTechnique[]
  }): ViagritelRowValeur[]
  (criteria: {
    ligne: number[]
    production?: ViagritelProduction | ViagritelProduction[]
  }): ViagritelRowFicheTechnique[]
  (criteria: { ligne: number; production: ViagritelProduction[] }): ViagritelRowFicheTechnique[]
  (criteria: { ligne: number; production: ViagritelProduction }): ViagritelRowFicheTechnique
}

export type DatabaseResolverFnImmobilisation = (criteria: {
  type: ImmoTypeLigneNo | ImmoTypeLigneNo[]
  ligne: string | string[]
}) => ViagritelRowImmobilisation[]

export type DatabaseResolverFnInventaireApprovisionnement = {
  (criteria: {
    ligne: number | number[]
    type_ligne?: ViagritelTypeLigneInventaireApprovisionnement | ViagritelTypeLigneInventaireApprovisionnement[]
    production?: ViagritelProduction | ViagritelProduction[]
  }): ViagritelRowInventaireApprovisionnement[]
  (criteria: { production: ViagritelProduction | ViagritelProduction[] }): ViagritelRowInventaireApprovisionnement[]
  (criteria: {
    type_ligne: ViagritelTypeLigneInventaireApprovisionnement | ViagritelTypeLigneInventaireApprovisionnement[]
  }): ViagritelRowInventaireApprovisionnement[]
}

export type DatabaseResolverFnMaraicher = {
  (criteria: {
    production: ViagritelTypeProductionMaraichere | ViagritelTypeProductionMaraichere[]
  }): ViagritelRowMaraicher[]
  (criteria: { type_production: ViagritelTypeProduction | ViagritelTypeProduction[] }): ViagritelRowMaraicher[]
}

type CourtTermeCriteria = {
  production?: ViagritelProduction | ReadonlyArray<ViagritelProduction>
  type_ligne?: ViagritelCourtTermeTypeLigne | ReadonlyArray<ViagritelCourtTermeTypeLigne>
  type: ViagritelCourtTermeType | ReadonlyArray<ViagritelCourtTermeType>
}
export type DatabaseResolverFnCourtTerme = (...criteria: ReadonlyArray<CourtTermeCriteria>) => ViagritelRowCourtTerme[]

export type DatabaseResolverFnAliments = (criteria: {
  production?: ViagritelProduction | ViagritelProduction[]
  colonne?: ViagritelAlimentColonne | ViagritelAlimentColonne[]
}) => ViagritelRowAliment[]

export type ConstantResolveCriteria =
  | {
      type: 'asset'
      assetType: AssetType
      code: string
      defaultValue?: BigNumber.Value
    }
  | {
      type: 'constant'
      code: string
      defaultValue?: BigNumber.Value
    }

export type DatabaseResolverFnConstant = (criteria: ConstantResolveCriteria) => BigNumber

export enum ViagritelApportRetraitLigneNo {
  ApportsDesProprietaires = 1000,
  LocationDeMaison = 1100,
  RevenusAutresEntreprises = 1200,
  AutresApportsPerso = 1300,
  AutresMisesDeFonds = 1400,
  SubventionDeCapital = 1500,
  TransfertDeFerme = 1600,
  VenteDeParts = 1700,
  AchatDeParts = 1800,
  RetraitsDesProprietaires = 1900,
  SalairesBrutsDesExploitants = 2000,
  RetraitsDesExploitantsEtDividendes = 2100,
  ImpotsPayes = 2200,
  PartiePersoDesChargesExploitation = 2300,
  Electricite = 2400,
  Telephone = 2500,
  Auto = 2600,
  Assurance = 2700,
  AutresRetraitsPerso = 2800,
  InteretsSurDuAuxActionnaires = 2900,
  RemboursementDeCapitalReelSurDuAuxActionnaires = 3000,
  InteretsSurEmpruntsNonAgricoles = 3100,
  RemboursementDeCapitalSurEmpruntsNonAgricoles = 3200,
  InvestissementsPersonnels = 3300,
  ContributionREER = 3400,
  AutresInvestissementsNonAgricoles = 3500,
  AutresInvestissementsPersonnels = 3600,
}

export type DatabaseResolverFnApportRetrait = (criteria: {
  apport_retrait_ligne_no?: ViagritelApportRetraitLigneNo | ViagritelApportRetraitLigneNo[]
}) => ViagritelRowApportRetrait[]

export type DatabaseResolverFnCerealeFourrage = (criteria: {
  production?: ViagritelProduction | ViagritelProduction[]
  type_production?: ViagritelTypeProduction | ViagritelTypeProduction[]
  ligne?: number | number[]
}) => ViagritelRowCerealeFourrage[]

export type DatabaseResolverFnEmprunts = (criteria: {
  loanCategory?: ImportedLoanCategory | ImportedLoanCategory[]
}) => ViagritelRowEmprunt[]

export type ViagritelRowMontantQuantite = {
  production: ViagritelProduction
  ligne: number
  type_ligne: ViagritelTypeLigneCompteExploitation
  montant: BigNumber
  quantite: BigNumber
}

export type ViagritelRowInventaireApprovisionnement = {
  production: ViagritelProduction
  ligne: number
  type_ligne: ViagritelTypeLigneInventaireApprovisionnement
  debut_dollars: BigNumber
  fin_dollars: BigNumber
  delta_dollars: BigNumber
}

export type ViagritelRowValeur = {
  production: ViagritelProduction
  ligne: number
  valeur: BigNumber
}

export type ViagritelRowFicheTechnique = {
  production: ViagritelProduction
  type_ligne: ViagritelTypeLigneCompteExploitation
  ligne: number
  valeur: BigNumber
}

export const ImmoTypeLigneNos = ['MA', 'BA', 'QUO', 'FTE', 'AA', 'CA'] as const
export const ImmoTypeLigneZod = z.enum(ImmoTypeLigneNos)
export type ImmoTypeLigneNo = (typeof ImmoTypeLigneNos)[number]

export type ViagritelRowImmobilisation = {
  type: ImmoTypeLigneNo
  ligne: string
  fin_quantite: BigNumber
}

export type ViagritelRowCourtTerme = {
  production?: ViagritelProduction
  type: ViagritelCourtTermeType
  type_ligne: ViagritelCourtTermeTypeLigne
  ligne_dossier_no: number
  previous_year_ligne_dossier_no: number | null
  valeur_depart: BigNumber
  valeur_fin: BigNumber
  profit_perte: BigNumber
}

export type ViagritelAnimalType = 'market' | 'breeding'

export type ViagritelRowAnimal = {
  production: ViagritelProduction
  ligne: number
  prix_tete_debut: BigNumber
  prix_tete_fin: BigNumber
  valeur_totale_debut: BigNumber
  valeur_totale_fin: BigNumber
  type: ViagritelAnimalType
  inventaire_debut: BigNumber
  inventaire_fin: BigNumber
  poids_moyen_debut: BigNumber
  poids_moyen_fin: BigNumber
}

export type ViagritelRowAliment = {
  production: ViagritelProduction
  aliment_ferme_achetes_id: number
  colonne: ViagritelAlimentColonne
  achat_dollars: BigNumber
  achat_kg: BigNumber
  debut_dollars_ton: BigNumber
  debut_kg: BigNumber
  energie: BigNumber
  fin_dollars_ton: BigNumber
  fin_kg: BigNumber
  matieres_seches: BigNumber
  perte: BigNumber
  proteine_brute: BigNumber
  quantite: BigNumber
  consommation_totale: BigNumber
}

export type ViagritelRowApportRetrait = {
  apport_retrait_ligne_no: number
  montant: BigNumber
}

export type ViagritelRowCerealeFourrage = {
  production: ViagritelProduction
  type_production: ViagritelTypeProduction
  inventaire_debut: BigNumber
  inventaire_fin: BigNumber
  nombre_balle_debut: BigNumber
  nombre_balle_fin: BigNumber
  poids_kg: BigNumber
  prix_unite_debut: BigNumber
  prix_unite_fin: BigNumber
  valeur_totale_debut: BigNumber
  valeur_totale_fin: BigNumber
}

export type ViagritelRowEmprunt = {
  current: {
    beginCapital: BigNumber | null
    newCapital: BigNumber | null
    endCapital: BigNumber | null
    paidReimbursement: BigNumber | null
    projectedReimbursement: BigNumber | null
    paidInterests: BigNumber | null
    beginDueAmount: BigNumber | null
    endDueAmount: BigNumber | null
  }
  description: string
  displayOrder: number
  initial: {
    amortizationMonths: BigNumber | null
    annualInterestRate: BigNumber | null
    capitalizationType?: CapitalizationType
    disbursementAmount: BigNumber | null
    disbursementDate?: Date
    loanType?: ImportedLoanType
  }
  lender?: string
  loanCategory?: ImportedLoanCategory
  paymentPlan: {
    amount: BigNumber | null
    frequency?: PaymentFrequency
  }
}

export type ViagritelRowMaraicher = {
  production: ViagritelTypeProductionMaraichere
  type_production: ViagritelTypeProduction
  debut_dollars: BigNumber
  fin_dollars: BigNumber
  delta_dollars: BigNumber
}

export type DatabaseResolverFunctions = {
  aliments: DatabaseResolverFnAliments
  animaux: DatabaseResolverFnAnimaux
  apportsRetraits: DatabaseResolverFnApportRetrait
  cerealesFourrages: DatabaseResolverFnCerealeFourrage
  comptesExploitation: DatabaseResolverFnCompteExploitation
  courtsTermes: DatabaseResolverFnCourtTerme
  emprunts: DatabaseResolverFnEmprunts
  fichesTechniques: DatabaseResolverFnFicheTechnique
  immobilisations: DatabaseResolverFnImmobilisation
  inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
  maraicher: DatabaseResolverFnMaraicher
  constant: DatabaseResolverFnConstant
}

export type DatabaseResolver = {
  resolve: (options: DatabaseResolverFunctions) => BigNumber
}

type DependencyResolver<D extends ReferenceDataKey[] = []> = {
  dependencies: D
  resolve: (options: { val: (key: D[number]) => BigNumber }) => BigNumber
}

export type DataKeyResolverDefinition<D extends ReferenceDataKey[]> =
  | { databaseResolver: DatabaseResolver; dependencyResolver?: never; testResolver: TestResolverWithPossibleMetadata }
  | {
      databaseResolver?: never
      dependencyResolver: DependencyResolver<D>
      testResolver: TestResolverWithPossibleMetadata
    }

export type BudgetDefinition<Keys extends ReferenceDataKey, Dependencies extends Keys = Keys> = Record<
  Keys,
  DataKeyResolverDefinition<Dependencies[]>
>

export const createBudgetDefinition = <Keys extends ReferenceDataKey, Dependencies extends Keys = Keys>(definition: {
  [K in Keys]: DataKeyResolverDefinition<Array<Exclude<Dependencies, K>>>
}) => definition as BudgetDefinition<Keys, Dependencies>
