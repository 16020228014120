import { LoanRowDataZod, PaymentFrequencyZod } from '@via/schema'
import z from 'zod'

import { FormFiniteNumberValueZod } from '../../atoms/Form/FormFiniteNumberValueZod.ts'

export const LoanFormZod = LoanRowDataZod.pick({ imported: true, estimated: true, computed: true }).extend({
  source: z.enum(['imported', 'scenario']),
  name: z.string().optional().default(''),
  startDate: z.string().optional().default(''),
  note: z.string().optional().default(''),
  amount: FormFiniteNumberValueZod,
  interestRate: FormFiniteNumberValueZod,
  duration: FormFiniteNumberValueZod,
  paymentPlan: z.object({
    amount: FormFiniteNumberValueZod,
    frequency: PaymentFrequencyZod.default('monthly'),
  }),
  durationWithoutInterestPaid: FormFiniteNumberValueZod,
  durationWithoutCapitalPaid: FormFiniteNumberValueZod,
  fixedCapitalPaymentAmount: FormFiniteNumberValueZod,
  newCapitalBorrowed: z
    .record(
      z.object({
        index: z.number(),
        date: z
          .string()
          .regex(/(^\d{4}-\d{2}-\d{2}$|^$)/)
          .optional()
          .default(''),
        value: FormFiniteNumberValueZod,
        payment: z.string().optional(),
      })
    )
    .optional()
    .default({}),
  earlyRepayment: z
    .record(
      z.object({
        index: z.number(),
        date: z
          .string()
          .regex(/(^\d{4}-\d{2}-\d{2}$|^$)/)
          .optional()
          .default(''),
        value: FormFiniteNumberValueZod,
        payment: z.string().optional(),
        remainingDueAmount: z.string().optional(),
        withdrawal: z.string().optional(),
      })
    )
    .optional()
    .default({}),
  interestRateChange: z
    .record(
      z.object({
        index: z.number(),
        date: z
          .string()
          .regex(/(^\d{4}-\d{2}-\d{2}$|^$)/)
          .optional()
          .default(''),
        value: FormFiniteNumberValueZod,
        payment: z.string().optional(),
      })
    )
    .optional()
    .default({}),
  enabledOptions: z
    .enum([
      'duration-without-capital',
      'duration-without-interest',
      'new-capital',
      'early-repayment',
      'interest-rate-change',
      'fixed-capital',
    ])
    .array()
    .optional()
    .default([]),
})
