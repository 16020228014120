import { type ReferenceDataKey } from '@via/schema'

import { bilan } from './accounting/bilan'
import { finance } from './accounting/finance'
import { inflowsOutflows } from './accounting/inflows-outflows'
import { loan } from './accounting/loan'
import { repaymentCapacity } from './accounting/repayment-capacity'
import { animal } from './productions/animal'
import { calf } from './productions/calf'
import { commercial } from './productions/commercial'
import { company } from './productions/company'
import { crops } from './productions/crops'
import { maple } from './productions/maple'
import { milk } from './productions/milk'
import { vegetal } from './productions/vegetal'
import { type BudgetDefinition } from './types'

export const budgetReferenceDefinition = {
  ...bilan,
  ...finance,
  ...inflowsOutflows,
  ...loan,
  ...repaymentCapacity,
  ...animal,
  ...calf,
  ...commercial,
  ...company,
  ...crops,
  ...maple,
  ...milk,
  ...vegetal,
} as unknown as BudgetDefinition<ReferenceDataKey> // performance optimization: avoid type checking
