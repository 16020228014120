import { useContext } from 'react'

import { ScenarioTabListContext } from './ScenarioTabListContext'

export const useScenarioTabList = () => {
  const tabList = useContext(ScenarioTabListContext)
  if (!tabList) {
    throw new Error('useScenarioTabList must be used within a ScenarioTabListProvider')
  }
  return tabList
}
