import { type ScenarioLoanStateZod } from '@via/schema'
import type z from 'zod'

import { type LoanDetailFormData, type LoanFormSubmitData } from './LoanFormProvider'

export const convertLoanFormToScenarioState = (values: LoanDetailFormData): z.input<typeof ScenarioLoanStateZod> => ({
  name: values.name,
  startDate: values.startDate,
  amount: values.amount,
  interestRate: values.interestRate,
  duration: values.duration,
  note: values.note,
  paymentAmount: values.paymentPlan.amount,
  paymentFrequency: values.paymentPlan.frequency,
  durationWithoutCapitalPaid: values.enabledOptions.includes('duration-without-capital')
    ? values.durationWithoutCapitalPaid
    : '',
  durationWithoutInterestPaid: values.enabledOptions.includes('duration-without-interest')
    ? values.durationWithoutInterestPaid
    : '',
  fixedCapitalPaymentAmount: values.enabledOptions.includes('fixed-capital') ? values.fixedCapitalPaymentAmount : '',
  newCapitalBorrowed: !values.enabledOptions.includes('new-capital')
    ? {}
    : Object.entries(values.newCapitalBorrowed)
        .filter(([, { date, value }]) => date && value)
        .reduce(
          (acc, [id, { date, value }]) => ({
            ...acc,
            [id]: { date, value },
          }),
          {} as LoanFormSubmitData['newCapitalBorrowed']
        ),
  earlyRepayment: !values.enabledOptions.includes('early-repayment')
    ? {}
    : Object.entries(values.earlyRepayment)
        .filter(([, { date, value }]) => date && value)
        .reduce(
          (acc, [id, { date, value, withdrawal }]) => ({
            ...acc,
            [id]: { date, value, ...(withdrawal ? { withdrawal } : {}) },
          }),
          {} as LoanFormSubmitData['earlyRepayment']
        ),
  interestRateChange: !values.enabledOptions.includes('interest-rate-change')
    ? {}
    : Object.entries(values.interestRateChange)
        .filter(([, { date, value }]) => date && value)
        .reduce(
          (acc, [id, { date, value }]) => ({
            ...acc,
            [id]: { date, value },
          }),
          {} as LoanFormSubmitData['interestRateChange']
        ),
})
