import {
  type AssetScenarioInvestmentStateZod,
  type BuildingInvestmentSubType,
  BuildingInvestmentSubTypes,
  type FinancingScenarioInvestmentStateZod,
  type LandInvestmentSubType,
  LandInvestmentSubTypes,
  MachineryInvestmentsSubTypes,
  type MachineryInvestmentSubType,
  type OtherInvestmentSubType,
  OtherInvestmentSubTypes,
  type QuotaInvestmentSubType,
  QuotaInvestmentSubTypes,
  type ScenarioInvestmentStateZod,
} from '@via/schema'
import { sumBy } from 'lodash-es'
import { type z } from 'zod'

import { type InvestmentFormData } from './InvestmentFormZod'

const stringIsBuildingInvestmentSubType = (str?: string): str is BuildingInvestmentSubType =>
  Boolean(str) && BuildingInvestmentSubTypes.includes(str as BuildingInvestmentSubType)

const stringIsMachineryInvestmentSubType = (str?: string): str is MachineryInvestmentSubType =>
  Boolean(str) && MachineryInvestmentsSubTypes.includes(str as MachineryInvestmentSubType)

const stringIsQuotaInvestmentSubType = (str?: string): str is QuotaInvestmentSubType =>
  Boolean(str) && QuotaInvestmentSubTypes.includes(str as QuotaInvestmentSubType)

const stringIsLandInvestmentSubType = (str?: string): str is LandInvestmentSubType =>
  Boolean(str) && LandInvestmentSubTypes.includes(str as LandInvestmentSubType)

const stringIsOtherInvestmentSubType = (str?: string): str is OtherInvestmentSubType =>
  Boolean(str) && OtherInvestmentSubTypes.includes(str as OtherInvestmentSubType)

const mapSubventionsOrDisbursement = (values: InvestmentFormData['subvention' | 'disbursement']) =>
  Object.entries(values)
    .filter(([, { date, value }]) => date && value)
    .reduce(
      (acc, [id, { date, value }]) => ({
        ...acc,
        [id]: { date, amount: value },
      }),
      {} as z.input<typeof AssetScenarioInvestmentStateZod>['subvention' | 'disbursement']
    )

function mapAssetInvestment({
  description,
  category,
  payment,
  subvention,
  disbursement,
  transactionDate,
  note,
  type,
  subtype,
  units,
  amortization,
  pricePerUnit,
  enabledOptions,
}: InvestmentFormData): z.input<typeof ScenarioInvestmentStateZod> {
  if (category !== 'sale' && category !== 'purchase') {
    throw new Error(`Invalid category ${category} for asset investment`)
  }
  const baseInvestment = {
    description,
    payment: enabledOptions.includes('disbursement')
      ? String(sumBy(Object.values(disbursement), ({ value }) => Number(value)))
      : payment || '0',
    transactionDate,
    note,
    category,
    ...(category === 'purchase' && enabledOptions.includes('subvention')
      ? { subvention: mapSubventionsOrDisbursement(subvention) }
      : {}),
    ...(category === 'purchase' && enabledOptions.includes('disbursement')
      ? { disbursement: mapSubventionsOrDisbursement(disbursement) }
      : {}),
  }

  switch (type) {
    case 'land':
      switch (true) {
        case stringIsLandInvestmentSubType(subtype):
          return {
            ...baseInvestment,
            type: {
              type,
              subtype,
            },
            units,
            pricePerUnit,
            amortization,
          }
        default:
          throw new Error(`Invalid subtype ${subtype} for land investment`)
      }
    case 'building':
      switch (true) {
        case stringIsBuildingInvestmentSubType(subtype):
          return {
            ...baseInvestment,
            type: {
              type,
              subtype,
            },
            amortization,
          }
        default:
          throw new Error(`Invalid subtype ${subtype} for building investment`)
      }
    case 'animals':
      switch (subtype) {
        case 'milk-animals':
        case 'calf-animals':
        case 'other-animals':
        case 'milk-veal':
        case 'goat':
        case 'horse':
        case 'heavy-turkey':
        case 'rabbit':
        case 'weaner':
        case 'table-egg':
        case 'ostrich':
        case 'aquaculture':
        case 'exotic':
        case 'replacement-heifer':
        case 'finished-beef':
        case 'bison':
        case 'wapiti':
        case 'roast-turkey':
        case 'pork':
        case 'sheep':
        case 'grain-veal':
        case 'incubation-egg':
        case 'roast-poultry':
        case 'stag':
          return {
            ...baseInvestment,
            type: {
              type,
              subtype,
            },
            units,
            pricePerUnit,
          }
        default:
          throw new Error(`Invalid subtype ${subtype} for animals investment`)
      }
    case 'quota':
      switch (true) {
        case stringIsQuotaInvestmentSubType(subtype):
          return {
            ...baseInvestment,
            type: {
              type,
              subtype,
            },
            units,
          }
        default:
          throw new Error(`Invalid subtype ${subtype} for quota investment`)
      }
    case 'machinery':
      switch (true) {
        case stringIsMachineryInvestmentSubType(subtype):
          return {
            ...baseInvestment,
            type: {
              type,
              subtype,
            },
            amortization,
          }
        default:
          throw new Error(`Invalid subtype ${subtype} for machinery investment`)
      }
    case 'other':
      switch (true) {
        case stringIsOtherInvestmentSubType(subtype):
          return {
            ...baseInvestment,
            type: {
              type,
              subtype,
            },
          }
        default:
          throw new Error(`Invalid subtype ${subtype} for other investment`)
      }
    case undefined:
      throw new Error('Missing investment type')
    default:
      throw new Error('Invalid investment type')
  }
}

function mapFinancingInvestment({
  description,
  payment,
  transactionDate,
  note,
  type,
  subtype,
  duration,
  paymentFrequency,
}: InvestmentFormData): z.input<typeof FinancingScenarioInvestmentStateZod> {
  if (!duration) {
    throw new Error('Missing duration for financing investment')
  }

  switch (type) {
    case 'machinery':
      if (!stringIsMachineryInvestmentSubType(subtype)) {
        throw new Error(`Invalid subtype ${subtype} for machinery financing investment`)
      }
      break
    default:
      throw new Error(`Invalid type ${type} for financing investment`)
  }
  return {
    description,
    payment: payment || '0',
    transactionDate,
    note,
    category: 'financing',
    duration,
    paymentFrequency,
    // Amortization is hard coded to 200% for now
    amortization: '200',
    type: {
      type,
      subtype,
    },
  }
}

export const mapInvestmentFormToScenarioState = (
  data: InvestmentFormData
): z.input<typeof ScenarioInvestmentStateZod> => {
  switch (data.category) {
    case 'purchase':
    case 'sale':
      return mapAssetInvestment(data)
    case 'financing':
      return mapFinancingInvestment(data)
    case 'shares-purchase':
    case 'shares-sale':
    case 'capital':
      return {
        description: data.description,
        payment: data.payment || '0',
        transactionDate: data.transactionDate,
        note: data.note,
        category: data.category,
      }
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Invalid category ${data.category} for investment`)
  }
}
