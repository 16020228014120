import { forwardRef, type HtmlHTMLAttributes } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const lineStripVariants = cva('w-1.5 flex [&>div]:pr-[5px] shrink-0', {
  variants: {
    variant: {
      default: '[&>div]:bg-sky-100 [&>div]:border-sky-500',
      calculate: '[&>div]:bg-green-300 [&>div]:border-green-400',
      groupCalculate: '[&>div]:bg-green-200 [&>div]:border-green-400',
      groupReference: '[&>div]:bg-gray-100 [&>div]:border-gray-400',
      calculateTotal: '[&>div]:bg-green-100 [&>div]:border-green-400',
      subTotal: '[&>div]:bg-orange-100 [&>div]:border-orange-300',
      subTotalClosing: '[&>div]:bg-orange-100 [&>div]:border-sky-500',
      total: '[&>div]:bg-orange-200 [&>div]:border-orange-300',
      totalClosing: '[&>div]:bg-orange-200 [&>div]:border-sky-500',
      finalTotal: '[&>div]:bg-orange-200 [&>div]:border-orange-300',
      empty: '[&>div]:bg-white [&>div]:border-transparent',
    },
    configuration: {
      head: '[&>div]:border-t [&>div]:border-l',
      line: '[&>div]:border-l',
      square: '[&>div]:border-l [&>div]:border-b',
      calculate: '[&>div]:border-y [&>div]:border-l',
      calculateTotal: '[&>div]:border-y [&>div]:border-l',
      bottom: '[&>div]:rounded-bl-lg [&>div]:border-b [&>div]:border-l',
      total: '[&>div]:rounded-l-lg [&>div]:border-y [&>div]:border-l',
    },
    filled: {
      true: '',
      false: '',
    },
    closingSection: {
      true: '',
      false: '',
    },
  },
  defaultVariants: {
    variant: 'default',
    configuration: 'line',
    filled: false,
    closingSection: false,
  },
  compoundVariants: [
    {
      variant: 'default',
      filled: true,
      class: 'bg-sky-100',
    },
    {
      variant: 'calculate',
      filled: true,
      class: 'bg-green-100',
    },
    {
      variant: 'subTotal',
      filled: true,
      class: 'bg-orange-100',
    },
    {
      variant: 'total',
      filled: true,
      class: 'bg-orange-200',
    },
    {
      variant: 'finalTotal',
      filled: true,
      class: 'bg-orange-200',
    },
    {
      variant: 'empty',
      filled: true,
      class: 'bg-white',
    },
    {
      variant: 'default',
      closingSection: true,
      class: '[&>div]:border-b [&>div]:border-b-sky-500',
    },
  ],
})

export interface LineStripProps extends HtmlHTMLAttributes<HTMLDivElement>, VariantProps<typeof lineStripVariants> {}

export const LineStrip = forwardRef<HTMLDivElement, LineStripProps>(
  ({ className, variant, configuration, filled, closingSection, ...props }, ref) => (
    <div
      className={cn(lineStripVariants({ variant, configuration, filled, closingSection }), className)}
      ref={ref}
      {...props}>
      <div />
    </div>
  )
)
