import { type FC, useMemo } from 'react'

import { type Unit } from '@via/schema'
import dayjs from 'dayjs'
import { times } from 'lodash-es'

import { LineCell } from '../../../atoms/LineCell/LineCell.tsx'
import { LineTitle } from '../../../atoms/LineTitle/LineTitle.tsx'
import { UnitLabel } from '../../../atoms/UnitLabel/UnitLabel.tsx'

export interface MonthlyTableTotalRowProps {
  readonly title: string
  readonly unit?: Unit
  readonly startDate: string
  readonly values?: Record<string, string | number>
}

export const MonthlyTableTotalRow: FC<MonthlyTableTotalRowProps> = ({ title, unit, startDate, values }) => {
  const startDateDayjs = useMemo(() => dayjs(startDate, 'YYYY-MM-DD').startOf('month'), [startDate])

  return (
    <div className="flex shrink-0">
      <LineTitle label={title} className="w-48" variant="subTotal">
        {unit && <UnitLabel unit={unit} />}
      </LineTitle>
      {times(12, (i) => {
        const key = startDateDayjs.add(i, 'months').format('YYYY-MM')
        return (
          <LineCell
            key={key}
            value={values?.[key] ?? 0}
            numberDisplayType={unit}
            variant="subTotal"
            className="w-16"
            withDividerRight
            tabIndex={0}
          />
        )
      })}
    </div>
  )
}
