import { createContext } from 'react'

import { type InvestmentFormSubmitData } from '../../organisms'

export interface InvestmentOperations {
  readonly onAddInvestment: (value: InvestmentFormSubmitData) => Promise<void> | void
  readonly onUpdateInvestment: (value: InvestmentFormSubmitData) => Promise<void> | void
  readonly onDeleteInvestment: (id: string, onCompleted?: () => void) => Promise<void> | void
}

export const InvestmentOperationsContext = createContext<InvestmentOperations>({
  onAddInvestment: () => Promise.reject(new Error('InvestmentOperationsContext not provided')),
  onUpdateInvestment: () => Promise.reject(new Error('InvestmentOperationsContext not provided')),
  onDeleteInvestment: () => Promise.reject(new Error('InvestmentOperationsContext not provided')),
})
