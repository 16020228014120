import { createFileRoute } from '@tanstack/react-router'

import { ScenarioBalancePage } from '../pages/scenarioBalance/ScenarioBalancePage'

const BalanceComponent = () => <ScenarioBalancePage readonly />

export const Route = createFileRoute(
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/balance'
)({
  component: BalanceComponent,
})
