import React from 'react'

import { captureException, ErrorBoundary } from '@sentry/react'

import { AppRxLeadershipContext } from '../../rxdb/contexts/AppRxLeadershipContext.ts'

import { FirestoreBudgetListSnapshotProvider } from './FirestoreBudgetListSnapshotProvider.tsx'
import { FirestoreClientSnapshotProvider } from './FirestoreClientSnaphotProvider.tsx'
import { FirestorePullProvider } from './FirestorePullProvider.tsx'

export const FirestoreAppSnapshotProvider: React.FC<{ readonly children: React.ReactElement }> = ({ children }) => {
  const isLeader = React.useContext(AppRxLeadershipContext)

  if (!isLeader) {
    return children
  }

  return (
    <ErrorBoundary
      fallback={children}
      onError={(error, componentStack, eventId) => {
        captureException(error, { tags: { firestore: true }, contexts: { react: { componentStack, eventId } } })
      }}>
      <FirestoreBudgetListSnapshotProvider>
        <FirestoreClientSnapshotProvider>
          <FirestorePullProvider>{children}</FirestorePullProvider>
        </FirestoreClientSnapshotProvider>
      </FirestoreBudgetListSnapshotProvider>
    </ErrorBoundary>
  )
}
