import { createFileRoute, notFound, Outlet } from '@tanstack/react-router'
import { ScenarioContext, ScenarioRowsProvider, ScenarioTabListContext } from '@via/components'

import { getComputedScenarioData } from '../firestore/helper/getComputedScenarioData'

const ScenarioComponent = () => {
  const { scenario, rows, scenarioTabList } = Route.useLoaderData()

  return (
    <ScenarioContext.Provider value={scenario}>
      <ScenarioRowsProvider rows={rows}>
        <ScenarioTabListContext.Provider value={scenarioTabList}>
          <Outlet />
        </ScenarioTabListContext.Provider>
      </ScenarioRowsProvider>
    </ScenarioContext.Provider>
  )
}

export const Route = createFileRoute('/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId')({
  component: ScenarioComponent,
  loader: async ({ params: { budgetId, scenarioId }, context: { file, scenarios } }) => {
    const scenario = scenarios.find((s) => s._id === scenarioId)

    if (!scenario) {
      throw notFound()
    }

    const stateId = file.scenarioStates[scenarioId]
    const computed = await getComputedScenarioData(budgetId, scenarioId, stateId)

    if (!computed) {
      throw notFound()
    }

    const scenarioTabList = Object.fromEntries(
      scenarios.map((s) => [s._id, { name: s.name, creationTimestamp: s.creation.timestamp, numberOfYears: 1 }])
    )

    const rows = Object.values(computed.data)

    return { scenario, rows, scenarioTabList }
  },
})
