import type React from 'react'
import { useIntl } from 'react-intl'

import { YearKeyZod } from '@via/schema'
import { z } from 'zod'

import { ZodForm } from '../../atoms/Form/ZodForm'
import { useBudget } from '../../context'
import { type ScenarioData } from '../../types'
import { ScenarioCreationFormZod } from '../ScenarioCreationForm/ScenarioCreationFormZod.ts'

export interface ScenarioUpdateFormProviderProps {
  readonly data: ScenarioData
}

const scenarioType = (scenario: ScenarioData) => {
  switch (scenario.blueprintVersion) {
    case 'simple-2024':
      return 'simple' as const
    case 'complete-2024':
      return 'complete' as const
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unknown blueprint version: ${scenario.blueprintVersion}`)
  }
}

export const ScenarioUpdateFormProvider: React.FC<React.PropsWithChildren<ScenarioUpdateFormProviderProps>> = ({
  data,
  children,
}) => {
  const defaultValues = {
    type: data ? scenarioType(data) : 'simple',
    name: data.name,
    projectionYear: data.period.numberOfYears,
    description: data.description ?? '',
    referenceType: data.referenceType,
    customReferenceName: data.customReferenceName ?? '',
    enabledProductions: [...data.enabledProductions],
  }

  const intl = useIntl()
  const budget = useBudget()

  const ScenarioUpdateWithValidationFormZod = ScenarioCreationFormZod.superRefine((scenario, ctx) => {
    const yearKeyValues = YearKeyZod.options
    const maxProjectionYears = Number(yearKeyValues.at(-1)) - budget.year

    if (scenario.projectionYear > maxProjectionYears) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: intl.formatMessage({ id: 'form.projectionYear.maximum' }, { count: maxProjectionYears }),
        path: ['projectionYear'],
      })
    }
  })

  return (
    <ZodForm schema={ScenarioUpdateWithValidationFormZod} defaultValues={defaultValues} mode="all">
      {children}
    </ZodForm>
  )
}
