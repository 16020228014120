import { type ScenarioState } from '@via/compute'

import { netMargin2024Blueprint } from './2024/netMargin2024Blueprint.ts'

export const netMarginBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...netMargin2024Blueprint }
  }
}
