import { convertRowData, type OverridableCellData, type RowData } from '@via/compute'
import { type BaseRowData, type MovementCellData, type MovementComputationType } from '@via/schema'

import { overridableMonetaryCellData } from './cell'

export const movementMonetaryRowData = (
  { key, computationType, ...rowData }: RowData<'movement-overridable' | 'movement-total'>,
  movementComputation?: MovementComputationType
) => {
  const reference = rowData.reference as MovementCellData<OverridableCellData> | undefined
  return {
    key,
    computationType,
    ...convertRowData(
      rowData as BaseRowData<MovementCellData<OverridableCellData>>,
      ({ startingBalance, balance, ...value }: MovementCellData<OverridableCellData>) => {
        switch (movementComputation) {
          case 'startingBalance':
            return {
              ...overridableMonetaryCellData(startingBalance ?? { value: '0' }),
              ...(startingBalance ? { startingBalance: Number(startingBalance.value) } : {}),
              ...(balance ? { balance: Number(balance.value) } : {}),
            }
          case 'balance':
            return {
              ...overridableMonetaryCellData(balance ?? { value: '0' }),
              ...(startingBalance ? { startingBalance: Number(startingBalance.value) } : {}),
              ...(balance ? { balance: Number(balance.value) } : {}),
            }
          default:
            return {
              ...overridableMonetaryCellData(value),
              ...(startingBalance ? { startingBalance: Number(startingBalance.value) } : {}),
              ...(balance ? { balance: Number(balance.value) } : {}),
            }
        }
      }
    ),
    ...(reference?.balance ? { startingBalance: overridableMonetaryCellData(reference.balance) } : {}),
  }
}
