import type React from 'react'
import { useIntl } from 'react-intl'

import { useFormatNumber } from '../../hooks/useFormatNumber'
import { type MonetaryLoanData } from '../../model'
import { InputFormField } from '../../molecules/InputFormField/InputFormField'

import { type LoanDetailFormData } from './LoanFormProvider'

export const LoanDurationWithoutInterestForm: React.FC<{ readonly loanData?: MonetaryLoanData }> = ({ loanData }) => {
  const intl = useIntl()
  const { formatNumber } = useFormatNumber()
  const paymentAmountWhenInterestPaymentStart = Number(loanData?.paymentPlan?.paymentAmounts?.['start-interest'])
  return (
    <div className="grid grid-cols-4 items-end gap-4">
      <InputFormField<LoanDetailFormData, 'durationWithoutInterestPaid'>
        name="durationWithoutInterestPaid"
        type="number"
        label={intl.formatMessage({ id: 'loan.form.without-interest.duration' })}
      />
      {Number.isFinite(paymentAmountWhenInterestPaymentStart) && (
        <div className="col-span-2 flex flex-row items-center gap-2">
          <p className="flex-none select-none text-xs text-gray-800">
            {intl.formatMessage({ id: 'loan.form.without-interest.payments' })}
          </p>
          <p
            className="grow select-none text-sm font-bold text-black"
            data-value={paymentAmountWhenInterestPaymentStart}>
            {formatNumber(paymentAmountWhenInterestPaymentStart, 'currency-with-symbol')}
          </p>
        </div>
      )}
    </div>
  )
}
