import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { dataLine, dividerLine } from '../utils'

export const investmentPlan2024Blueprint = {
  sections: [
    {
      id: 'finance.investment.plan',
      titleKey: 'table.finance.investment.plan',
      children: [
        {
          ...dividerLine(),
          children: [dataLine('finance.investment.plan')],
        },
        dataLine('finance.investment.total', { variant: 'total', closingSection: true }),
      ],
    },
  ],
  columns: [
    {
      id: 'title',
      type: 'title-with-add-investment',
      sticky: true,
      className: 'w-60',
      displayOnTableState: ['default'],
    },
    {
      id: 'title',
      type: 'title',
      sticky: true,
      className: 'w-60',
      displayOnTableState: ['print-with-reference', 'print-without-reference'],
    },
    {
      id: 'type',
      type: 'finance-investment-type',
      titleKey: 'table.finance.investment.type',
      sticky: true,
      className: 'w-24',
    },
    {
      id: 'amount',
      type: 'finance-investment-amount',
      titleKey: 'table.finance.investment.amount',
      sticky: true,
      withDividerRight: true,
      className: 'w-24',
      numberDisplayType: 'currency',
    },
    { id: 'divider', type: 'divider', className: 'w-1' },
    {
      id: 'projections',
      type: 'projections',
      className: 'w-24',
      withDividerRight: true,
      numberDisplayType: 'currency',
    },
  ],
  defaultState: 'default',
} as const satisfies Except<BlueprintTable<'default' | 'print-with-reference' | 'print-without-reference'>, 'version'>
