import { type FC, useContext } from 'react'

import { MonetaryYearFormFieldTable } from '../MonetaryYearFormFieldTable'

import { MilkIncomeFormComputationContext } from './MilkIncomeFormComputationProvider.tsx'
import { type MilkIncomeFormTableBlueprintKeys, useMilkIncomeBlueprint } from './useMilkIncomeBlueprint.tsx'

export const MilkIncomeFormTable: FC = () => {
  const blueprint = useMilkIncomeBlueprint()
  const values = useContext(MilkIncomeFormComputationContext)

  return <MonetaryYearFormFieldTable<MilkIncomeFormTableBlueprintKeys> blueprint={blueprint} values={values} />
}
