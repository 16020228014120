import { Checkbox } from '../../atoms/Checkbox/Checkbox'
import { FormLabel } from '../../atoms/FormLabel/FormLabel.tsx'
import { cn } from '../../lib/utils'

export interface ProductionCheckboxProps {
  readonly label: string
  readonly years: string
  readonly checked?: boolean
  readonly forceChecked?: boolean
  readonly readonly?: boolean
  readonly onCheckedChange: (checked: boolean) => void
}

export const ProductionCheckbox = ({
  label,
  years,
  checked,
  forceChecked,
  readonly,
  onCheckedChange,
}: ProductionCheckboxProps) => (
  <div
    className={cn(
      'flex w-full items-center justify-between gap-2 rounded-sm border-b border-gray-300 p-2.5',
      checked || forceChecked ? 'bg-blue-50' : 'bg-white'
    )}>
    <div className="flex items-center gap-1.5">
      {!forceChecked && !readonly ? (
        <>
          <Checkbox id={label} checked={checked} onCheckedChange={onCheckedChange} />
          <FormLabel className="cursor-pointer select-none py-1" htmlFor={label}>
            {label}
          </FormLabel>
        </>
      ) : (
        <p className="select-none py-1 text-sm leading-[0.5] tracking-[-0.28px] text-gray-700">{label}</p>
      )}
    </div>
    <p className="select-none text-xs font-bold leading-none text-gray-700">{years}</p>
  </div>
)
