import { forwardRef, useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'

import {
  type DataKeyDependencies,
  dataKeyDependenciesRecord,
  type DataKeyDependenciesRecordKey,
  dataKeyRecordDependenciesMapValues,
  type YearKey,
} from '@via/schema'
import { type Except } from 'type-fest'

import { SheetFormProvider, type SheetFormProviderProps } from '../SheetFormProvider'

import { MilkIncomeFormDependenciesContext } from './MilkIncomeFormDependenciesContext.ts'
import { type MilkIncomeFormData, MilkIncomeFormZod } from './MilkIncomeFormZod.ts'

export interface MilkIncomeFormSubmitData {
  readonly year: YearKey
  readonly values: Record<
    Exclude<
      DataKeyDependencies<'milk-income'>,
      | 'milk.sale.hectoliter'
      | 'milk.production.content.kilogram-per-hectoliter.fat'
      | 'milk.production.content.kilogram-per-hectoliter.protein'
      | 'milk.production.content.kilogram-per-hectoliter.lactose'
    >,
    string | null
  >
}

export interface MilkIncomeFormProviderProps
  extends Except<
    SheetFormProviderProps<typeof MilkIncomeFormZod>,
    'schema' | 'defaultValues' | 'onFormSubmit' | 'onFormApply' | 'title' | 'onDelete'
  > {
  readonly year: YearKey
  readonly onSubmit: (value: MilkIncomeFormSubmitData) => Promise<void>
  readonly onApply: (value: MilkIncomeFormSubmitData) => Promise<void>
}

const milkIncomeSubmitData = (year: YearKey, data: MilkIncomeFormData): MilkIncomeFormSubmitData => ({
  year,
  values: Object.fromEntries(
    Object.entries<DataKeyDependencies<'milk-income'>>(dataKeyDependenciesRecord('milk-income'))
      .filter(
        ([, dataKey]) =>
          ![
            'milk.sale.hectoliter',
            'milk.production.content.kilogram-per-hectoliter.fat',
            'milk.production.content.kilogram-per-hectoliter.protein',
            'milk.production.content.kilogram-per-hectoliter.lactose',
          ].includes(dataKey)
      )
      .map(([key, dataKey]) => [dataKey, data[key as DataKeyDependenciesRecordKey<'milk-income'>] ?? null])
  ) as MilkIncomeFormSubmitData['values'],
})

export const MilkIncomeFormProvider = forwardRef<HTMLFormElement, MilkIncomeFormProviderProps>(
  ({ year, onSubmit, onApply, children, ...props }, ref) => {
    const intl = useIntl()

    const onFormSubmit = useCallback(
      async (values: MilkIncomeFormData) => {
        await onSubmit(milkIncomeSubmitData(year, values))
      },
      [year, onSubmit]
    )

    const onFormApply = useCallback(
      async (values: MilkIncomeFormData) => {
        await onApply(milkIncomeSubmitData(year, values))
      },
      [year, onApply]
    )

    const dependencies = useContext(MilkIncomeFormDependenciesContext)
    const formValues = useMemo(
      () =>
        MilkIncomeFormZod.parse(
          dataKeyRecordDependenciesMapValues('milk-income', (key) => String(dependencies?.[key]?.override?.value ?? ''))
        ),
      [dependencies]
    )
    return (
      <SheetFormProvider<typeof MilkIncomeFormZod>
        {...props}
        title={intl.formatMessage({ id: 'milk.income.form.title' }, { year })}
        values={formValues}
        schema={MilkIncomeFormZod}
        ref={ref}
        onFormSubmit={onFormSubmit}
        onFormApply={onFormApply}>
        {children}
      </SheetFormProvider>
    )
  }
)
