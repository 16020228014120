import { forwardRef } from 'react'

import { cn } from '../../lib/utils'

import { MilkExpensesFormComputationProvider } from './MilkExpensesFormComputationProvider.tsx'
import { MilkExpensesFormDependenciesProvider } from './MilkExpensesFormDependenciesProvider.tsx'
import { MilkExpensesFormProvider, type MilkExpensesFormProviderProps } from './MilkExpensesFormProvider.tsx'
import { MilkExpensesFormTable } from './MilkExpensesFormTable.tsx'

export type MilkExpensesFormProps = MilkExpensesFormProviderProps

export const MilkExpensesForm = forwardRef<HTMLFormElement, MilkExpensesFormProps>(
  ({ year, className, ...props }, ref) => (
    <MilkExpensesFormDependenciesProvider year={year}>
      <MilkExpensesFormProvider year={year} className={cn('w-[500px] bg-white', className)} ref={ref} {...props}>
        <MilkExpensesFormComputationProvider>
          <div className="h-screen overflow-y-auto">
            <main className="flex flex-col pb-24 pt-6">
              <MilkExpensesFormTable />
            </main>
          </div>
        </MilkExpensesFormComputationProvider>
      </MilkExpensesFormProvider>
    </MilkExpensesFormDependenciesProvider>
  )
)
