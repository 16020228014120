import { useBudgetReferenceChanges } from '../../contexts/syncRequired/useBudgetReferenceChanges.ts'

export const BudgetReferenceChangelog = () => {
  const budgetReferenceChanges = useBudgetReferenceChanges()
  return (
    <div>
      {budgetReferenceChanges?.length ? (
        <ul className="ms-3 flex list-outside list-disc flex-col gap-1">
          {budgetReferenceChanges?.map((changeDescription, i) => (
            <li key={`budget-reference-detail-list-item-${i}`}>{changeDescription}</li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}
