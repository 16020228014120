import { type FC, Fragment, useContext } from 'react'

import { recordKeys } from '@via/compute'
import { dataKeyTypedAttributes, type OverridableReferenceDataKey } from '@via/schema'
import { max } from 'lodash-es'

import { type MonetaryRowData, type OverridableMonetaryCellData } from '../../../model'
import { MonetaryTableActionsContext } from '../contexts/MonetaryTableActionsContext.ts'
import { MonetaryTablePropsContext } from '../contexts/MonetaryTablePropsContext.ts'
import { MonetaryTableDividerCell } from '../dividers/MonetaryTableDividerCell.tsx'

import { type MonetaryTableCellProps } from './MonetaryTableCellProps.ts'
import { MonetaryTableLabelCell } from './MonetaryTableLabelCell.tsx'
import { MonetaryTableProjectionCell } from './MonetaryTableProjectionCell.tsx'
import { MonetaryTableProportionCell } from './MonetaryTableProportionCell.tsx'
import { MonetaryTableTitleCell } from './MonetaryTableTitleCell.tsx'
import { MonetaryTableValueCell } from './MonetaryTableValueCell.tsx'
import { valueCellType } from './valueCellType.tsx'

const getReferenceForHistoric = (rowData: MonetaryRowData): OverridableMonetaryCellData | undefined => {
  const { references, reference } = rowData
  const lastYear = references && max(recordKeys(references))
  return lastYear ? references[lastYear] : reference
}
const overridableReferenceYear = (key: OverridableReferenceDataKey) =>
  dataKeyTypedAttributes(key, 'overridable-reference').reference ?? 'latest'

export const MonetaryTableCell: FC<MonetaryTableCellProps> = ({ column, line, rowData, loading }) => {
  const { onUpdateCell } = useContext(MonetaryTableActionsContext)
  const { references, projections, readonlyReferences } = useContext(MonetaryTablePropsContext)
  const { type, className } = column

  switch (type) {
    case 'divider':
      return <MonetaryTableDividerCell className={className} />
    case 'title':
    case 'title-with-add':
    case 'title-with-add-investment':
    case 'title-with-selected-tab':
      return <MonetaryTableTitleCell column={column} line={line} rowData={rowData} loading={loading} />
    case 'reference':
      return line.overridableReference ? (
        <MonetaryTableValueCell
          type="overridable-reference"
          column={column}
          line={line}
          rowData={rowData}
          loading={loading}
          onUpdate={
            onUpdateCell &&
            ((value) =>
              onUpdateCell({
                year: overridableReferenceYear(line.overridableReference as OverridableReferenceDataKey),
                ...value,
              }))
          }
          dataKey={line.overridableReference}
          className="italic"
        />
      ) : (
        <MonetaryTableValueCell
          editable={!readonlyReferences}
          type="reference"
          column={column}
          line={line}
          rowData={rowData}
          loading={loading}
          onUpdate={onUpdateCell}
          className="italic"
        />
      )
    case 'all-references':
      return references.map((year) =>
        line.overridableReference ? (
          <MonetaryTableValueCell
            key={year}
            year={year}
            type="overridable-reference"
            column={column}
            line={line}
            cellData={rowData.references?.[year]}
            rowData={rowData}
            loading={loading}
            onUpdate={onUpdateCell && ((value) => onUpdateCell({ year, ...value }))}
            dataKey={line.overridableReference}
            className="italic"
          />
        ) : (
          <MonetaryTableValueCell
            key={year}
            year={year}
            editable={false}
            type="reference"
            column={column}
            line={line}
            cellData={rowData.references?.[year]}
            rowData={rowData}
            loading={loading}
            className="italic"
          />
        )
      )
    case 'historic': {
      const reference = getReferenceForHistoric(rowData)
      return (
        <>
          <MonetaryTableValueCell
            editable={false}
            type="reference"
            column={column}
            line={line}
            cellData={reference}
            rowData={rowData}
            loading={loading}
            withDividerRight={false}
            className="w-24 italic"
          />
          <MonetaryTableProportionCell
            column={column}
            line={line}
            rowData={rowData}
            cellData={reference}
            loading={loading}
            className="w-12"
          />
        </>
      )
    }
    case 'all-historic':
      return references.map((year) => {
        const cellData = rowData.references?.[year]
        return (
          <Fragment key={year}>
            <MonetaryTableValueCell
              editable={false}
              year={year}
              type="reference"
              column={column}
              line={line}
              cellData={cellData}
              rowData={rowData}
              loading={loading}
              withDividerRight={false}
              className="w-24 italic"
            />
            <MonetaryTableProportionCell
              column={column}
              line={line}
              rowData={rowData}
              cellData={cellData}
              loading={loading}
              className="w-12"
            />
          </Fragment>
        )
      })
    case 'growth':
      return (
        <MonetaryTableValueCell
          type={type}
          column={column}
          line={line}
          rowData={rowData}
          loading={loading}
          onUpdate={onUpdateCell}
        />
      )
    case 'finance-loan-starting-balance':
    case 'finance-loan-interest':
    case 'finance-loan-duration':
      return (
        <MonetaryTableValueCell type="finance-loan" column={column} line={line} rowData={rowData} loading={loading} />
      )
    case 'finance-short-term-amount':
      return <MonetaryTableValueCell type="readonly" column={column} line={line} rowData={rowData} loading={loading} />
    case 'finance-investment-amount':
    case 'finance-investment-total':
      return (
        <MonetaryTableValueCell
          type={valueCellType(rowData, line) as 'finance-investment' | 'finance-investment-inventory'}
          column={column}
          line={line}
          rowData={rowData}
          loading={loading}
        />
      )
    case 'finance-investment-type':
      return <MonetaryTableLabelCell column={column} line={line} label={rowData.typeLabel} loading={loading} />

    case 'projections-with-proportions':
      return projections.map((year) => {
        const cellData = rowData.values?.[year]
        return (
          <Fragment key={year}>
            <MonetaryTableValueCell
              type="result-projections"
              year={year}
              column={column}
              line={line}
              rowData={rowData}
              loading={loading}
              cellData={cellData}
              withDividerRight={false}
              onUpdate={onUpdateCell && ((value) => onUpdateCell({ year, ...value }))}
            />
            <MonetaryTableProportionCell
              column={column}
              line={line}
              rowData={rowData}
              cellData={cellData}
              loading={loading}
              className="w-12"
            />
          </Fragment>
        )
      })

    case 'result-projections':
      return projections.map((year) => (
        <MonetaryTableValueCell
          key={year}
          year={year}
          type={type}
          column={column}
          line={line}
          rowData={rowData}
          loading={loading}
          cellData={rowData.values?.[year]}
          withDividerRight
          onUpdate={onUpdateCell && ((value) => onUpdateCell({ year, ...value }))}
        />
      ))
    case 'projections':
      return projections.map((year) => (
        <MonetaryTableProjectionCell
          key={year}
          year={year}
          column={column}
          line={line}
          rowData={rowData}
          loading={loading}
        />
      ))
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`MonetaryTableCell: Unknown column type ${type}`)
  }
}
