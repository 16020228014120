import { forwardRef, type HtmlHTMLAttributes, useContext, useMemo } from 'react'

import { type FormatNumberOptions } from '@formatjs/intl'
import { cva, type VariantProps } from 'class-variance-authority'

import { PointerStateContext } from '../../context/table/PointerStateProvider.tsx'
import { type NumberDisplayType, useFormatNumber } from '../../hooks/useFormatNumber'
import { cn } from '../../lib/utils'
import { type LineVariant } from '../LineTitle/LineVariant'

export type ValueCellVariant =
  | 'emptyCell'
  | 'readonlyValue'
  | 'referenceValue'
  | 'growthValue'
  | 'editableValue'
  | 'grbfValue'
  | 'overridableValue'

export type LineCellVariant =
  | Exclude<
      LineVariant,
      'subValidateAlert' | 'subValidatePass' | 'group' | 'groupCalculate' | 'groupReference' | 'basic' | 'divider'
    >
  | ValueCellVariant
  | 'validateAlertEmpty' // Unused ?

const lineCellVariants = cva(
  'group relative flex flex-row h-6 justify-end items-baseline gap-1 w-10 px-1 shrink-0 text-right text-black text-sm font-light leading-6 tracking-[-0.28px] border-b border-y-gray-200 border-x-gray-400 outline-none',
  {
    variants: {
      variant: {
        default: 'bg-white',
        emptyCell: 'bg-gray-100',
        readonlyValue: 'bg-gray-100',
        referenceValue: 'bg-gray-50',
        editableValue: 'bg-white',
        grbfValue: 'bg-white',
        growthValue: 'bg-white',
        overridableValue: 'bg-white',
        section: 'bg-orange-50 font-bold',
        subSection: 'bg-gray-200 font-medium',
        subSectionProduction: 'bg-gray-200 italic',
        subTotal: 'bg-orange-100 font-bold',
        total: 'bg-orange-200 font-bold',
        validatePass: 'bg-white border-b-green-400',
        validateAlert: 'bg-red-200 border-b-red-400',
        validateAlertEmpty: 'bg-white border-b-red-400',
        calculate: 'bg-green-100 border-y border-y-green-400',
        calculateDerived: 'bg-gray-50',
        calculateSubTotal: 'bg-green-100',
        calculateTotal: 'bg-green-100 border-y border-y-green-400 h-7 font-bold leading-7',
        finalSubTotal: 'bg-orange-100 border-y border-y-orange-300 h-7 font-bold leading-7',
        finalTotal: 'bg-orange-200 border-y border-y-orange-300 h-7 font-bold leading-7',
        head: 'bg-sky-100 border-t border-t-sky-500 h-7 font-bold leading-7',
      } satisfies Record<LineCellVariant, string>,
      dataStatus: {
        default: '',
        overridden: 'font-bold',
        impacted: '',
        imported: 'text-gray-500',
        loading:
          'bg-[linear-gradient(93deg,_#E5E7EB_0.44%,_#FFF_42.63%,_rgba(229,231,235,0.34)_51.09%,_#FFF_64.85%,_#E5E7EB_100.83%)] [&>p]:hidden bg-white',
      },
      editing: {
        true: 'text-black font-light cursor-text',
        false: 'cursor-pointer',
      },
      withDividerLeft: {
        true: 'border-l',
        false: '',
      },
      withDividerRight: {
        true: 'last:border-r',
        false: '',
      },
      bottomBorder: {
        default: '',
        calculate: 'border-b-green-200',
        close: 'border-b-sky-500',
        none: 'border-b-0',
      },
      closingSection: {
        true: 'border-b border-b-sky-500',
        false: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      dataStatus: 'default',
      editing: false,
      withDividerLeft: true,
      withDividerRight: false,
      bottomBorder: 'default',
      closingSection: false,
    },
    compoundVariants: [
      {
        variant: 'referenceValue',
        dataStatus: 'imported',
        class: 'text-black',
      },
      {
        variant: 'grbfValue',
        dataStatus: 'overridden',
        editing: false,
        class: 'bg-cyan-300',
      },
      {
        variant: 'grbfValue',
        dataStatus: 'impacted',
        editing: false,
        class: 'bg-cyan-100',
      },
      {
        variant: 'growthValue',
        dataStatus: 'overridden',
        class: 'font-light',
      },
      {
        variant: ['readonlyValue', 'calculate', 'calculateSubTotal', 'calculateTotal', 'finalSubTotal', 'finalTotal'],
        dataStatus: 'imported',
        class: 'text-black',
      },
      {
        variant: ['subTotal', 'total'],
        closingSection: false,
        class: 'border-b-orange-300',
      },
      {
        variant: ['calculateDerived'],
        closingSection: true,
        class: 'border-b-green-400',
      },
    ],
  }
)

const lineCellHighlightVariants = cva('absolute -inset-px border-2 border-transparent group-focus:border-gray-400', {
  variants: {
    variant: {
      default: '',
      emptyCell: 'inset-0 border hover:border-gray-200',
      readonlyValue: '',
      referenceValue: '',
      editableValue: 'group-focus:border-orange-400',
      grbfValue: 'group-focus:border-orange-400',
      growthValue: 'group-focus:border-orange-400',
      overridableValue: 'group-focus:border-green-400',
      section: '',
      subSection: '',
      subSectionProduction: '',
      subTotal: '',
      total: '',
      validatePass: '',
      validateAlert: '',
      validateAlertEmpty: '',
      calculate: '',
      calculateDerived: '',
      calculateSubTotal: '',
      calculateTotal: '',
      finalSubTotal: '',
      finalTotal: '',
      head: '',
    } satisfies Record<LineCellVariant, string>,
    dataStatus: {
      default: '',
      overridden: '',
      impacted: '',
      imported: '',
      loading: '',
    },
    withCursor: {
      true: 'hover:border-gray-400',
      false: '',
    },
    editing: {
      true: 'border-black inset-0 border hover:border-black',
      false: '',
    },
  },
  defaultVariants: {
    variant: 'default',
    editing: false,
    withCursor: true,
  },
  compoundVariants: [
    {
      variant: 'editableValue',
      editing: true,
      class: 'border-orange-400',
    },
    {
      variant: 'grbfValue',
      editing: true,
      class: 'border-orange-400',
    },
    {
      variant: 'overridableValue',
      editing: true,
      class: 'border-green-400',
    },
  ],
})

export interface LineCellProps extends HtmlHTMLAttributes<HTMLDivElement>, VariantProps<typeof lineCellVariants> {
  readonly value?: string | number
  readonly withMessage?: boolean
  readonly numberDisplayType?: NumberDisplayType
  readonly negated?: boolean
  readonly formatNumberOptions?: FormatNumberOptions
}

export const LineCell = forwardRef<HTMLDivElement, LineCellProps>(
  (
    {
      className,
      variant,
      dataStatus,
      editing,
      withDividerLeft,
      withDividerRight,
      closingSection,
      bottomBorder,
      value,
      withMessage,
      numberDisplayType,
      formatNumberOptions,
      negated,
      children,
      ...props
    },
    ref
  ) => {
    const { formatNumber } = useFormatNumber()
    const pointerState = useContext(PointerStateContext)

    const { bodyClassName, highlightClassName } = useMemo(
      () => ({
        bodyClassName: lineCellVariants({
          variant,
          dataStatus,
          editing,
          withDividerLeft,
          withDividerRight,
          closingSection,
          bottomBorder,
        }),
        highlightClassName: lineCellHighlightVariants({
          variant,
          dataStatus,
          editing,
          withCursor: pointerState === 'shown',
        }),
      }),
      [dataStatus, editing, pointerState, variant, withDividerLeft, withDividerRight, closingSection, bottomBorder]
    )

    const formatedValue = useMemo(
      () =>
        typeof value === 'number' && Number.isFinite(value)
          ? formatNumber(negated ? -value : value, numberDisplayType, formatNumberOptions)
          : (value ?? '\u00A0'),
      [value, numberDisplayType, negated, formatNumber]
    )

    return (
      <div
        className={cn(bodyClassName, className)}
        ref={ref}
        {...props}
        data-variance={variant}
        data-number-format={numberDisplayType}>
        <div className={cn(highlightClassName)} />
        {variant !== 'emptyCell' && <p className="text-ellipsis">{formatedValue}</p>}
        {children}
        {withMessage && <div className="absolute bottom-px right-px size-1 rounded-full bg-black" />}
      </div>
    )
  }
)
