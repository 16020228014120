import { type BlueprintVersion } from '../../../enums'
import { dataKeyAttributes } from '../data-key-attributes'
import { isDataKey } from '../is-data-key'
import { type DataAttribute } from '../types'

import { resolveAlias } from './resolve-alias'

export const resolveAttributes = (key: string, blueprintVersion?: BlueprintVersion): DataAttribute | undefined => {
  const resolvedKey = isDataKey(key) ? resolveAlias(key, blueprintVersion) : undefined
  return resolvedKey && dataKeyAttributes(resolvedKey)
}
