import { type FC, Fragment, useContext } from 'react'

import { lineTitleConfiguration } from '../../atoms/LineTitle/lineTitleConfigurations.ts'

import { MonetaryTableCell } from './cells/MonetaryTableCell.tsx'
import { type MonetaryTableCellProps } from './cells/MonetaryTableCellProps.ts'
import { MonetaryTableCollapseStateContext } from './contexts/MonetaryTableCollapseProvider.tsx'
import {
  MonetaryTableBlueprintContext,
  MonetaryTableCurrentBlueprintColumnsContext,
} from './contexts/MonetaryTableStateProvider.tsx'
import { MonetaryTableDividerRow } from './dividers/MonetaryTableDividerRow.tsx'
import { MonetaryTableStickyColumns } from './MonetaryTableStickyColumns'
import { type MonetaryTableDividerLine, type MonetaryTableHeaderLine, type MonetaryTableLine } from './types'
import { useMonetaryTableData } from './useMonetaryTableData'

interface MonetaryTableBodyRowProps extends Pick<MonetaryTableCellProps, 'line' | 'rowData' | 'loading'> {
  readonly className?: string
}

const MonetaryTableBodyRow = ({ className, ...props }: MonetaryTableBodyRowProps) => {
  const blueprintColumns = useContext(MonetaryTableCurrentBlueprintColumnsContext)
  const stickyColumns = blueprintColumns.filter((col) => col.sticky)
  const scrollableColumns = blueprintColumns.filter((col) => !col.sticky)

  return (
    <div className="flex shrink-0" data-key={props.rowData.key}>
      <MonetaryTableStickyColumns columns={stickyColumns} className={className}>
        {(column) => <MonetaryTableCell key={`${props.rowData.key}-${column.id}`} {...props} column={column} />}
      </MonetaryTableStickyColumns>

      {scrollableColumns.map((column) => (
        <MonetaryTableCell key={`${props.rowData.key}-${column.id}`} {...props} column={column} />
      ))}
    </div>
  )
}

interface MonetaryTableBodyDataProps extends Pick<MonetaryTableCellProps, 'line'> {
  readonly className?: string
}

const MonetaryTableBodyData: FC<MonetaryTableBodyDataProps> = ({ line, className }) => {
  const blueprint = useContext(MonetaryTableBlueprintContext)
  const { isOpen } = useContext(MonetaryTableCollapseStateContext)
  const { collapsable } = lineTitleConfiguration(line.variant)
  const { rows, loading } = useMonetaryTableData({
    id: line.id,
    disabled: line.alwaysVisible ? false : collapsable && isOpen(line.id),
    computation: line.computation,
    weight: line.weight,
  })

  if (line.multipleValues) {
    const allRows = blueprint.preprocessMonetaryRowData?.(line.id, rows) ?? rows
    return (
      <>
        {allRows.map((rowData) => (
          <MonetaryTableBodyRow key={rowData.id} line={line} rowData={rowData} className={className} />
        ))}
      </>
    )
  }

  const [rowData = { id: line.id, key: line.id, computationType: 'grbf', blueprint: blueprint.version }] = rows
  return <MonetaryTableBodyRow key={line.id} line={line} rowData={rowData} loading={loading} className={className} />
}

const isMonetaryTableDividerLine = (
  line: MonetaryTableLine
): line is MonetaryTableDividerLine | MonetaryTableHeaderLine => ['divider', 'head'].includes(line.variant)

export const MonetaryTableBody = ({
  lines = [],
  childrenHidden,
  className,
}: {
  readonly lines?: ReadonlyArray<MonetaryTableLine>
  readonly childrenHidden?: boolean
  readonly className?: string
}) => {
  const { isOpen } = useContext(MonetaryTableCollapseStateContext)
  return (
    <>
      {lines.map((line) => (
        <Fragment key={line.id}>
          {isMonetaryTableDividerLine(line) ? (
            <MonetaryTableDividerRow line={line} className={className} />
          ) : (
            <MonetaryTableBodyData line={line} className={className} />
          )}
          {isOpen(line.id) && !childrenHidden && <MonetaryTableBody lines={line.lines} className={className} />}
        </Fragment>
      ))}
    </>
  )
}
