import { useEffect } from 'react'

import { useAsync } from '@react-hookz/web/useAsync/index.js'
import { handleNotAwaitedPromise } from '@via/frontend-sentry'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'

import { readConfiguration } from '../../firestore/helper/readConfiguration'

export const useCoverImages = () => {
  const [{ result: images }, { execute }] = useAsync(async () => {
    const coverImages = await readConfiguration('ReportCoverImages')
    const storage = getStorage()

    return await Promise.all(
      coverImages.value.map(async (item) => {
        const coverImageRef = ref(storage, item.path)
        const url = await getDownloadURL(coverImageRef)
        return { url, name: item.name, fullPath: item.path }
      })
    )
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleNotAwaitedPromise(execute()), [])

  return images
}
