import { type UserRole } from '@via/schema'
import { getAuth } from 'firebase/auth'

export interface CurrentUserInformation {
  readonly userId: string
  readonly userName: string
  readonly userRole: UserRole
}

export function currentUser(firebaseUser = getAuth().currentUser): CurrentUserInformation {
  if (!firebaseUser) {
    throw new Error('User not authenticated')
  }
  return {
    userId: firebaseUser.uid,
    userName: firebaseUser.displayName ?? firebaseUser.email ?? firebaseUser.uid,
    // TODO quand on implémentera les rôles? Ce n'est pas clair pour moi d'où ça viendra
    userRole: 'other',
  }
}
