import { useIntl } from 'react-intl'

import { type InventoryComputationType } from '@via/schema'

import { FormControl } from '../../atoms/Form/FormControl.tsx'
import { FormField } from '../../atoms/Form/FormField.tsx'
import { FormItem } from '../../atoms/Form/FormItem.tsx'
import { FormMessage } from '../../atoms/Form/FormMessage.tsx'
import { useFormComputationState } from '../../atoms/FormComputation/useFormComputationState.ts'
import { Textarea } from '../../atoms/Textarea/Textarea.tsx'
import { NumberWithUnitInput } from '../../molecules/NumberWithUnitInput/NumberWithUnitInput.tsx'
import { NumberWithUnitInputFormField } from '../../molecules/NumberWithUnitInputFormField/NumberWithUnitInputFormField.tsx'

import { type InventoryProjectionFormData } from './InventoryProjectionFormZod.ts'

export const InventoryProjectionWidgetBody = () => {
  const intl = useIntl()
  const computedValues = useFormComputationState<Partial<Record<InventoryComputationType, string>>>()

  return (
    <>
      <div className="grid grid-cols-2 gap-2 py-2">
        <p className="select-none justify-self-center text-xs font-bold">
          {intl.formatMessage({ id: 'inventory.form.values.start' })}
        </p>
        <p className="select-none justify-self-center text-xs font-bold">
          {intl.formatMessage({ id: 'inventory.form.values.end' })}
        </p>
        <NumberWithUnitInput
          value={computedValues.startingUnitBalance}
          disabled
          readOnly
          variant="calculate"
          unit="head-count"
          dimension="xs"
        />
        <NumberWithUnitInput
          value={computedValues.unitBalance}
          disabled
          readOnly
          variant="calculate"
          unit="head-count"
          dimension="xs"
        />
        <NumberWithUnitInput
          value={computedValues.startingUnitValue}
          disabled
          readOnly
          variant="calculate"
          unit="currency-per-head-count"
          dimension="xs"
        />
        <NumberWithUnitInputFormField<InventoryProjectionFormData, 'unitValue'>
          name="unitValue"
          unit="currency-per-head-count"
          dimension="xs"
          step={0.01}
          placeholder={computedValues.unitValue}
        />
        <NumberWithUnitInput
          value={computedValues.startingValue}
          disabled
          readOnly
          variant="calculate"
          unit="currency"
          dimension="xs"
        />
        <NumberWithUnitInput
          value={computedValues.value}
          disabled
          readOnly
          variant="calculate"
          unit="currency"
          dimension="xs"
        />
        <p className="col-span-2 -mb-1 select-none pt-3 text-xs font-bold">
          {intl.formatMessage({ id: 'inventory.form.values.purchase' })}
        </p>
        <NumberWithUnitInputFormField<InventoryProjectionFormData, 'purchase'>
          name="purchase"
          variant="calculate"
          unit="head-count"
          dimension="xs"
          placeholder="0"
        />
        <NumberWithUnitInputFormField<InventoryProjectionFormData, 'purchaseCost'>
          name="purchaseCost"
          variant="calculate"
          unit="currency"
          dimension="xs"
          placeholder="0"
          step={0.01}
        />
        <p className="col-span-2 -mb-1 select-none pt-3 text-xs font-bold">
          {intl.formatMessage({ id: 'inventory.form.values.sale' })}
        </p>
        <NumberWithUnitInputFormField<InventoryProjectionFormData, 'sale'>
          name="sale"
          variant="calculate"
          unit="head-count"
          dimension="xs"
          placeholder="0"
        />
        <NumberWithUnitInputFormField<InventoryProjectionFormData, 'saleCost'>
          name="saleCost"
          variant="calculate"
          unit="currency"
          dimension="xs"
          placeholder="0"
          step={0.01}
        />
      </div>
      <FormField<InventoryProjectionFormData, 'note'>
        name="note"
        render={({ field }) => (
          <FormItem className="w-full">
            <FormControl>
              <Textarea
                placeholder={intl.formatMessage({ id: 'inventory.form.notes.placeholder' })}
                rows={3}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  )
}
