import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const badgeVariants = cva(
  'flex w-[90px] pt-[5px] pb-[3px] px-2 justify-center items-center gap-1 rounded-[3px] text-sm leading-none tracking-[-0.42px] select-none',
  {
    variants: {
      variant: {
        orange: 'border border-orange-600 bg-orange-100 text-orange-800',
        gray: 'border border-gray-600 bg-gray-100 text-gray-700',
        green: 'border border-green-600 bg-green-100 text-green-700',
        blue: 'bg-blue-700 text-white',
        red: 'bg-red-700 text-white',
      },
    },
    defaultVariants: {
      variant: 'orange',
    },
  }
)

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

export const Badge = ({ className, variant = 'orange', ...props }: BadgeProps) => (
  <div className={cn(badgeVariants({ variant }), className)} {...props} />
)
