import type React from 'react'

import { ClientListView } from '@via/components'

import { useClientListPage } from './useClientListPage.ts'

export const ClientListPage: React.FC = () => {
  const props = useClientListPage()
  return <ClientListView {...props} />
}
