import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

export const initFirebase = ({
  enableAppCheck = true,
  enableAnalytics = true,
}: {
  enableAppCheck?: boolean
  enableAnalytics?: boolean
} = {}) => {
  const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig)
  // required to initialize the authentification in web workers
  getAuth(app)

  if (enableAppCheck) {
    if (import.meta.env.VITE_FIREBASE_APPCHECK_DEBUG_TOKEN) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,no-extra-semi
      ;(globalThis as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true
    }

    initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(import.meta.env.VITE_RECAPTCHA_KEY),
      isTokenAutoRefreshEnabled: true,
    })
  }

  if (enableAnalytics) {
    getAnalytics(app)
  }

  if (import.meta.env.VITE_EMULATOR_HOST) {
    // eslint-disable-next-line no-console
    console.warn('Running in emulator mode (VITE_EMULATOR_HOST is set)')
    connectAuthEmulator(getAuth(app), `http://${import.meta.env.VITE_EMULATOR_HOST}:9099`)
    connectFunctionsEmulator(getFunctions(app), import.meta.env.VITE_EMULATOR_HOST, 5001)
    connectFunctionsEmulator(getFunctions(app, 'northamerica-northeast1'), import.meta.env.VITE_EMULATOR_HOST, 5001)
    connectFirestoreEmulator(getFirestore(app), import.meta.env.VITE_EMULATOR_HOST, 8081)
    connectStorageEmulator(getStorage(app), import.meta.env.VITE_EMULATOR_HOST, 9199)
  }

  return app
}
