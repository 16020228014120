import { z } from 'zod'

export const InventoryComputationTypeZod = z.enum([
  'startingUnitBalance',
  'startingUnitValue',
  'startingValue',
  'unitBalance',
  'unitValue',
  'value',
  'unitMovement', // unitBalance - startingUnitBalance
  'valueMovementUnitBalance', // (unitBalance - startingUnitBalance) * unitValue
  'valueMovementUnitValue', // startingUnitBalance * (startingUnitValue - unitValue)
  'valueMovement', // startingValue - value [equals to: valueMovementUnitBalance + valueMovementUnitValue]
  'purchase',
  'purchaseCost',
  'sale',
  'saleCost',
  'disbursement', // purchaseCost - saleCost
])
export type InventoryComputationType = z.infer<typeof InventoryComputationTypeZod>
