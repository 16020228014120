import { BigNumber } from 'bignumber.js'

import { protectedDivision } from '../../compute'
import { createBudgetDefinition, type DatabaseResolver, type TestResolverWithPossibleMetadata } from '../types'
import { isFermeBudgitel2022, sumBy } from '../utils'

import { foodExpenses } from './utils'

const milkAnimalUnitCountResolver = (testResolver: TestResolverWithPossibleMetadata, lineNumber: number) => ({
  testResolver,
  databaseResolver: {
    resolve: ({ animaux }) => sumBy(animaux({ production: 'LA', ligne: lineNumber }), 'inventaire_fin'),
  } as DatabaseResolver,
})

const milkAnimalUnitValueResolver = (lineNumber: number) => ({
  testResolver: false as TestResolverWithPossibleMetadata,
  databaseResolver: {
    resolve: ({ animaux }) => sumBy(animaux({ production: 'LA', ligne: lineNumber }), 'prix_tete_fin'),
  } as DatabaseResolver,
})

export const milk = createBudgetDefinition({
  // From Viagritel's Animaux section
  'inventory.milk.imported.cows.purebred.unit-count': milkAnimalUnitCountResolver('LA83', 1000),
  'inventory.milk.imported.cows.purebred.unit-value': milkAnimalUnitValueResolver(1000),
  'inventory.milk.imported.cows.crossbred.unit-count': milkAnimalUnitCountResolver('LA85', 1500),
  'inventory.milk.imported.cows.crossbred.unit-value': milkAnimalUnitValueResolver(1500),
  'inventory.milk.imported.bred-heifers.purebred.unit-count': milkAnimalUnitCountResolver('LA96', 2000),
  'inventory.milk.imported.bred-heifers.purebred.unit-value': milkAnimalUnitValueResolver(2000),
  'inventory.milk.imported.bred-heifers.crossbred.unit-count': milkAnimalUnitCountResolver('LA97', 2500),
  'inventory.milk.imported.bred-heifers.crossbred.unit-value': milkAnimalUnitValueResolver(2500),
  'inventory.milk.imported.unbred-heifers.purebred.unit-count': milkAnimalUnitCountResolver('LA98', 3000),
  'inventory.milk.imported.unbred-heifers.purebred.unit-value': milkAnimalUnitValueResolver(3000),
  'inventory.milk.imported.unbred-heifers.crossbred.unit-count': milkAnimalUnitCountResolver('LA99', 3500),
  'inventory.milk.imported.unbred-heifers.crossbred.unit-value': milkAnimalUnitValueResolver(3500),
  'inventory.milk.imported.heifers.purebred.unit-count': milkAnimalUnitCountResolver('LA100', 4000),
  'inventory.milk.imported.heifers.purebred.unit-value': milkAnimalUnitValueResolver(4000),
  'inventory.milk.imported.heifers.crossbred.unit-count': milkAnimalUnitCountResolver('LA101', 4500),
  'inventory.milk.imported.heifers.crossbred.unit-value': milkAnimalUnitValueResolver(4500),
  'inventory.milk.imported.bulls.adult.purebred.unit-count': milkAnimalUnitCountResolver('LA430', 5000),
  'inventory.milk.imported.bulls.adult.purebred.unit-value': milkAnimalUnitValueResolver(5000),
  'inventory.milk.imported.bulls.adult.crossbred.unit-count': milkAnimalUnitCountResolver('LA431', 5500),
  'inventory.milk.imported.bulls.adult.crossbred.unit-value': milkAnimalUnitValueResolver(5500),
  'inventory.milk.imported.bulls.young.purebred.unit-count': milkAnimalUnitCountResolver('LA432', 6000),
  'inventory.milk.imported.bulls.young.purebred.unit-value': milkAnimalUnitValueResolver(6000),
  'inventory.milk.imported.bulls.young.crossbred.unit-count': milkAnimalUnitCountResolver('LA433', 6500),
  'inventory.milk.imported.bulls.young.crossbred.unit-value': milkAnimalUnitValueResolver(6500),
  'inventory.milk.imported.calves.breeding.unit-count': milkAnimalUnitCountResolver('LA434', 7000),
  'inventory.milk.imported.calves.breeding.unit-value': milkAnimalUnitValueResolver(7000),
  'inventory.milk.imported.calves.market.unit-count': milkAnimalUnitCountResolver('LA435', 7500),
  'inventory.milk.imported.calves.market.unit-value': milkAnimalUnitValueResolver(7500),
  'inventory.milk.imported.embryos.unit-count': milkAnimalUnitCountResolver(false, 8000),
  'inventory.milk.imported.embryos.unit-value': milkAnimalUnitValueResolver(8000),
  'inventory.milk.imported.cows.exceptional.unit-count': milkAnimalUnitCountResolver(false, 8500),
  'inventory.milk.imported.cows.exceptional.unit-value': milkAnimalUnitValueResolver(8500),
  'inventory.milk.imported.cows.cull.unit-count': milkAnimalUnitCountResolver(false, 9000),
  'inventory.milk.imported.cows.cull.unit-value': milkAnimalUnitValueResolver(9000),

  // ViaBudget Milk Animals section
  'inventory.milk.imported.cows.unit-count': {
    testResolver: ({ cri }) => cri('LA83').plus(cri('LA85')),
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.cows.purebred.unit-count',
        'inventory.milk.imported.cows.crossbred.unit-count',
      ],
      resolve: ({ val }) =>
        val('inventory.milk.imported.cows.purebred.unit-count').plus(
          val('inventory.milk.imported.cows.crossbred.unit-count')
        ),
    },
  },
  'inventory.milk.imported.cows.unit-value': {
    testResolver: false,
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.cows.purebred.unit-count',
        'inventory.milk.imported.cows.purebred.unit-value',
        'inventory.milk.imported.cows.crossbred.unit-count',
        'inventory.milk.imported.cows.crossbred.unit-value',
      ],
      resolve: ({ val }) => {
        const totalHeads = val('inventory.milk.imported.cows.purebred.unit-count').plus(
          val('inventory.milk.imported.cows.crossbred.unit-count')
        )
        const purebredValue = val('inventory.milk.imported.cows.purebred.unit-count').times(
          val('inventory.milk.imported.cows.purebred.unit-value')
        )
        const crossbredValue = val('inventory.milk.imported.cows.crossbred.unit-count').times(
          val('inventory.milk.imported.cows.crossbred.unit-value')
        )
        return protectedDivision(purebredValue.plus(crossbredValue), totalHeads)
      },
    },
  },
  'inventory.milk.imported.bred-heifers.unit-count': {
    testResolver: ({ cri }) => cri('LA96').plus(cri('LA97')),
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.bred-heifers.purebred.unit-count',
        'inventory.milk.imported.bred-heifers.crossbred.unit-count',
      ],
      resolve: ({ val }) =>
        val('inventory.milk.imported.bred-heifers.purebred.unit-count').plus(
          val('inventory.milk.imported.bred-heifers.crossbred.unit-count')
        ),
    },
  },
  'inventory.milk.imported.bred-heifers.unit-value': {
    testResolver: false,
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.bred-heifers.purebred.unit-count',
        'inventory.milk.imported.bred-heifers.purebred.unit-value',
        'inventory.milk.imported.bred-heifers.crossbred.unit-count',
        'inventory.milk.imported.bred-heifers.crossbred.unit-value',
      ],
      resolve: ({ val }) => {
        const totalHeads = val('inventory.milk.imported.bred-heifers.purebred.unit-count').plus(
          val('inventory.milk.imported.bred-heifers.crossbred.unit-count')
        )
        const purebredValue = val('inventory.milk.imported.bred-heifers.purebred.unit-count').times(
          val('inventory.milk.imported.bred-heifers.purebred.unit-value')
        )
        const crossbredValue = val('inventory.milk.imported.bred-heifers.crossbred.unit-count').times(
          val('inventory.milk.imported.bred-heifers.crossbred.unit-value')
        )
        return protectedDivision(purebredValue.plus(crossbredValue), totalHeads)
      },
    },
  },
  'inventory.milk.imported.unbred-heifers.unit-count': {
    testResolver: ({ cri }) => cri('LA98').plus(cri('LA99')),
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.unbred-heifers.purebred.unit-count',
        'inventory.milk.imported.unbred-heifers.crossbred.unit-count',
      ],
      resolve: ({ val }) =>
        val('inventory.milk.imported.unbred-heifers.purebred.unit-count').plus(
          val('inventory.milk.imported.unbred-heifers.crossbred.unit-count')
        ),
    },
  },
  'inventory.milk.imported.unbred-heifers.unit-value': {
    testResolver: false,
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.unbred-heifers.purebred.unit-count',
        'inventory.milk.imported.unbred-heifers.purebred.unit-value',
        'inventory.milk.imported.unbred-heifers.crossbred.unit-count',
        'inventory.milk.imported.unbred-heifers.crossbred.unit-value',
      ],
      resolve: ({ val }) => {
        const totalHeads = val('inventory.milk.imported.unbred-heifers.purebred.unit-count').plus(
          val('inventory.milk.imported.unbred-heifers.crossbred.unit-count')
        )
        const purebredValue = val('inventory.milk.imported.unbred-heifers.purebred.unit-count').times(
          val('inventory.milk.imported.unbred-heifers.purebred.unit-value')
        )
        const crossbredValue = val('inventory.milk.imported.unbred-heifers.crossbred.unit-count').times(
          val('inventory.milk.imported.unbred-heifers.crossbred.unit-value')
        )
        return protectedDivision(purebredValue.plus(crossbredValue), totalHeads)
      },
    },
  },
  'inventory.milk.imported.heifers.unit-count': {
    testResolver: ({ cri }) => cri('LA100').plus(cri('LA101')),
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.heifers.purebred.unit-count',
        'inventory.milk.imported.heifers.crossbred.unit-count',
      ],
      resolve: ({ val }) =>
        val('inventory.milk.imported.heifers.purebred.unit-count').plus(
          val('inventory.milk.imported.heifers.crossbred.unit-count')
        ),
    },
  },
  'inventory.milk.imported.heifers.unit-value': {
    testResolver: false,
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.heifers.purebred.unit-count',
        'inventory.milk.imported.heifers.purebred.unit-value',
        'inventory.milk.imported.heifers.crossbred.unit-count',
        'inventory.milk.imported.heifers.crossbred.unit-value',
      ],
      resolve: ({ val }) => {
        const totalHeads = val('inventory.milk.imported.heifers.purebred.unit-count').plus(
          val('inventory.milk.imported.heifers.crossbred.unit-count')
        )
        const purebredValue = val('inventory.milk.imported.heifers.purebred.unit-count').times(
          val('inventory.milk.imported.heifers.purebred.unit-value')
        )
        const crossbredValue = val('inventory.milk.imported.heifers.crossbred.unit-count').times(
          val('inventory.milk.imported.heifers.crossbred.unit-value')
        )
        return protectedDivision(purebredValue.plus(crossbredValue), totalHeads)
      },
    },
  },
  'inventory.milk.imported.bulls.unit-count': {
    testResolver: ({ cri }) => cri('LA430').plus(cri('LA431')).plus(cri('LA432')).plus(cri('LA433')),
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.bulls.adult.purebred.unit-count',
        'inventory.milk.imported.bulls.adult.crossbred.unit-count',
        'inventory.milk.imported.bulls.young.purebred.unit-count',
        'inventory.milk.imported.bulls.young.crossbred.unit-count',
      ],
      resolve: ({ val }) =>
        val('inventory.milk.imported.bulls.adult.purebred.unit-count')
          .plus(val('inventory.milk.imported.bulls.adult.crossbred.unit-count'))
          .plus(val('inventory.milk.imported.bulls.young.purebred.unit-count'))
          .plus(val('inventory.milk.imported.bulls.young.crossbred.unit-count')),
    },
  },
  'inventory.milk.imported.bulls.unit-value': {
    testResolver: false,
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.bulls.adult.purebred.unit-count',
        'inventory.milk.imported.bulls.adult.purebred.unit-value',
        'inventory.milk.imported.bulls.adult.crossbred.unit-count',
        'inventory.milk.imported.bulls.adult.crossbred.unit-value',
        'inventory.milk.imported.bulls.young.purebred.unit-count',
        'inventory.milk.imported.bulls.young.purebred.unit-value',
        'inventory.milk.imported.bulls.young.crossbred.unit-count',
        'inventory.milk.imported.bulls.young.crossbred.unit-value',
      ],
      resolve: ({ val }) => {
        const totalHeads = val('inventory.milk.imported.bulls.adult.purebred.unit-count')
          .plus(val('inventory.milk.imported.bulls.adult.crossbred.unit-count'))
          .plus(val('inventory.milk.imported.bulls.young.purebred.unit-count'))
          .plus(val('inventory.milk.imported.bulls.young.crossbred.unit-count'))
        const adultPurebredValue = val('inventory.milk.imported.bulls.adult.purebred.unit-count').times(
          val('inventory.milk.imported.bulls.adult.purebred.unit-value')
        )
        const adultCrossbredValue = val('inventory.milk.imported.bulls.adult.crossbred.unit-count').times(
          val('inventory.milk.imported.bulls.adult.crossbred.unit-value')
        )
        const youngPurebredValue = val('inventory.milk.imported.bulls.young.purebred.unit-count').times(
          val('inventory.milk.imported.bulls.young.purebred.unit-value')
        )
        const youngCrossbredValue = val('inventory.milk.imported.bulls.young.crossbred.unit-count').times(
          val('inventory.milk.imported.bulls.young.crossbred.unit-value')
        )
        return protectedDivision(
          BigNumber.sum(adultPurebredValue, adultCrossbredValue, youngPurebredValue, youngCrossbredValue),
          totalHeads
        )
      },
    },
  },
  'inventory.milk.imported.calves.unit-count': {
    testResolver: ({ cri }) => cri('LA434').plus(cri('LA435')),
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.calves.breeding.unit-count',
        'inventory.milk.imported.calves.market.unit-count',
      ],
      resolve: ({ val }) =>
        val('inventory.milk.imported.calves.breeding.unit-count').plus(
          val('inventory.milk.imported.calves.market.unit-count')
        ),
    },
  },
  'inventory.milk.imported.calves.unit-value': {
    testResolver: false,
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.calves.breeding.unit-count',
        'inventory.milk.imported.calves.breeding.unit-value',
        'inventory.milk.imported.calves.market.unit-count',
        'inventory.milk.imported.calves.market.unit-value',
      ],
      resolve: ({ val }) => {
        const totalHeads = val('inventory.milk.imported.calves.breeding.unit-count').plus(
          val('inventory.milk.imported.calves.market.unit-count')
        )
        const breedingValue = val('inventory.milk.imported.calves.breeding.unit-count').times(
          val('inventory.milk.imported.calves.breeding.unit-value')
        )
        const marketValue = val('inventory.milk.imported.calves.market.unit-count').times(
          val('inventory.milk.imported.calves.market.unit-value')
        )
        return protectedDivision(breedingValue.plus(marketValue), totalHeads)
      },
    },
  },
  'inventory.milk.imported.other.unit-count': {
    testResolver: false,
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.cows.exceptional.unit-count',
        'inventory.milk.imported.cows.cull.unit-count',
        'inventory.milk.imported.embryos.unit-count',
      ],
      resolve: ({ val }) =>
        val('inventory.milk.imported.cows.exceptional.unit-count').plus(
          val('inventory.milk.imported.cows.cull.unit-count').plus(val('inventory.milk.imported.embryos.unit-count'))
        ),
    },
  },
  'inventory.milk.imported.other.unit-value': {
    testResolver: false,
    dependencyResolver: {
      dependencies: [
        'inventory.milk.imported.cows.exceptional.unit-count',
        'inventory.milk.imported.cows.exceptional.unit-value',
        'inventory.milk.imported.cows.cull.unit-count',
        'inventory.milk.imported.cows.cull.unit-value',
        'inventory.milk.imported.embryos.unit-count',
        'inventory.milk.imported.embryos.unit-value',
      ],
      resolve: ({ val }) => {
        const totalHeads = val('inventory.milk.imported.cows.exceptional.unit-count')
          .plus(val('inventory.milk.imported.cows.cull.unit-count'))
          .plus(val('inventory.milk.imported.embryos.unit-count'))
        const embryosValue = val('inventory.milk.imported.embryos.unit-count').times(
          val('inventory.milk.imported.embryos.unit-value')
        )
        const exceptionalValue = val('inventory.milk.imported.cows.exceptional.unit-count').times(
          val('inventory.milk.imported.cows.exceptional.unit-value')
        )
        const cullValue = val('inventory.milk.imported.cows.cull.unit-count').times(
          val('inventory.milk.imported.cows.cull.unit-value')
        )
        return protectedDivision(BigNumber.sum(cullValue, embryosValue, exceptionalValue), totalHeads)
      },
    },
  },
  'milk.income.imported.animals.embryos': {
    testResolver: false,
    dependencyResolver: {
      dependencies: ['inventory.milk.imported.embryos.unit-count', 'inventory.milk.imported.embryos.unit-value'],
      resolve: ({ val }) =>
        val('inventory.milk.imported.embryos.unit-count').times(val('inventory.milk.imported.embryos.unit-value')),
    },
  },

  // The rest
  'milk.income.imported.miscellaneous': {
    testResolver: false,
    dependencyResolver: {
      dependencies: [
        'milk.income.imported.sales.miscellaneous',
        'milk.income.imported.deductions.product',
        'milk.income.imported.deductions.cattle',
        'milk.income.imported.payable.balance',
        'milk.income.imported.receivable.balance',
      ],
      resolve: ({ val }) =>
        BigNumber.sum(
          val('milk.income.imported.sales.miscellaneous'),
          val('milk.income.imported.deductions.product'),
          val('milk.income.imported.deductions.cattle'),
          val('milk.income.imported.payable.balance'),
          val('milk.income.imported.receivable.balance')
        ),
    },
  },
  'milk.income.imported.deductions.product': {
    testResolver: ({ cri }) =>
      cri('ZZ233').isZero()
        ? new BigNumber(0)
        : cri('ZZ235').times(cri('LA9').plus(cri('LA10')).plus(cri('LA11')).minus(cri('ZZ238')).div(cri('ZZ233'))),
    dependencyResolver: {
      dependencies: [
        'milk.deductions.family.allowance.quantity',
        'milk.expenses.imported.marketing.products',
        'milk.income.imported.sales.products',
        'milk.sales.total.quantity',
      ],
      resolve: ({ val }) =>
        val('milk.sales.total.quantity').isZero()
          ? new BigNumber(0)
          : val('milk.deductions.family.allowance.quantity').times(
              val('milk.income.imported.sales.products')
                .minus(val('milk.expenses.imported.marketing.products'))
                .div(val('milk.sales.total.quantity'))
            ),
    },
  },
  'milk.expenses.imported.fees.miscellaneous': {
    testResolver: ({ cri }) =>
      cri('LA445')
        .plus(cri('LA29'))
        .plus(cri('LA30'))
        .plus(cri('LA425'))
        .plus(cri('LA31'))
        .plus(cri('LA32'))
        .plus(cri('LA310'))
        .plus(cri('LA35')),
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const comptesExploitationLines = [
          22_900, // Hoof care expenses             LA445
          23_000, // Dairy supplies                 LA29
          23_100, // Hiring & pension of cow        LA30
          23_150, // Pension animals of replacement LA425
          23_200, // Herd Management                LA31
          23_300, // Bedding (except straw)         LA32
          23_350, // Quota location                 LA310
          23_400, // Misc. dairy cattle expenses    LA35
        ]
        const comptesExploitationAmount = sumBy(
          comptesExploitation({ production: 'LA', ligne: comptesExploitationLines }),
          'montant'
        )

        const inventaireApprovisionnementLines = [
          20_200, // Dairy supplies         LA29
          20_400, // Bedding (except straw) LA32
          20_500, // Others                 LA35
        ]
        const deltaDollars = sumBy(
          inventairesApprovisionnements({ production: 'LA', ligne: inventaireApprovisionnementLines }),
          'delta_dollars'
        )

        return comptesExploitationAmount.plus(deltaDollars)
      },
    },
  },
  // TODO: JMa needs to be split
  'milk.expenses.imported.fees.reproduction': {
    testResolver: ({ cri }) => cri('LA27').plus(cri('LA28')),
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceLignes = [
          22_700, // Achat d'embryons                LA27
          22_800, // Insémination et transplantation LA28
        ]
        const ceAmount = sumBy(comptesExploitation({ production: 'LA', ligne: ceLignes }), 'montant')

        const iaLignes = [
          20_000, // Produits pour insémination LA28
          20_100, // Embryons                   LA27
        ]
        const iaAmount = sumBy(inventairesApprovisionnements({ production: 'LA', ligne: iaLignes }), 'delta_dollars')

        return ceAmount.plus(iaAmount)
      },
    },
  },
  'milk.expenses.imported.fees.veterinary': {
    testResolver: 'LA26',
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const medsAndVetExpenses = sumBy(comptesExploitation({ production: 'LA', ligne: 22_600 }), 'montant') // LA26

        const medsInventoryAmount = sumBy(
          inventairesApprovisionnements({ production: 'LA', ligne: 20_300 }), // LA26
          'delta_dollars'
        )

        return medsAndVetExpenses.plus(medsInventoryAmount)
      },
    },
  },
  'milk.expenses.imported.marketing.products': {
    testResolver: 'ZZ238',
    dependencyResolver: {
      dependencies: ['milk.marketing.quota.amount', 'milk.marketing.export.amount'],
      resolve: ({ val }) => val('milk.marketing.quota.amount').plus(val('milk.marketing.export.amount')),
    },
  },
  'milk.income.imported.inventory.breeders': {
    testResolver: 'ZZ86',
    databaseResolver: {
      resolve: ({ animaux }) =>
        animaux({ production: 'LA', type: 'breeding' }).reduce(
          (total, animal) =>
            total.plus(animal.prix_tete_fin.times(animal.inventaire_fin.minus(animal.inventaire_debut))),
          new BigNumber(0)
        ),
    },
  },
  'milk.income.imported.sales.animals': {
    testResolver: ({ cri }) => cri('LA13').plus(cri('LA15')).plus(cri('LA17')).plus(cri('LA18')).plus(cri('LA19')),
    dependencyResolver: {
      dependencies: [
        'milk.sales.cows.cull.amount',
        'milk.sales.cows.breed.amount',
        'milk.sales.cows.calf.amount',
        'milk.sales.cows.embryos.amount',
        'milk.sales.livestock.amount',
      ],
      resolve: ({ val }) =>
        val('milk.sales.cows.cull.amount')
          .plus(val('milk.sales.cows.breed.amount'))
          .plus(val('milk.sales.cows.calf.amount'))
          .plus(val('milk.sales.cows.embryos.amount'))
          .plus(val('milk.sales.livestock.amount')),
    },
  },
  'milk.income.imported.sales.products': {
    testResolver: ({ cri }) => cri('LA9').plus(cri('LA10')).plus(cri('LA11')),
    dependencyResolver: {
      dependencies: ['milk.sales.total.amount', 'milk.subsidies.amount', 'milk.dividends.amount'],
      resolve: ({ val }) =>
        val('milk.sales.total.amount').plus(val('milk.subsidies.amount')).plus(val('milk.dividends.amount')),
    },
  },
  'milk.income.imported.deductions.cattle': {
    testResolver: 'ZZ236',
    databaseResolver: {
      resolve: ({ fichesTechniques }) => fichesTechniques({ production: 'LA', ligne: 21_500 }).valeur, // Prélèvement d'animaux par la famille
    },
  },
  'milk.deductions.family.allowance.quantity': {
    testResolver: 'ZZ235',
    databaseResolver: {
      resolve: ({ fichesTechniques }) => fichesTechniques({ production: 'LA', ligne: 21_400 }).valeur,
    },
  },
  'milk.dividends.amount': {
    testResolver: 'LA11',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 20_300 }), 'montant'),
    },
  },
  'milk.marketing.export.amount': {
    testResolver: false, // TODO: Add test resolver
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 21_650 }), 'montant'),
    },
  },
  'milk.marketing.quota.amount': {
    testResolver: false, // TODO: Add test resolver
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 21_600 }), 'montant'),
    },
  },
  'milk.animals.income.breed.imported.head-count': {
    testResolver: 'LA14',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 20_600 }), 'quantite'),
    },
  },
  'milk.sales.cows.breed.amount': {
    testResolver: 'LA15',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 20_600 }), 'montant'),
    },
  },
  'milk.animals.income.breed.imported.value-per-head-count': {
    testResolver: false,
    dependencyResolver: {
      dependencies: ['milk.sales.cows.breed.amount', 'milk.animals.income.breed.imported.head-count'],
      resolve: ({ val }) =>
        protectedDivision(val('milk.sales.cows.breed.amount'), val('milk.animals.income.breed.imported.head-count')),
    },
  },
  'milk.animals.income.calf.imported.females-kept-proportion': {
    testResolver: false,
    dependencyResolver: {
      dependencies: ['milk.animals.income.calf.imported.head-count', 'milk.production.imported.cows.head-count'],
      resolve: ({ val }) =>
        protectedDivision(
          val('milk.animals.income.calf.imported.head-count')
            .minus(val('milk.production.imported.cows.head-count'))
            .negated()
            .shiftedBy(2),
          val('milk.production.imported.cows.head-count').dividedBy(2)
        ),
    },
  },
  'milk.animals.income.calf.imported.head-count': {
    testResolver: 'LA16',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 20_700 }), 'quantite'),
    },
  },
  'milk.animals.income.calf.imported.value-per-head-count': {
    testResolver: false,
    dependencyResolver: {
      dependencies: ['milk.sales.cows.calf.amount', 'milk.animals.income.calf.imported.head-count'],
      resolve: ({ val }) =>
        protectedDivision(val('milk.sales.cows.calf.amount'), val('milk.animals.income.calf.imported.head-count')),
    },
  },
  'milk.sales.cows.calf.amount': {
    testResolver: 'LA17',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 20_700 }), 'montant'),
    },
  },
  'milk.animals.income.cull.imported.proportion': {
    testResolver: false,
    dependencyResolver: {
      dependencies: ['milk.animals.income.cull.imported.head-count', 'milk.production.imported.cows.head-count'],
      resolve: ({ val }) =>
        protectedDivision(
          val('milk.animals.income.cull.imported.head-count'),
          val('milk.production.imported.cows.head-count')
        ).shiftedBy(2),
    },
  },
  'milk.animals.income.cull.imported.head-count': {
    testResolver: 'LA12',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 20_500 }), 'quantite'),
    },
  },
  'milk.animals.income.cull.imported.value-per-head-count': {
    testResolver: false,
    dependencyResolver: {
      dependencies: ['milk.sales.cows.cull.amount', 'milk.animals.income.cull.imported.head-count'],
      resolve: ({ val }) =>
        protectedDivision(val('milk.sales.cows.cull.amount'), val('milk.animals.income.cull.imported.head-count')),
    },
  },
  'milk.sales.cows.cull.amount': {
    testResolver: 'LA13',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 20_500 }), 'montant'),
    },
  },
  'milk.sales.cows.embryos.amount': {
    testResolver: 'LA18',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 20_800 }), 'montant'),
    },
  },
  'milk.animals.income.other.imported.head-count': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 20_900 }), 'quantite'),
    },
  },
  'milk.animals.income.other.imported.value-per-head-count': {
    testResolver: false,
    dependencyResolver: {
      dependencies: ['milk.sales.livestock.amount', 'milk.animals.income.other.imported.head-count'],
      resolve: ({ val }) =>
        protectedDivision(val('milk.sales.livestock.amount'), val('milk.animals.income.other.imported.head-count')),
    },
  },
  'milk.sales.livestock.amount': {
    testResolver: 'LA19',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 20_900 }), 'montant'),
    },
  },
  'milk.income.imported.sales.miscellaneous': {
    testResolver: 'LA20',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 21_100 }), 'montant'), // Autres revenus directs
    },
  },
  'milk.sales.total.amount': {
    testResolver: 'LA9',
    databaseResolver: {
      resolve: ({ comptesExploitation }) =>
        sumBy(comptesExploitation({ production: 'LA', ligne: [20_100, 20_050, 20_150] }), 'montant'),
    },
  },
  'milk.sales.total.quantity': {
    testResolver: 'ZZ233',
    databaseResolver: {
      resolve: ({ comptesExploitation }) =>
        sumBy(comptesExploitation({ production: 'LA', ligne: [20_100, 20_050, 20_150] }), 'quantite'),
    },
  },
  'milk.subsidies.amount': {
    testResolver: 'LA10',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 20_200 }), 'montant'),
    },
  },
  'milk.income.imported.payable.balance': {
    testResolver: 'LA122',
    databaseResolver: {
      resolve: ({ courtsTermes }) => sumBy(courtsTermes({ production: 'LA', type: ['CP', 'FR'] }), 'profit_perte'),
    },
  },
  'milk.income.imported.receivable.balance': {
    testResolver: 'LA124',
    databaseResolver: {
      resolve: ({ courtsTermes }) => sumBy(courtsTermes({ production: 'LA', type: ['CR', 'FP'] }), 'profit_perte'),
    },
  },
  'milk.expenses.imported.feed.cow': {
    testResolver: 'LA23',
    databaseResolver: {
      resolve: ({ aliments }) => foodExpenses(aliments({ production: 'LA', colonne: 'A' })),
    },
  },
  'milk.expenses.imported.feed.young': {
    testResolver: 'LA24',
    databaseResolver: {
      resolve: ({ aliments }) => foodExpenses(aliments({ production: 'LA', colonne: 'Y' })),
    },
  },
  'milk.expenses.imported.feed.miscellaneous': {
    testResolver: 'LA25',
    databaseResolver: {
      resolve: ({ aliments }) => foodExpenses(aliments({ production: 'LA', colonne: 'O' })),
    },
  },
  'milk.expenses.imported.marketing.animals': {
    testResolver: 'LA21',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 21_700 }), 'montant'), // Mise en marché animaux
    },
  },
  'milk.animals.expenses.imported.cows.value': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ comptesExploitation }) =>
        sumBy(
          comptesExploitation({
            production: 'LA',
            ligne: 21_900, // Achat vaches LA22
          }),
          'montant'
        ),
    },
  },
  'milk.animals.expenses.cows.imported.head-count': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ comptesExploitation }) =>
        sumBy(
          comptesExploitation({
            production: 'LA',
            ligne: 21_900, // Achat vaches LA22
          }),
          'quantite'
        ),
    },
  },
  'milk.animals.expenses.cows.imported.value-per-head-count': {
    testResolver: false,
    dependencyResolver: {
      dependencies: ['milk.animals.expenses.imported.cows.value', 'milk.animals.expenses.cows.imported.head-count'],
      resolve: ({ val }) =>
        protectedDivision(
          val('milk.animals.expenses.imported.cows.value'),
          val('milk.animals.expenses.cows.imported.head-count')
        ),
    },
  },
  'milk.animals.expenses.imported.others.value': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ comptesExploitation }) =>
        sumBy(
          comptesExploitation({
            production: 'LA',
            ligne: 22_000, // Achat autres animaux LA22
          }),
          'montant'
        ),
    },
  },
  'milk.animals.expenses.other.imported.head-count': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ comptesExploitation }) =>
        sumBy(
          comptesExploitation({
            production: 'LA',
            ligne: 22_000, // Achat autres animaux LA22
          }),
          'quantite'
        ),
    },
  },
  'milk.animals.expenses.other.imported.value-per-head-count': {
    testResolver: false,
    dependencyResolver: {
      dependencies: ['milk.animals.expenses.imported.others.value', 'milk.animals.expenses.other.imported.head-count'],
      resolve: ({ val }) =>
        protectedDivision(
          val('milk.animals.expenses.imported.others.value'),
          val('milk.animals.expenses.other.imported.head-count')
        ),
    },
  },
  'milk.expenses.imported.livestock.maintenance': {
    testResolver: 'LA22',
    dependencyResolver: {
      dependencies: ['milk.animals.expenses.imported.cows.value', 'milk.animals.expenses.imported.others.value'],
      resolve: ({ val }) =>
        val('milk.animals.expenses.imported.cows.value').plus(val('milk.animals.expenses.imported.others.value')),
    },
  },
  'milk.expenses.imported.maintenance.milking': {
    testResolver: 'LA446',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 23_700 }), 'montant'), // Entretien du système de traite
    },
  },
  'milk.expenses.imported.maintenance.equipment': {
    testResolver: 'LA34',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'LA', ligne: 23_800 }), 'montant'), // Entr. & loc. équipements
    },
  },

  /*
   * Milk configuration
   */
  // Nombre de vaches
  'milk.production.imported.cows.head-count': {
    testResolver: 'LA38',
    databaseResolver: {
      resolve: ({ fichesTechniques }) => fichesTechniques({ production: 'LA', ligne: 20_100 }).valeur, // LA38 - Nb moyen vaches (UDP)
    },
  },
  // Volume non livré (veaux, etc)
  'milk.production.imported.volume.liter.excluded': {
    testResolver: ({ cri }) => cri('LA51').plus(cri('LA52')).plus(cri('ZZ235')),
    databaseResolver: {
      resolve: ({ fichesTechniques }) =>
        sumBy(
          fichesTechniques({
            production: 'LA',
            ligne: [
              21_700, // LA51 - Lait consommé par les veaux
              21_800, // LA52 - Lait perdu ou jeté ou donné
              21_400, // ZZ235 - Prélèvement de lait par la famille
            ],
          }),
          'valeur'
        ),
    },
  },
  // Vente totales de lait (sans les primes)
  'milk.production.imported.dollars.liter.sold': {
    testResolver: 'LA9',
    databaseResolver: {
      resolve: ({ comptesExploitation }) =>
        sumBy(
          comptesExploitation({
            production: 'LA',
            ligne: [
              20_100, // Lait hors-quota ($)
              20_050, // Lait intra-quota ($)
              20_150, // Lait contrat export. ($)
            ],
          }),
          'montant'
        ),
    },
  },
  'milk.production.imported.volume.liter.sold': {
    testResolver: 'LA8',
    databaseResolver: {
      resolve: ({ comptesExploitation }) =>
        sumBy(
          comptesExploitation({
            production: 'LA',
            ligne: [
              20_100, // Lait hors-quota (litres)
              20_050, // Lait intra-quota (litres)
              20_150, // Lait contrat export. (litres)
            ],
          }),
          'quantite'
        ),
    },
  },
  'milk.production.imported.cows.liter-per-head-count': {
    testResolver: {
      test: ({ cri }) => {
        if (cri('LA38').isZero()) {
          return new BigNumber(0)
        }
        return cri('LA51').plus(cri('LA52')).plus(cri('ZZ235')).plus(cri('LA8')).dividedBy(cri('LA38'))
      },
    },
    dependencyResolver: {
      dependencies: [
        'milk.production.imported.volume.liter.excluded',
        'milk.production.imported.volume.liter.sold',
        'milk.production.imported.cows.head-count',
      ],
      resolve: ({ val }) => {
        if (val('milk.production.imported.cows.head-count').isZero()) {
          return new BigNumber(0)
        }

        return val('milk.production.imported.volume.liter.excluded')
          .plus(val('milk.production.imported.volume.liter.sold'))
          .div(val('milk.production.imported.cows.head-count'))
      },
    },
  },
  'milk.production.imported.content.kilogram-per-hectoliter.fat': {
    testResolver: [
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(100),
      },
    ],
    databaseResolver: {
      resolve: ({ fichesTechniques }) => fichesTechniques({ production: 'LA', ligne: 21_100 }).valeur, // Taux moyen de gras (paies de lait)
    },
  },
  'milk.production.imported.content.kilogram-per-hectoliter.protein': {
    testResolver: [
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(101),
      },
    ],
    databaseResolver: {
      resolve: ({ fichesTechniques }) => fichesTechniques({ production: 'LA', ligne: 21_200 }).valeur, // Taux moyen de protéine(paies de lait)
    },
  },
  'milk.production.imported.content.kilogram-per-hectoliter.lactose': {
    testResolver: [
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(102),
      },
    ],
    databaseResolver: {
      resolve: ({ fichesTechniques }) => fichesTechniques({ production: 'LA', ligne: 21_300 }).valeur, // Taux moyen autres solides(paies de lait)
    },
  },
  'milk.quota.imported.kilogram-of-fat-per-day.start-up': {
    testResolver: 'LA53', // Quota de la relève (KG-JR)
    databaseResolver: {
      resolve: ({ fichesTechniques }) =>
        fichesTechniques({
          production: 'LA',
          ligne: 24_100, // Quota prêté pour relève agricole
        }).valeur,
    },
  },
  'milk.sale.imported.hectoliter': {
    testResolver: false,
    dependencyResolver: {
      dependencies: ['milk.production.imported.volume.liter.sold'],
      resolve: ({ val }) => val('milk.production.imported.volume.liter.sold').dividedBy(100),
    },
  },
  'milk.sale.imported.currency-per-hectoliter.dividends': {
    testResolver: false,
    dependencyResolver: {
      dependencies: ['milk.dividends.amount', 'milk.sale.imported.hectoliter'],
      resolve: ({ val }) => {
        if (val('milk.sale.imported.hectoliter').isZero()) {
          return new BigNumber(0)
        }

        return val('milk.dividends.amount').dividedBy(val('milk.sale.imported.hectoliter'))
      },
    },
  },
  'milk.sale.imported.total': {
    testResolver: ({ cri }) =>
      cri('LA9') // Vente lait total valeur ($)
        .plus(cri('LA11')), // Ristournes laitières ($)
    dependencyResolver: {
      dependencies: ['milk.production.imported.dollars.liter.sold', 'milk.dividends.amount'],
      resolve: ({ val }) => val('milk.production.imported.dollars.liter.sold').plus(val('milk.dividends.amount')),
    },
  },
  'milk.quota.imported.asset.value': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ constant }) => constant({ type: 'asset', assetType: 'quota', code: 'LA' }),
    },
  },
  'milk.price.imported.fat': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ constant }) => constant({ type: 'constant', code: 'LF' }),
    },
  },
  'milk.price.imported.level-1.protein': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ constant }) => constant({ type: 'constant', code: 'LI' }),
    },
  },
  'milk.price.imported.level-1.lactose': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ constant }) => constant({ type: 'constant', code: 'LQ' }),
    },
  },
  'milk.price.imported.level-2.protein': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ constant }) => constant({ type: 'constant', code: 'PI' }),
    },
  },
  'milk.price.imported.level-2.lactose': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ constant }) => constant({ type: 'constant', code: 'P2' }),
    },
  },
  'milk.price.imported.bonus.bio': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ constant }) => constant({ type: 'constant', code: 'LS' }),
    },
  },
})
