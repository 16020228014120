import { type DeepReadonly } from 'rxdb'

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/

export const deepStringToDate = <T, S = T>(value: DeepReadonly<S>): T => {
  switch (true) {
    case value instanceof Date:
      return value as T
    case typeof value === 'string':
      return (DATE_REGEX.test(value) ? new Date(value) : value) as T
    case Array.isArray(value):
      return value.map((v) => deepStringToDate(v)) as T
    case value instanceof Object:
      return Object.fromEntries(Object.entries(value).map(([key, v]) => [key, deepStringToDate(v)])) as T
    default:
      return value as unknown as T
  }
}
