import { BigNumber } from 'bignumber.js'

import { nper } from '../../bignumber'

import { type LoanStateData } from './merge-loan-state'
import { PaymentFrequencyToAnnualEventCount } from './payment-frequency-to-annual-event-count'

export const computeNumberOfPayments = (state: LoanStateData) => {
  if (!state.interestRate || !state.paymentAmount || state.paymentAmount.lte(0)) {
    return null
  }
  const numberOfPaymentEventInYear = PaymentFrequencyToAnnualEventCount[state.paymentFrequency]
  const computedNumberOfPayments = nper(
    state.interestRate.shiftedBy(-2).dividedBy(numberOfPaymentEventInYear),
    state.paymentAmount.negated(),
    state.amount
  )
  if (computedNumberOfPayments.isNaN()) {
    return null
  }
  const periods = computedNumberOfPayments.integerValue(BigNumber.ROUND_CEIL)
  switch (state.paymentFrequency) {
    case 'monthly':
      return {
        periods: periods.toNumber(),
        months: periods.toNumber(),
      }
    default:
      return {
        periods: periods.toNumber(),
        months: periods
          .multipliedBy(12)
          .dividedBy(numberOfPaymentEventInYear)
          .integerValue(BigNumber.ROUND_CEIL)
          .toNumber(),
      }
  }
}

export const computeNumberOfFixedCapitalPayments = (state: LoanStateData) => {
  if (!state.fixedCapitalPaymentAmount || state.fixedCapitalPaymentAmount.lte(0)) {
    return null
  }
  const computedNumberOfPayments = state.amount.dividedBy(state.fixedCapitalPaymentAmount)
  const periods = computedNumberOfPayments.integerValue(BigNumber.ROUND_CEIL)
  switch (state.paymentFrequency) {
    case 'monthly':
      return {
        periods: periods.toNumber(),
        months: periods.toNumber(),
      }
    default:
      return {
        periods: periods.toNumber(),
        months: periods
          .multipliedBy(12)
          .dividedBy(PaymentFrequencyToAnnualEventCount[state.paymentFrequency])
          .integerValue(BigNumber.ROUND_CEIL)
          .toNumber(),
      }
  }
}
