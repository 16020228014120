import { type FC } from 'react'
import { useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { useCallbackWithConfirmation } from '../../context'
import {
  ScenarioMainLayout,
  type ScenarioMainLayoutProps,
} from '../../organisms/ScenarioMainLayout/ScenarioMainLayout.tsx'
import {
  ScenarioUpdateForm,
  type ScenarioUpdateFormProps,
} from '../../organisms/ScenarioUpdateForm/ScenarioUpdateForm.tsx'
import { type ScenarioUpdateFormData } from '../../organisms/ScenarioUpdateForm/ScenarioUpdateFormZod.ts'
import { useScenarioIndex } from '../../organisms/ScenarioUpdateForm/useScenarioIndex.ts'

export interface ScenarioUpdateViewLayoutProps
  extends Pick<
      ScenarioMainLayoutProps,
      | 'budget'
      | 'syncRequired'
      | 'navigationMenuCollapsedState'
      | 'onNavigationMenuCollapse'
      | 'onCloseBudget'
      | 'onRequestOwnership'
      | 'onRefreshScenarioReference'
      | 'onNavigationChange'
      | 'onUpdateBudget'
      | 'onUpdateScenarioReferences'
    >,
    Pick<ScenarioUpdateFormProps, 'scenario' | 'onCancel' | 'onErase' | 'onDuplicate' | 'onValueChange' | 'onConfirm'> {
  readonly offline: boolean
}

export const ScenarioUpdateViewLayout: FC<ScenarioUpdateViewLayoutProps> = ({
  budget,
  scenario,
  syncRequired,
  offline,
  navigationMenuCollapsedState,
  onNavigationMenuCollapse,
  onCloseBudget,
  onErase,
  onNavigationChange,
  onRequestOwnership,
  onRefreshScenarioReference,
  onUpdateScenarioReferences,
  onUpdateBudget,
  ...formProps
}) => {
  const intl = useIntl()
  const [name, numberOfYears] = useWatch<ScenarioUpdateFormData, ['name', 'projectionYear']>({
    name: ['name', 'projectionYear'],
    defaultValue: { name: scenario.name, projectionYear: scenario.period.numberOfYears },
  })
  const formBudget = {
    ...budget,
    scenarios: {
      ...budget.scenarios,
      [scenario._id]: {
        ...budget.scenarios[scenario._id],
        name,
        numberOfYears,
      },
    },
  }

  const scenarioIndex = useScenarioIndex({ scenario, budget })

  const onlyOneScenarioInBudget = Object.keys(budget.scenarios).length === 1
  const onEraseWithConfirmation = useCallbackWithConfirmation(
    onErase,
    {
      confirmLabel: intl.formatMessage({ id: 'scenarioConfiguration.action.erase.confirmationDialog.confirm' }),
      cancelLabel: intl.formatMessage({ id: 'scenarioConfiguration.action.erase.confirmationDialog.close' }),
      title: intl.formatMessage({ id: 'scenarioConfiguration.action.erase.confirmationDialog.title' }),
      subtitle: intl.formatMessage({ id: 'scenarioConfiguration.action.erase.confirmationDialog.subTitle' }),
    },
    [onlyOneScenarioInBudget]
  )

  return (
    <ScenarioMainLayout
      currentScenarioNavigationId="update"
      budget={formBudget}
      scenario={scenario}
      syncRequired={syncRequired}
      offline={offline ?? false}
      navigationMenuCollapsedState={navigationMenuCollapsedState}
      onNavigationMenuCollapse={onNavigationMenuCollapse}
      onCloseBudget={onCloseBudget}
      onNavigationChange={onNavigationChange}
      onRequestOwnership={onRequestOwnership}
      onRefreshScenarioReference={onRefreshScenarioReference}
      onUpdateScenarioReferences={onUpdateScenarioReferences}
      onUpdateBudget={onUpdateBudget}
      loading={false}>
      <div className="pl-32">
        <ScenarioUpdateForm
          budget={formBudget}
          eraseDisabled={budget.readonly || onlyOneScenarioInBudget}
          scenario={scenario}
          scenarioIndex={scenarioIndex}
          offline={offline}
          readonly={budget.readonly}
          onErase={onEraseWithConfirmation}
          {...formProps}
        />
      </div>
    </ScenarioMainLayout>
  )
}
