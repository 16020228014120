import { createRef, forwardRef, type HtmlHTMLAttributes, type Ref, useImperativeHandle } from 'react'

import { type Unit } from '@via/schema'
import { omit } from 'lodash-es'

import { TableNavigationProvider } from '../../context/table/TableNavigationProvider'
import { type NumberDisplayType } from '../../hooks/useFormatNumber.ts'
import { cn } from '../../lib/utils'

import { MonthlyTableDataRow } from './rows/MonthlyTableDataRow.tsx'
import { MonthlyTableDividerRow, type MonthlyTableDividerRowProps } from './rows/MonthlyTableDividerRow.tsx'
import { MonthlyTableHeaderRow } from './rows/MonthlyTableHeaderRow.tsx'
import { MonthlyTableTotalRow } from './rows/MonthlyTableTotalRow.tsx'

export type MonthlyTableBlueprintRow<RowId extends string> =
  | {
      readonly id: RowId
      readonly type: 'data'
      readonly title: string
      readonly unit?: Unit
      readonly numberDisplayType?: NumberDisplayType
      readonly defaultValue?: string | number
    }
  | {
      readonly id: RowId
      readonly type: 'total'
      readonly title: string
      readonly unit?: Unit
    }
  | ({
      readonly id: string
      readonly type: 'divider'
    } & MonthlyTableDividerRowProps)

export interface MonthlyTableBlueprint<RowId extends string> {
  readonly sections: ReadonlyArray<{
    readonly id: string
    readonly rows: ReadonlyArray<MonthlyTableBlueprintRow<RowId>>
  }>
}

export interface MonthlyTableProps<RowId extends string> extends HtmlHTMLAttributes<HTMLDivElement> {
  readonly startDate: string
  readonly blueprint: MonthlyTableBlueprint<RowId>
  readonly values?: Record<RowId, Record<string, string | number>>
}

export const MonthlyTable = forwardRef(
  <RowId extends string>(
    { startDate, blueprint, values, className, ...props }: MonthlyTableProps<RowId>,
    ref: Ref<HTMLDivElement>
  ) => {
    const tableRef = createRef<HTMLDivElement>()
    useImperativeHandle(ref, () => tableRef.current!, [tableRef])

    return (
      <div className={cn('flex flex-col gap-6', className)} {...props} ref={tableRef}>
        <TableNavigationProvider nbCellsPerRow={12} containerRef={tableRef}>
          {blueprint.sections.map((section) => (
            <section key={section.id}>
              <MonthlyTableHeaderRow startDate={startDate} />
              {section.rows.map((row) => {
                switch (row.type) {
                  case 'divider':
                    return <MonthlyTableDividerRow key={row.id} {...omit(row, 'type')} />
                  case 'data':
                    return (
                      <MonthlyTableDataRow
                        key={row.id}
                        rowId={row.id}
                        startDate={startDate}
                        values={values?.[row.id]}
                        defaultValue={row.defaultValue}
                        {...omit(row, 'type')}
                      />
                    )
                  case 'total':
                    return (
                      <MonthlyTableTotalRow key={row.id} startDate={startDate} values={values?.[row.id]} {...row} />
                    )
                  default:
                    throw new Error(`Unknown row type`)
                }
              })}
            </section>
          ))}
        </TableNavigationProvider>
      </div>
    )
  }
)
