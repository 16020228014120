import { forwardRef, useId } from 'react'

import { FormLabel } from '../../atoms/FormLabel/FormLabel.tsx'
import { cn } from '../../lib/utils'
import {
  FormatedNumberReadonlyInput,
  type FormatedNumberReadonlyInputProps,
} from '../FormatedNumberReadonlyInput/FormatedNumberReadonlyInput'

export interface FormatedNumberReadonlyInputWithLabelProps extends FormatedNumberReadonlyInputProps {
  readonly label?: string
}

export const FormatedNumberReadonlyInputWithLabel = forwardRef<
  HTMLInputElement,
  FormatedNumberReadonlyInputWithLabelProps
>(({ label, id, value, className, ...props }, ref) => {
  const reactId = useId()
  if (!label) {
    return <FormatedNumberReadonlyInput id={id ?? reactId} value={value} ref={ref} {...props} />
  }

  return (
    <div className={cn('flex flex-col gap-1.5 pt-0.5', className)}>
      <FormLabel dimension={props.dimension} htmlFor={id ?? reactId}>
        {label}
      </FormLabel>
      <FormatedNumberReadonlyInput id={id ?? reactId} value={value} ref={ref} {...props} />
    </div>
  )
})
