import { forwardRef, type HtmlHTMLAttributes } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const lineFormulaStripVariants = cva(
  'flex w-5 pl-1.5 flex-col justify-center items-start shrink-0 rounded-l-lg rounded-r-none text-black text-sm font-semibold leading-none tracking-[-0.28px] font-overpass-mono select-none',
  {
    variants: {
      variant: {
        head: 'bg-sky-100',
        calculate: 'bg-green-100',
        subTotal: 'bg-orange-100',
        total: 'bg-orange-200',
        empty: 'bg-white',
        none: '',
      },
    },
    defaultVariants: {
      variant: 'empty',
    },
  }
)

export type LineFormulaSymbol = 'empty' | 'minus' | 'add' | 'total' | 'none'

export interface LineFormulaStripProps
  extends HtmlHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof lineFormulaStripVariants> {
  readonly symbol?: LineFormulaSymbol
}

export const LineFormulaStrip = forwardRef<HTMLDivElement, LineFormulaStripProps>(
  ({ className, variant = 'empty', symbol = 'empty', ...props }, ref) => (
    <div
      className={cn(lineFormulaStripVariants({ variant: symbol === 'none' ? 'none' : variant }), className)}
      ref={ref}
      {...props}>
      {symbol === 'minus' && <p>-</p>}
      {symbol === 'add' && <p>+</p>}
      {symbol === 'total' && <p>=</p>}
    </div>
  )
)
