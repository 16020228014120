import { type LoadingReportViewProps, useBudget } from '@via/components'

import { useCurrentBudgetHandler } from '../../contexts/currentBudget/useCurrentBudgetHandler.ts'
import { useOfflineState } from '../../contexts/offlineState/useOfflineState.ts'
import { useAppNavigation } from '../../navigation/useAppNavigation.ts'

export const useLoadingReportsPage = (): LoadingReportViewProps => {
  const budget = useBudget()
  const { offline } = useOfflineState()
  const {
    close: onCloseBudget,
    requestOwnership: onRequestOwnership,
    update: onUpdateBudget,
  } = useCurrentBudgetHandler()
  const { onNavigationChange } = useAppNavigation()

  return {
    budget,
    offline,
    onCloseBudget,
    onRequestOwnership,
    onNavigationChange,
    onUpdateBudget,
  }
}
