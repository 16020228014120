import { useContext, useMemo } from 'react'

import { type RowComputationType, type RowData } from '@via/compute'
import { type DataKeysOfComputationType } from '@via/schema'

import { ScenarioRowsContext } from './ScenarioRowsContext.ts'

export const useScenarioSingleRowData = <Computation extends RowComputationType>(
  dataKey: DataKeysOfComputationType<Computation>
): RowData<Computation> | undefined => {
  const { rows } = useContext(ScenarioRowsContext)
  const row = useMemo(() => rows.find(({ key }) => key === dataKey), [dataKey, rows])
  return row as RowData<Computation> | undefined
}
