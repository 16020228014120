import { type FC, type PropsWithChildren, useMemo } from 'react'

import { BudgetContext } from './BudgetContext'
import { useBudget } from './useBudget'

export const ReadonlyBudgetContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const budget = useBudget()

  const value = useMemo(() => ({ ...budget, readonly: true }), [budget])

  return <BudgetContext.Provider value={value}>{children}</BudgetContext.Provider>
}
