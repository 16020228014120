import { type ButtonHTMLAttributes, forwardRef } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const headerSelectVariants = cva(
  'flex pt-3 pb-2.5 justify-center items-center w-24 h-8 shrink-0 text-black text-center',
  {
    variants: {
      variant: {
        default: 'bg-gray-100',
        head: 'bg-sky-50',
        total: 'bg-orange-200',
        alternate: 'bg-white',
      },
      withDividerTop: {
        false: '',
        true: 'border-t border-t-gray-400',
      },
      withDividerLeft: {
        false: 'pl-0.5',
        true: 'pl-px border-l border-l-gray-400',
      },
      withDividerRight: {
        false: 'pr-0.5',
        true: 'pr-px last:border-r border-r-gray-400',
      },
    },
    defaultVariants: {
      variant: 'default',
      withDividerTop: false,
      withDividerLeft: true,
      withDividerRight: false,
    },
  }
)

export interface HeaderSelectProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof headerSelectVariants> {
  readonly label: string
  readonly active?: boolean
}

export const HeaderSelect = forwardRef<HTMLButtonElement, HeaderSelectProps>(
  ({ className, variant, withDividerLeft, withDividerRight, withDividerTop, label, active = false, ...props }, ref) => (
    <div
      className={cn(headerSelectVariants({ variant, withDividerLeft, withDividerRight, withDividerTop }), className)}>
      <button
        type="button"
        className={cn(
          'flex select-none items-center justify-center gap-2.5 rounded border border-blue-700 px-2 pt-1 text-center text-sm font-normal leading-none tracking-[-0.28px]',
          active ? 'bg-blue-700 text-white' : 'bg-white text-black'
        )}
        ref={ref}
        {...props}>
        {label}
      </button>
    </div>
  )
)
