import '@via/frontend-tinymce/init'

import { LoadingReportView } from '@via/components'

import { useLoadingReportsPage } from './useLoadingReportsPage.ts'

export const LoadingReportsPage = () => {
  const props = useLoadingReportsPage()
  return <LoadingReportView {...props} />
}
