import { forwardRef, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { sortBy } from 'lodash-es'

import { TabList, type TabListData } from '../../molecules/TabList/TabList'
import { type NavigationHandler } from '../../navigation/types'
import { type BudgetData, type ReportData } from '../../types'

export const REPORTS_TAB_LIST_PADDING_TOP_PX = 6
export const REPORTS_TAB_LIST_HEIGHT_PX = 28 + REPORTS_TAB_LIST_PADDING_TOP_PX

export interface ReportsTabListActions {
  readonly onAddReportClick?: () => Promise<void>
}

export interface ReportsTabListProps extends ReportsTabListActions, React.HtmlHTMLAttributes<HTMLDivElement> {
  readonly tabId?: string
  readonly budget: BudgetData
  readonly reports?: ReadonlyArray<ReportData>
  readonly onNavigationChange: NavigationHandler
}

export const ReportsTabList = forwardRef<HTMLDivElement, ReportsTabListProps>(
  ({ tabId, budget, reports = [], onAddReportClick, onNavigationChange, ...props }, ref) => {
    const intl = useIntl()

    const reportsData = useMemo<TabListData>(() => {
      if (reports.length === 0) {
        return { tabs: [] }
      }

      const sortedReports = sortBy(reports, 'creationTimestamp')

      const reportsTabList = sortedReports.map((report, index) => ({
        id: report._id,
        title: intl.formatMessage({ id: 'reports.tabList.title' }, { number: index + 1 }),
        label: report.name || intl.formatMessage({ id: 'reports.tabList.defaultName' }),
        creationTimestamp: report.creationTimestamp,
        configuration: false,
        route: {
          to: '/budgets/$budgetId/reports/$reportId',
          params: { budgetId: budget._id, reportId: report._id },
        } as const,
      }))

      return {
        activeTabId: tabId,
        tabs: reportsTabList,
      }
    }, [budget._id, intl, reports, tabId])

    return (
      <TabList
        onAddClick={budget.readonly ? undefined : onAddReportClick}
        onNavigationChange={onNavigationChange}
        variant="blue"
        ref={ref}
        {...reportsData}
        {...props}
      />
    )
  }
)
