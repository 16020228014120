import '@via/frontend-tinymce/init'

import { OfflineReportView } from '@via/components'

import { useOfflineReportsPage } from './useOfflineReportsPage.ts'

export const OfflineReportsPage = () => {
  const props = useOfflineReportsPage()
  return <OfflineReportView {...props} />
}
