import { type ScenarioState } from '@via/compute'

import { investmentPlanLoan2024Blueprint } from './2024/investmentPlanLoan2024Blueprint.ts'

export const investmentPlanLoanBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...investmentPlanLoan2024Blueprint }
  }
}
