import { type FC } from 'react'

import {
  BudgetUpdateDialogProvider,
  type BudgetUpdateDialogProviderProps,
} from '../BudgetUpdateDialog/BudgetUpdateDialogProvider'

import { BudgetActionHeader, type BudgetActionHeaderProps } from './BudgetActionHeader'
import { BudgetInformationHeader, type BudgetInformationHeaderProps } from './BudgetInformationHeader'

export type BudgetHeaderProps = BudgetActionHeaderProps & BudgetInformationHeaderProps & BudgetUpdateDialogProviderProps

export const BudgetHeader: FC<BudgetHeaderProps> = ({
  offline,
  onCloseBudget,
  onRequestOwnership,
  budget,
  onUpdateBudget,
}) => (
  <BudgetUpdateDialogProvider budget={budget} onUpdateBudget={onUpdateBudget}>
    <div className="flex flex-1 justify-between">
      <BudgetInformationHeader offline={offline} budget={budget} onUpdateBudget={onUpdateBudget} />
      <BudgetActionHeader
        budget={budget}
        offline={offline}
        onCloseBudget={onCloseBudget}
        onRequestOwnership={onRequestOwnership}
      />
    </div>
  </BudgetUpdateDialogProvider>
)
