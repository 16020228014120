import { type FieldPathByValue, type FieldValues } from 'react-hook-form'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import {
  NumberWithUnitInputWithLabel,
  type NumberWithUnitInputWithLabelProps,
} from '../NumberWithUnitInputWithLabel/NumberWithUnitInputWithLabel.tsx'

export interface NumberWithUnitInputFormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPathByValue<TFieldValues, string> = FieldPathByValue<TFieldValues, string>,
> extends NumberWithUnitInputWithLabelProps {
  readonly name: TName
  readonly disabled?: boolean
}

export const NumberWithUnitInputFormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPathByValue<TFieldValues, string> = FieldPathByValue<TFieldValues, string>,
>({
  name,
  className,
  disabled,
  ...props
}: NumberWithUnitInputFormFieldProps<TFieldValues, TName>) => (
  <FormField<TFieldValues, TName>
    name={name}
    disabled={disabled}
    render={({ field }) => (
      <FormItem className={className}>
        <FormControl>
          <NumberWithUnitInputWithLabel {...props} {...field} />
        </FormControl>
        <FormMessage />
      </FormItem>
    )}
  />
)
