import { forwardRef } from 'react'

import { type YearKey } from '@via/schema'

import { cn } from '../../lib/utils'

import { MilkQuotaFormComputationProvider } from './MilkQuotaFormComputationProvider.tsx'
import { MilkQuotaFormMonthlyTable } from './MilkQuotaFormMonthlyTable.tsx'
import { MilkQuotaFormProvider, type MilkQuotaFormProviderProps } from './MilkQuotaFormProvider.tsx'
import { MilkQuotaSummaryTable } from './MilkQuotaSummaryTable.tsx'

export interface MilkQuotaFormProps extends MilkQuotaFormProviderProps {
  readonly year: YearKey
}

export const MilkQuotaForm = forwardRef<HTMLFormElement, MilkQuotaFormProps>(({ year, className, ...props }, ref) => (
  <MilkQuotaFormProvider year={year} className={cn('w-[991px]bg-white', className)} ref={ref} {...props}>
    <MilkQuotaFormComputationProvider year={year}>
      <main className="flex flex-col gap-8 py-6">
        <MilkQuotaSummaryTable />
        <MilkQuotaFormMonthlyTable year={year} />
      </main>
    </MilkQuotaFormComputationProvider>
  </MilkQuotaFormProvider>
))
