import {
  BudgetReferenceVersionsZod,
  BudgetReferenceZod,
  BudgetReportFilesZod,
  BudgetReportsZod,
  BudgetRequestZod,
  BudgetZod,
  ClientZod,
  ConfigurationZod,
  GroupZod,
  ScenarioEventZod,
  ScenarioStateZod,
  ScenarioZod,
  UserZod,
} from '@via/schema'
import { collectionsBuilder } from 'zod-firebase'

import { firestoreDataConverter } from './firestoreDataConverter'
import { ZodSchemaError } from './zodSchemaError'

const schema = {
  budgets: {
    zod: BudgetZod,
    references: {
      zod: BudgetReferenceZod,
      readonlyDocuments: true,
    },
    reports: {
      zod: BudgetReportsZod,
      files: {
        zod: BudgetReportFilesZod,
        readonlyDocuments: true,
      },
    },
    requests: {
      zod: BudgetRequestZod,
    },
    scenarios: {
      zod: ScenarioZod,
      events: {
        zod: ScenarioEventZod,
        readonlyDocuments: true,
      },
      states: {
        zod: ScenarioStateZod,
        readonlyDocuments: true,
      },
    },
  },
  budgetReferenceVersions: {
    zod: BudgetReferenceVersionsZod,
    readonlyDocuments: true,
  },
  configurations: {
    zod: ConfigurationZod,
    includeDocumentIdForZod: true,
    readonlyDocuments: true,
  },
  clients: {
    zod: ClientZod,
    readonlyDocuments: true,
  },
  groups: {
    zod: GroupZod,
    readonlyDocuments: true,
  },
  users: {
    zod: UserZod,
  },
} as const

export const collections = collectionsBuilder(schema, {
  zodErrorHandler: (error, snapshot) => {
    // eslint-disable-next-line no-console
    if (import.meta.env.DEV) console.error('zod error', error.errors, snapshot.data())
    return new ZodSchemaError(snapshot, { cause: error })
  },
  snapshotDataConverter: firestoreDataConverter,
})
