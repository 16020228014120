import { forwardRef, type HtmlHTMLAttributes, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { map } from 'lodash-es'

import { useScenarioTabList } from '../../context'
import { TabList, type TabListData, type TabListProps } from '../../molecules/TabList/TabList'
import { type NavigationHandler, route } from '../../navigation/types'
import { type BudgetData } from '../../types'

import { sortScenarios } from './sortScenarios'

export interface ScenarioTabListActions {
  readonly onAddScenarioClick?: TabListProps['onAddClick']
}

export interface ScenarioTabListProps extends ScenarioTabListActions, HtmlHTMLAttributes<HTMLDivElement> {
  readonly tabId?: string
  readonly budget: BudgetData
  readonly newScenarioId?: string
  readonly newScenarioName?: string
  readonly onNavigationChange: NavigationHandler
}

export const SCENARIO_TAB_LIST_PADDING_TOP_PX = 6
export const SCENARIO_TAB_LIST_HEIGHT_PX = 28

export const ScenarioTabList = forwardRef<HTMLDivElement, ScenarioTabListProps>(
  ({ tabId, budget, newScenarioId, newScenarioName, onAddScenarioClick, onNavigationChange, ...props }, ref) => {
    const intl = useIntl()
    const budgetScenarios = useScenarioTabList()

    const scenarioData = useMemo<TabListData>(() => {
      const scenarios = sortScenarios(budgetScenarios)

      const isOnScenarioCreation = !tabId
      const otherScenarioRoute = isOnScenarioCreation ? '../$scenarioId' : '../../$scenarioId'

      const scenariosData = map(
        scenarios,
        (value, index) =>
          ({
            id: value.id,
            title: intl.formatMessage({ id: 'scenario.tabList.title' }, { number: index + 1 }),
            label: value.name,
            creationTimestamp: value.creationTimestamp,
            configuration: false,
            route: route({
              to: otherScenarioRoute,
              params: { scenarioId: value.id },
            }),
          }) as const
      )

      return {
        activeTabId: tabId ?? newScenarioId ?? 'new',
        tabs: [
          ...scenariosData,
          ...(newScenarioId && !budgetScenarios[newScenarioId]
            ? [
                {
                  id: newScenarioId ?? 'new',
                  title: intl.formatMessage({ id: 'scenario.tabList.title' }, { number: scenarios.length + 1 }),
                  label: newScenarioName || intl.formatMessage({ id: 'scenario.tabList.new' }),
                  configuration: false,
                  route: route({
                    to: otherScenarioRoute,
                    params: { scenarioId: 'new' },
                  }),
                } as const,
              ]
            : []),
        ],
      }
    }, [budgetScenarios, tabId, newScenarioId, intl, newScenarioName])

    return (
      <TabList
        onAddClick={budget.readonly ? undefined : onAddScenarioClick}
        onNavigationChange={onNavigationChange}
        ref={ref}
        {...scenarioData}
        {...props}
      />
    )
  }
)
