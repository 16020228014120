import { createRef, forwardRef, memo, type Ref, useImperativeHandle } from 'react'

import { type Except } from 'type-fest'

import { type MonetaryCellData } from '../../model'
import { LineCellWithUnit, type LineCellWithUnitProps } from '../../molecules/LineCellWithUnit/LineCellWithUnit.tsx'

export interface MonetaryReadonlyValueCellProps<ValueType extends string | number = number>
  extends Except<LineCellWithUnitProps, 'value' | 'tabIndex' | 'withMessage' | 'dataStatus' | 'onFocus'> {
  readonly cellData?: MonetaryCellData<ValueType>
  readonly loading?: boolean
}

// eslint-disable-next-line prefer-arrow-callback
export const ForwardedMonetaryReadonlyValueCell = forwardRef(function MonetaryReadonlyValueCell<
  ValueType extends string | number = number,
>(
  {
    cellData,
    variant,
    loading,
    numberDisplayType = 'currency',
    negated,
    unit,
    ...props
  }: MonetaryReadonlyValueCellProps<ValueType>,
  ref: Ref<HTMLDivElement>
) {
  const cellRef = createRef<HTMLDivElement>()
  useImperativeHandle(ref, () => cellRef.current!, [cellRef])

  return (
    <LineCellWithUnit
      variant={variant}
      value={cellData?.value}
      unit={unit}
      data-raw-value={cellData?.value}
      tabIndex={0}
      withMessage={!!cellData?.note}
      dataStatus={loading ? 'loading' : cellData?.status}
      numberDisplayType={numberDisplayType}
      negated={negated}
      ref={cellRef}
      {...props}
    />
  )
})

export const MonetaryReadonlyValueCell = memo(ForwardedMonetaryReadonlyValueCell)
MonetaryReadonlyValueCell.displayName = 'MemoizedMonetaryReadonlyValueCell'
