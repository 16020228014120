import type React from 'react'

import { ClientListContext } from '@via/components'

import { useLocalClientListSnapshotState } from '../../rxdb/localSnapshot/useLocalClientListSnapshotState.ts'

export const OnlineClientListProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const state = useLocalClientListSnapshotState()
  return <ClientListContext.Provider value={state}>{children}</ClientListContext.Provider>
}
