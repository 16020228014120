import { createFileRoute } from '@tanstack/react-router'

import { ScenarioBalancePage } from '../pages/scenarioBalance/ScenarioBalancePage.tsx'
import { setScenarioCurrentPage } from '../pages/scenarioUpdate/utils/scenarioPage.ts'

export const Route = createFileRoute('/budgets/$budgetId/scenarios/$scenarioId/balance')({
  component: ScenarioBalancePage,
  loader: ({ params }) => {
    setScenarioCurrentPage(params.budgetId, params.scenarioId, 'balance')
  },
})
