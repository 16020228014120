import { type BaseRowData, type YearKey } from '@via/schema'

import { mapNonNullValues } from './map-non-null-values'

export const convertRowData = <TCell, TResult, TRowData extends BaseRowData<TCell>>(
  { references, values, reference, total, ...rest }: TRowData,
  convert: (value: TCell, key: YearKey | 'reference' | 'total') => TResult
) => ({
  references: mapNonNullValues(references, convert),
  values: mapNonNullValues(values, convert),
  ...(reference ? { reference: convert(reference, 'reference') } : {}),
  ...(total ? { total: convert(total, 'total') } : {}),
  ...rest,
})
