import { type RowData } from '@via/compute'
import { type LoanComputationType } from '@via/schema'

import { overridableMonetaryCellData } from './cell'
import { monetaryFromRecordRowData } from './utils.ts'

export const loanTotalMonetaryRowData = (
  { startingBalance, amount, ...rowData }: RowData<'finance-loan-total'>,
  loanComputationType: LoanComputationType
) => ({
  ...monetaryFromRecordRowData(rowData, loanComputationType),
  loan: {
    source: 'total' as const,
    ...(startingBalance ? { startingBalance: overridableMonetaryCellData(startingBalance) } : {}),
    ...(amount ? { amount: overridableMonetaryCellData(amount) } : {}),
  },
})
