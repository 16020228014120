import { type FC, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { Link } from '@tanstack/react-router'
import { cva } from 'class-variance-authority'
import dayjs from 'dayjs'

import { Icons } from '../../atoms'
import { Switch } from '../../atoms/Switch/Switch'
import { type BudgetData, type BudgetUpdateData } from '../../types'
import { useBudgetUpdatePageDialog } from '../BudgetUpdateDialog/useBudgetUpdateDialog'

export interface BudgetInformationHeaderProps {
  readonly offline?: boolean
  readonly budget: BudgetData
  readonly onUpdateBudget: (data: Omit<BudgetUpdateData, 'lastModification'>) => Promise<void> | void
}

const clientLinkLabelVariant = cva('line-clamp-1 text-base font-light uppercase', {
  variants: {
    offline: {
      true: '',
      false: 'cursor-pointer',
    },
    defaultVariants: {
      variant: true,
    },
  },
})

export const BudgetInformationHeader: FC<BudgetInformationHeaderProps> = ({ budget, offline, onUpdateBudget }) => {
  const intl = useIntl()
  const { setOpen } = useBudgetUpdatePageDialog()

  const onBudgetInformationClick = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const startOfYearOffset = useMemo(() => {
    const endMonth = dayjs().endOf('year').add(budget.client.startOfYearOffset, 'months').locale('fr-ca').format('MMM')
    return budget.client.startOfYearOffset > 0 ? `${endMonth}\u00A0(+1)` : endMonth
  }, [budget.client.startOfYearOffset])

  return (
    <div className="flex select-none items-center gap-2">
      <Link
        to="/clients/$clientId"
        params={{ clientId: budget.client.code }}
        disabled={offline}
        className="flex items-center">
        {!offline && <Icons.ArrowLeftShort className="pb-1" />}
        <p className={clientLinkLabelVariant({ offline })} translate="no">
          {budget.client.name}
        </p>
      </Link>
      <span className="text-sm text-sage-300">|</span>
      <p className="line-clamp-1 cursor-pointer text-sm" onClick={onBudgetInformationClick} translate="no">
        {`${intl.formatMessage({ id: 'budgetNav.configuration' })} `}
        <span className="cursor-pointer font-bold underline" translate="no">
          {budget.name}
        </span>
      </p>
      {!!budget.users.agronomist && (
        <>
          <span className="text-sm text-sage-300">|</span>
          <p className="line-clamp-1 cursor-pointer text-sm" onClick={onBudgetInformationClick} translate="no">
            {`${intl.formatMessage({ id: 'budgetNav.agronomist' })} `}
            <span className="cursor-pointer font-bold underline" translate="no">
              {budget.users.agronomist.name}
            </span>
          </p>
        </>
      )}
      <span className="text-sm text-sage-300">|</span>
      <p className="line-clamp-1 cursor-pointer text-sm" onClick={onBudgetInformationClick} translate="no">
        {`${intl.formatMessage({ id: 'budgetNav.endOfYearOffset' })} `}
        <span className="cursor-pointer font-bold underline" translate="no">
          {startOfYearOffset}
        </span>
      </p>
      <span className="text-sm text-sage-300">|</span>
      <p className="line-clamp-1 cursor-pointer text-sm" onClick={onBudgetInformationClick} translate="no">
        {intl.formatMessage({ id: 'budgetNav.year' }, { year: budget.year })}
      </p>
      <Switch
        disabled={budget.readonly}
        checked={budget.status === 'completed'}
        onCheckedChange={async (checked) => {
          await onUpdateBudget({
            status: checked ? 'completed' : 'todo',
            name: budget.name,
            users: budget.users,
            contractId: budget.contractId,
          })
        }}
      />
      <p className="line-clamp-1 cursor-pointer text-sm" onClick={onBudgetInformationClick} translate="no">
        {intl.formatMessage({ id: 'budgetNav.completed' })}
      </p>
    </div>
  )
}
