import { type FieldPath, type FieldValues } from 'react-hook-form'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import {
  PercentageInputWithLabel,
  type PercentageInputWithLabelProps,
} from '../PercentageInputWithLabel/PercentageInputWithLabel'

export interface PercentageInputFormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends PercentageInputWithLabelProps {
  readonly name: TName
  readonly disabled?: boolean
}

export const PercentageInputFormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  className,
  disabled,
  ...props
}: PercentageInputFormFieldProps<TFieldValues, TName>) => (
  <FormField<TFieldValues, TName>
    name={name}
    disabled={disabled}
    render={({ field }) => (
      <FormItem className={className}>
        <FormControl>
          <PercentageInputWithLabel {...props} {...field} />
        </FormControl>
        <FormMessage />
      </FormItem>
    )}
  />
)
