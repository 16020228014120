import React from 'react'

import Tractor from '../../assets/images/Tractor.png'
import { Button } from '../../atoms/Button/Button'
import { Icons } from '../../atoms/Icons/Icons'
import { cn } from '../../lib/utils'
import {
  UserAvatarDropdownMenu,
  type UserAvatarDropdownMenuProps,
} from '../../molecules/UserAvatarDropdownMenu/UserAvatarDropdownMenu'

import { Header, type HeaderProps } from './Header'

export interface ClientHeaderProps extends HeaderProps {
  readonly clientName: string
  readonly avatar: UserAvatarDropdownMenuProps
  onCloseClient(): void
}

export const ClientHeader = React.forwardRef<HTMLDivElement, ClientHeaderProps>(
  ({ clientName, onCloseClient, avatar, children, ...props }, ref) => (
    <Header ref={ref} {...props}>
      <div className={cn('flex h-full w-full items-center justify-between pl-3 pr-4')}>
        <div className="flex h-full shrink-0 select-none flex-col items-center justify-center border-x border-gray-300 px-4 py-0">
          <div className="flex items-center gap-1.5">
            <Button variant="outline" shape="iconOnly" dimension="xs" onClick={onCloseClient}>
              <Icons.XLg />
            </Button>
            {clientName ? (
              <p className="max-w-48 truncate text-nowrap align-bottom text-sm font-medium leading-7 text-gray-800">
                {clientName}
              </p>
            ) : (
              <img src={Tractor} className="size-4" alt="Tractor" />
            )}
          </div>
        </div>
        {children}
        <div className={cn('flex h-full items-center pr-4')}>
          <UserAvatarDropdownMenu {...avatar} />
        </div>
      </div>
    </Header>
  )
)
