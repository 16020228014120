import { type ClientContactZod } from '@via/schema'
import { type JsonSchema } from 'rxdb'
import { type z } from 'zod'

export const ClientContactJsonSchema = {
  type: 'object',
  required: ['name', 'role'],
  properties: {
    name: {
      type: 'string',
    },
    email: {
      type: 'string',
    },
    phone: {
      type: 'string',
    },
  },
} as const satisfies JsonSchema<z.output<typeof ClientContactZod>>
