import { useContext } from 'react'

import { FormComputationContext } from './FormComputationContext.ts'

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export const useFormComputationState = <State>(): State => {
  const context = useContext(FormComputationContext)
  if (!context) {
    throw new Error('useFormComputationState must be used within a FormComputationProvider')
  }
  return context as State
}
