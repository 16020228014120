import { useMemo, useState } from 'react'

import { SELECT_RESET_VALUE } from '../atoms/Select/SelectResetOption'

import { useCallbackRef } from './useCallbackRef'

export const useFilterBy = <T>({
  data,
  filterData,
}: {
  data: T[]
  filterData: (data: T, currentFilter: string | undefined) => boolean
}) => {
  const filterDataRef = useCallbackRef(filterData)
  const [filteredBy, setFilteredBy] = useState<string>()

  return useMemo(() => {
    const filteredData = !filteredBy ? data : data.filter((client) => filterDataRef(client, filteredBy))
    return {
      filteredData,
      onFilterByChange: (filterValue: string) => {
        setFilteredBy(filterValue === SELECT_RESET_VALUE ? undefined : filterValue)
      },
    }
  }, [data, filterDataRef, filteredBy])
}
