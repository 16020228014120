import { type FC, useCallback, useContext, useState } from 'react'

import { type BlueprintVersion, type LoanCategory } from '@via/schema'
import { nanoid } from 'nanoid'

import { AddItemButton } from '../../atoms/AddItemButton/AddItemButton'
import { Sheet, SheetContent, SheetTrigger } from '../../atoms/Sheet/Sheet'
import { LoanOperationsContext } from '../../context'
import { MonetaryTableEditingContext } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import { type LoanFormSubmitData } from '../LoanDetail'
import { LoanDetail } from '../LoanDetail/LoanDetail'

interface AddLoanSheetState {
  open: boolean
  created: boolean
  id: string
}

export interface AddLoanSheetProps {
  readonly blueprintVersion: BlueprintVersion
  readonly category: LoanCategory
}

export const AddLoanSheet: FC<AddLoanSheetProps> = ({ blueprintVersion, category }) => {
  const [state, setState] = useState<AddLoanSheetState>({ open: false, created: false, id: nanoid() })
  const { onAddLoan, onDeleteLoan } = useContext(LoanOperationsContext)
  const { setEditing } = useContext(MonetaryTableEditingContext)

  const setOpen = useCallback(
    (open = true) => {
      setEditing(open)
      setState((prevState) => ({ ...prevState, open, created: false, ...(open ? { id: nanoid() } : {}) }))
    },
    [setEditing]
  )

  const onApply = useCallback(
    async (params: LoanFormSubmitData) => {
      await onAddLoan(params)
      setState((prevState) => ({ ...prevState, created: true }))
    },
    [onAddLoan]
  )

  const onSubmit = useCallback(
    async (params: LoanFormSubmitData) => {
      await onAddLoan(params)
      setState({ open: false, created: false, id: nanoid() })
    },
    [onAddLoan]
  )

  const onDelete = useCallback(async () => {
    await onDeleteLoan(state.id, () => {
      setState({ open: false, created: false, id: nanoid() })
    })
  }, [onDeleteLoan, state.id])

  return (
    <Sheet open={state.open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <AddItemButton />
      </SheetTrigger>
      <SheetContent side="right" className="w-fit p-0">
        <LoanDetail
          data={{ id: state.id, blueprint: blueprintVersion }}
          category={category}
          onSubmit={onSubmit}
          onApply={onApply}
          onDelete={state.created ? onDelete : undefined}
        />
      </SheetContent>
    </Sheet>
  )
}
