import { type ClientListViewProps, useClientList, useFilterBy } from '@via/components'

import { useAppNavigation } from '../../navigation/useAppNavigation.ts'
import { useCurrentUserAvatarProps } from '../../navigation/useCurrentUserAvatarProps.tsx'

export const useClientListPage = (): ClientListViewProps => {
  const navigation = useAppNavigation()
  const { data } = useClientList()
  const avatar = useCurrentUserAvatarProps()

  const { filteredData, onFilterByChange } = useFilterBy({
    data,
    filterData: (client, currentFilter) => String(client.latestYear) === currentFilter,
  })

  return {
    ...navigation,
    avatar,
    data: filteredData,
    version: __APP_VERSION__,
    onFilterByChange,
  }
}
