import { type AnimalProductionType, DataKeyTableFrLabels, DataKeyValues, UnitFrLabels } from '@via/schema'

import { AnimalProductionsFrench } from './ProductionsFrench.ts'

const RepaymentTableFrench = {
  'table.repayment.balance': 'Solde résiduel',
  'table.repayment.margin': 'Marge brute',
  'table.repayment.charges': 'Salaire, retraits, impôts',
  'table.payments.section': 'Paiements',
  'table.repayment.changes': 'Variation de l’encaisse',
  'table.repayment.inflows': 'Provenance des fonds',
  'table.repayment.outflows': 'Utilisation des fonds',
  'table.repayment.margin.income.raw': 'Produits bruts totaux',
  'table.repayment.margin.expenses.operational': '(-)\u00A0Charges (avant salaires, intérêt, amort.)',
  'table.repayment.margin.total': 'Total',
  'table.repayment.residual.balance.basis': 'SOLDE RÉSIDUEL (base gestion)',
  'table.repayment.charges.salaries': 'Salaires généraux',
  'table.repayment.charges.withdrawals.total': 'Retraits des propriétaires',
  'table.repayment.charges.withdrawals.loan': 'Paiements sur dûs aux actionnaires et emprunts non-agricoles',
  'table.repayment.charges.withdrawals.other': 'Retraits des propriétaires (salaires, dividendes)',
  'table.repayment.charges.tax': 'Impôts exigibles de l’année',
  'table.repayment.charges.tax.total': 'Impôts',
  'table.repayment.charges.tax.milk.dividends': 'Impôts sur ristourne laitière',
  'table.repayment.charges.total': 'Total',
  'table.repayment.capacity.maximum': 'CDR MAXIMUM',
  'table.repayment.debt.payments.capital': 'Remboursement capital réel',
  'table.repayment.debt.payments.interest': 'Intérêts sur la dette',
  'table.repayment.debt.total': 'Total',
  'table.repayment.residual.balance.without.inventory':
    'SOLDE RÉSIDUEL (sans variations d’inventaire animaux et fourrages)',
  'table.repayment.inflows.balance.basis': 'Solde résiduel',
  'table.repayment.inflows.loans.agricultural.total': 'Nouveaux emprunts nets',
  'table.repayment.inflows.loans.agricultural.new-capital': 'Nouveaux emprunts totaux',
  'table.repayment.inflows.loans.agricultural.early-repayment': 'Emprunts refinancés',
  'table.repayment.inflows.short.margin.increase': 'Augmentation marge de crédit & comptes à payer',
  'table.repayment.inflows.receivables.decrease': 'Diminution comptes à recevoir',
  'table.repayment.inflows.contributions': 'Apports et emprunts personnels',
  'table.repayment.inflows.inventory.decrease': 'Diminution des inventaires animaux, récoltes, approvisionnements',
  'table.repayment.inflows.shares.sales': 'Ventes parts ou actions',
  'table.repayment.inflows.total': 'Total',
  'table.repayment.outflows.investment.total': 'Investissements nets agricoles',
  'table.repayment.outflows.investment.machinery': 'Machineries',
  'table.repayment.outflows.investment.buildings': 'Bâtiments',
  'table.repayment.outflows.investment.land': 'Fonds de terre',
  'table.repayment.outflows.investment.quota': 'Quota',
  'table.repayment.outflows.investment.assets.farming': 'Autres actifs agricoles',
  'table.repayment.outflows.investment.subsidies': 'Subventions diverses',
  'table.repayment.outflows.investment.programs': 'Placement net au comptes Agris',
  'table.repayment.outflows.investment.assets.other': 'Investissements nets non agricoles',
  'table.repayment.outflows.short.margin.decrease': 'Diminution marge de crédit & comptes à payer',
  'table.repayment.outflows.receivables.increase': 'Augmentation des comptes à recevoir',
  'table.repayment.outflows.inventory.increase': 'Augmentation des inventaires animaux, récoltes, approvisionnements',
  'table.repayment.outflows.shares.buys': 'Achats de parts ou actions',
  'table.repayment.outflows.total': 'Total',
  'table.repayment.current.accounts.balance': 'Variation de l’encaisse',
  'table.repayment.balance.sheet.validation': 'Comparaison au bilan',
  'table.repayment.balance.sheet.difference': 'Différence',
} as const

const BalanceTableFrench = {
  'table.balance.assets.short': 'Court terme',
  'table.balance.assets.short.liquidities': 'Encaisse et placements',
  'table.balance.assets.short.programs': 'Comptes Agri-Qc, Agri-invest. (programmes gouvernementaux)',
  'table.balance.assets.short.receivables': 'Comptes à recevoir',
  'table.balance.assets.short.inventory': 'Inventaires',
  'table.balance.assets.short.inventory.forage.amount': 'Fourrages et paille',
  'table.balance.assets.short.inventory.cereals.amount': 'Céréales & autres végétaux',
  'table.balance.assets.short.inventory.slaughter.amount': 'Animaux d’abattage',
  'table.balance.assets.short.inventory.supplies.amount': 'Approvisionnements',
  'table.balance.assets.short.inventory.miscellaneous.amount': 'Autres inventaires',
  'table.balance.assets.long': 'Moyen-long terme',
  'table.balance.assets.long.breeders.amount': 'Animaux reproducteurs',
  'table.balance.assets.long.machinery.amount': 'Machinerie et équipements',
  'table.balance.assets.long.buildings.amount': 'Bâtiments (valeur contributive)',
  'table.balance.assets.long.land.amount': 'Terre',
  'table.balance.assets.long.quotas.amount': 'Quotas',
  'table.balance.assets.long.nonfarming.amount': 'Autres actifs agricoles',
  'table.balance.assets.total': 'Total des actifs',
  'table.balance.liabilities.short': 'Dette court terme',
  'table.balance.liabilities.short.banking': 'Découvert bancaire',
  'table.balance.liabilities.short.credit': 'Marge de crédit, plus paiements anticipés',
  'table.balance.liabilities.short.payables': 'Comptes fournisseurs',
  'table.balance.liabilities.short.loans': 'Exigibilités sur emprunts',
  'table.balance.liabilities.loans': 'Dette',
  'table.balance.liabilities.loans.medium': 'Emprunts moyen terme',
  'table.balance.liabilities.loans.long': 'Emprunts long terme',
  'table.balance.liabilities.total': 'Total des passifs',
  'table.balance.net.total': 'Avoir des propriétaires',
  'table.balance.validation': 'Comparaison avec l’avoir des propriétaires',
  'table.balance.validation.profit': 'Bénéfice d’exploitation',
  'table.balance.validation.inflation': 'Inflation',
  'table.balance.validation.withdrawals': 'Total des retraits',
  'table.balance.validation.contributions': 'Total des apports',
  'table.balance.validation.other-movements': 'Autres mouvements sur l’avoir',
  'table.balance.owners.assets.total': 'Total passif et avoir des propriétaires',
  'table.balance.exclusions.section': '',
  'table.balance.exclusions': 'Non inclus dans le bilan',
  'table.balance.exclusions.residence.amount': 'Résidence',
  'table.balance.exclusions.nonfarming.assets.amount': 'Autres actifs non agricoles',
  'table.balance.exclusions.buildings.loss.amount': 'Perte de valeur contr. bâtiments',
} as const

const FinanceTableFrench = {
  'table.finance.investment.plan': 'Plan d’investissement',
  'table.finance.investment.type': 'Type',
  'table.finance.investment.amount': 'Montant',
  'table.finance.investment.total': 'Total',

  'table.finance.investment.plan.total': 'Total des investissements',

  'table.finance.shortTerm.plan': 'Gestion du court terme',
  'table.finance.shortTerm.start': 'Début',

  'table.finance.short.liquidities': 'Encaisse',
  'table.finance.short.assets.programs': 'Comptes Agri-Qc, Agri-invest.',
  'table.finance.short.assets.receivables': 'Compte à recevoir',
  'table.finance.short.liabilities.credit': '(-)\u00A0Marge de crédit',
  'table.finance.short.liabilities.payables': '(-)\u00A0Compte fournisseur',

  'table.finance.short.liquidities.balance': 'Encaisse',
  'table.finance.short.assets.programs.balance': 'Comptes Agri-Qc, Agri-invest.',
  'table.finance.short.assets.receivables.balance': 'Compte à recevoir',
  'table.finance.short.liabilities.credit.balance': '(-)\u00A0Marge de crédit',
  'table.finance.short.liabilities.payables.balance': '(-)\u00A0Compte fournisseur',

  'table.finance.net-margin.total': 'Activités d’exploitation (Marge nette)',

  'table.finance.loan': 'Emprunts',
  'table.finance.loan.name': 'Emprunts',
  'table.finance.loan.startingBalance': 'Solde',
  'table.finance.loan.interestRate': 'Taux d’intérêt',
  'table.finance.loan.duration': 'Durée restante',
  'table.finance.loan.agricultural.imported.header': 'Emprunts agricoles',
  'table.finance.loan.agricultural.new.header': 'Nouveaux emprunts',

  'table.finance.loan.agricultural.medium.imported': 'Emprunts agricoles',
  'table.finance.loan.agricultural.long.imported': 'Emprunts agricoles',

  'table.finance.loan.agricultural.medium.imported.total': 'Total',
  'table.finance.loan.agricultural.medium.imported.total.remainingDueAmount': 'Solde fin',
  'table.finance.loan.agricultural.medium.imported.total.exigible-capital': 'Exigibilités sur emprunts',

  'table.finance.loan.agricultural.long.imported.total': 'Total',
  'table.finance.loan.agricultural.long.imported.total.remainingDueAmount': 'Solde fin',
  'table.finance.loan.agricultural.long.imported.total.exigible-capital': 'Exigibilités sur emprunts',

  'table.finance.loan.agricultural.medium.new': 'Nouveaux emprunts',
  'table.finance.loan.agricultural.long.new': 'Nouveaux emprunts',
  'table.finance.loan.agricultural.medium.new.total': 'Total',
  'table.finance.loan.agricultural.long.new.total': 'Total',

  'table.finance.loan.agricultural.medium.total': 'Total',
  'table.finance.loan.agricultural.long.total': 'Total',
  'table.finance.loan.agricultural.new.total': 'Total',
  'table.finance.loan.agricultural.imported.total': 'Total',

  'table.finance.loan.agricultural.imported.total.projected-capital': 'Capital réel',
  'table.finance.loan.agricultural.imported.total.new-capital': 'Apport des emprunts',
  'table.finance.loan.agricultural.imported.total.capital': 'Capital',
  'table.finance.loan.agricultural.imported.total.interest': 'Intérêt',

  'table.finance.loan.agricultural.new': 'Nouveaux emprunts',

  'table.finance.loan.agricultural.total': 'Total',
  'table.finance.loan.agricultural.total.new-capital': 'Apport des emprunts',
  'table.finance.loan.agricultural.total.early-repayment': 'Remboursement anticipé',

  'table.finance.loan.existing.header': 'Emprunts existants',
  'table.finance.loan.existing': 'Emprunts existants',
  'table.finance.loan.existing.total': 'Total',

  'table.finance.loan.other.header': 'Emprunts non-agricoles',
  'table.finance.loan.other': 'Emprunts non-agricoles',
  'table.finance.loan.other.total': 'Total',
  'table.finance.loan.other.total.projected-capital': 'Capital réel',
  'table.finance.loan.other.total.new-capital': 'Apport des emprunts',
  'table.finance.loan.other.total.capital': 'Capital',
  'table.finance.loan.other.total.interest': 'Intérêt',
  'table.finance.loan.other.total.net-new-capital': 'Apport des emprunts',

  'table.finance.loan.shareholders-due.header': 'Dûs aux actionnaires',
  'table.finance.loan.shareholders-due': 'Dûs aux actionnaires',
  'table.finance.loan.shareholders-due.total': 'Total',
  'table.finance.loan.shareholders-due.total.projected-capital': 'Capital réel',
  'table.finance.loan.shareholders-due.total.new-capital': 'Apport des emprunts',
  'table.finance.loan.shareholders-due.total.capital': 'Capital',
  'table.finance.loan.shareholders-due.total.interest': 'Intérêt',
  'table.finance.loan.shareholders-due.total.net-new-capital': 'Apport des emprunts',

  'table.finance.loan.total': 'Total',
  'table.finance.loan.total.new-capital': 'Apport des emprunts',
  'table.finance.loan.total.annuity': 'Annuité',
  'table.finance.loan.total.interest': 'Total des Intérêts',
  'table.finance.loan.total.exigible-capital': 'Exigibilités sur emprunts',
  'table.finance.loan.total.projected-capital': 'Capital réel',
  'table.finance.loan.total.early-repayment': 'Remboursement anticipé',

  'table.finance.loan.computation.disbursements': 'Nouveaux déboursés',
  'table.finance.loan.computation.repayment': 'Remboursements',
  'table.finance.loan.computation.annuity': 'Annuité',
  'table.finance.loan.computation.interest': 'Intérêt',
  'table.finance.loan.computation.capital': 'Capital',
  'table.finance.loan.computation.projected-capital': 'Capital réel',
  'table.finance.loan.computation.endingBalance': 'Solde fin',
  'table.finance.loan.computation.exigible': 'Exigible',

  'table.finance.loan.interests.grants': 'Subvention d’intérêts FADQ',
  'table.finance.loan.cdr': 'CDR Maximum',
  'table.finance.loan.payments': 'Total des paiements',
  'table.finance.loan.balance': 'Solde résiduel (base gestion)',
} as const

const MilkAnimalsTableFrench = {
  'table.milkAnimals.tabs.variation': 'Variation',
  'table.milkAnimals.tabs.heads': 'Têtes',
} as const

const TableSectionsFrench = {
  'table.balance.section': 'Bilan',
  'table.assets.section': 'Actifs',
  'table.liabilities.section': 'Passifs',
} as const

type DataKeyLabels = Record<`table.${(typeof DataKeyValues)[number]}`, string>

export const TableFrench = {
  ...(Object.fromEntries(DataKeyValues.map((key) => [`table.${key}`, `*!${key}!*`])) as DataKeyLabels),
  ...(Object.fromEntries(
    Object.entries(AnimalProductionsFrench).map(([key, label]) => [`investment.form.subtype.${key}`, label])
  ) as Record<`investment.form.subtype.${AnimalProductionType}`, string>),
  ...RepaymentTableFrench,
  ...BalanceTableFrench,
  ...FinanceTableFrench,
  ...MilkAnimalsTableFrench,
  ...TableSectionsFrench,
  ...DataKeyTableFrLabels,
  ...UnitFrLabels,
} as const

export type TableFrenchType = keyof typeof TableFrench
