import { type DependencyList, useCallback } from 'react'

import { type ConfirmationDialogOptions } from './ConfirmationDialogProvider'
import { useConfirmationDialogContext } from './useConfirmationDialogContext'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCallbackWithConfirmation = <T extends (...args: any[]) => Promise<void> | void>(
  callback: T,
  options: Omit<ConfirmationDialogOptions, 'onConfirm'>,
  deps: DependencyList
): ((...args: Parameters<T>) => void) => {
  const { open } = useConfirmationDialogContext()
  return useCallback(
    (...args: Parameters<T>) => {
      open({ ...options, onConfirm: () => callback(...args) })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, options, open, ...deps]
  )
}
