import { useSessionStorageValue } from '@react-hookz/web/useSessionStorageValue/index.js'
import { type SortingState } from '@tanstack/react-table'

export interface UsePersistedTableSortingOptions {
  key: string
  defaultValue: SortingState
}

export const usePersistedTableSorting = ({ key, defaultValue }: UsePersistedTableSortingOptions) => {
  const { value, set } = useSessionStorageValue(key, { defaultValue })

  return {
    sorting: value,
    onSortingChange: set,
  }
}
