import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { addBreadcrumb } from '@sentry/react'

import { ConfirmationDialog, type ConfirmationDialogProps } from '../../molecules/ConfirmationDialog/ConfirmationDialog'

export interface ConfirmationDialogOptions {
  onConfirm(): Promise<void> | void
  confirmLabel?: string
  onCancel?(): Promise<unknown> | void
  cancelLabel?: string
  title: string
  subtitle?: string
}
export interface ConfirmationDialogContextValue {
  open(options: ConfirmationDialogOptions): void
}

export const ConfirmationDialogContext = React.createContext<ConfirmationDialogContextValue | null>(null)

export const ConfirmationDialogProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const intl = useIntl()

  const defaultOptions = useMemo(
    () => ({
      onConfirm: () => Promise.reject(new Error('open should be set before calling onConfirm')),
      onCancel: () => Promise.reject(new Error('open should be set before calling onCancel')),
      confirmLabel: intl.formatMessage({ id: 'dialog.confirm' }),
      cancelLabel: intl.formatMessage({ id: 'dialog.cancel' }),
    }),
    [intl]
  )

  const [props, setProps] = React.useState<ConfirmationDialogProps>({
    ...defaultOptions,
    open: false,
    title: '',
  })

  const contextValue = useMemo<ConfirmationDialogContextValue>(
    () => ({
      open: ({ onConfirm, onCancel, ...options }: ConfirmationDialogOptions) => {
        addBreadcrumb({
          category: 'ui',
          message: 'open ConfirmationDialogProvider',
          data: { options },
        })

        const onConfirmAndClose = async () => {
          await onConfirm()
          setProps((state) => ({
            ...state,
            ...defaultOptions,
            open: false,
          }))
        }

        const onCancelAndClose = async () => {
          await onCancel?.()
          setProps((state) => ({
            ...state,
            ...defaultOptions,
            open: false,
          }))
        }
        setProps({
          ...defaultOptions,
          open: true,
          onConfirm: onConfirmAndClose,
          onCancel: onCancelAndClose,
          ...options,
        })
      },
    }),
    [defaultOptions]
  )

  return (
    <ConfirmationDialogContext.Provider value={contextValue}>
      <ConfirmationDialog {...props} />
      {children}
    </ConfirmationDialogContext.Provider>
  )
}
