import { type ProductionType } from '../../../enums'
import { type DataKey } from '../../data-key-zod'
import { InventoryAttributesJson, InventoryTotalAttributesJson } from '../../generated'

import { type GrbfDataKey } from './grbf-attributes'
import { type ReferenceDataKey } from './reference-attributes'

export interface InventoryDataKeyAttribute<UnitValueDeps extends DataKey = DataKey> {
  readonly type: 'inventory'
  readonly readonly: false
  readonly production?: ProductionType
  readonly referenceDependencies?: {
    readonly unitBalance?: ReadonlyArray<ReferenceDataKey>
  }
  readonly dependencies: {
    readonly unitValue: UnitValueDeps
  }
}

export interface InventoryTotalDataKeyAttribute<Deps extends InventoryDataKey = InventoryDataKey> {
  readonly type: 'inventory-total'
  readonly readonly: false
  readonly production?: ProductionType
  readonly dependencies: ReadonlyArray<Deps>
}

export const InventoryDataKeyAttributes = InventoryAttributesJson as Record<
  keyof typeof InventoryAttributesJson,
  InventoryDataKeyAttribute<GrbfDataKey>
>

export const InventoryTotalDataKeyAttributes = InventoryTotalAttributesJson as Record<
  keyof typeof InventoryTotalAttributesJson,
  InventoryTotalDataKeyAttribute
>

export type InventoryDataKey = keyof typeof InventoryDataKeyAttributes
export type InventoryTotalDataKey = keyof typeof InventoryTotalDataKeyAttributes
