import { type z } from 'zod'

import { InventoryComputationTypeZod } from './inventory-computation-type-zod'

export const InventoryTotalComputationTypeZod = InventoryComputationTypeZod.exclude([
  'startingUnitBalance',
  'startingUnitValue',
  'unitBalance',
  'unitValue',
  'unitMovement',
  'purchase',
  'purchaseCost',
  'sale',
  'saleCost',
])
export type InventoryTotalComputationType = z.infer<typeof InventoryTotalComputationTypeZod>
