import { type JsonSchema, type RxJsonSchema } from 'rxdb'

import { type RxCollectionDocumentType, type ScenarioRxDatabase } from '../types.ts'

type ScenarioReferenceRxDocument = RxCollectionDocumentType<ScenarioRxDatabase['reference']>

export const ScenarioReferenceRxSchema = {
  title: 'scenario reference',
  description: 'scenario reference schema',
  version: 0,
  type: 'object',
  primaryKey: '_id',
  required: ['_id'],
  properties: {
    _id: {
      type: 'string',
      maxLength: 100,
    },
    version: {
      type: 'number',
    } satisfies JsonSchema<ScenarioReferenceRxDocument['version']>,
  },
} as const satisfies RxJsonSchema<Pick<ScenarioReferenceRxDocument, '_id' | 'version'>>
