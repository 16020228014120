import { BigNumber } from 'bignumber.js'

import { createBudgetDefinition, type ViagritelRowEmprunt } from '../types'
import { isFermeBudgitel2021, isFermeBudgitel2022, sumBy } from '../utils'

const reduceEmpruntsBy = (emprunts: ViagritelRowEmprunt[], key: keyof ViagritelRowEmprunt['current']) =>
  emprunts.reduce((acc, emprunt) => {
    const value = emprunt.current[key]
    return value ? acc.plus(value) : acc
  }, new BigNumber(0))

export const loan = createBudgetDefinition({
  'finance.loan.agricultural.medium.imported.total.remaining-capital': {
    testResolver: ({ cri }) => cri('ZZ101'), // Emprunts MT fin
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(emprunts({ loanCategory: ['agricultural.medium-term'] }), 'endCapital'),
    },
  },
  'finance.loan.agricultural.long.imported.total.remaining-capital': {
    testResolver: ({ cri }) => cri('ZZ105'), // Emprunts LT fin
    databaseResolver: {
      resolve: ({ emprunts }) => reduceEmpruntsBy(emprunts({ loanCategory: ['agricultural.long-term'] }), 'endCapital'),
    },
  },
  'finance.loan.agricultural.medium.imported.total.exigible-capital': {
    testResolver: ({ cri }) => cri('ZZ103'), // Exigibilité emprunts MT fin
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(emprunts({ loanCategory: ['agricultural.medium-term'] }), 'endDueAmount'),
    },
  },
  'finance.loan.agricultural.long.imported.total.exigible-capital': {
    testResolver: ({ cri }) => cri('ZZ107'), // Exigibilité emprunts LT fin
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(emprunts({ loanCategory: ['agricultural.long-term'] }), 'endDueAmount'),
    },
  },

  'finance.loan.agricultural.imported.total.projected-capital': {
    testResolver: ({ cri }) =>
      cri('ZZ115') // Remboursement réel total
        .minus(cri('ZZ110')), // Dû aux act. rbsmt capital réel
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(
          emprunts({ loanCategory: ['agricultural.medium-term', 'agricultural.long-term'] }),
          'projectedReimbursement'
        ),
    },
  },
  'finance.loan.agricultural.imported.total.capital': {
    testResolver: ({ cri }) =>
      cri('ZZ114') // Remboursement capital total
        .minus(cri('ZZ334')), // Dû aux act. remb. capital tot.
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(
          emprunts({ loanCategory: ['agricultural.medium-term', 'agricultural.long-term'] }),
          'paidReimbursement'
        ),
    },
  },
  'finance.loan.agricultural.imported.total.new-capital': {
    testResolver: ({ cri }) =>
      cri('ZZ113') // Nouveaux emprunts total
        .minus(cri('ZZ109')), // Dû aux actionnaires nouveau
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(
          emprunts({ loanCategory: ['agricultural.medium-term', 'agricultural.long-term'] }),
          'newCapital'
        ),
    },
  },
  'finance.loan.agricultural.imported.total.interest': {
    testResolver: 'ZZ165',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'GV', ligne: 172_000 }), 'montant'), // Intérêts sur M&L terme ZZ165
    },
  },
  'finance.loan.imported.interests.grants': {
    testResolver: 'ZZ151',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'GV', ligne: 170_200 }), 'montant'), // Remb. intérêts ZZ151
    },
  },

  'finance.loan.other.imported.total.projected-capital': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(0),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(10_000),
      },
    ],
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(
          emprunts({ loanCategory: ['other.short-term', 'other.medium-term', 'other.long-term'] }),
          'projectedReimbursement'
        ),
    },
  },
  'finance.loan.other.imported.total.capital': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(0),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(10_000),
      },
    ],
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(
          emprunts({ loanCategory: ['other.short-term', 'other.medium-term', 'other.long-term'] }),
          'paidReimbursement'
        ),
    },
  },
  'finance.loan.other.imported.total.new-capital': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(0),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(80_000),
      },
    ],
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(
          emprunts({ loanCategory: ['other.short-term', 'other.medium-term', 'other.long-term'] }),
          'newCapital'
        ),
    },
  },
  'finance.loan.other.imported.total.interest': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(0),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(1500),
      },
    ],
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(
          emprunts({ loanCategory: ['other.short-term', 'other.medium-term', 'other.long-term'] }),
          'paidInterests'
        ),
    },
  },
  'finance.loan.shareholders-due.imported.total.projected-capital': {
    testResolver: ({ cri }) => cri('ZZ110'), // Dû aux act. rbsmt capital réel
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(emprunts({ loanCategory: ['agricultural.shareholders-due'] }), 'projectedReimbursement'),
    },
  },
  'finance.loan.shareholders-due.imported.total.capital': {
    testResolver: ({ cri }) => cri('ZZ334'), // Dû aux act. remb. capital tot.
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(emprunts({ loanCategory: ['agricultural.shareholders-due'] }), 'paidReimbursement'),
    },
  },
  'finance.loan.shareholders-due.imported.total.new-capital': {
    testResolver: ({ cri }) => cri('ZZ109'), // Dû aux actionnaires nouveau
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(emprunts({ loanCategory: ['agricultural.shareholders-due'] }), 'newCapital'),
    },
  },
  'finance.loan.shareholders-due.imported.total.interest': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(0),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(0),
      },
    ],
    databaseResolver: {
      resolve: ({ emprunts }) =>
        reduceEmpruntsBy(emprunts({ loanCategory: ['agricultural.shareholders-due'] }), 'paidInterests'),
    },
  },
  'finance.loan.shareholders-due.imported.total.movement': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(598),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(-13_000),
      },
    ],
    databaseResolver: {
      resolve: ({ courtsTermes }) => {
        const sumAdvancesActionnairesFin = sumBy(courtsTermes({ type: 'CR', type_ligne: '10' }), 'valeur_fin') // ZZ19 C/R avances aux action. fin
        const sumAdvancesActionnairesDebut = sumBy(courtsTermes({ type: 'CR', type_ligne: '10' }), 'valeur_depart') // ZZ18 C/R avances aux action. début
        const sumProfitPerteAvancesActionnaires = sumBy(courtsTermes({ type: 'CR', type_ligne: '10' }), 'profit_perte')
        return sumAdvancesActionnairesFin.minus(sumAdvancesActionnairesDebut).minus(sumProfitPerteAvancesActionnaires)
      },
    },
  },
})
