import type React from 'react'

import { CropsResultsView } from '@via/components'

import { useCropsResultsPage } from './useCropsResultsPage.ts'

export const CropsResultsPage: React.FC = () => {
  const props = useCropsResultsPage()
  return <CropsResultsView key={props.scenario._id} {...props} />
}
