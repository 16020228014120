import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { MilkWeights } from '../milkWeights.ts'
import { dataLine, headerLine } from '../utils'

export const milkResultsSheet2024Blueprint = {
  sections: [
    {
      id: 'milk.income.section',
      titleKey: 'table.income.section',
      children: [
        {
          ...headerLine('table.income.section'),
          children: [
            {
              ...dataLine('milk.income.products.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('milk.income.products.sales', { displayAsProjection: true }),
                dataLine('milk.income.products.dividends', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('milk.income.animals.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('milk.income.animals.cull', { displayAsProjection: true }),
                dataLine('milk.income.animals.breed', { displayAsProjection: true }),
                dataLine('milk.income.animals.calf', { displayAsProjection: true }),
                dataLine('milk.income.animals.other', { displayAsProjection: true }),
              ],
            },
            dataLine('milk.income.animals.embryos', { displayAsProjection: true }),
            dataLine('milk.income.miscellaneous', { displayAsProjection: true }),
          ],
        },
        dataLine('milk.income.results.total', { variant: 'total', closingSection: true }),
        dataLine('milk.income.inventory.movement', { variant: 'basic' }),
        dataLine('milk.income.total', { variant: 'basic', className: 'font-bold' }),
      ],
    },
    {
      id: 'milk.expenses.section',
      titleKey: 'table.expenses.section',
      children: [
        {
          ...headerLine('table.expenses.section'),
          children: [
            {
              ...dataLine('milk.expenses.marketing.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('milk.expenses.marketing.products', { displayAsProjection: true }),
                dataLine('milk.expenses.marketing.animals', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('milk.expenses.livestock.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('milk.expenses.livestock.cow', { displayAsProjection: true }),
                dataLine('milk.expenses.livestock.other', { displayAsProjection: true }),
                dataLine('milk.expenses.livestock.investments', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('milk.expenses.feed.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('milk.expenses.feed.cow', { displayAsProjection: true }),
                dataLine('milk.expenses.feed.calf', { displayAsProjection: true }),
                dataLine('milk.expenses.feed.other', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('milk.expenses.fees.animals.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('milk.expenses.fees.animals.veterinary', { displayAsProjection: true }),
                dataLine('milk.expenses.fees.animals.reproduction.embryos', { displayAsProjection: true }),
                dataLine('milk.expenses.fees.animals.reproduction.insemination', { displayAsProjection: true }),
                dataLine('milk.expenses.fees.animals.a', { displayAsProjection: true }),
                dataLine('milk.expenses.fees.animals.b', { displayAsProjection: true }),
                dataLine('milk.expenses.fees.animals.c', { displayAsProjection: true }),
                dataLine('milk.expenses.fees.animals.d', { displayAsProjection: true }),
                dataLine('milk.expenses.fees.animals.e', { displayAsProjection: true }),
                dataLine('milk.expenses.fees.animals.f', { displayAsProjection: true }),
                dataLine('milk.expenses.fees.quota.rent', { displayAsProjection: true }),
                dataLine('milk.expenses.fees.other', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('milk.expenses.maintenance.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('milk.expenses.maintenance.milking', { displayAsProjection: true }),
                dataLine('milk.expenses.maintenance.equipment', { displayAsProjection: true }),
              ],
            },
          ],
        },
        dataLine('milk.expenses.total', { variant: 'total', closingSection: true }),
      ],
    },
  ],
  summary: [
    dataLine('milk.income.results.total', { variant: 'finalSubTotal' }),
    dataLine('milk.expenses.total', {
      variant: 'finalSubTotal',
      symbol: 'minus',
    }),
    dataLine('milk.results.profit', { variant: 'finalTotal', symbol: 'total' }),
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true },
    {
      id: 'reference',
      type: 'reference',
      sticky: true,
      className: 'w-24',
      withDividerRight: true,
      displayOnTableState: ['selected-reference'],
    },
    {
      id: 'references',
      type: 'all-references',
      sticky: true,
      className: 'w-24',
      withDividerRight: true,
      displayOnTableState: ['references'],
    },
    {
      id: 'divider',
      type: 'divider',
      className: 'w-1',
      displayOnTableState: ['selected-reference', 'references'],
    },
    {
      id: 'projections',
      type: 'result-projections',
      withDividerRight: true,
      className: 'w-24',
    },
  ],
  defaultState: 'selected-reference',
  expendedState: 'references',
  weights: MilkWeights,
  weightsTitle: {
    hl: 'weight.milk.yield.hl',
    cows: 'weight.milk.yield.cows',
    fat: 'weight.milk.yield.fat',
    quota: 'weight.milk.yield.quota',
  },
} as const satisfies Except<
  BlueprintTable<'selected-reference' | 'references', '', keyof typeof MilkWeights>,
  'version'
>
