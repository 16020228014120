import type React from 'react'
import { useIntl } from 'react-intl'

import { Label } from '../../atoms/Label/Label'
import { Dialog, DialogContent, DialogOverlay, DialogPortal, DialogTitle } from '../../molecules/Dialog/Dialog'
import { BudgetCreationForm, type BudgetCreationFormProps } from '../BudgetCreationForm/BudgetCreationForm'
import {
  type BudgetCreateFormProviderProps,
  BudgetCreationFormProvider,
} from '../BudgetCreationForm/BudgetCreationFormProvider'

export interface BudgetCreationDialogProps
  extends Pick<BudgetCreationFormProps, 'onConfirm' | 'onCancel' | 'users'>,
    Pick<BudgetCreateFormProviderProps, 'data'> {
  readonly open: boolean
}

export const BudgetCreationDialog: React.FC<BudgetCreationDialogProps> = ({
  data,
  onCancel,
  onConfirm,
  users,
  open,
}) => {
  const intl = useIntl()

  return (
    <BudgetCreationFormProvider data={data}>
      <Dialog open={open}>
        <DialogPortal>
          <DialogOverlay onClick={onCancel} />
          <DialogContent className="w-fit overflow-auto p-20">
            <div className="flex w-full items-start pb-8">
              <DialogTitle asChild>
                <Label className="text-2xl font-bold">
                  {intl.formatMessage({ id: 'budgetConfiguration.form.create.title' })}
                </Label>
              </DialogTitle>
            </div>
            <BudgetCreationForm onCancel={onCancel} onConfirm={onConfirm} users={users} />
          </DialogContent>
        </DialogPortal>
      </Dialog>
    </BudgetCreationFormProvider>
  )
}
