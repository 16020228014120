import { z } from 'zod'

import {
  CalculationDataKeys,
  InventoryDataKeyAttributes,
  InventoryTotalDataKeyAttributes,
  MilkProductionDataKeys,
  OverridableReferenceDataKeyAttributes,
} from './attributes/specifications'
import {
  AliasAttributesJson,
  AssetAttributesJson,
  AssetInventoryAttributesJson,
  AssetTotalAttributesJson,
  BlueprintAliasAttributesJson,
  CompositeAttributesJson,
  GrbfAttributesJson,
  InvestmentAttributesJson,
  InvestmentInventoryAttributesJson,
  InvestmentTotalAttributesJson,
  MovementOverridableAttributesJson,
  MovementTotalAttributesJson,
  OverridableAttributesJson,
  ReferenceAttributesJson,
  ShortTermFinanceAttributesJson,
  TotalAttributesJson,
} from './generated'
import { keysToTuple } from './keys-to-tuple'

export const DataKeyValues = [
  ...keysToTuple(BlueprintAliasAttributesJson),
  ...keysToTuple(GrbfAttributesJson),
  ...keysToTuple(TotalAttributesJson),
  ...keysToTuple(AliasAttributesJson),
  ...keysToTuple(ReferenceAttributesJson),
  ...keysToTuple(OverridableReferenceDataKeyAttributes),
  ...keysToTuple(CompositeAttributesJson),
  ...keysToTuple(OverridableAttributesJson),
  ...keysToTuple(AssetAttributesJson),
  ...keysToTuple(AssetInventoryAttributesJson),
  ...keysToTuple(AssetTotalAttributesJson),
  ...keysToTuple(InvestmentAttributesJson),
  ...keysToTuple(InvestmentInventoryAttributesJson),
  ...keysToTuple(InvestmentTotalAttributesJson),
  ...keysToTuple(ShortTermFinanceAttributesJson),
  ...keysToTuple(MovementOverridableAttributesJson),
  ...keysToTuple(MovementTotalAttributesJson),
  ...keysToTuple(InventoryDataKeyAttributes),
  ...keysToTuple(InventoryTotalDataKeyAttributes),
  ...CalculationDataKeys,
  ...MilkProductionDataKeys,

  'empty',
  'result.profit',
  'result.expenses.finance.total',
  'result.company.expenses.fixed.salaries.total',

  // Repayment table
  'repayment.margin.income.raw',
  'repayment.margin.expenses.operational',
  'repayment.margin.total',
  'repayment.charges.salaries',
  'repayment.charges.withdrawals.total',
  'repayment.charges.withdrawals.loan',
  'repayment.charges.withdrawals.other',
  'repayment.charges.tax',
  'repayment.charges.tax.total',
  'repayment.charges.tax.milk.dividends',
  'repayment.charges.total',
  'repayment.capacity.maximum',
  'repayment.debt.payments.capital',
  'repayment.debt.payments.interest',
  'repayment.debt.total',
  'repayment.residual.balance.without.inventory',
  'repayment.residual.balance.basis',
  'repayment.inflows.balance.basis',
  'repayment.inflows.loans.agricultural.total',
  'repayment.inflows.loans.agricultural.new-capital',
  'repayment.inflows.loans.agricultural.early-repayment',
  'repayment.inflows.short.margin.increase',
  'repayment.inflows.receivables.decrease',
  'repayment.inflows.contributions',
  'repayment.inflows.inventory.decrease',
  'repayment.inflows.shares.sales',
  'repayment.inflows.total',
  'repayment.outflows.investment.total',
  'repayment.outflows.investment.machinery',
  'repayment.outflows.investment.buildings',
  'repayment.outflows.investment.land',
  'repayment.outflows.investment.quota',
  'repayment.outflows.investment.assets.farming',
  'repayment.outflows.investment.subsidies',
  'repayment.outflows.investment.programs',
  'repayment.outflows.investment.assets.other',
  'repayment.outflows.short.margin.decrease',
  'repayment.outflows.receivables.increase',
  'repayment.outflows.inventory.increase',
  'repayment.outflows.shares.buys',
  'repayment.outflows.total',
  'repayment.current.accounts.balance',
  'repayment.balance.sheet.validation',
  'repayment.balance.sheet.difference',

  // Balance assets
  'balance.assets.short',
  'balance.assets.short.liquidities',
  'balance.assets.short.programs',
  'balance.assets.short.receivables',
  'balance.assets.short.inventory',
  'balance.assets.short.inventory.forage.amount',
  'balance.assets.short.inventory.cereals.amount',
  'balance.assets.short.inventory.slaughter.amount',
  'balance.assets.short.inventory.supplies.amount',
  'balance.assets.short.inventory.miscellaneous.amount',
  'balance.assets.long',
  'balance.assets.long.breeders.amount',
  'balance.assets.long.machinery.amount',
  'balance.assets.long.buildings.amount',
  'balance.assets.long.land.amount',
  'balance.assets.long.quotas.amount',
  'balance.assets.long.nonfarming.amount',
  'balance.assets.total',

  /// Balance liabilities
  'balance.liabilities.short',
  'balance.liabilities.short.banking',
  'balance.liabilities.short.credit',
  'balance.liabilities.short.payables',
  'balance.liabilities.short.loans',
  'balance.liabilities.loans',
  'balance.liabilities.loans.medium',
  'balance.liabilities.loans.long',
  'balance.liabilities.total',

  /// Balance total
  'balance.net.total',
  'balance.validation',
  'balance.validation.profit',
  'balance.validation.inflation',
  'balance.validation.withdrawals',
  'balance.validation.contributions',
  'balance.validation.other-movements',
  'balance.owners.assets.total',
  'balance.exclusions.residence.amount',
  'balance.exclusions.nonfarming.assets.amount',
  'balance.exclusions.buildings.loss.amount',

  /// Investment
  'finance.investment.plan',

  'finance.assets.long.amortization.amount',

  /// Loans
  'finance.loan.agricultural.medium.imported',
  'finance.loan.agricultural.medium.imported.total',
  'finance.loan.agricultural.medium.imported.total.remaining-capital',
  'finance.loan.agricultural.medium.imported.total.exigible-capital',

  'finance.loan.agricultural.medium.new',
  'finance.loan.agricultural.medium.new.total',
  'finance.loan.agricultural.medium.total',
  'finance.loan.agricultural.medium.total.remaining-capital',
  'finance.loan.agricultural.medium.total.exigible-capital',

  'finance.loan.agricultural.long.imported',
  'finance.loan.agricultural.long.imported.total',
  'finance.loan.agricultural.long.imported.total.remaining-capital',
  'finance.loan.agricultural.long.imported.total.exigible-capital',

  'finance.loan.agricultural.long.new',
  'finance.loan.agricultural.long.new.total',
  'finance.loan.agricultural.long.total',
  'finance.loan.agricultural.long.total.remaining-capital',
  'finance.loan.agricultural.long.total.exigible-capital',

  'finance.loan.investment.financing',

  'finance.loan.agricultural.new',
  'finance.loan.agricultural.new.total',
  'finance.loan.agricultural.imported.total',
  'finance.loan.agricultural.imported.total.projected-capital',
  'finance.loan.agricultural.imported.total.capital',
  'finance.loan.agricultural.imported.total.new-capital',
  'finance.loan.agricultural.imported.total.interest',

  'finance.loan.agricultural.total',
  'finance.loan.agricultural.total.new-capital',
  'finance.loan.agricultural.total.early-repayment',
  'finance.loan.agricultural.total.projected-capital',
  'finance.loan.agricultural.total.interest',
  'finance.loan.agricultural.interest.paid',

  'finance.loan.other',
  'finance.loan.other.total',
  'finance.loan.other.total.annuity',
  'finance.loan.other.total.new-capital',

  'finance.loan.shareholders-due',
  'finance.loan.shareholders-due.total',
  'finance.loan.shareholders-due.total.projected-capital',
  'finance.loan.shareholders-due.total.liquidity-movement',

  'finance.loan.total',
  'finance.loan.total.new-capital',
  'finance.loan.total.annuity',
  'finance.loan.total.early-repayment',
  'finance.loan.total.exigible-capital',
  'finance.loan.total.liquidity-movement',
  'finance.loan.total.interest',

  'finance.loan.interests.grants',
  'finance.loan.outflows.withdrawals',
  'finance.loan.inflows.contributions',

  'finance.net-margin.total',
] as const

export const DataKeyZod = z.enum(DataKeyValues)

export type DataKey = (typeof DataKeyValues)[number]
