import { forwardRef, type Ref } from 'react'

import { type z } from 'zod'

import { FormSubmitProvider, type FormSubmitProviderProps } from './FormSubmitProvider'
import { ZodForm, type ZodFormProps } from './ZodForm'

export interface ZodFormProviderProps<Z extends z.ZodTypeAny>
  extends ZodFormProps<Z>,
    FormSubmitProviderProps<z.infer<Z>> {}

export const ZodFormSubmitProvider = forwardRef(
  <Z extends z.ZodTypeAny>(
    { schema, defaultValues, values, disabled, children, ...props }: ZodFormProviderProps<Z>,
    ref: Ref<HTMLFormElement>
  ) => (
    <ZodForm<Z> schema={schema} defaultValues={defaultValues} values={values} disabled={disabled}>
      <FormSubmitProvider {...props} ref={ref}>
        {children}
      </FormSubmitProvider>
    </ZodForm>
  )
)
