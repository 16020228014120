import { ScenarioMilkQuotaStateZod } from '@via/schema'
import { z } from 'zod'

import { FormFiniteNumberValueZod } from '../../atoms/Form/FormFiniteNumberValueZod.ts'

export const MilkQuotaFormZod = z.object({
  quota: ScenarioMilkQuotaStateZod.optional().default({}),
  quotaPrice: FormFiniteNumberValueZod,
})

export type MilkQuotaFormData = z.infer<typeof MilkQuotaFormZod>
