import { z } from 'zod'

import {
  BudgetReferenceConfigZod,
  BudgetReferenceDataZod,
  CurrentProductionsZod,
  ImportedAssetZod,
  LoanImportedDataZod,
} from '../../monetary'

export const BudgetReferenceZod = z.object({
  data: BudgetReferenceDataZod.optional().default({}),
  loans: z.record(LoanImportedDataZod).optional().default({}),
  assets: z.record(ImportedAssetZod).optional().default({}),
  currentProductions: CurrentProductionsZod.optional().default({}),
  config: BudgetReferenceConfigZod.optional().default({}),
  version: z.number().int().optional().default(0),
})
