import { type FC, useCallback } from 'react'

import { DndContext, type DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { omit, sortBy } from 'lodash-es'
import { nanoid } from 'nanoid'
import { type Except } from 'type-fest'

import { Button } from '../../../atoms/Button/Button'
import { type ReportFormData } from '../ReportFormZod.ts'

import { SortableBundledEditor, type SortableBundledEditorProps } from './SortableBundledEditor'

export type SortableBundledEditorsProps = Except<
  SortableBundledEditorProps,
  'id' | 'onChange' | 'value' | 'onItemDelete' | 'onItemMoveUp' | 'onItemMoveDown' | 'isFirst' | 'isLast'
> & {
  readonly items: ReportFormData['contents']
  readonly showAddItemButton?: boolean
  readonly onChange: (content: ReportFormData['contents']) => void
}

export const SortableBundledEditors: FC<SortableBundledEditorsProps> = ({
  items,
  onChange,
  showAddItemButton = true,
  ...props
}) => {
  const itemsWithId = Object.entries(items).map(([id, content]) => ({ id, ...content }))
  const sortedItems = sortBy(itemsWithId, 'order')

  const onItemChange = (id: string) => (content: string) => {
    onChange({ ...items, [id]: { ...items[id], content } })
  }

  const onRemoveItem = (id: string) => () => {
    onChange(omit(items, id))
  }

  const onItemMoveUp = (id: string) => () => {
    const currentItem = items[id]
    const previousItem = sortedItems.find((item) => item.order === currentItem.order - 1)
    if (previousItem) {
      const reorderedItems = {
        ...items,
        [id]: { ...currentItem, order: previousItem.order },
        [previousItem.id]: { ...previousItem, order: currentItem.order },
      }
      onChange(reorderedItems)
    }
  }

  const onItemMoveDown = (id: string) => () => {
    const currentItem = items[id]
    const nextItem = sortedItems.find((item) => item.order === currentItem.order + 1)
    if (nextItem) {
      const reorderedItems = {
        ...items,
        [id]: { ...currentItem, order: nextItem.order },
        [nextItem.id]: { ...nextItem, order: currentItem.order },
      }
      onChange(reorderedItems)
    }
  }

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (over && active.id !== over.id) {
        const activeCurrentOrder = items[active.id].order
        const overCurrentOrder = items[over.id].order

        const updatedOrder = arrayMove(sortedItems, activeCurrentOrder, overCurrentOrder)
        const reorderedItems = Object.fromEntries(
          updatedOrder.map(({ id, ...item }, index) => [id, { ...item, order: index }])
        )
        onChange(reorderedItems)
      }
    },
    [items, onChange, sortedItems]
  )

  const onAddItem = useCallback(() => {
    const id = nanoid()
    const order = sortedItems.length

    onChange({ ...items, [id]: { order, content: '' } })
  }, [items, onChange, sortedItems.length])

  return (
    <div>
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext items={sortedItems} strategy={verticalListSortingStrategy}>
          <div className="flex flex-col gap-2">
            {sortedItems.map((item, index) => (
              <SortableBundledEditor
                {...props}
                isFirst={index === 0}
                isLast={index === sortedItems.length - 1}
                key={`${item.id}-sortable-context-container`}
                id={item.id}
                onChange={onItemChange(item.id)}
                onItemDelete={onRemoveItem(item.id)}
                value={item.content}
                onItemMoveUp={onItemMoveUp(item.id)}
                onItemMoveDown={onItemMoveDown(item.id)}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      {showAddItemButton && (
        <div className="mt-2 w-fit rounded bg-blue-100 p-3">
          <Button onClick={onAddItem}>Aa</Button>
        </div>
      )}
    </div>
  )
}
