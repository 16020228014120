import type React from 'react'

import { ZodForm } from '../../atoms/Form/ZodForm'

import { type BudgetCreationFormData, BudgetCreationFormZod } from './BudgetCreationFormZod.ts'

export type BudgetCreateFormProviderProps = React.PropsWithChildren<{
  readonly data: Partial<BudgetCreationFormData>
}>

export const BudgetCreationFormProvider: React.FC<BudgetCreateFormProviderProps> = ({ data, children }) => {
  const defaultData = {
    year: new Date().getFullYear(),
    name: '',
    contractId: '',
    ...data,
  }

  return (
    <ZodForm schema={BudgetCreationFormZod} defaultValues={defaultData}>
      {children}
    </ZodForm>
  )
}
