import { createFileRoute } from '@tanstack/react-router'

import { ScenarioResultsPage } from '../pages/scenarioResults/ScenarioResultsPage.tsx'
import { setScenarioCurrentPage } from '../pages/scenarioUpdate/utils/scenarioPage.ts'

export const Route = createFileRoute('/budgets/$budgetId/scenarios/$scenarioId/results')({
  component: ScenarioResultsPage,
  loader: ({ params }) => {
    setScenarioCurrentPage(params.budgetId, params.scenarioId, 'results')
  },
})
