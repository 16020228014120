import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { VisuallyHidden } from '@radix-ui/react-visually-hidden'

import { Dialog, DialogContent, DialogOverlay, DialogPortal, DialogTitle } from '../../molecules/Dialog/Dialog'
import { type BudgetData } from '../../types'
import { BudgetUpdateFormProvider } from '../BudgetUpdateForm'
import { BudgetUpdateForm, type BudgetUpdateFormProps } from '../BudgetUpdateForm/BudgetUpdateForm'

export interface BudgetUpdateDialogProps extends Pick<BudgetUpdateFormProps, 'onCancel' | 'onValueChange' | 'users'> {
  readonly budget: BudgetData
  readonly open: boolean
}

export const BudgetUpdateDialog: FC<BudgetUpdateDialogProps> = ({ budget, onCancel, onValueChange, users, open }) => {
  const intl = useIntl()

  return (
    <BudgetUpdateFormProvider data={budget}>
      <Dialog open={open}>
        <DialogPortal>
          <DialogOverlay onClick={onCancel} />
          <DialogContent className="w-fit overflow-auto p-20">
            <VisuallyHidden>
              <DialogTitle>{intl.formatMessage({ id: 'budgetConfiguration.form.title' })}</DialogTitle>
            </VisuallyHidden>
            <BudgetUpdateForm
              readonly={budget.readonly}
              onCancel={onCancel}
              onValueChange={onValueChange}
              users={users}
            />
          </DialogContent>
        </DialogPortal>
      </Dialog>
    </BudgetUpdateFormProvider>
  )
}
