import { type FC, useContext } from 'react'

import { type YearKey } from '@via/schema'
import { type Except } from 'type-fest'

import { MilkOperationsContext } from '../../context'
import { MilkIncomeForm } from '../MilkIncomeForm/MilkIncomeForm.tsx'

import { type MonetaryReadonlyValueCellProps } from './MonetaryReadonlyValueCell'
import { SheetEditableValueCell } from './SheetEditableValueCell'

export interface MilkIncomeValueCellProps extends Except<MonetaryReadonlyValueCellProps, 'children'> {
  readonly year: YearKey
}

export const MilkIncomeValueCell: FC<MilkIncomeValueCellProps> = ({ year, cellData, ...props }) => {
  const { onUpdateIncome } = useContext(MilkOperationsContext)
  return (
    <SheetEditableValueCell cellData={cellData} {...props}>
      {({ onClose }) => (
        <MilkIncomeForm
          year={year}
          onSubmit={async (values) => {
            await onUpdateIncome(values)
            onClose()
          }}
          onApply={onUpdateIncome}
        />
      )}
    </SheetEditableValueCell>
  )
}
