import { createContext, type FC, type ReactNode, useCallback, useMemo, useState } from 'react'

import { Toast } from '../../organisms/Toast/Toast'

interface ToastState {
  title: string | ReactNode
  description: string
  open: boolean
}
type ShowToast = (title: string | ReactNode, description: string) => void

export const ToastContext = createContext<{
  showToast: ShowToast
} | null>(null)

export type ToastContextProps = React.PropsWithChildren

export const ToastProvider: FC<ToastContextProps> = ({ children }) => {
  const [toastState, setToastState] = useState<ToastState>({
    open: false,
    title: '',
    description: '',
  })

  const setOpen = useCallback(
    (open: boolean) => {
      setToastState({ ...toastState, open })
    },
    [toastState]
  )
  const showToast = useCallback(
    (title: string | ReactNode, description: string) => {
      if (toastState.open) {
        setOpen(false)

        setTimeout(() => {
          setToastState({ open: true, title, description })
        }, 400)
      } else {
        setToastState({ open: true, title, description })
      }
    },
    [setOpen, toastState.open]
  )

  const providerValue = useMemo(() => ({ showToast }), [showToast])

  return (
    <ToastContext.Provider value={providerValue}>
      <Toast {...toastState} setOpen={setOpen} />
      {children}
    </ToastContext.Provider>
  )
}
