import { type RxDocument, type RxQuery } from 'rxdb'
import { firstValueFrom, NEVER, of, switchMap, throwError, timeout } from 'rxjs'

import { rxDocumentData } from '../utils/rxDocumentData.ts'

export const waitForRxDocument = async <T>(
  query: RxQuery<T, RxDocument<T> | null>,
  errorMessage = 'Timeout waiting for RxDocument',
  timeoutInMs = 20_000
) =>
  firstValueFrom(
    query.$.pipe(
      switchMap((doc) => (!doc ? NEVER : of(rxDocumentData(doc)))),
      timeout({ first: timeoutInMs, with: () => throwError(() => new Error(errorMessage)) })
    )
  )
