import { type FC, forwardRef, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { FormSubmitButtons } from '../../atoms/Form/FormSubmitButtons'
import { Textarea } from '../../atoms/Textarea/Textarea'
import { cn } from '../../lib/utils'
import { AdjustmentInput } from '../../molecules/AdjustmentInput/AdjustmentInput'
import { FormatedNumberReadonlyInputWithLabel } from '../../molecules/FormatedNumberReadonlyInputWithLabel/FormatedNumberReadonlyInputWithLabel'
import { applyMonetaryCellFormOverride, type MonetaryCellFormData } from '../MonetaryCellFormZod.ts'
import {
  MonetaryWidgetFormSubmitProvider,
  type MonetaryWidgetFormSubmitProviderProps,
} from '../MonetaryWidgetFormSubmitProvider'

export type MovementOverridableProjectionWidgetProps = MonetaryWidgetFormSubmitProviderProps

const MovementOverridableBalanceFormValue: FC<Pick<MonetaryWidgetFormSubmitProviderProps, 'value'>> = ({ value }) => {
  const intl = useIntl()

  const override = useWatch<MonetaryCellFormData, 'override'>({ name: 'override' })
  const balance = useMemo(
    () =>
      value?.startingBalance !== undefined
        ? applyMonetaryCellFormOverride(override, value.startingBalance)
        : value?.balance,
    [override, value?.balance, value?.startingBalance]
  )

  return (
    <FormatedNumberReadonlyInputWithLabel
      variant="calculate"
      displayType="currency"
      label={intl.formatMessage({ id: 'finance.shortTermWidget.balanceEnd' })}
      dimension="xs"
      align="right"
      value={balance}
    />
  )
}

export const MovementOverridableProjectionWidget = forwardRef<
  HTMLFormElement,
  MovementOverridableProjectionWidgetProps
>(({ className, value, ...props }, ref) => {
  const intl = useIntl()

  return (
    <MonetaryWidgetFormSubmitProvider
      value={value}
      className={cn(
        'flex w-36 flex-col items-start gap-2.5 border-2 border-white bg-orange-200 p-2.5 shadow-md',
        className
      )}
      ref={ref}
      {...props}>
      <div className="gap- flex flex-col items-start gap-3">
        <FormatedNumberReadonlyInputWithLabel
          variant="calculate"
          displayType="currency"
          label={intl.formatMessage({ id: 'finance.shortTermWidget.balanceStart' })}
          dimension="xs"
          align="right"
          value={value?.startingBalance}
        />

        <FormField<MonetaryCellFormData, 'override'>
          name="override"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <AdjustmentInput
                  label={intl.formatMessage({ id: 'finance.shortTermWidget.movement' })}
                  dimension="xs"
                  allowedOperators={['+', '-']}
                  autoFocus
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <MovementOverridableBalanceFormValue value={value} />

        <FormField<MonetaryCellFormData, 'note'>
          name="note"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <Textarea
                  placeholder={intl.formatMessage({ id: 'finance.shortTermWidget.notes.placeholder' })}
                  rows={5}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <FormSubmitButtons className="flex w-full items-start justify-center gap-2" />
    </MonetaryWidgetFormSubmitProvider>
  )
})
