import { type FC, type PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'

import { type Except } from 'type-fest'

import {
  ScenarioSnapshotMainHeader,
  type ScenarioSnapshotMainHeaderProps,
} from '../ScenarioMainHeader/ScenarioReadonlyMainHeader'
import { ScenarioNavigation, type ScenarioNavigationProps } from '../ScenarioNavigation/ScenarioNavigation'

export interface ScenarioSnapshotMainLayoutProps
  extends Except<ScenarioSnapshotMainHeaderProps, 'snapshotTitle'>,
    ScenarioNavigationProps {
  readonly snapshotName?: string
}

export const ScenarioSnapshotMainLayout: FC<PropsWithChildren<ScenarioSnapshotMainLayoutProps>> = ({
  budget,
  scenario,
  loading,
  snapshotName,
  onNavigationChange,
  children,
  ...navigationProps
}) => {
  const intl = useIntl()
  return (
    <>
      <ScenarioSnapshotMainHeader
        budget={budget}
        scenario={scenario}
        loading={loading}
        snapshotTitle={snapshotName ? intl.formatMessage({ id: 'reports.file.title' }, { fileName: snapshotName }) : ''}
        onNavigationChange={onNavigationChange}
      />
      <div className="flex">
        <ScenarioNavigation scenario={scenario} budget={budget} withConfiguration={false} {...navigationProps} />
        {children}
      </div>
    </>
  )
}
