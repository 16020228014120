import { type ScenarioState } from '@via/compute'

import { balanceSheet2024Blueprint } from './2024/balanceSheet2024Blueprint.ts'

export const balanceSheetBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...balanceSheet2024Blueprint }
  }
}
