import { forwardRef, type HtmlHTMLAttributes, useCallback } from 'react'

import { useControllableState } from '@radix-ui/react-use-controllable-state'

import { Icons } from '../../atoms'
import { HeaderTitle } from '../../atoms/HeaderTitle/HeaderTitle.tsx'
import { cn } from '../../lib/utils.ts'

export interface CaretHeaderTitleProps extends HtmlHTMLAttributes<HTMLDivElement> {
  readonly label: string
  readonly open?: boolean
  readonly onCollapse: (open: boolean) => void
}

export const CaretHeaderTitle = forwardRef<HTMLDivElement, CaretHeaderTitleProps>(
  ({ className, label, open, onCollapse, ...props }, ref) => {
    const [isOpen, setOpen] = useControllableState({ prop: open, defaultProp: true, onChange: onCollapse })
    const toggle = useCallback(() => {
      setOpen((v) => !v)
    }, [setOpen])

    return (
      <div className={cn('flex w-84 shrink-0', className)} ref={ref} {...props}>
        <button type="button" className={cn('flex w-full shrink-0 items-start gap-1 pb-3 pl-2')} onClick={toggle}>
          <div className="shrink-0 pt-[3px]">
            {isOpen ? <Icons.CaretDownFill className="size-3" /> : <Icons.CaretRightFill className="size-3" />}
          </div>
          <HeaderTitle label={label} />
        </button>
      </div>
    )
  }
)
