import { type InvestmentComputationType, type ProductionType } from '../../../enums'
import { type DataKey } from '../../data-key-zod'
import {
  InvestmentAttributesJson,
  InvestmentInventoryAttributesJson,
  InvestmentTotalAttributesJson,
} from '../../generated'
import { keysToTuple } from '../../keys-to-tuple'
import { type DataAttribute } from '../types'

import { type AssetDataKey } from './asset-attributes'
import { type InventoryDataKey } from './inventory-attributes'
import { type ReferenceDataKey } from './reference-attributes'

export interface InvestmentTotalDataKeyAttribute {
  readonly type: 'finance-investment-total'
  readonly readonly: true
  readonly production?: ProductionType
  readonly dependencies: InvestmentDataKey[]
  readonly referenceDependencies?: Partial<Record<InvestmentComputationType, ReadonlyArray<ReferenceDataKey>>>
  readonly total?: boolean
  readonly reference?: 'selected' | 'latest' | 'none'
}

export interface InvestmentDataKeyAttribute {
  readonly type: 'finance-investment'
  readonly readonly: false
  readonly multipleValues: true
  readonly assetKey?: AssetDataKey
  readonly dependencies?: ['milk-quota'] | ['milk-income'] | []
  readonly blueprintVersions?: ['simple-2024', 'complete-2024']
}

export interface InvestmentInventoryDataKeyAttribute<Deps extends InventoryDataKey = InventoryDataKey> {
  readonly type: 'finance-investment-inventory'
  readonly readonly: true
  readonly multipleValues: true
  readonly production: ProductionType
  readonly dependencies: [Deps]
  readonly inventory: Deps
  readonly blueprintVersions?: ['complete-2024']
}

export const InvestmentDataKeyAttributes = {
  ...InvestmentAttributesJson,
  'finance.investment.quota.purchase': {
    ...InvestmentAttributesJson['finance.investment.quota.purchase'],
    blueprintVersions: ['simple-2024', 'complete-2024'],
    dependencies: ['milk-quota'],
  },
  'finance.investment.quota.sale': {
    ...InvestmentAttributesJson['finance.investment.quota.sale'],
    blueprintVersions: ['simple-2024', 'complete-2024'],
    dependencies: ['milk-quota'],
  },
  'finance.investment.other.agricultural.milk.dividends': {
    ...InvestmentAttributesJson['finance.investment.other.agricultural.milk.dividends'],
    blueprintVersions: ['simple-2024', 'complete-2024'],
    dependencies: ['milk-income'],
  },
} as Record<keyof typeof InvestmentAttributesJson, InvestmentDataKeyAttribute>

export const InvestmentTotalDataKeyAttributes = InvestmentTotalAttributesJson as Record<
  keyof typeof InvestmentTotalAttributesJson,
  InvestmentTotalDataKeyAttribute
>

export const InvestmentAttributes = {
  ...InvestmentDataKeyAttributes,
  ...InvestmentTotalDataKeyAttributes,
  'finance.investment.plan': {
    type: 'aggregate',
    sortKey: 'investment.createdAt',
    sortDirection: 'asc',
    dependencies: [...keysToTuple(InvestmentAttributesJson), ...keysToTuple(InvestmentInventoryAttributesJson)],
    readonly: false,
    multipleValues: true,
  },
} as const satisfies Partial<Record<DataKey, DataAttribute>>

export const InvestmentInventoryDataKeyAttributes = InvestmentInventoryAttributesJson as Record<
  keyof typeof InvestmentInventoryAttributesJson,
  InvestmentInventoryDataKeyAttribute
>

export type InvestmentDataKey = keyof typeof InvestmentAttributesJson
export type InvestmentTotalDataKey = keyof typeof InvestmentTotalAttributesJson
