import React from 'react'

import { Link } from '@tanstack/react-router'

import Brand from '../../assets/images/Brand.png'
import BrandSmall from '../../assets/images/BrandSmall.png'
import { cn } from '../../lib/utils'

export interface HeaderProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const HEADER_HEIGHT_PX = 48

const BLANK_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII='

export const Header = React.forwardRef<HTMLDivElement, HeaderProps>(({ className, children, ...props }, ref) => (
  <div className={cn('flex h-12 w-full items-center bg-white', className)} ref={ref} {...props}>
    <Link to="/" className="flex shrink-0 select-none flex-col items-center justify-center py-0 sm:px-4">
      <picture>
        <source srcSet={Brand} media="(min-width: 1024px)" />
        <source srcSet={BrandSmall} media="(min-width: 640px)" />
        <img src={BLANK_IMAGE} alt="Brand" className="h-5" />
      </picture>
    </Link>
    {children}
  </div>
))
