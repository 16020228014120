import { createContext, useCallback, useMemo, useState } from 'react'

import { type BudgetData, type ClientData } from '../../types'
import { type BudgetConfigurationData } from '../BudgetConfigurationForm'

import { BudgetCreationBillabelWarningDialog } from './BudgetCreationBillableWarningDialog'
import { BudgetCreationDialog } from './BudgetCreationDialog'

export const BudgetCreateDialogContext = createContext<{
  startCreationProcess: (year: string, budgets: BudgetData[]) => void
} | null>(null)

export type BudgetCreateDialogProviderProps = React.PropsWithChildren<{
  readonly onConfirm: (form: BudgetConfigurationData) => Promise<void>
  readonly client: ClientData
  readonly userId: string
}>

export const BudgetCreateDialogProvider: React.FC<BudgetCreateDialogProviderProps> = ({
  children,
  onConfirm,
  client,
  userId,
}) => {
  const [creationDialogOpen, setCreationDialogOpen] = useState(false)
  const [billableWarningDialogOpen, setBillableWarningDialogOpen] = useState(false)

  const [year, setYear] = useState(new Date().getFullYear())
  const [billable, setBillable] = useState(false)

  const data = useMemo(() => {
    const { role } = client.users[userId]
    return {
      ...(role === 'agronomist'
        ? { users: { agronomist: { ...client.users[userId], id: userId } } }
        : role === 'technician'
          ? { users: { technician: { ...client.users[userId], id: userId } } }
          : {}),
      year,
      billable,
    }
  }, [client, userId, billable, year])

  const onCreationCancel = useCallback(() => {
    setCreationDialogOpen(false)
  }, [])

  const onBillableWarningCancel = useCallback(() => {
    setBillableWarningDialogOpen(false)
  }, [])

  const onBillableWarningConfirm = useCallback(() => {
    setBillableWarningDialogOpen(false)
    setCreationDialogOpen(true)
  }, [])

  const providerValue = useMemo(
    () => ({
      startCreationProcess: (newYear: string, budgets: BudgetData[]) => {
        const newBudgetYear = Number(newYear)
        setYear(newBudgetYear)

        const isNewBudgetBillable = !budgets.some((budget) => budget.year === newBudgetYear)
        setBillable(isNewBudgetBillable)

        if (isNewBudgetBillable) {
          setBillableWarningDialogOpen(true)
        } else {
          setCreationDialogOpen(true)
        }
      },
    }),
    []
  )

  return (
    <BudgetCreateDialogContext.Provider value={providerValue}>
      <BudgetCreationDialog
        key={`${year}-${billable}`}
        open={creationDialogOpen}
        onCancel={onCreationCancel}
        onConfirm={onConfirm}
        users={client.users}
        data={data}
      />
      <BudgetCreationBillabelWarningDialog
        open={billableWarningDialogOpen}
        onCancel={onBillableWarningCancel}
        onConfirm={onBillableWarningConfirm}
        year={year}
      />
      {children}
    </BudgetCreateDialogContext.Provider>
  )
}
