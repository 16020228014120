import { type PaymentFrequency } from '@via/schema'
import { BigNumber } from 'bignumber.js'
import { min } from 'lodash-es'
import { type Except } from 'type-fest'

import { type BigNumberOverridableCellData } from '../../bignumber'

import { estimatePaymentAmount } from './estimate-payment-amount'
import { type LoanBigNumberImportedData } from './loan-big-number-imported-data'
import { type LoanBigNumberStateData } from './loan-big-number-state-data'
import { PaymentFrequencyToAnnualEventCount } from './payment-frequency-to-annual-event-count'

const structureNumberOfPayments = (
  specifiedDurationInMonths: number | undefined,
  paymentFrequency: PaymentFrequency
) => {
  if (specifiedDurationInMonths === undefined) {
    return specifiedDurationInMonths
  }
  switch (paymentFrequency) {
    case 'monthly':
      return { months: specifiedDurationInMonths, periods: specifiedDurationInMonths }
    default:
      return {
        months: specifiedDurationInMonths,
        periods: new BigNumber(specifiedDurationInMonths)
          .dividedBy(12)
          .multipliedBy(PaymentFrequencyToAnnualEventCount[paymentFrequency])
          .integerValue(BigNumber.ROUND_CEIL)
          .toNumber(),
      }
  }
}

export type LoanStateData = Except<
  LoanBigNumberStateData,
  'amount' | 'paymentFrequency' | 'interestRate' | 'paymentAmount' | 'duration'
> & {
  amount: BigNumber
  amountCellData: BigNumberOverridableCellData
  paymentFrequency: PaymentFrequency
  interestRate?: BigNumber | null
  paymentAmount?: BigNumber | null
  estimatedPaymentAmount?: BigNumber | null
  duration?: {
    months: number
    periods: number
  }
}

export const mergeLoanState = (
  scenarioStateData: LoanBigNumberStateData,
  importedData: LoanBigNumberImportedData | null,
  estimatedInterestRate?: BigNumber | null
): LoanStateData => {
  const paymentFrequency = scenarioStateData.paymentFrequency ?? importedData?.paymentPlan.frequency ?? 'monthly'
  const duration = structureNumberOfPayments(scenarioStateData.duration ?? importedData?.duration, paymentFrequency)
  const interestRate = scenarioStateData.interestRate ?? importedData?.interestRate ?? estimatedInterestRate

  const estimatedPaymentAmount = interestRate && estimatePaymentAmount(importedData, interestRate, paymentFrequency)

  const newCapitalBorrowed = Object.values(scenarioStateData.newCapitalBorrowed)
  const startDate =
    importedData?.startDate ??
    (newCapitalBorrowed.length > 0
      ? min(Object.values(scenarioStateData.newCapitalBorrowed).map((v) => v.date))
      : scenarioStateData.startDate)
  const amount =
    newCapitalBorrowed.length > 0
      ? BigNumber.sum(
          importedData?.current?.endCapital ?? 0,
          ...Object.values(scenarioStateData.newCapitalBorrowed).map((v) => v.value)
        )
      : (scenarioStateData.amount ?? importedData?.current?.endCapital ?? new BigNumber(0))

  const amountCellData =
    newCapitalBorrowed.length > 0
      ? { value: amount }
      : {
          value: amount,
          ...(importedData?.current?.endCapital ? { baseValue: importedData.current.endCapital } : {}),
          ...(scenarioStateData?.amount ? { override: { value: scenarioStateData?.amount } } : {}),
        }

  return {
    ...scenarioStateData,
    amount,
    amountCellData,
    startDate,
    paymentAmount: scenarioStateData?.paymentAmount ?? importedData?.paymentPlan.amount ?? estimatedPaymentAmount,
    estimatedPaymentAmount,
    paymentFrequency,
    interestRate,
    duration,
  }
}
