import { type FC } from 'react'
import { useWatch } from 'react-hook-form'

import { type Except } from 'type-fest'

import { FormSubmitProvider } from '../../atoms/Form/FormSubmitProvider'
import { cn } from '../../lib/utils'
import { ReportFormProvider } from '../../organisms'
import {
  ReportConfigurationForm,
  type ReportConfigurationFormProps,
} from '../../organisms/ReportConfigurationForm/ReportConfigurationForm'
import { type ReportFormData } from '../../organisms/ReportConfigurationForm/ReportFormZod.ts'
import { ReportMainLayout, type ReportMainLayoutProps } from '../../organisms/ReportMainLayout/ReportMainLayout'
import { type ReportFileData } from '../../types'

export interface ReportConfigurationViewProps
  extends Except<ReportMainLayoutProps, 'loading' | 'currentReport' | 'reports' | 'onAddReportClick'>,
    Required<Pick<ReportMainLayoutProps, 'currentReport' | 'reports' | 'onAddReportClick'>>,
    Pick<ReportConfigurationFormProps, 'onImageUpload'>,
    Except<ReportConfigurationFormProps, 'budget'> {
  readonly currentReportFiles: ReportFileData[]

  readonly onDeleteReportClick: (fileId: string) => Promise<void>
  readonly onDownloadReportClick: (fileUrl: string) => Promise<void>
  readonly onGenerateDraftPdfClick: () => Promise<void>
  readonly onGenerateFinalPdfClick: () => Promise<void>
  readonly onRetryGeneratePdfClick: (fileId: string) => Promise<void>
  readonly onValueChange: (form: ReportFormData) => Promise<void>
}

const ReportConfigurationLayout: FC<Except<ReportConfigurationViewProps, 'onValueChange'>> = ({
  onCloseBudget,
  budget,
  currentReport,
  offline,
  reports,
  onRequestOwnership,
  onNavigationChange,
  onAddReportClick,
  onUpdateBudget,
  ...props
}) => {
  const name = useWatch<ReportFormData, 'name'>({ name: 'name', defaultValue: currentReport.name })
  const formReports = reports.map((report) => (report._id === currentReport._id ? { ...report, name } : report))

  return (
    <ReportMainLayout
      onCloseBudget={onCloseBudget}
      budget={budget}
      currentReport={currentReport}
      reports={formReports}
      onRequestOwnership={onRequestOwnership}
      onNavigationChange={onNavigationChange}
      onAddReportClick={onAddReportClick}
      onUpdateBudget={onUpdateBudget}
      loading={false}
      offline={offline}>
      <ReportConfigurationForm budget={budget} {...props} />
    </ReportMainLayout>
  )
}

export const ReportConfigurationView: FC<ReportConfigurationViewProps> = ({ onValueChange, ...props }) => (
  <ReportFormProvider data={props.currentReport}>
    <FormSubmitProvider
      autoSubmit
      onFormSubmit={onValueChange}
      className={cn('flex w-full flex-col items-start overflow-hidden bg-white')}>
      <ReportConfigurationLayout {...props} />
    </FormSubmitProvider>
  </ReportFormProvider>
)
