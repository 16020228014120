import { useMemo } from 'react'

import { type BudgetData, type ScenarioCreateData } from '@via/components'
import { YearKeyZod } from '@via/schema'
import { times } from 'lodash-es'

import { useCreateBudgetReference } from '../../api/useCreateBudgetReference.ts'
import { useCurrentUserActionTimestamp } from '../../auth/useCurrentUserActionTimestamp.ts'
import { useScenarioHandler } from '../../worker/scenario/useScenarioHandler.ts'

export const useCreateScenario = ({ budget, scenarioId }: { budget: BudgetData; scenarioId: string }) => {
  const userActionTimestamp = useCurrentUserActionTimestamp()
  const { mutateAsync: createBudgetReference } = useCreateBudgetReference()
  const handler = useScenarioHandler()

  return useMemo(
    () => ({
      createScenario: async ({ period, ...scenarioForm }: ScenarioCreateData) => {
        const creationTimestamp = userActionTimestamp()
        const emptyBudget = scenarioForm.referenceType === 'empty'
        const { id: referenceId } = !emptyBudget
          ? await createBudgetReference({ budgetId: budget._id })
          : { id: undefined }

        return handler.createScenario({
          _id: scenarioId,
          budgetId: budget._id,
          creation: creationTimestamp,
          lastModification: creationTimestamp,
          period: {
            ...period,
            projectionYears: YearKeyZod.array().parse(times(period.numberOfYears, (i) => String(budget.year + i))),
            financeProjectionYears: YearKeyZod.array().parse(
              times(Math.max(period.numberOfYears, 10), (i) => String(budget.year + i))
            ),
          },
          ...(budget.client.startOfYearOffset !== undefined
            ? { startOfYearOffset: budget.client.startOfYearOffset }
            : {}),
          ...scenarioForm,
          ...(!emptyBudget
            ? {
                referenceYears: [...budget.referenceYears],
                referenceId,
                ...(budget.referenceYears.length === 1 ? { selectedReferenceYear: budget.referenceYears[0] } : {}),
                /* *
                 * TEMP fix
                 * TODO Removed when vigritel is in sync
                 */
                referenceSyncedDate: new Date('2024-12-12T23:00:00.000-05:00'),
                /* *
                 * TEMP fix
                 * TODO Removed when vigritel is in sync
                 */
              }
            : {
                referenceYears: [],
              }),
        })
      },
    }),
    [
      budget._id,
      budget.client.startOfYearOffset,
      budget.referenceYears,
      budget.year,
      createBudgetReference,
      handler,
      scenarioId,
      userActionTimestamp,
    ]
  )
}
