import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react'

import type * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

import { FormLabel } from '../../atoms/FormLabel/FormLabel.tsx'
import { Radio } from '../../atoms/Radio/Radio'
import { cn } from '../../lib/utils'

export interface RadioWithLabelProps extends ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> {
  readonly size?: 'sm'
  readonly label: string
}

export const RadioWithLabel = forwardRef<ElementRef<typeof RadioGroupPrimitive.Item>, RadioWithLabelProps>(
  ({ className, value, label, size, ...props }, ref) => (
    <div className={cn('flex items-center gap-1.5', className)}>
      <Radio id={value} value={value} ref={ref} {...props} />
      <FormLabel htmlFor={value} className={cn('select-none', size === 'sm' ? 'text-sm' : '')}>
        {label}
      </FormLabel>
    </div>
  )
)
