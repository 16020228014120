import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { Icons } from '../../atoms'
import { Button } from '../../atoms/Button/Button'
import { type BudgetData } from '../../types'

export interface BudgetActionHeaderProps {
  readonly offline?: boolean
  readonly budget: BudgetData
  readonly onCloseBudget?: () => Promise<void> | void
  readonly onRequestOwnership?: () => void
}

export const BudgetActionHeader: FC<BudgetActionHeaderProps> = ({
  offline = false,
  onCloseBudget,
  budget,
  onRequestOwnership,
}) => {
  const intl = useIntl()

  return (
    <div className="flex h-8 items-center gap-2">
      {offline && <Icons.Offline className="text-fuschia-800" />}
      {onRequestOwnership && !offline && budget.readonly && (
        <Button onClick={onRequestOwnership} dimension="sm">
          {intl.formatMessage({ id: 'budget.requestOwnership' })}
        </Button>
      )}
      {onCloseBudget && (
        <Button variant="outline" onClick={onCloseBudget} disabled={offline} dimension="sm">
          <Icons.XLg className="h-3" />
          {intl.formatMessage({ id: 'budget.close' })}
        </Button>
      )}
    </div>
  )
}
