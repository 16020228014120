import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { Icons } from '../../atoms'
import { Label } from '../../atoms/Label/Label'
import { UserAvatar } from '../../molecules/UserAvatar/UserAvatar'
import { type BudgetData } from '../../types'

export interface BudgetReadonlyHeaderProps {
  readonly budget: BudgetData
  readonly offline?: boolean
}

export const BudgetReadonlyHeader: FC<BudgetReadonlyHeaderProps> = ({ offline, budget }) => {
  const intl = useIntl()

  if (offline || !budget.readonly) {
    return null
  }

  return (
    <div className="flex items-center gap-1 pr-4">
      <div className="flex items-center gap-0.5">
        <Icons.PersonFillLock />
        <UserAvatar
          dimension="lg"
          userRole={budget.owner?.userRole}
          userName={budget.owner?.userName ?? ''}
          className="size-6 p-1 pt-1.5"
        />
      </div>
      <Label className="text-base font-medium uppercase">{intl.formatMessage({ id: 'budget.readonly' })}</Label>
    </div>
  )
}
