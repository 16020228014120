import React from 'react'

import * as SwitchPrimitives from '@radix-ui/react-switch'
import { useControllableState } from '@radix-ui/react-use-controllable-state'

import { cn } from '../../lib/utils'

const isChecked = (props: Record<string, unknown>) =>
  props['data-state'] === 'checked' || props['data-state'] === 'open'

export const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, checked, onCheckedChange: onCheckedChangeProps, ...props }, ref) => {
  const [ck, onCheckedChange] = useControllableState({
    prop: checked,
    defaultProp: isChecked(props),
    onChange: onCheckedChangeProps,
  })
  return (
    <SwitchPrimitives.Root
      checked={ck}
      className={cn(
        'group relative inline-flex h-4 w-7 shrink-0 cursor-pointer items-center rounded border focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-300 focus-visible:ring-offset-1 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50',

        'data-[state=checked]:border-blue-700 data-[state=checked]:bg-blue-500 data-[state=checked]:hover:border-blue-800 data-[state=checked]:hover:bg-blue-700',
        'data-[state=open]:border-blue-700 data-[state=open]:bg-blue-500 data-[state=open]:hover:border-blue-800 data-[state=open]:hover:bg-blue-700',

        'data-[state=unchecked]:border-gray-300 data-[state=unchecked]:bg-gray-100 data-[state=unchecked]:hover:border-gray-400',
        'data-[state=close]:border-gray-300 data-[state=close]:bg-gray-100 data-[state=close]:hover:border-gray-400',
        className
      )}
      onCheckedChange={onCheckedChange}
      {...props}
      ref={ref}>
      <SwitchPrimitives.Thumb
        className={cn(
          'pointer-events-none box-content block h-3.5 w-3.5 rounded border bg-white ring-0 transition-transform data-[state=checked]:translate-x-[11px]',

          'data-[state=checked]:border-blue-700',
          'data-[state=open]:border-blue-700',

          'data-[state=unchecked]:-translate-x-[1px] data-[state=unchecked]:border-gray-300 data-[state=unchecked]:group-hover:border-gray-400 data-[state=unchecked]:group-hover:bg-gray-100',
          'data-[state=close]:-translate-x-[1px] data-[state=close]:border-gray-300 data-[state=close]:group-hover:border-gray-400 data-[state=close]:group-hover:bg-gray-100'
        )}
      />
    </SwitchPrimitives.Root>
  )
})
