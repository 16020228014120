import { type FieldPathByValue, type FieldValues } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { type InvestmentCategory } from '@via/schema'

import { FormControl } from '../../atoms/Form/FormControl.tsx'
import { FormField } from '../../atoms/Form/FormField.tsx'
import { FormItem } from '../../atoms/Form/FormItem.tsx'
import { RadioGroup } from '../../atoms/Radio/Radio.tsx'
import { RadioWithLabel } from '../../molecules/RadioWithLabel/RadioWithLabel.tsx'

export interface InvestmentCategoryRadioGroupFormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, InvestmentCategory>,
> {
  readonly name: TName
  readonly onChange?: (value: string) => void
}

export const InvestmentCategoryRadioGroupFormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, InvestmentCategory>,
>({
  name,
  onChange,
}: InvestmentCategoryRadioGroupFormFieldProps<TFieldValues, TName>) => {
  const intl = useIntl()

  return (
    <FormField<TFieldValues, TName>
      name={name}
      render={({ field: { value, onChange: onFieldChange, ...field } }) => (
        <FormItem>
          <FormControl>
            <RadioGroup
              onValueChange={(newValue: string) => {
                onFieldChange(newValue)
                onChange?.(newValue)
              }}
              defaultValue={value}
              {...field}
              className="flex flex-wrap gap-x-8 gap-y-4">
              <RadioWithLabel value="purchase" label={intl.formatMessage({ id: 'investment.form.purchase' })} />
              <RadioWithLabel value="sale" label={intl.formatMessage({ id: 'investment.form.sale' })} />
              <RadioWithLabel value="financing" label={intl.formatMessage({ id: 'investment.form.financing' })} />
              <RadioWithLabel value="capital" label={intl.formatMessage({ id: 'investment.form.capital' })} />
              <RadioWithLabel
                value="shares-purchase"
                label={intl.formatMessage({ id: 'investment.form.shares-purchase' })}
              />
              <RadioWithLabel value="shares-sale" label={intl.formatMessage({ id: 'investment.form.shares-sale' })} />
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  )
}
