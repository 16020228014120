import { BigNumber } from 'bignumber.js'

import { createBudgetDefinition } from '../types'
import { sumBy } from '../utils'
import { type ViagritelCourtTermeTypeLigne } from '../values'

import { foodExpenses } from './utils'

export const calf = createBudgetDefinition({
  'calf.income.imported.miscellaneous': {
    testResolver: false,
    dependencyResolver: {
      dependencies: [
        'calf.income.imported.deductions',
        'calf.income.imported.operational',
        'calf.income.imported.short',
      ],
      resolve: ({ val }) =>
        BigNumber.sum(
          val('calf.income.imported.deductions'),
          val('calf.income.imported.operational'),
          val('calf.income.imported.short')
        ),
    },
  },
  'calf.income.imported.deductions': {
    testResolver: ({ cri }) => cri('VA83').plus(cri('VA86')).plus(cri('VA88')),
    databaseResolver: {
      resolve: ({ fichesTechniques }) => {
        const ftLignes = [
          112_900, // Prélèvement de veaux par la famille         VA83
          113_200, // Prélèvement de bouvillons par la famille    VA86
          113_400, // Prélèvement d'autres animaux par la famille VA88
        ]
        return sumBy(fichesTechniques({ production: 'VA', ligne: ftLignes }), 'valeur')
      },
    },
  },
  'calf.income.imported.insurance.stabilization': {
    testResolver: ({ cri }) => cri('VA21').plus(cri('VA23')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          90_800, // Rev. stab. v-veau, VA21
          90_900, // Rev. stab. bouv.,  VA23
        ]
        return sumBy(comptesExploitation({ production: 'VA', ligne: ceLignes }), 'montant')
      },
    },
  },
  'calf.income.imported.operational': {
    testResolver: ({ cri }) => cri('VA19').plus(cri('VA24')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          90_600, // Élevage à forfait      VA19
          91_100, // Autres revenus directs VA24
        ]
        return sumBy(comptesExploitation({ production: 'VA', ligne: ceLignes }), 'montant')
      },
    },
  },
  'calf.income.imported.short': {
    testResolver: ({ cri }) => cri('VA142').plus(cri('VA144')),
    databaseResolver: {
      resolve: ({ courtsTermes }) => {
        const ctLignes: ViagritelCourtTermeTypeLigne[] = [
          // VA142+VA144
          'AN', // Prod. animale:
          '70', // ASRA-Vaches-veaux
          '80', // ASRA-Bouvillons dans ferme Vaches-veaux
        ]
        return sumBy(
          courtsTermes({ production: 'VA', type: ['CP', 'FR', 'CR', 'FP'], type_ligne: ctLignes }),
          'profit_perte'
        )
      },
    },
  },
  'calf.income.imported.sales.products': {
    testResolver: ({ cri }) => cri('VA9').plus(cri('VA11')).plus(cri('VA13')).plus(cri('VA15')).plus(cri('VA17')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          90_100, // Vte vaches réforme, VA9
          90_200, // Vte vaches reprod,  VA11
          90_300, // Vte  veaux d'emb.,  VA13
          90_400, // Vte de bouvillons,  VA15
          90_500, // Vte de taureaux,   VA17
        ]
        return sumBy(comptesExploitation({ production: 'VA', ligne: ceLignes }), 'montant')
      },
    },
  },
  'calf.income.imported.inventory.breeders': {
    testResolver: 'VA126',
    databaseResolver: {
      resolve: ({ animaux }) =>
        animaux({ production: 'VA', type: 'breeding' }).reduce(
          (total, animal) =>
            total.plus(animal.prix_tete_fin.times(animal.inventaire_fin.minus(animal.inventaire_debut))),
          new BigNumber(0)
        ),
    },
  },
  'calf.income.imported.inventory.market': {
    testResolver: ({ cri }) => cri('VA129').minus(cri('VA128')),
    databaseResolver: {
      resolve: ({ animaux }) => {
        const market = animaux({ production: 'VA', type: 'market' })
        const endValue = market.reduce(
          (total, animal) => total.plus(animal.prix_tete_fin.times(animal.inventaire_fin)),
          new BigNumber(0)
        )
        const startValue = market.reduce(
          (total, animal) => total.plus(animal.prix_tete_debut.times(animal.inventaire_debut)),
          new BigNumber(0)
        )
        return endValue.minus(startValue)
      },
    },
  },
  'calf.expenses.imported.feed.cow': {
    testResolver: ({ cri }) => cri('VA35'),
    databaseResolver: {
      resolve: ({ aliments }) => foodExpenses(aliments({ production: 'VA', colonne: 'A' })),
    },
  },
  'calf.expenses.imported.feed.calf': {
    testResolver: ({ cri }) => cri('VA36'),
    databaseResolver: {
      resolve: ({ aliments }) => foodExpenses(aliments({ production: 'VA', colonne: 'Y' })),
    },
  },
  'calf.expenses.imported.feed.finished': {
    testResolver: ({ cri }) => cri('VA37'),
    databaseResolver: {
      resolve: ({ aliments }) => foodExpenses(aliments({ production: 'VA', colonne: 'O' })),
    },
  },
  'calf.expenses.imported.marketing': {
    testResolver: ({ cri }) => cri('VA50').plus(cri('VA51')).plus(cri('VA44')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          93_110, // Mise en marché veaux  VA50
          93_120, // Mise en marché bouv.  VA51
          93_300, // Mise en marché autres VA44
        ]
        return sumBy(comptesExploitation({ production: 'VA', ligne: ceLignes }), 'montant')
      },
    },
  },
  'calf.expenses.imported.livestock.maintenance': {
    testResolver: ({ cri }) => cri('VA28').plus(cri('VA30')).plus(cri('VA32')).plus(cri('VA34')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          91_600, // Achat de vaches    VA28
          91_700, // Achat de taureaux  VA30
          91_800, // Achat jeunes veaux VA32
          91_900, // Achat veaux d'emb. VA34
        ]
        return sumBy(comptesExploitation({ production: 'VA', ligne: ceLignes }), 'montant')
      },
    },
  },
  'calf.expenses.imported.fees.veterinary': {
    testResolver: ({ cri }) => cri('VA40').plus(cri('VA42')).plus(cri('VA43')),
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceLignes = [
          92_800, // Vét. et médicaments VA40
          93_000, // Implants veaux      VA42
          93_100, // Implants bouvillons VA43
        ]
        const iaLignes = [
          80_100, // Médicaments VA40
          80_200, // Implants    VA42
        ]
        return sumBy(comptesExploitation({ production: 'VA', ligne: ceLignes }), 'montant').plus(
          sumBy(inventairesApprovisionnements({ production: 'VA', ligne: iaLignes }), 'delta_dollars')
        )
      },
    },
  },
  'calf.expenses.imported.fees.reproduction': {
    testResolver: 'VA41',
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceLignes = [
          92_900, // Insémination VA41
        ]
        const iaLignes = [
          80_000, // Produits pour insémination VA41
        ]
        return sumBy(comptesExploitation({ production: 'VA', ligne: ceLignes }), 'montant').plus(
          sumBy(inventairesApprovisionnements({ production: 'VA', ligne: iaLignes }), 'delta_dollars')
        )
      },
    },
  },
  'calf.expenses.imported.fees.insurance': {
    testResolver: ({ cri }) => cri('VA38').plus(cri('VA39')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          92_500, // Cot. stab.  v-veau VA38
          92_600, // Cot. stab. bouv.   VA39
        ]
        return sumBy(comptesExploitation({ production: 'VA', ligne: ceLignes }), 'montant')
      },
    },
  },
  'calf.expenses.imported.fees.miscellaneous': {
    testResolver: ({ cri }) => cri('VA45').plus(cri('VA46')).plus(cri('VA47')),
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceLignes = [
          93_500, // Litière autre que paille VA45
          93_600, // Frais élevage à forfait  VA46
          93_700, // Autres approv. directs   VA47
        ]
        const iaLignes = [
          80_300, // Litière autre que paille VA45
          80_400, // Autres                   VA47
        ]
        return sumBy(comptesExploitation({ production: 'VA', ligne: ceLignes }), 'montant').plus(
          sumBy(inventairesApprovisionnements({ production: 'VA', ligne: iaLignes }), 'delta_dollars')
        )
      },
    },
  },
})
