import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { Link } from '@tanstack/react-router'
import { cva } from 'class-variance-authority'

import { cn } from '../../lib/utils.ts'
import { type ScenarioNavigationId } from '../../navigation'

const scenarioNavigationLinkVariants = cva('flex rounded border px-2 py-1.5 select-none', {
  variants: {
    active: {
      true: 'bg-sage-50',
      false: '',
    },
    disabled: {
      true: 'border-sage-200',
      false: 'border-sage-400',
    },
  },
  defaultVariants: {
    active: false,
    disabled: false,
  },
})

export interface ScenarioLinkProps {
  readonly page: ScenarioNavigationId
  readonly active?: boolean
  readonly disabled?: boolean
}

export const ScenarioLink: FC<ScenarioLinkProps> = ({ active, page, disabled }) => {
  const intl = useIntl()

  return (
    <Link className={scenarioNavigationLinkVariants({ active, disabled })} to={`../${page}`} disabled={disabled}>
      <p
        className={cn(
          'text-left text-xs font-medium leading-none tracking-[-0.42px] text-gray-800',
          disabled ? 'text-gray-400' : '',
          page === 'configuration' ? 'uppercase' : ''
        )}>
        {intl.formatMessage({ id: `scenarioNav.link.${page}` })}
      </p>
    </Link>
  )
}
