import { type InventoryComputationType, InventoryComputationTypeZod } from '@via/schema'
import { BigNumber } from 'bignumber.js'
import { type z } from 'zod'

export const InventoryComputationSourceTypeZod = InventoryComputationTypeZod.exclude([
  'startingValue',
  'value',
  'unitMovement',
  'valueMovement',
  'valueMovementUnitBalance',
  'valueMovementUnitValue',
  'disbursement',
])

type InventoryComputationSourceType = z.infer<typeof InventoryComputationSourceTypeZod>

export const computeInventoryValues = ({
  startingUnitBalance = new BigNumber(0),
  startingUnitValue = new BigNumber(0),
  purchase = new BigNumber(0),
  purchaseCost = new BigNumber(0),
  sale = new BigNumber(0),
  saleCost = new BigNumber(0),
  unitBalance: importedUnitBalance,
  ...values
}: Partial<Record<InventoryComputationSourceType, BigNumber>>): Record<InventoryComputationType, BigNumber> => {
  const unitBalance = importedUnitBalance ?? startingUnitBalance.plus(purchase).minus(sale)
  const unitValue = values.unitValue ?? startingUnitValue
  const value = unitBalance.times(unitValue)
  const startingValue = startingUnitBalance.times(startingUnitValue)
  const unitMovement = unitBalance.minus(startingUnitBalance)
  return {
    ...values,
    startingUnitBalance,
    startingUnitValue,
    startingValue,
    unitBalance,
    unitValue,
    value,
    unitMovement,
    valueMovement: value.minus(startingValue),
    valueMovementUnitBalance: unitMovement.times(unitValue),
    valueMovementUnitValue: startingUnitBalance.times(unitValue.minus(startingUnitValue)),
    purchase,
    purchaseCost,
    sale,
    saleCost,
    disbursement: purchaseCost.minus(saleCost),
  }
}
