import { dataKeyDependenciesRecord, keysToTuple } from '@via/schema'
import { z } from 'zod'

import { FormFiniteNumberValueZod } from '../../atoms/Form/FormFiniteNumberValueZod.ts'

export const MilkIncomeFormZod = z.record(
  z.enum([...keysToTuple(dataKeyDependenciesRecord('milk-income')), 'dividendsRevenueProportion']),
  FormFiniteNumberValueZod
)

export type MilkIncomeFormData = z.infer<typeof MilkIncomeFormZod>
