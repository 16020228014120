import type React from 'react'

import { captureException } from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'

import { type FirebaseAuthenticatedContextValue } from '../auth/FirebaseAuthProvider.tsx'
import { AppNavigationProvider } from '../navigation/AppNavigationProvider.tsx'
import { RouterDevtools } from '../RouterDevtools.tsx'
import { type AppRxDatabase, type AppRxState } from '../rxdb/types.ts'
import { isOffline } from '../state/isOffline.ts'
import { type AppWorker } from '../worker/AppWorker.ts'
import { type BudgetHandler } from '../worker/budget/BudgetHandler.ts'
import { type BudgetReportsHandler } from '../worker/report/BudgetReportsHandler.ts'
import { type ScenarioHandler } from '../worker/scenario/ScenarioHandler.ts'

export const RootRouteComponent: React.FC = () => (
  <AppNavigationProvider>
    <Outlet />
    {import.meta.env.DEV && <RouterDevtools />}
  </AppNavigationProvider>
)

export const Route = createRootRouteWithContext<{
  readonly queryClient: QueryClient
  readonly auth: FirebaseAuthenticatedContextValue
  readonly appDatabase: AppRxDatabase
  readonly appRxState: AppRxState
  readonly appWorker: AppWorker
  readonly budgetHandler: BudgetHandler
  readonly scenarioHandler: ScenarioHandler
  readonly budgetReportsHandler: BudgetReportsHandler
}>()({
  beforeLoad: ({ context: { appRxState } }) => ({ isOffline: isOffline(appRxState) }),
  component: RootRouteComponent,
  onError: (error) => {
    captureException(error, { tags: { router: true } })
  },
})
