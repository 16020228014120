import '@via/frontend-tinymce/init'

import { ReportConfigurationView } from '@via/components'

import { useReportsPage } from './useReportsPage.ts'

export const ReportsPage = () => {
  const props = useReportsPage()
  return <ReportConfigurationView key={props.currentReport._id} {...props} />
}
