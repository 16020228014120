import { type BlueprintVersion, type ProductionType } from '../../../enums'
import { type DataKey } from '../../data-key-zod'
import { BlueprintAliasAttributesJson } from '../../generated'

import { type GrbfDataKey } from './grbf-attributes'
import { type TotalDataKeys } from './total-attributes'

export interface BlueprintAliasDataAttribute<Key extends DataKey = DataKey> {
  readonly type: 'blueprint-alias'
  readonly keys: Partial<Record<BlueprintVersion, Key>>
  readonly production?: ProductionType
}

export type BlueprintAliasDataKeys = keyof typeof BlueprintAliasAttributesJson

export const BlueprintAliasDataKeyAttributes = BlueprintAliasAttributesJson as Record<
  BlueprintAliasDataKeys,
  BlueprintAliasDataAttribute<GrbfDataKey | TotalDataKeys>
>
