import { type ScenarioState } from '@via/compute'

import { milkAnimalsSheet2024Blueprint } from './2024/milkAnimalsSheet2024Blueprint.ts'

export const milkAnimalsBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...milkAnimalsSheet2024Blueprint }
  }
}
