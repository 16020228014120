import { type DataKey } from '../../data-key-zod'
import { type DataAttribute } from '../types'

export const NetMarginTableAttributes = {
  'finance.net-margin.total': {
    type: 'total',
    readonly: true,
    dependencies: [
      'result.income.total',
      'finance.inventory.movement.total',
      'result.expenses.total',
      'finance.investment.animals.total.disbursement',
      'result.company.expenses.finance.amortization',
      'repayment.charges.withdrawals.total',
      'repayment.charges.tax',
    ],
    arithmetic: {
      'finance.inventory.movement.total': { multiplier: -1 },
      'result.expenses.total': { multiplier: -1 },
      'repayment.charges.withdrawals.total': { multiplier: -1 },
      'repayment.charges.tax': { multiplier: -1 },
    },
  },
} as const satisfies Partial<Record<DataKey, DataAttribute>>
