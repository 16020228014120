import { type FC, type PropsWithChildren, useCallback, useMemo, useState } from 'react'

import { type BudgetData, RequesterUserDialog } from '@via/components'

import { useOfflineState } from '../contexts/offlineState/useOfflineState.ts'
import { useBudgetHandler } from '../worker/budget/useBudgetHandler.ts'

import { BudgetRequestConfirmationDialogContext } from './BudgetRequestConfirmationDialogContext.ts'

export interface BudgetRequestConfirmationDialogContextProps {
  readonly budget: BudgetData
}

const BudgetRequestConfirmationDialogOnlineProvider: FC<
  PropsWithChildren<BudgetRequestConfirmationDialogContextProps>
> = ({ children, budget }) => {
  const budgetHandler = useBudgetHandler()
  const [open, setOpen] = useState<boolean>(false)

  const request = useCallback(() => {
    setOpen(true)
  }, [])
  const providerValue = useMemo(() => ({ request }), [request])

  const onCancel = useCallback(() => {
    setOpen(false)
  }, [])

  const onConfirm = useCallback(async () => {
    await budgetHandler.requestOwnership(budget._id)
    onCancel()
  }, [budget._id, onCancel, budgetHandler])

  return (
    <BudgetRequestConfirmationDialogContext.Provider value={providerValue}>
      {budget.owner && (
        <RequesterUserDialog
          open={open}
          userAvatar={{ userName: budget.owner.userName, userRole: budget.owner.userRole }}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
      {children}
    </BudgetRequestConfirmationDialogContext.Provider>
  )
}

export const BudgetRequestConfirmationDialogProvider: FC<
  PropsWithChildren<BudgetRequestConfirmationDialogContextProps>
> = ({ children, ...props }) => {
  const { offline } = useOfflineState()

  return offline ? (
    children
  ) : (
    <BudgetRequestConfirmationDialogOnlineProvider {...props}>{children}</BudgetRequestConfirmationDialogOnlineProvider>
  )
}
