import React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'
import { Icons } from '../Icons/Icons'

const addItemVariants = cva(
  'flex w-12 py-1.5 justify-center items-center rounded border border-gray-200 active:shadow-none cursor-pointer',
  {
    variants: {
      variant: {
        default: 'bg-gray-50 text-black hover:bg-gray-200 active:bg-gray-800 active:text-white',
        sage: 'bg-sage-500 text-white hover:bg-sage-700 active:bg-sage-900',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface AddItemProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof addItemVariants> {}

export const AddItem = React.forwardRef<HTMLButtonElement, AddItemProps>(
  ({ className, variant = 'default', children, ...props }, ref) => (
    <span className={cn(addItemVariants({ variant }), className)} ref={ref} {...props}>
      <Icons.PlusSmall />
    </span>
  )
)
