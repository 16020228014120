import { useCallback } from 'react'

import { type Except } from 'type-fest'

import { type NavigationHandler } from '../../navigation/types'
import {
  ClientListDataTable,
  type ClientListDataTableProps,
} from '../../organisms/ClientListDataTable/ClientListDataTable'
import { MainLayout, type MainLayoutProps } from '../../organisms/MainLayout/MainLayout'

export interface ClientListViewProps
  extends Pick<MainLayoutProps, 'version' | 'avatar'>,
    Except<ClientListDataTableProps, 'onNavigateToClient'> {
  readonly onNavigationChange: NavigationHandler
}

export const ClientListView = ({
  avatar,
  version,
  data,
  onNavigationChange,
  onFilterByChange,
}: ClientListViewProps) => {
  const onNavigateToClient = useCallback(
    (clientId: string) => onNavigationChange({ to: '/clients/$clientId', params: { clientId } }),
    [onNavigationChange]
  )

  return (
    <MainLayout currentNavigationId="clients" version={version} avatar={avatar}>
      <div className="flex w-full flex-col items-start px-10 py-8">
        <ClientListDataTable data={data} onFilterByChange={onFilterByChange} onNavigateToClient={onNavigateToClient} />
      </div>
    </MainLayout>
  )
}
