import { type ApiMutationOptions, useApi } from './mutation/useApi.ts'

export interface TransferBudgetVariables {
  readonly budgetId: string
  readonly requestId: string
}

export const useTransferBudget = (options?: ApiMutationOptions<void, TransferBudgetVariables>) =>
  useApi<void, TransferBudgetVariables>({
    type: 'transferBudget',
    ...options,
  })
