import type React from 'react'
import { type ControllerProps, type FieldPath, type FieldValues } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { type ClientUser, type UserRole } from '@via/schema'
import { pickBy, sortBy } from 'lodash-es'
import { type Except } from 'type-fest'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { Select } from '../../atoms/Select/Select'
import { SELECT_RESET_FILTER_OPTION, SELECT_RESET_VALUE } from '../../atoms/Select/SelectResetOption'
import { InputWithLabel } from '../../molecules/InputWithLabel/InputWithLabel'

interface UserSelectFromItemProps {
  readonly title: string
  readonly subtitle: string
  readonly users: Record<string, ClientUser>
  readonly withReset?: boolean
}

const prepareUsersForSelect = (users: Record<string, ClientUser>, role?: UserRole) => {
  const usersWithRole = role ? pickBy(users, { role }) : users
  const usersWithIds = Object.entries(usersWithRole).map(([userId, user]) => ({ id: userId, ...user }))
  const sortedUsers = sortBy(usersWithIds, 'name')

  return Object.fromEntries(sortedUsers.map(({ id, ...user }) => [id, { ...user }]))
}

const UserSelectFormItem = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  title,
  subtitle,
  users,
  withReset,
  ...props
}: UserSelectFromItemProps & Except<ControllerProps<TFieldValues, TName>, 'render'>) => {
  const intl = useIntl()
  const options = Object.entries(users ?? {}).map(([userId, { name }]) => ({ value: userId, text: name }))

  return (
    <div className="flex flex-col gap-[5px]">
      <p className="text-xl-bold select-none text-nowrap font-bold leading-tight tracking-[-0.4px] text-gray-800">
        {title}
      </p>
      <p className="select-none text-nowrap text-xs font-light leading-tight tracking-[-0.4px] text-gray-800">
        {subtitle}
      </p>
      <FormField<TFieldValues, TName>
        {...props}
        render={({ field }) => (
          <FormItem className="w-full">
            <FormControl>
              <Select
                useValueFromProps
                placeholder={intl.formatMessage({ id: 'budgetConfiguration.form.selectUser' })}
                options={[
                  ...(withReset
                    ? [
                        SELECT_RESET_FILTER_OPTION(
                          intl.formatMessage({ id: 'budgetConfiguration.form.selectUser.none' }),
                          intl.formatMessage({ id: 'budgetConfiguration.form.selectUser' })
                        ),
                      ]
                    : []),
                  ...options,
                ]}
                onValueChange={(value: string) => {
                  if (value === SELECT_RESET_VALUE) {
                    field.onChange(null)
                  } else {
                    field.onChange({ id: value, name: users[value].name, role: users[value].role })
                  }
                }}
                name={field.value?.name}
                disabled={field.disabled}
                value={field.value?.id}
                initialValue={field.value?.id}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}

export interface BudgetConfigurationFormProps {
  readonly users: Record<string, ClientUser>
  readonly readonly?: boolean
}

export const BudgetConfigurationForm: React.FC<BudgetConfigurationFormProps> = ({ readonly, users }) => {
  const intl = useIntl()

  return (
    <div className="flex w-full flex-row justify-center px-0.5">
      <div className="w-[460px]">
        <div className="flex w-[312px] gap-6">
          <FormField
            name="year"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <InputWithLabel
                    readOnly
                    disabled
                    type="number"
                    label={intl.formatMessage({ id: 'budgetConfiguration.form.firstYear' })}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="contractId"
            disabled={readonly}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <InputWithLabel
                    label={intl.formatMessage({ id: 'budgetConfiguration.form.contractNumber' })}
                    autoComplete="off"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          name="name"
          disabled={readonly}
          render={({ field }) => (
            <FormItem className="w-full pt-9">
              <FormControl>
                <InputWithLabel
                  label={intl.formatMessage({ id: 'budgetConfiguration.form.name' })}
                  autoComplete="off"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="w-20 min-w-4" />
      <div className="w-[460px]">
        <div className="flex w-full flex-col gap-9">
          <UserSelectFormItem
            name="users.agronomist"
            title={intl.formatMessage({ id: 'budgetConfiguration.form.agronomist.title' })}
            subtitle={intl.formatMessage({ id: 'budgetConfiguration.form.agronomist.subtitle' })}
            users={prepareUsersForSelect(users, 'agronomist')}
            disabled={readonly}
          />
          <UserSelectFormItem
            name="users.technician"
            title={intl.formatMessage({ id: 'budgetConfiguration.form.technician.title' })}
            subtitle={intl.formatMessage({ id: 'budgetConfiguration.form.technician.subtitle' })}
            users={prepareUsersForSelect(users, 'technician')}
            disabled={readonly}
            withReset
          />
          <UserSelectFormItem
            name="users.collaborator"
            title={intl.formatMessage({ id: 'budgetConfiguration.form.collaborator.title' })}
            subtitle={intl.formatMessage({ id: 'budgetConfiguration.form.collaborator.subtitle' })}
            users={prepareUsersForSelect(users)}
            disabled={readonly}
            withReset
          />
        </div>
      </div>
    </div>
  )
}
