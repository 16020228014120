import React from 'react'

import { Tooltip } from '../../atoms/Tooltip/Tooltip'
import { cn } from '../../lib/utils'
import { UserAvatar, type UserAvatarProps } from '../UserAvatar/UserAvatar'

export type { UserAvatarVariant } from '../UserAvatar/UserAvatar'

export interface UserAvatarWithTooltipProps extends UserAvatarProps {}

export const UserAvatarWithTooltip = React.forwardRef<HTMLDivElement, UserAvatarWithTooltipProps>(
  ({ className, userName, userRole, dimension, ...props }, ref) => (
    <div className={cn('cursor-default', className)} ref={ref} {...props}>
      <Tooltip variant="default" side="top" sideOffset={-6} label={userName}>
        <UserAvatar userName={userName} userRole={userRole} dimension={dimension} />
      </Tooltip>
    </div>
  )
)
