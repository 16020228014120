import { type FC, type PropsWithChildren, useMemo } from 'react'

import { type RowData } from '@via/compute'

import { ScenarioRowsContext } from './ScenarioRowsContext'

export interface ScenarioRowsProviderProps {
  readonly rows: ReadonlyArray<RowData>
}

export const ScenarioRowsProvider: FC<PropsWithChildren<ScenarioRowsProviderProps>> = ({ rows, children }) => {
  const value = useMemo(() => ({ rows, loading: false }), [rows])
  return <ScenarioRowsContext.Provider value={value}>{children}</ScenarioRowsContext.Provider>
}
