import { useContext } from 'react'

import { BudgetUpdateDialogContext } from './BudgetUpdateDialogContext.ts'

export const useBudgetUpdatePageDialog = () => {
  const context = useContext(BudgetUpdateDialogContext)
  if (!context) {
    throw new Error('useBudgetUpdatePageDialog must be used within a BudgetUpdatePageDialogProvider')
  }

  return context
}
