import { type LineTitleConfiguration, type LineTitleVariant } from './LineTitle'

const CONFIGURATIONS: Record<LineTitleVariant, LineTitleConfiguration> = {
  default: {
    formulaStrip: 'empty',
    strip: {
      variant: 'default',
      configuration: 'line',
      filled: false,
    },
    tab: 'default',
    collapsable: false,
  },
  section: {
    formulaStrip: 'empty',
    strip: {
      variant: 'default',
      configuration: 'line',
      filled: false,
    },
    collapsable: true,
  },
  subSectionProduction: {
    formulaStrip: 'empty',
    strip: {
      variant: 'default',
      configuration: 'line',
      filled: false,
    },
    collapsable: true,
  },
  subSection: {
    formulaStrip: 'empty',
    strip: {
      variant: 'default',
      configuration: 'line',
      filled: false,
    },
    collapsable: true,
  },
  subTotal: {
    formulaStrip: 'empty',
    strip: {
      variant: 'subTotal',
      configuration: 'square',
      filled: true,
    },
    collapsable: false,
  },
  total: {
    formulaStrip: 'empty',
    strip: {
      variant: 'total',
      configuration: 'square',
      filled: false,
    },
    collapsable: false,
  },
  head: {
    formulaStrip: 'head',
    strip: {
      variant: 'default',
      configuration: 'head',
      filled: true,
    },
    collapsable: false,
  },
  calculate: {
    formulaStrip: 'empty',
    strip: {
      variant: 'calculate',
      configuration: 'calculate',
      filled: true,
    },
    collapsable: false,
  },
  calculateDerived: {
    formulaStrip: 'empty',
    strip: {
      variant: 'empty',
      configuration: 'calculate',
      filled: false,
    },
    tab: 'default',
    collapsable: false,
  },
  calculateSubTotal: {
    formulaStrip: 'empty',
    strip: {
      variant: 'default',
      configuration: 'bottom',
      filled: false,
    },
    collapsable: false,
  },
  calculateTotal: {
    formulaStrip: 'calculate',
    strip: {
      variant: 'calculateTotal',
      configuration: 'total',
      filled: false,
    },
    collapsable: false,
  },
  finalSubTotal: {
    formulaStrip: 'subTotal',
    strip: {
      variant: 'subTotal',
      configuration: 'total',
      filled: false,
    },
    collapsable: false,
  },
  finalTotal: {
    formulaStrip: 'total',
    strip: {
      variant: 'finalTotal',
      configuration: 'total',
      filled: false,
    },
    collapsable: false,
  },
  validateAlert: {
    formulaStrip: 'empty',
    strip: {
      variant: 'empty',
      configuration: 'line',
      filled: true,
    },
    tab: 'alert',
    collapsable: true,
  },
  validatePass: {
    formulaStrip: 'empty',
    strip: {
      variant: 'empty',
      configuration: 'line',
      filled: true,
    },
    tab: 'pass',
    collapsable: true,
  },
  subValidatePass: {
    formulaStrip: 'empty',
    strip: {
      variant: 'empty',
      configuration: 'line',
      filled: true,
    },
    externalTab: 'empty',
    tab: 'default',
    collapsable: false,
  },
  subValidateAlert: {
    formulaStrip: 'empty',
    strip: {
      variant: 'empty',
      configuration: 'line',
      filled: true,
    },
    externalTab: 'empty',
    tab: 'default',
    collapsable: false,
  },
  group: {
    formulaStrip: 'empty',
    strip: {
      variant: 'default',
      configuration: 'line',
      filled: true,
    },
    collapsable: false,
  },
  groupCalculate: {
    formulaStrip: 'empty',
    strip: {
      variant: 'groupCalculate',
      configuration: 'line',
      filled: true,
    },
    collapsable: false,
  },
  groupReference: {
    formulaStrip: 'empty',
    strip: {
      variant: 'groupReference',
      configuration: 'line',
      filled: true,
    },
    collapsable: false,
  },
  basic: {
    formulaStrip: 'empty',
    strip: {
      variant: 'empty',
      configuration: 'line',
      filled: false,
    },
    collapsable: false,
  },
}

const CLOSING_SECTION_CONFIGURATIONS: Partial<Record<LineTitleVariant, LineTitleConfiguration>> = {
  subTotal: {
    formulaStrip: 'empty',
    strip: {
      variant: 'subTotalClosing',
      configuration: 'bottom',
      filled: false,
    },
    collapsable: false,
  },
  total: {
    formulaStrip: 'empty',
    strip: {
      variant: 'totalClosing',
      configuration: 'bottom',
      filled: false,
    },
    collapsable: false,
  },
  groupCalculate: {
    formulaStrip: 'empty',
    strip: {
      variant: 'groupCalculate',
      configuration: 'square',
      filled: true,
    },
    collapsable: false,
  },
}

export const lineTitleConfiguration = (
  variant: LineTitleVariant | undefined | null,
  closingSection: boolean | null = false
): LineTitleConfiguration => {
  if (closingSection && variant) {
    return CLOSING_SECTION_CONFIGURATIONS[variant] ?? CONFIGURATIONS[variant]
  }

  return CONFIGURATIONS[variant ?? 'default']
}
