import { useMemo } from 'react'

import { type DataKey, type YearKey } from '@via/schema'
import { type Except } from 'type-fest'

import { type OverridableMonetaryCellData } from '../../model'

import { type UseMonetaryRowDataOptions } from './types.ts'
import { useMonetaryRowData } from './useMonetaryRowData.ts'

export interface UseMonetaryRowValues<Weight extends string> extends Except<UseMonetaryRowDataOptions<Weight>, 'id'> {
  readonly key: DataKey
}

export const useMonetaryRowValues = <Weight extends string = never>({
  key,
  ...options
}: UseMonetaryRowValues<Weight>): Partial<Record<YearKey, OverridableMonetaryCellData>> | undefined => {
  const { rows, loading } = useMonetaryRowData<Weight>({ id: key, ...options })
  return useMemo(() => (rows.length === 0 || loading ? undefined : rows[0].values), [rows, loading])
}
