import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { CropsWeights } from '../cropsWeights.ts'
import { dataLine, headerLine } from '../utils'

export const cropsResultsSheet2024Blueprint = {
  sections: [
    {
      id: 'crops.income.section',
      titleKey: 'table.income.section',
      children: [
        {
          ...headerLine('table.income.section'),
          children: [
            {
              ...dataLine('crops.income.sales.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('crops.income.sales.inventory', { displayAsProjection: true }),
                dataLine('crops.income.sales.production', { displayAsProjection: true }),
                dataLine('crops.income.sales.forage', { displayAsProjection: true }),
                dataLine('crops.income.sales.straw', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('crops.income.program.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('crops.income.program.insurance.general', { displayAsProjection: true }),
                dataLine('crops.income.program.insurance.stabilization', { displayAsProjection: true }),
              ],
            },
            dataLine('crops.income.miscellaneous', { displayAsProjection: true }),
          ],
        },
        dataLine('crops.income.results.total', { variant: 'total', closingSection: true }),
        dataLine('crops.income.inventory.movement', { variant: 'basic' }),
        dataLine('crops.income.total', { variant: 'basic', className: 'font-bold' }),
      ],
    },
    {
      id: 'crops.expenses.section',
      titleKey: 'table.expenses.section',
      children: [
        {
          ...headerLine('table.expenses.section'),
          children: [
            {
              ...dataLine('crops.expenses.supplies.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('crops.expenses.supplies.seed', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.fertilizer.mineral', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.fertilizer.manure', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.fertilizer.other', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.fertilizer.organic', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.pesticides.herbicides', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.pesticides.other', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.marketing', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.rope', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.plastic', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.preservative', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.lime-only', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.green-fertilizer', { displayAsProjection: true }),
                dataLine('crops.expenses.supplies.other', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('crops.expenses.insurance.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('crops.expenses.insurance.general', { displayAsProjection: true }),
                dataLine('crops.expenses.insurance.stabilization', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('crops.expenses.custom.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('crops.expenses.custom.sowing', { displayAsProjection: true }),
                dataLine('crops.expenses.custom.watering', { displayAsProjection: true }),
                dataLine('crops.expenses.custom.silage-making', { displayAsProjection: true }),
                dataLine('crops.expenses.custom.pressing', { displayAsProjection: true }),
                dataLine('crops.expenses.custom.threshing', { displayAsProjection: true }),
                dataLine('crops.expenses.custom.drying', { displayAsProjection: true }),
              ],
            },
          ],
        },
        dataLine('crops.expenses.total', { variant: 'total', closingSection: true }),
      ],
    },
  ],
  summary: [
    dataLine('crops.income.total', { variant: 'finalSubTotal' }),
    dataLine('crops.expenses.total', {
      variant: 'finalSubTotal',
      symbol: 'minus',
    }),
    dataLine('crops.results.profit', { variant: 'finalTotal', symbol: 'total' }),
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true },
    {
      id: 'reference',
      type: 'reference',
      sticky: true,
      className: 'w-24',
      withDividerRight: true,
      displayOnTableState: ['selected-reference'],
    },
    {
      id: 'references',
      type: 'all-references',
      sticky: true,
      className: 'w-24',
      withDividerRight: true,
      displayOnTableState: ['references'],
    },
    {
      id: 'divider',
      type: 'divider',
      className: 'w-1',
      displayOnTableState: ['selected-reference', 'references'],
    },
    {
      id: 'projections',
      type: 'result-projections',
      withDividerRight: true,
      className: 'w-24',
    },
  ],
  defaultState: 'selected-reference',
  expendedState: 'references',
  weights: CropsWeights,
  weightsTitle: {
    ha: 'table.crops.production.hectare',
  },
} as const satisfies Except<
  BlueprintTable<'selected-reference' | 'references', '', keyof typeof CropsWeights>,
  'version'
>
