import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { useControllableState } from '@radix-ui/react-use-controllable-state'
import { type Except } from 'type-fest'

import { Label } from '../../atoms/Label/Label'
import { RadioGroup } from '../../atoms/Radio/Radio.tsx'
import { MonetaryTableEditingStateProvider } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import { RadioWithLabel } from '../../molecules/RadioWithLabel/RadioWithLabel.tsx'
import {
  ScenarioMonetaryLayout,
  type ScenarioMonetaryLayoutProps,
} from '../../organisms/ScenarioMonetaryLayout/ScenarioMonetaryLayout.tsx'

import { useMilkResultsBlueprint } from './useMilkResultsBlueprint.ts'

export type MilkResultsViewProps = Except<ScenarioMonetaryLayoutProps, 'currentScenarioNavigationId' | 'blueprint'>

export const MilkResultsView: FC<MilkResultsViewProps> = ({ scenario, currentWeight, onWeightChange, ...props }) => {
  const intl = useIntl()
  const blueprint = useMilkResultsBlueprint(scenario)
  const [weight, setWeight] = useControllableState({
    prop: currentWeight,
    onChange: onWeightChange,
    defaultProp: 'none',
  })

  return (
    <MonetaryTableEditingStateProvider>
      <ScenarioMonetaryLayout
        currentScenarioNavigationId="milk-results"
        scenario={scenario}
        blueprint={blueprint}
        currentWeight={weight}
        onWeightChange={setWeight}
        {...props}>
        <div className="flex">
          <div className="sticky left-36 pl-8 pt-6">
            <div className="flex items-center">
              <div className="flex flex-col pr-8">
                <Label className="text-2xl font-normal text-black">
                  {intl.formatMessage({ id: 'milk.results.title' })}
                </Label>
                <Label className="text-2xl font-bold text-black">
                  {intl.formatMessage({ id: 'milk.results.subTitle' })}
                </Label>
              </div>
              <div>
                <RadioGroup onValueChange={setWeight} value={weight} className="flex flex-wrap gap-x-8">
                  <RadioWithLabel value="none" label={intl.formatMessage({ id: 'weight.none' })} size="sm" />
                  <RadioWithLabel value="hl" label={intl.formatMessage({ id: 'weight.milk.yield.hl' })} size="sm" />
                  <RadioWithLabel value="cows" label={intl.formatMessage({ id: 'weight.milk.yield.cows' })} size="sm" />
                  <RadioWithLabel value="fat" label={intl.formatMessage({ id: 'weight.milk.yield.fat' })} size="sm" />
                  <RadioWithLabel
                    value="quota"
                    label={intl.formatMessage({ id: 'weight.milk.yield.quota' })}
                    size="sm"
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
      </ScenarioMonetaryLayout>
    </MonetaryTableEditingStateProvider>
  )
}
