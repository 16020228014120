import { forwardRef, type HtmlHTMLAttributes } from 'react'

import { useControllableState } from '@radix-ui/react-use-controllable-state'

import { MonetaryTabTrigger } from '../../atoms/MonetaryTabTrigger/MonetaryTabTrigger'
import { cn } from '../../lib/utils'

export interface ControllableTabListItem {
  label: string
  value: string
}

export interface ControllableTabListProps extends HtmlHTMLAttributes<HTMLDivElement> {
  readonly tabs: ReadonlyArray<ControllableTabListItem>
  readonly activeValue: string

  readonly onTabChange?: (value: string) => void
}

export const ControllableTabList = forwardRef<HTMLDivElement, ControllableTabListProps>(
  ({ className, tabs, activeValue, onTabChange, ...props }, ref) => {
    const [active, setActive] = useControllableState<string>({ prop: activeValue, onChange: onTabChange })
    return (
      <div className={cn('inline-flex items-center justify-center gap-1 pl-1', className)} ref={ref} {...props}>
        {tabs.map((tab) => (
          <MonetaryTabTrigger
            key={tab.value}
            active={active === tab.value}
            onClick={() => {
              setActive(tab.value)
            }}>
            {tab.label}
          </MonetaryTabTrigger>
        ))}
      </div>
    )
  }
)
