import {
  type AnimalProductionType,
  type CommercialProductionsType,
  type ParentProductionsType,
  type ProductionType,
  type VegetalProductionsType,
} from './production-type-zod'

type ProductionCategory = 'all' | 'sub-all' | 'other' | 'item'

export const AnimalProductionConfig = {
  'milk-veal': { parent: 'animal', type: 'item', sortIndex: 5 },
  goat: { parent: 'animal', type: 'item', sortIndex: 5 },
  horse: { parent: 'animal', type: 'item', sortIndex: 5 },
  'heavy-turkey': { parent: 'animal', type: 'item', sortIndex: 5 },
  rabbit: { parent: 'animal', type: 'item', sortIndex: 5 },
  weaner: { parent: 'animal', type: 'item', sortIndex: 5 },
  'table-egg': { parent: 'animal', type: 'item', sortIndex: 5 },
  ostrich: { parent: 'animal', type: 'item', sortIndex: 5 },
  aquaculture: { parent: 'animal', type: 'item', sortIndex: 5 },
  exotic: { parent: 'animal', type: 'item', sortIndex: 5 },
  'replacement-heifer': { parent: 'animal', type: 'item', sortIndex: 5 },
  'finished-beef': { parent: 'animal', type: 'item', sortIndex: 5 },
  bison: { parent: 'animal', type: 'item', sortIndex: 5 },
  wapiti: { parent: 'animal', type: 'item', sortIndex: 5 },
  'roast-turkey': { parent: 'animal', type: 'item', sortIndex: 5 },
  pork: { parent: 'animal', type: 'item', sortIndex: 5 },
  sheep: { parent: 'animal', type: 'item', sortIndex: 5 },
  'grain-veal': { parent: 'animal', type: 'item', sortIndex: 5 },
  'incubation-egg': { parent: 'animal', type: 'item', sortIndex: 5 },
  'roast-poultry': { parent: 'animal', type: 'item', sortIndex: 5 },
  stag: { parent: 'animal', type: 'item', sortIndex: 5 },
} as const satisfies Record<
  AnimalProductionType,
  {
    parent: 'animal'
    type: ProductionCategory
    sortIndex: 5
  }
>

export const VegetalProductionsConfig = {
  asparagus: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  all: { parent: 'vegetal', type: 'all', sortIndex: 6 },
  tobacco: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  thyme: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  peat: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  blueberry: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  brocoli: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  beet: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  zucchini: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'greenhouse-cucumber': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  camomille: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  mushroom: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  celery: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  pickle: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  kale: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  spinach: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  fruit: { parent: 'vegetal', type: 'sub-all', sortIndex: 6 },
  raspberry: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  strawberry: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'cannery-bean': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'cannery-corn': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'table-potato': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'cannery-pea': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  nursery: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  parsnip: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  leek: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  garlic: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'field-tomato': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'greenhouse-tomato': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  other: { parent: 'vegetal', type: 'other', sortIndex: 6 },
  'table-carrot': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  pumpkin: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  cauliflower: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  cabbage: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  cantaloup: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  ornamental: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'winter-cabbage': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'brussel-sprout': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  shallots: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'fava-bean': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'fresh-bean': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  vegetable: { parent: 'vegetal', type: 'sub-all', sortIndex: 6 },
  lettuce: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  watermelon: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'sweet-corn': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  turnip: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  onion: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  'seed-potato': { parent: 'vegetal', type: 'item', sortIndex: 6 },
  medicinal: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  apple: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  pepper: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  radish: { parent: 'vegetal', type: 'item', sortIndex: 6 },
  fir: { parent: 'vegetal', type: 'item', sortIndex: 6 },
} as const satisfies Record<
  VegetalProductionsType,
  {
    parent: 'vegetal'
    type: ProductionCategory
    sortIndex: 6
  }
>

export const CommercialProductionsConfig = {
  cheese: { parent: 'commercial-group', type: 'item', sortIndex: 7 },
  quarry: { parent: 'commercial-group', type: 'item', sortIndex: 7 },
  bakery: { parent: 'commercial-group', type: 'item', sortIndex: 7 },
  honey: { parent: 'commercial-group', type: 'item', sortIndex: 7 },
  wood: { parent: 'commercial-group', type: 'item', sortIndex: 7 },
  commercial: { parent: 'commercial-group', type: 'other', sortIndex: 7 },
  'agro-tourism': { parent: 'commercial-group', type: 'item', sortIndex: 7 },
  'snow-clearing': { parent: 'commercial-group', type: 'item', sortIndex: 7 },
  butchery: { parent: 'commercial-group', type: 'item', sortIndex: 7 },
  garden: { parent: 'commercial-group', type: 'item', sortIndex: 7 },
  nursery: { parent: 'commercial-group', type: 'item', sortIndex: 7 },
  greenhouse: { parent: 'commercial-group', type: 'item', sortIndex: 7 },
} as const satisfies Record<
  CommercialProductionsType,
  {
    parent: 'commercial-group'
    type: ProductionCategory
    sortIndex: 7
  }
>

export const ProductionsConfig = {
  milk: { parent: 'milk', type: 'item', sortIndex: 1 } as const,
  crops: { parent: 'crops', type: 'item', sortIndex: 2 } as const,
  calf: { parent: 'calf', type: 'item', sortIndex: 3 } as const,
  maple: { parent: 'maple', type: 'item', sortIndex: 4 } as const,

  ...AnimalProductionConfig,
  ...VegetalProductionsConfig,
  ...CommercialProductionsConfig,
} as const satisfies Record<
  ProductionType,
  {
    parent: ParentProductionsType
    type: ProductionCategory
    sortIndex: number
  }
>

export const SortedProductions = [
  { parent: 'milk', production: 'milk', type: 'item' },
  { parent: 'crops', production: 'crops', type: 'item' },
  { parent: 'calf', production: 'calf', type: 'item' },
  { parent: 'maple', production: 'maple', type: 'item' },
  // Animal
  { parent: 'animal', production: 'bison', type: 'item' },
  { parent: 'animal', production: 'finished-beef', type: 'item' },
  { parent: 'animal', production: 'stag', type: 'item' },
  { parent: 'animal', production: 'horse', type: 'item' },
  { parent: 'animal', production: 'goat', type: 'item' },
  { parent: 'animal', production: 'roast-turkey', type: 'item' },
  { parent: 'animal', production: 'heavy-turkey', type: 'item' },
  { parent: 'animal', production: 'exotic', type: 'item' },
  { parent: 'animal', production: 'rabbit', type: 'item' },
  { parent: 'animal', production: 'table-egg', type: 'item' },
  { parent: 'animal', production: 'incubation-egg', type: 'item' },
  { parent: 'animal', production: 'pork', type: 'item' },
  { parent: 'animal', production: 'aquaculture', type: 'item' },
  { parent: 'animal', production: 'weaner', type: 'item' },
  { parent: 'animal', production: 'roast-poultry', type: 'item' },
  { parent: 'animal', production: 'sheep', type: 'item' },
  { parent: 'animal', production: 'ostrich', type: 'item' },
  { parent: 'animal', production: 'replacement-heifer', type: 'item' },
  { parent: 'animal', production: 'grain-veal', type: 'item' },
  { parent: 'animal', production: 'milk-veal', type: 'item' },
  { parent: 'animal', production: 'wapiti', type: 'item' },
  // Vegetal
  // TODO: Sort by name
  { parent: 'vegetal', production: 'all', type: 'all' },
  { parent: 'vegetal', production: 'fruit', type: 'sub-all' },
  { parent: 'vegetal', production: 'vegetable', type: 'sub-all' },
  { parent: 'vegetal', production: 'other', type: 'other' },
  { parent: 'vegetal', production: 'asparagus', type: 'item' },
  { parent: 'vegetal', production: 'tobacco', type: 'item' },
  { parent: 'vegetal', production: 'thyme', type: 'item' },
  { parent: 'vegetal', production: 'peat', type: 'item' },
  { parent: 'vegetal', production: 'blueberry', type: 'item' },
  { parent: 'vegetal', production: 'brocoli', type: 'item' },
  { parent: 'vegetal', production: 'beet', type: 'item' },
  { parent: 'vegetal', production: 'zucchini', type: 'item' },
  { parent: 'vegetal', production: 'greenhouse-cucumber', type: 'item' },
  { parent: 'vegetal', production: 'camomille', type: 'item' },
  { parent: 'vegetal', production: 'mushroom', type: 'item' },
  { parent: 'vegetal', production: 'celery', type: 'item' },
  { parent: 'vegetal', production: 'pickle', type: 'item' },
  { parent: 'vegetal', production: 'kale', type: 'item' },
  { parent: 'vegetal', production: 'spinach', type: 'item' },
  { parent: 'vegetal', production: 'raspberry', type: 'item' },
  { parent: 'vegetal', production: 'strawberry', type: 'item' },
  { parent: 'vegetal', production: 'cannery-bean', type: 'item' },
  { parent: 'vegetal', production: 'cannery-corn', type: 'item' },
  { parent: 'vegetal', production: 'table-potato', type: 'item' },
  { parent: 'vegetal', production: 'cannery-pea', type: 'item' },
  { parent: 'vegetal', production: 'nursery', type: 'item' },
  { parent: 'vegetal', production: 'parsnip', type: 'item' },
  { parent: 'vegetal', production: 'leek', type: 'item' },
  { parent: 'vegetal', production: 'garlic', type: 'item' },
  { parent: 'vegetal', production: 'field-tomato', type: 'item' },
  { parent: 'vegetal', production: 'greenhouse-tomato', type: 'item' },
  { parent: 'vegetal', production: 'table-carrot', type: 'item' },
  { parent: 'vegetal', production: 'pumpkin', type: 'item' },
  { parent: 'vegetal', production: 'cauliflower', type: 'item' },
  { parent: 'vegetal', production: 'cabbage', type: 'item' },
  { parent: 'vegetal', production: 'cantaloup', type: 'item' },
  { parent: 'vegetal', production: 'ornamental', type: 'item' },
  { parent: 'vegetal', production: 'winter-cabbage', type: 'item' },
  { parent: 'vegetal', production: 'brussel-sprout', type: 'item' },
  { parent: 'vegetal', production: 'shallots', type: 'item' },
  { parent: 'vegetal', production: 'fava-bean', type: 'item' },
  { parent: 'vegetal', production: 'fresh-bean', type: 'item' },
  { parent: 'vegetal', production: 'lettuce', type: 'item' },
  { parent: 'vegetal', production: 'watermelon', type: 'item' },
  { parent: 'vegetal', production: 'sweet-corn', type: 'item' },
  { parent: 'vegetal', production: 'turnip', type: 'item' },
  { parent: 'vegetal', production: 'onion', type: 'item' },
  { parent: 'vegetal', production: 'seed-potato', type: 'item' },
  { parent: 'vegetal', production: 'medicinal', type: 'item' },
  { parent: 'vegetal', production: 'apple', type: 'item' },
  { parent: 'vegetal', production: 'pepper', type: 'item' },
  { parent: 'vegetal', production: 'radish', type: 'item' },
  { parent: 'vegetal', production: 'fir', type: 'item' },
  // Commercial
  // TODO: Sort by name
  { parent: 'commercial-group', production: 'commercial', type: 'other' },
  { parent: 'commercial-group', production: 'cheese', type: 'item' },
  { parent: 'commercial-group', production: 'quarry', type: 'item' },
  { parent: 'commercial-group', production: 'bakery', type: 'item' },
  { parent: 'commercial-group', production: 'honey', type: 'item' },
  { parent: 'commercial-group', production: 'wood', type: 'item' },
  { parent: 'commercial-group', production: 'agro-tourism', type: 'item' },
  { parent: 'commercial-group', production: 'snow-clearing', type: 'item' },
  { parent: 'commercial-group', production: 'butchery', type: 'item' },
  { parent: 'commercial-group', production: 'garden', type: 'item' },
  { parent: 'commercial-group', production: 'nursery', type: 'item' },
  { parent: 'commercial-group', production: 'greenhouse', type: 'item' },
] as const satisfies Array<{
  parent: ParentProductionsType
  production: ProductionType
  type: ProductionCategory
}>
