import { type FC, Suspense } from 'react'

import { Await, createFileRoute, defer } from '@tanstack/react-router'
import { ScenarioLoadingView } from '@via/components'
import { type CurrentProductions } from '@via/compute'

import { invokeApi } from '../api/invoke/invokeApi.ts'
import { CurrentBudgetProductionsProvider } from '../contexts/currentBudgetProductions/CurrentBudgetProductionsProvider.tsx'
import { useAppNavigation } from '../navigation/useAppNavigation.ts'
import { ScenarioCreatePage } from '../pages/scenarioCreate/ScenarioCreatePage.tsx'

export const ScenarioCreatePageComponent: FC = () => {
  const { currentProductions, clientCurrentProductions } = Route.useLoaderData()

  const { budget } = Route.useRouteContext()
  const { onNavigationChange } = useAppNavigation()

  if (currentProductions) {
    return (
      <Suspense fallback={<ScenarioLoadingView budget={budget} onNavigationChange={onNavigationChange} />}>
        <Await promise={currentProductions}>
          {(productions) => (
            <CurrentBudgetProductionsProvider initialCurrentProductions={productions} budget={budget}>
              <ScenarioCreatePage />
            </CurrentBudgetProductionsProvider>
          )}
        </Await>
      </Suspense>
    )
  }

  return (
    <CurrentBudgetProductionsProvider initialCurrentProductions={clientCurrentProductions} budget={budget}>
      <ScenarioCreatePage />
    </CurrentBudgetProductionsProvider>
  )
}

export const Route = createFileRoute('/budgets/$budgetId/scenarios/create')({
  component: ScenarioCreatePageComponent,
  loader: ({ context: { isOffline, budget } }) => {
    if (!isOffline) {
      const currentProductionsPromise = invokeApi<CurrentProductions, { budgetId: string }>(
        'budgetCurrentProductions',
        {
          budgetId: budget._id,
        }
      )

      return {
        clientCurrentProductions: budget.client.currentProductions,
        currentProductions: defer(currentProductionsPromise),
      }
    }

    return { clientCurrentProductions: budget.client.currentProductions, currentProductions: null }
  },
})
