import type React from 'react'

import { type BudgetData } from '../../types'

import { ReportConfigurationFormAnnex, type ReportConfigurationFormAnnexProps } from './ReportConfigurationFormAnnex'
import {
  ReportConfigurationFormContent,
  type ReportConfigurationFormContentProps,
} from './ReportConfigurationFormContent'
import { ReportConfigurationFormPreamble } from './ReportConfigurationFormPreamble'
import { ReportConfigurationFormSections } from './ReportConfigurationFormSections'
import { ReportConfigurationFormType, type ReportConfigurationFormTypeProps } from './ReportConfigurationFormType'

export interface ReportConfigurationFormProps
  extends ReportConfigurationFormAnnexProps,
    Pick<ReportConfigurationFormContentProps, 'onImageUpload'>,
    Pick<ReportConfigurationFormTypeProps, 'coverImages' | 'groupImages'> {
  readonly budget: BudgetData
}

export const ReportConfigurationForm: React.FC<ReportConfigurationFormProps> = ({
  budget,
  coverImages,
  groupImages,
  scenarios,
  selectableYears,
  onImageUpload,
}) => (
  <div className="pb-4 pl-44">
    <ReportConfigurationFormType budget={budget} coverImages={coverImages} groupImages={groupImages} />
    <ReportConfigurationFormPreamble onImageUpload={onImageUpload} />
    <ReportConfigurationFormContent onImageUpload={onImageUpload} />
    <ReportConfigurationFormAnnex scenarios={scenarios} selectableYears={selectableYears} />
    <ReportConfigurationFormSections />
  </div>
)
