import React from 'react'

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

import { cn } from '../../lib/utils'
import { DropdownMenuContent } from '../DropdownMenuContent/DropdownMenuContent'
import { Icons } from '../Icons/Icons'
import { type SelectOption } from '../Select/Select'

export const SettingDropdownMenuTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <DropdownMenuPrimitive.Trigger
    ref={ref}
    className={cn(
      'flex cursor-pointer select-none items-center rounded p-1.5 outline-none hover:bg-gray-200 data-[state=open]:bg-gray-400',
      className
    )}
    {...props}>
    <Icons.ThreeDotsVertical className="size-3 text-black" />
  </DropdownMenuPrimitive.Trigger>
))

export interface SettingDropdownMenuProps<T extends string = string>
  extends React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Root> {
  readonly options: Array<SelectOption<T>> | ReadonlyArray<SelectOption<T>>
  onItemSelect(value: T): void
}

export const SettingDropdownMenu = <T extends string = string>({
  options,
  onItemSelect,
  ...props
}: SettingDropdownMenuProps<T>) => (
  <DropdownMenuPrimitive.Root {...props}>
    <SettingDropdownMenuTrigger />
    <DropdownMenuContent options={options} onItemSelect={onItemSelect} />
  </DropdownMenuPrimitive.Root>
)
