import type React from 'react'
import { useEffect, useState } from 'react'

import { addBreadcrumb } from '@sentry/react'
import { handleNotAwaitedPromise } from '@via/frontend-sentry'
import { type RxDatabase } from 'rxdb'

import { AppRxLeadershipContext } from './AppRxLeadershipContext.ts'

type AppRxLeadershipProviderProps<Internals, InstanceCreationOptions> = React.PropsWithChildren<{
  readonly database: RxDatabase<Internals, InstanceCreationOptions>
}>

export const AppRxLeadershipProvider = <Internals, InstanceCreationOptions>({
  database,
  children,
}: AppRxLeadershipProviderProps<Internals, InstanceCreationOptions>) => {
  const [isLeader, setIsLeader] = useState(false)

  useEffect(() => {
    handleNotAwaitedPromise(
      database.waitForLeadership().then(() => {
        setIsLeader(true)
        addBreadcrumb({
          category: 'rxdb',
          message: `${database.name} is elected leader`,
          data: { database: database.name },
        })
        return true
      })
    )
  }, [database])

  return <AppRxLeadershipContext.Provider value={isLeader}>{children}</AppRxLeadershipContext.Provider>
}
