import { z } from 'zod'

import { ClientInformationZod, RxDBDocumentZod, UserUpdateZod } from '../common'

export const ClientZod = RxDBDocumentZod.merge(ClientInformationZod).extend({
  members: z.array(z.string()).optional().default([]),

  lastUpdate: UserUpdateZod.optional(),
})

export type Client = z.infer<typeof ClientZod>
