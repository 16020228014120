import { useContext } from 'react'

import { type UseMonetaryRowDataOptions } from '../../context/monetary/types.ts'
import { useMonetaryRowData } from '../../context/monetary/useMonetaryRowData.ts'

import {
  MonetaryTableBlueprintContext,
  MonetaryTableCurrentBlueprintTabContext,
  MonetaryTableCurrentWeightContext,
} from './contexts/MonetaryTableStateProvider.tsx'

type UseMonetaryTableDataOptions<Weight extends string> = UseMonetaryRowDataOptions<Weight>

export const useMonetaryTableData = <Weight extends string = string>({
  computation,
  weight,
  ...options
}: UseMonetaryTableDataOptions<Weight>) => {
  const blueprint = useContext(MonetaryTableBlueprintContext)
  const currentTab = useContext(MonetaryTableCurrentBlueprintTabContext)
  const currentWeight = useContext(MonetaryTableCurrentWeightContext) as Weight | undefined
  const weightValue = weight ?? currentWeight
  return useMonetaryRowData<Weight>({
    computation: computation ?? currentTab,
    weight: weightValue && blueprint.weights?.[weightValue],
    ...options,
  })
}
