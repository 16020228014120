import { z } from 'zod'

import { AnimalProductions, type ProductionType } from './production-type-zod'

export const BaseAssetTypes = [
  'machinery', // MA
  'building', // BA
  'land', // FTE
  'quota', // QUO
  'animals',
  'other', // AA
] as const

export const AssetTypes = [
  ...BaseAssetTypes,
  'acquisition-cost', // CA
] as const

export const AssetTypeZod = z.enum(AssetTypes)
export type AssetType = z.infer<typeof AssetTypeZod>

export const BuildingAssetSubTypes = [
  'building.machinery', // 00
  'building.grain-silo', // 05
  'building.forage-silo', // 10
  'building.manures', // 15
  'building.horticultural', // 25
  'building.gardening', // 26
  'building.decorative-wood', // 27
  'building.decorative-greenhouse', // 28
  'building.incinerative', // 30
  'building.composting', // 32
  'building.moulded-manufacture', // 34
  'building.exotic-animals', // AX
  'building.manure-exotic-animals', // AXF
  'building.finished-beef', // BI
  'building.manure-finished-beef', // BIF
  'building.bison', // BS
  'building.manure-bison', // BSF
  'building.goat', // CR
  'building.manure-goat', // CRF
  'building.horse', // CV
  'building.manure-horse', // CVF
  'building.maple', // DA
  'building.roast-turkey', // DG
  'building.manuer-roast-turkey', // DGF
  'building.heavy-turkey', // DL
  'building.manure-heavy-turkey', // DLF
  'building.pork', // FI
  'building.manure-pork', // FIF
  'building.sheep', // IN
  'building.manure-sheep', // INF
  'building.wood-lot', // IS
  'building.grain-fed-veal', // JV
  'building.manure-grain-fed-veal', // JVF
  'building.milk', // LA
  'building.manure-milk', // LAF
  'building.rabbit', // LP
  'building.manure-rabbit', // LPF
  'building.weaners', // NA
  'building.manure-weaners', // NAF
  'building.table-eggs', // OC
  'building.manure-table-eggs', // OCF
  'building.incubator-eggs', // OI
  'building.manure-incubation-eggs', // OIF
  'building.ostrish', // OT
  'building.manure-ostrish', // OTF
  'building.roast-chicken', // PG
  'building.manure-roast-chicken', // PGF
  'building.aquaculture', // PS
  'building.manure-aquaculture', // PSF
  'building.cerf', // ST
  'building.manure-cerf', // STF
  'building.other-commercial', // T1
  'building.cheese', // T2
  'building.agri-tourism', // T3
  'building.sand-pit', // T4
  'building.snow', // T5
  'building.bakery', // T6
  'building.butchery', // T7
  'building.honey', // T8
  'building.heifers', // TA
  'building.manure-heigers', // TAF
  'building.cow-calf', // VA
  'building.manure-cow-calf', // VAF
  'building.milk-fed-veal', // VL
  'building.manure-milk-fead-veal', // VLF
  'building.wapiti', // WA
  'building.manure-wapiti', // WAF
  'building.other', // XX
] as const

export const MachineryAssetSubTypes = [
  'machinery.tractor', // 05
  'machinery.combine', // 10
  'machinery.truck', // 20
  'machinery.grain-dryer', // 30
  'machinery.other', // 40
  'machinery.manure', // 50
  'machinery.grain-forage', // 60
  'machinery.horticulture', // 62
  'machinery.gardening', // 64
  'machinery.decorative-wood', // 66
  'machinery.decorative-greenhouse', // 68
  'machinery.incineration', // 70
  'machinery.composting', // 72
  'machinery.moulded-manufacture', // 74
  'machinery.manure-trait', // 76
  'machinery.exotics-animal', // AX
  'machinery.finished-beef', // BI
  'machinery.bison', // BS
  'machinery.goat', // CR
  'machinery.horse', // CV
  'machinery.maple', // DA
  'machinery.roast-turkey', // DG
  'machinery.heavy-turkey', // DL
  'machinery.pork', // FI
  'machinery.sheep', // IN
  'machinery.wood-lot', // IS
  'machinery.grain-fed-veal', // JV
  'machinery.milk', // LA
  'machinery.rabbits', // LP
  'machinery.weaners', // NA
  'machinery.table-eggs', // OC
  'machinery.incubation-eggs', // OI
  'machinery.ostrich', // OT
  'machinery.roast-poultry', // PG
  'machinery.aquaculture', // PS
  'machinery.stag', // ST
  'machinery.other-commercial-activities', // T1
  'machinery.cheese', // T2
  'machinery.agri-tourism', // T3
  'machinery.sand-pit', // T4
  'machinery.snow', // T5
  'machinery.bakery', // T6
  'machinery.butchery', // T7
  'machinery.honey', // T8
  'machinery.heifers', // TA
  'machinery.cow-calf', // VA
  'machinery.milk-fed-veal', // VL
  'machinery.wapiti', // WA
  'machinery.allocation-100', // XW
  'machinery.allocation-20', // XX
] as const

export const LandAssetSubTypes = [
  'land.crops-01', // TAA
  'land.crops-02', // TAB
  'land.crops-03', // TAC
  'land.crops-04', // TAD
  'land.crops-05', // TAE
  'land.crops-06', // TBA
  'land.crops-07', // TBB
  'land.crops-08', // TBC
  'land.crops-09', // TBD
  'land.crops-10', // TBE
  'land.crops-11', // TCA
  'land.crops-12', // TCB
  'land.crops-13', // TCC
  'land.crops-14', // TCD
  'land.crops-15', // TCE
  'land.crops-16', // TDA
  'land.crops-17', // TDB
  'land.crops-18', // TDC
  'land.crops-19', // TDD
  'land.crops-20', // TDE
  'land.crops-21', // TFA
  'land.crops-22', // TFB
  'land.crops-23', // TFC
  'land.crops-24', // TFD
  'land.crops-25', // TFE
  'land.crops-26', // TGA
  'land.crops-27', // TGB
  'land.crops-28', // TGC
  'land.crops-29', // TGD
  'land.crops-30', // TGE
  'land.drainage-01', // TEA
  'land.drainage-02', // TEB
  'land.drainage-03', // TEC
  'land.drainage-04', // TED
  'land.drainage-05', // TEE
  'land.wood-lot-01', // TJA
  'land.wood-lot-02', // TJB
  'land.wood-lot-03', // TJC
  'land.wood-lot-04', // TJD
  'land.wood-lot-05', // TJE
  'land.maple-01', // TMA
  'land.maple-02', // TMB
  'land.maple-03', // TMC
  'land.maple-04', // TMD
  'land.maple-05', // TME
  'land.non-agricultural-pasture-01', // TRA
  'land.non-agricultural-pasture-02', // TRB
  'land.non-agricultural-pasture-03', // TRC
  'land.non-agricultural-pasture-04', // TRD
  'land.non-agricultural-pasture-05', // TRE
] as const

export const QuotaAssetSubTypes = [
  'quotas.roast-turkey', // DG
  'quotas.heavy-turkey', // DL
  'quotas.milk', // LA
  'quotas.laying-eggs', // OC
  'quotas.incubation-eggs', // OI
  'quotas.roast-chicken', // PG
] as const

export const QuotaFromProductions = {
  'roast-turkey': 'quotas.roast-turkey',
  'heavy-turkey': 'quotas.heavy-turkey',
  milk: 'quotas.milk',
  'table-egg': 'quotas.laying-eggs',
  'incubation-egg': 'quotas.incubation-eggs',
  'roast-poultry': 'quotas.roast-chicken',
} as const satisfies Partial<Record<ProductionType, (typeof QuotaAssetSubTypes)[number]>>

export const AnimalsSubTypes = ['calf-animals', 'milk-animals', 'other-animals', ...AnimalProductions] as const

export const OtherAssetSubTypes = [
  'other.residence', // CH
  'other.non-agricultural', // CJ
  'other.agricultural',
] as const
export type OtherAssetSubType = (typeof OtherAssetSubTypes)[number]

export const AssetSubTypes = [
  'other.agricultural-1', // CE
  'other.agricultural-2', // CF
  'acquisition-cost.machinery-cat-10', // WC
  'acquisition-cost.machinery-cat-8', // WE
  'acquisition-cost.truck-cat-10', // WG
  'acquisition-cost.building-cat-6', // WI
  'acquisition-cost.land', // WK
  'acquisition-cost.drainage', // WM
  'acquisition-cost.quota', // WO
  'acquisition-cost.residence', // WP
  'acquisition-cost.non-agricultural', // WQ
  'acquisition-cost.agricultural-1', // WS
  'acquisition-cost.agricultural-2', // WU
  ...BuildingAssetSubTypes,
  ...MachineryAssetSubTypes,
  ...LandAssetSubTypes,
  ...QuotaAssetSubTypes,
  ...AnimalsSubTypes,
  ...OtherAssetSubTypes,
] as const

export const AssetSubTypeZod = z.enum(AssetSubTypes)
export type AssetSubType = z.infer<typeof AssetSubTypeZod>
