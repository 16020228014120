import { ProductionZod } from '@via/schema'
import { z } from 'zod'

export const ScenarioCreationFormZod = z.object({
  type: z.enum(['simple', 'complete']),
  name: z.string().min(1, { message: 'form.required' }),
  projectionYear: z.coerce.number().gte(1, { message: 'form.projectionYear.minimum' }),
  customReferenceName: z.string().optional(),
  description: z.string(),
  referenceType: z.enum(['viagritel', 'empty']),
  enabledProductions: z.array(ProductionZod).optional().default([]),
})
