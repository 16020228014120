import { type JsonSchema, type RxJsonSchema } from 'rxdb'

import { type AppRxDatabase, type RxCollectionDocumentType } from '../types.ts'

import { ClientInformationJsonSchema } from './common/clientInformationJsonSchema.ts'
import { UserUpdateJsonSchema } from './common/userUpdateJsonSchema.ts'

type BudgetRxDocument = RxCollectionDocumentType<AppRxDatabase['budgets']>

export const BudgetRxSchema = {
  title: 'budgets',
  description: 'budgets schema',
  version: 0,
  type: 'object',
  primaryKey: '_id',
  required: ['_id', 'clientId', 'name', 'year', 'status'],
  properties: {
    _id: {
      type: 'string',
      maxLength: 100,
    },
    clientId: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    year: {
      type: 'number',
    },
    status: {
      type: 'string',
    },
    contractId: {
      type: 'string',
    },
    referenceVersion: {
      type: 'number',
    },
    referenceYears: {
      type: 'array',
    },
    archived: {
      type: 'boolean',
    },
    users: {
      type: 'object',
      properties: {
        agronomist: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
          },
        },
        technician: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
          },
        },
        collaborator: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
          },
        },
      },
    } satisfies JsonSchema<BudgetRxDocument['users']>,
    members: {
      type: 'array',
      items: {
        type: 'string',
      },
    } satisfies JsonSchema<BudgetRxDocument['members']>,
    scenarios: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        creationTimestamp: {
          type: 'string',
          format: 'date-time',
        },
        numberOfYears: {
          type: 'array',
          items: {
            type: 'number',
          },
        },
      },
    } satisfies JsonSchema<BudgetRxDocument['scenarios']>,
    client: ClientInformationJsonSchema,
    creation: UserUpdateJsonSchema,
    lastModification: UserUpdateJsonSchema,
    owner: UserUpdateJsonSchema,
    // Local Extensions
    readonly: {
      type: 'boolean',
      default: false,
    },
    hasSyncError: {
      type: 'boolean',
      default: false,
    },
    mustSync: {
      type: 'boolean',
      default: false,
    },
    snapshotEpochTimestamp: {
      type: 'number',
    },
    lastPushEpochTimestamp: {
      type: 'number',
    },
  },
} as const satisfies RxJsonSchema<
  Pick<
    BudgetRxDocument,
    | '_id'
    | 'clientId'
    | 'name'
    | 'year'
    | 'status'
    | 'contractId'
    | 'referenceVersion'
    | 'referenceYears'
    | 'archived'
    | 'users'
    | 'members'
    | 'scenarios'
    | 'client'
    | 'creation'
    | 'lastModification'
    | 'owner'
    | 'readonly'
    | 'hasSyncError'
    | 'mustSync'
    | 'snapshotEpochTimestamp'
    | 'lastPushEpochTimestamp'
  >
>
