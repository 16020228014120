import { type SelectOption } from '../../atoms/Select/Select'
import { SettingDropdownMenu, type SettingDropdownMenuProps } from '../../atoms/SettingDropdownMenu/SettingDropdownMenu'
import { useConfirmationDialogContext } from '../../context'

export type SelectOptionWithConfirmation<T extends string = string> = SelectOption<T> & {
  title?: string
  subtitle?: string
  confirmLabel?: string
  cancelLabel?: string
}

export interface SettingDropdownWithConfirmationMenuProps<T extends string = string>
  extends SettingDropdownMenuProps<T> {
  readonly options: Array<SelectOptionWithConfirmation<T>> | ReadonlyArray<SelectOptionWithConfirmation<T>>
}

export const SettingDropdownWithConfirmationMenu = <T extends string = string>({
  options,
  onItemSelect,
  ...props
}: SettingDropdownWithConfirmationMenuProps<T>) => {
  const { open } = useConfirmationDialogContext()
  const selectItemWithConfirmation = (value: T) => {
    const selectedOption = options.find((option) => option.value === value)
    if (!selectedOption?.title) {
      onItemSelect(value)
      return
    }
    open({
      title: selectedOption.title,
      subtitle: selectedOption.subtitle,
      confirmLabel: selectedOption.confirmLabel,
      cancelLabel: selectedOption.cancelLabel,
      onConfirm: () => {
        onItemSelect(value)
      },
    })
  }

  return <SettingDropdownMenu {...props} options={options} onItemSelect={selectItemWithConfirmation} />
}
