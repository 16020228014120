import { type RefObject, useEffect, useState } from 'react'

export const useTableNavigationInsets = (navigationRef: RefObject<HTMLElement>) => {
  const { innerHeight: height, innerWidth: width } = globalThis

  const [insets, setInsets] = useState({ topInset: 0, bottomInset: height, leftInset: 0, rightInset: width })

  useEffect(() => {
    const topElement = navigationRef.current?.querySelector<HTMLElement>('#sticky-monetary-table-top-elements')

    const headerHeight = Number(topElement?.style.top.replace('px', ''))
    const stickyHeaderHeight = topElement?.getBoundingClientRect().height ?? 0
    const topInset = headerHeight + stickyHeaderHeight

    const bottomElement = navigationRef.current?.querySelector<HTMLElement>('#sticky-monetary-table-summary')

    const bottomInset = bottomElement?.getBoundingClientRect().height
      ? height - bottomElement.getBoundingClientRect().height
      : height

    const leftElements = navigationRef.current?.querySelectorAll<HTMLElement>('#sticky-monetary-table-left-elements')
    // Using second child sine the first one is the header of the sticky elements and doesn't have the same width
    const leftInset = leftElements?.[1]?.getBoundingClientRect().right ?? 0

    const rightInset = width

    setInsets({ topInset, bottomInset, leftInset, rightInset })
    // Navigation ref cause too much re-renders of the effect and is not needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInsets, width, height])

  return insets
}
