import { AnimalProductions, CommercialProductions, VegetalProductions } from '../../../enums'
import { type DataKey } from '../../data-key-zod'
import { type DataAttribute } from '../types'

export const RepaymentTableAttributes = {
  'repayment.margin.income.raw': {
    type: 'alias',
    key: 'income.total',
    readonly: true,
    fractionOf: 'result.income.total',
  },
  'repayment.margin.expenses.operational': {
    type: 'total',
    readonly: true,
    dependencies: ['expenses.total', 'result.company.expenses.fixed.salaries.total', 'result.expenses.finance.total'],
    arithmetic: {
      'result.company.expenses.fixed.salaries.total': { multiplier: -1 },
      'result.expenses.finance.total': { multiplier: -1 },
    },
    fractionOf: 'result.income.total',
  },
  'repayment.margin.total': {
    type: 'total',
    readonly: true,
    dependencies: ['repayment.margin.income.raw', 'repayment.margin.expenses.operational'],
    arithmetic: {
      'repayment.margin.expenses.operational': { multiplier: -1 },
    },
    fractionOf: 'result.income.total',
  },
  'repayment.charges.salaries': {
    type: 'alias',
    key: 'result.company.expenses.fixed.salaries.total',
    readonly: true,
    fractionOf: 'result.income.total',
  },
  'repayment.charges.withdrawals.total': {
    type: 'total',
    readonly: true,
    dependencies: ['repayment.charges.withdrawals.loan', 'repayment.charges.withdrawals.other'],
    fractionOf: 'result.income.total',
  },
  'repayment.charges.withdrawals.loan': {
    type: 'total',
    readonly: true,
    dependencies: ['finance.loan.outflows.withdrawals', 'finance.loan.shareholders-due.total.movement'],
    fractionOf: 'result.income.total',
  },
  'repayment.charges.withdrawals.other': {
    type: 'grbf',
    readonly: false,
    growth: false,
    reference: 'latest',
    referenceDependencies: [
      'outflows.owner.imported.total',
      'calf.income.imported.deductions',
      'milk.income.imported.deductions.product',
      'milk.income.imported.deductions.cattle',
      ...AnimalProductions.map((production) => `animal.${production}.income.imported.deductions` as const),
    ],
    fractionOf: 'result.income.total',
  },
  'repayment.charges.tax.total': {
    type: 'total',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    dependencies: ['repayment.charges.tax', 'repayment.charges.tax.milk.dividends'],
    fractionOf: 'result.income.total',
  },
  'repayment.charges.tax.milk.dividends': {
    type: 'alias',
    readonly: true,
    key: 'milk-income.dividends.tax',
    fractionOf: 'result.income.total',
  },
  'repayment.charges.tax': {
    type: 'grbf',
    readonly: false,
    growth: false,
    reference: 'latest',
    referenceDependencies: ['outflows.tax.imported.total'],
    fractionOf: 'result.income.total',
  },
  'repayment.charges.total': {
    type: 'total',
    readonly: true,
    dependencies: [
      'repayment.charges.salaries',
      'repayment.charges.withdrawals.total',
      'repayment.charges.tax',
      'repayment.charges.tax.milk.dividends',
    ],
    fractionOf: 'result.income.total',
  },
  'repayment.capacity.maximum': {
    type: 'total',
    readonly: true,
    dependencies: ['repayment.margin.total', 'repayment.charges.total'],
    arithmetic: {
      'repayment.charges.total': { multiplier: -1 },
    },
    fractionOf: 'result.income.total',
  },
  'repayment.debt.payments.capital': {
    type: 'alias',
    key: 'finance.loan.agricultural.total.projected-capital',
    readonly: true,
    fractionOf: 'result.income.total',
  },
  'repayment.debt.payments.interest': {
    type: 'alias',
    key: 'finance.loan.agricultural.interest.paid',
    readonly: true,
    fractionOf: 'result.income.total',
  },
  'repayment.debt.total': {
    type: 'total',
    readonly: true,
    dependencies: ['repayment.debt.payments.capital', 'repayment.debt.payments.interest'],
    fractionOf: 'result.income.total',
  },
  'repayment.residual.balance.basis': {
    type: 'total',
    readonly: true,
    dependencies: ['repayment.capacity.maximum', 'repayment.debt.total'],
    arithmetic: {
      'repayment.debt.total': { multiplier: -1 },
    },
    fractionOf: 'result.income.total',
  },
  'repayment.residual.balance.without.inventory': {
    type: 'total',
    readonly: true,
    dependencies: [
      'repayment.residual.balance.basis',
      'result.milk.income.inventory.breeders',
      'result.crops.income.inventory.forage',
      'result.calf.income.inventory.market',
      'result.calf.income.inventory.breeders',
      ...AnimalProductions.flatMap(
        (production) =>
          [
            `result.animal.${production}.income.inventory.breeders`,
            `result.animal.${production}.income.inventory.slaughter`,
          ] as const
      ),
    ],
    arithmetic: {
      'result.milk.income.inventory.breeders': { multiplier: -1 },
      'result.crops.income.inventory.forage': { multiplier: -1 },
      'result.calf.income.inventory.market': { multiplier: -1 },
      'result.calf.income.inventory.breeders': { multiplier: -1 },
      ...Object.fromEntries(
        AnimalProductions.flatMap((production) => [
          [`result.animal.${production}.income.inventory.breeders` as const, { multiplier: -1 }],
          [`result.animal.${production}.income.inventory.slaughter` as const, { multiplier: -1 }],
        ])
      ),
    },
    fractionOf: 'result.income.total',
  },
  'repayment.inflows.loans.agricultural.new-capital': {
    type: 'alias',
    key: 'finance.loan.agricultural.total.new-capital',
    readonly: true,
  },
  'repayment.inflows.loans.agricultural.early-repayment': {
    type: 'alias',
    key: 'finance.loan.agricultural.total.early-repayment',
    readonly: true,
  },
  'repayment.inflows.loans.agricultural.total': {
    type: 'total',
    readonly: true,
    dependencies: [
      'repayment.inflows.loans.agricultural.new-capital',
      'repayment.inflows.loans.agricultural.early-repayment',
    ],
    arithmetic: {
      'repayment.inflows.loans.agricultural.early-repayment': { multiplier: -1 },
    },
  },
  'repayment.inflows.balance.basis': {
    type: 'alias',
    key: 'repayment.residual.balance.basis',
    readonly: true,
  },
  'repayment.inflows.short.margin.increase': {
    type: 'total',
    readonly: true,
    dependencies: ['finance.short.liabilities.credit', 'finance.short.liabilities.payables'],
    arithmetic: {
      minValue: 0,
    },
  },
  'repayment.inflows.receivables.decrease': {
    type: 'total',
    readonly: true,
    dependencies: ['finance.short.assets.receivables'],
    arithmetic: {
      'finance.short.assets.receivables': { multiplier: -1 },
      minValue: 0,
    },
  },
  'repayment.inflows.contributions': {
    type: 'total',
    readonly: true,
    dependencies: [
      'inflows.owner.total',
      'finance.loan.inflows.contributions',
      'finance.investment.capital.contribution.total.disbursement',
    ],
    arithmetic: {
      'finance.investment.capital.contribution.total.disbursement': { multiplier: -1 },
    },
  },
  'repayment.inflows.inventory.decrease': {
    type: 'total',
    readonly: true,
    dependencies: [
      'income.inventory.supplies.total',
      'income.inventory.food.total',
      'result.milk.income.inventory.breeders',
      'result.calf.income.inventory.market',
      'result.calf.income.inventory.breeders',
      'result.crops.income.inventory.crops',
      'result.crops.income.inventory.forage',
      'result.maple.income.inventory.products',
      ...AnimalProductions.map((production) => `result.animal.${production}.income.inventory.breeders` as const),
      ...AnimalProductions.map((production) => `result.animal.${production}.income.inventory.slaughter` as const),
      ...CommercialProductions.map((production) => `result.commercial.${production}.income.inventory` as const),
      ...VegetalProductions.map((production) => `result.vegetal.${production}.income.inventory.products` as const),
    ],
    arithmetic: {
      'income.inventory.supplies.total': { multiplier: -1 },
      'income.inventory.food.total': { multiplier: -1 },
      'result.milk.income.inventory.breeders': { multiplier: -1 },
      'result.calf.income.inventory.market': { multiplier: -1 },
      'result.calf.income.inventory.breeders': { multiplier: -1 },
      'result.crops.income.inventory.crops': { multiplier: -1 },
      'result.crops.income.inventory.forage': { multiplier: -1 },
      'result.maple.income.inventory.products': { multiplier: -1 },
      ...Object.fromEntries(
        AnimalProductions.map((production) => [
          `result.animal.${production}.income.inventory.breeders` as const,
          { multiplier: -1 },
        ])
      ),
      ...Object.fromEntries(
        AnimalProductions.map((production) => [
          `result.animal.${production}.income.inventory.slaughter` as const,
          { multiplier: -1 },
        ])
      ),
      ...Object.fromEntries(
        CommercialProductions.map((production) => [
          `result.commercial.${production}.income.inventory` as const,
          { multiplier: -1 },
        ])
      ),
      ...Object.fromEntries(
        VegetalProductions.map((production) => [
          `result.vegetal.${production}.income.inventory.products` as const,
          { multiplier: -1 },
        ])
      ),
      minValue: 0,
    },
  },
  'repayment.inflows.shares.sales': {
    type: 'total',
    readonly: true,
    dependencies: ['inflows.investment.shares', 'finance.investment.shares.total.disbursement'],
    arithmetic: {
      'finance.investment.shares.total.disbursement': { multiplier: -1 },
      minValue: 0,
    },
  },
  'repayment.inflows.total': {
    type: 'total',
    readonly: true,
    dependencies: [
      'repayment.residual.balance.basis',
      'repayment.inflows.loans.agricultural.total',
      'repayment.inflows.short.margin.increase',
      'repayment.inflows.receivables.decrease',
      'repayment.inflows.contributions',
      'repayment.inflows.inventory.decrease',
      'repayment.inflows.shares.sales',
    ],
  },
  'repayment.outflows.investment.machinery': {
    type: 'total',
    readonly: true,
    dependencies: [
      'finance.asset.machinery.total.disbursement',
      'finance.investment.machinery.financing.total.disbursement',
    ],
  },
  'repayment.outflows.investment.buildings': {
    type: 'alias',
    readonly: true,
    key: 'finance.asset.building.total.disbursement',
  },
  'repayment.outflows.investment.land': {
    type: 'alias',
    readonly: true,
    key: 'finance.asset.land.total.disbursement',
  },
  'repayment.outflows.investment.quota': {
    type: 'alias',
    readonly: true,
    key: 'finance.asset.quota.total.disbursement',
  },
  'repayment.outflows.investment.assets.farming': {
    type: 'alias',
    readonly: true,
    key: 'finance.asset.other.agricultural.total.disbursement',
  },
  'repayment.outflows.investment.subsidies': {
    type: 'alias',
    readonly: true,
    key: 'finance.asset.total.subsidies',
  },
  'repayment.outflows.investment.total': {
    type: 'total',
    readonly: true,
    dependencies: [
      'repayment.outflows.investment.machinery',
      'repayment.outflows.investment.buildings',
      'repayment.outflows.investment.land',
      'repayment.outflows.investment.quota',
      'repayment.outflows.investment.assets.farming',
      'repayment.outflows.investment.subsidies',
    ],
    arithmetic: {
      'repayment.outflows.investment.subsidies': { multiplier: -1 },
    },
  },
  'repayment.outflows.investment.programs': {
    type: 'alias',
    readonly: true,
    key: 'finance.short.assets.programs',
  },
  'repayment.outflows.investment.assets.other': {
    type: 'total',
    readonly: true,
    dependencies: [
      'finance.asset.other.non-agricultural.total.disbursement',
      'finance.asset.other.residency.total.disbursement',
      'outflows.investment.personal',
      'outflows.investment.rrsp',
      'outflows.investment.non-agricultural',
    ],
  },
  'repayment.outflows.short.margin.decrease': {
    type: 'total',
    readonly: true,
    dependencies: ['finance.short.liabilities.credit', 'finance.short.liabilities.payables'],
    arithmetic: {
      minValue: 0,
      'finance.short.liabilities.credit': { multiplier: -1 },
      'finance.short.liabilities.payables': { multiplier: -1 },
    },
  },
  'repayment.outflows.receivables.increase': {
    type: 'total',
    readonly: true,
    dependencies: ['finance.short.assets.receivables'],
    arithmetic: {
      minValue: 0,
    },
  },
  'repayment.outflows.inventory.increase': {
    type: 'total',
    readonly: true,
    dependencies: [
      'income.inventory.supplies.total',
      'income.inventory.food.total',
      'result.milk.income.inventory.breeders',
      'result.calf.income.inventory.market',
      'result.calf.income.inventory.breeders',
      'result.crops.income.inventory.crops',
      'result.crops.income.inventory.forage',
      'result.maple.income.inventory.products',
      ...AnimalProductions.map((production) => `result.animal.${production}.income.inventory.breeders` as const),
      ...AnimalProductions.map((production) => `result.animal.${production}.income.inventory.slaughter` as const),
      ...CommercialProductions.map((production) => `result.commercial.${production}.income.inventory` as const),
      ...VegetalProductions.map((production) => `result.vegetal.${production}.income.inventory.products` as const),
    ],
    arithmetic: {
      minValue: 0,
    },
  },

  'repayment.outflows.shares.buys': {
    type: 'total',
    readonly: true,
    dependencies: ['inflows.investment.shares', 'finance.investment.shares.total.disbursement'],
    arithmetic: {
      'inflows.investment.shares': { multiplier: -1 },
      minValue: 0,
    },
  },
  'repayment.outflows.total': {
    type: 'total',
    readonly: true,
    dependencies: [
      'repayment.outflows.investment.total',
      'repayment.outflows.investment.programs',
      'repayment.outflows.investment.assets.other',
      'repayment.outflows.short.margin.decrease',
      'repayment.outflows.receivables.increase',
      'repayment.outflows.inventory.increase',
      'repayment.outflows.shares.buys',
    ],
  },
  'repayment.current.accounts.balance': {
    type: 'total',
    readonly: true,
    dependencies: ['repayment.inflows.total', 'repayment.outflows.total'],
    arithmetic: {
      'repayment.outflows.total': { multiplier: -1 },
    },
  },
  'repayment.balance.sheet.validation': { type: 'projection', readonly: false },
  'repayment.balance.sheet.difference': { type: 'projection', readonly: false },
} as const satisfies Partial<Record<DataKey, DataAttribute>>
