import type React from 'react'

import { type ReadonlyDeep } from 'type-fest'
import { z } from 'zod'

import { ZodForm } from '../../atoms/Form/ZodForm'

export type ReportFileFormProviderProps = React.PropsWithChildren<{
  readonly data: Partial<ReadonlyDeep<ReportFileFormData>>
}>

const ReportFileFormZod = z.object({
  defaultFileName: z.string().optional().default(''),
})

export type ReportFileFormData = z.infer<typeof ReportFileFormZod>

export const ReportFileFormProvider: React.FC<ReportFileFormProviderProps> = ({ data, children }) => {
  const defaultData = ReportFileFormZod.parse(data)

  return (
    <ZodForm schema={ReportFileFormZod} defaultValues={defaultData}>
      {children}
    </ZodForm>
  )
}
