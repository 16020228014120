import { type DataKey } from '../../data-key-zod'
import { AliasAttributesJson } from '../../generated'

export interface AliasDataAttribute<Key extends DataKey = DataKey> {
  readonly type: 'alias'
  readonly readonly: boolean
  readonly key: Key
}

export const AliasDataKeyAttributes = AliasAttributesJson as Record<
  keyof typeof AliasAttributesJson,
  AliasDataAttribute
>

export type AliasDataKey = keyof typeof AliasDataKeyAttributes
