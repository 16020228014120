import { addBreadcrumb, captureException } from '@sentry/react'
import { addRxPlugin, createRxDatabase, type RxStorage } from 'rxdb'
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup'
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election'
import { RxDBLocalDocumentsPlugin } from 'rxdb/plugins/local-documents'
import { RxDBStatePlugin } from 'rxdb/plugins/state'
import { RxDBUpdatePlugin } from 'rxdb/plugins/update'
import { wrappedLoggerStorage } from 'rxdb-premium/plugins/logger'

import { deleteAllViaIndexedDb } from '../indexedDB/deleteAllViaIndexedDb.ts'
import { registerRxCollectionBreadcrumbs } from '../registerRxCollectionBreadcrumbs.ts'
import { BudgetRxSchema } from '../schema/budgetRxSchema.ts'
import { ScenarioSyncStateRxSchema } from '../schema/scenarioSyncStateRxSchema.ts'
import { SnapshotRxSchema } from '../schema/snapshotRxSchema.ts'
import { type AppRxDatabase } from '../types.ts'

const APP_DB_NAME = `via-budgitel--${import.meta.env.VITE_RXDB_VERSION}`

async function createOrReset<Internals, InstanceCreationOptions>(
  storage: RxStorage<Internals, InstanceCreationOptions>
) {
  const db = await createRxDatabase<AppRxDatabase<Internals, InstanceCreationOptions>>({
    name: APP_DB_NAME,
    storage,
  })

  try {
    await db.addCollections<AppRxDatabase['collections']>({
      budgets: { schema: BudgetRxSchema },
      'scenario-sync-states': { schema: ScenarioSyncStateRxSchema },
      snapshots: { schema: SnapshotRxSchema, localDocuments: true },
    })
    return db
  } catch (error) {
    captureException(error, { level: 'warning' })
    await db.remove()
    await deleteAllViaIndexedDb(indexedDB)
    const newDb = await createRxDatabase<AppRxDatabase<Internals, InstanceCreationOptions>>({
      name: APP_DB_NAME,
      storage,
    })
    await newDb.addCollections<AppRxDatabase['collections']>({
      budgets: { schema: BudgetRxSchema },
      'scenario-sync-states': { schema: ScenarioSyncStateRxSchema },
      snapshots: { schema: SnapshotRxSchema, localDocuments: true },
    })
    return newDb
  }
}

export const initAppRxDatabase = async <Internals, InstanceCreationOptions>(
  storage: RxStorage<Internals, InstanceCreationOptions>
) => {
  if (import.meta.env.DEV) {
    const { RxDBDevModePlugin } = await import('rxdb/plugins/dev-mode')
    addRxPlugin(RxDBDevModePlugin)
  }

  addRxPlugin(RxDBUpdatePlugin)
  addRxPlugin(RxDBLeaderElectionPlugin)
  addRxPlugin(RxDBLocalDocumentsPlugin)
  addRxPlugin(RxDBStatePlugin)
  addRxPlugin(RxDBCleanupPlugin)

  const enableRxDBLogger = import.meta.env.VITE_RXDB_ENABLE_LOG === 'true'
  const instrumentedStorage = wrappedLoggerStorage({
    storage,
    settings: {
      prefix: 'via',
      times: enableRxDBLogger,
      metaStorageInstances: enableRxDBLogger,
      bulkWrite: enableRxDBLogger,
      findDocumentsById: enableRxDBLogger,
      query: enableRxDBLogger,
      count: enableRxDBLogger,
      info: enableRxDBLogger,
      getAttachmentData: false,
      getChangedDocumentsSince: enableRxDBLogger,
      cleanup: enableRxDBLogger,
      close: enableRxDBLogger,
      remove: enableRxDBLogger,
    },
    onOperationError: (operationsName, logId, args, error) =>
      captureException(error, { contexts: { rxdb: { operationsName, logId, args } } }),
  })

  const db = await createOrReset(instrumentedStorage)
  registerRxCollectionBreadcrumbs(db.budgets)
  registerRxCollectionBreadcrumbs(db['scenario-sync-states'])

  addBreadcrumb({ category: 'rxdb', message: `initialized ${APP_DB_NAME}` })

  return db
}
