import { BigNumber } from 'bignumber.js'

import {
  createBudgetDefinition,
  type CriReference,
  type CriReferenceFunction,
  type DatabaseResolverFnCompteExploitation,
  type DatabaseResolverFnCourtTerme,
  type DatabaseResolverFnFicheTechnique,
  type DatabaseResolverFnInventaireApprovisionnement,
  type TestResolver,
} from '../types'
import { isFermeBudgitel2021, isFermeBudgitel2022, sumBy } from '../utils'
import { type ViagritelProduction } from '../values'

const greenhouseProductions: ViagritelProduction[] = [
  'S2',
  'S4',
  'S6',
  'S8',
  'SB',
  'SD',
  'SF',
  'S1',
  'S3',
  'S5',
  'S7',
  'S9',
  'SC',
  'SH',
] as const
const nurseryProductions: ViagritelProduction[] = [
  'P4',
  'P6',
  'P8',
  'PQ',
  'PP',
  'PW',
  'PH',
  'PY',
  'PZ',
  'P3',
  'P5',
  'P7',
  'P9',
  'PJ',
  'PR',
  'PB',
  'PF',
  'PU',
  'PV',
] as const

const expensesSuppliesDirectResolverForCommercialProductions = (production: ViagritelProduction) => ({
  testResolver: ({ cri }: { cri: CriReferenceFunction }) => {
    const ceAndIaCris = [`${production}12`, `${production}16`, `${production}22`, `${production}23`]
    return ceAndIaCris.reduce((acc, criCode) => acc.plus(cri(criCode as CriReference)), new BigNumber(0))
  },
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        150_850, // Achat de matières premières (T1..8)    CO22
        150_900, // Contenants, boîtes, étiquettes (T1..8) CO12
        151_300, // Autres approv. directs (T1..8)         CO16
        151_350, // Produits sanitaires (T1..8)            CO23
      ]
      const iaLignes = [
        150_100, // Contenants, étiquettes, etc. (TX)   CO12
        150_110, // Matières premières (TX)             CO22
        150_120, // Produits sanitaires (TX)            CO23
        150_200, // Approvisionnements divers (TX)      CO16
      ]

      const ceTotal = sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
      const iaTotal = sumBy(inventairesApprovisionnements({ production, ligne: iaLignes }), 'delta_dollars')

      return ceTotal.plus(iaTotal)
    },
  },
})

const expensesSuppliesDirectResolverForGardenProductions = () => ({
  testResolver: ({ cri }: { cri: CriReferenceFunction }) => {
    const ceCris = [
      'CJ30',
      'CJ32',
      'CJ34',
      'CJ36',
      'CJ38',
      'CJ40',
      'CJ42',
      'CJ44',
      'CJ46',
      'CJ48',
      'CJ50',
      'CJ51',
      'CJ52',
      'CJ53',
      'CJ55',
      'CJ56',
    ]
    const ceAmount = ceCris.reduce((acc, criCode) => acc.plus(cri(criCode as CriReference)), new BigNumber(0))

    const iaBeginCris = ['CJ112', 'CJ113', 'CJ114', 'CJ115', 'CJ116', 'CJ117', 'CJ118']
    const iaBeginAmount = iaBeginCris.reduce((acc, criCode) => acc.plus(cri(criCode as CriReference)), new BigNumber(0))

    const iaEndCris = ['CJ124', 'CJ125', 'CJ126', 'CJ127', 'CJ128', 'CJ129', 'CJ130']
    const iaEndAmount = iaEndCris.reduce((acc, criCode) => acc.plus(cri(criCode as CriReference)), new BigNumber(0))

    return ceAmount.plus(iaBeginAmount).minus(iaEndAmount)
  },
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        11_500, // Achats végétaux(Fiche des ventes)                 CJ30
        11_600, // Achats fleuristerie (Fiche des ventes)            CJ32
        11_700, // Achats matières premières (Fiche des ventes)      CJ34
        11_800, // Achats produits inertes (Fiche des ventes)        CJ36
        11_900, // Autres achats (Fiche des ventes)                  CJ38
        12_000, // Achats interne végétaux (Fiche des ventes)        CJ40
        12_100, // Achats internes fleuristerie (Fiche des ventes)   CJ42
        12_200, // Achats int. matières prem. (Fiche des ventes)     CJ44
        12_300, // Ach. int. autres prod. inertes (Fiche des ventes) CJ46
        12_400, // Autres achats internes (Fiche des ventes)         CJ48
        12_500, // Transport à forfait sur achats                    CJ50
        12_600, // Entre. et répar. matériel roulant pour achat      CJ51
        12_700, // Enregis., assurances., permis m.r. pour achat     CJ52
        12_800, // Carburant matériel roulant pour achat             CJ53
        12_900, // Autres frais reliés aux achats                    CJ55
        13_000, // Esc. accordés sur achats (chiffre négatif)        CJ56
      ]
      const iaLignes = [
        10_000, // Fourn. pour val. ajoutée (caches pot et déco.) CJ112 - CJ124
        10_100, // Fournitures pour livraison et emballage        CJ113 - CJ125
        10_200, // Fournitures d'étiquetage et d'identification   CJ114 - CJ126
        10_300, // Autres fournitures directs                     CJ115 - CJ127
        10_400, // Autres approvisionnements                      CJ116 - CJ128
        10_500, // Carburant pour livraison                       CJ117 - CJ129
        10_600, // Combustible pour chauffage                     CJ118 - CJ130
      ]

      const ceTotal = sumBy(comptesExploitation({ production: 'CJ', ligne: ceLignes }), 'montant')
      const iaTotal = sumBy(inventairesApprovisionnements({ production: 'CJ', ligne: iaLignes }), 'delta_dollars')

      return ceTotal.plus(iaTotal)
    },
  },
})

const expensesSuppliesDirectResolverForGreenhouseProduction = () => ({
  testResolver: ({ cri }: { cri: CriReferenceFunction }) => {
    const ceCris = greenhouseProductions.flatMap((greenhouseProduction) => [
      `${greenhouseProduction}27`,
      `${greenhouseProduction}29`,
      `${greenhouseProduction}30`,
      `${greenhouseProduction}31`,
      `${greenhouseProduction}33`,
      `${greenhouseProduction}35`,
      `${greenhouseProduction}37`,
      `${greenhouseProduction}39`,
      `${greenhouseProduction}40`,
      `${greenhouseProduction}41`,
      `${greenhouseProduction}42`,
      `${greenhouseProduction}43`,
      `${greenhouseProduction}44`,
      `${greenhouseProduction}45`,
      `${greenhouseProduction}46`,
      `${greenhouseProduction}48`,
      `${greenhouseProduction}49`,
      `${greenhouseProduction}50`,
      `${greenhouseProduction}51`,
      `${greenhouseProduction}53`,
      `${greenhouseProduction}55`,
      `${greenhouseProduction}56`,
      `${greenhouseProduction}57`,
      `${greenhouseProduction}58`,
      `${greenhouseProduction}59`,
      `${greenhouseProduction}60`,
      `${greenhouseProduction}62`,
      `${greenhouseProduction}63`,
    ])
    const ceAmount = ceCris.reduce((acc, criCode) => acc.plus(cri(criCode as CriReference)), new BigNumber(0))

    const iaBeginCris = greenhouseProductions.flatMap((production) => [
      `${production}141`,
      `${production}142`,
      `${production}143`,
      `${production}144`,
      `${production}145`,
      `${production}146`,
      `${production}147`,
      `${production}148`,
      `${production}149`,
      `${production}150`,
      `${production}151`,
      `${production}152`,
      `${production}153`,
      `${production}154`,
      `${production}155`,
      `${production}156`,
      `${production}157`,
      `${production}158`,
      `${production}159`,
      `${production}160`,
      `${production}161`,
      `${production}162`,
      `${production}163`,
      `${production}164`,
    ])
    const iaBeginAmount = iaBeginCris.reduce((acc, criCode) => acc.plus(cri(criCode as CriReference)), new BigNumber(0))

    const iaEndCris = greenhouseProductions.flatMap((production) => [
      `${production}181`,
      `${production}182`,
      `${production}183`,
      `${production}184`,
      `${production}185`,
      `${production}186`,
      `${production}187`,
      `${production}188`,
      `${production}189`,
      `${production}190`,
      `${production}191`,
      `${production}192`,
      `${production}193`,
      `${production}194`,
      `${production}195`,
      `${production}196`,
      `${production}197`,
      `${production}198`,
      `${production}199`,
      `${production}200`,
      `${production}201`,
      `${production}202`,
      `${production}203`,
      `${production}204`,
    ])
    const iaEndAmount = iaEndCris.reduce((acc, criCode) => acc.plus(cri(criCode as CriReference)), new BigNumber(0))

    return ceAmount.plus(iaBeginAmount).minus(iaEndAmount)
  },
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        211_200, // Achats pour revente plantes (Fiche des ventes)   SO27
        211_300, // Achats d'autres produits pour revente            SO29
        211_500, // Semences                                         SO30
        211_600, // Boutures                                         SO31
        211_700, // Plants                                           SO33
        211_750, // Achat de plants mère                             SO35
        211_800, // Approvisionnements internes de plants            SO37
        211_900, // Approvisionnements internes matières premières   SO39
        212_000, // Approvisionnements internes autres               SO40
        212_100, // Contenants de production                         SO41
        212_200, // Substrats + milieu de culture                    SO42
        212_300, // Étiquettes de production                         SO43
        212_400, // Herbicides                                       SO44
        212_500, // Autres pesticides chim. et contrôle bio.         SO45
        212_600, // Fertilisants                                     SO46
        212_700, // Amendements                                      SO48
        212_800, // Régulateurs de croissance                        SO49
        212_900, // Contenants d'expédition                          SO50
        212_950, // Étiquettes pour arrangements ou affichage        SO51
        213_000, // Manchons (Sleeves)                               SO53
        213_100, // Autres approvisionnements pour expédition        SO55
        213_200, // Mat. Val. ajoutée (cache-pot, étiquettes,etc.)   SO56
        213_300, // Transp. à forfait sur achats, approvisionnements SO57
        213_400, // Entre. et répar. matériel roulant pour achat     SO58
        213_500, // Enreg., assurances, permis m.r. pour achat       SO59
        213_600, // Carburant matériel roulant pour achat            SO60
        213_700, // Autres approv. directs de production             SO62
        213_800, // Esc. accordés sur achats (chiffre négatif)       SO63
      ]
      const iaLignes = [
        170_000, // Autres produits pour revente                       SO141 - SO181
        170_100, // Semences                                           SO142 - SO182
        170_200, // Boutures                                           SO143 - SO183
        170_300, // Plants à mettre en culture                         SO144 - SO184
        170_400, // Contenants de productions                          SO145 - SO185
        170_500, // Substrats + milieu de culture                      SO146 - SO186
        170_600, // Étiquettes de production                           SO147 - SO187
        170_700, // Herbicides                                         SO148 - SO188
        170_800, // Autres pesticides chimiques et contrôle biologique SO149 - SO189
        170_900, // Fertilisants                                       SO150 - SO190
        171_000, // Amendements                                        SO151 - SO191
        171_100, // Régulateurs de croissance                          SO152 - SO192
        171_200, // Contenants d'expédition                            SO153 - SO193
        171_250, // Étiquettes pour arrangements ou affichage          SO154 - SO194
        171_300, // Manchons (sleeves)                                 SO155 - SO195
        171_400, // Autres approvisionnements pour expédition          SO156 - SO196
        171_500, // Mat. Val. ajoutée (cache-pot, étiquettes,etc.)     SO157 - SO197
        171_600, // Autres approv. directs de production               SO158 - SO198
        171_700, // Marchandises pour revente                          SO159 - SO199
        171_900, // Huile à chauffage                                  SO160 - SO200
        172_000, // 1 Chauffage autres combustible                     SO161 - SO201
        172_100, // 2 Autre combustible                                SO162 - SO202
        172_200, // Autres approvisionnements                          SO163 - SO203
        172_300, // Carburant pour livraison                           SO164 - SO204
      ]

      const ceTotal = sumBy(comptesExploitation({ ligne: ceLignes, type_ligne: 'SO' }), 'montant')
      const iaTotal = sumBy(inventairesApprovisionnements({ ligne: iaLignes, type_ligne: 'SO' }), 'delta_dollars')

      return ceTotal.plus(iaTotal)
    },
  },
})

const expensesSuppliesDirectResolverForNurseryProductions = () => ({
  testResolver: {
    test: [
      // We sadly can't rely solely on cris as cri 182 isn't generated and all lines after this one are off
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(13_016),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(-5784),
      },
    ],
  },
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        201_200, // Achats pour revente de plantes               PO27
        201_300, // Achats d'autres produits pour revente        PO29
        201_500, // Semences                                     PO30
        201_600, // Boutures                                     PO31
        201_700, // Plants                                       PO33
        201_750, // Achat de plants mère                         PO35
        201_800, // Approvisionnements internes de plants        PO37
        201_900, // Approvisionnements internes mat. premières   PO39
        202_000, // Approvisionnements internes autres           PO40
        202_100, // Contenants de production                     PO41
        202_200, // Substrats + milieu de culture                PO43
        202_300, // Étiquettes de production                     PO45
        202_400, // Herbicides                                   PO47
        202_500, // Autres pesticides chimiques et contrôle bio. PO48
        202_600, // Fertilisants                                 PO49
        202_700, // Amendements                                  PO51
        202_800, // Régulateurs de croissance                    PO53
        202_900, // Matériaux pour expédition                    PO54
        203_000, // Matériel d'hivernage                         PO55
        203_050, // Étiquettes pour arrangements ou affichage    PO56
        203_100, // Autres approv. directs de production         PO58
        203_200, // Matériaux pour valeur ajoutée                PO59
        203_300, // Transport à forfait sur achats et approv.    PO60
        203_400, // Entr. et répar. matériel roulant pour achat  PO61
        203_500, // Enreg., assurances, permis m.r. pour achat   PO62
        203_600, // Carburant matériel roulant pour achat        PO63
        203_700, // Esc. accordés sur achats (chiffre négatif)   PO65
      ]
      const iaLignes = [
        180_000, // Semences                                      PO148 - PO182
        180_100, // Boutures                                      PO149 - PO183
        180_200, // Plants à mettre en culture                    PO150 - PO184
        180_300, // Contenants de production                      PO151 - PO185
        180_400, // Substrats + milieu de culture                 PO152 - PO186
        180_500, // Étiquettes de production                      PO153 - PO187
        180_600, // Herbicides                                    PO154 - PO188
        180_700, // Autres pesticides chimiques et contrôle biol. PO155 - PO189
        180_800, // Fertilisants                                  PO156 - PO190
        180_900, // Amendements                                   PO157 - PO191
        181_000, // Régulateurs de croissance                     PO158 - PO192
        181_100, // Matériaux pour expédition                     PO159 - PO193
        181_200, // Matériel d'hivernage                          PO160 - PO194
        181_250, // Étiquettes pour arrangements ou affichage     PO161 - PO195
        181_300, // Autres approv. directs de production          PO162 - PO196
        181_400, // Autres matériaux pour valeur ajoutée          PO163 - PO197
        181_600, // Marchandises pour revente                     PO164 - PO198
        181_800, // Huile à chauffage                             PO165 - PO199
        181_900, // 1 Chauffage autres combustible                PO166 - PO200
        182_000, // 2 Autre combustible                           PO167 - PO201
      ]

      const ceTotal = sumBy(comptesExploitation({ ligne: ceLignes, type_ligne: 'PO' }), 'montant')
      const iaTotal = sumBy(inventairesApprovisionnements({ ligne: iaLignes, type_ligne: 'PO' }), 'delta_dollars')

      return ceTotal.plus(iaTotal)
    },
  },
})

const expensesSuppliesDirectResolverForWoodProduction = () => ({
  testResolver: ({ cri }: { cri: CriReferenceFunction }) => {
    const ceCris = ['IS23', 'IS33', 'IS34']
    return ceCris.reduce((acc, criCode) => acc.plus(cri(criCode as CriReference)), new BigNumber(0))
  },
  databaseResolver: {
    resolve: ({
      comptesExploitation,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        33_100, // Autres approv. directs      IS34
        32_000, // Outils, vêtements, sécurité IS23
        33_000, // Coûts directs chevaux       IS33
      ]

      return sumBy(comptesExploitation({ production: 'IS', ligne: ceLignes }), 'montant')
    },
  },
})

const inventoryIncomeResolver = (production: ViagritelProduction) => ({
  testResolver: ({ cri }: { cri: CriReferenceFunction }) => cri(`${production}261`).minus(cri(`${production}260`)),
  databaseResolver: {
    resolve: ({ fichesTechniques }: { fichesTechniques: DatabaseResolverFnFicheTechnique }) =>
      fichesTechniques({ production, ligne: 120_100 })
        .valeur // Inventaire fin de produits fabriqués
        .minus(fichesTechniques({ production, ligne: 120_000 }).valeur), // Inventaire début de produits fabriqués,
  },
})

const marketingExpenseResolver = (production: ViagritelProduction) => ({
  testResolver: `${production}14` as TestResolver,
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) =>
      sumBy(comptesExploitation({ production, ligne: 151_100 }), 'montant'), // M-marché, transp., inspection CO14
  },
})

const miscellaneousExpenseResolver = (production: ViagritelProduction) => ({
  testResolver: ({ cri }: { cri: CriReferenceFunction }) =>
    cri(`${production}13`)
      .plus(cri(`${production}15`))
      .plus(cri(`${production}24`))
      .plus(cri(`${production}21`)),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) => {
      const ceLignes = [
        151_000, // Publicité, représentation        ${production}13
        151_200, // Chauffage et électricité         ${production}15
        151_550, // Frais de formation et honoraires ${production}24
        151_800, // Autres frais directs             ${production}21
      ]
      return sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
    },
  },
})

const miscellaneousIncomeResolver = (production: ViagritelProduction) => ({
  testResolver: ({ cri }: { cri: CriReferenceFunction }) =>
    cri(`${production}9`)
      .plus(cri(`${production}10`))
      .plus(cri(`${production}29`))
      .plus(cri(`${production}31`)),
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      courtsTermes,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      courtsTermes: DatabaseResolverFnCourtTerme
    }) => {
      const ceLignes = [
        150_200, // Commissions de vente cri = ${production}9
        150_300, // Autres revenus directs cri = ${production}10
      ]
      return sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant').plus(
        sumBy(courtsTermes({ production, type: ['CP', 'CR', 'FP', 'FR'] }), 'profit_perte') // cris = ${production}29 & ${production}31
      )
    },
  },
})

const productSalesIncomeResolver = (production: ViagritelProduction) => ({
  testResolver: `${production}8` as TestResolver,
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) =>
      sumBy(comptesExploitation({ production, ligne: 150_100 }), 'montant'), // Vente de produits
  },
})

const resaleSuppliesExpenseResolver = (production: ViagritelProduction) => ({
  testResolver: ({ cri }: { cri: CriReferenceFunction }) => cri(`${production}11`),
  databaseResolver: {
    // Au CE T1 à T8 ligne Produits achetés pour revente
    // + Inv. approv Prodns T1 à T8 ligne Produits achetés pour revente début $
    // - Inv approv. Prodns T1 à T8 ligne Produits achetés pour revente fin$
    resolve: ({
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceCharges = sumBy(comptesExploitation({ production, ligne: 150_800 }), 'montant') // Produits achetés pour revente CO11
      const iaDelta = sumBy(inventairesApprovisionnements({ production, ligne: 150_000 }), 'delta_dollars') // Produits achetés pour revente CO11

      return ceCharges.plus(iaDelta)
    },
  },
})

export const commercial = createBudgetDefinition({
  'commercial.agro-tourism.expenses.imported.marketing': marketingExpenseResolver('T3'),
  'commercial.agro-tourism.expenses.imported.miscellaneous': miscellaneousExpenseResolver('T3'),
  'commercial.agro-tourism.expenses.imported.supplies.direct':
    expensesSuppliesDirectResolverForCommercialProductions('T3'),
  'commercial.agro-tourism.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('T3'),
  'commercial.agro-tourism.income.imported.inventory': inventoryIncomeResolver('T3'),
  'commercial.agro-tourism.income.imported.miscellaneous': miscellaneousIncomeResolver('T3'),
  'commercial.agro-tourism.income.imported.sales.products': productSalesIncomeResolver('T3'),
  'commercial.bakery.expenses.imported.marketing': marketingExpenseResolver('T6'),
  'commercial.bakery.expenses.imported.miscellaneous': miscellaneousExpenseResolver('T6'),
  'commercial.bakery.expenses.imported.supplies.direct': expensesSuppliesDirectResolverForCommercialProductions('T6'),
  'commercial.bakery.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('T6'),
  'commercial.bakery.income.imported.inventory': inventoryIncomeResolver('T6'),
  'commercial.bakery.income.imported.miscellaneous': miscellaneousIncomeResolver('T6'),
  'commercial.bakery.income.imported.sales.products': productSalesIncomeResolver('T6'),
  'commercial.butchery.expenses.imported.marketing': marketingExpenseResolver('T7'),
  'commercial.butchery.expenses.imported.miscellaneous': miscellaneousExpenseResolver('T7'),
  'commercial.butchery.expenses.imported.supplies.direct': expensesSuppliesDirectResolverForCommercialProductions('T7'),
  'commercial.butchery.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('T7'),
  'commercial.butchery.income.imported.inventory': inventoryIncomeResolver('T7'),
  'commercial.butchery.income.imported.miscellaneous': miscellaneousIncomeResolver('T7'),
  'commercial.butchery.income.imported.sales.products': productSalesIncomeResolver('T7'),
  'commercial.cheese.expenses.imported.marketing': marketingExpenseResolver('T2'),
  'commercial.cheese.expenses.imported.miscellaneous': miscellaneousExpenseResolver('T2'),
  'commercial.cheese.expenses.imported.supplies.direct': expensesSuppliesDirectResolverForCommercialProductions('T2'),
  'commercial.cheese.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('T2'),
  'commercial.cheese.income.imported.inventory': inventoryIncomeResolver('T2'),
  'commercial.cheese.income.imported.miscellaneous': miscellaneousIncomeResolver('T2'),
  'commercial.cheese.income.imported.sales.products': productSalesIncomeResolver('T2'),
  'commercial.commercial.expenses.imported.marketing': marketingExpenseResolver('T1'),
  'commercial.commercial.expenses.imported.miscellaneous': miscellaneousExpenseResolver('T1'),
  'commercial.commercial.expenses.imported.supplies.direct':
    expensesSuppliesDirectResolverForCommercialProductions('T1'),
  'commercial.commercial.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('T1'),
  'commercial.commercial.income.imported.inventory': inventoryIncomeResolver('T1'),
  'commercial.commercial.income.imported.miscellaneous': miscellaneousIncomeResolver('T1'),
  'commercial.commercial.income.imported.sales.products': productSalesIncomeResolver('T1'),
  'commercial.garden.expenses.imported.miscellaneous': {
    testResolver: ({ cri }: { cri: CriReferenceFunction }) =>
      cri('CJ89')
        .plus(cri('CJ90'))
        .plus(cri('CJ91'))
        .plus(cri('CJ92'))
        .plus(cri('CJ93'))
        .plus(cri('CJ94'))
        .plus(cri('CJ95'))
        .plus(cri('CJ96'))
        .plus(cri('CJ97'))
        .plus(cri('CJ98'))
        .plus(cri('CJ99'))
        .plus(cri('CJ101'))
        .plus(cri('CJ102'))
        .plus(cri('CJ103'))
        .plus(cri('CJ104'))
        .plus(cri('CJ105'))
        .plus(cri('CJ106'))
        .plus(cri('CJ109')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          14_500, // Frais de ventes et commissions ventes à forfait CJ89
          14_600, // Frais de cartes crédits et débits               CJ90
          14_700, // Fourn. pour val. ajoutée ( caches pot et déco.) CJ91
          14_800, // Fournitures pour livraison et emballage         CJ92
          14_900, // Fournitures d'étiquetage et d'identification    CJ93
          15_000, // Autres fournitures directes                     CJ94
          15_100, // Autres frais directs d'opération                CJ95
          15_200, // Transport à forfait livraison                   CJ96
          15_300, // Ent. et répar. matériel roulant livraison       CJ97
          15_400, // Enreg., assurances, permis m.r. livraison       CJ98
          15_500, // Carburant matériel roulant livraison            CJ99
          15_600, // Autres frais directs mise en marché et distr.   CJ101
          15_700, // Entretien des équipements ventes                CJ102
          15_800, // Entretien matériel et équipement informatique   CJ103
          15_900, // Location matériel et équipement informatique    CJ104
          16_000, // Publicité, promotion                            CJ105
          16_100, // Catalogue, affichage                            CJ106
          16_300, // Service externe et frais bancaires              CJ109
        ]
        return sumBy(comptesExploitation({ production: 'CJ', ligne: ceLignes }), 'montant')
      },
    },
  },
  'commercial.garden.expenses.imported.supplies.direct': expensesSuppliesDirectResolverForGardenProductions(),
  'commercial.garden.expenses.imported.supplies.resale': {
    testResolver: false,
    databaseResolver: {
      resolve: () => new BigNumber(0),
    },
  },
  'commercial.garden.income.imported.miscellaneous': {
    testResolver: ({ cri }) => cri('CJ25').plus(cri('CJ26')).plus(cri('CJ27')).plus(cri('CJ28')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          10_900, // Revenus de livraison                       CJ25
          11_000, // Revenus de paysagement                     CJ26
          11_100, // Autres revenus                             CJ27
          11_200, // Esc. accordés sur ventes (chiffre négatif) CJ28
        ]
        return sumBy(comptesExploitation({ production: 'CJ', ligne: ceLignes }), 'montant')
      },
    },
  },
  'commercial.garden.income.imported.sales.products': {
    testResolver: ({ cri }) =>
      cri('CJ9')
        .plus(cri('CJ11'))
        .plus(cri('CJ13'))
        .plus(cri('CJ15'))
        .plus(cri('CJ17'))
        .minus(cri('CJ19'))
        .minus(cri('CJ21'))
        .plus(cri('CJ23')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const sumVentes = sumBy(
          comptesExploitation({
            production: 'CJ',
            ligne: [
              10_100, // Ventes ext. de végétaux (Fiche a.v.)              CJ9
              10_200, // Ventes ext. produits de fleuristerie (Fiche a.v.) CJ11
              10_300, // Ventes ext. matières premières (Fiche a.v.)       CJ13
              10_400, // Ventes ext. autres produits inertes (Fiche a.v.)  CJ15
              10_500, // Autres ext. ventes nettes (Fiche a.v.)            CJ17
              10_800, // Autres ventes internes (Fiche a.v.)               CJ23
            ],
          }),
          'montant'
        )
        const sumVentesInternes = sumBy(
          comptesExploitation({
            production: 'CJ',
            ligne: [
              10_600, // Ventes internes végétaux (Fiche a.v.)             CJ19
              10_700, // Ventes int. matières prem. (Fiche a.v.)           CJ21
            ],
          }),
          'montant'
        )
        return sumVentes.minus(sumVentesInternes)
      },
    },
  },
  'commercial.greenhouse.expenses.imported.miscellaneous': {
    testResolver: ({ cri }) =>
      greenhouseProductions.reduce(
        (acc, production) =>
          acc.plus(
            cri(`${production}96`)
              .plus(cri(`${production}97`))
              .plus(cri(`${production}98`))
              .plus(cri(`${production}100`))
              .plus(cri(`${production}102`))
              .plus(cri(`${production}104`))
              .plus(cri(`${production}106`))
              .plus(cri(`${production}108`))
              .plus(cri(`${production}110`))
              .plus(cri(`${production}112`))
              .plus(cri(`${production}114`))
              .plus(cri(`${production}115`))
              .plus(cri(`${production}116`))
              .plus(cri(`${production}118`))
              .plus(cri(`${production}119`))
              .plus(cri(`${production}120`))
              .plus(cri(`${production}122`))
              .plus(cri(`${production}123`))
              .plus(cri(`${production}124`))
              .plus(cri(`${production}125`))
              .plus(cri(`${production}127`))
              .plus(cri(`${production}128`))
              .plus(cri(`${production}129`))
              .plus(cri(`${production}130`))
              .plus(cri(`${production}131`))
              .plus(cri(`${production}132`))
              .plus(cri(`${production}133`))
              .plus(cri(`${production}134`))
              .plus(cri(`${production}135`))
              .plus(cri(`${production}138`))
          ),
        new BigNumber(0)
      ),
    databaseResolver: {
      resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) => {
        const ceLignes = [
          215_400, // Trav. à forfait de cult. (contenu M.O. max 70%) SO96
          215_500, // Forfait géné. répartis (service suivi de cult.) SO97
          215_600, // Chauffage électricité                           SO98
          215_700, // Chauffage gaz naturel                           SO100
          215_750, // Chauffage au gas propane                        SO102
          215_800, // Chauffage huile                                 SO104
          215_850, // Chauffage au bois                               SO106
          215_900, // Chauffage autres                                SO108
          215_950, // Chauffage au charbon                            SO110
          216_000, // Électricité pour éclairage artificiel           SO112
          216_100, // Entr. et loc. équipements de culture- serre     SO114
          216_200, // Fourn. pour travaux de cult. & autres directs   SO115
          216_300, // Entretien et location serres                    SO116
          216_400, // Entr. et répar. matériel roulant usage général  SO118
          216_500, // Enreg. assurances, permis m.r. usage général    SO119
          216_600, // Carburant matériel roulant usage général        SO120
          216_800, // Transport à forfait livraison                   SO122
          216_900, // Entr. et réparation matériel roulant livraison  SO123
          217_000, // Enreg., assurances, permis m.r. livraison       SO124
          217_100, // Carburant matériel roulant livraison            SO125
          217_200, // Frais de ventes et commissions à forfait        SO127
          217_300, // Frais de cartes de crédit et cartes débit       SO128
          217_400, // Autres frais directs mise en marché et distrib. SO129
          217_600, // Entre. des équipements ventes                   SO130
          217_700, // Entretien matériel et équipement informatique   SO131
          217_800, // Location matériel et équipement informatique    SO132
          217_900, // Publicité, promotion                            SO133
          218_000, // Catalogue, affichage                            SO134
          218_100, // Autres dépenses marketing et ventes fixes       SO135
          218_300, // Service externe et frais bancaires              SO138
        ]
        return sumBy(comptesExploitation({ production: greenhouseProductions, ligne: ceLignes }), 'montant')
      },
    },
  },
  'commercial.greenhouse.expenses.imported.supplies.direct': expensesSuppliesDirectResolverForGreenhouseProduction(),
  'commercial.greenhouse.expenses.imported.supplies.resale': {
    testResolver: false,
    databaseResolver: {
      resolve: () => new BigNumber(0),
    },
  },
  'commercial.greenhouse.income.imported.miscellaneous': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(2980),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation, courtsTermes }) => {
        const ceLignes = [
          210_700, // V. de services main d'oeuvre               SO21
          210_800, // Autres revenus                             SO23
          210_900, // Esc. accordés sur ventes (chiffre négatif) SO24
          210_950, // Autres programmes                          SO25
        ]
        return sumBy(comptesExploitation({ type_ligne: 'SO', ligne: ceLignes }), 'montant').plus(
          sumBy(courtsTermes({ type_ligne: 'GS', type: ['FP', 'CR', 'CP', 'FR'] }), 'profit_perte')
        )
      },
    },
  },
  'commercial.greenhouse.income.imported.sales.products': {
    testResolver: ({ cri }) =>
      greenhouseProductions.reduce(
        (acc, production) =>
          acc
            .plus(cri(`${production}9`))
            .minus(cri(`${production}11`))
            .plus(cri(`${production}13`))
            .plus(cri(`${production}15`))
            .plus(cri(`${production}17`))
            .minus(cri(`${production}18`))
            .plus(cri(`${production}19`)),
        new BigNumber(0)
      ),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const sumVentes = sumBy(
          comptesExploitation({
            production: greenhouseProductions,
            ligne: [
              210_100, // V. ext. produits des cult. ( Fiche a.v.) SO9
              210_300, // V. achat/revente plantes (Fiche a.v.)    SO13
              210_350, // Vente de plants mère                     SO15
              210_400, // V. nettes d'autres produits              SO17
              210_600, // V. de matières premières                 SO19
            ],
          }),
          'montant'
        )
        const sumVentesInternes = sumBy(
          comptesExploitation({
            production: greenhouseProductions,
            ligne: [
              210_200, // V. int. produits des cult. (Fiche a.v.)  SO11
              210_500, // V. internes autres produits              SO18
            ],
          }),
          'montant'
        )
        return sumVentes.minus(sumVentesInternes)
      },
    },
  },
  'commercial.honey.expenses.imported.marketing': marketingExpenseResolver('T8'),
  'commercial.honey.expenses.imported.miscellaneous': miscellaneousExpenseResolver('T8'),
  'commercial.honey.expenses.imported.supplies.direct': expensesSuppliesDirectResolverForCommercialProductions('T8'),
  'commercial.honey.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('T8'),
  'commercial.honey.income.imported.inventory': inventoryIncomeResolver('T8'),
  'commercial.honey.income.imported.miscellaneous': miscellaneousIncomeResolver('T8'),
  'commercial.honey.income.imported.sales.products': productSalesIncomeResolver('T8'),
  'commercial.nursery.expenses.imported.miscellaneous': {
    testResolver: ({ cri }) =>
      nurseryProductions.reduce(
        (acc, production) =>
          acc.plus(
            cri(`${production}98`)
              .plus(cri(`${production}99`))
              .plus(cri(`${production}100`))
              .plus(cri(`${production}102`))
              .plus(cri(`${production}104`))
              .plus(cri(`${production}106`))
              .plus(cri(`${production}108`))
              .plus(cri(`${production}110`))
              .plus(cri(`${production}112`))
              .plus(cri(`${production}114`))
              .plus(cri(`${production}116`))
              .plus(cri(`${production}118`))
              .plus(cri(`${production}119`))
              .plus(cri(`${production}121`))
              .plus(cri(`${production}122`))
              .plus(cri(`${production}123`))
              .plus(cri(`${production}124`))
              .plus(cri(`${production}125`))
              .plus(cri(`${production}126`))
              .plus(cri(`${production}127`))
              .plus(cri(`${production}129`))
              .plus(cri(`${production}130`))
              .plus(cri(`${production}131`))
              .plus(cri(`${production}132`))
              .plus(cri(`${production}134`))
              .plus(cri(`${production}135`))
              .plus(cri(`${production}136`))
              .plus(cri(`${production}137`))
              .plus(cri(`${production}138`))
              .plus(cri(`${production}139`))
              .plus(cri(`${production}140`))
              .plus(cri(`${production}141`))
              .plus(cri(`${production}142`))
              .plus(cri(`${production}145`))
          ),
        new BigNumber(0)
      ),
    databaseResolver: {
      resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) => {
        const ceLignes = [
          205_300, // Travaux à forfait cult. (contenu M.O. max 70%)   PO98
          205_400, // Forfait géné. répartis (service suivi de cult.)  PO99
          205_500, // Chauffage électricité                            PO100
          205_550, // Chauffage au gas propane                         PO102
          205_600, // Chauffage gaz naturel                            PO104
          205_650, // chauffage au bois                                PO106
          205_700, // Chauffage huile                                  PO108
          205_750, // Chauffage au charbon                             PO110
          205_800, // Chauffage autres                                 PO112
          205_900, // Électricité pour éclairage artificiel            PO114
          206_000, // Entr. et loc. des équip. de culture- serre       PO116
          206_100, // Entr. loc. équip. de cult.-champs (inc. mat.Rou) PO118
          206_200, // Carburant matériel roulant production champs     PO119
          206_300, // Entretien et location équipement d'irrigation    PO121
          206_400, // Entr. et loc., équip. d'entrep. et hivernage     PO122
          206_500, // Fournitures pour trav. de cult. & autres directs PO123
          206_600, // Entretien et location serres                     PO124
          206_700, // Entr. et répar. matériel roulant usage général   PO125
          206_800, // Enreg., assurances, permis m.r. usage général    PO126
          206_900, // Carburant matériel roulant usage général         PO127
          207_100, // Transport à forfait livraison                    PO129
          207_200, // Entr. et réparation matériel roulant livraison   PO130
          207_300, // Enreg., assurances, permis m.r. livraison        PO131
          207_400, // Carburant matériel roulant livraison             PO132
          207_500, // Frais de ventes et commissions à forfait         PO134
          207_600, // Frais de cartes de crédit et cartes débit        PO135
          207_700, // Autres frais directs de mise en marché et distr. PO136
          207_900, // Entr. des équip. ventes(tables, arrosage,etc.)   PO137
          208_000, // Entretien matériel et équipement informatique    PO138
          208_100, // Location matériel et équipement informatique     PO139
          208_200, // Publicité, promotion                             PO140
          208_300, // Catalogue, affichage                             PO141
          208_400, // Autres dépenses marketing et ventes fixes        PO142
          208_600, // Service externe et frais bancaires               PO145
        ]
        return sumBy(comptesExploitation({ production: nurseryProductions, ligne: ceLignes }), 'montant')
      },
    },
  },
  'commercial.nursery.expenses.imported.supplies.direct': expensesSuppliesDirectResolverForNurseryProductions(),
  'commercial.nursery.expenses.imported.supplies.resale': {
    testResolver: false,
    databaseResolver: {
      resolve: () => new BigNumber(0),
    },
  },
  'commercial.nursery.income.imported.miscellaneous': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(3376),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation, courtsTermes }) => {
        const ceLignes = [
          200_700, // Ventes de services main d'oeuvre           PO21
          200_800, // Autres revenus                             PO23
          200_900, // Esc. accordés sur ventes (chiffre négatif) PO24
          200_950, // Autres programmes                          PO25
        ]
        return sumBy(comptesExploitation({ type_ligne: 'PO', ligne: ceLignes }), 'montant').plus(
          sumBy(courtsTermes({ type_ligne: 'GQ', type: ['CR', 'FP', 'CP', 'FR'] }), 'profit_perte')
        )
      },
    },
  },
  'commercial.nursery.income.imported.sales.products': {
    testResolver: ({ cri }) =>
      nurseryProductions.reduce(
        (acc, production) =>
          acc
            .plus(cri(`${production}9`))
            .minus(cri(`${production}11`))
            .plus(cri(`${production}13`))
            .plus(cri(`${production}15`))
            .plus(cri(`${production}17`))
            .minus(cri(`${production}18`))
            .plus(cri(`${production}19`)),
        new BigNumber(0)
      ),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const sumVentes = sumBy(
          comptesExploitation({
            production: nurseryProductions,
            ligne: [
              200_100, // Ventes externes produits des cultures PO9
              200_300, // Ventes achat/revente de plantes       PO13
              200_350, // Vente de plants mère                  PO15
              200_400, // Ventes nettes d'autres produits       PO17
              200_600, // Ventes de matières premières          PO19
            ],
          }),
          'montant'
        )
        const sumVentesInternes = sumBy(
          comptesExploitation({
            production: nurseryProductions,
            ligne: [
              200_200, // Ventes internes produits des cultures PO11
              200_500, // Ventes internes autres produits       PO18
            ],
          }),
          'montant'
        )
        return sumVentes.minus(sumVentesInternes)
      },
    },
  },
  'commercial.quarry.expenses.imported.marketing': marketingExpenseResolver('T4'),
  'commercial.quarry.expenses.imported.miscellaneous': miscellaneousExpenseResolver('T4'),
  'commercial.quarry.expenses.imported.supplies.direct': expensesSuppliesDirectResolverForCommercialProductions('T4'),
  'commercial.quarry.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('T4'),
  'commercial.quarry.income.imported.inventory': inventoryIncomeResolver('T4'),
  'commercial.quarry.income.imported.miscellaneous': miscellaneousIncomeResolver('T4'),
  'commercial.quarry.income.imported.sales.products': productSalesIncomeResolver('T4'),
  'commercial.snow-clearing.expenses.imported.marketing': marketingExpenseResolver('T5'),
  'commercial.snow-clearing.expenses.imported.miscellaneous': miscellaneousExpenseResolver('T5'),
  'commercial.snow-clearing.expenses.imported.supplies.direct':
    expensesSuppliesDirectResolverForCommercialProductions('T5'),
  'commercial.snow-clearing.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('T5'),
  'commercial.snow-clearing.income.imported.inventory': inventoryIncomeResolver('T5'),
  'commercial.snow-clearing.income.imported.miscellaneous': miscellaneousIncomeResolver('T5'),
  'commercial.snow-clearing.income.imported.sales.products': productSalesIncomeResolver('T5'),
  'commercial.wood.expenses.imported.marketing': {
    testResolver: 'IS27',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'IS', ligne: 32_400 }), 'montant'),
    },
  },
  'commercial.wood.expenses.imported.miscellaneous': {
    testResolver: false,
    databaseResolver: {
      resolve: () => new BigNumber(0),
    },
  },
  'commercial.wood.expenses.imported.supplies.direct': expensesSuppliesDirectResolverForWoodProduction(),
  'commercial.wood.expenses.imported.supplies.resale': {
    testResolver: ({ cri }) => cri('IS35'),
    databaseResolver: {
      resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) =>
        sumBy(comptesExploitation({ production: 'IS', ligne: 33_040 }), 'montant'), // Achat contrats de coupe
    },
  },
  'commercial.wood.income.imported.inventory': {
    testResolver: ({ cri }) =>
      cri('IS49').plus(cri('IS51')).plus(cri('IS53')).minus(cri('IS43')).minus(cri('IS45')).minus(cri('IS47')),
    databaseResolver: {
      resolve: ({ fichesTechniques }) => {
        const amountEndLines = [
          31_000, // Inv. fin bois sciage    IS49
          31_200, // Inv. fin bois pulpe     IS51
          31_400, // Inv. fin bois chauffage IS53
        ]
        const endAmount = sumBy(fichesTechniques({ production: 'IS', ligne: amountEndLines }), 'valeur')

        const amountBeginLines = [
          30_400, // Inv. déb. bois sciage    IS43
          30_600, // Inv. déb. bois pulpe     IS45
          30_800, // Inv. déb. bois chauffage IS47
        ]
        const beginAmount = sumBy(fichesTechniques({ production: 'IS', ligne: amountBeginLines }), 'valeur')

        return endAmount.minus(beginAmount)
      },
    },
  },
  'commercial.wood.income.imported.miscellaneous': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(32_052),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation, courtsTermes }) => {
        const ceLignes = [
          30_500, // Subv. d'aménagement        IS12
          30_600, // Travaux sylvicoles forfait IS13
          30_700, // Travaux de coupe forfait   IS14
          30_800, // Loc. de débusqueuse        IS15
          30_900, // Péréquation au transport   IS16
          31_000, // Autres revenus directs     IS17
        ]
        return sumBy(comptesExploitation({ production: 'IS', ligne: ceLignes }), 'montant').plus(
          sumBy(courtsTermes({ production: 'IS', type: ['CP', 'CR', 'FP', 'FR'] }), 'profit_perte') // IS60 & IS62
        )
      },
    },
  },
  'commercial.wood.income.imported.sales.products': {
    testResolver: ({ cri }) => cri('IS8').plus(cri('IS9')).plus(cri('IS10')).plus(cri('IS11')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          30_100, // Vte contrats de coupe IS8
          30_200, // Vte bois de sciage    IS9
          30_300, // Vte bois de pulpe     IS10
          30_400, // Vte bois de chauffage IS11
        ]
        return sumBy(comptesExploitation({ production: 'IS', ligne: ceLignes }), 'montant')
      },
    },
  },
})
