import { type EmptyObject } from 'type-fest'

import { type DataKey } from '../../data-key-zod'
import { type DataAttributes } from '../attributes'
import { dataKeyAttributes } from '../data-key-attributes'

type DataAttributesDependenciesRecordKey<TDataKeyAttribute> = TDataKeyAttribute extends {
  dependencies: Record<infer K, DataKey>
}
  ? K
  : never

type DataAttributesDependenciesRecord<TDataKeyAttribute> = TDataKeyAttribute extends {
  dependencies: infer RecDeps extends Record<string, DataKey>
}
  ? RecDeps
  : EmptyObject

export type DataKeyDependenciesRecordKey<TDataKey extends DataKey> = DataAttributesDependenciesRecordKey<
  (typeof DataAttributes)[TDataKey]
>

export type DataKeyDependenciesRecord<TDataKey extends DataKey> = DataAttributesDependenciesRecord<
  (typeof DataAttributes)[TDataKey]
>

const EMPTY = Object.freeze({})

export const dataKeyDependenciesRecord = <TDataKey extends DataKey>(
  dataKey: TDataKey
): DataKeyDependenciesRecord<TDataKey> => {
  const attributes = dataKeyAttributes(dataKey)
  if (!('dependencies' in attributes)) {
    return EMPTY as DataKeyDependenciesRecord<TDataKey>
  }
  if (typeof attributes.dependencies === 'object') {
    return attributes.dependencies as unknown as DataKeyDependenciesRecord<TDataKey>
  }
  return EMPTY as DataKeyDependenciesRecord<TDataKey>
}
