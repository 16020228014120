import { useIntl } from 'react-intl'

import { type MonetaryYearFormFieldTableBlueprint } from '../MonetaryYearFormFieldTable'

import { type MilkExpensesFormComputationState } from './MilkExpensesFormComputationProvider.tsx'

const MILK_EXPENSES_BLUEPRINT = {
  sections: [
    {
      id: 'references',
      rows: [
        { id: 'kilogram-per-hectoliter-start', type: 'divider', variant: 'default', border: 'top' },
        {
          id: 'fatContent',
          type: 'readonly',
          titleKey: 'table.milk.production.content.kilogram-per-hectoliter.fat',
          unit: 'kilogram-per-hectoliter',
          variant: 'group',
        },
        {
          id: 'proteinContent',
          type: 'readonly',
          titleKey: 'table.milk.production.content.kilogram-per-hectoliter.protein',
          unit: 'kilogram-per-hectoliter',
          variant: 'group',
        },
        {
          id: 'lactoseContent',
          type: 'readonly',
          titleKey: 'table.milk.production.content.kilogram-per-hectoliter.lactose',
          unit: 'kilogram-per-hectoliter',
          variant: 'group',
        },
        { id: 'kilogram-per-hectoliter-end', type: 'divider', variant: 'default', border: 'bottom' },
        { id: 'compute-start', type: 'divider', variant: 'reference', border: 'top' },
        {
          id: 'totalSolidContent',
          type: 'readonly',
          titleKey: 'milk.expenses.form.table.solid-total-content',
          unit: 'kilogram-per-hectoliter',
          variant: 'groupReference',
        },
        {
          id: 'totalSolidProduction',
          type: 'readonly',
          titleKey: 'milk.expenses.form.table.solid-total-production',
          unit: 'kilogram',
          variant: 'groupReference',
          formatNumberOptions: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          },
        },
        { id: 'reference-end', type: 'divider', variant: 'reference', border: 'bottom' },
      ],
    },
    {
      id: 'expenses',
      rows: [
        {
          id: 'milkExpenses',
          type: 'readonly',
          titleKey: 'table.milk.marketing.currency-per-hectoliter',
          unit: 'currency-per-hectoliter',
          variant: 'head',
        },
        {
          id: 'defense',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-kilogram',
          titleKey: 'table.milk.marketing.currency-per-kilogram.defense-fund',
          formatNumberOptions: {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          },
        },
        {
          id: 'advertising',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-kilogram',
          titleKey: 'table.milk.marketing.currency-per-kilogram.advertising',
          formatNumberOptions: {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          },
        },
        {
          id: 'development',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-kilogram',
          titleKey: 'table.milk.marketing.currency-per-kilogram.development-fund',
          formatNumberOptions: {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          },
        },
        {
          id: 'marketing',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-hectoliter',
          titleKey: 'table.milk.marketing.currency-per-hectoliter.marketing-fund',
          formatNumberOptions: {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          },
        },
        {
          id: 'transport',
          type: 'data',
          variant: 'default',
          unit: 'currency-per-hectoliter',
          titleKey: 'table.milk.marketing.currency-per-hectoliter.transport',
          bottomBorder: 'close',
          formatNumberOptions: {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          },
        },
      ],
    },
  ],
} as const

export type MilkExpensesFormTableBlueprintKeys = keyof MilkExpensesFormComputationState

export const useMilkExpensesBlueprint = () => {
  const intl = useIntl()

  return {
    sections: MILK_EXPENSES_BLUEPRINT.sections.map((section) => ({
      ...section,
      rows: section.rows.map((row) => {
        switch (row.type) {
          case 'divider':
            return row
          default: {
            const { titleKey, ...data } = row
            return {
              ...data,
              title: intl.formatMessage({ id: titleKey }),
            }
          }
        }
      }),
    })),
  } as const satisfies MonetaryYearFormFieldTableBlueprint<MilkExpensesFormTableBlueprintKeys>
}
