import { z } from 'zod'

export const MilkIncomeComputationTypeZod = z.enum([
  'nonFatSolidRatio', // ratio
  'proteinExcessLevel1', // kg/hl
  'solidExcessLevel1', // kg/hl
  'proteinExcessLevel2', // kg/hl
  'solidExcessLevel2', // kg/hl
  'basePrice', // $/hl
  'applicableBonusBio', // $/hl
  'applicableBonusQuality', // $/hl
  'applicableBonusOther', // $/hl
  'applicableBonus', // $/hl
  'price', // $/hl
  'salesRevenue', // $
  'grossDividends', // $
  'dividendsRevenueProportion', // percentage
  'dividendsRevenue', // $
  'dividendsAssetValue', // $
  'dividendsTax', // $
  'total', // $
])
export type MilkIncomeComputationType = z.infer<typeof MilkIncomeComputationTypeZod>
