import type React from 'react'

import { useOfflineState } from '../offlineState/useOfflineState.ts'

import { OnlineClientListProvider } from './OnlineClientListProvider.tsx'

export const ClientListProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { offline } = useOfflineState()
  return offline ? children : <OnlineClientListProvider>{children}</OnlineClientListProvider>
}
