import { BigNumber } from 'bignumber.js'

import { foodExpenses } from '../productions/utils'
import { createBudgetDefinition, ViagritelApportRetraitLigneNo } from '../types'
import { sumBy } from '../utils'
import {
  ViagritelAlimentsColonne,
  ViagritelProductions,
  ViagritelTypeLigneInventairesApprovisionnement,
} from '../values'

export const repaymentCapacity = createBudgetDefinition({
  'inflows.investment.imported.capital.subsidies': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ apportsRetraits }) =>
        apportsRetraits({ apport_retrait_ligne_no: ViagritelApportRetraitLigneNo.SubventionDeCapital }).reduce(
          (acc, apportRetrait) => acc.plus(apportRetrait.montant),
          new BigNumber(0)
        ),
    },
  },
  'inflows.investment.imported.shares': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ apportsRetraits }) => {
        const achats = sumBy(
          apportsRetraits({ apport_retrait_ligne_no: ViagritelApportRetraitLigneNo.AchatDeParts }),
          'montant'
        )
        const ventes = sumBy(
          apportsRetraits({ apport_retrait_ligne_no: ViagritelApportRetraitLigneNo.VenteDeParts }),
          'montant'
        )

        return ventes.minus(achats)
      },
    },
  },
  'income.imported.inventory.supplies.total': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ inventairesApprovisionnements }) => {
        const allInventories = inventairesApprovisionnements({
          type_ligne: [...ViagritelTypeLigneInventairesApprovisionnement],
        })
        const inventoriesEndValue = sumBy(allInventories, 'fin_dollars')
        const inventoryStartValue = sumBy(allInventories, 'debut_dollars')
        const totalInventory = inventoriesEndValue.minus(inventoryStartValue)

        return totalInventory
      },
    },
  },
  'income.imported.inventory.food.total': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ aliments }) =>
        foodExpenses(
          aliments({
            production: [...ViagritelProductions],
            colonne: [...ViagritelAlimentsColonne],
          })
        ),
    },
  },
})
