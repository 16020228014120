import { useCallback, useEffect, useReducer, useRef } from 'react'

import { type DocumentData, onSnapshot, type Query } from 'firebase/firestore'

import {
  type CollectionReducerState,
  CollectionReducerStateInitial,
  collectionStateReducer,
} from './collectionStateReducer.ts'

export interface UseCollectionOptions<AppModelType = DocumentData, DbModelType extends DocumentData = DocumentData> {
  query: Query<AppModelType, DbModelType> | null
  onError?(error: unknown): void
}

export type UseCollectionResult<
  AppModelType = DocumentData,
  DbModelType extends DocumentData = DocumentData,
> = CollectionReducerState<AppModelType, DbModelType>

export const useCollection = <AppModelType = DocumentData, DbModelType extends DocumentData = DocumentData>({
  query,
  onError,
}: UseCollectionOptions<AppModelType, DbModelType>): UseCollectionResult<AppModelType, DbModelType> => {
  const [state, dispatch] = useReducer(
    collectionStateReducer<AppModelType, DbModelType>(),
    CollectionReducerStateInitial
  )

  const handleError = useCallback(
    (error: unknown) => {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error('useCollection', error)
      }
      // TODO: Sentry
      onError?.(error)
      dispatch({ type: 'error', error })
    },
    [onError]
  )

  const queryRef = useRef(query)
  useEffect(() => {
    if (!queryRef.current) {
      dispatch({ type: 'disable' })
      return
    }
    // eslint-disable-next-line consistent-return
    return onSnapshot(
      queryRef.current,
      (snapshot) => {
        try {
          const data = snapshot.docs.map((doc) => doc.data())
          dispatch({ type: 'data', snapshot, data })
        } catch (error) {
          handleError(error)
        }
      },
      handleError
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleError, queryRef.current])

  return state
}
