import { useMemo } from 'react'

import { bigNumberMilkQuotaRecord, previousYear, tupleToRecord } from '@via/compute'
import { MilkQuotaComputationTypeZod, type YearKey } from '@via/schema'
import { BigNumber } from 'bignumber.js'
import dayjs from 'dayjs'

import { useScenario } from '../../context'
import { useScenarioSingleRowData } from '../../context/scenario/useScenarioSingleRowData.ts'

export const useMilkPreviousValues = (year: YearKey) => {
  const milkRowData = useScenarioSingleRowData<'milk-quota'>('milk-quota')
  const { milkQuota = {}, startOfYearOffset, period } = useScenario()
  return useMemo(() => {
    const previous = previousYear(year)
    if (!milkRowData || !previous) {
      return bigNumberMilkQuotaRecord({})
    }

    const targetDayJsDate = dayjs(year, 'YYYY')
      .startOf('year')
      .add(startOfYearOffset ?? 0, 'months')
    const milkRowDataBefore = Object.entries(milkQuota)
      .filter(([key]) => targetDayJsDate.isAfter(dayjs(key, 'YYYY-MM')))
      .map(([, value]) => value)

    const values = period.projectionYears.includes(previous) ? milkRowData.values[previous] : milkRowData.reference
    return tupleToRecord(MilkQuotaComputationTypeZod.options, (type) => {
      switch (type) {
        case 'rental':
        case 'rentalCost':
          return milkRowDataBefore.reduce((acc, row) => (row[type] ? new BigNumber(row[type]) : acc), new BigNumber(0))
        default:
          return new BigNumber(values?.[type]?.value ?? 0)
      }
    })
  }, [milkQuota, milkRowData, period.projectionYears, startOfYearOffset, year])
}
