import { createContext } from 'react'

import { type DataKey, type YearKey } from '@via/schema'

import { type OverridableCellUpdateValue } from '../../../model'
import { type InventoryProjectionUpdateValue } from '../../../monetary/InventoryMovementProjectionWidget/InventoryProjectionWidgetFormProvider.tsx'

export type OnUpdateCellParams<TKey extends DataKey = DataKey> =
  | ({ readonly type: 'growth' | 'reference'; readonly dataKey: TKey } & OverridableCellUpdateValue)
  | ({
      readonly type: 'overridable-reference'
      readonly dataKey: TKey
      readonly year: YearKey | 'latest' | 'selected'
    } & OverridableCellUpdateValue)
  | ({
      readonly type: 'result-projections' | 'movement-overridable'
      readonly dataKey: TKey
      readonly year: YearKey
    } & OverridableCellUpdateValue)
  | ({
      readonly type: 'inventory'
      readonly dataKey: TKey
      readonly year: YearKey
    } & InventoryProjectionUpdateValue)

export type OnUpdateCell = (params: OnUpdateCellParams) => Promise<void> | void
export interface MonetaryTableActions {
  onUpdateCell?: OnUpdateCell
  onReferenceChange?: (reference: YearKey) => Promise<void> | void
}

export const MonetaryTableActionsContext = createContext<MonetaryTableActions>({})
