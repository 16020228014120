import { type FC } from 'react'

import { type DataKey } from '@via/schema'
import { type Except } from 'type-fest'

import { useScenario } from '../../context'
import { InvestmentForm, type InvestmentFormProps } from '../InvestmentForm/InvestmentForm.tsx'
import { InvestmentInventoryForm } from '../InvestmentInventoryForm/InvestmentInventoryForm.tsx'
import { MilkQuotaInvestmentForm } from '../MilkQuotaInvestmentForm'

export type InvestmentDetailProps = Except<InvestmentFormProps, 'blueprintVersion' | 'enabledProductions'> & {
  readonly dataKey: DataKey
  readonly computationType: 'finance-investment' | 'finance-loan-investment' | 'finance-investment-inventory'
}

export const InvestmentDetail: FC<InvestmentDetailProps> = ({ dataKey, computationType, ...props }) => {
  const { blueprintVersion, enabledProductions } = useScenario()

  if (computationType === 'finance-investment-inventory') {
    return <InvestmentInventoryForm dataKey={dataKey} />
  }

  switch (props.investmentId) {
    case 'milk-quota-investment-purchase':
      return <MilkQuotaInvestmentForm type="purchase" />
    case 'milk-quota-investment-sale':
      return <MilkQuotaInvestmentForm type="sale" />
    case 'milk-income-investment':
      return null
    default:
      return <InvestmentForm {...props} blueprintVersion={blueprintVersion} enabledProductions={enabledProductions} />
  }
}
