import { type DataKeyDependencies } from '@via/schema'

export const MilkExpensesDependencies = {
  fatContent: 'milk.production.content.kilogram-per-hectoliter.fat',
  proteinContent: 'milk.production.content.kilogram-per-hectoliter.protein',
  lactoseContent: 'milk.production.content.kilogram-per-hectoliter.lactose',
  defense: 'milk.marketing.currency-per-kilogram.defense-fund',
  advertising: 'milk.marketing.currency-per-kilogram.advertising',
  development: 'milk.marketing.currency-per-kilogram.development-fund',
  marketing: 'milk.marketing.currency-per-hectoliter.marketing-fund',
  transport: 'milk.marketing.currency-per-hectoliter.transport',
} as const satisfies Record<string, DataKeyDependencies<'milk.marketing.currency-per-hectoliter'>>
