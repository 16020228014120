import { scenarioData } from '@via/components'
import { addSentryBreadcrumb } from '@via/frontend-sentry'
import { concatMap, retry, tap, timeout } from 'rxjs'

import { AppWorker } from '../../worker/AppWorker.ts'

import { firestoreScenarioData$, type FirestoreScenarioDataParams } from './firestoreScenarioData.ts'

export const firestoreScenarioDataWithRows$ = (params: FirestoreScenarioDataParams) =>
  firestoreScenarioData$(params).pipe(
    tap(({ scenario }) => {
      if (import.meta.env.DEV) {
        performance.mark(`firestore-scenario-data:${scenario.currentStateId}:start-compute`)
      }
    }),
    concatMap(async ({ scenarioState, scenario, reference }) => {
      const rows = await AppWorker.instance.computeAllRows(scenario.budgetId, scenario._id, scenarioState, reference)
      return {
        scenario: scenarioData(scenario, scenarioState, reference),
        rows,
      }
    }),
    tap(({ scenario }) => {
      if (import.meta.env.DEV) {
        performance.mark(`firestore-scenario-data:${scenario.currentStateId}:end`)
        const measure = performance.measure(
          `firestore-scenario-data:${scenario.currentStateId}`,
          `firestore-scenario-data:${scenario.currentStateId}:start`,
          `firestore-scenario-data:${scenario.currentStateId}:end`
        )
        // eslint-disable-next-line no-console
        console.info('firestoreScenarioData', measure)
      }
    }),
    timeout({ first: 3000 }),
    retry({ count: 1 }),
    addSentryBreadcrumb((data) => ({
      level: 'debug',
      category: 'firestore',
      message: 'scenario data',
      data,
    }))
  )
