import { createFileRoute } from '@tanstack/react-router'

import { MilkConfigurationPage } from '../pages/milkConfiguration/MilkConfigurationPage.tsx'
import { setScenarioCurrentPage } from '../pages/scenarioUpdate/utils/scenarioPage.ts'

export const Route = createFileRoute('/budgets/$budgetId/scenarios/$scenarioId/milk-configuration')({
  component: MilkConfigurationPage,
  loader: ({ params }) => {
    setScenarioCurrentPage(params.budgetId, params.scenarioId, 'milk-configuration')
  },
})
