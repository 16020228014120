import { z } from 'zod'

import { CellDataOverrideZod } from './cell-data-override-zod'
import { MonetaryValueZod } from './monetary-value-zod'

export const CellDataZod = z.object({
  value: MonetaryValueZod,
  note: z.string().optional(),
})

export const OverridableCellDataZod = CellDataZod.extend({
  baseValue: MonetaryValueZod.optional(),
  override: CellDataOverrideZod.optional(),
})
