import { getRxStorageWorker } from 'rxdb-premium/plugins/storage-worker'

import { initAppRxDatabase } from './initAppRxDatabase.ts'

export const appRxDatabase = await initAppRxDatabase(
  getRxStorageWorker({
    workerOptions: {
      type: 'module',
      name: 'rxdb-indexeddb-worker',
      credentials: 'same-origin',
    },
    workerInput: '/workers/indexeddb.worker.js',
  })
)
