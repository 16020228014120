import { cva } from 'class-variance-authority'

export const navigationBarLinkVariants = cva(
  'flex select-none items-center justify-center gap-1 rounded font-medium transition-colors disabled:pointer-events-none disabled:border-gray-200 disabled:bg-gray-300 disabled:text-gray-50 disabled:shadow-none h-8 text-sm tracking-[-0.42px] leading-[0.5] px-2',
  {
    variants: {
      active: {
        true: 'border border-gray-200 bg-gray-700 text-white',
        false: 'border border-blue-400 bg-white text-black hover:bg-gray-100 active:bg-sky-100',
      },
    },
    defaultVariants: {
      active: false,
    },
  }
)
