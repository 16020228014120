import { type FC, type ReactNode } from 'react'

import { Icons } from '../../atoms'
import { Button } from '../../atoms/Button/Button'
import { FormSubmitButtons } from '../../atoms/Form/FormSubmitButtons'

export interface SheetFormHeaderProps {
  readonly title: ReactNode
  readonly readonly?: boolean
  readonly disabled?: boolean
  readonly onDelete?: () => Promise<void> | void
}

export const SheetFormHeader: FC<SheetFormHeaderProps> = ({ title, readonly, disabled, onDelete }) => (
  <header className="flex w-full items-start justify-between py-4 pb-0 pl-6 pr-4">
    <p className="select-none text-2xl font-light leading-tight tracking-[-0.48px] text-black">{title}</p>
    {!readonly && (
      <div className="flex items-center gap-6">
        {onDelete && !disabled && (
          <Button dimension="xs" shape="iconOnly" variant="outline" onClick={onDelete}>
            <Icons.Trash className="size-4" />
          </Button>
        )}
        <FormSubmitButtons className="flex gap-2" disabled={disabled} />
      </div>
    )}
  </header>
)
