import { z } from 'zod'

import { PaymentFrequencyZod } from '../enums'

import { DateStringZod } from './date-string-zod'
import { DurationValueZod, GrowthValueZod, MonetaryValueZod } from './monetary-value-zod'

const LoanCategoryZod = z.enum(['agricultural', 'shareholders-due', 'other'])
export type LoanCategory = z.infer<typeof LoanCategoryZod>

export const ScenarioLoanStateZod = z.object({
  name: z.string().optional(),
  category: LoanCategoryZod.optional(),
  amount: MonetaryValueZod.optional(),
  interestRate: GrowthValueZod.optional(),
  duration: DurationValueZod.optional(),
  paymentAmount: MonetaryValueZod.optional(),
  paymentFrequency: PaymentFrequencyZod.optional(),
  startDate: z.string().optional(),
  note: z.string().optional(),
  durationWithoutInterestPaid: DurationValueZod.optional(),
  durationWithoutCapitalPaid: DurationValueZod.optional(),
  fixedCapitalPaymentAmount: MonetaryValueZod.optional(),
  newCapitalBorrowed: z
    .record(
      z.object({
        date: DateStringZod,
        value: MonetaryValueZod,
      })
    )
    .optional(),
  earlyRepayment: z
    .record(
      z.object({
        date: DateStringZod,
        value: MonetaryValueZod,
        withdrawal: MonetaryValueZod.optional(),
      })
    )
    .optional(),
  interestRateChange: z
    .record(
      z.object({
        date: DateStringZod,
        value: GrowthValueZod,
      })
    )
    .optional(),
})
