import { z } from 'zod'

import { BudgetReportContentsZod } from '../budgets'

import { ImageZod } from './image-zod'

export const ConfigurationZod = z.discriminatedUnion('_id', [
  z.object({
    _id: z.literal('EnableViagritelUserSignUp'),
    value: z.boolean().optional().default(true),
  }),
  z.object({
    _id: z.literal('DefaultReportContentsInitialValue'),
    value: BudgetReportContentsZod.optional().default({}),
  }),
  z.object({
    _id: z.literal('ReportCoverImages'),
    value: z.array(ImageZod),
  }),
  z.object({
    _id: z.literal('ReportGroupsImages'),
    value: z.record(z.object({ images: z.array(ImageZod) })),
  }),
  z.object({
    _id: z.literal('ViagritelLastSyncDateOverride'),
    value: z.date().nullish(),
  }),
])
