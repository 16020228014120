import { z } from 'zod'

import { UserUpdateZod } from '../../common'
import { DataEventZod } from '../../monetary'

export const ScenarioEventZod = z
  .object({
    previousEventId: z.string(),
  })
  .and(UserUpdateZod)
  .and(DataEventZod)
