import React, { useContext, useEffect, useMemo } from 'react'

import { collections } from '@via/frontend-schema'
import { handleNotAwaitedPromise } from '@via/frontend-sentry'
import { omit } from 'lodash-es'
import { type DistributedOmit } from 'type-fest'
import { type SchemaDocumentOutput } from 'zod-firebase'

import { useAuthenticatedFirebaseAuth } from '../../auth/useFirebaseAuth.ts'
import { AppRxDatabaseContext } from '../../rxdb/contexts/AppRxDatabaseContext.ts'
import { deepDateToString } from '../../rxdb/utils/deepDateToString.ts'
import { CollectionReducerStateInitial } from '../hooks/collectionStateReducer.ts'
import { useCollection, type UseCollectionResult } from '../hooks/useCollection.ts'

export type FirestoreClientDocument = SchemaDocumentOutput<typeof collections.clients>
export const FirestoreClientSnapshotContext =
  React.createContext<DistributedOmit<UseCollectionResult<FirestoreClientDocument>, 'snapshot'>>(
    CollectionReducerStateInitial
  )

export const FirestoreClientSnapshotProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const appDatabase = useContext(AppRxDatabaseContext)
  const { user } = useAuthenticatedFirebaseAuth()
  const queryState = useCollection({
    query: collections.clients.prepare({
      name: 'current user client list',
      where: [['members', 'array-contains', user.uid]],
      orderBy: [['code', 'asc']],
    }),
  })

  const state = useMemo(
    () =>
      ({
        ...omit(queryState, 'snapshot'),
        data: queryState.data.filter((client) => !client._deleted),
      }) as DistributedOmit<UseCollectionResult<FirestoreClientDocument>, 'snapshot'>,
    [queryState]
  )

  useEffect(() => {
    if (!state.hasError && !state.isLoading) {
      handleNotAwaitedPromise(
        appDatabase.snapshots.upsertLocal('clients', {
          ...deepDateToString(state),
          epochTimestamp: Date.now(),
        })
      )
    }
  }, [state, appDatabase])

  return <FirestoreClientSnapshotContext.Provider value={state}>{children}</FirestoreClientSnapshotContext.Provider>
}
