import type React from 'react'

import { MilkResultsView } from '@via/components'

import { useMilkResultsPage } from './useMilkResultsPage.ts'

export const MilkResultsPage: React.FC = () => {
  const props = useMilkResultsPage()
  return <MilkResultsView key={props.scenario._id} {...props} />
}
