import { createContext } from 'react'

export const OfflineStateContext = createContext<
  | {
      readonly forceOffline: true
      readonly offline: true
    }
  | {
      readonly forceOffline: false
      readonly offline: boolean
    }
  | null
>(null)
