import { type FC } from 'react'

import { times } from 'lodash-es'

import { LineDivider, type LineDividerProps } from '../../../atoms/LineDivider/LineDivider.tsx'
import { LineFormulaStrip } from '../../../atoms/LineTitle/LineFormulaStrip.tsx'

export type MonthlyTableDividerRowProps = Pick<LineDividerProps, 'variant' | 'border'>

export const MonthlyTableDividerRow: FC<MonthlyTableDividerRowProps> = ({ variant, border }) => (
  <div className="flex shrink-0">
    <div className="flex w-48 shrink-0">
      <LineFormulaStrip variant="empty" />
      <LineDivider className="flex w-full" variant={variant} border={border} withDividerLeft></LineDivider>
    </div>
    {times(12, (i) => (
      <LineDivider
        key={i}
        className="flex w-16 shrink-0"
        variant={variant}
        border={border}
        withDividerRight></LineDivider>
    ))}
  </div>
)
