import { type FC, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { stringIsMessageId } from '../../../l10n'
import { MonetaryReadonlyValueCell } from '../../MonetaryValueCell/MonetaryReadonlyValueCell.tsx'
import { type MonetaryTableColumn, type MonetaryTableValueLine } from '../types.ts'

import { cellVariant } from './cellVariant.ts'

export interface MonetaryTableLabelCellProps {
  readonly column: MonetaryTableColumn
  readonly line: MonetaryTableValueLine
  readonly label?: string
  readonly loading?: boolean
}

export const MonetaryTableLabelCell: FC<MonetaryTableLabelCellProps> = ({ line, label, column, ...props }) => {
  const intl = useIntl()

  const cellData = useMemo(
    () => ({
      value: label && stringIsMessageId(label) ? intl.formatMessage({ id: label }) : (label ?? ''),
    }),
    [intl, label]
  )
  return (
    <MonetaryReadonlyValueCell
      cellData={cellData}
      variant={cellVariant(line, column)}
      className={column.className}
      closingSection={line.closingSection}
      {...props}
    />
  )
}
