import { captureException } from '@sentry/react'
import { collections } from '@via/frontend-schema'
import { addSentryBreadcrumb, handleNotAwaitedPromise } from '@via/frontend-sentry'
import { difference, isEqual } from 'lodash-es'
import {
  catchError,
  distinctUntilChanged,
  EMPTY,
  first,
  groupBy,
  map,
  mergeMap,
  type Observable,
  of,
  pairwise,
  retry,
  skipWhile,
  startWith,
  switchMap,
} from 'rxjs'
import { type SchemaDocumentOutput } from 'zod-firebase'

import { fromQuery } from '../../firestore/rxjs/fromQuery.ts'
import { scenarioSyncState } from '../../rxdb/app/sync/scenarioSyncState.ts'
import { type AppRxDatabase } from '../../rxdb/types.ts'
import { incrementalUpdate } from '../../rxdb/utils/incrementalUpdate.ts'

type Scenario = SchemaDocumentOutput<typeof collections.budgets.scenarios>

export const pullScenarios$ = (appDatabase: AppRxDatabase): Observable<Scenario> =>
  appDatabase.budgets.find({ selector: { hasSyncError: false } }).$.pipe(
    map((docs) => docs.map((doc) => doc._id)),
    distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
    startWith([]),
    pairwise(),
    mergeMap(([prev, curr]) => {
      const start = difference(curr, prev).map((budgetId) => ({ type: 'start-snapshot' as const, budgetId }))
      const stop = difference(prev, curr).map((budgetId) => ({ type: 'stop-snapshot' as const, budgetId }))
      return [...start, ...stop]
    }),
    groupBy(({ budgetId }) => budgetId),
    mergeMap((group) =>
      group.pipe(
        addSentryBreadcrumb((value) => ({
          type: 'debug',
          category: 'firestore',
          message: value.type === 'start-snapshot' ? 'start scenario snapshot' : 'stop scenario snapshot',
          data: { budgetId: group.key },
        })),
        switchMap((value) => {
          if (value.type === 'start-snapshot') {
            return fromQuery(
              collections.budgets(group.key).scenarios.prepare({
                name: `Active scenarios for budget ${group.key}`,
                where: [['archived', '==', false]],
              }),
              {
                includeMetadataChanges: true,
              }
            ).pipe(
              retry({ count: 3, delay: 200 }),
              catchError((error) => {
                handleNotAwaitedPromise(
                  incrementalUpdate(appDatabase.budgets.findOne(group.key), { $set: { hasSyncError: true } })
                )
                captureException(error, {
                  tags: { 'sync.pull': 'scenario' },
                  contexts: { budget: { _id: group.key } },
                })
                return EMPTY
              })
            )
          }
          return EMPTY
        }),
        addSentryBreadcrumb((snapshot) => ({
          category: 'firestore',
          message: `received snapshot for (/budgets/${group.key}/scenarios/)`,
          data: { snapshot },
        })),
        skipWhile((snapshot) => snapshot.metadata.hasPendingWrites || snapshot.metadata.fromCache),
        mergeMap((snapshot) => snapshot.docs.map((doc) => doc.data())),
        mergeMap((scenario) =>
          scenarioSyncState(appDatabase, scenario.budgetId, scenario._id).$.pipe(
            first(),
            addSentryBreadcrumb((syncState) => ({
              type: 'debug',
              category: 'pull scenarios',
              message: `scenario sync state (/budgets/${scenario.budgetId}/scenarios/${scenario._id})`,
              data: {
                scenario,
                syncState,
              },
            })),
            switchMap((syncState) =>
              syncState?.mustSync || syncState?.snapshot?.stateId === scenario.currentStateId ? EMPTY : of(scenario)
            )
          )
        ),
        addSentryBreadcrumb((scenario) => ({
          category: 'pull scenarios',
          message: `updating (/budgets/${scenario.budgetId}/scenarios/${scenario._id})`,
          data: {
            scenario,
          },
        })),
        catchError((error) => {
          handleNotAwaitedPromise(
            incrementalUpdate(appDatabase.budgets.findOne(group.key), { $set: { hasSyncError: true } })
          )
          captureException(error, {
            tags: { 'sync.pull': 'scenario' },
            contexts: { budget: { _id: group.key } },
          })
          return EMPTY
        })
      )
    )
  )
