import { createContext } from 'react'

import { MilkExpensesDependencies } from '@via/compute'

import { type OverridableMonetaryCellData } from '../../model'

export const MilkExpensesFormDependencies = {
  ...MilkExpensesDependencies,
  volume: 'milk.production.volume.hectoliter.total',
} as const

export type MilkExpensesFormDependenciesState = Partial<
  Record<keyof typeof MilkExpensesFormDependencies, OverridableMonetaryCellData>
>

export const MilkExpensesFormDependenciesContext = createContext<MilkExpensesFormDependenciesState>({})
