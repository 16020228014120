import { createContext } from 'react'

import { type RowData } from '@via/compute'

export interface ScenarioRowsContextValue {
  rows: ReadonlyArray<RowData>
  loading: boolean
}

export const ScenarioRowsContext = createContext<ScenarioRowsContextValue>({
  rows: [],
  loading: false,
})
