import { useIntl } from 'react-intl'

import { Icons } from '../atoms'

export const useClientBudgetListDataTableOptions = (offline: boolean) => {
  const intl = useIntl()

  return {
    actionOptions: (isBudgetReadonly?: boolean, isAvailableOffline?: boolean) =>
      [
        {
          disabled: offline,
          value: 'duplicate',
          text: intl.formatMessage({ id: 'clientList.action.duplicate' }),
          confirmLabel: intl.formatMessage({ id: 'budgetConfiguration.action.duplicate.confirmationDialog.confirm' }),
          cancelLabel: intl.formatMessage({ id: 'budgetConfiguration.action.duplicate.confirmationDialog.close' }),
          title: intl.formatMessage({ id: 'budgetConfiguration.action.duplicate.confirmationDialog.title' }),
          subtitle: intl.formatMessage({ id: 'budgetConfiguration.action.duplicate.confirmationDialog.subTitle' }),
        },
        {
          disabled: offline || isBudgetReadonly,
          value: 'archive',
          text: intl.formatMessage({ id: 'clientList.action.archive' }),
          confirmLabel: intl.formatMessage({ id: 'budgetConfiguration.action.erase.confirmationDialog.confirm' }),
          cancelLabel: intl.formatMessage({ id: 'budgetConfiguration.action.erase.confirmationDialog.close' }),
          title: intl.formatMessage({ id: 'budgetConfiguration.action.erase.confirmationDialog.title' }),
          subtitle: intl.formatMessage({ id: 'budgetConfiguration.action.erase.confirmationDialog.subTitle' }),
        },
        {
          disabled: offline || isBudgetReadonly,
          value: isAvailableOffline ? 'close' : 'open',
          text: (
            <div className="flex items-center gap-1.5">
              <p>{intl.formatMessage({ id: 'clientList.action.open' })}</p>
              {isAvailableOffline && <Icons.Check className="size-6" />}
            </div>
          ),
        },
      ] as const,
  }
}
