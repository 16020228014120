import { forwardRef, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { type ScenarioInvestmentStateZod } from '@via/schema'
import { isEmpty, sortBy } from 'lodash-es'
import { type Except } from 'type-fest'
import { z } from 'zod'

import { useScenario } from '../../context'
import { localDate } from '../../date'
import { type MonetaryInvestmentData } from '../../model'
import { SheetFormProvider, type SheetFormProviderProps } from '../SheetFormProvider'

import { type InvestmentFormData, InvestmentFormZod } from './InvestmentFormZod'
import { mapInvestmentFormToScenarioState } from './mapInvestmentFormToScenarioState'

export type InvestmentFormSubmitData = z.input<typeof ScenarioInvestmentStateZod> & {
  readonly investmentId: string
}

export interface InvestmentFormProviderProps
  extends Except<
    SheetFormProviderProps<typeof InvestmentFormZod>,
    'schema' | 'defaultValues' | 'onFormSubmit' | 'onFormApply' | 'title'
  > {
  readonly investmentId: string
  readonly onSubmit: (value: InvestmentFormSubmitData) => Promise<void> | void
  readonly onApply: (value: InvestmentFormSubmitData) => Promise<void> | void
}

const investmentFormData = (investment: MonetaryInvestmentData): InvestmentFormData => {
  const { type } = investment ?? {}

  const subvention = sortBy(Object.entries(investment?.subvention ?? {}), '[1].date').reduce(
    (acc, [id, { amount, date }], index) => ({
      ...acc,
      [id]: {
        index,
        value: amount,
        date,
      },
    }),
    {} as InvestmentFormData['subvention']
  )
  const disbursement = sortBy(Object.entries(investment?.disbursement ?? {}), '[1].date').reduce(
    (acc, [id, { amount, date }], index) => ({
      ...acc,
      [id]: {
        index,
        value: amount,
        date,
      },
    }),
    {} as InvestmentFormData['disbursement']
  )
  return InvestmentFormZod.parse({
    payment: investment?.payment,
    description: investment?.description,
    transactionDate: investment?.transactionDate ? localDate(investment.transactionDate) : '',
    note: investment?.note,
    category: investment?.category,
    ...(type && 'type' in type ? { type: type.type } : {}),
    ...(type && 'subtype' in type ? { subtype: type.subtype } : {}),
    units: investment?.units,
    amortization: investment?.amortization,
    pricePerUnit: investment?.pricePerUnit,
    duration: investment?.duration,
    paymentFrequency: investment?.paymentFrequency,
    rate: investment?.rate,
    subvention,
    disbursement,
    enabledOptions: [
      ...(!isEmpty(subvention) ? ['subvention'] : []),
      ...(!isEmpty(disbursement) ? ['disbursement'] : []),
    ],
  })
}

export const InvestmentFormProvider = forwardRef<HTMLFormElement, InvestmentFormProviderProps>(
  ({ investmentId, onSubmit, onApply, children, ...props }, ref) => {
    const intl = useIntl()
    const { investments } = useScenario()
    const values = useMemo(
      () => investmentFormData(investmentId ? (investments?.[investmentId] ?? {}) : {}),
      [investmentId, investments]
    )

    const onFormSubmit = useCallback(
      async (data: InvestmentFormData) => {
        await onSubmit({
          investmentId,
          ...mapInvestmentFormToScenarioState(data),
        })
      },
      [investmentId, onSubmit]
    )

    const onFormApply = useCallback(
      async (data: InvestmentFormData) => {
        await onApply({
          investmentId,
          ...mapInvestmentFormToScenarioState(data),
        })
      },
      [investmentId, onApply]
    )

    const InvestmentWithValidationFormZod = InvestmentFormZod.superRefine((investment, ctx) => {
      if (['purchase', 'sale', 'financing'].includes(investment.category)) {
        if (!investment.type) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intl.formatMessage({ id: 'form.required' }),
            path: ['type'],
          })
          return
        }

        if (!investment.subtype) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intl.formatMessage({ id: 'form.required' }),
            path: ['subtype'],
          })
        }
      }

      if (investment.category === 'financing' && !investment.duration) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage({ id: 'form.required' }),
          path: ['duration'],
        })
      }

      const payment = Number(investment.payment)
      if (Number.isNaN(payment) || payment < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage({ id: 'investment.form.payment.required' }),
          path: ['payment'],
        })
      }

      const subventions = Object.values(investment.subvention)
      if (subventions.reduce((acc, { value }) => acc + Number(value), 0) > payment) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage({ id: 'investment.form.subvention.required' }),
          path: ['subvention'],
        })
      }
    })

    return (
      <SheetFormProvider<typeof InvestmentWithValidationFormZod>
        key={investmentId}
        {...props}
        title={intl.formatMessage({ id: 'investment.form.title' })}
        values={values}
        schema={InvestmentWithValidationFormZod}
        ref={ref}
        onFormSubmit={onFormSubmit}
        onFormApply={onFormApply}>
        {children}
      </SheetFormProvider>
    )
  }
)
