import React, { useContext } from 'react'

import { BudgetListContext } from '@via/components'
import { useRxQuery } from 'rxdb-hooks'

import { AppRxDatabaseContext } from '../../rxdb/contexts/AppRxDatabaseContext.ts'
import { rxDocumentData } from '../../rxdb/utils/rxDocumentData.ts'

export const OfflineBudgetListProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const database = useContext(AppRxDatabaseContext)
  const { result } = useRxQuery(database.budgets.find({ sort: [{ 'client.code': 'asc' }] }))
  const value = React.useMemo(
    () => ({
      data: result?.map(rxDocumentData) ?? [],
    }),
    [result]
  )
  return <BudgetListContext.Provider value={value}>{children}</BudgetListContext.Provider>
}
