import { type ReportData } from '@via/components'
import { type Remote } from 'comlink'

import { type BudgetReportsOperator } from './BudgetReportsOperator.ts'
import { type ReportCreateData } from './types.ts'

export class BudgetReportsHandler {
  constructor(private readonly operator: Remote<BudgetReportsOperator>) {}

  createBudgetReport = async (budgetId: string, data: ReportCreateData) =>
    this.operator.createBudgetReport(budgetId, data)

  updateBudgetReport = async (budgetId: string, documentId: string, data: Partial<ReportData>) =>
    this.operator.updateBudgetReport(budgetId, documentId, data)

  archiveReportFile = async (budgetId: string, reportId: string, fileId: string) =>
    this.operator.archiveReportFile(budgetId, reportId, fileId)
}
