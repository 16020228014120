import { BigNumber } from 'bignumber.js'

import { createBudgetDefinition } from '../types'
import { isFermeBudgitel2021, isFermeBudgitel2022, sumBy } from '../utils'
import {
  CerealProductions,
  type ViagritelProduction,
  type ViagritelTypeLigneCompteExploitation,
  type ViagritelTypeLigneInventaireApprovisionnement,
} from '../values'

// Dans les comptes d'exploitation:
// - le ce_type_ligne FO inclut les productions FO (foin), ME (maïs ensilage), PA (pâturage) et SO (sorgho)
// - le ce_type_ligne CE inclut toutes les productions céréalières
// - le ce_type_ligne GD n'inclut que la production Cult. non-détaillées
// - le ce_type_ligne MA inclut toutes les productions maraîchères

export const crops = createBudgetDefinition({
  'crops.income.imported.sales.products': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(18_139),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        // Aux CE des prodns CE,
        //   ligne Vte réc. précédente + ligne Vte réc. courante.+
        // Au CE des prodns FO,
        //   ligne Vte fourrages préc. + ligne Vte fourrages cour. +
        // Au CE Cult non détaillées,
        //   ligne Vente de paille précédente + ligne Vente paille courante
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          { type_ligne: 'CE', ligne: [100_110, 100_100] }, // Vte réc. courante + Vte réc. précédente     GC300
          { type_ligne: 'FO', ligne: [80_110, 80_100] }, // Vte fourrages cour. + Vte fourrages préc.
          { type_ligne: 'GD', ligne: [120_110, 120_100] }, // Vte paille courante + Vte paille précédente ZZ229
        ]
        return ceLignes.reduce(
          (acc, filter) => acc.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'crops.income.imported.insurance.general': {
    testResolver: ({ cri }) => cri('ZZ215').minus(cri('GL14')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) =>
        // Rev. ass. récolte
        sumBy(comptesExploitation({ ligne: 100_300, type_ligne: 'CE' }), 'montant').plus(
          sumBy(comptesExploitation({ ligne: 80_300, type_ligne: 'FO' }), 'montant')
        ),
    },
  },
  'crops.income.imported.insurance.stabilization': {
    testResolver: ({ cri }) => cri('ZZ216').minus(cri('GL15')),
    databaseResolver: {
      // Aux CE des productions CE, ligne Rev. ass. stabilisation
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ type_ligne: 'CE', ligne: 100_400 }), 'montant'),
    },
  },
  'crops.income.imported.inventory.crops': {
    testResolver: ({ cri }) => cri('ZZ144').minus(cri('ZZ143')),
    databaseResolver: {
      // Dans la fiche Céréales fourrages, section céréales, Valeur tot. fin $ - Valeur tot. début $ (Inventaire céréales fin - Inventaire céréales début)
      resolve: ({ cerealesFourrages }) =>
        cerealesFourrages({ type_production: 'CE' }).reduce(
          (acc, item) => acc.plus(item.valeur_totale_fin.minus(item.valeur_totale_debut)),
          new BigNumber(0)
        ),
    },
  },
  'crops.income.imported.inventory.forage': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(-3905),
      },
    ],
    databaseResolver: {
      // Var.inv.qté,prix fin: foin +
      // Var.inv.qté,prix fin: mais-ens +
      // Var.inv.qté,prix fin: sorgho +
      // Paille: var.inv. qté ($) +
      // Variation inventaire qté pâturage
      resolve: ({ cerealesFourrages }) =>
        cerealesFourrages({ type_production: ['FO', 'PL'] }).reduce((acc, item) => {
          const variation = item.inventaire_fin.minus(item.inventaire_debut).times(item.prix_unite_fin)
          return acc.plus(variation)
        }, new BigNumber(0)),
    },
  },
  'crops.income.imported.miscellaneous': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(-840),
      },
    ],
    databaseResolver: {
      // Au court terme.
      //  G/P sur C/P & Reçu d'av. global grandes cult +
      //  G/P sur C/R & Payé d'av. global grandes cult.+
      //  C/R G/P sur ASRA grandes cultures
      resolve: ({ courtsTermes }) =>
        sumBy(courtsTermes({ type_ligne: 'GC', type: ['CP', 'CR', 'FR', 'FP'] }), 'profit_perte').plus(
          sumBy(courtsTermes({ production: 'GC', type: ['CR', 'FR'] }), 'profit_perte')
        ),
    },
  },
  'crops.expenses.imported.purchases.straw': {
    testResolver: 'ZZ230',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ type_ligne: 'GD', ligne: 120_600 }), 'montant'), // Achat de paille ZZ230
    },
  },
  'crops.expenses.imported.purchases.forage': {
    testResolver: ({ cri }) =>
      (['FO', 'ME', 'SO', 'PA'] as ViagritelProduction[]).reduce(
        (acc, production) => acc.plus(cri(`${production}16`)).plus(cri(`${production}61`)),
        new BigNumber(0)
      ),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          80_800, // Achat de fourrages FO16
          80_900, // Achat réc. debout  FO61
        ]
        return sumBy(comptesExploitation({ type_ligne: 'FO', ligne: ceLignes }), 'montant')
      },
    },
  },
  'crops.expenses.imported.purchases.crops': {
    testResolver: ({ cri }) =>
      CerealProductions.reduce(
        (acc, production) => acc.plus(cri(`${production}16`)).plus(cri(`${production}61`)),
        new BigNumber(0)
      ),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          100_900, // Achat pour revente CE16
          101_000, // Achat réc. debout  CE61
        ]
        return sumBy(comptesExploitation({ type_ligne: 'CE', ligne: ceLignes }), 'montant')
      },
    },
  },
  'crops.expenses.imported.supplies.seed': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(16_939),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(13_406),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          { type_ligne: 'CE', ligne: [101_300] }, // Semences CE19
          { type_ligne: 'FO', ligne: [81_200] }, // Semences  FO19
          { type_ligne: 'GD', ligne: [121_000] }, // Semences Pas de cri
        ]
        const iaLignes: Array<{ type_ligne: ViagritelTypeLigneInventaireApprovisionnement; ligne: number[] }> = [
          { type_ligne: 'CE', ligne: [90_100] }, // Semences  CE19
          { type_ligne: 'FO', ligne: [70_100] }, // Semences  FO19
          { type_ligne: 'GD', ligne: [110_100] }, // Semences Pas de cri
        ]
        return ceLignes
          .reduce((acc, filter) => acc.plus(sumBy(comptesExploitation(filter), 'montant')), new BigNumber(0))
          .plus(
            iaLignes.reduce(
              (acc, filter) => acc.plus(sumBy(inventairesApprovisionnements(filter), 'delta_dollars')),
              new BigNumber(0)
            )
          )
      },
    },
  },
  'crops.expenses.imported.supplies.fertilizer': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(33_824),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(26_812),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          {
            type_ligne: 'CE',
            ligne: [
              101_100, // Fertilisants minéraux   CE17
              101_200, // Fertilisants organiques CE18
            ],
          },
          {
            type_ligne: 'FO',
            ligne: [
              81_000, // Fertilisants minéraux   FO17
              81_100, // Fertilisants organiques FO18
            ],
          },
          {
            type_ligne: 'GD',
            ligne: [
              120_800, // Fertilisants minéraux   Pas de cri
              120_900, // Fertilisants organiques Pas de cri
            ],
          },
        ]
        const iaLignes: Array<{ type_ligne: ViagritelTypeLigneInventaireApprovisionnement; ligne: number[] }> = [
          {
            type_ligne: 'CE',
            ligne: [
              90_000, // Fertilisants minéraux   CE17
              90_050, // Fertilisants organiques Pas de cri
            ],
          },
          {
            type_ligne: 'FO',
            ligne: [
              70_000, // Fertilisants minéraux   FO17
              70_050, // Fertilisants organiques Pas de cri
            ],
          },
          {
            type_ligne: 'GD',
            ligne: [
              110_000, // Fertilisants minéraux   Pas de cri
              110_050, // Fertilisants organiques Pas de cri
            ],
          },
        ]
        return ceLignes
          .reduce(
            (acc, { type_ligne, ligne }) => acc.plus(sumBy(comptesExploitation({ type_ligne, ligne }), 'montant')),
            new BigNumber(0)
          )
          .plus(
            iaLignes.reduce(
              (acc, filter) => acc.plus(sumBy(inventairesApprovisionnements(filter), 'delta_dollars')),
              new BigNumber(0)
            )
          )
      },
    },
  },
  'crops.expenses.imported.supplies.pesticides': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(22_692),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(17_604),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          {
            type_ligne: 'CE',
            ligne: [
              101_400, // Herbicides        CE20
              101_500, // Autres pesticides CE21
            ],
          },
          {
            type_ligne: 'FO',
            ligne: [81_300], // Herbicides FO20
          },
          {
            type_ligne: 'GD',
            ligne: [
              121_100, // Herbicides        Pas de cri
              121_200, // Autres pesticides Pas de cri
            ],
          },
        ]
        const iaLignes: Array<{ type_ligne: ViagritelTypeLigneInventaireApprovisionnement; ligne: number[] }> = [
          {
            type_ligne: 'CE',
            ligne: [
              90_200, // Herbicides        CE20
              90_300, // Autres pesticides CD21
            ],
          },
          {
            type_ligne: 'FO',
            ligne: [70_200], // Herbicides FO20
          },
          {
            type_ligne: 'GD',
            ligne: [
              110_200, // Herbicides        Pas de cri
              110_300, // Autres pesticides Pas de cri
            ],
          },
        ]
        return ceLignes
          .reduce(
            (acc, { type_ligne, ligne }) => acc.plus(sumBy(comptesExploitation({ type_ligne, ligne }), 'montant')),
            new BigNumber(0)
          )
          .plus(
            iaLignes.reduce(
              (acc, filter) => acc.plus(sumBy(inventairesApprovisionnements(filter), 'delta_dollars')),
              new BigNumber(0)
            )
          )
      },
    },
  },
  'crops.expenses.imported.supplies.lime': {
    testResolver: ({ cri }) => cri('ZZ348').plus(cri('ZZ349')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          161_000, // Chaux,         ZZ348
          161_100, // Engrais verts, ZZ349
        ]
        return sumBy(comptesExploitation({ type_ligne: 'GT', ligne: ceLignes }), 'montant')
      },
    },
  },
  'crops.expenses.imported.supplies.miscellaenous': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(70_920),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(60_352),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          {
            type_ligne: 'CE',
            ligne: [
              101_600, // Contenants et ficelle  CE22
              101_700, // Agents de conservation CE23
              101_800, // Plastique              CE24
              101_900, // Mise en marché         CE25
              102_000, // Autres approv. directs CE27
            ],
          },
          {
            type_ligne: 'FO',
            ligne: [
              81_400, // Contenants & ficelle   FO22
              81_500, // Agents de conservation FO23
              81_600, // Plastique              FO24
              // (absent) Mise en marché
              81_700, // Autres approv. directs FO27
            ],
          },
          {
            type_ligne: 'GD',
            ligne: [
              121_300, // Contenants, ficelle    Pas de cri
              121_400, // Agents de conservation Pas de cri
              121_500, // Plastique              Pas de cri
              121_600, // Mise en marché         Pas de cri
              121_700, // Autres approv. directs Pas de cri
            ],
          },
        ]
        const iaLignes: Array<{ type_ligne: ViagritelTypeLigneInventaireApprovisionnement; ligne: number[] }> = [
          {
            type_ligne: 'CE',
            ligne: [
              90_400, // Contenants et ficelle CE22
              90_500, // Plastique             CE24
              90_600, // Autres                CE27
            ],
          },
          {
            type_ligne: 'FO',
            ligne: [
              70_300, // Corde     FO22
              70_400, // Plastique FO24
              70_500, // Autres    FO27
            ],
          },
          {
            type_ligne: 'GD',
            ligne: [
              110_400, // Contenants et ficelle Pas de cri
              110_500, // Plastique             Pas de cri
              110_600, // Autres                Pas de cri
            ],
          },
        ]
        return ceLignes
          .reduce(
            (acc, { type_ligne, ligne }) => acc.plus(sumBy(comptesExploitation({ type_ligne, ligne }), 'montant')),
            new BigNumber(0)
          )
          .plus(
            iaLignes.reduce(
              (acc, filter) => acc.plus(sumBy(inventairesApprovisionnements(filter), 'delta_dollars')),
              new BigNumber(0)
            )
          )
      },
    },
  },
  'crops.expenses.imported.insurance.general': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(15_185),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(15_098),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        // Aux CE des productions de céréales CE et fourrages FO, ligne cot. ass-récolte + Au CE Cult. non-détaillées, ligne Cot. ass. récolte
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          { type_ligne: 'CE', ligne: [102_200] }, // Cot. ass. récolte CE28
          { type_ligne: 'FO', ligne: [81_900] }, // Cot. ass. récolte  FO28
          { type_ligne: 'GD', ligne: [121_900] }, // Cot. ass. récolte Pas de cri
        ]
        return ceLignes.reduce(
          (acc, filter) => acc.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'crops.expenses.imported.insurance.stabilization': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(4749.95),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(5064),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        // Aux CE des productions de céréales CE , ligne cot. ass-stabilisation + Au CE Cult non  détaillées, ligne Cot. ass. stabilisation
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          { type_ligne: 'CE', ligne: [102_300] }, // Cot. ass. stabilisation CE29
          // (absent) { type_ligne: 'FO', ligne: [] }, // Cot. ass. stabilisation
          { type_ligne: 'GD', ligne: [122_000] }, // Cot. ass. stabilisation Pas de cri
        ]
        return ceLignes.reduce(
          (acc, filter) => acc.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
})
