import { type FC, useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import { Link } from '@tanstack/react-router'
import { pick } from 'lodash-es'
import { type Except } from 'type-fest'

import { Icons } from '../../atoms'
import { Badge } from '../../atoms/Badge/Badge'
import { Button } from '../../atoms/Button/Button'
import { FormSubmitProvider } from '../../atoms/Form/FormSubmitProvider'
import { useFormSubmit } from '../../atoms/Form/useFormSubmit'
import { Tooltip } from '../../atoms/Tooltip/Tooltip'
import { useCallbackWithConfirmation } from '../../context'
import { usePersistedTableSorting } from '../../hooks/usePersistedTableSorting'
import { cn } from '../../lib/utils'
import { DataTable, type DataTableColumnDef, DataTableColumnHeader } from '../../molecules/DataTable/DataTable'
import { UserAvatarWithTooltip } from '../../molecules/UserAvatarWithTooltip/UserAvatarWithTooltip'
import { type ReportFormData } from '../../organisms/ReportConfigurationForm/ReportFormZod.ts'
import { ReportFileForm } from '../../organisms/ReportFileForm/ReportFileForm'
import { ReportFileFormProvider } from '../../organisms/ReportFileForm/ReportFileFormProvider'
import { ReportMainLayout, type ReportMainLayoutProps } from '../../organisms/ReportMainLayout/ReportMainLayout'
import { TitleBar } from '../../organisms/TitleBar/TitleBar'
import { type ReportFileData } from '../../types'

export interface ReportFileListViewProps
  extends Except<ReportMainLayoutProps, 'loading' | 'currentReport' | 'reports' | 'onAddReportClick'>,
    Required<Pick<ReportMainLayoutProps, 'currentReport' | 'reports' | 'onAddReportClick'>> {
  readonly currentUserId: string
  readonly currentReportFiles: ReportFileData[]

  onDeleteReportClick(fileId: string): Promise<void>
  onDownloadReportClick(fileUrl: string): Promise<void>
  onGenerateDraftPdfClick(): Promise<void>
  onGenerateFinalPdfClick(): Promise<void>
  onRetryGeneratePdfClick(fileId: string): Promise<void>
  onValueChange(form: ReportFormData): Promise<void>
}

const GeneratingReportActionColumn: FC = () => (
  <div className="flex justify-end gap-3 overflow-hidden">
    <Icons.Loading style={{ width: '4.25rem' }} />
  </div>
)

const ErrorGeneratingReportTextColumn: FC = () => {
  const intl = useIntl()
  return <div className="flex justify-center">{intl.formatMessage({ id: 'reportFileList.error' })}</div>
}

const ReportFileListLayout: FC<Omit<ReportFileListViewProps, 'onValueChange'>> = ({
  onCloseBudget,
  budget,
  offline,
  currentUserId,
  currentReport,
  currentReportFiles,
  reports,
  onRequestOwnership,
  onNavigationChange,
  onAddReportClick,
  onGenerateDraftPdfClick,
  onGenerateFinalPdfClick,
  onRetryGeneratePdfClick,
  onDeleteReportClick,
  onDownloadReportClick,
  onUpdateBudget,
}) => {
  const intl = useIntl()
  const { submit } = useFormSubmit()
  const formReports = reports.map((report) =>
    report._id === currentReport._id ? { ...report, name: currentReport.name } : report
  )

  const onDeleteReportWithConfirmation = useCallbackWithConfirmation(
    onDeleteReportClick,
    {
      confirmLabel: intl.formatMessage({ id: 'reportFileList.action.delete.confirmationDialog.confirm' }),
      cancelLabel: intl.formatMessage({ id: 'reportFileList.action.delete.confirmationDialog.close' }),
      title: intl.formatMessage({ id: 'reportFileList.action.delete.confirmationDialog.title' }),
      subtitle: intl.formatMessage({ id: 'reportFileList.action.delete.confirmationDialog.subTitle' }),
    },
    [intl]
  )

  const generateDraftPdf = useCallback(async () => {
    await submit()
    await onGenerateDraftPdfClick()
  }, [onGenerateDraftPdfClick, submit])

  const generateFinalPdf = useCallback(async () => {
    await submit()
    await onGenerateFinalPdfClick()
  }, [onGenerateFinalPdfClick, submit])

  const [retryingGeneratePdf, setRetryingGeneratePdf] = useState(false)
  const retryGeneratePdf = useCallback(
    async (fileId: string) => {
      try {
        setRetryingGeneratePdf(true)
        await onRetryGeneratePdfClick(fileId)
      } finally {
        setRetryingGeneratePdf(false)
      }
    },
    [onRetryGeneratePdfClick]
  )

  const { sorting, onSortingChange } = usePersistedTableSorting({
    key: 'draftReportFileListSort',
    defaultValue: [{ id: 'fileName', desc: true }],
  })

  const columns = useMemo<Array<DataTableColumnDef<ReportFileData>>>(
    () => [
      {
        id: 'fileName',
        accessorFn: (row) => row.fileName ?? '',
        headerClassName: 'flex flex-1',
        cellClassName: 'flex flex-1',
        // eslint-disable-next-line react/no-unstable-nested-components
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title={intl.formatMessage({ id: 'reportFileList.fileName' })} />
        ),
      },
      {
        id: 'type',
        accessorFn: (row) => row.type,
        headerClassName: 'w-24',
        cellClassName: 'w-24',
        // eslint-disable-next-line react/no-unstable-nested-components
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({ id: 'reportFileList.type' })}
            className="justify-center"
          />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <Badge variant="green">
            <Icons.Flower />
            {intl.formatMessage({ id: `reportFileList.type.${row.original.type}` })}
          </Badge>
        ),
      },
      {
        id: 'actions-scenarios',
        accessorFn: (row) => row.scenarioStates,
        headerClassName: 'w-36',
        cellClassName: 'w-36',
        // eslint-disable-next-line react/no-unstable-nested-components
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({ id: 'reportFileList.scenarios' })}
            className="justify-center"
          />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => {
          if (row.original.status !== 'ready') {
            return null
          }

          return (
            <div className="flex items-center pl-2">
              <Link
                to="./$fileId"
                from="/budgets/$budgetId/reports/$reportId/files"
                params={{ fileId: row.original._id }}>
                <Button variant="outline">
                  <Icons.Eye />
                  {intl.formatMessage(
                    { id: 'reportFileList.scenarios.view' },
                    { count: Object.keys(row.original.scenarioStates).length }
                  )}
                </Button>
              </Link>
            </div>
          )
        },
      },
      {
        id: 'creation',
        accessorFn: (row) => row.timestamp,
        accessorKey: 'creation',
        headerClassName: 'w-44',
        cellClassName: 'w-44',
        // eslint-disable-next-line react/no-unstable-nested-components
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({ id: 'reportFileList.creation' })}
            className="justify-center"
          />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => {
          if (row.original.status === 'error') {
            return <ErrorGeneratingReportTextColumn />
          }

          return (
            <div className="flex flex-row items-center justify-between gap-3 pl-4 pr-6">
              <span className="w-20">{intl.formatDate(row.original.timestamp)}</span>
              {row.original.createdBy ? (
                <UserAvatarWithTooltip {...pick(row.original.createdBy, ['userName', 'userRole'])} />
              ) : null}
            </div>
          )
        },
      },
      {
        id: 'actions',
        headerClassName: 'w-12',
        cellClassName: 'w-12',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => {
          switch (row.original.status) {
            case 'created':
            case 'generating':
              return <GeneratingReportActionColumn />
            case 'error':
              return (
                <div className="flex justify-end gap-3">
                  <Tooltip label={intl.formatMessage({ id: 'reportFileList.action.retry.draft.tooltip' })}>
                    <Button
                      className="w-fit text-nowrap text-left text-xs leading-[0.9]"
                      onClick={() => retryGeneratePdf(row.original._id)}
                      loading={retryingGeneratePdf}
                      disabled={retryingGeneratePdf}>
                      <div className="pt-1">{intl.formatMessage({ id: 'reportFileList.action.retry' })}</div>
                    </Button>
                  </Tooltip>
                </div>
              )
            case 'ready':
              return (
                <div className="flex justify-end gap-3">
                  <Button
                    dimension="xs"
                    shape="iconOnly"
                    variant="outline"
                    disabled={offline}
                    onClick={() => {
                      onDeleteReportWithConfirmation(row.original._id)
                    }}>
                    <Icons.Trash className="size-4" />
                  </Button>
                  {row.original.fileUrl !== undefined ? (
                    <Button
                      dimension="xs"
                      shape="iconOnly"
                      variant="outline"
                      disabled={offline}
                      onClick={() => onDownloadReportClick(row.original.fileUrl!)}>
                      <Icons.Download className="size-4" />
                    </Button>
                  ) : null}
                </div>
              )
            default:
              return null
          }
        },
      },
    ],
    [intl, offline, onDeleteReportWithConfirmation, onDownloadReportClick, retryGeneratePdf, retryingGeneratePdf]
  )

  const draftReports = currentReportFiles.filter((file) => file.type === 'draft')
  const finalReports = currentReportFiles.filter((file) => file.type === 'final')

  return (
    <ReportMainLayout
      onCloseBudget={onCloseBudget}
      budget={budget}
      currentReport={currentReport}
      offline={offline}
      reports={formReports}
      onRequestOwnership={onRequestOwnership}
      onNavigationChange={onNavigationChange}
      onAddReportClick={onAddReportClick}
      onUpdateBudget={onUpdateBudget}
      loading={false}>
      <div className="flex w-full flex-col pb-4 pl-44 pr-12 pt-8">
        <TitleBar label={intl.formatMessage({ id: 'reportFileList.title' })} />
        <ReportFileForm
          onGenerateDraftPdfClick={generateDraftPdf}
          onGenerateFinalPdfClick={generateFinalPdf}
          isCurrentUserBudgetAgronomist={budget.users.agronomist?.id === currentUserId}
        />
        <DataTable
          className="pt-8"
          tableId="draft-document-list-data-table"
          titleLabel={intl.formatMessage({ id: 'reportFileList.drafts.title' })}
          emptyLabel={intl.formatMessage({ id: 'reportFileList.drafts.noDataLabel' })}
          columns={columns.filter((column) => column.id !== 'type')}
          data={draftReports}
          sorting={sorting}
          onSortingChange={onSortingChange}
        />

        <DataTable
          className="pt-16"
          tableId="draft-document-list-data-table"
          titleLabel={intl.formatMessage({ id: 'reportFileList.finals.title' })}
          emptyLabel={
            budget.users.agronomist
              ? intl.formatMessage({ id: 'reportFileList.finals.noDataLabel' })
              : intl.formatMessage({ id: 'reportFileList.finals.noAgronomist' })
          }
          columns={columns}
          data={finalReports}
          sorting={sorting}
          onSortingChange={onSortingChange}
        />
      </div>
    </ReportMainLayout>
  )
}

export const ReportFileListView: FC<ReportFileListViewProps> = ({ onValueChange, ...props }) => (
  <ReportFileFormProvider data={{ defaultFileName: props.currentReport.name }}>
    <FormSubmitProvider
      autoSubmit
      onFormSubmit={onValueChange}
      className={cn('flex w-full flex-col items-start overflow-hidden bg-white')}>
      <ReportFileListLayout {...props} />
    </FormSubmitProvider>
  </ReportFileFormProvider>
)
