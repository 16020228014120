import type React from 'react'

import { LoginView } from '@via/components'

import { useFirebaseSignIn } from '../../auth/useFirebaseSignIn.ts'

export const LoginPage: React.FC = () => {
  const { signIn } = useFirebaseSignIn()
  return (
    <LoginView
      onConnectClick={async (username, password) => {
        await signIn({ username, password })
      }}
    />
  )
}
