import type React from 'react'

import { useOfflineState } from '../offlineState/useOfflineState.ts'

import { OnlineCurrentClientBudgetListProvider } from './OnlineCurrentClientBudgetListProvider.tsx'

export type CurrentClientBudgetListProviderProps = React.PropsWithChildren<{ readonly clientId: string }>

export const CurrentClientBudgetListProvider: React.FC<CurrentClientBudgetListProviderProps> = ({
  children,
  clientId,
}) => {
  const { offline } = useOfflineState()
  return offline ? (
    children
  ) : (
    <OnlineCurrentClientBudgetListProvider clientId={clientId}>{children}</OnlineCurrentClientBudgetListProvider>
  )
}
