import { type BlueprintVersion, type ProductionType } from '../../../enums'
import { type DataKey } from '../../data-key-zod'

export interface CalculationDataAttribute<Deps extends DataKey = DataKey> {
  readonly type: 'calculation'
  readonly readonly: true
  readonly reference?: 'selected' | 'latest' | 'none' // default: 'selected'
  readonly weights?: ReadonlyArray<DataKey>
  readonly dependencies: Deps[] | Record<string, Deps>
  readonly blueprintVersions?: ReadonlyArray<BlueprintVersion>
  readonly production?: ProductionType
}

export const CalculationDataKeys = [
  'milk.animals.expenses.cows.value',
  'milk.animals.expenses.other.value',
  'milk.animals.income.breed.value',
  'milk.animals.income.calf.head-count',
  'milk.animals.income.calf.value',
  'milk.animals.income.cull.head-count',
  'milk.animals.income.cull.value',
  'milk.animals.income.other.value',
  'milk.production.volume.hectoliter.total',
  'milk.production.sale.hectoliter',
  'milk.production.sale.kilogram-of-fat',
  'milk.production.sale.kilogram-of-fat-per-day',
  'milk.quota.allowed-production.hectoliter',
  'milk.quota.analysis.percentage.usage',
  'milk.quota.analysis.kilogram-of-fat-per-day.allowed.difference',
  'milk.quota.analysis.head-count.equilibrium',
  'milk.quota.analysis.liter-per-head-count.equilibrium',
  'milk.sale.hectoliter',
  'milk.marketing.currency-per-hectoliter',
  'milk.marketing.total',
] as const

export const CalculationDataKeyAttributes = {
  'milk.animals.expenses.cows.value': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.animals.expenses.cows.head-count', 'milk.animals.expenses.cows.value-per-head-count'],
  },
  'milk.animals.expenses.other.value': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.animals.expenses.other.head-count', 'milk.animals.expenses.other.value-per-head-count'],
  },
  'milk.animals.income.breed.value': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.animals.income.breed.head-count', 'milk.animals.income.breed.value-per-head-count'],
  },
  'milk.animals.income.calf.head-count': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.animals.cows.average-head-count', 'milk.animals.income.calf.females-kept-proportion'],
  },
  'milk.animals.income.calf.value': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.animals.income.calf.head-count', 'milk.animals.income.calf.value-per-head-count'],
  },
  'milk.animals.income.cull.head-count': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.animals.cows.average-head-count', 'milk.animals.income.cull.proportion'],
  },
  'milk.animals.income.cull.value': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.animals.income.cull.head-count', 'milk.animals.income.cull.value-per-head-count'],
  },
  'milk.animals.income.other.value': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.animals.income.other.head-count', 'milk.animals.income.other.value-per-head-count'],
  },
  'milk.production.volume.hectoliter.total': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.production.cows.head-count', 'milk.production.cows.liter-per-head-count'],
  },
  'milk.production.sale.hectoliter': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.production.volume.hectoliter.total', 'milk.production.volume.liter.excluded'],
  },
  'milk.production.sale.kilogram-of-fat': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.production.sale.hectoliter', 'milk.production.content.kilogram-per-hectoliter.fat'],
  },
  'milk.production.sale.kilogram-of-fat-per-day': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.production.sale.kilogram-of-fat'],
  },
  'milk.quota.allowed-production.hectoliter': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk-quota.allowed-kilogram-of-fat', 'milk.production.content.kilogram-per-hectoliter.fat'],
  },
  'milk.quota.analysis.percentage.usage': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.production.sale.hectoliter', 'milk.quota.allowed-production.hectoliter'],
  },
  'milk.quota.analysis.kilogram-of-fat-per-day.allowed.difference': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: [
      'milk.production.sale.hectoliter',
      'milk.quota.allowed-production.hectoliter',
      'milk.production.content.kilogram-per-hectoliter.fat',
    ],
  },
  'milk.quota.analysis.head-count.equilibrium': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.production.cows.liter-per-head-count', 'milk.quota.allowed-production.hectoliter'],
  },
  'milk.quota.analysis.liter-per-head-count.equilibrium': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.production.cows.head-count', 'milk.quota.allowed-production.hectoliter'],
  },
  'milk.sale.hectoliter': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.production.sale.hectoliter', 'milk.quota.allowed-production.hectoliter'],
  },
  'milk.marketing.currency-per-hectoliter': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: {
      fatContent: 'milk.production.content.kilogram-per-hectoliter.fat',
      proteinContent: 'milk.production.content.kilogram-per-hectoliter.protein',
      lactoseContent: 'milk.production.content.kilogram-per-hectoliter.lactose',
      defense: 'milk.marketing.currency-per-kilogram.defense-fund',
      advertising: 'milk.marketing.currency-per-kilogram.advertising',
      development: 'milk.marketing.currency-per-kilogram.development-fund',
      marketing: 'milk.marketing.currency-per-hectoliter.marketing-fund',
      transport: 'milk.marketing.currency-per-hectoliter.transport',
    },
  },
  'milk.marketing.total': {
    type: 'calculation',
    readonly: true,
    blueprintVersions: ['complete-2024'],
    production: 'milk',
    dependencies: ['milk.marketing.currency-per-hectoliter', 'milk.production.sale.hectoliter'],
    weights: [
      'milk.sale.hectoliter',
      'milk.production.cows.head-count',
      'milk.production.sale.kilogram-of-fat',
      'milk-quota.allowed-kilogram-of-fat',
    ],
  },
} as const satisfies Record<(typeof CalculationDataKeys)[number], CalculationDataAttribute>

export type CalculationDataKey = keyof typeof CalculationDataKeyAttributes
