import { createFileRoute, redirect } from '@tanstack/react-router'
import { sortScenarios } from '@via/components'

export const Route = createFileRoute('/budgets/$budgetId/')({
  beforeLoad: ({ params, context }) => {
    const activeScenarios = sortScenarios(context.budget.scenarios)
    const scenario = activeScenarios?.[0]

    if (!scenario) {
      throw redirect({
        from: '/budgets/$budgetId/',
        to: '/budgets/$budgetId/scenarios/create',
        params: { ...params },
        replace: true,
      })
    }

    throw redirect({
      from: '/budgets/$budgetId/',
      to: '/budgets/$budgetId/scenarios/$scenarioId',
      params: { ...params, scenarioId: scenario.id },
      replace: true,
    })
  },
})
