import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { pick } from 'lodash-es'

import { Icons } from '../../atoms'
import { useBudgetListDataTableOptions } from '../../hooks/useBudgetListDataTableOptions.tsx'
import { usePersistedTableFilter } from '../../hooks/usePersistedTableFilter'
import { usePersistedTableSorting } from '../../hooks/usePersistedTableSorting'
import { DataTable, type DataTableColumnDef, DataTableColumnHeader } from '../../molecules/DataTable/DataTable'
import { SettingDropdownWithConfirmationMenu } from '../../molecules/SettingDropdownWithConfirmationMenu/SettingDropdownWithConfirmationMenu'
import { StatusBadge } from '../../molecules/StatusBadge/StatusBadge'
import { UserAvatarWithTooltip } from '../../molecules/UserAvatarWithTooltip/UserAvatarWithTooltip'
import { type BudgetData, type BudgetListFilterState, type BudgetOwnership, type UserData } from '../../types'

export interface BudgetListDataTableData {
  readonly data: BudgetData[]
  readonly offline: boolean
  readonly user: UserData | null
}

export interface BudgetListDataTableActions {
  onFilterByChange?(value: string): void
  onActionSelect(id: string, value: 'duplicate' | 'archive' | 'open' | 'close'): Promise<void>
  onNavigateToBudget(budgetId: string): Promise<void>
  applyFilter?(state: BudgetListFilterState, budgetData: BudgetData[]): BudgetData[]
}

export interface BudgetListDataTableProps extends BudgetListDataTableData, BudgetListDataTableActions {}

export const BudgetListDataTable = ({
  data,
  offline,
  user,
  applyFilter,
  onActionSelect,
  onNavigateToBudget,
}: BudgetListDataTableProps) => {
  const intl = useIntl()

  const years = useMemo(() => data.map((budget) => budget.year), [data])
  const { budgetOwnershipFilterOptions, filterOptions, actionOptions } = useBudgetListDataTableOptions({
    years,
    offline,
  })
  const { sorting, onSortingChange } = usePersistedTableSorting({
    key: 'budgetListSort',
    defaultValue: [{ id: 'year', desc: true }],
  })
  const { selectedFilter: selectedYear, onFilterChange: onFilterByYearChange } = usePersistedTableFilter({
    key: 'budgetListSelectedYear',
  })
  const { selectedFilter: selectedOwnership, onFilterChange: onFilterByOwnershipChange } =
    usePersistedTableFilter<BudgetOwnership>({
      key: 'budgetListSelectedOwnership',
      defaultValue: 'mine',
    })

  const { selectedFilter: selectedBudgetStatus, onFilterChange: onFilterByStatusChange } = usePersistedTableFilter({
    key: 'clientBudgetListSelectedStatus',
  })

  const statusFilterOptions = useMemo(
    () => [
      { value: 'all', text: intl.formatMessage({ id: 'clientBudgetList.all' }) },
      { value: 'completed', text: intl.formatMessage({ id: 'clientBudgetList.completed' }) },
      { value: 'todo', text: intl.formatMessage({ id: 'clientBudgetList.todo' }) },
    ],
    [intl]
  )

  const filteredData = useMemo(
    () =>
      applyFilter?.(
        {
          selectedYear,
          selectedOwnership: selectedOwnership ?? 'mine',
          status: selectedBudgetStatus as BudgetListFilterState['status'],
        },
        data
      ) ?? data,
    [data, applyFilter, selectedYear, selectedOwnership, selectedBudgetStatus]
  )

  const columns = useMemo<Array<DataTableColumnDef<BudgetData>>>(
    () => [
      {
        id: 'code',
        accessorFn: (row) => row.client?.code,
        headerClassName: 'px-6 w-28',
        cellClassName: 'px-6',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title={intl.formatMessage({ id: 'budgetList.file' })} />
        ),
      },
      {
        id: 'budgetName',
        accessorFn: (row) => row.name,
        cellClassName: 'text-base',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title={intl.formatMessage({ id: 'budgetList.budgetName' })} />
        ),
      },
      {
        id: 'contract',
        accessorFn: (row) => row.contractId,
        headerClassName: 'px-6 w-28',
        cellClassName: 'px-6',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title={intl.formatMessage({ id: 'budgetList.contract' })} />
        ),
      },
      {
        id: 'name',
        accessorFn: (row) => row.client?.name,
        cellClassName: 'text-base',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title={intl.formatMessage({ id: 'budgetList.enterprise' })} />
        ),
      },
      {
        id: 'contactInformation',
        accessorFn: (row) => row.client?.contactInformation?.name,
        cellClassName: 'text-base',
        headerClassName: 'w-[256px]',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title={intl.formatMessage({ id: 'budgetList.contact' })} />
        ),
      },
      {
        id: 'lastModification',
        accessorFn: (row) => row.lastModification,
        accessorKey: 'lastModification',
        headerClassName: 'px-4 w-40',
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({ id: 'budgetList.updatedDate' })}
            className="w-40"
          />
        ),
        cell: ({ row }) =>
          row.original.owner ? (
            <div className="flex items-center gap-3">
              <Icons.PersonFillLock className="flex w-20 justify-center" />
              <UserAvatarWithTooltip {...pick(row.original.owner, ['userName', 'userRole'])} />
            </div>
          ) : (
            <div className="flex items-center gap-3">
              <span className="w-20">{intl.formatDate(row.original.lastModification.timestamp)}</span>
              <UserAvatarWithTooltip {...pick(row.original.lastModification, ['userName', 'userRole'])} />
            </div>
          ),
      },
      {
        id: 'year',
        accessorFn: (row) => row.year,
        headerClassName: 'px-2 w-28 text-center',
        cellClassName: 'text-center',
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({ id: 'budgetList.firstYearBudgeted' })}
            className="w-28"
          />
        ),
      },
      {
        accessorKey: 'status',
        headerClassName: 'px-1 w-24',
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({ id: 'budgetList.status' })}
            className="justify-center"
          />
        ),
        cell: ({ row }) => (
          <StatusBadge
            variant={row.original.status}
            label={intl.formatMessage({ id: `budget.status.${row.original.status}` })}
          />
        ),
      },
      {
        id: 'actions',
        headerClassName: 'w-8',
        cell: ({ row }) => (
          <SettingDropdownWithConfirmationMenu
            options={actionOptions(row.original.readonly, row.original.owner?.userId === user?._id)}
            onItemSelect={(value) => onActionSelect(row.original._id, value)}
          />
        ),
      },
    ],
    [actionOptions, intl, onActionSelect, user?._id]
  )

  return (
    <DataTable
      tableId="budget-list-data-table"
      titleLabel={intl.formatMessage({ id: 'budgetList.title' })}
      emptyLabel={intl.formatMessage({ id: 'budgetList.empty' })}
      filterOptions={{
        year: {
          options: filterOptions,
          initialValue: selectedYear,
          placeholder: intl.formatMessage({ id: 'budgetList.filter.firstYearBudgeted' }),
          onChange: onFilterByYearChange,
        },
        ownership: {
          options: budgetOwnershipFilterOptions,
          initialValue: selectedOwnership ?? 'mine',
          placeholder: intl.formatMessage({ id: 'budgetList.filter.myBudgets' }),
          onChange: (value) => {
            onFilterByOwnershipChange(value as BudgetOwnership)
          },
        },
        'status-filters': {
          options: statusFilterOptions,
          initialValue: selectedBudgetStatus,
          placeholder: intl.formatMessage({ id: 'clientBudgetList.statusPlaceholder' }),
          onChange: onFilterByStatusChange,
        },
      }}
      searchPlaceholderLabel={intl.formatMessage({ id: 'budgetList.search' })}
      columns={columns}
      data={filteredData}
      sorting={sorting}
      onSortingChange={onSortingChange}
      filterKeys={[
        'contractId',
        'name',
        'client.code',
        'client.name',
        'client.nim',
        'client.contactInformation.email',
        'client.contactInformation.name',
        'client.contactInformation.phone',
        'client.address.city',
        'client.address.postalCode',
        'client.address.streetAddress',
      ]}
      onRowClick={async (id) => {
        const row = filteredData[Number(id)]
        await onNavigateToBudget(row._id)
      }}
    />
  )
}
