import type React from 'react'

import { MilkConfigurationView } from '@via/components'

import { useMilkConfigurationPage } from './useMilkConfigurationPage.ts'

export const MilkConfigurationPage: React.FC = () => {
  const props = useMilkConfigurationPage()
  return <MilkConfigurationView key={props.scenario._id} {...props} />
}
