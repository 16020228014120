import { type FC, Fragment, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'

import { isEmpty } from 'lodash-es'

import { Icons } from '../../atoms'
import { AddItemButton } from '../../atoms/AddItemButton/AddItemButton.tsx'
import { Callout } from '../../atoms/Callout/Callout.tsx'
import { HeaderLabel } from '../../atoms/HeaderLabel/HeaderLabel.tsx'
import { HeaderSelect } from '../../atoms/HeaderSelect/HeaderSelect.tsx'
import { HeaderTitle } from '../../atoms/HeaderTitle/HeaderTitle.tsx'
import { HeaderUnit } from '../../atoms/HeaderUnit/HeaderUnit.tsx'
import { Label } from '../../atoms/Label/Label.tsx'
import { stringIsMessageId } from '../../l10n'
import { cn } from '../../lib/utils.ts'
import { CaretHeaderTitle } from '../../molecules/CaretHeaderTitle/CaretHeaderTitle.tsx'
import { ControllableTabList } from '../../molecules/ControllableTabList/ControllableTabList.tsx'
import { AddInvestmentSheet } from '../AddInvestment/AddInvestmentSheet.tsx'

import { MonetaryTableActionsContext } from './contexts/MonetaryTableActionsContext.ts'
import {
  MonetaryTableCollapseSetterContext,
  MonetaryTableCollapseStateContext,
} from './contexts/MonetaryTableCollapseProvider.tsx'
import { MonetaryTablePropsContext } from './contexts/MonetaryTablePropsContext.ts'
import {
  MonetaryTableBlueprintContext,
  MonetaryTableCurrentBlueprintColumnsContext,
  MonetaryTableCurrentBlueprintStateContext,
  MonetaryTableCurrentBlueprintTabContext,
  MonetaryTableCurrentWeightContext,
} from './contexts/MonetaryTableStateProvider.tsx'
import { MonetaryTableStateSetterContext } from './contexts/MonetaryTableStateSetterState.ts'
import { MonetaryTableDividerCell } from './dividers/MonetaryTableDividerCell.tsx'
import { MonetaryTableStickyColumns } from './MonetaryTableStickyColumns.tsx'
import {
  type MonetaryTableColumnType,
  type MonetaryTableSection,
  type MonetaryTableTopHeaderDimension,
  type MonetaryTableTopHeaderVariant,
} from './types.ts'

const TableSectionTitle: FC<{ readonly section: MonetaryTableSection; readonly className?: string }> = ({
  section,
  className,
}) => {
  const { id, title, collapsible = true } = section
  const { setSectionOpen } = useContext(MonetaryTableCollapseSetterContext)
  const { isSectionOpen } = useContext(MonetaryTableCollapseStateContext)
  const setOpen = useCallback(
    (open: boolean) => {
      setSectionOpen(id, open)
    },
    [id, setSectionOpen]
  )

  if (!title) {
    return null
  }

  if (!collapsible) {
    return <HeaderTitle label={title} className={cn('absolute bottom-2 left-[52px] w-84', className)} />
  }

  return (
    <CaretHeaderTitle
      label={title}
      open={isSectionOpen(id)}
      onCollapse={setOpen}
      className={cn('absolute bottom-0 left-[28px] w-84', className)}
    />
  )
}

const TableTopNavigationCell: FC<{
  readonly type: MonetaryTableColumnType
  readonly baseColumnWidth?: number
  readonly withDividerRight?: boolean
  readonly className?: string
}> = ({ type, baseColumnWidth = 96, withDividerRight = false, className }) => {
  const intl = useIntl()
  const blueprint = useContext(MonetaryTableBlueprintContext)
  const currentBlueprintTab = useContext(MonetaryTableCurrentBlueprintTabContext)
  const { onExpandReference, onTabChange } = useContext(MonetaryTableStateSetterContext)
  const { references, withNavigation } = useContext(MonetaryTablePropsContext)

  switch (type) {
    case 'title':
      return <div className={cn('w-84 shrink-0 bg-white', className)} />

    case 'divider':
      return <MonetaryTableDividerCell className={className} />

    case 'reference':
      return blueprint.hideReferenceLabel ? (
        <div className={className} />
      ) : (
        <div
          className={cn(
            'flex h-6 items-start justify-between rounded-t-lg border-x border-t border-x-gray-400 border-t-gray-400 bg-gray-100 pb-1.5 pl-1.5 pr-0 pt-1',
            className
          )}>
          <p className="select-none text-center text-base font-medium leading-none tracking-[-0.48px] text-gray-700">
            {intl.formatMessage({ id: 'monetaryTable.reference' })}
          </p>
          {references.length > 1 && (
            <button
              type="button"
              onClick={() => {
                onExpandReference?.(true)
              }}>
              <Icons.ArrowRightShort className="text-black" />
            </button>
          )}
        </div>
      )
    case 'all-references':
      return !isEmpty(references) ? (
        <div
          className={cn(
            'flex h-6 items-start justify-between rounded-t-lg border-x border-t border-x-gray-400 border-t-gray-400 bg-gray-100 pb-1.5 pl-1.5 pr-0 pt-1',
            className
          )}
          style={{ width: references.length * baseColumnWidth + (withDividerRight ? 0 : 1) }}>
          {onExpandReference && (
            <button
              type="button"
              className="absolute"
              onClick={() => {
                onExpandReference(false)
              }}>
              <Icons.ArrowLeftSquareFill className="text-blue-600" />
            </button>
          )}
          {references.length !== 1 && (
            <p className="w-full select-none text-center text-base font-medium leading-none tracking-[-0.48px] text-gray-700">
              {intl.formatMessage({ id: 'monetaryTable.reference' })}
            </p>
          )}
        </div>
      ) : (
        <div />
      )
    case 'historic':
      return (
        <div className="flex h-6 w-36 items-start gap-1.5 rounded-t-lg border-x border-t border-x-gray-400 border-t-gray-400 bg-gray-100 pb-1.5 pl-1.5 pr-0 pt-1">
          <p className="select-none text-center text-base font-medium leading-none tracking-[-0.48px] text-gray-700">
            {intl.formatMessage({ id: 'monetaryTable.historic' })}
          </p>
          {references.length > 1 && onExpandReference && (
            <button
              type="button"
              onClick={() => {
                onExpandReference(true)
              }}>
              <Icons.ArrowRightShort className="text-black" />
            </button>
          )}
        </div>
      )
    case 'all-historic':
      return (
        <div
          className="flex h-6 items-start gap-1.5 rounded-t-lg border-x border-t border-x-gray-400 border-t-gray-400 bg-gray-100 pb-1.5 pl-1.5 pr-0 pt-1"
          style={{ width: references.length > 0 ? references.length * 144 : 144 }}>
          {onExpandReference && (
            <button
              type="button"
              className="absolute"
              onClick={() => {
                onExpandReference(false)
              }}>
              <Icons.ArrowLeftSquareFill className="text-blue-600" />
            </button>
          )}
          <p className="w-full select-none text-center text-base font-medium leading-none tracking-[-0.48px] text-gray-700">
            {intl.formatMessage({ id: 'monetaryTable.historic' })}
          </p>
        </div>
      )
    case 'projections':
      return (
        withNavigation &&
        blueprint.tabs && (
          <div className="relative z-0">
            <ControllableTabList
              activeValue={currentBlueprintTab ?? ''}
              tabs={blueprint.tabs}
              className="absolute -bottom-px z-10"
              onTabChange={onTabChange}
            />
            <div className="h-6" />
          </div>
        )
      )
    case 'growth':
      return <div className={cn('w-0 shrink-0 bg-white', className)} />
    default:
      return <div className={cn('w-84 shrink-0 bg-white', className)} />
  }
}

const TableBottomNavigationCell: FC<{
  readonly type: MonetaryTableColumnType
  readonly className?: string
}> = ({ type, className }) => {
  const blueprint = useContext(MonetaryTableBlueprintContext)
  const { projections, withNavigation } = useContext(MonetaryTablePropsContext)

  switch (type) {
    case 'title':
      return <div className={cn('w-84 shrink-0 bg-white', className)} />
    case 'divider':
      return <MonetaryTableDividerCell className={className} />
    case 'projections':
      return withNavigation && blueprint.tabs?.length
        ? projections.map((projection) => <div key={projection} className={cn('h-1 bg-sky-800', className)} />)
        : null
    default:
      return <div className={cn('w-84 shrink-0 bg-white', className)} />
  }
}

const TableTopHeaderCell: FC<{
  readonly type: MonetaryTableColumnType
  readonly section: MonetaryTableSection
  readonly title?: string
  readonly isTopHeader?: boolean
  readonly withDividerRight?: boolean
  readonly className?: string
  readonly topHeaderClassName?: string
  readonly topHeaderDimension?: MonetaryTableTopHeaderDimension
  readonly topHeaderVariant?: MonetaryTableTopHeaderVariant
}> = ({
  type,
  section,
  title,
  className,
  topHeaderClassName,
  topHeaderDimension = 'sm',
  topHeaderVariant,
  withDividerRight,
  isTopHeader = false,
}) => {
  const { onReferenceChange } = useContext(MonetaryTableActionsContext)
  const { readonly, selectedReferenceYear, referenceLabel, references, projections } =
    useContext(MonetaryTablePropsContext)

  switch (type) {
    case 'title':
      return <div className={cn('w-84 shrink-0 bg-white', className, topHeaderClassName)} />
    case 'title-with-add':
    case 'title-with-add-investment':
    case 'title-with-selected-tab':
      return (
        <>
          <TableSectionTitle section={section} />
          <div className={cn('w-84 shrink-0 bg-white', className, topHeaderClassName)} />
        </>
      )
    case 'divider':
      return <MonetaryTableDividerCell className={cn(className, topHeaderClassName)} />
    case 'reference':
    case 'historic':
      return (
        <HeaderLabel
          dimension={topHeaderDimension}
          label={referenceLabel ?? ''}
          withDividerRight={withDividerRight}
          className={cn('w-36', className, topHeaderClassName)}
        />
      )
    case 'all-references':
      return references.map((year) =>
        readonly || !onReferenceChange ? (
          <HeaderLabel
            key={year}
            label={year}
            dimension={topHeaderDimension}
            withDividerRight={withDividerRight}
            className={cn('w-36', className, topHeaderClassName)}
          />
        ) : (
          <HeaderSelect
            key={year}
            label={year}
            active={year === selectedReferenceYear}
            withDividerRight={withDividerRight}
            onClick={() => onReferenceChange(year)}
            className={cn('w-36', className, topHeaderClassName)}
          />
        )
      )
    case 'all-historic':
      return references.map((aReference) => (
        <HeaderLabel
          key={aReference}
          dimension={topHeaderDimension}
          label={aReference}
          withDividerRight={withDividerRight}
          className={cn('w-36', className, topHeaderClassName)}
        />
      ))
    case 'projections-with-proportions':
      return projections.map((year) => (
        <HeaderLabel
          key={year}
          variant={topHeaderVariant}
          dimension={topHeaderDimension}
          label={year}
          withDividerTop={isTopHeader}
          className={cn('w-36', className, topHeaderClassName)}
          withDividerRight={withDividerRight}
        />
      ))
    case 'finance-investment-amount':
    case 'finance-investment-type':
    case 'finance-investment-total':
    case 'finance-short-term-amount':
    case 'finance-loan-starting-balance':
    case 'finance-loan-interest':
    case 'finance-loan-duration':
    case 'growth':
      return (
        <HeaderLabel
          dimension={topHeaderDimension}
          variant={topHeaderVariant}
          label={title ?? ''}
          withDividerTop={isTopHeader}
          withDividerRight={withDividerRight}
          className={cn(className, topHeaderClassName)}
        />
      )
    case 'result-projections':
    case 'projections':
      return projections.map((year) => (
        <HeaderLabel
          key={year}
          variant={topHeaderVariant}
          dimension={topHeaderDimension}
          label={year}
          withDividerTop={isTopHeader}
          withDividerRight={withDividerRight}
          className={cn('w-24', className, topHeaderClassName)}
        />
      ))
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`TableTopHeaderCell: Unknown column type ${type}`)
  }
}

const TableBottomHeaderCell: FC<{
  readonly type: MonetaryTableColumnType
  readonly section: MonetaryTableSection
  readonly withDividerRight?: boolean
  readonly valueMeasureUnit?: string
  readonly topHeaderVariant?: MonetaryTableTopHeaderVariant
  readonly className?: string
}> = ({ type, section, withDividerRight, valueMeasureUnit, topHeaderVariant, className }) => {
  const intl = useIntl()
  const blueprint = useContext(MonetaryTableBlueprintContext)
  const currentBlueprintTab = useContext(MonetaryTableCurrentBlueprintTabContext)
  const currentWeight = useContext(MonetaryTableCurrentWeightContext)

  const { references, projections, readonly } = useContext(MonetaryTablePropsContext)
  const isPercentage = ['growth', 'finance-loan-interest'].includes(type)
  const label = (currentWeight && blueprint.weightsTitle?.[currentWeight]) ?? '$'

  const selectedBlueprintTab = blueprint.tabs?.find((tab) => tab.value === currentBlueprintTab)

  switch (type) {
    case 'title':
      return (
        <>
          <TableSectionTitle section={section} />
          <div className={cn('w-84 shrink-0', className)} />
        </>
      )
    case 'title-with-add':
      return (
        <>
          {!readonly && (
            <div className={cn('absolute bottom-1 left-12 w-84 pl-1', className)}>
              <AddItemButton />
            </div>
          )}
          <div className={cn('w-84 shrink-0 bg-white', className)} />
        </>
      )
    case 'title-with-add-investment':
      return (
        <>
          {!readonly && (
            <div className={cn('absolute bottom-1 left-12 w-84 pl-1', className)}>
              <AddInvestmentSheet />
            </div>
          )}
          <div className={cn('w-84 shrink-0 bg-white', className)} />
        </>
      )
    case 'title-with-selected-tab':
      return (
        <>
          <div className={cn('absolute bottom-1 left-12 w-84', className)}>
            {selectedBlueprintTab && (
              <Label className="text-xl font-bold">
                {stringIsMessageId(selectedBlueprintTab.label)
                  ? intl.formatMessage({ id: selectedBlueprintTab.label })
                  : selectedBlueprintTab.label}
              </Label>
            )}
          </div>
          <div className={cn('w-84 shrink-0 bg-white', className)} />
        </>
      )
    case 'divider':
      return <MonetaryTableDividerCell className={cn(className)} />
    case 'all-references':
      return references.map((reference) => (
        <HeaderUnit
          key={reference}
          variant={topHeaderVariant}
          label={label}
          className={cn(className)}
          withDividerRight={withDividerRight}
        />
      ))
    case 'historic':
      return (
        <>
          <HeaderUnit label={label} className="w-24" />
          <HeaderUnit label="%" withDividerRight={withDividerRight} className="w-12 justify-start text-xxs" />
        </>
      )
    case 'all-historic':
      return references.map((year) => (
        <Fragment key={year}>
          <HeaderUnit label={label} className="w-24" />
          <HeaderUnit label="%" withDividerRight={withDividerRight} className="w-12 justify-start text-xxs" />
        </Fragment>
      ))
    case 'projections-with-proportions':
      return projections.map((year) => (
        <Fragment key={year}>
          <HeaderUnit label={label} className="w-24" />
          <HeaderUnit label="%" withDividerRight={withDividerRight} className="w-12 justify-start text-xxs" />
        </Fragment>
      ))
    case 'reference':
    case 'growth':
    case 'finance-investment-amount':
    case 'finance-investment-total':
    case 'finance-short-term-amount':
    case 'finance-loan-starting-balance':
    case 'finance-loan-interest':
    case 'finance-loan-duration':
      return (
        <HeaderUnit
          variant={topHeaderVariant}
          label={valueMeasureUnit ?? (isPercentage ? '%' : '$')}
          withDividerRight={withDividerRight}
          className={cn(className)}
        />
      )
    case 'finance-investment-type':
      return (
        <HeaderUnit variant={topHeaderVariant} label="" withDividerRight={withDividerRight} className={cn(className)} />
      )

    case 'result-projections':
    case 'projections':
      return projections.map((year) => (
        <HeaderUnit
          key={year}
          variant={topHeaderVariant}
          label={label}
          className={cn('w-24', className)}
          withDividerRight={withDividerRight}
        />
      ))
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`TableBottomHeaderCell: Unknown column type ${type}`)
  }
}
const TableTopNavHeader: FC = () => {
  const blueprint = useContext(MonetaryTableBlueprintContext)
  const currentBlueprintState = useContext(MonetaryTableCurrentBlueprintStateContext)
  const blueprintColumns = useContext(MonetaryTableCurrentBlueprintColumnsContext)
  const columns = blueprintColumns.map(({ topNavClassName, className, ...column }) => ({
    ...column,
    className: topNavClassName ?? className,
  }))

  const callout = currentBlueprintState && blueprint.callouts?.[currentBlueprintState]
  const stickyColumns = columns.filter((col) => col.sticky || col.headerSticky)
  const scrollableColumns = columns.filter((col) => !col.sticky && !col.headerSticky)

  return (
    <div className="relative flex shrink-0">
      <MonetaryTableStickyColumns columns={stickyColumns}>
        {({ id, ...column }) => <TableTopNavigationCell key={id} {...column} />}
      </MonetaryTableStickyColumns>

      {scrollableColumns.map(({ id, ...colum }) => (
        <TableTopNavigationCell key={id} {...colum} />
      ))}
      {callout && (
        <div className="relative flex">
          <div className="absolute left-4 top-4">
            <Callout label={callout} />
          </div>
        </div>
      )}
    </div>
  )
}

export interface MonetaryTableSectionHeaderProps {
  readonly section: MonetaryTableSection
  readonly isTopHeader?: boolean
  readonly className?: string
}

export const MonetaryTableSectionHeader: FC<MonetaryTableSectionHeaderProps> = ({
  section,
  isTopHeader,
  className,
}) => {
  const { showBottomHeader = true, noHeader, className: sectionClassName } = section
  const blueprintColumns = useContext(MonetaryTableCurrentBlueprintColumnsContext)
  const { stickyTop } = useContext(MonetaryTablePropsContext)
  const stickyColumns = blueprintColumns.filter((col) => col.sticky)
  const scrollableColumns = blueprintColumns.filter((col) => !col.sticky)

  if (noHeader) {
    return null
  }

  return (
    <>
      {isTopHeader && <TableTopNavHeader />}
      <div
        className={cn('sticky z-20 print:static', sectionClassName)}
        style={{ top: stickyTop }}
        id="sticky-monetary-table-top-elements">
        <div className="relative flex shrink-0">
          <MonetaryTableStickyColumns columns={stickyColumns}>
            {({ id, ...column }) => <TableBottomNavigationCell key={`${section.id}-${id}`} {...column} />}
          </MonetaryTableStickyColumns>

          {scrollableColumns.map(({ id, ...colum }) => (
            <TableBottomNavigationCell key={`${section.id}-${id}`} {...colum} />
          ))}
        </div>

        <div className="relative flex shrink-0">
          <MonetaryTableStickyColumns columns={stickyColumns} className={className}>
            {({ id, ...column }) => (
              <TableTopHeaderCell key={`${section.id}-${id}`} {...column} isTopHeader={isTopHeader} section={section} />
            )}
          </MonetaryTableStickyColumns>

          {scrollableColumns.map(({ id, ...colum }) => (
            <TableTopHeaderCell key={`${section.id}-${id}`} {...colum} isTopHeader={isTopHeader} section={section} />
          ))}
        </div>

        <div className="relative flex shrink-0">
          <MonetaryTableStickyColumns columns={stickyColumns} className={className}>
            {({ id, ...column }) =>
              showBottomHeader || column.type === 'title' ? (
                <TableBottomHeaderCell key={`${section.id}-${id}`} {...column} section={section} />
              ) : null
            }
          </MonetaryTableStickyColumns>

          {scrollableColumns.map(({ id, ...colum }) =>
            showBottomHeader ? <TableBottomHeaderCell key={`${section.id}-${id}`} {...colum} section={section} /> : null
          )}
        </div>
      </div>
    </>
  )
}
