import { Timestamp } from 'firebase/firestore'

export const deepTimestampToDate = <T>(value: T): T => {
  switch (true) {
    case value instanceof Date:
      return value
    case value instanceof Timestamp:
      return value.toDate() as T
    case typeof value === 'string':
      return value
    case Array.isArray(value):
      return value.map((v) => deepTimestampToDate(v) as T[keyof T]) as T
    case value instanceof Object:
      return Object.fromEntries(Object.entries(value).map(([key, v]) => [key, deepTimestampToDate(v)])) as T
    default:
      return value
  }
}
