import { first, map, of, switchMap, tap } from 'rxjs'

import { type ScenarioRxDatabase } from '../types.ts'
import { rxDocumentData } from '../utils/rxDocumentData.ts'

export const rxdbScenarioData$ = (database: ScenarioRxDatabase) =>
  database.scenario.find().$.pipe(
    map((scenarios) => {
      if (scenarios.length !== 1) {
        throw new Error('Expected exactly one scenario')
      }
      return rxDocumentData(scenarios[0])
    }),
    tap((scenario) => {
      if (import.meta.env.DEV) {
        performance.mark(`rxdb-scenario-data:${scenario.currentStateId}:start`)
      }
    }),
    switchMap((scenario) =>
      database['scenario-states'].findOne(scenario.currentStateId).$.pipe(
        first(),
        map((scenarioState) => {
          if (!scenarioState) {
            throw new Error('Missing scenario state')
          }
          return rxDocumentData(scenarioState)
        }),
        map((scenarioState) => ({
          scenario,
          scenarioState,
        }))
      )
    ),
    switchMap(({ scenario, scenarioState }) =>
      !scenarioState.referenceId
        ? of({ scenario, scenarioState, reference: undefined })
        : database.reference.findOne(scenarioState.referenceId).$.pipe(
            first(),
            map((reference) => {
              if (!reference) {
                throw new Error('Missing scenario reference')
              }
              return rxDocumentData(reference)
            }),
            map((reference) => ({ scenario, scenarioState, reference }))
          )
    )
  )
