import type React from 'react'
import { useIntl } from 'react-intl'

import { type BundledEditorProps } from '@via/frontend-tinymce'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { Label } from '../../atoms/Label/Label'

import { SortableBundledEditors } from './content/SortableBundledEditors'
import { type ReportFormData } from './ReportFormZod.ts'

export interface ReportConfigurationFormContentProps extends Pick<BundledEditorProps, 'onImageUpload'> {}

export const ReportConfigurationFormContent: React.FC<ReportConfigurationFormContentProps> = ({ onImageUpload }) => {
  const intl = useIntl()

  return (
    <div className="w-[1068px]">
      <div id="content" className="absolute mt-[-132px]" />
      <div className="flex w-full flex-col justify-center pt-8">
        <FormField<ReportFormData, 'contents'>
          name="contents"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <>
                  <Label className="mb-2 select-none text-2xl font-bold">
                    {intl.formatMessage({ id: 'reportConfiguration.form.content' })}
                  </Label>
                  <SortableBundledEditors
                    onChange={field.onChange}
                    items={field.value}
                    onImageUpload={onImageUpload}
                    height={150}
                  />
                </>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  )
}
