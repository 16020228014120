import { type FC, useMemo } from 'react'

import { cn } from '../../../lib/utils.ts'
import { type OverridableMonetaryCellData } from '../../../model'

import { type MonetaryTableCellProps } from './MonetaryTableCellProps.ts'
import { MonetaryTableValueCell } from './MonetaryTableValueCell.tsx'

export interface MonetaryTableProportionCellProps extends MonetaryTableCellProps {
  readonly cellData?: OverridableMonetaryCellData
  readonly withDividerRight?: boolean
  readonly className?: string
}

export const MonetaryTableProportionCell: FC<MonetaryTableProportionCellProps> = ({
  column,
  line,
  rowData,
  cellData,
  withDividerRight,
  loading,
  className,
}) => {
  const proportionCellData = useMemo(
    () => (cellData?.proportion ? { value: cellData.proportion } : undefined),
    [cellData?.proportion]
  )
  return (
    <MonetaryTableValueCell
      editable={false}
      type="proportion"
      numberDisplayType="proportion"
      column={column}
      line={line}
      cellData={proportionCellData}
      rowData={rowData}
      loading={loading}
      withDividerRight={withDividerRight !== false && column.withDividerRight}
      className={cn('items-center justify-start text-xxs font-light', className)}
    />
  )
}
