import { lastValueFrom, NEVER, skipWhile, switchMap, take, takeUntil, timer } from 'rxjs'

import { type AppRxDatabase, type SnapshotRxDocument } from '../types.ts'

export const waitForSnapshot = (database: AppRxDatabase, snapshot: 'budgets' | 'clients', timeout = 10_000) =>
  lastValueFrom(
    database.snapshots.getLocal$<SnapshotRxDocument>(snapshot).pipe(
      switchMap((a) => a?.get$('isLoading') ?? NEVER),
      takeUntil(timer(timeout)),
      skipWhile((isLoading: boolean) => isLoading),
      take(1)
    )
  )
