import 'dayjs/locale/fr-ca'

import type React from 'react'
import { IntlProvider } from 'react-intl'

import dayjs from 'dayjs'

import DefaultFrench from './fr.json'
import { TableFrench } from './TableFrench'

const fr = {
  ...DefaultFrench,
  ...TableFrench,
}

dayjs.locale('fr-ca')

export const L10NProvider: React.FC<React.PropsWithChildren> = ({ children }) => (
  <IntlProvider locale="fr-ca" defaultLocale="fr" messages={fr}>
    {children}
  </IntlProvider>
)
