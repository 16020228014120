import { forwardRef } from 'react'
import { useIntl } from 'react-intl'

import { useMediaQuery } from '@react-hookz/web/useMediaQuery/index.js'

import { Icons } from '../../atoms'
import { cn } from '../../lib/utils'
import { AppNavigationBar, type AppNavigationBarProps } from '../../molecules/NavigationBar/AppNavigationBar'
import { AppSelectNavigationBar } from '../../molecules/NavigationBar/AppSelectNavigationBar'
import {
  UserAvatarDropdownMenu,
  type UserAvatarDropdownMenuProps,
} from '../../molecules/UserAvatarDropdownMenu/UserAvatarDropdownMenu'

import { Header, type HeaderProps } from './Header'

export interface AppHeaderProps extends HeaderProps {
  readonly currentNavigationId?: AppNavigationBarProps['active']
  readonly avatar: UserAvatarDropdownMenuProps
  readonly offline?: boolean
}

export const AppHeader = forwardRef<HTMLDivElement, AppHeaderProps>(
  ({ currentNavigationId, avatar, offline = false, children, ...props }, ref) => {
    const intl = useIntl()
    const labels = {
      budgets: intl.formatMessage({ id: 'mainNav.budgets' }),
      clients: intl.formatMessage({ id: 'mainNav.clients' }),
      referencesValues: intl.formatMessage({ id: 'mainNav.referencesValues' }),
    }

    const isLargeOrLarger = useMediaQuery('(min-width: 1024px)')

    return (
      <Header ref={ref} {...props}>
        <div className={cn('flex h-full w-full items-center justify-between pl-3')}>
          <div className="flex items-center">
            {isLargeOrLarger ? (
              <AppNavigationBar active={currentNavigationId} labels={labels} offline={offline} />
            ) : (
              <AppSelectNavigationBar active={currentNavigationId} labels={labels} offline={offline} />
            )}
          </div>
          <div className={cn('flex h-full items-center gap-4')}>
            {children}
            {offline && <Icons.Offline className="text-fuschia-800" />}
            <div className={cn('flex h-full items-center pr-4')}>
              <UserAvatarDropdownMenu {...avatar} />
            </div>
          </div>
        </div>
      </Header>
    )
  }
)
