import { z } from 'zod'

export const FormFiniteNumberValueZod = z
  .number()
  .finite()
  .pipe(z.coerce.string())
  .or(z.literal(''))
  .or(z.string().refine((val) => Number.isFinite(Number(val)), { message: 'form.invalid.number' }))
  .optional()
  .default('')
