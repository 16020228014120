import { forwardRef, type InputHTMLAttributes } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'
import { type Except } from 'type-fest'

import { cn } from '../../lib/utils'

const inputVariants = cva(
  'flex w-full items-center rounded px-1.5 py-0 font-normal transition-colors placeholder:italic placeholder:text-gray-600 focus-visible:outline-none focus-visible:ring-1 disabled:bg-gray-200 disabled:shadow-none disabled:border-gray-200 disabled:placeholder:text-gray-700 text-black disabled:text-gray-700',
  {
    variants: {
      variant: {
        default: 'focus-visible:border-blue-500 focus-visible:ring-blue-500',
        calculate: 'focus-visible:border-orange-400 focus-visible:ring-orange-400',
        external: 'focus-visible:border-green-400 focus-visible:ring-green-400',
        gray: 'focus-visible:border-gray-500 focus-visible:ring-gray-500',
      },
      dataStatus: {
        default: '',
        updated: '',
      },
      dimension: {
        sm: 'h-8 text-sm leading-none tracking-[-0.28px]',
        xs: 'h-6 text-xs leading-[0.5] tracking-[-0.24px]',
      },
      readOnly: {
        false:
          'border border-gray-300 bg-white shadow-input hover:border-gray-600 focus-visible:border-1 focus-visible:shadow-none disabled:cursor-not-allowed',
        true: 'border-0',
      },
      align: {
        left: 'text-left',
        right: 'text-right',
      },
    },
    defaultVariants: {
      variant: 'default',
      dataStatus: 'default',
      dimension: 'sm',
      readOnly: false,
      align: 'left',
    },
    compoundVariants: [
      {
        dataStatus: 'updated',
        variant: 'default',
        class: 'bg-cyan-100 disabled:bg-cyan-100 font-bold',
      },
      {
        readOnly: true,
        variant: 'default',
        class: 'bg-sky-50 disabled:bg-sky-50',
      },
      {
        readOnly: true,
        variant: 'gray',
        class: 'bg-gray-200 disabled:bg-gray-200 border-gray-200 placeholder:text-gray-700 text-black',
      },
      {
        readOnly: true,
        variant: 'calculate',
        class: 'bg-orange-100 disabled:bg-orange-100 disabled:text-black',
      },
      {
        readOnly: true,
        variant: 'external',
        class: 'bg-green-100 disabled:bg-green-100 disabled:text-black',
      },
    ],
  }
)

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    Except<VariantProps<typeof inputVariants>, 'readOnly'> {}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type = 'text',
      readOnly,
      variant = 'default',
      dataStatus = 'default',
      dimension = 'sm',
      align = 'left',
      ...props
    },
    ref
  ) => (
    <input
      type={type}
      readOnly={readOnly}
      className={cn(inputVariants({ variant, dataStatus, dimension, readOnly, align }), className)}
      ref={ref}
      {...props}
    />
  )
)
