import { type FC, forwardRef, useMemo } from 'react'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import {
  AnimalProductions,
  type AnimalProductionType,
  type BlueprintVersion,
  BuildingInvestmentSubTypes,
  LandInvestmentSubTypes,
  MachineryInvestmentsSubTypes,
  OtherInvestmentSubTypes,
  type ProductionType,
  PurchaseSaleInvestmentsTypes,
  QuotaFromProductions,
} from '@via/schema'
import { intersection, pick, sortBy, sumBy, without } from 'lodash-es'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { Select } from '../../atoms/Select/Select'
import { Textarea } from '../../atoms/Textarea/Textarea'
import { cn } from '../../lib/utils'
import { DateInputFormField } from '../../molecules/DateInputFormField/DateInputFormField'
import { FormatedNumberReadonlyInputWithLabel } from '../../molecules/FormatedNumberReadonlyInputWithLabel/FormatedNumberReadonlyInputWithLabel'
import { InputFormField } from '../../molecules/InputFormField/InputFormField'
import {
  SwitchAccordion,
  SwitchAccordionContent,
  SwitchAccordionItem,
  SwitchAccordionTrigger,
} from '../../molecules/SwitchAccordion/SwitchAccordion'
import { InvestmentCategoryRadioGroupFormField } from '../InvestmentCategoryRadioGroupFormField/InvestmentCategoryRadioGroupFormField.tsx'
import { SheetFormFooter } from '../SheetFormProvider'

import { InvestmentDisbursementForm } from './InvestmentDisbursementForm'
import { InvestmentFormProvider, type InvestmentFormProviderProps } from './InvestmentFormProvider'
import { type InvestmentFormData } from './InvestmentFormZod'
import { InvestmentSubventionForm } from './InvestmentSubventionForm'

interface MainContentProps {
  readonly enabledProductions: ReadonlyArray<ProductionType>
  readonly blueprintVersion: BlueprintVersion
}

const getSubTypeFromType = (
  type: InvestmentFormData['type'],
  blueprintVersion: BlueprintVersion,
  enabledProductions: ReadonlyArray<ProductionType> = []
) => {
  switch (type) {
    case 'land':
      return LandInvestmentSubTypes
    case 'building':
      return BuildingInvestmentSubTypes
    case 'animals': {
      const enabledAnimalProductions = intersection(AnimalProductions, enabledProductions) as AnimalProductionType[]
      return blueprintVersion === 'complete-2024'
        ? enabledAnimalProductions
        : (['calf-animals', 'milk-animals', ...enabledAnimalProductions] as const)
    }
    case 'quota': {
      const quotasFromProduction = Object.values(pick(QuotaFromProductions, enabledProductions))
      return blueprintVersion === 'complete-2024' ? without(quotasFromProduction, 'quotas.milk') : quotasFromProduction
    }
    case 'machinery':
      return MachineryInvestmentsSubTypes
    case 'other':
      return OtherInvestmentSubTypes
    default:
      return []
  }
}

const SubTypeSelect: FC<MainContentProps> = ({ blueprintVersion, enabledProductions }) => {
  const intl = useIntl()
  const type = useWatch<InvestmentFormData, 'type'>({ name: 'type' })
  const subTypes = getSubTypeFromType(type, blueprintVersion, enabledProductions)

  const options = useMemo(
    () =>
      sortBy(
        subTypes.map((subType) => ({
          value: subType,
          text: intl.formatMessage({ id: `investment.form.subtype.${subType}` }),
        })),
        'text'
      ),
    [intl, subTypes]
  )

  return (
    <FormField<InvestmentFormData, 'subtype'>
      name="subtype"
      render={({ field }) => (
        <FormItem className="col-span-2">
          <FormControl>
            <Select
              placeholder={intl.formatMessage({ id: 'investment.form.subtype.placeholder' })}
              options={options}
              onValueChange={(v) => {
                field.onChange(v)
              }}
              name={field.name}
              disabled={field.disabled || options.length === 0}
              value={field.value}
              initialValue={field.value}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const PurchaseSaleUnitInputs = () => {
  const intl = useIntl()
  const [type] = useWatch<InvestmentFormData, ['type']>({
    name: ['type'],
  })

  switch (type) {
    case 'land':
      return (
        <>
          <InputFormField<InvestmentFormData, 'units'>
            name="units"
            className="col-span-1"
            label={intl.formatMessage({
              id: 'investment.form.units.land',
            })}
          />
          <InputFormField<InvestmentFormData, 'pricePerUnit'>
            name="pricePerUnit"
            className="col-span-1"
            label={intl.formatMessage({
              id: 'investment.form.pricePerUnit.land',
            })}
          />
        </>
      )

    default:
      return null
  }
}

const PurchaseSaleMain: FC<MainContentProps> = ({ blueprintVersion, enabledProductions }) => {
  const [category, type, enabledOptions, disbursement] = useWatch<
    InvestmentFormData,
    ['category', 'type', 'enabledOptions', 'disbursement']
  >({
    name: ['category', 'type', 'enabledOptions', 'disbursement'],
    defaultValue: { category: 'purchase', enabledOptions: [], disbursement: {} },
  })
  const intl = useIntl()
  const { setValue } = useFormContext<InvestmentFormData>()

  const typeOptions = useMemo(
    () =>
      sortBy(
        PurchaseSaleInvestmentsTypes.map((purchaseSaleInvestmentType) => ({
          value: purchaseSaleInvestmentType,
          text: intl.formatMessage({ id: `investment.form.type.${purchaseSaleInvestmentType}` }),
        })),
        'text'
      ).filter(({ value }) => getSubTypeFromType(value, blueprintVersion, enabledProductions).length > 0),
    [blueprintVersion, enabledProductions, intl]
  )
  const isDisbursementEnabled = enabledOptions.includes('disbursement')
  const disbursementTotalValue = sumBy(Object.values(disbursement), ({ value }) => Number(value))

  return (
    <main className="grid grid-cols-4 items-end gap-4">
      <FormField<InvestmentFormData, 'type'>
        name="type"
        render={({ field }) => (
          <FormItem className="col-span-2">
            <FormControl>
              <Select
                placeholder={intl.formatMessage({ id: 'investment.form.type.placeholder' })}
                options={typeOptions}
                onValueChange={(v) => {
                  field.onChange(v)
                  setValue('subtype', undefined)
                }}
                name={field.name}
                disabled={field.disabled}
                value={field.value}
                initialValue={field.value}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {type && (
        <>
          <SubTypeSelect blueprintVersion={blueprintVersion} enabledProductions={enabledProductions} />

          <InputFormField<InvestmentFormData, 'description'>
            name="description"
            className="col-span-3"
            label={intl.formatMessage({ id: 'investment.form.description' })}
          />
          {isDisbursementEnabled ? (
            <FormatedNumberReadonlyInputWithLabel
              value={disbursementTotalValue}
              displayType="currency"
              variant="gray"
              label={intl.formatMessage({ id: 'investment.form.amount' })}
            />
          ) : (
            <InputFormField<InvestmentFormData, 'payment'>
              name="payment"
              className="col-span-1"
              label={intl.formatMessage({ id: 'investment.form.amount' })}
            />
          )}

          <DateInputFormField<InvestmentFormData, 'transactionDate'>
            name="transactionDate"
            className="col-span-1"
            label={intl.formatMessage({
              id:
                category === 'purchase'
                  ? 'investment.form.transactionDate.purchase'
                  : 'investment.form.transactionDate.sale',
            })}
          />
          <PurchaseSaleUnitInputs />

          <FormField<InvestmentFormData, 'note'>
            name="note"
            render={({ field }) => (
              <FormItem className="col-span-4 col-start-1 pt-1">
                <FormControl>
                  <Textarea {...field} placeholder={intl.formatMessage({ id: 'investment.form.note' })} />
                </FormControl>
              </FormItem>
            )}
          />
        </>
      )}
    </main>
  )
}

const FinancingMain: FC<MainContentProps> = ({ blueprintVersion, enabledProductions }) => {
  const intl = useIntl()

  const type = useWatch<InvestmentFormData, 'type'>({
    name: 'type',
  })

  const typeOptions = sortBy(
    PurchaseSaleInvestmentsTypes.filter((investmentType) => investmentType === 'machinery').map(
      (purchaseSaleInvestmentType) => ({
        value: purchaseSaleInvestmentType,
        text: intl.formatMessage({ id: `investment.form.type.${purchaseSaleInvestmentType}` }),
      })
    ),
    'text'
  )

  return (
    <main className="grid grid-cols-4 items-end gap-4">
      <FormField<InvestmentFormData, 'type'>
        name="type"
        render={({ field }) => (
          <FormItem className="col-span-2">
            <FormControl>
              <Select
                placeholder={intl.formatMessage({ id: 'investment.form.type.placeholder' })}
                options={typeOptions}
                onValueChange={(v) => {
                  field.onChange(v)
                }}
                name={field.name}
                disabled={field.disabled}
                value={field.value}
                initialValue={field.value}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {type && (
        <>
          <SubTypeSelect blueprintVersion={blueprintVersion} enabledProductions={enabledProductions} />
          <InputFormField<InvestmentFormData, 'description'>
            name="description"
            className="col-span-3"
            label={intl.formatMessage({ id: 'investment.form.description' })}
          />
          <InputFormField<InvestmentFormData, 'payment'>
            name="payment"
            className="col-span-1"
            label={intl.formatMessage({ id: 'investment.form.financing.amount' })}
          />
          <DateInputFormField<InvestmentFormData, 'transactionDate'>
            name="transactionDate"
            className="col-span-1"
            label={intl.formatMessage({ id: 'investment.form.transactionDate.loan' })}
          />
          <InputFormField<InvestmentFormData, 'duration'>
            name="duration"
            className="col-span-1"
            label={intl.formatMessage({ id: 'investment.form.duration' })}
          />
          <FormField<InvestmentFormData, 'note'>
            name="note"
            render={({ field }) => (
              <FormItem className="col-span-4 col-start-1 pt-1">
                <FormControl>
                  <Textarea {...field} placeholder={intl.formatMessage({ id: 'investment.form.note' })} />
                </FormControl>
              </FormItem>
            )}
          />
        </>
      )}
    </main>
  )
}
const CapitalMain = () => {
  const category = useWatch<InvestmentFormData, 'category'>({ name: 'category' })
  const intl = useIntl()

  return (
    <main className="grid grid-cols-4 items-end gap-4">
      <InputFormField<InvestmentFormData, 'description'>
        name="description"
        className="col-span-3"
        label={intl.formatMessage({ id: 'investment.form.description' })}
      />
      <InputFormField<InvestmentFormData, 'payment'>
        name="payment"
        className="col-span-1"
        label={intl.formatMessage({ id: 'investment.form.amount' })}
      />

      <DateInputFormField<InvestmentFormData, 'transactionDate'>
        name="transactionDate"
        className="col-span-1"
        label={intl.formatMessage({
          id:
            category === 'shares-purchase'
              ? 'investment.form.transactionDate.purchase'
              : category === 'shares-sale'
                ? 'investment.form.transactionDate.sale'
                : 'investment.form.transactionDate.date',
        })}
      />

      <FormField<InvestmentFormData, 'note'>
        name="note"
        render={({ field }) => (
          <FormItem className="col-span-4 col-start-1 pt-1">
            <FormControl>
              <Textarea {...field} placeholder={intl.formatMessage({ id: 'investment.form.note' })} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </main>
  )
}

const CategoryRadioGroup = () => {
  const { setValue } = useFormContext<InvestmentFormData>()

  const type = useWatch<InvestmentFormData, 'type'>({
    name: 'type',
  })

  return (
    <InvestmentCategoryRadioGroupFormField<InvestmentFormData, 'category'>
      name="category"
      onChange={(value) => {
        if (value === 'financing' && type !== 'machinery') {
          setValue('type', undefined)
          setValue('subtype', undefined)
        }
      }}
    />
  )
}
const MainContent: FC<MainContentProps> = ({ blueprintVersion, enabledProductions }) => {
  const category = useWatch<InvestmentFormData, 'category'>({ name: 'category', defaultValue: 'purchase' })

  switch (category) {
    case 'purchase':
    case 'sale':
      return <PurchaseSaleMain blueprintVersion={blueprintVersion} enabledProductions={enabledProductions} />
    case 'financing':
      return <FinancingMain blueprintVersion={blueprintVersion} enabledProductions={enabledProductions} />
    case 'capital':
    case 'shares-purchase':
    case 'shares-sale':
      return <CapitalMain />
    default:
      return null
  }
}

const PurchaseFooter = () => {
  const intl = useIntl()
  const {
    field: { onChange, ...field },
  } = useController<InvestmentFormData, 'enabledOptions'>({ name: 'enabledOptions' })

  return (
    <SwitchAccordion type="multiple" {...field} onValueChange={onChange}>
      <SwitchAccordionItem value="subvention">
        <SwitchAccordionTrigger id="subvention" label={intl.formatMessage({ id: 'investment.form.subvention' })} />
        <SwitchAccordionContent>
          <InvestmentSubventionForm />
        </SwitchAccordionContent>
      </SwitchAccordionItem>

      <SwitchAccordionItem value="disbursement">
        <SwitchAccordionTrigger id="disbursement" label={intl.formatMessage({ id: 'investment.form.disbursement' })} />
        <SwitchAccordionContent>
          <InvestmentDisbursementForm />
        </SwitchAccordionContent>
      </SwitchAccordionItem>
    </SwitchAccordion>
  )
}

const FooterContent = () => {
  const [category, type] = useWatch<InvestmentFormData, ['category', 'type']>({
    name: ['category', 'type'],
    defaultValue: { category: 'purchase' },
  })

  switch (true) {
    case type && category === 'purchase':
      return (
        <SheetFormFooter>
          <PurchaseFooter />
        </SheetFormFooter>
      )

    default:
      return <SheetFormFooter />
  }
}

export interface InvestmentFormProps extends InvestmentFormProviderProps, MainContentProps {}

export const InvestmentForm = forwardRef<HTMLFormElement, InvestmentFormProps>(
  ({ className, blueprintVersion, enabledProductions, ...props }, ref) => (
    <InvestmentFormProvider className={cn('w-[614px] bg-gray-100', className)} ref={ref} {...props}>
      <div className="flex flex-col gap-6 bg-gray-100 pb-6">
        <nav className="px-6 pt-6">
          <CategoryRadioGroup />
        </nav>

        <main className="bg-gray-100 pb-6 pl-6 pr-4 pt-4">
          <MainContent blueprintVersion={blueprintVersion} enabledProductions={enabledProductions} />
        </main>
      </div>

      <FooterContent />
    </InvestmentFormProvider>
  )
)
