import { type Except } from 'type-fest'

import { type MonetaryRowData } from '../../model'
import { MovementOverridableProjectionWidget } from '../../monetary/MovementOverridableProjectionWidget/MovementOverridableProjectionWidget.tsx'

import { PopoverEditableValueCell, type PopoverEditableValueCellProps } from './PopoverEditableValueCell'
import { type UpdateCellValue } from './UpdateCellValue.ts'

export interface EditableMovementProjectionCellProps extends Except<PopoverEditableValueCellProps, 'children'> {
  readonly rowData: MonetaryRowData
  readonly onUpdateCell: UpdateCellValue<'movement-overridable'>
}
export const EditableMovementProjectionCell = ({
  rowData,
  cellData,
  onUpdateCell,
  className,
  ...props
}: EditableMovementProjectionCellProps) => (
  <PopoverEditableValueCell cellData={cellData} className={className} {...props}>
    {({ onClose }) => (
      <MovementOverridableProjectionWidget
        value={cellData}
        onConfirm={async (value) => {
          await onUpdateCell({ type: 'movement-overridable', dataKey: rowData.key, ...value })
          onClose()
        }}
        onApply={async (value) => {
          await onUpdateCell({ type: 'movement-overridable', dataKey: rowData.key, ...value })
        }}
      />
    )}
  </PopoverEditableValueCell>
)
