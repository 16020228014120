import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { pick, times } from 'lodash-es'

import { Icons } from '../../atoms'
import { useClientBudgetListDataTableOptions } from '../../hooks/useClientBudgetListDataTableOptions.tsx'
import { usePersistedTableFilter } from '../../hooks/usePersistedTableFilter'
import { usePersistedTableSorting } from '../../hooks/usePersistedTableSorting'
import { DataTable, type DataTableColumnDef, DataTableColumnHeader } from '../../molecules/DataTable/DataTable'
import { SettingDropdownWithConfirmationMenu } from '../../molecules/SettingDropdownWithConfirmationMenu/SettingDropdownWithConfirmationMenu'
import { StatusBadge } from '../../molecules/StatusBadge/StatusBadge'
import { UserAvatarWithTooltip } from '../../molecules/UserAvatarWithTooltip/UserAvatarWithTooltip'
import { type BudgetData, type UserData } from '../../types'
import { useBudgetCreateDialog } from '../BudgetCreationDialog'

export interface ClientBudgetListDataTableData {
  readonly data: BudgetData[]
  readonly clientCode: string
  readonly offline: boolean
  readonly user: UserData | null
}

export interface ClientBudgetListDataTableActions {
  readonly onActionSelect: (id: string, action: 'duplicate' | 'archive' | 'open' | 'close') => Promise<void>
  readonly onNavigateToBudget: (budgetId: string) => Promise<void>
}

export interface ClientBudgetListDataTableProps
  extends ClientBudgetListDataTableData,
    ClientBudgetListDataTableActions {}

export const ClientBudgetListDataTable = ({
  clientCode,
  data,
  user,
  onActionSelect,
  onNavigateToBudget,
  offline,
}: ClientBudgetListDataTableProps) => {
  const { actionOptions } = useClientBudgetListDataTableOptions(offline)
  const { sorting, onSortingChange } = usePersistedTableSorting({
    key: 'budgetListSort',
    defaultValue: [{ id: 'year', desc: true }],
  })
  const intl = useIntl()

  const { selectedFilter: selectedBudgetStatus, onFilterChange: onFilterByStatusChange } = usePersistedTableFilter({
    key: 'clientBudgetListSelectedStatus',
  })

  const statusFilterOptions = useMemo(
    () => [
      { value: 'all', text: intl.formatMessage({ id: 'clientBudgetList.all' }) },
      { value: 'completed', text: intl.formatMessage({ id: 'clientBudgetList.completed' }) },
      { value: 'todo', text: intl.formatMessage({ id: 'clientBudgetList.todo' }) },
    ],
    [intl]
  )

  const filteredData = data.filter((budget) => {
    if (!selectedBudgetStatus || selectedBudgetStatus === 'all') {
      return true
    }
    return budget.status === selectedBudgetStatus
  })

  const { startCreationProcess } = useBudgetCreateDialog()
  const onNewClick = useCallback(
    (year?: string) => {
      if (!year) {
        return
      }

      startCreationProcess(year, data)
    },
    [startCreationProcess, data]
  )

  const columns = useMemo<Array<DataTableColumnDef<BudgetData>>>(
    () => [
      {
        id: 'contractId',
        accessorFn: (row) => row.contractId,
        headerClassName: 'w-32',
        cellClassName: 'px-6',
        // eslint-disable-next-line react/no-unstable-nested-components
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({ id: 'clientBudgetList.contract' })}
            className="w-32"
          />
        ),
      },
      {
        id: 'year',
        accessorFn: (row) => row.year,
        headerClassName: 'px-2 w-28',
        cellClassName: 'px-2 text-center',
        // eslint-disable-next-line react/no-unstable-nested-components
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({ id: 'clientBudgetList.firstBudgetedYear' })}
            className="w-28 justify-center"
          />
        ),
      },
      {
        id: 'budgetName',
        accessorFn: (row) => row.name,
        // eslint-disable-next-line react/no-unstable-nested-components
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title={intl.formatMessage({ id: 'clientBudgetList.budgetName' })} />
        ),
      },
      {
        id: 'creation',
        headerClassName: 'w-40',
        cellClassName: 'px-4',
        // eslint-disable-next-line react/no-unstable-nested-components
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title={intl.formatMessage({ id: 'clientBudgetList.createdDate' })} />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <div className="flex items-center gap-3">
            <span className="w-20">{intl.formatDate(row.original.creation.timestamp)}</span>
            <UserAvatarWithTooltip {...pick(row.original.creation, ['userName', 'userRole'])} />
          </div>
        ),
      },
      {
        id: 'lastModification',
        headerClassName: 'w-40',
        cellClassName: 'px-4',
        // eslint-disable-next-line react/no-unstable-nested-components
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title={intl.formatMessage({ id: 'clientBudgetList.updatedDate' })} />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) =>
          row.original.owner ? (
            <div className="flex items-center gap-3">
              <Icons.PersonFillLock className="flex w-20 justify-center" />
              <UserAvatarWithTooltip {...pick(row.original.owner, ['userName', 'userRole'])} />
            </div>
          ) : (
            <div className="flex items-center gap-3">
              <span className="w-20">{intl.formatDate(row.original.lastModification.timestamp)}</span>
              <UserAvatarWithTooltip {...pick(row.original.lastModification, ['userName', 'userRole'])} />
            </div>
          ),
      },
      {
        id: 'status',
        accessorKey: 'status',
        headerClassName: 'w-24',
        // eslint-disable-next-line react/no-unstable-nested-components
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({ id: 'clientBudgetList.status' })}
            className="justify-center"
          />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <StatusBadge
            variant={row.original.status}
            label={intl.formatMessage({ id: `budget.status.${row.original.status}` })}
          />
        ),
      },
      {
        id: 'actions',
        headerClassName: 'w-8',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <SettingDropdownWithConfirmationMenu
            options={actionOptions(row.original.readonly, row.original.owner?.userId === user?._id)}
            onItemSelect={(value) => onActionSelect(row.original._id, value)}
          />
        ),
      },
    ],
    [actionOptions, intl, user?._id, onActionSelect]
  )

  const newOptions = useMemo(() => {
    const currentYear = new Date().getFullYear()
    return times(9, (i) => {
      const year = currentYear - 3 + i
      return { text: year.toString(), value: year.toString() }
    })
  }, [])

  return (
    <DataTable
      tableId={`client-${clientCode}-budget-list-data-table`}
      titleLabel={intl.formatMessage({ id: 'clientBudgetList.title' })}
      emptyLabel={intl.formatMessage({ id: 'clientBudgetList.empty' })}
      searchPlaceholderLabel={intl.formatMessage({ id: 'clientBudgetList.search' })}
      newButtonLabel={intl.formatMessage({ id: 'clientBudgetList.new' })}
      filterOptions={{
        'status-filters': {
          options: statusFilterOptions,
          initialValue: selectedBudgetStatus,
          placeholder: intl.formatMessage({ id: 'clientBudgetList.statusPlaceholder' }),
          onChange: onFilterByStatusChange,
        },
      }}
      columns={columns}
      data={filteredData}
      sorting={sorting}
      filterKeys={[
        'contractId',
        'name',
        'client.code',
        'client.name',
        'client.nim',
        'client.contactInformation.email',
        'client.contactInformation.name',
        'client.contactInformation.phone',
        'client.address.city',
        'client.address.postalCode',
        'client.address.streetAddress',
      ]}
      onSortingChange={onSortingChange}
      onNewClick={onNewClick}
      newOptions={newOptions}
      onRowClick={async (id: string) => {
        const row = data[Number(id)]
        await onNavigateToBudget(row._id)
      }}
    />
  )
}
