import { type collections as serverCollections } from '@via/backend-schema'
import { type collections as frontendCollections } from '@via/frontend-schema'
import { mapValues } from 'lodash-es'
import { type SchemaDocumentOutput as FrontendSchemaDocumentOutput } from 'zod-firebase'
import { type SchemaDocumentOutput as ServerSchemaDocumentOutput } from 'zod-firebase-admin'

import { type ScenarioData } from '../types'

type ScenarioDocument =
  | FrontendSchemaDocumentOutput<typeof frontendCollections.budgets.scenarios>
  | ServerSchemaDocumentOutput<typeof serverCollections.budgets.scenarios>
type ScenarioStateDocument =
  | FrontendSchemaDocumentOutput<typeof frontendCollections.budgets.scenarios.states>
  | ServerSchemaDocumentOutput<typeof serverCollections.budgets.scenarios.states>
type ReferenceDocument =
  | FrontendSchemaDocumentOutput<typeof frontendCollections.budgets.references>
  | ServerSchemaDocumentOutput<typeof serverCollections.budgets.references>

export const scenarioData = (
  scenario: ScenarioDocument,
  state: ScenarioStateDocument,
  reference: ReferenceDocument | undefined
): ScenarioData => ({
  currentProductions: reference?.currentProductions ?? {},
  currentReferenceVersion: reference?.version,
  imported: {
    loans: mapValues(reference?.loans, (loan) => loan.description),
    assets: mapValues(reference?.assets, (asset) => asset.label),
  },
  ...state,
  // must be last to have to correct _id
  ...scenario,
})
