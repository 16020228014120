import { type BlueprintVersion } from '../../../enums'
import { type DataKey } from '../../data-key-zod'
import { dataKeyAttributes } from '../data-key-attributes'

export const resolveAlias = (
  key: DataKey,
  blueprintVersion?: BlueprintVersion,
  maxDepth?: number
): DataKey | undefined => {
  const attributes = dataKeyAttributes(key)
  switch (true) {
    case maxDepth && Number.isSafeInteger(maxDepth) && maxDepth <= 0:
      return key
    case attributes.type === 'alias':
      return resolveAlias(attributes.key, blueprintVersion, maxDepth && maxDepth - 1)
    case attributes.type === 'blueprint-alias': {
      const aliasKey = blueprintVersion && attributes.keys[blueprintVersion]
      return aliasKey && resolveAlias(aliasKey, blueprintVersion, maxDepth && maxDepth - 1)
    }
    default:
      return key
  }
}
