import { type FC, type PropsWithChildren } from 'react'

import { LoadingBar } from '../../atoms/LoadingBar/LoadingBar'
import { type NavigationHandler } from '../../navigation'
import { type BudgetData, type ReportData } from '../../types'
import { BudgetHeader, type BudgetHeaderProps } from '../Header/BudgetHeader'
import { BudgetLogoHeader } from '../Header/BudgetLogoHeader'
import { ReportNavigationItems } from '../ReportNavigationItems/ReportNavigationItems'
import { ReportsActionBar } from '../ReportsActionBar/ReportsActionBar'
import { ReportsTabList } from '../ReportsTabList/ReportsTabList'

export interface ReportMainLayoutProps extends BudgetHeaderProps {
  readonly budget: BudgetData
  readonly currentReport?: ReportData
  readonly reports?: ReadonlyArray<ReportData>
  readonly onNavigationChange: NavigationHandler
  readonly onAddReportClick?: () => Promise<void>
  readonly loading: boolean
}

export const ReportMainLayout: FC<PropsWithChildren<ReportMainLayoutProps>> = ({
  onCloseBudget,
  budget,
  currentReport,
  offline,
  reports = [],
  onRequestOwnership,
  onNavigationChange,
  onAddReportClick,
  loading,
  onUpdateBudget,
  children,
}) => (
  <>
    <div className="fixed inset-x-0 top-0 z-30 w-full gap-2">
      <div className="flex h-[84px] items-center gap-4 overflow-hidden bg-sky-100 pl-4 pt-3">
        <BudgetLogoHeader currentPage="reports" budget={budget} />
        <div className="flex flex-1 flex-col overflow-hidden">
          <div className="pr-4 pt-1">
            <BudgetHeader
              budget={budget}
              offline={offline}
              onUpdateBudget={onUpdateBudget}
              onCloseBudget={onCloseBudget}
              onRequestOwnership={onRequestOwnership}
            />
          </div>
          <div className="flex h-11 items-end justify-between">
            <div className="flex flex-1 overflow-x-scroll" style={{ scrollbarWidth: 'none' }}>
              {!offline && (
                <ReportsTabList
                  tabId={currentReport?._id}
                  budget={budget}
                  reports={reports}
                  onNavigationChange={onNavigationChange}
                  onAddReportClick={!budget.readonly ? onAddReportClick : undefined}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="z-20 flex h-12 bg-white">
        <ReportsActionBar disabled={offline} />
      </div>
      <div className="-mt-0.5 h-0.5 bg-sky-300" />
      <div className="z-40 mt-[-132px] h-2">{loading && <LoadingBar />}</div>
    </div>
    <div className="z-20 h-[132px]" />
    <div className="flex w-[1068px]">
      <ReportNavigationItems className="fixed z-40 min-h-full w-32 bg-white" disabled={offline} />
      {children}
    </div>
  </>
)
