import type React from 'react'

import { createFileRoute, Outlet } from '@tanstack/react-router'

import { ClientListProvider } from '../contexts/clientList/ClientListProvider.tsx'
import { waitForSnapshot } from '../rxdb/localSnapshot/waitForSnapshot.ts'

export const ClientListComponent: React.FC = () => (
  <ClientListProvider>
    <Outlet />
  </ClientListProvider>
)

export const Route = createFileRoute('/clients')({
  component: ClientListComponent,
  loader: async ({ context: { isOffline, appDatabase } }) => {
    if (!isOffline) {
      await waitForSnapshot(appDatabase, 'clients')
    }
  },
})
