import { VegetalProductions, type VegetalProductionsType } from '@via/schema'
import { BigNumber } from 'bignumber.js'

import {
  createBudgetDefinition,
  type CriReferenceFunction,
  type DatabaseResolverFnCompteExploitation,
  type DatabaseResolverFnInventaireApprovisionnement,
  type DatabaseResolverFnMaraicher,
  type TestResolver,
} from '../types'
import { isFermeBudgitel2022, sumBy } from '../utils'
import { type ViagritelTypeProductionMaraichere } from '../values'

const optionalTestResolver = (testValueForFermeBudgitel2022?: number, only = false) =>
  testValueForFermeBudgitel2022 || only
    ? {
        test: [
          {
            condition: isFermeBudgitel2022,
            expectedValue: new BigNumber(testValueForFermeBudgitel2022 ?? 0),
          },
        ],
        only,
      }
    : (false as const)

// Au CE, tous les codes maraîcher, ligne Fert.chimiques+ Fert. organiques +
// Inv. approv Maraîcher début $ - Fin  $, ligne Fertilisants chimiques + ligne Fertilisants organiques
const fertilizerExpenseResolver = (
  production: ViagritelTypeProductionMaraichere,
  testValueForFermeBudgitel2022?: number
) => ({
  // cris MA13 and MA16 cannot be used here to validate as they consider only the current year
  testResolver: optionalTestResolver(testValueForFermeBudgitel2022),
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        131_400, // Fert. chimiques  MA13
        131_500, // Fert. organiques MA16
      ]
      const ceCharges = sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')

      const iaLignes = [
        140_000, // Fertilisants chimiques  MA13
        140_100, // Fertilisants organiques MA16
      ]
      const iaDelta = sumBy(inventairesApprovisionnements({ production, ligne: iaLignes }), 'delta_dollars')

      return ceCharges.plus(iaDelta)
    },
  },
})

const generalInsuranceIncomeResolver = (
  production: ViagritelTypeProductionMaraichere,
  testValueForFermeBudgitel2022?: number
) => ({
  testResolver: optionalTestResolver(testValueForFermeBudgitel2022),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) =>
      sumBy(comptesExploitation({ production, ligne: 130_700 }), 'montant'), // Rev. ass. récoltes
  },
})

// Au CE, tous les codes maraîcher, ligne Cot. ass. récoltes
const harvestInsuranceFeeResolver = (production: ViagritelTypeProductionMaraichere) => ({
  testResolver: `${production}52` as TestResolver,
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) =>
      sumBy(comptesExploitation({ production, ligne: 132_700 }), 'montant'), // Cot. ass. récoltes MA52
  },
})

const inventoryIncomeResolver = (
  production: ViagritelTypeProductionMaraichere,
  testValueForFermeBudgitel2022?: number
) => ({
  testResolver: optionalTestResolver(testValueForFermeBudgitel2022),
  databaseResolver: {
    resolve: ({ maraicher }: { maraicher: DatabaseResolverFnMaraicher }) =>
      sumBy(maraicher({ production }), 'delta_dollars'),
  },
})

// Au CE, tous les codes maraîcher, lignes Mise en marché & publicité
const marketingExpenseResolver = (production: ViagritelTypeProductionMaraichere) => ({
  testResolver: ({ cri }: { cri: CriReferenceFunction }) => cri(`${production}37`),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) =>
      sumBy(comptesExploitation({ production, ligne: 132_200 }), 'montant'), // Mise en marché & publicité MA37
  },
})

// Au CE, tous les codes maraîcher, lignes Réseau de dépistage + Contenants & fichelle + Agents de conservation + Paillis + Autres approv. directs + Propane +
// Inv. approv. code Maraîcher début $ - Fin $, lignes Emballages, contenants et ficelles, Agents de conservation, Paillis, Autres approvisionnements directs, Propane
const miscellaneousSuppliesExpenseResolver = (
  production: ViagritelTypeProductionMaraichere,
  testValueForFermeBudgitel2022?: number
) => ({
  testResolver: optionalTestResolver(testValueForFermeBudgitel2022),
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        132_000, // Réseau de dépistage    Does not seem to have a reliable cri. DB says MA31, but the amount I see in Viagritel is not what I would expect
        132_100, // Contenants & ficelle   MA34
        132_300, // Agents de conservation MA40
        132_400, // Paillis                MA43
        132_500, // Autres approv. directs MA46
        132_600, // Propane                MA49
      ]
      const ceCharges = sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')

      const iaLignes = [
        140_600, // Emballages, contenants et ficelles MA34
        140_700, // Agents de conservation             MA40
        140_800, // Paillis                            MA43
        140_900, // Autres approvisionnements directs  MA46
        141_000, // Propane                            MA49
      ]
      const iaDelta = sumBy(inventairesApprovisionnements({ production, ligne: iaLignes }), 'delta_dollars')

      return ceCharges.plus(iaDelta)
    },
  },
})

// Au CE, tous les codes maraîcher, ligne Herbicides + ligne Autres pesticides +
// Inv. approv, code Maraîcher début $ - Fin $, ligne Herbicides, ligne autres pesticides
const pesticidesExpenseResolver = (production: ViagritelTypeProductionMaraichere) => ({
  testResolver: ({ cri }: { cri: CriReferenceFunction }) => cri(`${production}25`).plus(cri(`${production}28`)),
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        131_800, // Herbicides        MA25
        131_900, // Autres pesticides MA28
      ]
      const ceCharges = sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')

      const iaLignes = [
        140_400, // Herbicides        MA25
        140_500, // Autres pesticides MA28
      ]
      const iaDelta = sumBy(inventairesApprovisionnements({ production, ligne: iaLignes }), 'delta_dollars')

      return ceCharges.plus(iaDelta)
    },
  },
})

// Au CE, tous les codes maraîcher, ligne Achats pour revente
const resaleSuppliesExpenseResolver = (production: ViagritelTypeProductionMaraichere) => ({
  testResolver: ({ cri }: { cri: CriReferenceFunction }) => cri(`${production}11`),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) =>
      sumBy(comptesExploitation({ production, ligne: 131_300 }), 'montant'), // Achats pour revente MA11
  },
})

const miscellaneousIncomeResolver = (
  production: ViagritelTypeProductionMaraichere,
  testValueForFermeBudgitel2022?: number
) => ({
  testResolver: optionalTestResolver(testValueForFermeBudgitel2022),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) =>
      sumBy(comptesExploitation({ production, ligne: 130_900 }), 'montant'), // Autres revenus directs
  },
})

const salesIncomeResolver = (
  production: ViagritelTypeProductionMaraichere,
  testValueForFermeBudgitel2022?: number
) => ({
  testResolver: optionalTestResolver(testValueForFermeBudgitel2022),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) => {
      const ceLignes = [
        130_100, // Vte auto-cueillette GL8
        130_200, // Vte au détail       GL9
        130_300, // Vte grossiste       GL10
        130_400, // Vte conserverie     GL11
        130_500, // Vte autres          GL12
      ]
      return sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
    },
  },
})

// Au CE, tous les codes maraîcher, ligne Semences et plants + ligne Fabrication de plants + ligne Frais d'implantation +
// Inv. approv code Maraîcher début $ - Fin $, ligne Semences et plants, ligne Fabrication de plants
const seedSuppliesExpenseResolver = (
  production: ViagritelTypeProductionMaraichere,
  testValueForFermeBudgitel2022?: number
) => ({
  testResolver: optionalTestResolver(testValueForFermeBudgitel2022),
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        131_600, // Semences et plants
        131_700, // Fabrication de plants
        131_750, // Frais d'implantation
      ]
      const ceCharges = sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')

      const iaLignes = [
        140_200, // Semences et plants
        140_300, // Frais de fabrication de plants
      ]
      const iaDelta = sumBy(inventairesApprovisionnements({ production, ligne: iaLignes }), 'delta_dollars')

      return ceCharges.plus(iaDelta)
    },
  },
})

// Au CE, tous les codes maraîcher, ligne Cot. ass. stabilisation
const stabilizationInsuranceFeeResolver = (production: ViagritelTypeProductionMaraichere) => ({
  testResolver: `${production}55` as TestResolver,
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) =>
      sumBy(comptesExploitation({ production, ligne: 132_800 }), 'montant'), // Cot. ass. stabilisation MA55
  },
})

const stabilizationInsuranceIncomeResolver = (
  production: ViagritelTypeProductionMaraichere,
  testValueForFermeBudgitel2022?: number
) => ({
  testResolver: optionalTestResolver(testValueForFermeBudgitel2022),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) =>
      sumBy(comptesExploitation({ production, ligne: 130_800 }), 'montant'), // Rev. ass. stabilisation
  },
})

export const vegetal = createBudgetDefinition({
  'finance.asset.inventory.imported.vegetal.amount': {
    testResolver: false,
    dependencyResolver: {
      resolve: ({ val }) =>
        BigNumber.sum(
          ...VegetalProductions.map((production) => val(`vegetal.${production}.income.imported.inventory.products`))
        ),
      dependencies: VegetalProductions.map(
        (production) => `vegetal.${production}.income.imported.inventory.products`
      ) as Array<`vegetal.${VegetalProductionsType}.income.imported.inventory.products`>,
    },
  },
  'vegetal.all.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('AM'),
  'vegetal.all.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('AM'),
  'vegetal.all.expenses.imported.marketing': marketingExpenseResolver('AM'),
  'vegetal.all.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('AM'),
  'vegetal.all.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('AM'),
  'vegetal.all.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('AM'),
  'vegetal.all.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('AM'),
  'vegetal.all.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('AM'),
  'vegetal.all.income.imported.insurance.general': generalInsuranceIncomeResolver('AM'),
  'vegetal.all.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('AM'),
  'vegetal.all.income.imported.inventory.products': inventoryIncomeResolver('AM'),
  'vegetal.all.income.imported.miscellaneous': miscellaneousIncomeResolver('AM'),
  'vegetal.all.income.imported.sales.products': salesIncomeResolver('AM'),
  'vegetal.apple.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('PM'),
  'vegetal.apple.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('PM'),
  'vegetal.apple.expenses.imported.marketing': marketingExpenseResolver('PM'),
  'vegetal.apple.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('PM'),
  'vegetal.apple.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('PM'),
  'vegetal.apple.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('PM'),
  'vegetal.apple.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('PM'),
  'vegetal.apple.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('PM'),
  'vegetal.apple.income.imported.insurance.general': generalInsuranceIncomeResolver('PM'),
  'vegetal.apple.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('PM'),
  'vegetal.apple.income.imported.inventory.products': inventoryIncomeResolver('PM'),
  'vegetal.apple.income.imported.miscellaneous': miscellaneousIncomeResolver('PM'),
  'vegetal.apple.income.imported.sales.products': salesIncomeResolver('PM'),
  'vegetal.asparagus.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('AG'),
  'vegetal.asparagus.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('AG'),
  'vegetal.asparagus.expenses.imported.marketing': marketingExpenseResolver('AG'),
  'vegetal.asparagus.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('AG'),
  'vegetal.asparagus.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('AG'),
  'vegetal.asparagus.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('AG'),
  'vegetal.asparagus.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('AG'),
  'vegetal.asparagus.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('AG'),
  'vegetal.asparagus.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('AG'),
  'vegetal.asparagus.income.imported.insurance.general': generalInsuranceIncomeResolver('AG'),
  'vegetal.asparagus.income.imported.inventory.products': inventoryIncomeResolver('AG'),
  'vegetal.asparagus.income.imported.miscellaneous': miscellaneousIncomeResolver('AG'),
  'vegetal.asparagus.income.imported.sales.products': salesIncomeResolver('AG'),
  'vegetal.beet.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('BT'),
  'vegetal.beet.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('BT'),
  'vegetal.beet.expenses.imported.marketing': marketingExpenseResolver('BT'),
  'vegetal.beet.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('BT'),
  'vegetal.beet.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('BT'),
  'vegetal.beet.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('BT'),
  'vegetal.beet.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('BT'),
  'vegetal.beet.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('BT'),
  'vegetal.beet.income.imported.insurance.general': generalInsuranceIncomeResolver('BT'),
  'vegetal.beet.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('BT'),
  'vegetal.beet.income.imported.inventory.products': inventoryIncomeResolver('BT'),
  'vegetal.beet.income.imported.miscellaneous': miscellaneousIncomeResolver('BT'),
  'vegetal.beet.income.imported.sales.products': salesIncomeResolver('BT'),
  'vegetal.blueberry.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('BE'),
  'vegetal.blueberry.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('BE'),
  'vegetal.blueberry.expenses.imported.marketing': marketingExpenseResolver('BE'),
  'vegetal.blueberry.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('BE'),
  'vegetal.blueberry.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('BE'),
  'vegetal.blueberry.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('BE'),
  'vegetal.blueberry.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('BE'),
  'vegetal.blueberry.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('BE'),
  'vegetal.blueberry.income.imported.insurance.general': generalInsuranceIncomeResolver('BE'),
  'vegetal.blueberry.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('BE'),
  'vegetal.blueberry.income.imported.inventory.products': inventoryIncomeResolver('BE'),
  'vegetal.blueberry.income.imported.miscellaneous': miscellaneousIncomeResolver('BE'),
  'vegetal.blueberry.income.imported.sales.products': salesIncomeResolver('BE'),
  'vegetal.brocoli.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('BR'),
  'vegetal.brocoli.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('BR'),
  'vegetal.brocoli.expenses.imported.marketing': marketingExpenseResolver('BR'),
  'vegetal.brocoli.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('BR'),
  'vegetal.brocoli.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('BR'),
  'vegetal.brocoli.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('BR'),
  'vegetal.brocoli.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('BR'),
  'vegetal.brocoli.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('BR'),
  'vegetal.brocoli.income.imported.insurance.general': generalInsuranceIncomeResolver('BR'),
  'vegetal.brocoli.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('BR'),
  'vegetal.brocoli.income.imported.inventory.products': inventoryIncomeResolver('BR'),
  'vegetal.brocoli.income.imported.miscellaneous': miscellaneousIncomeResolver('BR'),
  'vegetal.brocoli.income.imported.sales.products': salesIncomeResolver('BR'),
  'vegetal.brussel-sprout.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CX'),
  'vegetal.brussel-sprout.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CX'),
  'vegetal.brussel-sprout.expenses.imported.marketing': marketingExpenseResolver('CX'),
  'vegetal.brussel-sprout.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CX'),
  'vegetal.brussel-sprout.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CX'),
  'vegetal.brussel-sprout.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CX'),
  'vegetal.brussel-sprout.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CX'),
  'vegetal.brussel-sprout.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CX'),
  'vegetal.brussel-sprout.income.imported.insurance.general': generalInsuranceIncomeResolver('CX'),
  'vegetal.brussel-sprout.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CX'),
  'vegetal.brussel-sprout.income.imported.inventory.products': inventoryIncomeResolver('CX'),
  'vegetal.brussel-sprout.income.imported.miscellaneous': miscellaneousIncomeResolver('CX'),
  'vegetal.brussel-sprout.income.imported.sales.products': salesIncomeResolver('CX'),
  'vegetal.cabbage.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CH'),
  'vegetal.cabbage.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CH'),
  'vegetal.cabbage.expenses.imported.marketing': marketingExpenseResolver('CH'),
  'vegetal.cabbage.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CH'),
  'vegetal.cabbage.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CH'),
  'vegetal.cabbage.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CH'),
  'vegetal.cabbage.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CH'),
  'vegetal.cabbage.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CH'),
  'vegetal.cabbage.income.imported.insurance.general': generalInsuranceIncomeResolver('CH'),
  'vegetal.cabbage.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CH'),
  'vegetal.cabbage.income.imported.inventory.products': inventoryIncomeResolver('CH'),
  'vegetal.cabbage.income.imported.miscellaneous': miscellaneousIncomeResolver('CH'),
  'vegetal.cabbage.income.imported.sales.products': salesIncomeResolver('CH'),
  'vegetal.camomille.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CE'),
  'vegetal.camomille.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CE'),
  'vegetal.camomille.expenses.imported.marketing': marketingExpenseResolver('CE'),
  'vegetal.camomille.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CE'),
  'vegetal.camomille.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CE'),
  'vegetal.camomille.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CE'),
  'vegetal.camomille.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CE'),
  'vegetal.camomille.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CE'),
  'vegetal.camomille.income.imported.insurance.general': generalInsuranceIncomeResolver('CE'),
  'vegetal.camomille.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CE'),
  'vegetal.camomille.income.imported.inventory.products': inventoryIncomeResolver('CE'),
  'vegetal.camomille.income.imported.miscellaneous': miscellaneousIncomeResolver('CE'),
  'vegetal.camomille.income.imported.sales.products': salesIncomeResolver('CE'),
  'vegetal.cannery-bean.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('HC'),
  'vegetal.cannery-bean.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('HC'),
  'vegetal.cannery-bean.expenses.imported.marketing': marketingExpenseResolver('HC'),
  'vegetal.cannery-bean.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('HC'),
  'vegetal.cannery-bean.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('HC'),
  'vegetal.cannery-bean.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('HC'),
  'vegetal.cannery-bean.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('HC'),
  'vegetal.cannery-bean.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('HC'),
  'vegetal.cannery-bean.income.imported.insurance.general': generalInsuranceIncomeResolver('HC'),
  'vegetal.cannery-bean.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('HC'),
  'vegetal.cannery-bean.income.imported.inventory.products': inventoryIncomeResolver('HC'),
  'vegetal.cannery-bean.income.imported.miscellaneous': miscellaneousIncomeResolver('HC'),
  'vegetal.cannery-bean.income.imported.sales.products': salesIncomeResolver('HC'),
  'vegetal.cannery-corn.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('MC'),
  'vegetal.cannery-corn.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('MC'),
  'vegetal.cannery-corn.expenses.imported.marketing': marketingExpenseResolver('MC'),
  'vegetal.cannery-corn.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('MC'),
  'vegetal.cannery-corn.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('MC'),
  'vegetal.cannery-corn.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('MC'),
  'vegetal.cannery-corn.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('MC'),
  'vegetal.cannery-corn.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('MC'),
  'vegetal.cannery-corn.income.imported.insurance.general': generalInsuranceIncomeResolver('MC'),
  'vegetal.cannery-corn.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('MC'),
  'vegetal.cannery-corn.income.imported.inventory.products': inventoryIncomeResolver('MC'),
  'vegetal.cannery-corn.income.imported.miscellaneous': miscellaneousIncomeResolver('MC'),
  'vegetal.cannery-corn.income.imported.sales.products': salesIncomeResolver('MC'),
  'vegetal.cannery-pea.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('PC'),
  'vegetal.cannery-pea.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('PC'),
  'vegetal.cannery-pea.expenses.imported.marketing': marketingExpenseResolver('PC'),
  'vegetal.cannery-pea.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('PC'),
  'vegetal.cannery-pea.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('PC'),
  'vegetal.cannery-pea.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('PC'),
  'vegetal.cannery-pea.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('PC'),
  'vegetal.cannery-pea.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('PC'),
  'vegetal.cannery-pea.income.imported.insurance.general': generalInsuranceIncomeResolver('PC'),
  'vegetal.cannery-pea.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('PC'),
  'vegetal.cannery-pea.income.imported.inventory.products': inventoryIncomeResolver('PC'),
  'vegetal.cannery-pea.income.imported.miscellaneous': miscellaneousIncomeResolver('PC'),
  'vegetal.cannery-pea.income.imported.sales.products': salesIncomeResolver('PC'),
  'vegetal.cantaloup.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CM'),
  'vegetal.cantaloup.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CM'),
  'vegetal.cantaloup.expenses.imported.marketing': marketingExpenseResolver('CM'),
  'vegetal.cantaloup.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CM'),
  'vegetal.cantaloup.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CM'),
  'vegetal.cantaloup.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CM'),
  'vegetal.cantaloup.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CM'),
  'vegetal.cantaloup.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CM'),
  'vegetal.cantaloup.income.imported.insurance.general': generalInsuranceIncomeResolver('CM'),
  'vegetal.cantaloup.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CM'),
  'vegetal.cantaloup.income.imported.inventory.products': inventoryIncomeResolver('CM'),
  'vegetal.cantaloup.income.imported.miscellaneous': miscellaneousIncomeResolver('CM'),
  'vegetal.cantaloup.income.imported.sales.products': salesIncomeResolver('CM'),
  'vegetal.cauliflower.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CF'),
  'vegetal.cauliflower.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CF'),
  'vegetal.cauliflower.expenses.imported.marketing': marketingExpenseResolver('CF'),
  'vegetal.cauliflower.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CF'),
  'vegetal.cauliflower.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CF'),
  'vegetal.cauliflower.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CF'),
  'vegetal.cauliflower.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CF'),
  'vegetal.cauliflower.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CF'),
  'vegetal.cauliflower.income.imported.insurance.general': generalInsuranceIncomeResolver('CF'),
  'vegetal.cauliflower.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CF'),
  'vegetal.cauliflower.income.imported.inventory.products': inventoryIncomeResolver('CF'),
  'vegetal.cauliflower.income.imported.miscellaneous': miscellaneousIncomeResolver('CF'),
  'vegetal.cauliflower.income.imported.sales.products': salesIncomeResolver('CF'),
  'vegetal.celery.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CL'),
  'vegetal.celery.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CL'),
  'vegetal.celery.expenses.imported.marketing': marketingExpenseResolver('CL'),
  'vegetal.celery.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CL'),
  'vegetal.celery.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CL'),
  'vegetal.celery.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CL'),
  'vegetal.celery.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CL'),
  'vegetal.celery.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CL'),
  'vegetal.celery.income.imported.insurance.general': generalInsuranceIncomeResolver('CL'),
  'vegetal.celery.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CL'),
  'vegetal.celery.income.imported.inventory.products': inventoryIncomeResolver('CL'),
  'vegetal.celery.income.imported.miscellaneous': miscellaneousIncomeResolver('CL'),
  'vegetal.celery.income.imported.sales.products': salesIncomeResolver('CL'),
  'vegetal.fava-bean.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('GO'),
  'vegetal.fava-bean.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('GO'),
  'vegetal.fava-bean.expenses.imported.marketing': marketingExpenseResolver('GO'),
  'vegetal.fava-bean.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('GO'),
  'vegetal.fava-bean.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('GO'),
  'vegetal.fava-bean.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('GO'),
  'vegetal.fava-bean.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('GO'),
  'vegetal.fava-bean.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('GO'),
  'vegetal.fava-bean.income.imported.insurance.general': generalInsuranceIncomeResolver('GO'),
  'vegetal.fava-bean.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('GO'),
  'vegetal.fava-bean.income.imported.inventory.products': inventoryIncomeResolver('GO'),
  'vegetal.fava-bean.income.imported.miscellaneous': miscellaneousIncomeResolver('GO'),
  'vegetal.fava-bean.income.imported.sales.products': salesIncomeResolver('GO'),
  'vegetal.field-tomato.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('TC'),
  'vegetal.field-tomato.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('TC'),
  'vegetal.field-tomato.expenses.imported.marketing': marketingExpenseResolver('TC'),
  'vegetal.field-tomato.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('TC'),
  'vegetal.field-tomato.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('TC'),
  'vegetal.field-tomato.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('TC'),
  'vegetal.field-tomato.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('TC'),
  'vegetal.field-tomato.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('TC'),
  'vegetal.field-tomato.income.imported.insurance.general': generalInsuranceIncomeResolver('TC'),
  'vegetal.field-tomato.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('TC'),
  'vegetal.field-tomato.income.imported.inventory.products': inventoryIncomeResolver('TC'),
  'vegetal.field-tomato.income.imported.miscellaneous': miscellaneousIncomeResolver('TC'),
  'vegetal.field-tomato.income.imported.sales.products': salesIncomeResolver('TC'),
  'vegetal.fir.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('SP'),
  'vegetal.fir.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('SP'),
  'vegetal.fir.expenses.imported.marketing': marketingExpenseResolver('SP'),
  'vegetal.fir.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('SP'),
  'vegetal.fir.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('SP'),
  'vegetal.fir.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('SP'),
  'vegetal.fir.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('SP'),
  'vegetal.fir.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('SP'),
  'vegetal.fir.income.imported.insurance.general': generalInsuranceIncomeResolver('SP'),
  'vegetal.fir.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('SP'),
  'vegetal.fir.income.imported.inventory.products': inventoryIncomeResolver('SP'),
  'vegetal.fir.income.imported.miscellaneous': miscellaneousIncomeResolver('SP'),
  'vegetal.fir.income.imported.sales.products': salesIncomeResolver('SP'),
  'vegetal.fresh-bean.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('HF'),
  'vegetal.fresh-bean.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('HF'),
  'vegetal.fresh-bean.expenses.imported.marketing': marketingExpenseResolver('HF'),
  'vegetal.fresh-bean.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('HF'),
  'vegetal.fresh-bean.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('HF'),
  'vegetal.fresh-bean.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('HF'),
  'vegetal.fresh-bean.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('HF'),
  'vegetal.fresh-bean.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('HF'),
  'vegetal.fresh-bean.income.imported.insurance.general': generalInsuranceIncomeResolver('HF'),
  'vegetal.fresh-bean.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('HF'),
  'vegetal.fresh-bean.income.imported.inventory.products': inventoryIncomeResolver('HF'),
  'vegetal.fresh-bean.income.imported.miscellaneous': miscellaneousIncomeResolver('HF'),
  'vegetal.fresh-bean.income.imported.sales.products': salesIncomeResolver('HF'),
  'vegetal.fruit.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('FD'),
  'vegetal.fruit.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('FD'),
  'vegetal.fruit.expenses.imported.marketing': marketingExpenseResolver('FD'),
  'vegetal.fruit.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('FD'),
  'vegetal.fruit.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('FD'),
  'vegetal.fruit.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('FD'),
  'vegetal.fruit.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('FD'),
  'vegetal.fruit.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('FD'),
  'vegetal.fruit.income.imported.insurance.general': generalInsuranceIncomeResolver('FD'),
  'vegetal.fruit.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('FD'),
  'vegetal.fruit.income.imported.inventory.products': inventoryIncomeResolver('FD'),
  'vegetal.fruit.income.imported.miscellaneous': miscellaneousIncomeResolver('FD'),
  'vegetal.fruit.income.imported.sales.products': salesIncomeResolver('FD'),
  'vegetal.garlic.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('AI'),
  'vegetal.garlic.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('AI'),
  'vegetal.garlic.expenses.imported.marketing': marketingExpenseResolver('AI'),
  'vegetal.garlic.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('AI', 248),
  'vegetal.garlic.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('AI', 797),
  'vegetal.garlic.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('AI'),
  'vegetal.garlic.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('AI'),
  'vegetal.garlic.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('AI', 368),
  'vegetal.garlic.income.imported.insurance.general': generalInsuranceIncomeResolver('AI'),
  'vegetal.garlic.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('AI'),
  'vegetal.garlic.income.imported.inventory.products': inventoryIncomeResolver('AI'),
  'vegetal.garlic.income.imported.miscellaneous': miscellaneousIncomeResolver('AI', 108),
  'vegetal.garlic.income.imported.sales.products': salesIncomeResolver('AI'),
  'vegetal.greenhouse-cucumber.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CB'),
  'vegetal.greenhouse-cucumber.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CB'),
  'vegetal.greenhouse-cucumber.expenses.imported.marketing': marketingExpenseResolver('CB'),
  'vegetal.greenhouse-cucumber.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CB'),
  'vegetal.greenhouse-cucumber.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CB'),
  'vegetal.greenhouse-cucumber.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CB'),
  'vegetal.greenhouse-cucumber.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CB'),
  'vegetal.greenhouse-cucumber.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CB'),
  'vegetal.greenhouse-cucumber.income.imported.insurance.general': generalInsuranceIncomeResolver('CB'),
  'vegetal.greenhouse-cucumber.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CB'),
  'vegetal.greenhouse-cucumber.income.imported.inventory.products': inventoryIncomeResolver('CB'),
  'vegetal.greenhouse-cucumber.income.imported.miscellaneous': miscellaneousIncomeResolver('CB'),
  'vegetal.greenhouse-cucumber.income.imported.sales.products': salesIncomeResolver('CB'),
  'vegetal.greenhouse-tomato.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('TS'),
  'vegetal.greenhouse-tomato.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('TS'),
  'vegetal.greenhouse-tomato.expenses.imported.marketing': marketingExpenseResolver('TS'),
  'vegetal.greenhouse-tomato.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('TS'),
  'vegetal.greenhouse-tomato.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('TS'),
  'vegetal.greenhouse-tomato.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('TS'),
  'vegetal.greenhouse-tomato.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('TS'),
  'vegetal.greenhouse-tomato.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('TS'),
  'vegetal.greenhouse-tomato.income.imported.insurance.general': generalInsuranceIncomeResolver('TS'),
  'vegetal.greenhouse-tomato.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('TS'),
  'vegetal.greenhouse-tomato.income.imported.inventory.products': inventoryIncomeResolver('TS'),
  'vegetal.greenhouse-tomato.income.imported.miscellaneous': miscellaneousIncomeResolver('TS'),
  'vegetal.greenhouse-tomato.income.imported.sales.products': salesIncomeResolver('TS'),
  'vegetal.kale.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CS'),
  'vegetal.kale.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CS'),
  'vegetal.kale.expenses.imported.marketing': marketingExpenseResolver('CS'),
  'vegetal.kale.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CS'),
  'vegetal.kale.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CS'),
  'vegetal.kale.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CS'),
  'vegetal.kale.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CS'),
  'vegetal.kale.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CS'),
  'vegetal.kale.income.imported.insurance.general': generalInsuranceIncomeResolver('CS'),
  'vegetal.kale.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CS'),
  'vegetal.kale.income.imported.inventory.products': inventoryIncomeResolver('CS'),
  'vegetal.kale.income.imported.miscellaneous': miscellaneousIncomeResolver('CS'),
  'vegetal.kale.income.imported.sales.products': salesIncomeResolver('CS'),
  'vegetal.leek.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('PX'),
  'vegetal.leek.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('PX'),
  'vegetal.leek.expenses.imported.marketing': marketingExpenseResolver('PX'),
  'vegetal.leek.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('PX'),
  'vegetal.leek.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('PX'),
  'vegetal.leek.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('PX'),
  'vegetal.leek.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('PX'),
  'vegetal.leek.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('PX'),
  'vegetal.leek.income.imported.insurance.general': generalInsuranceIncomeResolver('PX'),
  'vegetal.leek.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('PX'),
  'vegetal.leek.income.imported.inventory.products': inventoryIncomeResolver('PX'),
  'vegetal.leek.income.imported.miscellaneous': miscellaneousIncomeResolver('PX'),
  'vegetal.leek.income.imported.sales.products': salesIncomeResolver('PX'),
  'vegetal.lettuce.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('LT'),
  'vegetal.lettuce.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('LT'),
  'vegetal.lettuce.expenses.imported.marketing': marketingExpenseResolver('LT'),
  'vegetal.lettuce.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('LT'),
  'vegetal.lettuce.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('LT'),
  'vegetal.lettuce.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('LT'),
  'vegetal.lettuce.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('LT'),
  'vegetal.lettuce.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('LT'),
  'vegetal.lettuce.income.imported.insurance.general': generalInsuranceIncomeResolver('LT'),
  'vegetal.lettuce.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('LT'),
  'vegetal.lettuce.income.imported.inventory.products': inventoryIncomeResolver('LT'),
  'vegetal.lettuce.income.imported.miscellaneous': miscellaneousIncomeResolver('LT'),
  'vegetal.lettuce.income.imported.sales.products': salesIncomeResolver('LT'),
  'vegetal.medicinal.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('PD'),
  'vegetal.medicinal.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('PD'),
  'vegetal.medicinal.expenses.imported.marketing': marketingExpenseResolver('PD'),
  'vegetal.medicinal.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('PD'),
  'vegetal.medicinal.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('PD'),
  'vegetal.medicinal.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('PD'),
  'vegetal.medicinal.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('PD'),
  'vegetal.medicinal.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('PD'),
  'vegetal.medicinal.income.imported.insurance.general': generalInsuranceIncomeResolver('PD'),
  'vegetal.medicinal.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('PD'),
  'vegetal.medicinal.income.imported.inventory.products': inventoryIncomeResolver('PD'),
  'vegetal.medicinal.income.imported.miscellaneous': miscellaneousIncomeResolver('PD'),
  'vegetal.medicinal.income.imported.sales.products': salesIncomeResolver('PD'),
  'vegetal.mushroom.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CG'),
  'vegetal.mushroom.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CG'),
  'vegetal.mushroom.expenses.imported.marketing': marketingExpenseResolver('CG'),
  'vegetal.mushroom.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CG'),
  'vegetal.mushroom.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CG'),
  'vegetal.mushroom.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CG'),
  'vegetal.mushroom.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CG'),
  'vegetal.mushroom.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CG'),
  'vegetal.mushroom.income.imported.insurance.general': generalInsuranceIncomeResolver('CG'),
  'vegetal.mushroom.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CG'),
  'vegetal.mushroom.income.imported.inventory.products': inventoryIncomeResolver('CG'),
  'vegetal.mushroom.income.imported.miscellaneous': miscellaneousIncomeResolver('CG'),
  'vegetal.mushroom.income.imported.sales.products': salesIncomeResolver('CG'),
  'vegetal.nursery.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('PE'),
  'vegetal.nursery.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('PE'),
  'vegetal.nursery.expenses.imported.marketing': marketingExpenseResolver('PE'),
  'vegetal.nursery.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('PE'),
  'vegetal.nursery.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('PE'),
  'vegetal.nursery.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('PE'),
  'vegetal.nursery.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('PE'),
  'vegetal.nursery.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('PE'),
  'vegetal.nursery.income.imported.insurance.general': generalInsuranceIncomeResolver('PE'),
  'vegetal.nursery.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('PE'),
  'vegetal.nursery.income.imported.inventory.products': inventoryIncomeResolver('PE'),
  'vegetal.nursery.income.imported.miscellaneous': miscellaneousIncomeResolver('PE'),
  'vegetal.nursery.income.imported.sales.products': salesIncomeResolver('PE'),
  'vegetal.onion.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('OG'),
  'vegetal.onion.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('OG'),
  'vegetal.onion.expenses.imported.marketing': marketingExpenseResolver('OG'),
  'vegetal.onion.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('OG'),
  'vegetal.onion.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('OG'),
  'vegetal.onion.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('OG'),
  'vegetal.onion.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('OG'),
  'vegetal.onion.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('OG'),
  'vegetal.onion.income.imported.insurance.general': generalInsuranceIncomeResolver('OG'),
  'vegetal.onion.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('OG'),
  'vegetal.onion.income.imported.inventory.products': inventoryIncomeResolver('OG'),
  'vegetal.onion.income.imported.miscellaneous': miscellaneousIncomeResolver('OG'),
  'vegetal.onion.income.imported.sales.products': salesIncomeResolver('OG'),
  'vegetal.ornamental.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CO'),
  'vegetal.ornamental.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CO'),
  'vegetal.ornamental.expenses.imported.marketing': marketingExpenseResolver('CO'),
  'vegetal.ornamental.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CO'),
  'vegetal.ornamental.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CO'),
  'vegetal.ornamental.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CO'),
  'vegetal.ornamental.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CO'),
  'vegetal.ornamental.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CO'),
  'vegetal.ornamental.income.imported.insurance.general': generalInsuranceIncomeResolver('CO'),
  'vegetal.ornamental.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CO'),
  'vegetal.ornamental.income.imported.inventory.products': inventoryIncomeResolver('CO'),
  'vegetal.ornamental.income.imported.miscellaneous': miscellaneousIncomeResolver('CO'),
  'vegetal.ornamental.income.imported.sales.products': salesIncomeResolver('CO'),
  'vegetal.other.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('ZV'),
  'vegetal.other.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('ZV'),
  'vegetal.other.expenses.imported.marketing': marketingExpenseResolver('ZV'),
  'vegetal.other.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('ZV'),
  'vegetal.other.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('ZV'),
  'vegetal.other.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('ZV'),
  'vegetal.other.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('ZV'),
  'vegetal.other.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('ZV'),
  'vegetal.other.income.imported.insurance.general': generalInsuranceIncomeResolver('ZV'),
  'vegetal.other.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('ZV'),
  'vegetal.other.income.imported.inventory.products': inventoryIncomeResolver('ZV'),
  'vegetal.other.income.imported.miscellaneous': miscellaneousIncomeResolver('ZV'),
  'vegetal.other.income.imported.sales.products': salesIncomeResolver('ZV'),
  'vegetal.parsnip.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('PN'),
  'vegetal.parsnip.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('PN'),
  'vegetal.parsnip.expenses.imported.marketing': marketingExpenseResolver('PN'),
  'vegetal.parsnip.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('PN'),
  'vegetal.parsnip.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('PN'),
  'vegetal.parsnip.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('PN'),
  'vegetal.parsnip.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('PN'),
  'vegetal.parsnip.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('PN'),
  'vegetal.parsnip.income.imported.insurance.general': generalInsuranceIncomeResolver('PN'),
  'vegetal.parsnip.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('PN'),
  'vegetal.parsnip.income.imported.inventory.products': inventoryIncomeResolver('PN'),
  'vegetal.parsnip.income.imported.miscellaneous': miscellaneousIncomeResolver('PN'),
  'vegetal.parsnip.income.imported.sales.products': salesIncomeResolver('PN'),
  'vegetal.peat.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('TR'),
  'vegetal.peat.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('TR'),
  'vegetal.peat.expenses.imported.marketing': marketingExpenseResolver('TR'),
  'vegetal.peat.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('TR'),
  'vegetal.peat.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('TR'),
  'vegetal.peat.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('TR'),
  'vegetal.peat.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('TR'),
  'vegetal.peat.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('TR'),
  'vegetal.peat.income.imported.insurance.general': generalInsuranceIncomeResolver('TR'),
  'vegetal.peat.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('TR'),
  'vegetal.peat.income.imported.inventory.products': inventoryIncomeResolver('TR'),
  'vegetal.peat.income.imported.miscellaneous': miscellaneousIncomeResolver('TR'),
  'vegetal.peat.income.imported.sales.products': salesIncomeResolver('TR'),
  'vegetal.pepper.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('PT'),
  'vegetal.pepper.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('PT'),
  'vegetal.pepper.expenses.imported.marketing': marketingExpenseResolver('PT'),
  'vegetal.pepper.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('PT'),
  'vegetal.pepper.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('PT'),
  'vegetal.pepper.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('PT'),
  'vegetal.pepper.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('PT'),
  'vegetal.pepper.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('PT'),
  'vegetal.pepper.income.imported.insurance.general': generalInsuranceIncomeResolver('PT'),
  'vegetal.pepper.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('PT'),
  'vegetal.pepper.income.imported.inventory.products': inventoryIncomeResolver('PT'),
  'vegetal.pepper.income.imported.miscellaneous': miscellaneousIncomeResolver('PT'),
  'vegetal.pepper.income.imported.sales.products': salesIncomeResolver('PT'),
  'vegetal.pickle.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CN'),
  'vegetal.pickle.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CN'),
  'vegetal.pickle.expenses.imported.marketing': marketingExpenseResolver('CN'),
  'vegetal.pickle.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CN'),
  'vegetal.pickle.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CN'),
  'vegetal.pickle.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CN'),
  'vegetal.pickle.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CN'),
  'vegetal.pickle.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CN'),
  'vegetal.pickle.income.imported.insurance.general': generalInsuranceIncomeResolver('CN'),
  'vegetal.pickle.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CN'),
  'vegetal.pickle.income.imported.inventory.products': inventoryIncomeResolver('CN'),
  'vegetal.pickle.income.imported.miscellaneous': miscellaneousIncomeResolver('CN'),
  'vegetal.pickle.income.imported.sales.products': salesIncomeResolver('CN'),
  'vegetal.pumpkin.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CI'),
  'vegetal.pumpkin.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CI'),
  'vegetal.pumpkin.expenses.imported.marketing': marketingExpenseResolver('CI'),
  'vegetal.pumpkin.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CI'),
  'vegetal.pumpkin.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CI'),
  'vegetal.pumpkin.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CI'),
  'vegetal.pumpkin.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CI'),
  'vegetal.pumpkin.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CI'),
  'vegetal.pumpkin.income.imported.insurance.general': generalInsuranceIncomeResolver('CI'),
  'vegetal.pumpkin.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CI'),
  'vegetal.pumpkin.income.imported.inventory.products': inventoryIncomeResolver('CI'),
  'vegetal.pumpkin.income.imported.miscellaneous': miscellaneousIncomeResolver('CI'),
  'vegetal.pumpkin.income.imported.sales.products': salesIncomeResolver('CI'),
  'vegetal.radish.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('RA'),
  'vegetal.radish.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('RA'),
  'vegetal.radish.expenses.imported.marketing': marketingExpenseResolver('RA'),
  'vegetal.radish.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('RA'),
  'vegetal.radish.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('RA'),
  'vegetal.radish.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('RA'),
  'vegetal.radish.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('RA'),
  'vegetal.radish.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('RA'),
  'vegetal.radish.income.imported.insurance.general': generalInsuranceIncomeResolver('RA'),
  'vegetal.radish.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('RA'),
  'vegetal.radish.income.imported.inventory.products': inventoryIncomeResolver('RA'),
  'vegetal.radish.income.imported.miscellaneous': miscellaneousIncomeResolver('RA'),
  'vegetal.radish.income.imported.sales.products': salesIncomeResolver('RA'),
  'vegetal.raspberry.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('FM'),
  'vegetal.raspberry.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('FM'),
  'vegetal.raspberry.expenses.imported.marketing': marketingExpenseResolver('FM'),
  'vegetal.raspberry.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('FM'),
  'vegetal.raspberry.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('FM'),
  'vegetal.raspberry.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('FM'),
  'vegetal.raspberry.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('FM'),
  'vegetal.raspberry.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('FM'),
  'vegetal.raspberry.income.imported.insurance.general': generalInsuranceIncomeResolver('FM'),
  'vegetal.raspberry.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('FM'),
  'vegetal.raspberry.income.imported.inventory.products': inventoryIncomeResolver('FM'),
  'vegetal.raspberry.income.imported.miscellaneous': miscellaneousIncomeResolver('FM'),
  'vegetal.raspberry.income.imported.sales.products': salesIncomeResolver('FM'),
  'vegetal.seed-potato.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('P1'),
  'vegetal.seed-potato.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('P1'),
  'vegetal.seed-potato.expenses.imported.marketing': marketingExpenseResolver('P1'),
  'vegetal.seed-potato.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('P1', 1041),
  'vegetal.seed-potato.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('P1', 3066),
  'vegetal.seed-potato.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('P1'),
  'vegetal.seed-potato.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('P1'),
  'vegetal.seed-potato.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('P1', 1526),
  'vegetal.seed-potato.income.imported.insurance.general': generalInsuranceIncomeResolver('P1'),
  'vegetal.seed-potato.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('P1', 500),
  'vegetal.seed-potato.income.imported.inventory.products': inventoryIncomeResolver('P1', -19_355.189),
  'vegetal.seed-potato.income.imported.miscellaneous': miscellaneousIncomeResolver('P1', 508),
  'vegetal.seed-potato.income.imported.sales.products': salesIncomeResolver('P1', 46_929),
  'vegetal.shallots.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('EC'),
  'vegetal.shallots.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('EC'),
  'vegetal.shallots.expenses.imported.marketing': marketingExpenseResolver('EC'),
  'vegetal.shallots.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('EC'),
  'vegetal.shallots.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('EC'),
  'vegetal.shallots.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('EC'),
  'vegetal.shallots.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('EC'),
  'vegetal.shallots.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('EC'),
  'vegetal.shallots.income.imported.insurance.general': generalInsuranceIncomeResolver('EC'),
  'vegetal.shallots.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('EC'),
  'vegetal.shallots.income.imported.inventory.products': inventoryIncomeResolver('EC'),
  'vegetal.shallots.income.imported.miscellaneous': miscellaneousIncomeResolver('EC'),
  'vegetal.shallots.income.imported.sales.products': salesIncomeResolver('EC'),
  'vegetal.spinach.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('EP'),
  'vegetal.spinach.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('EP'),
  'vegetal.spinach.expenses.imported.marketing': marketingExpenseResolver('EP'),
  'vegetal.spinach.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('EP'),
  'vegetal.spinach.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('EP'),
  'vegetal.spinach.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('EP'),
  'vegetal.spinach.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('EP'),
  'vegetal.spinach.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('EP'),
  'vegetal.spinach.income.imported.insurance.general': generalInsuranceIncomeResolver('EP'),
  'vegetal.spinach.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('EP'),
  'vegetal.spinach.income.imported.inventory.products': inventoryIncomeResolver('EP'),
  'vegetal.spinach.income.imported.miscellaneous': miscellaneousIncomeResolver('EP'),
  'vegetal.spinach.income.imported.sales.products': salesIncomeResolver('EP'),
  'vegetal.strawberry.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('FR'),
  'vegetal.strawberry.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('FR'),
  'vegetal.strawberry.expenses.imported.marketing': marketingExpenseResolver('FR'),
  'vegetal.strawberry.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('FR'),
  'vegetal.strawberry.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('FR'),
  'vegetal.strawberry.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('FR'),
  'vegetal.strawberry.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('FR'),
  'vegetal.strawberry.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('FR'),
  'vegetal.strawberry.income.imported.insurance.general': generalInsuranceIncomeResolver('FR'),
  'vegetal.strawberry.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('FR'),
  'vegetal.strawberry.income.imported.inventory.products': inventoryIncomeResolver('FR'),
  'vegetal.strawberry.income.imported.miscellaneous': miscellaneousIncomeResolver('FR'),
  'vegetal.strawberry.income.imported.sales.products': salesIncomeResolver('FR'),
  'vegetal.sweet-corn.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('MF'),
  'vegetal.sweet-corn.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('MF'),
  'vegetal.sweet-corn.expenses.imported.marketing': marketingExpenseResolver('MF'),
  'vegetal.sweet-corn.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('MF'),
  'vegetal.sweet-corn.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('MF'),
  'vegetal.sweet-corn.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('MF'),
  'vegetal.sweet-corn.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('MF'),
  'vegetal.sweet-corn.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('MF'),
  'vegetal.sweet-corn.income.imported.insurance.general': generalInsuranceIncomeResolver('MF'),
  'vegetal.sweet-corn.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('MF'),
  'vegetal.sweet-corn.income.imported.inventory.products': inventoryIncomeResolver('MF'),
  'vegetal.sweet-corn.income.imported.miscellaneous': miscellaneousIncomeResolver('MF'),
  'vegetal.sweet-corn.income.imported.sales.products': salesIncomeResolver('MF'),
  'vegetal.table-carrot.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CC'),
  'vegetal.table-carrot.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CC'),
  'vegetal.table-carrot.expenses.imported.marketing': marketingExpenseResolver('CC'),
  'vegetal.table-carrot.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CC'),
  'vegetal.table-carrot.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CC'),
  'vegetal.table-carrot.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CC'),
  'vegetal.table-carrot.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CC'),
  'vegetal.table-carrot.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CC'),
  'vegetal.table-carrot.income.imported.insurance.general': generalInsuranceIncomeResolver('CC'),
  'vegetal.table-carrot.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CC'),
  'vegetal.table-carrot.income.imported.inventory.products': inventoryIncomeResolver('CC'),
  'vegetal.table-carrot.income.imported.miscellaneous': miscellaneousIncomeResolver('CC'),
  'vegetal.table-carrot.income.imported.sales.products': salesIncomeResolver('CC'),
  'vegetal.table-potato.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('P2'),
  'vegetal.table-potato.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('P2'),
  'vegetal.table-potato.expenses.imported.marketing': marketingExpenseResolver('P2'),
  'vegetal.table-potato.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('P2', 2008),
  'vegetal.table-potato.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('P2', 6181),
  'vegetal.table-potato.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('P2'),
  'vegetal.table-potato.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('P2'),
  'vegetal.table-potato.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('P2', 3024),
  'vegetal.table-potato.income.imported.insurance.general': generalInsuranceIncomeResolver('P2', 500),
  'vegetal.table-potato.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('P2', 1000),
  'vegetal.table-potato.income.imported.inventory.products': inventoryIncomeResolver('P2', -33_403),
  'vegetal.table-potato.income.imported.miscellaneous': miscellaneousIncomeResolver('P2', 1008),
  'vegetal.table-potato.income.imported.sales.products': salesIncomeResolver('P2', 499_823),
  'vegetal.thyme.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('TH'),
  'vegetal.thyme.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('TH'),
  'vegetal.thyme.expenses.imported.marketing': marketingExpenseResolver('TH'),
  'vegetal.thyme.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('TH'),
  'vegetal.thyme.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('TH'),
  'vegetal.thyme.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('TH'),
  'vegetal.thyme.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('TH'),
  'vegetal.thyme.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('TH'),
  'vegetal.thyme.income.imported.insurance.general': generalInsuranceIncomeResolver('TH'),
  'vegetal.thyme.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('TH'),
  'vegetal.thyme.income.imported.inventory.products': inventoryIncomeResolver('TH'),
  'vegetal.thyme.income.imported.miscellaneous': miscellaneousIncomeResolver('TH'),
  'vegetal.thyme.income.imported.sales.products': salesIncomeResolver('TH'),
  'vegetal.tobacco.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('TB'),
  'vegetal.tobacco.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('TB'),
  'vegetal.tobacco.expenses.imported.marketing': marketingExpenseResolver('TB'),
  'vegetal.tobacco.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('TB'),
  'vegetal.tobacco.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('TB'),
  'vegetal.tobacco.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('TB'),
  'vegetal.tobacco.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('TB'),
  'vegetal.tobacco.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('TB'),
  'vegetal.tobacco.income.imported.insurance.general': generalInsuranceIncomeResolver('TB'),
  'vegetal.tobacco.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('TB'),
  'vegetal.tobacco.income.imported.inventory.products': inventoryIncomeResolver('TB'),
  'vegetal.tobacco.income.imported.miscellaneous': miscellaneousIncomeResolver('TB'),
  'vegetal.tobacco.income.imported.sales.products': salesIncomeResolver('TB'),
  'vegetal.turnip.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('NT'),
  'vegetal.turnip.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('NT'),
  'vegetal.turnip.expenses.imported.marketing': marketingExpenseResolver('NT'),
  'vegetal.turnip.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('NT'),
  'vegetal.turnip.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('NT'),
  'vegetal.turnip.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('NT'),
  'vegetal.turnip.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('NT'),
  'vegetal.turnip.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('NT'),
  'vegetal.turnip.income.imported.insurance.general': generalInsuranceIncomeResolver('NT'),
  'vegetal.turnip.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('NT'),
  'vegetal.turnip.income.imported.inventory.products': inventoryIncomeResolver('NT'),
  'vegetal.turnip.income.imported.miscellaneous': miscellaneousIncomeResolver('NT'),
  'vegetal.turnip.income.imported.sales.products': salesIncomeResolver('NT'),
  'vegetal.vegetable.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('LD'),
  'vegetal.vegetable.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('LD'),
  'vegetal.vegetable.expenses.imported.marketing': marketingExpenseResolver('LD'),
  'vegetal.vegetable.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('LD'),
  'vegetal.vegetable.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('LD'),
  'vegetal.vegetable.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('LD'),
  'vegetal.vegetable.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('LD'),
  'vegetal.vegetable.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('LD'),
  'vegetal.vegetable.income.imported.insurance.general': generalInsuranceIncomeResolver('LD'),
  'vegetal.vegetable.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('LD'),
  'vegetal.vegetable.income.imported.inventory.products': inventoryIncomeResolver('LD'),
  'vegetal.vegetable.income.imported.miscellaneous': miscellaneousIncomeResolver('LD'),
  'vegetal.vegetable.income.imported.sales.products': salesIncomeResolver('LD'),
  'vegetal.watermelon.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('MD'),
  'vegetal.watermelon.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('MD'),
  'vegetal.watermelon.expenses.imported.marketing': marketingExpenseResolver('MD'),
  'vegetal.watermelon.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('MD'),
  'vegetal.watermelon.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('MD'),
  'vegetal.watermelon.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('MD'),
  'vegetal.watermelon.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('MD'),
  'vegetal.watermelon.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('MD'),
  'vegetal.watermelon.income.imported.insurance.general': generalInsuranceIncomeResolver('MD'),
  'vegetal.watermelon.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('MD'),
  'vegetal.watermelon.income.imported.inventory.products': inventoryIncomeResolver('MD'),
  'vegetal.watermelon.income.imported.miscellaneous': miscellaneousIncomeResolver('MD'),
  'vegetal.watermelon.income.imported.sales.products': salesIncomeResolver('MD'),
  'vegetal.winter-cabbage.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('CU'),
  'vegetal.winter-cabbage.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('CU'),
  'vegetal.winter-cabbage.expenses.imported.marketing': marketingExpenseResolver('CU'),
  'vegetal.winter-cabbage.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('CU'),
  'vegetal.winter-cabbage.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('CU'),
  'vegetal.winter-cabbage.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('CU'),
  'vegetal.winter-cabbage.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('CU'),
  'vegetal.winter-cabbage.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('CU'),
  'vegetal.winter-cabbage.income.imported.insurance.general': generalInsuranceIncomeResolver('CU'),
  'vegetal.winter-cabbage.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('CU'),
  'vegetal.winter-cabbage.income.imported.inventory.products': inventoryIncomeResolver('CU'),
  'vegetal.winter-cabbage.income.imported.miscellaneous': miscellaneousIncomeResolver('CU'),
  'vegetal.winter-cabbage.income.imported.sales.products': salesIncomeResolver('CU'),
  'vegetal.zucchini.expenses.imported.insurance.stabilization': stabilizationInsuranceFeeResolver('ZC'),
  'vegetal.zucchini.expenses.imported.insurance.harvest': harvestInsuranceFeeResolver('ZC'),
  'vegetal.zucchini.expenses.imported.marketing': marketingExpenseResolver('ZC'),
  'vegetal.zucchini.expenses.imported.supplies.fertilizer': fertilizerExpenseResolver('ZC', 18_131),
  'vegetal.zucchini.expenses.imported.supplies.miscellaneous': miscellaneousSuppliesExpenseResolver('ZC', 54_296),
  'vegetal.zucchini.expenses.imported.supplies.pesticides': pesticidesExpenseResolver('ZC'),
  'vegetal.zucchini.expenses.imported.supplies.resale': resaleSuppliesExpenseResolver('ZC'),
  'vegetal.zucchini.expenses.imported.supplies.seed': seedSuppliesExpenseResolver('ZC', 27_078),
  'vegetal.zucchini.income.imported.insurance.general': generalInsuranceIncomeResolver('ZC'),
  'vegetal.zucchini.income.imported.insurance.stabilization': stabilizationInsuranceIncomeResolver('ZC', 8940),
  'vegetal.zucchini.income.imported.inventory.products': inventoryIncomeResolver('ZC', -427.5),
  'vegetal.zucchini.income.imported.miscellaneous': miscellaneousIncomeResolver('ZC', 9008),
  'vegetal.zucchini.income.imported.sales.products': salesIncomeResolver('ZC', 7839),
})
