import { BigNumber } from 'bignumber.js'
import dayjs from 'dayjs'
import { isEmpty, sortBy } from 'lodash-es'

import { type LoanStateData } from './merge-loan-state'
import { PaymentFrequencyToAnnualEventCount } from './payment-frequency-to-annual-event-count'
import { type LoanComputationAction } from './reduce-loan-state'

export type LoanStateEvent = LoanComputationAction & {
  date: Date
}

export const prepareLoanStateEvents = (stateData: LoanStateData, startProjectionsDate: Date): LoanStateEvent[] => {
  const startDate = stateData.startDate ? dayjs(stateData.startDate) : null
  const startPaymentsDate = startDate?.isAfter(startProjectionsDate) ? startDate.toDate() : startProjectionsDate

  const newCapitalEvents: LoanStateEvent[] = Object.entries(stateData.newCapitalBorrowed).map(
    ([id, { value: newCapitalBorrowed, date }]) => ({
      id,
      type: 'new-capital',
      newCapitalBorrowed,
      date: dayjs(date, 'YYYY-MM-DD').toDate(),
    })
  )
  const earlyRepaymentEvents: LoanStateEvent[] = Object.entries(stateData.earlyRepayment).map(
    ([id, { value: earlyRepayment, date }]) => ({
      id,
      type: 'early-repayment',
      earlyRepayment,
      date: dayjs(date, 'YYYY-MM-DD').toDate(),
    })
  )
  const interestRateChangeEvents: LoanStateEvent[] = Object.entries(stateData.interestRateChange).map(
    ([id, { value: annualInterestRate, date }]) => ({
      id,
      type: 'change-interest-rate',
      interestRateForPeriod: annualInterestRate
        .shiftedBy(-2)
        .dividedBy(PaymentFrequencyToAnnualEventCount[stateData.paymentFrequency ?? 'monthly']),
      date: dayjs(date, 'YYYY-MM-DD').toDate(),
    })
  )

  const otherEvents: LoanStateEvent[] = []
  if (startDate?.add(1, 'hour')?.isAfter(startProjectionsDate) && isEmpty(stateData.newCapitalBorrowed)) {
    otherEvents.push({
      id: 'start-loan',
      type: 'new-capital',
      newCapitalBorrowed: stateData.amount ?? new BigNumber(0),
      date: startDate.toDate(),
    })
  }

  if (stateData.durationWithoutInterestPaid?.gt(0)) {
    const startInterestPaymentsDate = dayjs(startPaymentsDate)
      .add(stateData.durationWithoutInterestPaid.toNumber(), 'months')
      // because dates are difficult!
      .add(1, 'day')
      // to be after startCapitalPaymentsDate when same date
      .add(1, 'minute')
      .toDate()

    otherEvents.push({
      id: 'start-interest',
      type: 'change-interest-rate',
      interestRateForPeriod:
        stateData.interestRate
          ?.shiftedBy(-2)
          ?.dividedBy(PaymentFrequencyToAnnualEventCount[stateData.paymentFrequency ?? 'monthly']) ?? new BigNumber(0),
      date: startInterestPaymentsDate,
    })
  }

  if (stateData.durationWithoutCapitalPaid?.gt(0) && stateData.duration?.periods) {
    const startCapitalPaymentsDate = dayjs(startPaymentsDate)
      .add(stateData.durationWithoutCapitalPaid.toNumber(), 'months')
      // because dates are difficult!
      .add(1, 'day')
      .toDate()
    otherEvents.push({
      id: 'start-capital',
      type: 'pay-capital',
      numberOfPayments: stateData.duration.periods,
      date: startCapitalPaymentsDate,
    })
  }

  return sortBy([...newCapitalEvents, ...earlyRepaymentEvents, ...interestRateChangeEvents, ...otherEvents], 'date')
}
