import React from 'react'

import { cn } from '../../lib/utils'
import { Icons } from '../Icons/Icons'

export interface LineCollapsableProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly open?: boolean
}

export const LineCollapsable = React.forwardRef<HTMLDivElement, LineCollapsableProps>(
  ({ className, open = true, ...props }, ref) => (
    <div className={cn('w-2', className)} ref={ref} {...props}>
      {open ? <Icons.CollapsableOpen /> : <Icons.CollapsableClose />}
    </div>
  )
)
