import type React from 'react'
import { type FC } from 'react'
import { useCallback, useContext, useState } from 'react'

import { type Except } from 'type-fest'

import { Sheet, SheetContent, SheetTrigger } from '../../atoms/Sheet/Sheet'
import { MonetaryTableEditingContext } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'

import { MonetaryReadonlyValueCell, type MonetaryReadonlyValueCellProps } from './MonetaryReadonlyValueCell'

interface SheetEditableValueCellProps extends Except<MonetaryReadonlyValueCellProps, 'children'> {
  readonly children: (props: { onClose: () => void }) => React.ReactNode
}

export const SheetEditableValueCell: FC<SheetEditableValueCellProps> = ({ className, children, ...props }) => {
  const { setEditing: setContextEditing } = useContext(MonetaryTableEditingContext)
  const [editing, setEditing] = useState<boolean>(false)

  const onClose = useCallback(() => {
    setEditing(false)
    setContextEditing(false)
  }, [setContextEditing])

  return (
    <Sheet
      open={editing}
      onOpenChange={(value) => {
        setEditing(value)
        setContextEditing(value)
      }}>
      <SheetTrigger asChild>
        <MonetaryReadonlyValueCell className={className} editing={editing} {...props} />
      </SheetTrigger>
      <SheetContent side="right" className="w-fit overflow-auto p-0">
        {children({ onClose })}
      </SheetContent>
    </Sheet>
  )
}
