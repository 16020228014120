import { createFileRoute } from '@tanstack/react-router'

import { ScenarioFinancePage } from '../pages/scenarioFinance/ScenarioFinancePage.tsx'
import { setScenarioCurrentPage } from '../pages/scenarioUpdate/utils/scenarioPage.ts'

export const Route = createFileRoute('/budgets/$budgetId/scenarios/$scenarioId/finance')({
  component: ScenarioFinancePage,
  loader: ({ params }) => {
    setScenarioCurrentPage(params.budgetId, params.scenarioId, 'finance')
  },
})
