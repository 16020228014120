import { createContext, type FC, type PropsWithChildren, useMemo } from 'react'

import { useControllableState } from '@radix-ui/react-use-controllable-state'
import { noop } from 'lodash-es'

export interface MonetaryTableCollapseStateContextValue {
  isSectionOpen: (id: string) => boolean
  isOpen: (id: string) => boolean
}

export const MonetaryTableCollapseStateContext = createContext<MonetaryTableCollapseStateContextValue>({
  isSectionOpen: () => true,
  isOpen: () => true,
})

export interface MonetaryTableCollapseSetterContextValue {
  setSectionOpen: (id: string, open: boolean) => void
  setOpen: (id: string, open: boolean) => void
}

export const MonetaryTableCollapseSetterContext = createContext<MonetaryTableCollapseSetterContextValue>({
  setSectionOpen: noop,
  setOpen: noop,
})

export interface MonetaryTableCollapseProviderProps {
  readonly collapsed?: Record<string, boolean>
  readonly onCollapse?: (state: Record<string, boolean>) => void
}

export const MonetaryTableCollapseProvider: FC<PropsWithChildren<MonetaryTableCollapseProviderProps>> = ({
  collapsed,
  onCollapse,
  children,
}) => {
  const [state, setState] = useControllableState<Record<string, boolean>>({ prop: collapsed, onChange: onCollapse })

  const stateValue = useMemo(
    () => ({
      isSectionOpen: (id: string) => state?.[`section__${id}`] ?? true,
      isOpen: (id: string) => state?.[id] ?? true,
    }),
    [state]
  )

  const setterValue = useMemo(
    () => ({
      setSectionOpen: (id: string, open: boolean) => {
        setState((s) => ({ ...s, [`section__${id}`]: open }))
      },
      setOpen: (id: string, open: boolean) => {
        setState((s) => ({ ...s, [id]: open }))
      },
    }),
    [setState]
  )

  return (
    <MonetaryTableCollapseStateContext.Provider value={stateValue}>
      <MonetaryTableCollapseSetterContext.Provider value={setterValue}>
        {children}
      </MonetaryTableCollapseSetterContext.Provider>
    </MonetaryTableCollapseStateContext.Provider>
  )
}
