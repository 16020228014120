import { type ScenarioLoanStateZod } from '@via/schema'
import { type z } from 'zod'

import { computeCumulativeLoanState } from './compute-cumulative-loan-state'
import { loanBigNumberStateData } from './loan-big-number-state-data'
import { mergeLoanState } from './merge-loan-state'

export const computeLoanRemainingDueAmount = (
  data: z.infer<typeof ScenarioLoanStateZod>,
  startProjectionsDate: Date,
  to: Date
) =>
  computeCumulativeLoanState(mergeLoanState(loanBigNumberStateData(data), null), startProjectionsDate, to)
    .remainingDueAmount
