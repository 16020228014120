import React from 'react'

import { cn } from '../../lib/utils'
import { Icons } from '../Icons/Icons'

export interface SearchBoxProps {
  readonly placeholder: string
  readonly className?: string
  readonly initialValue?: string
  onChange?(value: string): void
  onBlur?(value: string): void
}

export const Search = ({ placeholder, className, onChange, onBlur, initialValue }: SearchBoxProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const clear = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.focus()
      onChange?.('')
    }
  }

  return (
    <div className={cn('relative rounded', className)}>
      <input
        name="search"
        className="peer block h-8 w-full rounded border-0 py-1.5 pr-10 text-sm leading-none tracking-[-0.28px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:italic placeholder:text-gray-600 hover:ring-gray-500 focus:ring-1 focus:ring-inset focus:ring-blue-600 focus:placeholder:not-italic focus:placeholder:text-gray-400 [&:not(:placeholder-shown)]:ring-gray-500"
        placeholder={placeholder}
        onChange={(event: React.FocusEvent<HTMLInputElement>) => {
          onChange?.(event.target.value)
        }}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          onBlur?.(event.target.value)
        }}
        defaultValue={initialValue}
        autoComplete="off"
        ref={inputRef}
      />

      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 peer-[:not(:placeholder-shown)]:hidden peer-focus:[&>svg]:text-blue-600">
        <Icons.Search className="text-gray-400" />
      </div>

      <div
        className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3 peer-placeholder-shown:hidden"
        onClick={clear}>
        <Icons.XSquare className="text-black" />
      </div>
    </div>
  )
}
