import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { useNavigate } from '@tanstack/react-router'
import {
  type BudgetConfigurationData,
  type ClientBudgetListViewProps,
  type ClientData,
  useBudgetList,
  useClient,
  useToast,
} from '@via/components'
import { recordKeys } from '@via/compute'
import { type ProductionType } from '@via/schema'
import { sortBy, uniqBy } from 'lodash-es'

import { useDuplicateBudget } from '../../api/useDuplicateBudget.ts'
import { useCurrentUserActionTimestamp } from '../../auth/useCurrentUserActionTimestamp.ts'
import { useAuthenticatedFirebaseAuth } from '../../auth/useFirebaseAuth.ts'
import { useOfflineState } from '../../contexts/offlineState/useOfflineState.ts'
import { useAppNavigation } from '../../navigation/useAppNavigation.ts'
import { useCurrentUserAvatarProps } from '../../navigation/useCurrentUserAvatarProps.tsx'
import { useBudgetHandler } from '../../worker/budget/useBudgetHandler.ts'

const currentProductionsIncludesProduction = (
  currentProductions: ClientData['currentProductions'],
  production: ProductionType
) => Object.values(currentProductions).some((productionsForYear) => productionsForYear.includes(production))

export const useClientBudgetListPage = (): ClientBudgetListViewProps => {
  const navigation = useAppNavigation()
  const avatar = useCurrentUserAvatarProps()
  const client = useClient()
  const { data } = useBudgetList()
  const { user, userDocument } = useAuthenticatedFirebaseAuth()
  const { offline } = useOfflineState()
  const intl = useIntl()

  const userActionTimestamp = useCurrentUserActionTimestamp()
  const budgetHandler = useBudgetHandler()
  const duplicateBudget = useDuplicateBudget()
  const navigate = useNavigate()

  const { showToast } = useToast()

  const createBudget = useCallback(
    async (budgetForm: BudgetConfigurationData) => {
      const creationTimestamp = userActionTimestamp()
      const budget = {
        status: 'todo' as const,
        ...budgetForm,
        users: {
          ...(budgetForm.users.agronomist ? { agronomist: budgetForm.users.agronomist } : {}),
          ...(budgetForm.users.technician ? { technician: budgetForm.users.technician } : {}),
          ...(budgetForm.users.collaborator ? { collaborator: budgetForm.users.collaborator } : {}),
        },
        referenceYears: recordKeys(client.currentProductions),
        creation: creationTimestamp,
        lastModification: creationTimestamp,
        archived: false,
      }

      const document = await budgetHandler.createBudget(budget, client)

      await navigate({
        to: '/budgets/$budgetId',
        params: { budgetId: document._id },
        replace: true,
      })
    },
    [client, budgetHandler, navigate, userActionTimestamp]
  )

  const onActionSelect = useCallback(
    async (budgetId: string, action: 'open' | 'close' | 'duplicate' | 'archive') => {
      switch (action) {
        case 'open':
          await budgetHandler.acquireBudget(budgetId)
          showToast(
            intl.formatMessage({ id: 'clientList.action.open.done.title' }),
            intl.formatMessage({ id: 'clientList.action.open.done.description' })
          )
          break
        case 'close':
          await budgetHandler.closeBudget(budgetId)
          showToast(
            intl.formatMessage({ id: 'clientList.action.close.done.title' }),
            intl.formatMessage({ id: 'clientList.action.close.done.description' })
          )
          break
        case 'archive':
          await budgetHandler.archiveBudget(budgetId)
          break
        case 'duplicate': {
          const budget = data.find((b) => b._id === budgetId)
          if (budget) {
            const budgetName = `${intl.formatMessage({ id: 'budgetConfiguration.action.duplicate.name-prefix' })} ${budget.name}`
            await duplicateBudget.mutateAsync({ budgetId, budgetName })
          }
          break
        }
        default:
          break
      }
    },
    [budgetHandler, showToast, intl, data, duplicateBudget]
  )

  const contacts = sortBy(
    uniqBy(
      data.flatMap((budget) => Object.values(budget.users)),
      'id'
    ),
    'role'
  )

  const productions = useMemo(
    () => [
      ...(currentProductionsIncludesProduction(client.currentProductions, 'milk')
        ? [{ name: intl.formatMessage({ id: 'scenarioConfiguration.form.production.milk' }) }]
        : []),
      ...(currentProductionsIncludesProduction(client.currentProductions, 'crops')
        ? [{ name: intl.formatMessage({ id: 'scenarioConfiguration.form.production.crops' }) }]
        : []),
      ...(currentProductionsIncludesProduction(client.currentProductions, 'calf')
        ? [{ name: intl.formatMessage({ id: 'scenarioConfiguration.form.production.calf' }) }]
        : []),
      ...(currentProductionsIncludesProduction(client.currentProductions, 'maple')
        ? [{ name: intl.formatMessage({ id: 'scenarioConfiguration.form.production.maple' }) }]
        : []),
    ],
    [client.currentProductions, intl]
  )

  return {
    ...navigation,
    offline,
    avatar,
    client,
    contacts,
    productions,
    budgets: data,
    onCloseClient: () => navigation.onNavigationChange({ to: '/clients' }),
    onActionSelect,
    createBudget,
    userId: user.uid,
    user: userDocument,
  }
}
