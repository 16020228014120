import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { dataLine, dividerLine } from '../utils.ts'

export const cropsConfigurationSheet2024Blueprint = {
  sections: [
    {
      id: 'crops.production.section',
      titleKey: 'table.crops.production.section',
      showBottomHeader: false,
      collapsible: false,
      children: [
        dividerLine({ type: 'default' }, 'crops.production.section'),
        dataLine('crops.production.hectare', {
          variant: 'group',
          unit: 'head-count',
          cellVariants: { 'result-projections': 'editableValue' },
        }),
      ],
    },
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true, className: 'w-84' },
    {
      id: 'selected-reference',
      type: 'reference',
      sticky: true,
      className: 'w-[7.5rem]',
      topHeaderClassName: 'w-[7.5rem] h-14',
      withDividerRight: true,
      showUnitInCell: true,
      displayOnTableState: ['selected-reference'],
    },
    {
      id: 'all-references',
      type: 'all-references',
      sticky: true,
      className: 'w-[7.5rem]',
      topHeaderClassName: 'w-[7.5rem] h-14',
      baseColumnWidth: 120,
      withDividerRight: true,
      displayOnTableState: ['all-references'],
    },
    {
      id: 'divider',
      type: 'divider',
      className: 'w-1',
    },
    {
      id: 'projections',
      type: 'projections',
      withDividerRight: true,
      className: 'w-[7.5rem]',
      topHeaderVariant: 'head',
      topHeaderClassName: 'w-[7.5rem] h-14',
      showUnitInCell: true,
    },
  ],
  defaultState: 'selected-reference',
  expendedState: 'all-references',
} as const satisfies Except<BlueprintTable<'selected-reference' | 'all-references'>, 'version'>
