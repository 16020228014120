import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { type Except } from 'type-fest'

import { Button } from '../../atoms/Button/Button'
import { FormSubmitProvider } from '../../atoms/Form/FormSubmitProvider'
import { Icons } from '../../atoms/Icons/Icons'
import { cn } from '../../lib/utils'
import { type BudgetData, type ScenarioData } from '../../types'
import {
  ScenarioConfigurationForm,
  type ScenarioConfigurationFormProps,
} from '../ScenarioConfigurationForm/ScenarioConfigurationForm'
import { TitleBar } from '../TitleBar/TitleBar'

import { type ScenarioUpdateData } from './scenarioUpdateData'
import { type ScenarioUpdateFormData } from './ScenarioUpdateFormZod.ts'

export type ScenarioUpdateFormProps = React.FormHTMLAttributes<HTMLFormElement> &
  Except<ScenarioConfigurationFormProps, 'formAction' | 'currentProductions'> & {
    onConfirm(): Promise<void>
    onDuplicate(): Promise<void>
    onErase(): Promise<void> | void
    onCancel(): Promise<void>
    onValueChange(form: ScenarioUpdateData): Promise<void>
    readonly budget: BudgetData
    readonly eraseDisabled: boolean
    readonly scenarioIndex: number
    readonly scenario: ScenarioData
  }

export const ScenarioUpdateForm = React.forwardRef<HTMLFormElement, ScenarioUpdateFormProps>(
  (
    {
      budget,
      className,
      eraseDisabled,
      scenario,
      scenarioIndex,
      offline,
      readonly,
      onDuplicate,
      onErase,
      onValueChange,
      onConfirm,
      onCancel,
      ...props
    },
    ref
  ) => {
    const intl = useIntl()

    const transformFormValues = useCallback(
      ({ projectionYear, ...formValues }: ScenarioUpdateFormData) =>
        onValueChange({
          ...formValues,
          period: {
            type: 'annual',
            numberOfYears: projectionYear,
          },
        }),
      [onValueChange]
    )

    return (
      <FormSubmitProvider
        autoSubmit
        onFormSubmit={transformFormValues}
        className={cn('flex flex-col items-start overflow-hidden bg-white', className)}
        ref={ref}
        {...props}>
        <div className="flex-around flex w-full flex-col pl-8 pt-6">
          <TitleBar label={intl.formatMessage({ id: 'scenarioConfiguration.form.title' })}>
            <div className="flex w-full justify-end gap-2 pr-12">
              <Button className="pl-1.5" variant="outline" onClick={onCancel}>
                <Icons.ArrowLeftShort />
                {intl.formatMessage({ id: 'scenarioConfiguration.form.action.back' })}
              </Button>
              <Button className="pl-1.5" variant="outline" onClick={onErase} disabled={eraseDisabled}>
                <Icons.Trash />
                {intl.formatMessage({ id: 'scenarioConfiguration.form.action.erase' })}
              </Button>
              <Button className="pl-1.5" variant="outline" onClick={onDuplicate} disabled={readonly}>
                <Icons.SignIntersection />
                {intl.formatMessage({ id: 'scenarioConfiguration.form.action.duplicate' })}
              </Button>
              <Button onClick={onConfirm} disabled={readonly}>
                <Icons.Stars />
                {intl.formatMessage({ id: 'scenarioConfiguration.form.action.confirm' })}
              </Button>
            </div>
          </TitleBar>

          <ScenarioConfigurationForm
            formAction="update"
            offline={offline}
            readonly={readonly}
            currentProductions={scenario.currentProductions}
          />
        </div>
      </FormSubmitProvider>
    )
  }
)
