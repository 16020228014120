import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

interface ApiRequest<TVariables> {
  readonly type: string
  readonly value: TVariables
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export async function invokeApi<TData, TVariables>(type: string, variables: TVariables) {
  const apiCallable = httpsCallable<ApiRequest<TVariables>, TData>(
    getFunctions(getApp(), 'northamerica-northeast1'),
    'via-api'
  )
  const result = await apiCallable({ type, value: variables })
  return result.data
}
