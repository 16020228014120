import React from 'react'

export const useKeyDown = (callback: (event: KeyboardEvent) => void, keys: string[]) => {
  const onKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      const wasAnyKeyPressed = keys.includes(event.key)
      if (wasAnyKeyPressed) {
        callback(event)
      }
    },
    [callback, keys]
  )

  React.useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])
}
