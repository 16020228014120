import { forwardRef } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { FormLabel } from '../../atoms/FormLabel/FormLabel.tsx'
import { Textarea, type TextareaProps } from '../../atoms/Textarea/Textarea'
import { cn } from '../../lib/utils'

const textAreaLabelSize = cva('select-none text-xs', {
  variants: {
    labelDimension: {
      default: '',
      '2xl': 'text-2xl font-bold',
    },
  },
  defaultVariants: {
    labelDimension: 'default',
  },
})

export interface TextareaWithLabelProps extends TextareaProps, VariantProps<typeof textAreaLabelSize> {
  readonly label: string
}

export const TextareaWithLabel = forwardRef<HTMLTextAreaElement, TextareaWithLabelProps>(
  ({ label, id, className, labelDimension, ...props }, ref) => (
    <div className={cn('flex flex-col gap-1.5', className)}>
      <FormLabel className={cn(textAreaLabelSize({ labelDimension }))} htmlFor={id}>
        {label}
      </FormLabel>
      <Textarea id={id} ref={ref} {...props} />
    </div>
  )
)
