import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react'

import type * as SwitchPrimitives from '@radix-ui/react-switch'

import { FormLabel } from '../../atoms/FormLabel/FormLabel.tsx'
import { Switch } from '../../atoms/Switch/Switch'
import { cn } from '../../lib/utils'

export interface SwitchWithLabelProps extends ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  readonly label: string
}

export const SwitchWithLabel = forwardRef<ElementRef<typeof SwitchPrimitives.Root>, SwitchWithLabelProps>(
  ({ label, id, className, ...props }, ref) => (
    <div className={cn('flex items-center gap-2', className)}>
      <Switch id={id} {...props} ref={ref} />
      <FormLabel htmlFor={id} className={cn('select-none pt-1')}>
        {label}
      </FormLabel>
    </div>
  )
)
