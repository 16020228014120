import { collections } from '@via/frontend-schema'
import { type ConfigurationKeys } from '@via/schema'
import { type DocumentInput, type DocumentOutput } from 'zod-firebase'

type ConfigurationDocument<Type extends string> = Extract<
  DocumentOutput<typeof collections.configurations.zod>,
  { _id: Type }
>

type DefaultConfiguration<Type extends string> = Omit<
  Extract<DocumentInput<typeof collections.configurations.zod>, { _id: Type }>,
  '_id'
>

type Configuration<Type extends string> = Omit<ConfigurationDocument<Type>, '_id'>

export const readConfiguration = async <Type extends ConfigurationKeys>(
  configurationName: Type,
  defaultValue?: DefaultConfiguration<Type>
): Promise<Configuration<Type>> => {
  const document = (await collections.configurations.findById(configurationName)) as ConfigurationDocument<Type>
  if (!document) {
    const { _id, ...configuration } = collections.configurations.zod.parse({ _id: configurationName, ...defaultValue })
    return configuration as Configuration<Type>
  }

  const { _id, ...configuration } = document
  return configuration
}
