import { useMemo } from 'react'

import { useBudget } from '@via/components'

const latestBudgetReferenceVersion = Number(import.meta.env.VITE_BUDGET_REFERENCE_VERSION)

export const useSyncRequired = () => {
  const budget = useBudget()

  return useMemo(
    () => budget.referenceVersion !== undefined && budget.referenceVersion !== latestBudgetReferenceVersion,
    [budget]
  )
}
