import type React from 'react'

import { useOfflineState } from '../offlineState/useOfflineState.ts'

import { OfflineBudgetListProvider } from './OfflineBudgetListProvider.tsx'
import { OnlineBudgetListProvider } from './OnlineBudgetListProvider.tsx'

export const BudgetListProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { offline } = useOfflineState()
  return offline ? (
    <OfflineBudgetListProvider>{children}</OfflineBudgetListProvider>
  ) : (
    <OnlineBudgetListProvider>{children}</OnlineBudgetListProvider>
  )
}
