import { type BudgetReference, type ScenarioState } from '@via/compute'
import { wrap } from 'comlink'
import { EMPTY, filter, fromEvent, map, merge, of, switchMap, take, timer } from 'rxjs'

import { appRxDatabase } from '../rxdb/app/appRxDatabase.ts'
import { appRxState } from '../rxdb/app/appRxState.ts'
import { type AppRxDatabase, type AppRxState } from '../rxdb/types.ts'

import { BudgetHandler } from './budget/BudgetHandler.ts'
import { type BudgetOperator } from './budget/BudgetOperator.ts'
import { type PushOperator } from './push/PushOperator.ts'
import { BudgetReportsHandler } from './report/BudgetReportsHandler.ts'
import { type BudgetReportsOperator } from './report/BudgetReportsOperator.ts'
import { ScenarioHandler } from './scenario/ScenarioHandler.ts'
import { type ScenarioOperator } from './scenario/ScenarioOperator.ts'
import SharedWorker from './app.shared-worker.ts?sharedworker'
import Worker from './app.worker.ts?worker'
import { type AppWorkerOperator } from './AppWorkerOperator.ts'
import ComputeWorker from './compute.worker.ts?worker'
import { type ComputeWorkerOperator } from './ComputeWorkerOperator.ts'

type PublicInterface<T> = { [K in keyof T]: T[K] }
type Operator = PublicInterface<BudgetOperator> &
  PublicInterface<ScenarioOperator> &
  PublicInterface<AppWorkerOperator> &
  PublicInterface<BudgetReportsOperator>

export class AppWorker {
  static readonly #computeWorker = new ComputeWorker({ name: `via-compute-worker` })

  static readonly #sharedWorker = new SharedWorker({
    name: `via-shared-worker`,
  })

  static readonly #worker = new Worker({
    name: `via-app-worker`,
  })

  static #isWorkerReady = false

  static {
    const listener = (message: MessageEvent) => {
      if (message.data === 'ready') {
        this.#worker.removeEventListener('message', listener)
        this.#isWorkerReady = true
      }
    }
    this.#worker.addEventListener('message', listener)
  }

  static readonly isWorkerReady$ = merge(
    of(this.#isWorkerReady).pipe(switchMap(() => (this.#isWorkerReady ? of(true) : EMPTY))),
    fromEvent<MessageEvent>(this.#worker, 'message').pipe(
      filter((message) => message.data === 'ready'),
      take(1),
      map(() => true)
    ),
    timer(3000).pipe(map(() => true))
  )

  static readonly _appOperator = wrap<PublicInterface<PushOperator>>(AppWorker.#sharedWorker.port)

  static readonly #computeOperator = wrap<ComputeWorkerOperator>(AppWorker.#computeWorker)

  static readonly #operator = wrap<Operator>(AppWorker.#worker)

  static readonly instance = new AppWorker(appRxDatabase, appRxState)

  private constructor(
    readonly appDatabase: AppRxDatabase,
    readonly appState: AppRxState,
    readonly budgetHandler = new BudgetHandler(appDatabase, AppWorker.#operator),
    readonly budgetReportsHandler = new BudgetReportsHandler(AppWorker.#operator),
    readonly scenarioHandler = new ScenarioHandler(appDatabase, AppWorker.#operator)
  ) {}

  signOut = async () => {
    await Promise.all([this.appState.collection.remove(), this.appDatabase.snapshots.remove()])
    globalThis.location.reload()
  }

  resetRxDB = async () => {
    await AppWorker.#operator.resetRxDB()
    globalThis.location.reload()
  }

  computeAllRows = async (
    budgetId: string,
    scenarioId: string,
    state: ScenarioState | undefined,
    budgetReference: BudgetReference | undefined
  ) => {
    await this.appState.set(`computing.${scenarioId}`, () => true)
    try {
      return await AppWorker.#computeOperator.computeAllRows(budgetId, scenarioId, state, budgetReference)
    } finally {
      await this.appState.set(`computing.${scenarioId}`, () => false)
    }
  }

  toggleForceOffline = () => this.appState.set('forceOffline', (value) => !value)
}
