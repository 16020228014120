import { type FC, type PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'

import { ReportMainLayout, type ReportMainLayoutProps } from '../../organisms/ReportMainLayout/ReportMainLayout'

export type OfflineReportViewProps = Pick<ReportMainLayoutProps, 'budget' | 'onNavigationChange'>

export const OfflineReportView: FC<PropsWithChildren<OfflineReportViewProps>> = ({
  budget,
  onNavigationChange,
  children,
}) => {
  const intl = useIntl()

  return (
    <ReportMainLayout
      budget={budget}
      onCloseBudget={() => {}}
      onRequestOwnership={() => {}}
      onNavigationChange={onNavigationChange}
      onUpdateBudget={() => {}}
      loading={false}
      offline={true}>
      <div className="pb-4 pl-44">
        <div className="mt-16 space-y-2">
          <div className="flex flex-col gap-1.5 pt-0.5">
            <label className="select-none text-xl font-bold tracking-[-0.28px] text-gray-700">
              {intl.formatMessage({ id: 'general.toast.offline.title' })}
            </label>
          </div>
          <div className="text text-gray-800">
            {intl.formatMessage({ id: 'reportConfiguration.offline.description' })}
          </div>
        </div>
      </div>
      {children}
    </ReportMainLayout>
  )
}
