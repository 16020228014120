import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { Button } from '../../atoms/Button/Button'
import { Label } from '../../atoms/Label/Label'
import { Dialog, DialogContent, DialogOverlay, DialogPortal, DialogTitle } from '../../molecules/Dialog/Dialog'

interface BudgetCreationBillabelWarningDialogProps {
  readonly open: boolean
  readonly onConfirm: () => void
  readonly onCancel: () => void
  readonly year: number
}

export const BudgetCreationBillabelWarningDialog: FC<BudgetCreationBillabelWarningDialogProps> = ({
  open,
  onCancel,
  onConfirm,
  year,
}) => {
  const intl = useIntl()

  return (
    <Dialog open={open}>
      <DialogPortal>
        <DialogOverlay onClick={onCancel} />
        <DialogTitle />
        <DialogContent className="w-fit px-12 py-16">
          <div className="flex flex-col items-center gap-8">
            <div className="flex w-[512px] flex-col items-center gap-2">
              <Label className="text-2xl font-bold">
                {intl.formatMessage({ id: 'budgetConfiguration.billableWarning.title' }, { year })}
              </Label>
              <div className="flex flex-col items-center">
                <Label className="text-base">
                  {intl.formatMessage({ id: 'budgetConfiguration.billableWarning.billable' })}
                </Label>
                <Label className="text-base">
                  {intl.formatMessage({ id: 'budgetConfiguration.billableWarning.billableQuestion' }, { year })}
                </Label>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <Button onClick={onCancel} variant="outline">
                {intl.formatMessage({ id: 'budgetConfiguration.billableWarning.cancel' })}
              </Button>
              <Button onClick={onConfirm}>
                {intl.formatMessage({ id: 'budgetConfiguration.billableWarning.confirm' })}
              </Button>
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}
