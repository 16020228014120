import { type DependencyList, useCallback, useEffect, useRef } from 'react'

/**
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/hooks/src/use-callback-ref.ts
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useCallbackRef<RT, TArgs extends any[], T extends (...args: TArgs) => RT>(
  callback: T | undefined,
  deps: DependencyList = []
) {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(((...args) => callbackRef.current?.(...args)) as T, deps)
}
