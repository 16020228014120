import { addBreadcrumb, captureException, startSpan } from '@sentry/react'
import { useMutation, type UseMutationOptions } from '@tanstack/react-query'

import { invokeApi } from '../invoke/invokeApi.ts'

export type ApiMutationOptions<TData, TVariables, TError = unknown, TContext = unknown> = Omit<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  'mutationFn'
> & {
  reportErrorToSentry?: boolean
}

export function useApi<TData, TVariables, TError = unknown, TContext = unknown>({
  type,
  onError,
  onSuccess,
  reportErrorToSentry = true,
  ...options
}: { type: string } & ApiMutationOptions<TData, TVariables, TError, TContext>) {
  return useMutation<TData, TError, TVariables, TContext>({
    mutationFn: (value) => startSpan({ name: `via-api`, attributes: { type } }, () => invokeApi(type, value)),
    onSuccess: (data, variables, context) => {
      addBreadcrumb({
        category: 'via-api',
        message: `invoke via-api ${type}`,
        data: {
          type,
          variables,
          data,
          context,
        },
      })
      return onSuccess?.(data, variables, context)
    },
    onError: (e, variables, context) => {
      if (reportErrorToSentry) {
        captureException(e, { tags: { type }, fingerprint: ['via-api', type], extra: { variables, context } })
      }
      return onError?.(e, variables, context)
    },
    ...options,
  })
}
