import { type BlueprintVersion } from '../../../enums'
import { type DataKey } from '../../data-key-zod'
import { DataAttributes } from '../attributes'
import { type DataAttribute } from '../types'
import { resolveAlias } from '../utils'

import { type FractionOfDataKey } from './fraction-of-data-keys'

export const findFractionOf = (key: DataKey, blueprintVersion?: BlueprintVersion): FractionOfDataKey | undefined => {
  const attribute = DataAttributes[key] as DataAttribute
  if (attribute.fractionOf || (attribute.type !== 'alias' && attribute.type !== 'blueprint-alias')) {
    return attribute.fractionOf
  }
  const aliasKey = resolveAlias(key, blueprintVersion, 1)
  return aliasKey && findFractionOf(aliasKey, blueprintVersion)
}
