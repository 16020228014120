import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { dataLine, dividerLine } from '../utils.ts'

export const milkConfigurationSheet2024Blueprint = {
  sections: [
    {
      id: 'milk.production.section',
      titleKey: 'table.milk.production.section',
      showBottomHeader: false,
      collapsible: false,
      children: [
        dividerLine({ type: 'default' }, 'milk.production.section'),
        dataLine('milk.production.cows.head-count', {
          variant: 'group',
          unit: 'head-count',
          cellVariants: { 'result-projections': 'editableValue' },
        }),
        dataLine('milk.production.cows.liter-per-head-count', {
          variant: 'group',
          unit: 'liter',
          cellVariants: { 'result-projections': 'editableValue' },
        }),
        dataLine('milk.production.content.kilogram-per-hectoliter.fat', {
          variant: 'group',
          unit: 'kilogram-per-hectoliter',
          cellVariants: { 'result-projections': 'editableValue' },
        }),
        dataLine('milk.production.content.kilogram-per-hectoliter.protein', {
          variant: 'group',
          unit: 'kilogram-per-hectoliter',
          cellVariants: { 'result-projections': 'editableValue' },
        }),
        dataLine('milk.production.content.kilogram-per-hectoliter.lactose', {
          variant: 'group',
          unit: 'kilogram-per-hectoliter',
          cellVariants: { 'result-projections': 'editableValue' },
        }),
        dataLine('milk.production.volume.hectoliter.total', {
          variant: 'calculate',
          unit: 'hectoliter',
        }),
        dataLine('milk.production.volume.liter.excluded', {
          variant: 'group',
          unit: 'liter',
          cellVariants: { 'result-projections': 'editableValue' },
        }),
        dataLine('milk.production.sale.hectoliter', {
          variant: 'calculate',
          unit: 'hectoliter',
        }),
        dataLine('milk.production.sale.kilogram-of-fat', {
          variant: 'calculateDerived',
          unit: 'kilogram-of-fat',
        }),
        dataLine('milk.production.sale.kilogram-of-fat-per-day', {
          variant: 'calculateDerived',
          unit: 'kilogram-of-fat-per-day',
          closingSection: true,
        }),
      ],
    },
    {
      id: 'milk.quota.section',
      titleKey: 'table.milk.quota.section',
      showBottomHeader: false,
      collapsible: false,
      children: [
        dataLine('milk.quota.kilogram-of-fat-per-day.starting-balance', {
          variant: 'subTotal',
          unit: 'kilogram-of-fat-per-day',
        }),
        {
          ...dividerLine({ type: 'calculate' }, 'milk.quota.ownership'),
          children: [
            dataLine('milk.quota.kilogram-of-fat-per-day.purchase', {
              variant: 'groupCalculate',
              unit: 'none',
              numberDisplayType: 'kilogram-of-fat-per-day',
            }),
            dataLine('milk.quota.kilogram-of-fat-per-day.sale', {
              variant: 'groupCalculate',
              unit: 'none',
              numberDisplayType: 'kilogram-of-fat-per-day',
            }),
            dataLine('milk.quota.kilogram-of-fat-per-day.adjustment', {
              variant: 'groupCalculate',
              unit: 'none',
              numberDisplayType: 'kilogram-of-fat-per-day',
            }),
            dataLine('milk.quota.kilogram-of-fat-per-day.balance', {
              variant: 'subTotal',
              unit: 'kilogram-of-fat-per-day',
            }),
          ],
        },
        {
          ...dividerLine({ type: 'calculate', border: 'none' }, 'milk.quota.effective'),
          children: [
            dataLine('milk.quota.kilogram-of-fat-per-day.start-up', {
              variant: 'groupCalculate',
              unit: 'none',
              numberDisplayType: 'kilogram-of-fat-per-day',
            }),
            dataLine('milk.quota.kilogram-of-fat-per-day.rental', {
              variant: 'groupCalculate',
              unit: 'none',
              numberDisplayType: 'kilogram-of-fat-per-day',
            }),
            dataLine('milk.quota.kilogram-of-fat-per-day.effective', {
              variant: 'subTotal',
              unit: 'kilogram-of-fat-per-day',
            }),
          ],
        },
        {
          ...dividerLine({ type: 'calculate', border: 'none' }, 'milk.quota.allowed-production'),
          children: [
            dataLine('milk.quota.kilogram-of-fat-per-day.loan', {
              variant: 'groupCalculate',
              unit: 'none',
              numberDisplayType: 'kilogram-of-fat-per-day',
            }),
            dataLine('milk.quota.kilogram-of-fat-per-day.federation-extension', {
              variant: 'groupCalculate',
              unit: 'none',
              numberDisplayType: 'kilogram-of-fat-per-day',
            }),
            dataLine('milk.quota.allowed-production.kilogram-of-fat-per-day', {
              variant: 'subTotal',
              unit: 'kilogram-of-fat-per-day',
            }),
          ],
        },
        dataLine('milk.quota.allowed-production.hectoliter', {
          variant: 'calculate',
          unit: 'hectoliter',
        }),
        {
          ...dividerLine({ type: 'reference' }, 'milk.quota.analysis'),
          children: [
            dataLine('milk.quota.usage.kilogram-of-fat.excess', {
              variant: 'groupReference',
              unit: 'kilogram-of-fat',
              cellVariants: { reference: 'emptyCell' },
            }),
            dataLine('milk.quota.usage.kilogram-of-fat.shortage', {
              variant: 'groupReference',
              unit: 'kilogram-of-fat',
              cellVariants: { reference: 'emptyCell' },
            }),
            dividerLine({ type: 'reference', border: 'none' }, 'milk.quota.analysis.sub1'),
            dataLine('milk.quota.tolerance.kilogram-of-fat.start', {
              variant: 'groupReference',
              unit: 'kilogram-of-fat',
              cellVariants: { reference: 'emptyCell' },
            }),
            dataLine('milk.quota.tolerance.kilogram-of-fat.variation', {
              variant: 'groupReference',
              unit: 'kilogram-of-fat',
              cellVariants: { reference: 'emptyCell' },
            }),
            dataLine('milk.quota.tolerance.kilogram-of-fat.end', {
              variant: 'groupReference',
              unit: 'kilogram-of-fat',
              overridableReference: 'milk.quota.tolerance.kilogram-of-fat.reference.balance',
            }),
            dividerLine({ type: 'reference', border: 'none' }, 'milk.quota.analysis.sub2'),
            dataLine('milk.quota.analysis.percentage.usage', {
              variant: 'groupReference',
              unit: 'percent',
            }),
            dataLine('milk.quota.analysis.kilogram-of-fat-per-day.allowed.difference', {
              variant: 'groupReference',
              unit: 'kilogram-of-fat-per-day',
            }),
            dataLine('milk.quota.analysis.head-count.equilibrium', {
              variant: 'groupReference',
              unit: 'head-count',
            }),
            dataLine('milk.quota.analysis.liter-per-head-count.equilibrium', {
              variant: 'groupReference',
              unit: 'liter',
            }),
          ],
        },
        dividerLine({ type: 'reference', border: 'bottom' }, 'milk.quota.analysis.end'),
      ],
    },
    {
      id: 'milk.sale.section',
      titleKey: 'table.milk.sale.section',
      showBottomHeader: false,
      collapsible: false,
      children: [
        {
          ...dividerLine({ type: 'calculate' }, 'milk.sale.section'),
          children: [
            dataLine('milk.price.currency-per-hectoliter.base', {
              variant: 'groupCalculate',
              unit: 'currency-per-hectoliter',
            }),
            dataLine('milk.price.currency-per-hectoliter.bonus', {
              variant: 'groupCalculate',
              unit: 'currency-per-hectoliter',
            }),
            dataLine('milk.price.currency-per-hectoliter', {
              variant: 'calculate',
              unit: 'currency-per-hectoliter',
            }),
            dataLine('milk.sale.dividends.total', {
              variant: 'groupCalculate',
              unit: 'currency',
              bottomBorder: 'calculate',
            }),
          ],
        },
        dividerLine({ type: 'calculate', border: 'bottom' }),
        dataLine('milk.sale.hectoliter', { variant: 'total', unit: 'hectoliter' }),
        dataLine('milk.sale.total', { variant: 'total', unit: 'currency' }),
      ],
    },
    {
      id: 'milk.marketing.section',
      titleKey: 'table.milk.marketing.section',
      showBottomHeader: false,
      collapsible: false,
      children: [
        {
          ...dividerLine({ type: 'calculate' }, 'milk.marketing.section'),
          children: [
            dataLine('milk.marketing.currency-per-hectoliter', {
              variant: 'groupCalculate',
              unit: 'currency-per-hectoliter',
            }),
            dataLine('milk.marketing.total', {
              variant: 'groupCalculate',
              unit: 'currency',
              bottomBorder: 'calculate',
            }),
          ],
        },
        dividerLine({ type: 'calculate', border: 'bottom' }),
      ],
    },
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true, className: 'w-84' },
    {
      id: 'selected-reference',
      type: 'reference',
      sticky: true,
      className: 'w-[7.5rem]',
      topHeaderClassName: 'w-[7.5rem] h-14',
      withDividerRight: true,
      showUnitInCell: true,
      displayOnTableState: ['selected-reference'],
    },
    {
      id: 'all-references',
      type: 'all-references',
      sticky: true,
      className: 'w-[7.5rem]',
      topHeaderClassName: 'w-[7.5rem] h-14',
      baseColumnWidth: 120,
      withDividerRight: true,
      displayOnTableState: ['all-references'],
    },
    {
      id: 'divider',
      type: 'divider',
      className: 'w-1',
    },
    {
      id: 'projections',
      type: 'projections',
      withDividerRight: true,
      className: 'w-[7.5rem]',
      topHeaderVariant: 'head',
      topHeaderClassName: 'w-[7.5rem] h-14',
      showUnitInCell: true,
    },
  ],
  defaultState: 'selected-reference',
  expendedState: 'all-references',
} as const satisfies Except<BlueprintTable<'selected-reference' | 'all-references'>, 'version'>
