import { z } from 'zod'

import { CurrentProductionsZod } from '../monetary/current-productions-zod'

import { ClientContactZod } from './client-contact-zod'
import { ClientUserZod } from './client-user-zod'

export const ClientInformationZod = z.object({
  viagritelId: z.string(),
  code: z.string(),
  name: z.string(),
  nim: z.string(),

  latestYear: z.number(),
  currentProductions: CurrentProductionsZod.optional().default({}),
  startOfYearOffset: z.number().int().optional().default(0),

  address: z.object({
    streetAddress: z.string(),
    city: z.string(),
    province: z.string(),
    postalCode: z.string(),
  }),

  groups: z
    .record(
      z.object({
        name: z.string(),
      })
    )
    .optional()
    .default({}),

  users: z.record(ClientUserZod).optional().default({}),
  contactInformation: ClientContactZod,
})
