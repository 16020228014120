import { z } from 'zod'

export const AssetComputationTypeZod = z.enum([
  'value',
  'valueMovement',
  'amortization',
  'contributoryValue',
  'disbursement',
  'subsidies',
  'actualDisbursement',
  'inflation',
  'valueBalanceMovement', // to be sum into balance.validation.other-movements
])
export type AssetComputationType = z.infer<typeof AssetComputationTypeZod>
