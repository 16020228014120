import { type FieldPathByValue, type FieldValues } from 'react-hook-form'

import { cn } from '../../lib/utils.ts'
import { LineCellWithUnit, type LineCellWithUnitProps } from '../LineCellWithUnit/LineCellWithUnit.tsx'

import { PopoverInlineCellFormField, type PopoverInlineCellFormFieldProps } from './PopoverInlineCellFormField.tsx'

export interface PopoverLineCellFormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends PopoverInlineCellFormFieldProps<TFieldValues, TName>,
    Pick<
      LineCellWithUnitProps,
      | 'value'
      | 'numberDisplayType'
      | 'variant'
      | 'withDividerRight'
      | 'className'
      | 'bottomBorder'
      | 'formatNumberOptions'
    > {
  readonly cellClassName?: string
}

export const PopoverLineCellWithUnitFormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>({
  value = 0,
  unit,
  numberDisplayType,
  variant,
  withDividerRight = true,
  className,
  bottomBorder,
  formatNumberOptions,
  cellClassName,
  tabIndex = 0,
  ...props
}: PopoverLineCellFormFieldProps<TFieldValues, TName>) => (
  <PopoverInlineCellFormField<TFieldValues, TName> className={cn('w-16', className)} unit={unit} {...props}>
    <LineCellWithUnit
      value={value}
      unit={unit}
      numberDisplayType={numberDisplayType}
      variant={variant}
      withDividerRight={withDividerRight}
      className={cn('w-16', className, cellClassName)}
      bottomBorder={bottomBorder}
      formatNumberOptions={formatNumberOptions}
      tabIndex={tabIndex}
    />
  </PopoverInlineCellFormField>
)
