import { type ScenarioState } from '@via/compute'

import { loan2024Blueprint } from './2024/loan2024Blueprint.ts'

export const loanBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...loan2024Blueprint }
  }
}
