import { type FC, type PropsWithChildren, useCallback, useMemo, useState } from 'react'

import { type BudgetData, type BudgetUpdateData } from '../../types'
import { type BudgetUpdateFormData } from '../BudgetUpdateForm/BudgetUpdateFormZod.ts'

import { BudgetUpdateDialog } from './BudgetUpdateDialog'
import { BudgetUpdateDialogContext } from './BudgetUpdateDialogContext.ts'

export interface BudgetUpdateDialogProviderProps {
  readonly onUpdateBudget: (data: Omit<BudgetUpdateData, 'lastModification'>) => Promise<void> | void
  readonly budget: BudgetData
}

export const BudgetUpdateDialogProvider: FC<PropsWithChildren<BudgetUpdateDialogProviderProps>> = ({
  children,
  budget,
  onUpdateBudget,
}) => {
  const [open, setOpen] = useState<boolean>(false)

  const users = useMemo(
    () => ({
      ...budget.client.users,
      ...(budget.users.agronomist
        ? { [budget.users.agronomist.id]: { name: budget.users.agronomist.name, role: budget.users.agronomist.role } }
        : {}),
      ...(budget.users.technician
        ? { [budget.users.technician.id]: { name: budget.users.technician.name, role: budget.users.technician.role } }
        : {}),
      ...(budget.users.collaborator
        ? {
            [budget.users.collaborator.id]: {
              name: budget.users.collaborator.name,
              role: budget.users.collaborator.role,
            },
          }
        : {}),
    }),
    [budget.client, budget.users]
  )

  const onValueChange = useCallback(
    async (data: BudgetUpdateFormData) => {
      const sanitizedData = {
        ...data,
        users: {
          agronomist: data.users?.agronomist ?? undefined,
          technician: data.users?.technician ?? undefined,
          collaborator: data.users?.collaborator ?? undefined,
        },
      }

      await onUpdateBudget(sanitizedData)

      setOpen(false)
    },
    [onUpdateBudget]
  )

  const close = useCallback(() => {
    setOpen(false)
  }, [])
  const providerValue = useMemo(() => ({ setOpen }), [setOpen])

  return (
    <BudgetUpdateDialogContext.Provider value={providerValue}>
      <BudgetUpdateDialog open={open} budget={budget} onCancel={close} users={users} onValueChange={onValueChange} />
      {children}
    </BudgetUpdateDialogContext.Provider>
  )
}
