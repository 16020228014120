import { forwardRef, useId } from 'react'

import { FormLabel } from '../../atoms/FormLabel/FormLabel.tsx'
import { cn } from '../../lib/utils'
import { PercentageInput, type PercentageInputProps } from '../PercentageInput/PercentageInput'

export interface PercentageInputWithLabelProps extends PercentageInputProps {
  readonly label: string
}

export const PercentageInputWithLabel = forwardRef<HTMLInputElement, PercentageInputWithLabelProps>(
  ({ label, id, value, className, ...props }, ref) => {
    const reactId = useId()
    return (
      <div className={cn('flex flex-col gap-1.5 pt-0.5', className)}>
        <FormLabel
          className={cn('select-none', props.dimension === 'xs' ? 'text-xxs' : 'text-xs')}
          htmlFor={id ?? reactId}>
          {label}
        </FormLabel>
        <PercentageInput id={id ?? reactId} value={value} ref={ref} {...props} />
      </div>
    )
  }
)
