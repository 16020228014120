import { type BlueprintVersion } from '../../../enums'
import { type DataKey } from '../../data-key-zod'
import { isDataKey } from '../is-data-key'

import { resolveAlias } from './resolve-alias'

export const resolveAliasOrThrow = (key: string, blueprintVersion?: BlueprintVersion): DataKey => {
  if (!isDataKey(key)) {
    throw new Error(`Invalid data key: ${key}`)
  }
  const resolved = resolveAlias(key, blueprintVersion)
  if (!resolved) {
    throw new Error(`Could not resolve alias: ${key}`)
  }
  return resolved
}
