import { createContext } from 'react'

import { type DataKeyDependenciesRecordKey } from '@via/schema'

import { type OverridableMonetaryCellData } from '../../model'

export type MilkIncomeFormDependenciesState = Partial<
  Record<DataKeyDependenciesRecordKey<'milk-income'>, OverridableMonetaryCellData>
>

export const MilkIncomeFormDependenciesContext = createContext<MilkIncomeFormDependenciesState>({})
