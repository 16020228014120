import { type FormHTMLAttributes, type PropsWithChildren, type Ref } from 'react'
import { forwardRef, useCallback, useMemo } from 'react'
import {
  type FieldErrors,
  type FieldValues,
  type SubmitErrorHandler,
  type SubmitHandler,
  useFormContext,
} from 'react-hook-form'

import { addBreadcrumb } from '@sentry/react'
import { type Except } from 'type-fest'

import { AutomaticFormSubmitProvider } from './AutomaticFormSubmitProvider'
import { FormSubmitContext } from './FormSubmitContext'

export type FormSubmitProviderProps<TFieldValues extends FieldValues = FieldValues> = Except<
  FormHTMLAttributes<HTMLFormElement>,
  'onSubmit'
> &
  PropsWithChildren<{
    readonly autoSubmit?: boolean
    readonly autoSubmitDelay?: number
    readonly onFormSubmit: SubmitHandler<TFieldValues>
    readonly onFormApply?: SubmitHandler<TFieldValues>
    readonly onFormInvalid?: SubmitErrorHandler<TFieldValues>
  }>

export const FormSubmitProvider = forwardRef(
  <T extends FieldValues = FieldValues>(
    {
      onFormSubmit,
      onFormApply,
      onFormInvalid,
      autoComplete = 'false',
      autoSubmit = false,
      autoSubmitDelay,
      children,
      ...props
    }: FormSubmitProviderProps<T>,
    ref: Ref<HTMLFormElement>
  ) => {
    const { handleSubmit } = useFormContext<T>()
    const onFormInvalidWithBreadcrumb = useCallback(
      (errors: FieldErrors<T>) => {
        addBreadcrumb({
          category: 'form',
          message: 'invalid from data',
          level: 'info',
          data: { errors },
        })
        return onFormInvalid?.(errors)
      },
      [onFormInvalid]
    )
    const onFormSubmitWithBreadcrumb = useCallback(
      (data: T) => {
        addBreadcrumb({
          category: 'form',
          message: 'submit',
          level: 'info',
          data: { data },
        })
        return onFormSubmit(data)
      },
      [onFormSubmit]
    )
    const onFormApplyWithBreadcrumb = useCallback(
      (data: T) => {
        addBreadcrumb({
          category: 'form',
          message: 'apply',
          level: 'info',
          data: { data },
        })
        return onFormApply?.(data)
      },
      [onFormApply]
    )

    const onSubmit = useMemo(
      () => handleSubmit(onFormSubmitWithBreadcrumb, onFormInvalidWithBreadcrumb),
      [handleSubmit, onFormSubmitWithBreadcrumb, onFormInvalidWithBreadcrumb]
    )
    const context = useMemo(
      () => ({
        submit: onSubmit,
        apply: onFormApply && handleSubmit(onFormApplyWithBreadcrumb, onFormInvalidWithBreadcrumb),
      }),
      [onSubmit, onFormApply, onFormApplyWithBreadcrumb, handleSubmit, onFormInvalidWithBreadcrumb]
    )
    return (
      <form autoComplete={autoComplete} onSubmit={onSubmit} ref={ref} {...props}>
        <FormSubmitContext.Provider value={context}>
          {autoSubmit ? (
            <AutomaticFormSubmitProvider wait={autoSubmitDelay}>{children}</AutomaticFormSubmitProvider>
          ) : (
            children
          )}
        </FormSubmitContext.Provider>
      </form>
    )
  }
)
