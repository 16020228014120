import { type ClientData } from '@via/components'
import { useObservable, useObservableState } from 'observable-hooks'
import { map, switchMap } from 'rxjs'

import { appRxDatabase } from '../app/appRxDatabase.ts'

import { querySnapshotLocalDocumentData$ } from './querySnapshotLocalDocumentData.ts'

export const useLocalClientSnapshotData = (client: ClientData) => {
  const client$ = useObservable(
    (inputs$) =>
      inputs$.pipe(
        switchMap(([id]) =>
          querySnapshotLocalDocumentData$<ClientData>(appRxDatabase, 'clients').pipe(
            map((querySnapshot) => querySnapshot.data.find((d) => d._id === id))
          )
        )
      ),
    [client._id]
  )

  return useObservableState(client$, client)
}
