import { useContext } from 'react'

import { FirebaseAuthContext } from './FirebaseAuthProvider.tsx'

export const useFirebaseAuth = () => useContext(FirebaseAuthContext)

export const useAuthenticatedFirebaseAuth = () => {
  const user = useFirebaseAuth()
  if (!user.user) throw new Error('User is not authenticated')
  return user
}
