import { type PaymentFrequency } from '@via/schema'

export const PaymentFrequencyToAnnualEventCount = {
  annual: 1, // Annuelle
  biannual: 2, // Bi-annuelle ou Semestrielle
  quarterly: 4, // Trimestrielle
  monthly: 12, // Mensuelle
  bimonthly: 24, // Bi-mensuelle
  weekly: 52, // Hebdomadaire
} satisfies Record<PaymentFrequency, number>
