import { useContext } from 'react'

import { NetworkStateContext } from './NetworkStateProvider.tsx'

export const useNetworkState = () => {
  const state = useContext(NetworkStateContext)
  if (!state) {
    throw new Error('useNetworkState must be used within a NetworkStateProvider')
  }
  return state
}
