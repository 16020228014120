import { type FC, type PropsWithChildren } from 'react'

import { type Except } from 'type-fest'

import { ReportMainLayout, type ReportMainLayoutProps } from '../../organisms/ReportMainLayout/ReportMainLayout'

export type LoadingReportViewProps = Except<
  ReportMainLayoutProps,
  'loading' | 'currentReport' | 'reports' | 'onAddReportClick'
>

export const LoadingReportView: FC<PropsWithChildren<LoadingReportViewProps>> = ({ children, ...props }) => (
  <ReportMainLayout {...props} loading>
    {children}
  </ReportMainLayout>
)
