import { useContext, useMemo } from 'react'

import { createFileRoute, notFound, Outlet } from '@tanstack/react-router'
import { ReportContext } from '@via/components'
import { collections } from '@via/frontend-schema'

import { type QuerySnapshotState } from '../firestore/hooks/types.ts'
import {
  type FirestoreBudgetReportDocument,
  FirestoreBudgetReportSnapshotContext,
} from '../firestore/snapshot/FirestoreBudgetReportSnaphotProvider.tsx'

export const ReportComponent = () => {
  const { reportId } = Route.useParams()

  const { report } = Route.useLoaderData()
  const { data } = useContext(FirestoreBudgetReportSnapshotContext)

  const reportSnapshotData = useMemo(() => data.find((r) => r._id === reportId), [data, reportId])

  return (
    <ReportContext.Provider value={reportSnapshotData ?? report}>
      <Outlet />
    </ReportContext.Provider>
  )
}

export const Route = createFileRoute('/budgets/$budgetId/reports/$reportId')({
  component: ReportComponent,
  loader: async ({ context: { queryClient }, params: { budgetId, reportId } }) => {
    const queryData = queryClient.getQueryData<QuerySnapshotState<FirestoreBudgetReportDocument>>(['reports', budgetId])
    const cachedReport = queryData?.data.find((r) => r._id === reportId)
    if (cachedReport) {
      return { report: cachedReport }
    }

    const report = await collections.budgets(budgetId).reports.findById(reportId)
    if (!report) {
      throw notFound()
    }

    return { report }
  },
})
