import React from 'react'
import { useIntl } from 'react-intl'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { FormSubmitButtons } from '../../atoms/Form/FormSubmitButtons'
import { Textarea } from '../../atoms/Textarea/Textarea'
import { cn } from '../../lib/utils'
import { PercentageInputWithLabel } from '../../molecules/PercentageInputWithLabel/PercentageInputWithLabel'
import { type MonetaryCellFormData } from '../MonetaryCellFormZod.ts'
import {
  MonetaryWidgetFormSubmitProvider,
  type MonetaryWidgetFormSubmitProviderProps,
} from '../MonetaryWidgetFormSubmitProvider'

export interface MonetaryGrowthWidgetProps extends MonetaryWidgetFormSubmitProviderProps {}

export const MonetaryGrowthWidget = React.forwardRef<HTMLFormElement, MonetaryGrowthWidgetProps>(
  ({ className, value, ...props }, ref) => {
    const intl = useIntl()

    return (
      <MonetaryWidgetFormSubmitProvider
        value={value}
        className={cn(
          'flex w-32 flex-col items-start gap-2.5 border-2 border-white bg-orange-200 p-2.5 shadow-md',
          className
        )}
        ref={ref}
        {...props}>
        <div className="flex flex-col items-start gap-3">
          <FormField<MonetaryCellFormData, 'growth'>
            name="growth"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <PercentageInputWithLabel
                    label={intl.formatMessage({ id: 'financialResults.growthWidget.growth' })}
                    dimension="xs"
                    align="right"
                    dataStatus={value?.override?.growth !== undefined ? 'updated' : 'default'}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField<MonetaryCellFormData, 'note'>
            name="note"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <Textarea
                    placeholder={intl.formatMessage({ id: 'financialResults.growthWidget.notes.placeholder' })}
                    rows={3}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormSubmitButtons className="flex w-full items-start justify-center gap-2" />
      </MonetaryWidgetFormSubmitProvider>
    )
  }
)
