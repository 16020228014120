import { type ApiMutationOptions, useApi } from './mutation/useApi.ts'

export interface InitiateDraftReportGenerationVariables {
  readonly budgetId: string
  readonly reportId: string
}

export const useInitiateDraftReportGeneration = (
  options?: ApiMutationOptions<{ fileId: string }, InitiateDraftReportGenerationVariables>
) =>
  useApi<{ fileId: string }, InitiateDraftReportGenerationVariables>({
    type: 'initiateDraftReportGeneration',
    ...options,
  })
