import { ErrorWithSentryCaptureContext } from '@via/frontend-sentry'
import { type QueryDocumentSnapshot } from 'firebase/firestore'

export class ZodSchemaError<D> extends ErrorWithSentryCaptureContext {
  constructor(snapshot: QueryDocumentSnapshot<D>, options?: ErrorOptions) {
    super(
      `Zod Error for ${snapshot.ref.path}`,
      {
        tags: { 'schema.zod': true, 'schema.collection': snapshot.ref.parent.id, 'schema.document': snapshot.ref.path },
        fingerprint: ['zod', snapshot.ref.parent.id],
        contexts: {
          snapshot: {
            ref: snapshot.ref,
            data: snapshot.data(),
          },
        },
      },
      options
    )
  }
}
