import { type FC, type PropsWithChildren, useMemo } from 'react'

import { Navigate } from '@tanstack/react-router'
import { BudgetContext, type BudgetData, ScenarioTabListContext } from '@via/components'
import { collections } from '@via/frontend-schema'

import { useCurrentUserId } from '../../auth/useCurrentUserId.ts'
import { useDocument } from '../../firestore/hooks/useDocument.ts'
import { BudgetRequestConfirmationDialogProvider } from '../../requests/BudgetRequestConfirmationDialogProvider.tsx'
import { BudgetUserRequestsDialogProvider } from '../../requests/BudgetUserRequestsDialogProvider.tsx'
import { CurrentBudgetReportListProvider } from '../currentBudgetReportList/CurrentBudgetReportListProvider.tsx'

interface FirestoreCurrentBudgetProviderProps {
  readonly budget: BudgetData
}

export const FirestoreCurrentBudgetProvider: FC<PropsWithChildren<FirestoreCurrentBudgetProviderProps>> = ({
  children,
  budget: initialBudget,
}) => {
  const firestoreBudget = useDocument({ ref: collections.budgets.read.doc(initialBudget._id) })
  const userId = useCurrentUserId()
  const currentBudget = useMemo(() => {
    const budget = firestoreBudget.data ?? initialBudget
    const currentUserIsOwner = budget.owner?.userId === userId
    return {
      ...budget,
      currentUserIsOwner,
      readonly: !currentUserIsOwner,
    }
  }, [firestoreBudget.data, initialBudget, userId])

  if (firestoreBudget.exists === false || currentBudget.archived) {
    return <Navigate to="/budgets" />
  }

  if (currentBudget.currentUserIsOwner) {
    return <Navigate to="." />
  }

  return (
    <BudgetContext.Provider value={currentBudget}>
      <ScenarioTabListContext.Provider value={currentBudget.scenarios}>
        <BudgetRequestConfirmationDialogProvider budget={currentBudget}>
          <BudgetUserRequestsDialogProvider budget={currentBudget}>
            <CurrentBudgetReportListProvider budgetId={initialBudget._id}>{children}</CurrentBudgetReportListProvider>
          </BudgetUserRequestsDialogProvider>
        </BudgetRequestConfirmationDialogProvider>
      </ScenarioTabListContext.Provider>
    </BudgetContext.Provider>
  )
}
