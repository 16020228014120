import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { Label } from '../../atoms/Label/Label'
import {
  type InvestmentOperationProviderProps,
  InvestmentOperationsProvider,
  LoanOperationsProvider,
  type LoanOperationsProviderProps,
} from '../../context'
import { MonetaryTableEditingStateProvider } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import { type NavigationHandler } from '../../navigation'
import { MonetaryTable, type MonetaryTableActions, type MonetaryTableProps } from '../../organisms/MonetaryTable'
import { ScenarioMainLayout, type ScenarioMainLayoutProps } from '../../organisms/ScenarioMainLayout/ScenarioMainLayout'
import { type BudgetData, type ScenarioData } from '../../types'

import { useInvestmentPlanBlueprint } from './useInvestmentPlanBlueprint'
import { useInvestmentPlanLoanBlueprint } from './useInvestmentPlanLoanBlueprint'
import { useLoanBlueprint } from './useLoanBlueprint'
import { useLoanSummaryBlueprint } from './useLoanSummaryBlueprint'
import { useNetMarginBlueprint } from './useNetMarginBlueprint'
import { useShortTermManagementBlueprint } from './useShortTermManagementBlueprint'

export type FinanceViewTabs<Section extends 'shortTerm' | 'loan'> = Section extends 'shortTerm'
  ? 'movement' | 'balance'
  : Section extends 'loan'
    ?
        | 'disbursements'
        | 'repayment'
        | 'annuity'
        | 'interest'
        | 'capital'
        | 'projectedCapital'
        | 'endingBalance'
        | 'exigible'
    : never

export interface FinanceViewProps
  extends LoanOperationsProviderProps,
    InvestmentOperationProviderProps,
    Pick<
      ScenarioMainLayoutProps,
      | 'snapshot'
      | 'snapshotName'
      | 'onCloseBudget'
      | 'onRequestOwnership'
      | 'onRefreshScenarioReference'
      | 'onUpdateBudget'
      | 'syncRequired'
      | 'onUpdateScenarioReferences'
    >,
    Required<Pick<MonetaryTableActions, 'onUpdateCell'>>,
    Pick<MonetaryTableProps, 'collapsed' | 'onCollapse'> {
  readonly budget: BudgetData
  readonly scenario: ScenarioData
  readonly onNavigationChange: NavigationHandler
  readonly shortTermTab?: FinanceViewTabs<'shortTerm'>
  readonly loanTab?: FinanceViewTabs<'loan'>
  readonly onTabChange?: <Section extends 'shortTerm' | 'loan'>(section: Section, tab: FinanceViewTabs<Section>) => void
  readonly offline: boolean
  readonly loading: boolean
}

export const FinanceView: FC<FinanceViewProps> = ({
  budget,
  scenario,
  syncRequired,
  offline,
  loading,
  snapshot,
  snapshotName,
  onCloseBudget,
  onRequestOwnership,
  onNavigationChange,
  onRefreshScenarioReference,
  onUpdateScenarioReferences,
  shortTermTab,
  loanTab,
  onAddLoan,
  onUpdateLoan,
  onDeleteLoan,
  onAddInvestment,
  onUpdateInvestment,
  onDeleteInvestment,
  onTabChange,
  onUpdateBudget,
  onUpdateCell,
}) => {
  const intl = useIntl()
  const investmentPlan = useInvestmentPlanBlueprint(scenario)
  const investmentPlanLoan = useInvestmentPlanLoanBlueprint(scenario)
  const netMargin = useNetMarginBlueprint(scenario)
  const shortTermManagement = useShortTermManagementBlueprint(scenario)
  const loan = useLoanBlueprint(scenario)
  const loanSummary = useLoanSummaryBlueprint(scenario)

  return (
    <MonetaryTableEditingStateProvider>
      <LoanOperationsProvider onAddLoan={onAddLoan} onUpdateLoan={onUpdateLoan} onDeleteLoan={onDeleteLoan}>
        <InvestmentOperationsProvider
          onAddInvestment={onAddInvestment}
          onUpdateInvestment={onUpdateInvestment}
          onDeleteInvestment={onDeleteInvestment}>
          <ScenarioMainLayout
            currentScenarioNavigationId="finance"
            budget={budget}
            scenario={scenario}
            syncRequired={syncRequired}
            offline={offline ?? false}
            onCloseBudget={onCloseBudget}
            onRequestOwnership={onRequestOwnership}
            onRefreshScenarioReference={onRefreshScenarioReference}
            onUpdateScenarioReferences={onUpdateScenarioReferences}
            onUpdateBudget={onUpdateBudget}
            onNavigationChange={onNavigationChange}
            loading={loading}
            snapshot={snapshot}
            snapshotName={snapshotName}>
            <div className="pb-8 pl-32">
              <div className="flex">
                <div className="sticky left-36 pl-8 pt-6">
                  <Label className="text-2xl font-bold uppercase text-black">
                    {intl.formatMessage({ id: 'finance.name' })}
                  </Label>
                </div>
              </div>
              <MonetaryTable
                selectedReferenceYear={scenario.selectedReferenceYear}
                references={scenario.referenceYears}
                projections={scenario.period.financeProjectionYears ?? scenario.period.projectionYears}
                readonly={budget.readonly}
                blueprint={investmentPlan}
                currentBlueprintState="default"
                className="pt-5"
              />
              <MonetaryTable
                selectedReferenceYear={scenario.selectedReferenceYear}
                references={scenario.referenceYears}
                projections={scenario.period.financeProjectionYears ?? scenario.period.projectionYears}
                readonly={budget.readonly}
                blueprint={investmentPlanLoan}
                className="pt-5"
              />
              <MonetaryTable
                selectedReferenceYear={scenario.selectedReferenceYear}
                references={scenario.referenceYears}
                projections={scenario.period.financeProjectionYears ?? scenario.period.projectionYears}
                readonly={budget.readonly}
                blueprint={shortTermManagement}
                onUpdateCell={onUpdateCell}
                currentBlueprintTab={shortTermTab}
                onTabChange={(tab) => onTabChange?.('shortTerm', tab)}
                className="pt-5"
              />
              <MonetaryTable
                selectedReferenceYear={scenario.selectedReferenceYear}
                references={scenario.referenceYears}
                projections={scenario.period.financeProjectionYears ?? scenario.period.projectionYears}
                readonly={budget.readonly}
                blueprint={netMargin}
                className="pt-5"
              />
              <MonetaryTable
                selectedReferenceYear={scenario.selectedReferenceYear}
                references={scenario.referenceYears}
                projections={scenario.period.financeProjectionYears ?? scenario.period.projectionYears}
                readonly={budget.readonly}
                readonlyReferences
                blueprint={loan}
                currentBlueprintTab={loanTab}
                onTabChange={(tab) => onTabChange?.('loan', tab)}
                className="pt-5"
              />
              <MonetaryTable
                selectedReferenceYear={scenario.selectedReferenceYear}
                references={scenario.referenceYears}
                projections={scenario.period.financeProjectionYears ?? scenario.period.projectionYears}
                readonly={budget.readonly}
                blueprint={loanSummary}
                onUpdateCell={onUpdateCell}
                className="pt-[2px]"
              />
            </div>
          </ScenarioMainLayout>
        </InvestmentOperationsProvider>
      </LoanOperationsProvider>
    </MonetaryTableEditingStateProvider>
  )
}
