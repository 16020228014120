import React, { type ReactNode } from 'react'

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

import { cn } from '../../lib/utils'

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    readonly inset?: boolean
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex h-8 w-full cursor-default select-none items-center rounded px-3 text-sm font-medium leading-none tracking-[-0.42px] text-gray-700 outline-none focus:bg-blue-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      inset && 'pl-8',
      className
    )}
    {...props}
  />
))

export interface DropdownMenuOption<T extends string = string> {
  text: ReactNode
  value: T
  disabled?: boolean
}

export interface DropdownMenuContentProps<T extends string = string>
  extends React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> {
  readonly options: Array<DropdownMenuOption<T>> | ReadonlyArray<DropdownMenuOption<T>>
  onItemSelect(value: T): void
}

export const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  DropdownMenuContentProps
>(({ className, sideOffset = 4, options, onItemSelect, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      align="start"
      className={cn(
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[8rem] overflow-hidden rounded border border-blue-500 bg-white px-1 py-1 shadow-md',
        className
      )}
      {...props}>
      {options.map((option) => (
        <DropdownMenuItem
          key={option.value}
          id={option.value}
          disabled={option.disabled}
          onSelect={(e: Event) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const id = (e?.target?.id as string) || ''
            if (id && onItemSelect) {
              onItemSelect(id)
            }
          }}>
          {option.text}
        </DropdownMenuItem>
      ))}
    </DropdownMenuPrimitive.Content>
  </DropdownMenuPrimitive.Portal>
))
