import { type DataKey } from '@via/schema'
import { type Except } from 'type-fest'

import { cn } from '../../lib/utils'
import { InlineEditingCellForm } from '../../molecules/InlineEditingCell/InlineEditingCellForm.tsx'
import { MonetaryGrowthWidget } from '../../monetary/MonetaryGrowthWidget/MonetaryGrowthWidget'

import { PopoverEditableValueCell, type PopoverEditableValueCellProps } from './PopoverEditableValueCell'
import { type UpdateCellValue } from './UpdateCellValue.ts'

interface EditableGrowthValueCellProps extends Except<PopoverEditableValueCellProps, 'children'> {
  readonly dataKey: DataKey
  readonly onUpdateCell: UpdateCellValue<'growth'>
}

export const EditableGrowthValueCell = ({
  dataKey,
  cellData,
  className,
  onUpdateCell,
  ...props
}: EditableGrowthValueCellProps) => (
  <PopoverEditableValueCell cellData={cellData} className={className} {...props}>
    {({ type, onClose }) =>
      type === 'inline' ? (
        <InlineEditingCellForm
          value={cellData.value}
          onConfirm={async (value) => {
            await onUpdateCell({ type: 'growth', dataKey, override: { growth: value } })
            onClose()
          }}
          className={cn('-mt-6', className)}
        />
      ) : (
        <MonetaryGrowthWidget
          value={cellData}
          onConfirm={async (value) => {
            await onUpdateCell({ type: 'growth', dataKey, ...value })
            onClose()
          }}
          onApply={async (value) => {
            await onUpdateCell({ type: 'growth', dataKey, ...value })
          }}
        />
      )
    }
  </PopoverEditableValueCell>
)
