import React from 'react'

type FormSubmit = () => Promise<void>
interface FormSubmitContextValue {
  readonly submit: FormSubmit
  readonly apply?: FormSubmit
}

export const FormSubmitContext = React.createContext<FormSubmitContextValue>({
  submit: () => Promise.reject(new Error('Missing FormSubmitContext')),
})
