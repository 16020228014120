import { z } from 'zod'

export const MilkQuotaComputationTypeZod = z.enum([
  'startingBalance', // kgMG/jr
  'purchase', // kgMG/jr
  'purchaseCost', // $
  'sale', // kgMG/jr
  'saleCost', // $
  'disbursement', // $
  'adjustment', // %
  'adjustmentAmount', // kgMG/jr
  'movement', // kgMG/jr
  'balance', // kgMG/jr
  'assetValue', // $
  'assetValueMovement', // $
  'startUp', // kgMG/jr
  'rental', // kgMG/jr
  'rentalCost', // $/kgMG/jr
  'rentalTotalCost', // $
  'effective', // kgMG/jr
  'loan', // %
  'loanAmount', // kgMG/jr
  'federationExtensionDays', // jr
  'federationExtensionUsage', // %
  'federationExtension', // kgMG/jr
  'allowedKilogram', // kgMG
  'allowedProduction', // kgMG/jr
  'toleranceBalance', // kgMG
  'toleranceMovement', // kgMG
  'toleranceExcess', // kgMG
  'toleranceShortage', // kgMG
])
export type MilkQuotaComputationType = z.infer<typeof MilkQuotaComputationTypeZod>
