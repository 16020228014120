import { createContext } from 'react'

import { type collections } from '@via/frontend-schema'
import { type DistributedOmit } from 'type-fest'
import { type SchemaDocumentOutput } from 'zod-firebase'

import { CollectionReducerStateInitial } from '../hooks/collectionStateReducer.ts'
import { type UseCollectionResult } from '../hooks/useCollection.ts'

export type FirestoreBudgetDocument = SchemaDocumentOutput<typeof collections.budgets> & {
  readonly readonly?: boolean
  readonly currentUserIsOwner: boolean
}

export const FirestoreBudgetListSnapshotContext =
  createContext<DistributedOmit<UseCollectionResult<FirestoreBudgetDocument>, 'snapshot'>>(
    CollectionReducerStateInitial
  )
