import { type LinkProps, type NavigateOptions, type RegisteredRouter } from '@tanstack/react-router'

type Route<
  TFrom extends string = string,
  TTo extends string | undefined = '.',
  TMaskFrom extends string = TFrom,
  TMaskTo extends string = '.',
> = NavigateOptions<RegisteredRouter, TFrom, TTo, TMaskFrom, TMaskTo>

export type NavigationHandler<
  TFrom extends string = string,
  TTo extends string | undefined = '.',
  TMaskFrom extends string = TFrom,
  TMaskTo extends string = '.',
> = (route: Route<TFrom, TTo, TMaskFrom, TMaskTo>) => Promise<void>

export interface NavigationItem {
  id: string
  route: LinkProps
  label: string
}

export const route = ({ to, params }: { to: string; params: Record<string, unknown> }): NavigationItem['route'] =>
  ({
    to,
    params,
  }) as NavigationItem['route']
