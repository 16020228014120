import { useCustomCompareMemo } from '@react-hookz/web/useCustomCompareMemo/index.js'
import { type ScenarioState } from '@via/compute'
import { isEqual, pick } from 'lodash-es'

export const useScenarioStateMemo = <T>(
  factory: (state: ScenarioState) => T,
  state: ScenarioState,
  deps: Array<keyof ScenarioState>
) =>
  useCustomCompareMemo(
    () => factory(state),
    [state],
    ([prevState], [newState]) => isEqual(pick(prevState, deps), pick(newState, deps))
  )
