import { type LoanComputationType } from '@via/schema'
import { BigNumber } from 'bignumber.js'
import { times } from 'lodash-es'

import { cumipmt, cumprinc, pmt } from '../../bignumber'

export type LoanComputationAction =
  | {
      id: string
      type: 'new-capital'
      newCapitalBorrowed: BigNumber
    }
  | {
      id: string
      type: 'early-repayment'
      earlyRepayment: BigNumber
    }
  | {
      id: string
      type: 'change-interest-rate'
      interestRateForPeriod: BigNumber
    }
  | {
      id: string
      type: 'pay-capital'
      numberOfPayments: number
    }
  | {
      type: 'compute-interest-and-capital'
      numberOfPeriods: number
    }

export type LoanComputationState = Record<LoanComputationType, BigNumber> & {
  startingBalance: BigNumber
  interestRateForPeriod: BigNumber | undefined | null
  nextPaymentPeriod: number
  numberOfPayments: number
  paymentAmounts: Record<string, BigNumber>
  earlyRepaymentRemainingDueAmount: Record<string, BigNumber>
  payInterestOnly: boolean
  fixedCapitalPayment?: {
    amount: BigNumber
    duration: number
  }
}

const roundToZeroWhenSmall = (value: BigNumber, threshold = 0.0001) =>
  value.absoluteValue().lte(threshold) ? new BigNumber(0) : value

const computeInterestAndCapital = (
  state: LoanComputationState,
  { numberOfPeriods }: Extract<LoanComputationAction, { type: 'compute-interest-and-capital' }>
): LoanComputationState => {
  const { interestRateForPeriod, fixedCapitalPayment } = state
  if (!interestRateForPeriod || numberOfPeriods === 0 || state.remainingDueAmount.lte(0)) {
    return state
  }

  if (state.payInterestOnly) {
    const payment = interestRateForPeriod.multipliedBy(state.remainingDueAmount).multipliedBy(numberOfPeriods)

    return {
      ...state,
      nextPaymentPeriod: state.nextPaymentPeriod + numberOfPeriods,
      annuity: state.annuity.plus(payment),
      interest: state.interest.plus(payment),
    }
  }

  if (fixedCapitalPayment) {
    const capital = BigNumber.min(fixedCapitalPayment.amount.multipliedBy(numberOfPeriods), state.remainingDueAmount)
    const interest = times(numberOfPeriods).reduce((acc, iter) => {
      const paidCapital = BigNumber.min(fixedCapitalPayment.amount.multipliedBy(iter), state.remainingDueAmount)
      const remainingDueAmount = state.remainingDueAmount.minus(paidCapital)
      return acc.plus(remainingDueAmount.multipliedBy(interestRateForPeriod))
    }, new BigNumber(0))

    return {
      ...state,
      nextPaymentPeriod: state.nextPaymentPeriod + numberOfPeriods,
      annuity: state.annuity.plus(capital.plus(interest)),
      interest: state.interest.plus(interest),
      capital: state.capital.plus(capital),
      projectedCapital: state.projectedCapital.plus(capital),
      exigibleCapital: state.exigibleCapital.plus(capital),
      remainingDueAmount: roundToZeroWhenSmall(state.remainingDueAmount.minus(capital)),
    }
  }

  if (state.nextPaymentPeriod >= state.numberOfPayments) {
    return state
  }

  const interest = cumipmt(
    interestRateForPeriod,
    state.numberOfPayments,
    state.startingBalance,
    state.nextPaymentPeriod + 1,
    Math.min(state.nextPaymentPeriod + numberOfPeriods, state.numberOfPayments)
  ).negated()
  const capital = cumprinc(
    interestRateForPeriod,
    state.numberOfPayments,
    state.startingBalance,
    state.nextPaymentPeriod + 1,
    Math.min(state.nextPaymentPeriod + numberOfPeriods, state.numberOfPayments)
  ).negated()

  const projectedCapital = state.earlyRepayment.lte(0)
    ? capital
    : cumprinc(
        interestRateForPeriod,
        state.numberOfPayments,
        state.startingBalance.plus(state.earlyRepayment),
        state.nextPaymentPeriod + 1,
        Math.min(state.nextPaymentPeriod + numberOfPeriods, state.numberOfPayments)
      ).negated()

  return {
    ...state,
    nextPaymentPeriod: state.nextPaymentPeriod + numberOfPeriods,
    annuity: state.annuity.plus(capital.plus(interest)),
    interest: state.interest.plus(interest),
    capital: state.capital.plus(capital),
    projectedCapital: state.projectedCapital.plus(projectedCapital),
    exigibleCapital: state.exigibleCapital.plus(capital),
    remainingDueAmount: roundToZeroWhenSmall(state.remainingDueAmount.minus(capital)),
  }
}

export const reduceLoanState = (
  previousState: LoanComputationState,
  action: LoanComputationAction
): LoanComputationState => {
  switch (action.type) {
    case 'compute-interest-and-capital':
      return computeInterestAndCapital(previousState, action)
    case 'new-capital': {
      const newBalance = previousState.remainingDueAmount.plus(action.newCapitalBorrowed)
      const remainingPayments = previousState.numberOfPayments - previousState.nextPaymentPeriod
      return {
        ...previousState,
        newCapitalBorrowed: previousState.newCapitalBorrowed.plus(action.newCapitalBorrowed),
        remainingDueAmount: newBalance,
        startingBalance: newBalance,
        numberOfPayments: remainingPayments,
        nextPaymentPeriod: 0,
        paymentAmounts: {
          ...previousState.paymentAmounts,
          ...(previousState.interestRateForPeriod && !previousState.payInterestOnly && remainingPayments > 0
            ? { [action.id]: pmt(previousState.interestRateForPeriod, remainingPayments, newBalance).negated() }
            : {}),
        },
      }
    }
    case 'early-repayment': {
      const newBalance = previousState.remainingDueAmount?.minus(action.earlyRepayment)
      const remainingPayments = previousState.numberOfPayments - previousState.nextPaymentPeriod
      return {
        ...previousState,
        capital: previousState.capital.plus(action.earlyRepayment),
        annuity: previousState.annuity.plus(action.earlyRepayment),
        earlyRepayment: previousState.earlyRepayment.plus(action.earlyRepayment),
        remainingDueAmount: newBalance,
        startingBalance: newBalance,
        numberOfPayments: remainingPayments,
        nextPaymentPeriod: 0,
        earlyRepaymentRemainingDueAmount: {
          ...previousState.earlyRepaymentRemainingDueAmount,
          [action.id]: previousState.remainingDueAmount,
        },
        paymentAmounts: {
          ...previousState.paymentAmounts,
          ...(previousState.interestRateForPeriod && !previousState.payInterestOnly && remainingPayments > 0
            ? { [action.id]: pmt(previousState.interestRateForPeriod, remainingPayments, newBalance).negated() }
            : {}),
        },
      }
    }
    case 'change-interest-rate': {
      const remainingPayments = previousState.numberOfPayments - previousState.nextPaymentPeriod
      const paymentAmount = pmt(
        action.interestRateForPeriod,
        remainingPayments,
        previousState.remainingDueAmount
      ).negated()
      return {
        ...previousState,
        interestRateForPeriod: action.interestRateForPeriod,
        startingBalance: previousState.remainingDueAmount,
        numberOfPayments: remainingPayments,
        nextPaymentPeriod: 0,
        paymentAmounts: {
          ...previousState.paymentAmounts,
          ...(paymentAmount.isFinite() ? { [action.id]: paymentAmount } : {}),
        },
      }
    }
    case 'pay-capital': {
      const paymentAmount =
        previousState.interestRateForPeriod &&
        pmt(previousState.interestRateForPeriod, action.numberOfPayments, previousState.remainingDueAmount).negated()
      return {
        ...previousState,
        payInterestOnly: false,
        nextPaymentPeriod: 0,
        numberOfPayments: action.numberOfPayments,
        paymentAmounts: {
          ...previousState.paymentAmounts,
          ...(paymentAmount?.isFinite()
            ? {
                [action.id]: paymentAmount,
              }
            : {}),
        },
      }
    }

    default:
      return previousState
  }
}
