import { type FinanceViewProps, useBudget, useReportFile, useScenario } from '@via/components'
import { noop } from 'lodash-es'

import { useAppNavigation } from '../../navigation/useAppNavigation.ts'

export const useScenarioReadonlyFinancePage = (): FinanceViewProps => {
  const { onNavigationChange } = useAppNavigation()

  const budget = useBudget()
  const scenario = useScenario()
  const file = useReportFile()

  return {
    loading: false,
    offline: false,
    budget,
    scenario,

    snapshotName: file.fileName,

    onNavigationChange,

    onAddInvestment: noop,
    onAddLoan: noop,
    onDeleteInvestment: noop,
    onDeleteLoan: noop,
    onUpdateInvestment: noop,
    onUpdateLoan: noop,
    onUpdateBudget: noop,
    onUpdateCell: noop,
  }
}
