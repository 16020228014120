import { DataAttributes } from './attributes'
import { type ComputationType } from './computation-type-data-key'
import { type DataAttribute } from './types'

export const dataKeyTypedAttributes = <Type extends ComputationType>(
  key: keyof typeof DataAttributes,
  ...types: Type[]
): Extract<DataAttribute, { type: Type }> => {
  const attribute = DataAttributes[key]
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!attribute) {
    throw new Error(`Data key attributes not found for key: ${key}`)
  }
  if (!(types as ComputationType[]).includes(attribute.type)) {
    throw new Error(`Expected ${key} (${attribute.type}) to have type ${types.join(' or ')}`)
  }
  return attribute as Extract<DataAttribute, { type: Type }>
}
