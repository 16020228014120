import { forwardRef, type HtmlHTMLAttributes } from 'react'

import { type BlueprintVersion } from '@via/schema'

import { cn } from '../../lib/utils'
import { type MonetaryItemType } from '../../types'
import { AddLoanSheet } from '../AddLoan/AddLoanSheet'

export interface AddMonetaryItemButtonProps extends HtmlHTMLAttributes<HTMLDivElement> {
  readonly blueprintVersion: BlueprintVersion
  readonly monetaryItemType: MonetaryItemType
}

export const AddMonetaryItemButton = forwardRef<HTMLDivElement, AddMonetaryItemButtonProps>(
  ({ blueprintVersion, className, monetaryItemType, ...props }, ref) => {
    switch (monetaryItemType) {
      case 'loan.agricultural':
        return (
          <div className={cn('flex w-6 items-center justify-center py-1.5', className)} ref={ref} {...props}>
            <AddLoanSheet blueprintVersion={blueprintVersion} category="agricultural" />
          </div>
        )
      case 'loan.shareholders-due':
        return (
          <div className={cn('flex w-6 items-center justify-center py-1.5', className)} ref={ref} {...props}>
            <AddLoanSheet blueprintVersion={blueprintVersion} category="shareholders-due" />
          </div>
        )
      case 'loan.other':
        return (
          <div className={cn('flex w-6 items-center justify-center py-1.5', className)} ref={ref} {...props}>
            <AddLoanSheet blueprintVersion={blueprintVersion} category="other" />
          </div>
        )
    }

    return null
  }
)
