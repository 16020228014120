import type React from 'react'
import { forwardRef } from 'react'
import { useFormState } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Button } from '../../atoms/Button/Button'
import { FormSubmitProvider } from '../../atoms/Form/FormSubmitProvider'
import { cn } from '../../lib/utils'
import {
  BudgetConfigurationForm,
  type BudgetConfigurationFormProps,
} from '../BudgetConfigurationForm/BudgetConfigurationForm'

import { type BudgetCreationFormData } from './BudgetCreationFormZod.ts'

export interface BudgetCreationFormActions {
  readonly onCancel: () => void | Promise<void>
  readonly onConfirm: (form: BudgetCreationFormData) => Promise<void>
}

export type BudgetCreationFormProps = BudgetCreationFormActions &
  Pick<BudgetConfigurationFormProps, 'users'> &
  React.FormHTMLAttributes<HTMLFormElement>

export const BudgetCreationForm = forwardRef<HTMLFormElement, BudgetCreationFormProps>(
  ({ className, onCancel, onConfirm, users, ...props }, ref) => {
    const intl = useIntl()
    const { isSubmitting } = useFormState()

    return (
      <FormSubmitProvider
        onFormSubmit={onConfirm}
        className={cn('flex h-full w-fit flex-col items-center overflow-hidden', className)}
        ref={ref}
        {...props}>
        <BudgetConfigurationForm users={users} />
        <div className="flex gap-4 pt-20">
          <Button onClick={onCancel} variant="outline" disabled={isSubmitting}>
            {intl.formatMessage({ id: 'budgetConfiguration.form.action.cancel' })}
          </Button>
          <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
            {intl.formatMessage({ id: 'budgetConfiguration.form.action.confirm' })}
          </Button>
        </div>
      </FormSubmitProvider>
    )
  }
)
