import { type ScenarioState } from '@via/compute'

import { investmentPlan2024Blueprint } from './2024/investmentPlan2024Blueprint.ts'

export const investmentPlanBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...investmentPlan2024Blueprint }
  }
}
