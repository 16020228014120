import { type FC, useMemo } from 'react'

import dayjs from 'dayjs'
import { times } from 'lodash-es'

export interface MonthlyTableHeaderRowProps {
  readonly startDate: string
}

export const MonthlyTableHeaderRow: FC<MonthlyTableHeaderRowProps> = ({ startDate }) => {
  const startDateDayjs = useMemo(() => dayjs(startDate, 'YYYY-MM-DD').startOf('month'), [startDate])
  return (
    <div className="flex h-6 shrink-0 select-none items-center">
      <div className="w-48 shrink-0 select-none" />
      {times(12, (i) => (
        <div
          key={i}
          className="flex w-16 shrink-0 justify-center border-l border-t border-gray-400 bg-gray-100 p-1 text-sm font-normal tracking-[-0.28px] text-black last:border-r">
          <p className="capitalize">{startDateDayjs.add(i, 'months').format('MMM')}</p>
        </div>
      ))}
    </div>
  )
}
