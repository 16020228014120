import { createContext, type FC, type PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import { useNetworkState } from '@react-hookz/web/useNetworkState/index.js'
import { setUser as setSentryUser } from '@sentry/react'
import { collections } from '@via/frontend-schema'
import { getAuth, onAuthStateChanged, type User } from 'firebase/auth'
import { z } from 'zod'
import { type SchemaDocumentOutput } from 'zod-firebase'

import { useDocument } from '../firestore/hooks/useDocument.ts'

import { currentUser, type CurrentUserInformation } from './currentUser.ts'

export type UserFirestoreDocument = SchemaDocumentOutput<typeof collections.users>

export type FirebaseAuthenticatedContextValue = CurrentUserInformation & {
  readonly initialized: true
  readonly user: User
  readonly userDocument: UserFirestoreDocument | null
  readonly permissions: string[]
  signOut(): Promise<void>
}

export type FirebaseAuthContextValue =
  | FirebaseAuthenticatedContextValue
  | {
      readonly initialized: boolean
      readonly user: null
    }

export const FirebaseAuthContext = createContext<FirebaseAuthContextValue>({
  initialized: false,
  user: null,
})

const IdTokenResultCustomClaimsZod = z.object({
  permissions: z.string().toLowerCase().array().default([]),
})

const getFirebaseUserAuthClaims = async (user: User) => {
  const { claims } = await user.getIdTokenResult()
  return IdTokenResultCustomClaimsZod.parse(claims)
}

export const FirebaseAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [initialized, setInitialized] = useState(false)
  const { online } = useNetworkState()
  const [user, setUser] = useState<User | null>(getAuth().currentUser)
  const [claims, setClaims] = useState<z.infer<typeof IdTokenResultCustomClaimsZod>>()
  const signOut = useCallback(() => {
    setUser(null)
    setClaims(undefined)
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve(getAuth().signOut())
      }, 100)
    })
  }, [])

  useEffect(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onAuthStateChanged(getAuth(), async (firebaseUser) => {
        setUser(firebaseUser)
        setInitialized(true)
        if (!firebaseUser) {
          setClaims(undefined)
          setSentryUser(null)
        } else {
          const userClaims = online ? await getFirebaseUserAuthClaims(firebaseUser) : { permissions: [] }
          setClaims(userClaims)
          setSentryUser(
            firebaseUser && {
              id: firebaseUser.uid,
              email: firebaseUser.email ?? undefined,
              displayName: firebaseUser.displayName,
              claims: userClaims,
            }
          )
        }
      }),
    [online]
  )

  const { data: userDocument = null } = useDocument({
    ref: user ? collections.users.read.doc(user.uid) : null,
  })

  const value = useMemo(
    () =>
      user
        ? {
            initialized: true as const,
            ...currentUser(user),
            userDocument,
            user,
            permissions: claims?.permissions ?? [],
            signOut,
          }
        : { initialized, user },
    [initialized, claims?.permissions, signOut, user, userDocument]
  )

  return <FirebaseAuthContext.Provider value={value}>{children}</FirebaseAuthContext.Provider>
}
