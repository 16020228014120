import React from 'react'

import { useEventListener } from '@react-hookz/web/useEventListener/index.js'

export const PointerStateContext = React.createContext<'hidden' | 'shown'>('shown')

export const PointerStateProvider: React.FC<{ readonly children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = React.useState<'hidden' | 'shown'>(document.pointerLockElement ? 'hidden' : 'shown')

  useEventListener(document, 'pointerlockchange', () => {
    setState(document.pointerLockElement ? 'hidden' : 'shown')
  })

  return <PointerStateContext.Provider value={state}>{children}</PointerStateContext.Provider>
}
