import { type BalanceSheetViewProps, useBudget, useReportFile, useScenario } from '@via/components'
import { noop } from 'lodash-es'

import { useAppNavigation } from '../../navigation/useAppNavigation.ts'

export const useScenarioReadonlyBalancePage = (): BalanceSheetViewProps => {
  const { onNavigationChange } = useAppNavigation()

  const budget = useBudget()
  const scenario = useScenario()
  const file = useReportFile()

  return {
    loading: false,
    offline: false,

    budget,
    scenario,

    snapshotName: file.fileName,

    onNavigationChange,

    onUpdateBudget: noop,
  }
}
