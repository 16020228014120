import { useMemo } from 'react'

import { type CellDataOverride, scenarioStateValueKey } from '@via/compute'
import { type DataKey, resolveAlias, type YearKey } from '@via/schema'

import { useScenario } from './useScenario.ts'

export const useScenarioProjectionOverride = ({
  key,
  year,
}: {
  key: DataKey
  year: YearKey
}): CellDataOverride | undefined => {
  const { overrides, blueprintVersion } = useScenario()
  return useMemo(() => {
    const resolved = resolveAlias(key, blueprintVersion)
    return resolved && overrides?.[key]?.[scenarioStateValueKey(year)]
  }, [blueprintVersion, key, overrides, year])
}
