import {
  type ChangeEvent,
  type FocusEvent,
  forwardRef,
  type KeyboardEvent,
  type ReducerWithoutAction,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { useControllableState } from '@radix-ui/react-use-controllable-state'
import { type Except } from 'type-fest'
import { type z } from 'zod'

import { FormLabel } from '../../atoms/FormLabel/FormLabel'
import { Input, type InputProps } from '../../atoms/Input/Input'
import { cn } from '../../lib/utils'

import { type AdjustmentZod } from './AdjustmentZod'

export type Adjustment = z.infer<typeof AdjustmentZod>

export type AdjustmentOperator = '+' | '-' | '='
export interface AdjustmentInputProps extends Except<InputProps, 'value' | 'defaultValue' | 'onChange'> {
  readonly label: string
  readonly operationDisabled?: boolean
  readonly allowedOperators?: AdjustmentOperator[]
  readonly value?: Adjustment
  readonly defaultValue?: Adjustment
  readonly onChange?: (value: Adjustment) => void
}

interface AdjustmentLocalState {
  operator?: AdjustmentOperator
  isNegative?: boolean
  placeholder?: string
  hasFocus?: boolean
}

type AdjustmentState =
  | { value: string; operator: AdjustmentOperator; isNegative: boolean }
  | { value: ''; operator: ''; isNegative: boolean }
const EMPTY_STATE = { value: '', operator: '', isNegative: false } as const satisfies AdjustmentState

const isValidInput = (value: string) => /^(\d*)(?:[,.](\d*))?$/.test(value)

const toState = (adjustment: Adjustment, { operator, isNegative }: AdjustmentLocalState): AdjustmentState => {
  const [value, negativeSymbol, unsignedValue, integerPart] = /^(-)?((\d*)(?:[,.](\d*))?)$/.exec(adjustment.value) ?? []
  if (!value) {
    return { value: '', operator: operator ?? '', isNegative: isNegative ?? false }
  }
  const integerValue = integerPart ? Number(integerPart) : Number.NaN
  if (adjustment.operator === '' || !Number.isFinite(integerValue)) {
    return { value: '', operator: operator ?? '', isNegative: isNegative ?? false }
  }
  return {
    value: unsignedValue,
    operator: adjustment.operator,
    isNegative: negativeSymbol === '-',
  }
}

const fromState = ({ value, operator, isNegative }: Exclude<AdjustmentState, { operator: '' }>): Adjustment => ({
  value: `${isNegative ? '-' : ''}${value}`,
  operator,
})

export const AdjustmentInput = forwardRef<HTMLInputElement, AdjustmentInputProps>(
  (
    {
      value,
      defaultValue,
      onFocus,
      onBlur,
      onKeyDown,
      label,
      id,
      onChange,
      className,
      operationDisabled,
      allowedOperators,
      ...props
    },
    ref
  ) => {
    const onStateChange = useCallback(
      (state: AdjustmentState) => {
        if (state.operator) {
          onChange?.(fromState(state))
        }
      },
      [onChange]
    )
    const isAllowedOperator = useCallback(
      (operator: AdjustmentOperator) => !allowedOperators || allowedOperators.includes(operator),
      [allowedOperators]
    )

    const defaultOperator = useMemo(
      () => (['=', '+', '-'] as const).find((operator) => isAllowedOperator(operator)) ?? ('=' as const),
      [isAllowedOperator]
    )

    const [localState, setLocalState] = useState<AdjustmentLocalState>({})
    const [state = EMPTY_STATE, setControllableState] = useControllableState<AdjustmentState>({
      prop: value && toState(value, localState),
      defaultProp: defaultValue && toState(defaultValue, localState),
      onChange: onStateChange,
    })

    const setState = useCallback(
      (update: ReducerWithoutAction<AdjustmentState>) => {
        setControllableState((prev) => {
          const newState = update(
            prev ?? { value: '', operator: localState.operator ?? '', isNegative: localState.isNegative ?? false }
          )
          setLocalState((prevLocalState) => ({
            ...prevLocalState,
            ...(newState.operator ? { operator: newState.operator } : {}),
            isNegative: newState.isNegative,
          }))
          return newState
        })
      },
      [localState.isNegative, localState.operator, setControllableState]
    )

    const onChangeOverride = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = (event.nativeEvent.target as HTMLInputElement)?.value
        setState((prev = EMPTY_STATE) => {
          if (!isValidInput(newValue)) {
            return prev
          }
          const operator = prev.operator || defaultOperator
          return { ...prev, value: newValue, operator }
        })
      },
      [defaultOperator, setState]
    )

    const onKeyDownOverride = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (isAllowedOperator('=')) {
          if (event.key === 'Backspace') {
            setState((prev) => (prev.value ? prev : { ...prev, operator: '=', isNegative: false }))
          }
          if (event.key === '=') {
            setState((prev) => ({ ...prev, operator: '=', isNegative: false }))
            event.preventDefault()
          }
          if (event.key === '(' || event.key === ')') {
            setState((prev) => ({ ...prev, operator: '=', isNegative: !prev.isNegative }))
            event.preventDefault()
          }
        }
        if (event.key === '+' && !operationDisabled && isAllowedOperator('+')) {
          setState((prev) => ({ ...prev, operator: '+', isNegative: false }))
          event.preventDefault()
        }
        if (event.key === '-') {
          if (!operationDisabled && isAllowedOperator('-')) {
            setState((prev) => ({ ...prev, operator: '-', isNegative: false }))
          } else {
            setState((prev) => ({ ...prev, isNegative: true }))
          }
          event.preventDefault()
        }
        onKeyDown?.(event)
      },
      [isAllowedOperator, operationDisabled, onKeyDown, setState]
    )

    const onFocusOverride = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setLocalState((prev) => ({ ...prev, hasFocus: true }))
        setState((prev) => ({
          ...prev,
          ...((event.nativeEvent.target as HTMLInputElement)?.value === '0' ? { value: '' } : {}),
          ...(!prev.operator ? { operator: defaultOperator } : {}),
        }))
        onFocus?.(event)
      },
      [setState, onFocus, defaultOperator]
    )

    const onBlurOverride = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setLocalState((prev) => ({ ...prev, hasFocus: false }))
        onBlur?.(event)
      },
      [onBlur, setLocalState]
    )

    return (
      <div className={cn('flex flex-col gap-1.5 pt-0.5', className)}>
        <FormLabel className={cn('select-none', props.dimension === 'xs' ? 'text-xxs' : 'text-xs')} htmlFor={id}>
          {label}
        </FormLabel>
        <div className="relative flex-row">
          {Boolean(state.operator) && (
            <div className="rounded-left absolute inset-y-0 left-0 m-0.5 flex select-none bg-gray-100 py-1.5 pr-0.5">
              <p className="inline-flex items-center px-1 text-xs">{state.operator}</p>
            </div>
          )}
          {state.isNegative && (
            <div className="absolute inset-y-0 left-[18px] flex select-none pt-0.5">
              <p className="inline-flex items-center px-1 text-xs">(</p>
            </div>
          )}
          <Input
            {...props}
            id={id}
            className="pr-2 pt-0.5"
            onChange={onChangeOverride}
            onKeyDown={onKeyDownOverride}
            onBlur={onBlurOverride}
            onFocus={onFocusOverride}
            value={state.value}
            align="right"
            ref={ref}
            placeholder={!localState.hasFocus && !state.operator ? '-' : ''}
          />
          {state.isNegative && (
            <div className="absolute inset-y-0 right-0 flex select-none pt-0.5">
              <p className="inline-flex items-center px-1 text-xs">)</p>
            </div>
          )}
        </div>
      </div>
    )
  }
)
