import { forwardRef, type Ref } from 'react'

import { type Except } from 'type-fest'
import { type z } from 'zod'

import { type ZodFormProviderProps, ZodFormSubmitProvider } from '../../atoms/Form/ZodFormSubmitProvider.tsx'
import { cn } from '../../lib/utils'

import { SheetFormHeader, type SheetFormHeaderProps } from './SheetFormHeader.tsx'

export interface SheetFormProviderProps<Z extends z.ZodTypeAny>
  extends Except<ZodFormProviderProps<Z>, 'title'>,
    SheetFormHeaderProps {}

export const SheetFormProvider = forwardRef(
  <Z extends z.ZodTypeAny>(
    {
      className,
      title,
      readonly,
      disabled,
      onDelete,
      autoComplete = 'off',
      children,
      ...props
    }: SheetFormProviderProps<Z>,
    ref: Ref<HTMLFormElement>
  ) => (
    <ZodFormSubmitProvider
      className={cn('flex min-h-screen flex-col border border-l-4 border-l-gray-400 bg-white', className)}
      ref={ref}
      autoComplete={autoComplete}
      disabled={disabled}
      {...props}>
      <SheetFormHeader title={title} readonly={readonly} disabled={disabled} onDelete={onDelete} />
      {children}
    </ZodFormSubmitProvider>
  )
)
