import { forwardRef, useMemo } from 'react'

import { type Except } from 'type-fest'

import { Input, type InputProps } from '../../atoms/Input/Input'
import { type NumberDisplayType, useFormatNumber } from '../../hooks/useFormatNumber'
import { PercentageInput } from '../PercentageInput/PercentageInput'

export interface FormatedNumberReadonlyInputProps
  extends Except<InputProps, 'type' | 'readOnly' | 'value' | 'onChange'> {
  readonly value?: string | number | undefined | null
  readonly displayType?: NumberDisplayType
}

export const FormatedNumberReadonlyInput = forwardRef<HTMLInputElement, FormatedNumberReadonlyInputProps>(
  ({ value, displayType, tabIndex = -1, ...props }, ref) => {
    const { formatNumber } = useFormatNumber()
    const formated = useMemo(() => {
      switch (true) {
        case value === undefined || value === null:
          return ''
        case typeof value === 'number' && Number.isFinite(value):
          return formatNumber(value, displayType)
        case typeof value === 'string':
          return formatNumber(Number(value), displayType)
        default:
          return ''
      }
    }, [value, formatNumber, displayType])

    switch (displayType) {
      case 'growth':
      case 'interest':
        return <PercentageInput readOnly ref={ref} tabIndex={tabIndex} {...props} value={formated} type="text" />
      default:
        return <Input readOnly ref={ref} tabIndex={tabIndex} {...props} value={formated} />
    }
  }
)
