import React from 'react'

import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const tooltipVariants = cva(
  'text-white animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 overflow-hidden rounded px-2 pt-1.5 pb-1 text-xs font-bold leading-none text-center',
  {
    variants: {
      variant: {
        default: 'bg-black',
        sage: 'bg-sage-800',
        red: 'bg-red-700',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & VariantProps<typeof tooltipVariants>
>(({ className, sideOffset = 4, variant = 'default', ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(tooltipVariants({ variant }), className)}
    {...props}
  />
))

const tooltipArrowVariants = cva('', {
  variants: {
    variant: {
      default: 'fill-black',
      sage: 'fill-sage-800',
      red: 'fill-red-700',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

const TooltipArrow = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Arrow>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Arrow> & VariantProps<typeof tooltipArrowVariants>
>(({ className, variant = 'default', ...props }, ref) => (
  <TooltipPrimitive.Arrow ref={ref} className={cn(tooltipArrowVariants({ variant }), className)} {...props} />
))

export interface TooltipProps extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Provider> {
  readonly label: string
  readonly variant?: 'default' | 'sage' | 'red'
  readonly side?: 'top' | 'right' | 'bottom' | 'left'
  readonly sideOffset?: number
  readonly className?: string
  readonly children: React.ReactNode
}

export const Tooltip = ({
  label,
  variant = 'default',
  side = 'top',
  sideOffset = 0,
  className,
  children,
  ...props
}: TooltipProps) => (
  <TooltipPrimitive.Provider {...props}>
    <TooltipPrimitive.Root>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipContent variant={variant} side={side} sideOffset={sideOffset}>
          {label}
          <TooltipArrow variant={variant} />
        </TooltipContent>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  </TooltipPrimitive.Provider>
)
