import { type RxJsonSchema } from 'rxdb'

import { type AppRxDatabase, type RxCollectionDocumentType } from '../types.ts'

type SnapshotRxDocument = RxCollectionDocumentType<AppRxDatabase['snapshots']> & { readonly _id: string }

export const SnapshotRxSchema = {
  title: 'snapshot schema',
  description: 'snapshot schema',
  version: 0,
  type: 'object',
  primaryKey: '_id',
  required: ['_id'],
  properties: {
    _id: {
      type: 'string',
      maxLength: 100,
    },
    empty: {
      type: 'boolean',
    },
    size: {
      type: 'boolean',
    },
    isLoading: {
      type: 'boolean',
    },
    data: {
      type: 'array',
      items: {
        type: 'object',
      },
    },
    epochTimestamp: {
      type: 'number',
    },
  },
} as const satisfies RxJsonSchema<SnapshotRxDocument>
