import { useIntl } from 'react-intl'

import { type MonthlyTableBlueprint, type MonthlyTableBlueprintRow } from '../MonthlyTable'

const MILK_QUOTA_BLUEPRINT = {
  sections: [
    {
      id: 'section1',
      rows: [
        { id: 'quota-ownership-start', type: 'divider', variant: 'calculate', border: 'top' },
        {
          id: 'purchase',
          type: 'data',
          titleKey: 'table.milk.quota.kilogram-of-fat-per-day.purchase',
          unit: 'kilogram-of-fat-per-day',
        },
        {
          id: 'sale',
          type: 'data',
          titleKey: 'table.milk.quota.kilogram-of-fat-per-day.sale',
          unit: 'kilogram-of-fat-per-day',
        },
        {
          id: 'adjustment',
          type: 'data',
          titleKey: 'table.milk.quota.kilogram-of-fat-per-day.adjustment',
          unit: 'percent',
        },
        {
          id: 'balance',
          type: 'total',
          titleKey: 'milk.quota.form.table.owned',
          unit: 'kilogram-of-fat-per-day',
        },
        { id: 'quota-rental-start', type: 'divider', variant: 'calculate', border: 'top' },
        {
          id: 'startUp',
          type: 'data',
          titleKey: 'table.milk.quota.kilogram-of-fat-per-day.start-up',
          unit: 'kilogram-of-fat-per-day',
        },
        {
          id: 'rental',
          type: 'data',
          titleKey: 'milk.quota.form.table.rental',
          unit: 'kilogram-of-fat-per-day',
        },
        {
          id: 'rentalCost',
          type: 'data',
          titleKey: 'milk.quota.form.table.rental.cost',
          unit: 'currency-per-kilogram-of-fat-per-day',
        },
        {
          id: 'effective',
          type: 'total',
          titleKey: 'milk.quota.form.table.effective',
          unit: 'kilogram-of-fat-per-day',
        },
        { id: 'quota-federation-loan', type: 'divider', variant: 'calculate', border: 'top' },
        {
          id: 'loan',
          type: 'data',
          titleKey: 'milk.quota.form.table.loan',
          unit: 'percent',
        },
        {
          id: 'federationExtensionDays',
          type: 'data',
          titleKey: 'milk.quota.form.table.federation-extension',
          unit: 'day',
        },
        {
          id: 'federationExtensionUsage',
          type: 'data',
          titleKey: 'milk.quota.form.table.federation-extension.usage',
          unit: 'percent',
          numberDisplayType: 'integer',
          defaultValue: 100,
        },
        {
          id: 'allowedKilogram',
          type: 'total',
          titleKey: 'milk.quota.form.table.allowed-kilogram',
          unit: 'kilogram-of-fat',
        },
      ],
    },
  ],
} as const

export type MilkQuotaFormMonthlyTableBlueprintKeys =
  | 'startingBalance' // kgMG/jr
  | 'purchase' // kgMG/jr
  | 'sale' // kgMG/jr
  | 'adjustment' // %
  | 'adjustmentAmount' // kgMG/jr
  | 'movement' // kgMG/jr
  | 'balance' // kgMG/jr
  | 'startUp' // kgMG/jr
  | 'rental' // kgMG/jr
  | 'rentalCost' // $/kgMG/jr
  | 'rentalTotalCost' // $
  | 'effective' // kgMG/jr
  | 'loan' // %
  | 'loanAmount' // kgMG/jr
  | 'federationExtensionDays' // jr
  | 'federationExtensionUsage' // %
  | 'federationExtension' // kgMG/jr
  | 'allowedKilogram' // kgMG

export const useMilkQuotaFormMonthlyTableBlueprint =
  (): MonthlyTableBlueprint<MilkQuotaFormMonthlyTableBlueprintKeys> => {
    const intl = useIntl()

    return {
      ...MILK_QUOTA_BLUEPRINT,
      sections: MILK_QUOTA_BLUEPRINT.sections.map((section) => ({
        ...section,
        rows: section.rows.map<MonthlyTableBlueprintRow<MilkQuotaFormMonthlyTableBlueprintKeys>>((row) => {
          switch (row.type) {
            case 'divider':
              return row satisfies MonthlyTableBlueprintRow<MilkQuotaFormMonthlyTableBlueprintKeys>
            default: {
              const { titleKey, ...rowData } = row
              return {
                ...rowData,
                title: intl.formatMessage({ id: titleKey }),
              }
            }
          }
        }),
      })),
    }
  }
