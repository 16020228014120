import { type FieldPath, type FieldValues } from 'react-hook-form'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { Select, type SelectProps } from '../../atoms/Select/Select'

export type SelectFormFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<
  SelectProps,
  'value' | 'initailValue' | 'onValueChange' | 'useValueFromProps'
> & {
  readonly name: TName
}

export const SelectFormField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  name,
  disabled,
  ...props
}: SelectFormFieldProps<TFieldValues, TName>) => (
  <FormField<TFieldValues, TName>
    name={name}
    disabled={disabled}
    render={({ field }) => (
      <FormItem className="w-full">
        <FormControl>
          <Select
            {...props}
            useValueFromProps
            onValueChange={(v) => {
              field.onChange(v)
            }}
            name={field.name}
            disabled={field.disabled || disabled}
            value={field.value}
            initialValue={field.value}
          />
        </FormControl>
        <FormMessage />
      </FormItem>
    )}
  />
)
