import { BudgetUserZod } from '@via/schema'
import { z } from 'zod'

export const BudgetUpdateFormZod = z.object({
  contractId: z.string().optional().default(''),
  name: z.string().min(1, { message: 'form.required' }),
  users: z.object({
    agronomist: BudgetUserZod.nullable(),
    technician: BudgetUserZod.nullable(),
    collaborator: BudgetUserZod.nullable(),
  }),
})
export type BudgetUpdateFormData = z.output<typeof BudgetUpdateFormZod>
