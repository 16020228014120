import { useContext } from 'react'

import { BudgetCreateDialogContext } from './BudgetCreateDialogProvider'

export const useBudgetCreateDialog = () => {
  const context = useContext(BudgetCreateDialogContext)
  if (!context) {
    throw new Error('useBudgetCreateDialog must be used within a BudgetCreateDialogProvider')
  }

  return context
}
