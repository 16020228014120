import { addBreadcrumb } from '@sentry/core'
import { BigNumber } from 'bignumber.js'
import dayjs from 'dayjs'
import { floor } from 'lodash-es'

import { computeNumberOfFixedCapitalPayments, computeNumberOfPayments } from './compute-number-of-payments'
import { type LoanStateData } from './merge-loan-state'
import { PaymentFrequencyToAnnualEventCount } from './payment-frequency-to-annual-event-count'
import { computeNextDate } from './prepare-loan-state-actions'
import { prepareLoanStateEvents } from './prepare-loan-state-events'
import { type LoanComputationAction, type LoanComputationState, reduceLoanState } from './reduce-loan-state'

export const computeCumulativeLoanState = (
  loanState: LoanStateData,
  startProjectionsDate: Date,
  to: Date
): LoanComputationState => {
  const events = prepareLoanStateEvents(loanState, startProjectionsDate).filter((event) =>
    dayjs(event.date).isBefore(to)
  )

  const duration = loanState.fixedCapitalPaymentAmount
    ? computeNumberOfFixedCapitalPayments(loanState)
    : (loanState.duration ?? computeNumberOfPayments(loanState))

  const startDate = loanState.startDate ? dayjs(loanState.startDate) : null
  const startPaymentsDate = startDate?.add(1, 'hour')?.isAfter(startProjectionsDate)
    ? startDate.toDate()
    : startProjectionsDate

  const numberOfPaymentEventInYear = PaymentFrequencyToAnnualEventCount[loanState.paymentFrequency]
  const durationInPeriods =
    floor(numberOfPaymentEventInYear * dayjs(to).diff(dayjs(startPaymentsDate), 'years', true)) +
    (startDate?.add(1, 'hour')?.isAfter(startProjectionsDate) ? 0 : 1)

  const result = events.reduce<{
    actions: LoanComputationAction[]
    currentDate: dayjs.ConfigType
    remainingPeriods: number
  }>(
    ({ actions, currentDate, remainingPeriods }, event) => {
      const { nextDate, numberOfPaymentPeriods } = computeNextDate(currentDate, event.date, loanState.paymentFrequency)
      return {
        actions: [
          ...actions,
          {
            type: 'compute-interest-and-capital',
            numberOfPeriods: numberOfPaymentPeriods,
          },
          event,
        ],
        currentDate: nextDate,
        remainingPeriods: remainingPeriods - numberOfPaymentPeriods,
      }
    },
    {
      actions: [],
      currentDate: startPaymentsDate,
      remainingPeriods: duration?.periods ? Math.min(durationInPeriods, duration.periods) : durationInPeriods,
    }
  )

  const actions = [
    ...result.actions,
    {
      type: 'compute-interest-and-capital',
      numberOfPeriods: result.remainingPeriods,
    } as const,
  ]

  const startingBalanceValue =
    loanState.startDate && dayjs(startProjectionsDate).subtract(1, 'day').isBefore(loanState.startDate)
      ? new BigNumber(0)
      : (loanState.amount ?? new BigNumber(0))

  const fixedCapitalPayment = loanState.fixedCapitalPaymentAmount && {
    amount: loanState.fixedCapitalPaymentAmount,
    duration: computeNumberOfFixedCapitalPayments(loanState)?.periods ?? 0,
  }

  const initialState: LoanComputationState = {
    annuity: new BigNumber(0),
    interest: new BigNumber(0),
    capital: new BigNumber(0),
    projectedCapital: new BigNumber(0),
    earlyRepayment: new BigNumber(0),
    exigibleCapital: new BigNumber(0),
    interestRateForPeriod: loanState.durationWithoutInterestPaid?.gt(0)
      ? new BigNumber(0)
      : loanState.interestRate?.shiftedBy(-2)?.dividedBy(numberOfPaymentEventInYear),
    startingBalance: startingBalanceValue,
    remainingDueAmount: startingBalanceValue,
    payInterestOnly: loanState?.durationWithoutCapitalPaid?.gt(0) ?? false,
    numberOfPayments: loanState?.durationWithoutCapitalPaid?.gt(0)
      ? loanState.durationWithoutCapitalPaid.toNumber()
      : (loanState.duration?.periods ?? 0),
    newCapitalBorrowed: new BigNumber(0),
    fixedCapitalPayment,
    nextPaymentPeriod: 0,
    paymentAmounts: {},
    earlyRepaymentRemainingDueAmount: {},
  }

  return actions.reduce((prev, action) => {
    const nextState = reduceLoanState(prev, action)
    addBreadcrumb({
      type: 'debug',
      level: 'debug',
      message: 'reduceLoanState',
      category: 'compute',
      data: { previousState: prev, currentState: nextState, action },
    })
    return nextState
  }, initialState)
}
