import type React from 'react'

import { ClientBudgetListView } from '@via/components'

import { useClientBudgetListPage } from './useClientBudgetListPage.ts'

export const ClientBudgetListPage: React.FC = () => {
  const props = useClientBudgetListPage()
  return <ClientBudgetListView key={props.client._id} {...props} />
}
