import { type MilkQuotaComputationType, MilkQuotaComputationTypeZod } from '@via/schema'
import { BigNumber } from 'bignumber.js'

import { tupleToRecord } from '../../utils'

export const bigNumberMilkQuotaRecord = (
  values: Partial<Record<MilkQuotaComputationType, BigNumber>>,
  defaultValue = new BigNumber(0)
): Record<MilkQuotaComputationType, BigNumber> =>
  tupleToRecord(MilkQuotaComputationTypeZod.options, (type) => values[type] ?? defaultValue)
