import { createFileRoute } from '@tanstack/react-router'

import { MilkAnimalsPage } from '../pages/milkAnimals/MilkAnimalsPage.tsx'
import { setScenarioCurrentPage } from '../pages/scenarioUpdate/utils/scenarioPage.ts'

export const Route = createFileRoute('/budgets/$budgetId/scenarios/$scenarioId/milk-animals')({
  component: MilkAnimalsPage,
  loader: ({ params }) => {
    setScenarioCurrentPage(params.budgetId, params.scenarioId, 'milk-animals')
  },
})
