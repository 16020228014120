import { forwardRef } from 'react'
import { useIntl } from 'react-intl'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { FormSubmitButtons } from '../../atoms/Form/FormSubmitButtons'
import { Textarea } from '../../atoms/Textarea/Textarea'
import { cn } from '../../lib/utils'
import { AdjustmentInput } from '../../molecules/AdjustmentInput/AdjustmentInput'
import { FormatedNumberReadonlyInputWithLabel } from '../../molecules/FormatedNumberReadonlyInputWithLabel/FormatedNumberReadonlyInputWithLabel'
import { PercentageInputWithLabel } from '../../molecules/PercentageInputWithLabel/PercentageInputWithLabel'
import { type MonetaryCellFormData } from '../MonetaryCellFormZod.ts'
import {
  MonetaryWidgetFormSubmitProvider,
  type MonetaryWidgetFormSubmitProviderProps,
} from '../MonetaryWidgetFormSubmitProvider'

export type GrbfProjectionWithGrowthWidgetProps = MonetaryWidgetFormSubmitProviderProps

export const GrbfProjectionWithGrowthWidget = forwardRef<HTMLFormElement, GrbfProjectionWithGrowthWidgetProps>(
  ({ className, value, ...props }, ref) => {
    const intl = useIntl()

    return (
      <MonetaryWidgetFormSubmitProvider
        value={value}
        className={cn(
          'flex w-36 flex-col items-start gap-2.5 border-2 border-white bg-orange-200 p-2.5 shadow-md',
          className
        )}
        ref={ref}
        {...props}>
        <div className="gap- flex flex-col items-start gap-3">
          <FormatedNumberReadonlyInputWithLabel
            className="select-all"
            variant="calculate"
            label={intl.formatMessage({ id: 'financialResults.projectionWidget.calculated' })}
            dimension="xs"
            align="right"
            displayType="currency"
            value={value?.baseValue ?? value?.value}
          />

          <FormField<MonetaryCellFormData, 'growth'>
            name="growth"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <PercentageInputWithLabel
                    label={intl.formatMessage({ id: 'financialResults.projectionWidget.growth' })}
                    dimension="xs"
                    align="right"
                    dataStatus={value?.override?.growth !== undefined ? 'updated' : 'default'}
                    placeholder={value?.growth ? String(value.growth) : ''}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField<MonetaryCellFormData, 'override'>
            name="override"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <AdjustmentInput
                    label={intl.formatMessage({ id: 'financialResults.projectionWidget.adjustment' })}
                    dimension="xs"
                    autoFocus
                    onFocus={(e) => {
                      e.target.select()
                    }}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField<MonetaryCellFormData, 'note'>
            name="note"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <Textarea
                    placeholder={intl.formatMessage({ id: 'financialResults.projectionWidget.notes.placeholder' })}
                    rows={5}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormSubmitButtons className="flex w-full items-start justify-center gap-2" />
      </MonetaryWidgetFormSubmitProvider>
    )
  }
)
