import { type FC, useMemo } from 'react'

import {
  type BudgetData,
  type RequestData,
  RequestDeclinedDialog,
  RequesteeUserDialog,
  RequestSentDialog,
} from '@via/components'
import { collections } from '@via/frontend-schema'
import { handleNotAwaitedPromise } from '@via/frontend-sentry'
import dayjs from 'dayjs'

import { useTransferBudget } from '../api/useTransferBudget.ts'
import { useCurrentUserId } from '../auth/useCurrentUserId.ts'
import { useOfflineState } from '../contexts/offlineState/useOfflineState.ts'
import { useCollection } from '../firestore/hooks/useCollection.ts'

export type BudgetUserRequestsDialogProviderProps = React.PropsWithChildren<{
  readonly budget: BudgetData
}>

interface ActiveRequestState {
  state: 'accept-request' | 'waiting' | 'rejected'
  request: RequestData
}

interface InactiveRequestState {
  state: 'none'
  request: null
}

const defaultRequestState = {
  state: 'none',
  request: null,
} as const

const BudgetUserRequestsDialogOnlineProvider: FC<BudgetUserRequestsDialogProviderProps> = ({ children, budget }) => {
  const currentUserId = useCurrentUserId()
  const { mutateAsync: transferBudget } = useTransferBudget()

  const {
    data: [latestRequest],
  } = useCollection<RequestData>({
    query: collections.budgets(budget._id).requests.prepare({
      name: `requests`,
      where: [
        ['status', 'in', ['requested', 'rejected']],
        ['timestamp', '>=', dayjs().subtract(5, 'minute').toDate()],
      ],
      orderBy: [['timestamp', 'desc']],
      limit: 1,
    }),
  })

  const { state, request } = useMemo<ActiveRequestState | InactiveRequestState>(() => {
    if (!latestRequest) {
      return defaultRequestState
    }

    if (dayjs().diff(latestRequest.timestamp, 'minute', true) > 5) {
      handleNotAwaitedPromise(
        collections.budgets(budget._id).requests.update(latestRequest._id, { status: 'timedout' })
      )

      return defaultRequestState
    }

    if (latestRequest.status === 'requested') {
      if (latestRequest.to === currentUserId) {
        return { state: 'accept-request', request: latestRequest }
      }

      if (latestRequest.from === currentUserId) {
        return { state: 'waiting', request: latestRequest }
      }
    }

    if (latestRequest.status === 'rejected' && latestRequest.from === currentUserId) {
      return { state: 'rejected', request: latestRequest }
    }

    return defaultRequestState
  }, [currentUserId, latestRequest, budget._id])

  return (
    <>
      {request && (
        <>
          <RequesteeUserDialog
            open={state === 'accept-request'}
            userAvatar={{ userName: request.fromName, userRole: request.fromRole }}
            onConfirm={() => transferBudget({ budgetId: budget._id, requestId: request._id })}
            onCancel={() => collections.budgets(budget._id).requests.update(request._id, { status: 'rejected' })}
          />
          <RequestSentDialog
            open={state === 'waiting'}
            onCancel={() => collections.budgets(budget._id).requests.update(request._id, { status: 'cancelled' })}
          />
          <RequestDeclinedDialog
            open={state === 'rejected'}
            onClose={() =>
              collections.budgets(budget._id).requests.update(request._id, { status: 'rejection-confirmed' })
            }
          />
        </>
      )}
      {children}
    </>
  )
}

export const BudgetUserRequestsDialogProvider: React.FC<BudgetUserRequestsDialogProviderProps> = ({
  children,
  ...props
}) => {
  const { offline } = useOfflineState()

  return offline ? (
    children
  ) : (
    <BudgetUserRequestsDialogOnlineProvider {...props}>{children}</BudgetUserRequestsDialogOnlineProvider>
  )
}
