import { type FC, useState } from 'react'

import { Button } from '../../atoms/Button/Button'
import { Label } from '../../atoms/Label/Label'
import { Dialog, DialogContent, DialogDescription, DialogOverlay, DialogPortal, DialogTitle } from '../Dialog/Dialog'
import { UserAvatarWithName, type UserAvatarWithNameProps } from '../UserAvatarWithName/UserAvatarWithName'

export interface UserDialogProps {
  readonly open: boolean
  readonly confirmAction: () => Promise<void>
  readonly confirmLabel: string
  readonly cancelAction: () => Promise<void> | void
  readonly cancelLabel: string
  readonly title: string
  readonly subtitlePrefix: string
  readonly subtitleSuffix: string
  readonly userAvatar: UserAvatarWithNameProps
}

export const UserDialog: FC<UserDialogProps> = ({
  open,
  confirmAction,
  confirmLabel,
  cancelAction,
  cancelLabel,
  title,
  subtitlePrefix,
  subtitleSuffix,
  userAvatar,
}) => {
  const [cancelLoading, setCancelLoading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const cancel = async () => {
    setCancelLoading(true)
    try {
      await cancelAction()
    } finally {
      setCancelLoading(false)
    }
  }

  const confirm = async () => {
    setConfirmLoading(true)
    try {
      await confirmAction()
    } finally {
      setConfirmLoading(false)
    }
  }

  return (
    <Dialog open={open}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent>
          <div className="flex flex-col items-center gap-6 px-12 py-16">
            <div className="flex flex-col items-center justify-center gap-2">
              <DialogTitle asChild>
                <Label className="text-2xl font-bold">{title}</Label>
              </DialogTitle>
              <DialogDescription asChild>
                <div className="flex flex-col items-center gap-2">
                  <Label className="text-base font-light">{subtitlePrefix}</Label>
                  <UserAvatarWithName {...userAvatar} />
                  <Label className="text-base font-light">{subtitleSuffix}</Label>
                </div>
              </DialogDescription>
            </div>
            <div className="flex gap-4">
              <Button
                variant="outline"
                className="focus:outline-none"
                onClick={cancel}
                loading={cancelLoading}
                disabled={cancelLoading || confirmLoading}>
                {cancelLabel}
              </Button>
              <Button
                className="focus:outline-none"
                onClick={confirm}
                loading={confirmLoading}
                disabled={cancelLoading || confirmLoading}>
                {confirmLabel}
              </Button>
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}
