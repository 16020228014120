import { forwardRef } from 'react'

import { type YearKey } from '@via/schema'

import { cn } from '../../lib/utils'

import { MilkIncomeFormComputationProvider } from './MilkIncomeFormComputationProvider.tsx'
import { MilkIncomeFormDependenciesProvider } from './MilkIncomeFormDependenciesProvider.tsx'
import { MilkIncomeFormProvider, type MilkIncomeFormProviderProps } from './MilkIncomeFormProvider.tsx'
import { MilkIncomeFormTable } from './MilkIncomeFormTable.tsx'

export interface MilkIncomeFormProps extends MilkIncomeFormProviderProps {
  readonly year: YearKey
}

export const MilkIncomeForm = forwardRef<HTMLFormElement, MilkIncomeFormProps>(({ year, className, ...props }, ref) => (
  <MilkIncomeFormDependenciesProvider year={year}>
    <MilkIncomeFormProvider year={year} className={cn('w-[500px] bg-white', className)} ref={ref} {...props}>
      <MilkIncomeFormComputationProvider>
        <div className="h-screen overflow-y-auto">
          <main className="flex flex-col pb-24 pt-6">
            <MilkIncomeFormTable />
          </main>
        </div>
      </MilkIncomeFormComputationProvider>
    </MilkIncomeFormProvider>
  </MilkIncomeFormDependenciesProvider>
))
