import { type Breadcrumb, type BreadcrumbHint, type ErrorEvent, type EventHint, type Integration } from '@sentry/core'
import { init, makeBrowserOfflineTransport, makeFetchTransport } from '@sentry/react'
import { ErrorWithSentryCaptureContext } from '@via/frontend-sentry'
import { BigNumber } from 'bignumber.js'

export const deepBigNumberToString = (value: unknown, dept = 0): unknown => {
  switch (true) {
    case dept > 7:
      return value
    case value instanceof BigNumber:
      return value.toString()
    case Array.isArray(value):
      return value.map((v) => deepBigNumberToString(v, dept + 1))
    case value instanceof Object:
      return Object.fromEntries(Object.entries(value).map(([key, v]) => [key, deepBigNumberToString(v, dept + 1)]))
    default:
      return value
  }
}

const typeConsoleColor = (breadcrumb: Breadcrumb) => {
  switch (true) {
    case breadcrumb.type === 'error':
    case breadcrumb.level === 'error':
      return 'background-color: crimson; color: white'
    case breadcrumb.type === 'debug':
    case breadcrumb.level === 'debug':
      return 'background-color: silver; color: white'
    default:
      return 'background-color: grey; color: white'
  }
}

const categoryConsoleColor = (category?: string) => {
  switch (category) {
    case 'rxdb':
      return 'background-color: teal; color: white'
    case 'firestore':
      return 'background-color: purple; color: white'
    case 'compute':
      return 'background-color: olive; color: white'
    default:
      return 'color: blue'
  }
}

const style = 'border-radius: 0.5em; font-weight: bold; padding: 2px 0.5em;'

const breadcrumbToConsole = (breadcrumb: Breadcrumb, hint?: BreadcrumbHint) => {
  const consoleLevel = breadcrumb.level === 'debug' || breadcrumb.type === 'debug' ? 'debug' : ('info' as const)

  /* eslint-disable no-console */
  console[consoleLevel](
    `%cBreadcrumb%c %c${breadcrumb.category}`,
    `${style} ${typeConsoleColor(breadcrumb)}`,
    '',
    `${style} ${categoryConsoleColor(breadcrumb.category)}`,
    breadcrumb.message,
    deepBigNumberToString(breadcrumb.data) ?? hint?.data
  )
  if (hint) {
    console.groupCollapsed('Breadcrumb Data')
    console.info(breadcrumb)
    console.info('hint', hint)
    console.groupEnd()
  }
  /* eslint-enable no-console */
}

const getConsoleLevel = (event: ErrorEvent) => {
  switch (event.level) {
    case 'fatal':
    case 'error':
      return 'error'
    case 'warning':
      return 'warn'
    case 'log':
      return 'log'
    case 'info':
      return 'info'
    case 'debug':
      return 'debug'
    default:
      return 'log'
  }
}

function errorToConsole(event: ErrorEvent, hint?: EventHint) {
  /* eslint-disable no-console */
  console[getConsoleLevel(event)](
    `%cError%c ${event.level}`,
    `${style} background-color: crimson; color: white`,
    'color: red',
    event.message ?? hint?.originalException
  )
  console.groupCollapsed('Error Data')
  if (event.message && hint?.originalException) {
    console.error('exception', hint.originalException)
  }
  if (hint?.originalException instanceof Error && hint.originalException.cause) {
    console.error('cause', hint.originalException.cause)
  }
  if (event.tags) {
    console.info('tags', event.tags)
  }
  if (event.contexts) {
    console.info('contexts', event.contexts)
  }
  if (event.extra) {
    console.info('extra', event.extra)
  }
  console.groupEnd()
  /* eslint-enable no-console */
}

const processErrorWithSentryCaptureContext = (event: ErrorEvent, exception: unknown) => {
  if (exception instanceof ErrorWithSentryCaptureContext) {
    exception.beforeSend(event)
    if (exception.cause) {
      processErrorWithSentryCaptureContext(event, exception.cause)
    }
  }
}

export const initSentry = (integrations: Integration[] = []) => {
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    transport: makeBrowserOfflineTransport(makeFetchTransport),
    integrations,
    tracePropagationTargets: [
      'localhost',
      'via-budgitel-dev.cloudfunctions.net',
      'via-budgitel-staging.cloudfunctions.net',
      'via-budgitel-prod.cloudfunctions.net',
    ],
    normalizeDepth: 5,
    // Performance Monitoring
    tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
    // Session Replay
    replaysSessionSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
    replaysOnErrorSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
    beforeSend: (event, hint) => {
      processErrorWithSentryCaptureContext(event, hint.originalException)
      if (import.meta.env.MODE === 'development') {
        errorToConsole(event, hint)
      }
      return event
    },
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (import.meta.env.MODE === 'development') {
        breadcrumbToConsole(breadcrumb, hint)
      }
      if (breadcrumb.level === 'debug' && import.meta.env.MODE !== 'development') {
        return null
      }
      return breadcrumb
    },
  })
}
