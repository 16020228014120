import { type DataKey } from '../../data-key-zod'
import { type DataAttribute } from '../types'

export const FinanceAttributes = {
  'finance.assets.long.amortization.amount': {
    type: 'total',
    readonly: true,
    dependencies: [
      'finance.asset.other.agricultural.total.amortization',
      'finance.asset.machinery.total.amortization',
      'finance.asset.building.total.amortization',
      'finance.investment.machinery.financing.total.disbursement',
    ],
  },
} as const satisfies Partial<Record<DataKey, DataAttribute>>
