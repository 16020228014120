import { mapNonNullValues, type RowData } from '@via/compute'
import { type LoanComputationType } from '@via/schema'

import { overridableMonetaryCellData } from './cell'

export const loanMonetaryRowData = (
  {
    key,
    computationType,
    name,
    startDate,
    values,
    startingBalance,
    amount,
    interestRate,
    duration,
    paymentPlan: { amount: paymentPlanAmount, frequency, ...paymentPlan },
    investment,
    ...rowData
  }: RowData<'finance-loan' | 'finance-loan-investment'>,
  loanComputationType: LoanComputationType
) => ({
  key,
  computationType,
  label: name,
  values: mapNonNullValues(values, (v) => overridableMonetaryCellData(v[loanComputationType])),
  ...(investment ? { investment } : {}),
  loan: {
    ...rowData,
    ...(startDate ? { startDate: { value: startDate } } : {}),
    ...(startingBalance ? { startingBalance: overridableMonetaryCellData(startingBalance) } : {}),
    ...(amount ? { amount: overridableMonetaryCellData(amount) } : {}),
    ...(interestRate ? { interestRate: overridableMonetaryCellData(interestRate) } : {}),
    ...(duration ? { duration: overridableMonetaryCellData(duration) } : {}),
    paymentPlan: {
      ...(paymentPlanAmount ? { amount: overridableMonetaryCellData(paymentPlanAmount) } : {}),
      ...(frequency ? { frequency: { value: frequency } } : {}),
      ...paymentPlan,
    },
  },
})
