import React from 'react'

import { BudgetListContext } from '@via/components'

import { useLocalBudgetListSnapshotState } from '../../rxdb/localSnapshot/useLocalBudgetListSnapshotState.ts'

export type CurrentClientBudgetListProviderProps = React.PropsWithChildren<{ readonly clientId: string }>

export const OnlineCurrentClientBudgetListProvider: React.FC<CurrentClientBudgetListProviderProps> = ({
  children,
  clientId,
}) => {
  const budgets = useLocalBudgetListSnapshotState()
  const state = React.useMemo(
    () => ({
      ...budgets,
      data: budgets.data.filter((budget) => budget.clientId === clientId),
    }),
    [budgets, clientId]
  )

  return <BudgetListContext.Provider value={state}>{children}</BudgetListContext.Provider>
}
