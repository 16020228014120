import { mapNonNullValues, type RowData } from '@via/compute'
import { isEmpty } from 'lodash-es'

import { overridableMonetaryCellData } from './cell'

export const overridableMonetaryRowData = ({
  references,
  reference,
  values,
  total,
  ...rowData
}: RowData<'overridable'>) => ({
  references: mapNonNullValues(references, (value) => overridableMonetaryCellData(value)),
  values: mapNonNullValues(values, (cellData) => ({
    ...overridableMonetaryCellData(cellData),
    status: !isEmpty(cellData.override) ? ('overridden' as const) : null,
  })),
  ...(reference ? { reference: overridableMonetaryCellData(reference) } : {}),
  ...(total ? { reference: overridableMonetaryCellData(total) } : {}),
  ...rowData,
})
