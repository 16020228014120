import { type FC } from 'react'
import { useWatch } from 'react-hook-form'

import { type CurrentProductions } from '@via/compute'

import {
  ScenarioCreationForm,
  type ScenarioCreationFormProps,
} from '../../organisms/ScenarioCreationForm/ScenarioCreationForm.tsx'
import {
  ScenarioMainLayout,
  type ScenarioMainLayoutProps,
} from '../../organisms/ScenarioMainLayout/ScenarioMainLayout.tsx'

export interface ScenarioCreateViewLayoutProps
  extends Pick<
      ScenarioMainLayoutProps,
      | 'budget'
      | 'navigationMenuCollapsedState'
      | 'onNavigationMenuCollapse'
      | 'onCloseBudget'
      | 'onNavigationChange'
      | 'onRefreshScenarioReference'
      | 'onUpdateBudget'
    >,
    Pick<ScenarioCreationFormProps, 'canCreateCompleteScenario' | 'onConfirm' | 'onCancel'> {
  readonly currentProductions: CurrentProductions
  readonly offline?: boolean
  readonly newScenarioId?: string
}

export const ScenarioCreateViewLayout: FC<ScenarioCreateViewLayoutProps> = ({
  budget,
  offline,
  currentProductions,
  navigationMenuCollapsedState,
  onNavigationMenuCollapse,
  onCloseBudget,
  onNavigationChange,
  onRefreshScenarioReference,
  newScenarioId,
  onUpdateBudget,
  ...formProps
}) => {
  const name = useWatch({ name: 'name', defaultValue: '' })

  return (
    <ScenarioMainLayout
      currentScenarioNavigationId="create"
      budget={budget}
      offline={offline ?? false}
      navigationMenuCollapsedState={navigationMenuCollapsedState}
      onNavigationMenuCollapse={onNavigationMenuCollapse}
      onCloseBudget={onCloseBudget}
      onNavigationChange={onNavigationChange}
      onRefreshScenarioReference={onRefreshScenarioReference}
      onUpdateBudget={onUpdateBudget}
      loading={false}
      newScenarioName={name}
      newScenarioId={newScenarioId}>
      <div className="pl-32">
        <ScenarioCreationForm currentProductions={currentProductions} offline={offline} {...formProps} />
      </div>
    </ScenarioMainLayout>
  )
}
