import { useIntl } from 'react-intl'

import { uniq } from 'lodash-es'

import { Icons } from '../atoms'
import { SELECT_RESET_FILTER_OPTION } from '../atoms/Select/SelectResetOption'

interface UseBudgetListDataTableOptionsProps {
  years: number[]
  offline: boolean
}

export const useBudgetListDataTableOptions = ({ years, offline }: UseBudgetListDataTableOptionsProps) => {
  const intl = useIntl()

  const sanitizedYears = uniq(years).sort()
  const firstYearsFilterOptions = [
    SELECT_RESET_FILTER_OPTION(intl.formatMessage({ id: 'budgetList.filter.firstYearBudgeted' })),
    ...sanitizedYears.map((year) => ({
      value: String(year),
      text: String(year),
      selectedText: intl.formatMessage(
        {
          id: 'budgetList.filter.firstYearBudgeted.display',
        },
        {
          year: String(year),
        }
      ),
    })),
  ]
  const budgetOwnershipFilterOptions = [
    {
      value: 'mine',
      text: intl.formatMessage({ id: 'budgetList.filter.myBudgets' }),
    },
    {
      value: 'my-group',
      text: intl.formatMessage({ id: 'budgetList.filter.myGroupBudgets' }),
    },
  ]

  return {
    filterOptions: firstYearsFilterOptions,
    budgetOwnershipFilterOptions,
    actionOptions: (isBudgetReadonly?: boolean, isAvailableOffline?: boolean) =>
      [
        {
          disabled: offline,
          value: 'duplicate',
          text: intl.formatMessage({ id: 'clientList.action.duplicate' }),
          confirmLabel: intl.formatMessage({ id: 'budgetConfiguration.action.duplicate.confirmationDialog.confirm' }),
          cancelLabel: intl.formatMessage({ id: 'budgetConfiguration.action.duplicate.confirmationDialog.close' }),
          title: intl.formatMessage({ id: 'budgetConfiguration.action.duplicate.confirmationDialog.title' }),
          subtitle: intl.formatMessage({ id: 'budgetConfiguration.action.duplicate.confirmationDialog.subTitle' }),
        },
        {
          disabled: offline || isBudgetReadonly,
          value: 'archive',
          text: intl.formatMessage({ id: 'clientList.action.archive' }),
          confirmLabel: intl.formatMessage({ id: 'budgetConfiguration.action.erase.confirmationDialog.confirm' }),
          cancelLabel: intl.formatMessage({ id: 'budgetConfiguration.action.erase.confirmationDialog.close' }),
          title: intl.formatMessage({ id: 'budgetConfiguration.action.erase.confirmationDialog.title' }),
          subtitle: intl.formatMessage({ id: 'budgetConfiguration.action.erase.confirmationDialog.subTitle' }),
        },
        {
          disabled: offline || isBudgetReadonly,
          value: isAvailableOffline ? 'close' : 'open',
          text: (
            <div className="flex items-center gap-1.5">
              <p>{intl.formatMessage({ id: 'clientList.action.open' })}</p>
              {isAvailableOffline && <Icons.Check className="size-6" />}
            </div>
          ),
        },
      ] as const,
  }
}
