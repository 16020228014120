import { useContext, useMemo } from 'react'

import { isDataKey } from '@via/schema'

import { monetaryRowFactory } from '../../model'
import { ScenarioRowsContext, useScenario } from '../scenario'

import { type UseMonetaryRowDataOptions, type UseMonetaryRowDataResult } from './types.ts'

export const useMonetaryRowData = <Weight extends string = never>({
  id,
  disabled,
  computation,
  weight,
}: UseMonetaryRowDataOptions<Weight>): UseMonetaryRowDataResult => {
  const scenario = useScenario()
  const { rows, loading } = useContext(ScenarioRowsContext)
  return useMemo(() => {
    const dataKeys = (Array.isArray(id) ? id : [id]).filter((key) => isDataKey(key))
    return dataKeys.length === 0 || disabled
      ? { rows: [], loading }
      : {
          rows: monetaryRowFactory<Weight>(dataKeys, rows, scenario.blueprintVersion, { computation, weight }),
          loading,
        }
  }, [id, disabled, loading, rows, scenario.blueprintVersion, computation, weight])
}
