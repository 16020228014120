import { z } from 'zod'

import { AssetSubTypeZod, AssetTypeZod } from '../enums'

import { YearKeyZod } from './year-key-zod'

const AssetConfigRecord = <ZData extends z.ZodTypeAny>(data: ZData) =>
  z.record(AssetTypeZod, z.record(AssetSubTypeZod, data).optional().default({})).optional().default({})

const RateDataZod = AssetConfigRecord(z.object({ rate: z.string().optional() }))

export const BudgetReferenceConfigZod = z.object({
  contributory: z.record(YearKeyZod, RateDataZod).optional().default({}),
  amortization: z.record(YearKeyZod, RateDataZod).optional().default({}),
})
