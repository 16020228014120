import { type FC, useState } from 'react'
import { useIntl } from 'react-intl'

import { sortBy } from 'lodash-es'

import { Label } from '../../atoms/Label/Label'
import { Select } from '../../atoms/Select/Select'
import { type GroupData } from '../../types'

export interface GroupsInformationProps {
  readonly groups: GroupData[]
  readonly currentUserId: string
}

export const GroupsInformation: FC<GroupsInformationProps> = ({ groups, currentUserId }) => {
  const intl = useIntl()
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null)

  if (groups.length === 0) {
    return null
  }

  const groupOptions = sortBy(
    groups.map((group) => ({
      text: group.name,
      value: group._id,
    })),
    'text'
  )

  const selectedGroup = groups.find((group) => group._id === selectedGroupId)

  return (
    <>
      <Label className="pb-1">{intl.formatMessage({ id: 'groupsInformation.selectGroup.title' })}</Label>
      <Select
        className="max-w-[325px]"
        placeholder={intl.formatMessage({ id: 'groupsInformation.selectGroup.placeholder' })}
        value={selectedGroupId}
        options={groupOptions}
        onValueChange={setSelectedGroupId}
        useValueFromProps
      />
      {selectedGroup && (
        <div className="pt-5">
          <Label className="text-xl font-bold">{intl.formatMessage({ id: 'groupsInformation.address.title' })}</Label>
          <div className="pt-2">
            <Label className="text-base font-light">{selectedGroup.address}</Label>
            <div className="flex gap-1">
              <Label className="text-base font-light">{selectedGroup.city}</Label>
              <Label className="text-base font-light">({selectedGroup.province})</Label>
              <Label className="text-base font-light">{selectedGroup.postalCode}</Label>
            </div>
            <div className="flex gap-1">
              {selectedGroup.email && <Label className="text-base font-light underline">{selectedGroup.email}</Label>}
              {selectedGroup.telephone && (
                <Label className="text-base font-light">
                  {intl.formatMessage({ id: 'groupsInformation.phone.prefix' })} {selectedGroup.telephone}
                </Label>
              )}
              {selectedGroup.poste && <Label className="text-base font-light">{selectedGroup.poste}</Label>}
            </div>
            {selectedGroup.fax && (
              <Label className="text-base font-light">
                {intl.formatMessage({ id: 'groupsInformation.fax.prefix' })} {selectedGroup.fax}
              </Label>
            )}
            <div className="flex gap-1">
              <Label className="text-base font-bold">
                {intl.formatMessage({ id: 'groupsInformation.members.role' })}
              </Label>
              <Label className="text-base font-light">
                {intl.formatMessage({
                  id: `groupsInformation.members.role.${selectedGroup.users[currentUserId].role}`,
                })}
              </Label>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
