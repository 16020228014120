import {
  createRef,
  type ForwardedRef,
  forwardRef,
  type HtmlHTMLAttributes,
  type InputHTMLAttributes,
  useImperativeHandle,
} from 'react'
import { type UseFormReturn } from 'react-hook-form'

import { type FormatNumberOptions } from '@formatjs/intl'
import { type Unit } from '@via/schema'
import { omit } from 'lodash-es'

import { type LineVariant } from '../../atoms/LineTitle/LineVariant.ts'
import { TableNavigationProvider } from '../../context/table/TableNavigationProvider'
import { cn } from '../../lib/utils'

import {
  MonetaryYearFormFieldTableDividerRow,
  type MonetaryYearFormFieldTableDividerRowProps,
} from './rows/MonetaryYearFormFieldTableDividerRow.tsx'
import { MonetaryYearFormFieldTableEditableDataRow } from './rows/MonetaryYearFormFieldTableEditableDataRow.tsx'
import { MonetaryYearFormFieldTableGroupTitleRow } from './rows/MonetaryYearFormFieldTableGroupTitleRow.tsx'
import { MonetaryYearFormFieldTableReadonlyDataRow } from './rows/MonetaryYearFormFieldTableReaonlyDataRow.tsx'

export type MonetaryYearFormFieldTableBlueprintRow<RowId extends string> =
  | ({
      readonly id: RowId
      readonly type: 'data'
      readonly title: string
      readonly unit?: Unit
      readonly variant: Exclude<LineVariant, 'head' | 'divider'>
      readonly bottomBorder?: 'close'
      readonly formatNumberOptions?: FormatNumberOptions
      readonly onChange?: (value: string | number | undefined, form: UseFormReturn) => void
    } & Pick<InputHTMLAttributes<HTMLInputElement>, 'step' | 'min' | 'max'>)
  | {
      readonly id: RowId
      readonly type: 'readonly'
      readonly title: string
      readonly unit?: Unit
      readonly variant: Exclude<LineVariant, 'divider'>
      readonly bottomBorder?: 'close'
      readonly formatNumberOptions?: FormatNumberOptions
    }
  | {
      readonly id: string
      readonly type: 'title'
      readonly title: string
      readonly variant: Exclude<LineVariant, 'divider'>
    }
  | ({
      readonly id: string
      readonly type: 'divider'
    } & MonetaryYearFormFieldTableDividerRowProps)

export interface MonetaryYearFormFieldTableBlueprint<RowId extends string> {
  readonly sections: ReadonlyArray<{
    readonly id: string
    readonly rows: ReadonlyArray<MonetaryYearFormFieldTableBlueprintRow<RowId>>
  }>
}

export interface MonetaryYearFormFieldTableProps<RowId extends string> extends HtmlHTMLAttributes<HTMLDivElement> {
  readonly blueprint: MonetaryYearFormFieldTableBlueprint<RowId>
  readonly values?: Record<RowId, string | number>
}

export const MonetaryYearFormFieldTable = forwardRef(
  <RowId extends string>(
    { blueprint, values, className, ...props }: MonetaryYearFormFieldTableProps<RowId>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const tableRef = createRef<HTMLDivElement>()
    useImperativeHandle(ref, () => tableRef.current!, [tableRef])

    return (
      <div className={cn('flex flex-col gap-3', className)} {...props} ref={tableRef}>
        <TableNavigationProvider nbCellsPerRow={1} containerRef={tableRef}>
          {blueprint.sections.map((section) => (
            <section key={section.id}>
              {section.rows.map((row) => {
                switch (row.type) {
                  case 'divider':
                    return <MonetaryYearFormFieldTableDividerRow key={row.id} {...omit(row, 'type')} />
                  case 'title':
                    return <MonetaryYearFormFieldTableGroupTitleRow key={row.id} {...omit(row, 'type')} />
                  case 'data':
                    return (
                      <MonetaryYearFormFieldTableEditableDataRow
                        key={row.id}
                        rowId={row.id}
                        value={values?.[row.id]}
                        {...omit(row, 'type')}
                      />
                    )
                  case 'readonly':
                    return (
                      <MonetaryYearFormFieldTableReadonlyDataRow
                        key={row.id}
                        value={values?.[row.id]}
                        {...omit(row, 'type')}
                      />
                    )
                  default:
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    throw new Error(`Unknown row type ${row.type}`)
                }
              })}
            </section>
          ))}
        </TableNavigationProvider>
      </div>
    )
  }
)
