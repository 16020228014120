import { z } from 'zod'

import { UserRoleZod } from './user-role-zod'

export const ClientUserZod = z.object({
  name: z.string(),
  role: UserRoleZod,
})

export type ClientUser = z.infer<typeof ClientUserZod>
