import { keysToTuple, type MilkQuotaComputationType, MilkQuotaMonthStateZod } from '@via/schema'
import { BigNumber } from 'bignumber.js'
import dayjs from 'dayjs'
import type z from 'zod'

import { tupleToRecord } from '../../utils'

export type MonthlyMilkQuota = Record<MilkQuotaComputationType, BigNumber>

type MilkQuotaMonthState = z.input<typeof MilkQuotaMonthStateZod>

const bigNumberWithDefaultValue = (value: BigNumber.Value | undefined, fallBackValue: BigNumber.Value = 0) =>
  value ? new BigNumber(value) : new BigNumber(fallBackValue)

export const computeMonthlyMilkQuota = (
  state: MilkQuotaMonthState | undefined,
  monthKey: string,
  previous?: Partial<MonthlyMilkQuota>,
  actualProduction = new BigNumber(0), // kgMG/jr
  quotaCost = new BigNumber(0), // $/kgMG/jr
  quotaAssetValue = new BigNumber(24_000) // $/kgMG/jr
): MonthlyMilkQuota => {
  const {
    purchase, // kgMG/jr
    sale, // kgMG/jr
    adjustment, // %
    startUp, // kgMG/jr
    rental, // kgMG/jr
    rentalCost, // $/kgMG/jr
    loan, // %
    federationExtensionDays, // days
    federationExtensionUsage, // %
  } = tupleToRecord(keysToTuple(MilkQuotaMonthStateZod.shape), (type) => {
    switch (type) {
      case 'purchase':
      case 'sale':
        return bigNumberWithDefaultValue(state?.[type]).decimalPlaces(2, BigNumber.ROUND_UP)
      case 'startUp':
      case 'rental':
        return bigNumberWithDefaultValue(state?.[type], previous?.[type]).decimalPlaces(2, BigNumber.ROUND_UP)
      case 'rentalCost':
        return bigNumberWithDefaultValue(state?.[type], previous?.[type])
      case 'federationExtensionUsage':
        return bigNumberWithDefaultValue(state?.[type], 100)
      default:
        return bigNumberWithDefaultValue(state?.[type])
    }
  })

  const daysInMonth = dayjs(monthKey, 'YYYY-MM').daysInMonth()
  const startingBalance = previous?.balance ?? new BigNumber(0) // kgMG/jr
  const adjustmentAmount = startingBalance
    .plus(purchase)
    .minus(sale)
    .plus(startUp)
    .multipliedBy(adjustment.shiftedBy(-2))
    .decimalPlaces(2, BigNumber.ROUND_UP) // kgMG/jr
  const purchaseCost = purchase.times(quotaCost)
  const saleCost = sale.times(quotaCost).negated()
  const disbursement = purchaseCost.plus(saleCost)

  const movement = purchase.minus(sale).plus(adjustmentAmount)
  const balance = startingBalance.plus(movement)
  const assetValue = balance.times(quotaAssetValue)
  const assetValueMovement = movement.times(quotaAssetValue).minus(purchaseCost).plus(saleCost)
  const rentalTotalCost = rental.multipliedBy(rentalCost)
  const effective = balance.plus(startUp).plus(rental)
  const loanAmount = effective.multipliedBy(loan.shiftedBy(-2)).decimalPlaces(2, BigNumber.ROUND_UP) // kgMG/jr

  const federationExtensionAmount = effective
    .plus(loanAmount)
    .multipliedBy(federationExtensionDays.multipliedBy(federationExtensionUsage.shiftedBy(-2)))
  const federationExtension = federationExtensionAmount.dividedBy(daysInMonth).decimalPlaces(2, BigNumber.ROUND_UP) // kgMG/jr

  const allowedKilogram = effective
    .plus(loanAmount)
    .multipliedBy(daysInMonth)
    .plus(federationExtensionAmount)
    .decimalPlaces(0, BigNumber.ROUND_UP) // kgMG
  const allowedProduction = effective.plus(loanAmount).plus(federationExtension)

  const producedKg = actualProduction.multipliedBy(daysInMonth)
  const productionDiff = producedKg.minus(allowedKilogram)
  const productionDiffBalance = BigNumber.sum(previous?.toleranceBalance ?? 0, productionDiff).decimalPlaces(
    0,
    BigNumber.ROUND_UP
  ) // kgMG

  const toleranceMaximum = BigNumber.sum(
    allowedKilogram,
    effective
      .plus(loanAmount)
      .multipliedBy(BigNumber.sum(10, federationExtensionDays))
      .decimalPlaces(0, BigNumber.ROUND_UP) // kgMG
  )
  const toleranceMinimum = BigNumber.sum(allowedKilogram, effective.plus(loanAmount).multipliedBy(-15)).decimalPlaces(
    0,
    BigNumber.ROUND_DOWN
  ) // kgMG

  const toleranceExcess = productionDiffBalance.minus(BigNumber.min(productionDiffBalance, toleranceMaximum))
  const toleranceShortage = productionDiffBalance.minus(BigNumber.max(productionDiffBalance, toleranceMinimum))

  const toleranceBalance = BigNumber.min(BigNumber.max(productionDiffBalance, toleranceMinimum), toleranceMaximum)
  const toleranceMovement = toleranceBalance.minus(previous?.toleranceBalance ?? 0)

  return {
    startingBalance,
    purchase,
    purchaseCost,
    sale,
    saleCost,
    disbursement,
    adjustment,
    adjustmentAmount,
    movement,
    balance,
    assetValue,
    assetValueMovement,
    startUp,
    rental,
    rentalCost,
    rentalTotalCost,
    effective,
    loan,
    loanAmount,
    federationExtensionDays,
    federationExtensionUsage,
    federationExtension,
    allowedProduction,
    allowedKilogram,
    toleranceBalance,
    toleranceMovement,
    toleranceExcess,
    toleranceShortage,
  }
}
