import { type FC } from 'react'
import { useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { type YearKey } from '@via/schema'
import { pull, times } from 'lodash-es'

import { FormField } from '../../atoms/Form/FormField'
import { Select } from '../../atoms/Select/Select'
import { SELECT_RESET_FILTER_OPTION, SELECT_RESET_VALUE } from '../../atoms/Select/SelectResetOption'
import { CheckboxWithLabel } from '../../molecules/CheckboxWithLabel/CheckboxWithLabel'

import { type ReportFormData } from './ReportFormZod.ts'

export interface ReportConfigurationFormAnnexProps {
  readonly selectableYears: YearKey[]
  readonly scenarios: Array<{ id: string; name: string; creationTimestamp: Date }>
}

export const ReportConfigurationFormAnnex: FC<ReportConfigurationFormAnnexProps> = ({ scenarios, selectableYears }) => {
  const intl = useIntl()
  const years = useWatch<ReportFormData, 'years'>({ name: 'years' })
  const options = selectableYears.map((year) => ({ value: year, text: year }))

  return (
    <div id="annex" className="flex w-full max-w-screen-md flex-col justify-center gap-8 pt-16">
      <p className="text-2xl font-bold">{intl.formatMessage({ id: 'reportConfiguration.form.annex' })}</p>
      <div className="flex pl-4 font-bold">
        <FormField<ReportFormData, 'includesReference'>
          name="includesReference"
          render={({ field: { value, onChange, ...field } }) => (
            <CheckboxWithLabel
              label={intl.formatMessage({ id: 'reportConfiguration.form.printReference' })}
              onCheckedChange={onChange}
              checked={value}
              {...field}
            />
          )}
        />
      </div>
      <FormField<ReportFormData, 'scenarios'>
        name="scenarios"
        defaultValue={[]}
        render={({ field }) => (
          <div className="flex max-w-xs flex-col gap-2">
            {scenarios.map(({ id, name }, index) => (
              <CheckboxWithLabel
                key={id}
                checked={field.value.includes(id)}
                variant="outline"
                label={`${index + 1}. ${name}`}
                onCheckedChange={(checked) => {
                  const ids = checked ? [...field.value, id] : pull(field.value, id)
                  field.onChange(ids)
                }}
              />
            ))}
          </div>
        )}
      />
      <div className="flex flex-col gap-4">
        <div>
          <p className="text-xl font-bold">{intl.formatMessage({ id: 'reportConfiguration.form.years' })}</p>
          <p className="text-xs">{intl.formatMessage({ id: 'reportConfiguration.form.years.mandatory' })}</p>
        </div>
        <FormField<ReportFormData, 'years'>
          name="years"
          defaultValue={[]}
          render={({ field }) => (
            <div className="flex max-w-screen-md flex-row gap-3">
              {/* eslint-disable-next-line react/no-unstable-nested-components */}
              {times(Math.min(selectableYears.length, 6), (index) => (
                <Select<string>
                  key={`${index}-${field.value[index]}`}
                  options={[
                    SELECT_RESET_FILTER_OPTION('Aucun'),
                    ...options.filter(
                      (option) => !(years?.includes(option.value) && option.value !== field.value[index])
                    ),
                  ]}
                  placeholder={intl.formatMessage({ id: 'reportConfiguration.form.years.none' })}
                  onValueChange={(value) => {
                    if (value === SELECT_RESET_VALUE) {
                      field.onChange(pull(field.value, field.value[index]))
                    } else if (field.value[index]) {
                      field.onChange(
                        [...field.value.filter((previousValue) => previousValue !== field.value[index]), value].sort()
                      )
                    } else {
                      field.onChange([...field.value, value].sort())
                    }
                  }}
                  value={field.value[index]}
                  useValueFromProps
                />
              ))}
            </div>
          )}
        />
      </div>
    </div>
  )
}
