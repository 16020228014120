import { WithDefaultBudgetReportSectionZod, YearKeyZod } from '@via/schema'
import { z } from 'zod'

export const ReportFormZod = z.object({
  name: z.string().optional().default(''),
  contents: z
    .record(z.object({ order: z.number(), content: z.string() }))
    .optional()
    .default({}),
  preambles: z
    .record(z.object({ order: z.number(), content: z.string() }))
    .optional()
    .default({}),
  includesReference: z.boolean().optional().default(false),
  scenarios: z.array(z.string()).optional().default([]),
  years: YearKeyZod.array().optional().default([]),

  withCoverPage: z.boolean().optional().default(true),

  title: z.string().optional().default(''),
  displayDate: z.boolean().optional().default(false),
  date: z
    .string()
    .regex(/(^\d{4}-\d{2}-\d{2}$|^$)/)
    .optional()
    .default(''),

  displayTechnician: z.boolean().optional().default(false),
  displayCollaborator: z.boolean().optional().default(false),

  coverImagePath: z.string().optional().default(''),
  logoImagePath: z.string().optional().default(''),

  sections: z
    .object({
      results: WithDefaultBudgetReportSectionZod,
      repayment: WithDefaultBudgetReportSectionZod,
      balance: WithDefaultBudgetReportSectionZod,
      finance: WithDefaultBudgetReportSectionZod.and(
        z.object({
          investments: WithDefaultBudgetReportSectionZod,
          shortTerms: WithDefaultBudgetReportSectionZod.and(
            z
              .object({
                movement: WithDefaultBudgetReportSectionZod,
                balance: WithDefaultBudgetReportSectionZod,
              })
              .optional()
              .default({})
          ),
          loans: WithDefaultBudgetReportSectionZod.and(
            z
              .object({
                annuity: WithDefaultBudgetReportSectionZod,
                interest: WithDefaultBudgetReportSectionZod,
                capital: WithDefaultBudgetReportSectionZod,
                projectedCapital: WithDefaultBudgetReportSectionZod,
                endingBalance: WithDefaultBudgetReportSectionZod,
                disbursements: WithDefaultBudgetReportSectionZod,
                repayment: WithDefaultBudgetReportSectionZod,
                exigible: WithDefaultBudgetReportSectionZod,
              })
              .optional()
              .default({})
          ),
        })
      )
        .optional()
        .default({}),
    })
    .optional()
    .default({}),
})

export type ReportFormData = z.infer<typeof ReportFormZod>
