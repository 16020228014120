import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { dataLine } from '../utils'

export const shortTermManagement2024Blueprint = {
  sections: [
    {
      id: 'finance.shortTerm.plan',
      titleKey: 'table.finance.shortTerm.plan',
      children: [
        {
          ...dataLine('finance.short.liquidities', {
            variant: 'calculate',
          }),
          children: [
            dataLine('finance.short.assets.programs', { variant: 'group' }),
            dataLine('finance.short.assets.receivables', { variant: 'group' }),
            dataLine('finance.short.liabilities.credit', { variant: 'group' }),
            dataLine('finance.short.liabilities.payables', { variant: 'group', closingSection: true }),
          ],
        },
      ],
    },
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true, className: 'w-[21rem]', displayOnTableState: ['default'] },
    {
      id: 'title',
      type: 'title-with-selected-tab',
      sticky: true,
      className: 'w-[21rem]',
      displayOnTableState: ['print-with-reference', 'print-without-reference'],
    },
    {
      id: 'money',
      type: 'finance-short-term-amount',
      titleKey: 'table.finance.shortTerm.start',
      sticky: true,
      withDividerRight: true,
      className: 'w-24',
    },
    { id: 'divider', type: 'divider', headerSticky: true, className: 'w-1' },
    {
      id: 'projections',
      numberDisplayType: 'currency-movement',
      type: 'projections',
      headerSticky: true,
      withDividerRight: true,
      className: 'w-24',
      displayOnTableActiveTab: ['movement'],
    },
    {
      id: 'projections',
      type: 'projections',
      headerSticky: true,
      withDividerRight: true,
      className: 'w-24',
      displayOnTableActiveTab: ['balance'],
    },
  ],
  tabs: [
    { labelKey: 'finance.shortTerm.movement', value: 'movement' },
    { labelKey: 'finance.shortTerm.balance', value: 'balance' },
  ],
  defaultState: 'default',
  defaultTab: 'movement',
} as const satisfies Except<
  BlueprintTable<'default' | 'print-with-reference' | 'print-without-reference', 'movement' | 'balance'>,
  'version'
>
