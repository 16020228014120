import { createContext } from 'react'

import { type MilkExpensesFormSubmitData } from '../../organisms/MilkExpensesForm'
import { type MilkIncomeFormSubmitData } from '../../organisms/MilkIncomeForm'
import { type MilkQuotaFormSubmitData } from '../../organisms/MilkQuotaForm'

export interface MilkOperations {
  readonly onUpdateQuota: (value: MilkQuotaFormSubmitData) => Promise<void>
  readonly onUpdateIncome: (value: MilkIncomeFormSubmitData) => Promise<void>
  readonly onUpdateExpenses: (value: MilkExpensesFormSubmitData) => Promise<void>
}

export const MilkOperationsContext = createContext<MilkOperations>({
  onUpdateQuota: () => Promise.reject(new Error('MilkOperationsContext not provided')),
  onUpdateIncome: () => Promise.reject(new Error('MilkOperationsContext not provided')),
  onUpdateExpenses: () => Promise.reject(new Error('MilkOperationsContext not provided')),
})
