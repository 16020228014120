import { z } from 'zod'

import { ClientUserZod } from '../common'

export const GroupZod = z.object({
  name: z.string(),
  address: z.string(),
  city: z.string(),
  province: z.string(),
  postalCode: z.string(),
  telephone: z.string().nullable(),
  poste: z.string().nullable(),
  fax: z.string().nullable(),
  email: z.string().nullable(),
  code: z.string(),

  viagritelId: z.string(),

  users: z.record(ClientUserZod).optional().default({}),
  members: z.array(z.string()),
})
