import { type PropsWithChildren, useEffect, useState } from 'react'
import { type FieldValues, useFormContext, type WatchObserver } from 'react-hook-form'

import { FormComputationContext } from './FormComputationContext.ts'

export type FormComputation<TComputationState, TFieldValues extends FieldValues = FieldValues> = (
  ...args: Parameters<WatchObserver<TFieldValues>>
) => TComputationState

export interface FormComputationProviderProps<TComputationState, TFieldValues extends FieldValues = FieldValues> {
  readonly compute: FormComputation<TComputationState, TFieldValues>
  readonly initialState: TComputationState
}

export const FormComputationProvider = <TComputationState, TFieldValues extends FieldValues = FieldValues>({
  initialState,
  compute,
  children,
}: PropsWithChildren<FormComputationProviderProps<TComputationState, TFieldValues>>) => {
  const [computationState, setComputationState] = useState<TComputationState>(initialState)
  const { watch } = useFormContext<TFieldValues>()
  useEffect(() => {
    const { unsubscribe } = watch((...args) => {
      setComputationState(compute(...args))
    })
    return unsubscribe
  }, [compute, watch])
  return <FormComputationContext.Provider value={computationState}>{children}</FormComputationContext.Provider>
}
