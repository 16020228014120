import { useCallback } from 'react'

import { type Except } from 'type-fest'

import { type NavigationHandler } from '../../navigation'
import {
  BudgetListDataTable,
  type BudgetListDataTableProps,
} from '../../organisms/BudgetListDataTable/BudgetListDataTable'
import { MainLayout, type MainLayoutProps } from '../../organisms/MainLayout/MainLayout'

export interface BudgetListViewProps
  extends Pick<MainLayoutProps, 'version' | 'avatar' | 'offline'>,
    Except<BudgetListDataTableProps, 'onNavigateToBudget' | 'offline'> {
  readonly onNavigationChange: NavigationHandler
}

export const BudgetListView = ({
  applyFilter,
  avatar,
  version,
  data,
  offline,
  user,
  onNavigationChange,
  onActionSelect,
}: BudgetListViewProps) => {
  const onNavigateToBudget = useCallback(
    (budgetId: string) => onNavigationChange({ to: '/budgets/$budgetId', params: { budgetId } }),
    [onNavigationChange]
  )
  return (
    <MainLayout currentNavigationId="budgets" version={version} avatar={avatar} offline={offline}>
      <div className="flex w-full flex-col items-start px-10 py-8">
        <BudgetListDataTable
          data={data}
          offline={offline ?? false}
          user={user}
          applyFilter={applyFilter}
          onActionSelect={onActionSelect}
          onNavigateToBudget={onNavigateToBudget}
        />
      </div>
    </MainLayout>
  )
}
