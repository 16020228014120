import { useContext } from 'react'

import { ToastContext } from './ToastProvider'

export const useToast = () => {
  const toastState = useContext(ToastContext)
  if (!toastState) {
    throw new Error('useToast must be used within a ToastContext')
  }

  return toastState
}
