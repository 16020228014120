import { type FC } from 'react'

import { ActionDialog } from '../ActionDialog/ActionDialog'

interface LoadingDialogProps {
  readonly label: string
  readonly open: boolean
}

export const LoadingDialog: FC<LoadingDialogProps> = ({ label, open }) => (
  <ActionDialog open={open} title={label} loading />
)
