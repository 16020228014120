import { type FC } from 'react'

import { LineTitle } from '../../../atoms/LineTitle/LineTitle.tsx'
import { lineToCellVariant } from '../../../atoms/LineTitle/lineToCellVariant.ts'
import { type LineVariant } from '../../../atoms/LineTitle/LineVariant.ts'
import { LineCellWithUnit, type LineCellWithUnitProps } from '../../../molecules/LineCellWithUnit/LineCellWithUnit.tsx'

export interface MonetaryYearFormFieldTableReadonlyDataRowProps extends Omit<LineCellWithUnitProps, 'variant'> {
  readonly title: string
  readonly variant: Exclude<LineVariant, 'divider'>
}

export const MonetaryYearFormFieldTableReadonlyDataRow: FC<MonetaryYearFormFieldTableReadonlyDataRowProps> = ({
  title,
  value = 0,
  tabIndex = 0,
  withDividerRight = true,
  variant,
  ...props
}) => (
  <div className="flex shrink-0">
    <LineTitle label={title} className="w-72" variant={variant} />
    <LineCellWithUnit
      value={value}
      variant={lineToCellVariant(variant, 'readonlyValue')}
      className="w-32"
      tabIndex={tabIndex}
      withDividerRight={withDividerRight}
      {...props}
    />
  </div>
)
