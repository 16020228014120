import { type ScenarioLoanStateZod } from '@via/schema'
import { BigNumber } from 'bignumber.js'
import { mapValues } from 'lodash-es'
import { type z } from 'zod'

import { toBigNumberRecord } from '../../bignumber'

export type LoanBigNumberStateData = Pick<
  z.output<typeof ScenarioLoanStateZod>,
  'name' | 'note' | 'startDate' | 'category' | 'paymentFrequency'
> & {
  amount?: BigNumber
  interestRate?: BigNumber | null
  duration?: number
  paymentAmount?: BigNumber
  durationWithoutInterestPaid?: BigNumber
  durationWithoutCapitalPaid?: BigNumber
  fixedCapitalPaymentAmount?: BigNumber
  newCapitalBorrowed: Record<string, { date: string; value: BigNumber }>
  earlyRepayment: Record<string, { date: string; value: BigNumber; withdrawal?: BigNumber }>
  interestRateChange: Record<string, { date: string; value: BigNumber }>
}

export const loanBigNumberStateData = ({
  name,
  category,
  duration,
  paymentFrequency,
  startDate,
  note,
  durationWithoutInterestPaid,
  durationWithoutCapitalPaid,
  fixedCapitalPaymentAmount,
  newCapitalBorrowed,
  earlyRepayment,
  interestRateChange,
  ...data
}: z.output<typeof ScenarioLoanStateZod> = {}): LoanBigNumberStateData => ({
  paymentFrequency,
  category,
  ...toBigNumberRecord<'amount' | 'interestRate' | 'paymentAmount'>(data),
  ...(duration ? { duration: Number(duration) } : {}),
  ...(name ? { name } : {}),
  ...(note ? { note } : {}),
  ...(startDate ? { startDate } : {}),
  ...(durationWithoutInterestPaid ? { durationWithoutInterestPaid: new BigNumber(durationWithoutInterestPaid) } : {}),
  ...(durationWithoutCapitalPaid ? { durationWithoutCapitalPaid: new BigNumber(durationWithoutCapitalPaid) } : {}),
  ...(fixedCapitalPaymentAmount ? { fixedCapitalPaymentAmount: new BigNumber(fixedCapitalPaymentAmount) } : {}),
  newCapitalBorrowed: mapValues(newCapitalBorrowed, ({ date, value }) => ({ date, value: new BigNumber(value) })),
  earlyRepayment: mapValues(earlyRepayment, ({ date, value, withdrawal }) => ({
    date,
    value: new BigNumber(value),
    ...(withdrawal ? { withdrawal: new BigNumber(withdrawal) } : {}),
  })),
  interestRateChange: mapValues(interestRateChange, ({ date, value }) => ({ date, value: new BigNumber(value) })),
})
