import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import {
  type BudgetData,
  type BudgetListFilterState,
  type BudgetListViewProps,
  useBudgetList,
  useToast,
} from '@via/components'

import { useDuplicateBudget } from '../../api/useDuplicateBudget.ts'
import { useAuthenticatedFirebaseAuth } from '../../auth/useFirebaseAuth.ts'
import { useOfflineState } from '../../contexts/offlineState/useOfflineState.ts'
import { useAppNavigation } from '../../navigation/useAppNavigation.ts'
import { useCurrentUserAvatarProps } from '../../navigation/useCurrentUserAvatarProps.tsx'
import { useBudgetHandler } from '../../worker/budget/useBudgetHandler.ts'

export const useBudgetListPage = (): BudgetListViewProps => {
  const { user, userDocument } = useAuthenticatedFirebaseAuth()
  const navigation = useAppNavigation()
  const { offline } = useOfflineState()
  const avatar = useCurrentUserAvatarProps()
  const { data } = useBudgetList()
  const intl = useIntl()
  const { showToast } = useToast()
  const budgetHandler = useBudgetHandler()
  const duplicateBudget = useDuplicateBudget()

  const applyFilter = useCallback(
    (state: BudgetListFilterState, budgetData: BudgetData[]): BudgetData[] => {
      let filteredBudget = budgetData

      filteredBudget = Number.isNaN(Number(state.selectedYear))
        ? budgetData
        : budgetData.filter((budget) => String(budget.year) === state.selectedYear)

      if (state.selectedOwnership === 'mine') {
        filteredBudget = filteredBudget.filter(
          (budget) =>
            budget.users.agronomist?.id === user.uid ||
            budget.users.technician?.id === user.uid ||
            budget.users.collaborator?.id === user.uid
        )
      }

      if (state.status && state.status !== 'all') {
        filteredBudget = filteredBudget.filter((budget) => budget.status === state.status)
      }

      return filteredBudget
    },
    [user]
  )

  const onActionSelect = useCallback(
    async (budgetId: string, action: 'open' | 'close' | 'duplicate' | 'archive') => {
      switch (action) {
        case 'open':
          await budgetHandler.acquireBudget(budgetId)
          showToast(
            intl.formatMessage({ id: 'clientList.action.open.done.title' }),
            intl.formatMessage({ id: 'clientList.action.open.done.description' })
          )
          break
        case 'close':
          await budgetHandler.closeBudget(budgetId)
          showToast(
            intl.formatMessage({ id: 'clientList.action.close.done.title' }),
            intl.formatMessage({ id: 'clientList.action.close.done.description' })
          )
          break
        case 'archive':
          await budgetHandler.archiveBudget(budgetId)
          break
        case 'duplicate': {
          const budget = data.find((b) => b._id === budgetId)
          if (budget) {
            const budgetName = `${intl.formatMessage({ id: 'budgetConfiguration.action.duplicate.name-prefix' })} ${budget.name}`
            await duplicateBudget.mutateAsync({ budgetId, budgetName })
          }
          break
        }
        default:
          break
      }
    },
    [budgetHandler, showToast, intl, data, duplicateBudget]
  )

  return {
    ...navigation,
    offline,
    avatar,
    version: __APP_VERSION__,
    data,
    user: userDocument,
    applyFilter,
    onActionSelect,
  }
}
