import { useContext } from 'react'

import { OfflineStateContext } from './OfflineStateContext.ts'

export const useOfflineState = () => {
  const state = useContext(OfflineStateContext)
  if (!state) {
    throw new Error('useOfflineState must be used within a OfflineStateProvider')
  }
  return state
}
