import { type FC } from 'react'

import { captureException, ErrorBoundary } from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ComponentProvider } from '@via/components'

import { FirebaseAuthProvider } from './auth/FirebaseAuthProvider.tsx'
import { NetworkStateProvider } from './contexts/networkState/NetworkStateProvider.tsx'
import { OfflineStateProvider } from './contexts/offlineState/OfflineStateProvider.tsx'
import { PWAStateProvider } from './contexts/pwa/PwaStateProvider.tsx'
import { AppRxDatabaseProvider } from './rxdb/contexts/AppRxDatabaseProvider.tsx'
import { AppRouter } from './AppRouter.tsx'
import { initFirebase } from './firebase.ts'

initFirebase({ enableAppCheck: false })

const queryClient = new QueryClient()

export const App: FC = () => (
  <ErrorBoundary
    onError={(error, componentStack, eventId) => {
      captureException(error, { tags: { root: true }, contexts: { react: { componentStack, eventId } } })
    }}>
    <QueryClientProvider client={queryClient}>
      <ComponentProvider>
        <FirebaseAuthProvider>
          <PWAStateProvider>
            <NetworkStateProvider>
              <OfflineStateProvider>
                <AppRxDatabaseProvider>
                  <AppRouter />
                </AppRxDatabaseProvider>
              </OfflineStateProvider>
            </NetworkStateProvider>
          </PWAStateProvider>
        </FirebaseAuthProvider>
      </ComponentProvider>
    </QueryClientProvider>
  </ErrorBoundary>
)
