import React, { useCallback, useMemo, useState } from 'react'

import { type BudgetData } from '@via/components'
import { type CurrentProductions } from '@via/compute'

import { useGetCurrentProductions } from '../../api/useGetCurrentBudgetProductions.ts'

interface CurrentBudgetProductionsProviderValue {
  reSyncCurrentProductions: () => Promise<void>
  currentProductions: CurrentProductions
}
export const CurrentBudgetProductionsContext = React.createContext<CurrentBudgetProductionsProviderValue | null>(null)

export type CurrentBudgetProductionsProviderProps = React.PropsWithChildren<{
  readonly initialCurrentProductions: CurrentProductions
  readonly budget: BudgetData
}>

export const CurrentBudgetProductionsProvider: React.FC<CurrentBudgetProductionsProviderProps> = ({
  children,
  initialCurrentProductions,
  budget,
}) => {
  const { mutateAsync } = useGetCurrentProductions()
  const [currentProductions, setCurrentProductions] = useState(initialCurrentProductions)

  const reSyncCurrentProductions = useCallback(async () => {
    const newCurrentProductions = await mutateAsync({ budgetId: budget._id })
    setCurrentProductions(newCurrentProductions)
  }, [budget._id, mutateAsync])

  const value = useMemo(
    () => ({ currentProductions, reSyncCurrentProductions }),
    [currentProductions, reSyncCurrentProductions]
  )

  return <CurrentBudgetProductionsContext.Provider value={value}>{children}</CurrentBudgetProductionsContext.Provider>
}
