import { type PaymentFrequency } from '@via/schema'
import dayjs from 'dayjs'
import { floor } from 'lodash-es'

import { isInYearPredicate } from '../../date'

import { type LoanStateData } from './merge-loan-state'
import { PaymentFrequencyToAnnualEventCount } from './payment-frequency-to-annual-event-count'
import { type LoanStateEvent } from './prepare-loan-state-events'
import { type LoanComputationAction } from './reduce-loan-state'

export const computeNextDate = (
  currentDate: dayjs.ConfigType,
  eventDate: dayjs.ConfigType,
  paymentFrequency: PaymentFrequency = 'monthly'
): { nextDate: dayjs.ConfigType; numberOfPaymentPeriods: number } => {
  switch (paymentFrequency) {
    case 'monthly': {
      const diff = dayjs(eventDate).diff(currentDate, 'months')
      return {
        nextDate: dayjs(currentDate).add(diff, 'months').toDate(),
        numberOfPaymentPeriods: diff,
      }
    }
    case 'biannual': {
      const diff = floor(dayjs(eventDate).diff(currentDate, 'months') / 6)
      return {
        nextDate: dayjs(currentDate)
          .add(diff * 6, 'months')
          .toDate(),
        numberOfPaymentPeriods: diff,
      }
    }
    case 'quarterly': {
      const diff = floor(dayjs(eventDate).diff(currentDate, 'months') / 4)
      return {
        nextDate: dayjs(currentDate)
          .add(diff * 4, 'months')
          .toDate(),
        numberOfPaymentPeriods: diff,
      }
    }
    case 'bimonthly': {
      const diff = floor(dayjs(eventDate).diff(currentDate, 'months', true) * 2)
      return {
        nextDate: dayjs(currentDate)
          .add(diff / 2, 'months')
          .toDate(),
        numberOfPaymentPeriods: diff,
      }
    }
    case 'annual': {
      const diff = dayjs(eventDate).diff(currentDate, 'year')
      return {
        nextDate: dayjs(currentDate).add(diff, 'year').toDate(),
        numberOfPaymentPeriods: diff,
      }
    }
    case 'weekly': {
      const diff = dayjs(eventDate).diff(currentDate, 'weeks')
      return {
        nextDate: dayjs(currentDate).add(diff, 'weeks').toDate(),
        numberOfPaymentPeriods: diff,
      }
    }
    default:
      throw new Error(`Unsupported payment frequency`)
  }
}

export const prepareLoanStateActions = (
  year: string,
  stateData: LoanStateData,
  events: LoanStateEvent[],
  {
    startOfYearOffset = 0,
  }: {
    startOfYearOffset?: number
  }
): LoanComputationAction[] => {
  const numberOfPaymentEventInYear = PaymentFrequencyToAnnualEventCount[stateData.paymentFrequency]
  const startProjection = dayjs(year, 'YYYY').startOf('year').add(startOfYearOffset, 'months')
  const endOfProjection = dayjs(year, 'YYYY').endOf('year').add(startOfYearOffset, 'months')
  const isInCurrentYear = isInYearPredicate(year, startOfYearOffset)

  const produceYearActions = ({
    startDate,
    numberOfPeriods,
  }: {
    startDate: dayjs.ConfigType
    numberOfPeriods: number
  }): LoanComputationAction[] => {
    const result = events
      .filter((event) => isInCurrentYear(event.date))
      .reduce<{
        actions: LoanComputationAction[]
        currentDate: dayjs.ConfigType
        remainingPeriods: number
      }>(
        ({ actions, currentDate, remainingPeriods }, event) => {
          const { nextDate, numberOfPaymentPeriods } = computeNextDate(
            currentDate,
            event.date,
            stateData.paymentFrequency
          )
          return {
            actions: [
              ...actions,
              {
                type: 'compute-interest-and-capital',
                numberOfPeriods: numberOfPaymentPeriods,
              },
              event,
            ],
            currentDate: nextDate,
            remainingPeriods: remainingPeriods - numberOfPaymentPeriods,
          }
        },
        { actions: [], currentDate: startDate, remainingPeriods: numberOfPeriods }
      )
    return [
      ...result.actions,
      {
        type: 'compute-interest-and-capital',
        numberOfPeriods: result.remainingPeriods,
      },
    ]
  }

  if (stateData.startDate && isInCurrentYear(stateData.startDate)) {
    return produceYearActions({
      startDate: stateData.startDate,
      numberOfPeriods: floor(numberOfPaymentEventInYear * endOfProjection.diff(stateData.startDate, 'year', true)),
    })
  }
  return produceYearActions({ startDate: startProjection.toDate(), numberOfPeriods: numberOfPaymentEventInYear })
}
