import { z } from 'zod'

import { MonetaryValueZod } from './monetary-value-zod'
import { YearKeyZod } from './year-key-zod'

export const ScenarioInventoryYearStateZod = z.object({
  purchase: MonetaryValueZod.optional(),
  purchaseCost: MonetaryValueZod.optional(),
  sale: MonetaryValueZod.optional(),
  saleCost: MonetaryValueZod.optional(),
  note: z.string().optional(),
})

export const ScenarioInventoryStateZod = z.record(YearKeyZod, ScenarioInventoryYearStateZod)
