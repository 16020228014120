import { DataAttributes } from '../attributes'
import { type ComputationType, type ComputationTypeDataKey } from '../computation-type-data-key'
import { type DataAttribute } from '../types'

export const isComputationTypeDataKey = <Computation extends ComputationType>(
  key: string,
  ...types: Computation[]
): key is ComputationTypeDataKey<Computation> => {
  const keyType = (DataAttributes as Record<string, DataAttribute>)[key]?.type
  return !!keyType && (types as ComputationType[]).includes(keyType)
}
