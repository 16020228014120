import type React from 'react'
import { useMemo } from 'react'

import { useObservableState } from 'observable-hooks'
import { map } from 'rxjs'

import { appRxState } from '../../rxdb/app/appRxState.ts'
import { useNetworkState } from '../networkState/useNetworkState.ts'

import { OfflineStateContext } from './OfflineStateContext.ts'

const forceOffline$ = appRxState.get$('forceOffline').pipe(map((a?: boolean) => a ?? false))

export const OfflineStateProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const forceOffline = useObservableState<boolean>(forceOffline$, false)
  const networkState = useNetworkState()
  const value = useMemo(
    () =>
      forceOffline
        ? ({
            forceOffline: true,
            offline: true,
          } as const)
        : ({
            forceOffline: false,
            offline: !networkState.online,
          } as const),
    [forceOffline, networkState]
  )
  return <OfflineStateContext.Provider value={value}>{children}</OfflineStateContext.Provider>
}
