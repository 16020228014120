import React from 'react'
import { useIntl } from 'react-intl'

import { type MessageDescriptor } from '@formatjs/intl'
import { isString } from 'lodash-es'

import { cn } from '../../lib/utils'

import { useFormField } from './useFormField'

export const FormMessage = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, children, ...props }, ref) => {
    const intl = useIntl()
    const { error, formMessageId } = useFormField()

    if (!error?.message) {
      return null
    }

    const body = isString(error.message)
      ? error.message.startsWith('form.')
        ? intl.formatMessage({ id: error.message as MessageDescriptor['id'] })
        : error.message
      : children

    return (
      <p
        ref={ref}
        id={formMessageId}
        className={cn('select-none text-xs font-medium text-red-500', className)}
        {...props}>
        {body}
      </p>
    )
  }
)
