import { BigNumber } from 'bignumber.js'

import { createBudgetDefinition, type DatabaseResolverFnFicheTechnique } from '../types'
import { isFermeBudgitel2021, isFermeBudgitel2022, sumBy } from '../utils'
import { type ViagritelProduction, type ViagritelTypeLigneFichesTechnique } from '../values'

const sum = (
  fichesTechniques: DatabaseResolverFnFicheTechnique,
  typeLigne: ViagritelTypeLigneFichesTechnique,
  lignes: number[]
) =>
  sumBy(
    fichesTechniques({
      type_ligne: typeLigne,
      ligne: lignes,
    }),
    'valeur'
  )

export const bilan = createBudgetDefinition({
  'finance.asset.inventory.imported.crops.amount': {
    testResolver: 'ZZ144', // Inventaire céréales fin
    databaseResolver: {
      resolve: ({ cerealesFourrages }) => sumBy(cerealesFourrages({ type_production: 'CE' }), 'valeur_totale_fin'),
    },
  },
  'finance.asset.inventory.imported.other-vegetal.amount': {
    testResolver: 'ZZ147', // Inventaire maraîchers fin
    databaseResolver: {
      resolve: ({ maraicher }) => sumBy(maraicher({ type_production: 'MA' }), 'fin_dollars'),
    },
  },
  'finance.asset.inventory.imported.cereals.amount': {
    testResolver: ({ cri }) =>
      cri('ZZ144') // Inventaire céréales fin
        .plus(cri('ZZ147')), // Inventaire maraîchers fin
    dependencyResolver: {
      dependencies: [
        'finance.asset.inventory.imported.crops.amount',
        'finance.asset.inventory.imported.other-vegetal.amount',
      ],
      resolve: ({ val }) =>
        BigNumber.sum(
          val('finance.asset.inventory.imported.crops.amount'),
          val('finance.asset.inventory.imported.other-vegetal.amount')
        ),
    },
  },
  'finance.asset.inventory.imported.forage.amount': {
    testResolver: ({ cri }) =>
      cri('ZZ140') // Inventaire fourrages fin
        .plus(cri('ZZ78')), // Inv. paille fin ($)
    databaseResolver: {
      resolve: ({ cerealesFourrages }) =>
        sumBy(cerealesFourrages({ type_production: ['FO', 'PL'] }), 'valeur_totale_fin'),
    },
  },
  'finance.asset.inventory.imported.miscellaneous.amount': {
    testResolver: ({ cri }) =>
      cri('DA48') // Inv. sirop fin d'année ($)
        .plus(cri('IS49')) // Inv. fin bois sciage ($)
        .plus(cri('IS51')) // Inv. fin bois pulpe ($)
        .plus(cri('IS53')) // Inv. fin bois chauffage ($)
        .plus(cri('T1261')) // Inventaire fin de produits fabriqués, Autr. act. comm.
        .plus(cri('T2261')) // Inventaire fin de produits fabriqués, Fromagerie
        .plus(cri('T3261')) // Inventaire fin de produits fabriqués, Agro-tourisme
        .plus(cri('T4261')) // Inventaire fin de produits fabriqués, Carrière
        .plus(cri('T5261')) // Inventaire fin de produits fabriqués, Déneigement
        .plus(cri('T6261')) // Inventaire fin de produits fabriqués, Boulangerie
        .plus(cri('T7261')) // Inventaire fin de produits fabriqués, Boucherie
        .plus(cri('T8261')), // Inventaire fin de produits fabriqués, Miel
    databaseResolver: {
      resolve: ({ fichesTechniques }) => {
        const valueSiropInventaire = sum(fichesTechniques, 'DA', [1700, 2755]) // Valeur du sirop en inventaire fin d'année DA48 (les deux lignes)
        const valueBoisInventaire = sum(fichesTechniques, 'IS', [
          31_000, // Inv. fin bois sciage    IS49
          31_200, // Inv. fin bois pulpe     IS51
          31_400, // Inv. fin bois chauffage IS53
        ])
        const valueProduitsFabriques = sum(fichesTechniques, 'TX', [120_100]) // Inventaire fin de produits fabriqués T?261

        return valueSiropInventaire.plus(valueBoisInventaire).plus(valueProduitsFabriques)
      },
    },
  },
  'finance.asset.inventory.imported.slaughter.amount': {
    testResolver: 'ZZ81', // Animaux abattage total fin
    databaseResolver: {
      resolve: ({ animaux }) =>
        sumBy(
          animaux({
            production: [
              'AX', // Exotique
              'BI', // Bouvillons
              'BS', // Bisons
              'CR', // Chèvres
              'DG', // Dindons de gril
              'DL', // Dindons lourds
              'FI', // Porcs
              'IN', // Ovins
              'JV', // Veaux de grain
              'LP', // Lapins
              'NA', // Porcelets
              'OT', // Ratites
              'PG', // Poulets de gril
              'PS', // Pisciculture
              'ST', // Cerfs
              'VL', // Veaux de lait
              'VA', // Vache-veau
              'WA', // Wapitis
            ] as ViagritelProduction[],
            type: 'market',
          }),
          'valeur_totale_fin'
        ),
    },
  },
  'finance.asset.inventory.imported.supplies.amount': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(118_465.5),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(24_482),
      },
    ],
    databaseResolver: {
      resolve: ({ aliments, inventairesApprovisionnements }) => {
        const alimentsInfo = aliments({}).reduce(
          (acc, aliment) => {
            const alimentFermeAcheteId = String(aliment.aliment_ferme_achetes_id)
            if (!acc[alimentFermeAcheteId]) {
              // When resolving aliments, the same data gets duplicated in each production. Here, we want the dollar
              // amount for each aliment, regardless of the production.
              acc[alimentFermeAcheteId] = aliment.fin_kg.dividedBy(1000).times(aliment.fin_dollars_ton)
            }
            return acc
          },
          {} as Record<string, BigNumber>
        )
        const sumAliments = Object.values(alimentsInfo).reduce((acc, value) => acc.plus(value), new BigNumber(0))

        const sumManyTypes = sumBy(
          inventairesApprovisionnements({
            type_ligne: [
              'CE', // Céréales
              'CJ', // Centre-Jardin
              'DA', // Érablière
              'FO', // Fourrages
              'GD', // Culture non-détaillée
              'GP', // Machinerie
              'MA', // Maraîcher
              'PO', // Pépinière-Ornementale
              'SO', // Serre-Ornementale
              'TX', // Productions commerciales
            ],
          }),
          'fin_dollars'
        )
        const sumProductionsAnimales = sumBy(
          inventairesApprovisionnements({
            production: [
              'AX', // Exotique
              'BI', // Bouvillons
              'BS', // Bisons
              'CR', // Chèvres
              'CV', // Chevaux
              'DG', // Dindons de gril
              'DL', // Dindons lourds
              'FI', // Porcs
              'IN', // Ovins
              'JV', // Veaux de grain
              'LA', // Lait
              'LP', // Lapins
              'NA', // Porcelets
              'OC', // Oeufs consommation
              'OI', // Oeufs incubation
              'OT', // Ratites
              'PG', // Poulets de gril
              'PS', // Pisciculture
              'ST', // Cerfs
              'TA', // Taures d'élevage
              'VA', // Vache-veau
              'VL', // Veaux de lait
              'WA', // Wapitis
            ],
          }),
          'fin_dollars'
        )

        return sumAliments.plus(sumManyTypes).plus(sumProductionsAnimales)
      },
    },
  },
})
