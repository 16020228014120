import { type DocumentData } from 'firebase/firestore'
import { NEVER, of, skipWhile, switchMap } from 'rxjs'

import { type AppRxDatabase, type SnapshotRxDocument } from '../types.ts'
import { rxLocalDocumentData } from '../utils/rxLocalDocumentData.ts'

export const querySnapshotLocalDocumentData$ = <T = DocumentData>(
  database: AppRxDatabase,
  snapshot: 'budgets' | 'clients'
) =>
  database.snapshots.getLocal$<SnapshotRxDocument<T>>(snapshot).pipe(
    switchMap((doc) => (doc ? of(rxLocalDocumentData(doc)) : NEVER)),
    skipWhile((querySnapshot) => querySnapshot.isLoading)
  )
