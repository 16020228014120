import { type FC, type PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { computeMonthlyMilkQuota } from '@via/compute'
import { MilkQuotaComputationTypeZod, type YearKey } from '@via/schema'
import { BigNumber } from 'bignumber.js'
import dayjs from 'dayjs'
import { forEach, sum, times } from 'lodash-es'
import { type Except } from 'type-fest'

import { useScenario } from '../../context'
import { useMonetaryRowsProjectionCellData } from '../../context/monetary/useMonetaryRowsProjectionCellData.ts'

import {
  initialState,
  MilkQuotaFormComputationContext,
  type MilkQuotaFormComputationState,
} from './MilkQuotaFormComputationContext.tsx'
import { type MilkQuotaFormData } from './MilkQuotaFormZod.ts'
import { useMilkPreviousValues } from './useMilkPreviousValues.ts'

export interface MilkQuotaFormComputationProviderProps {
  readonly year: YearKey
}

type FormMonthlyState = Except<
  MilkQuotaFormComputationState,
  'kilogramOfFatPerHectoliter' | 'actualProductionInHectoliters' | 'actualProduction' | 'quotaPrice'
>

export const MilkQuotaFormComputationProvider: FC<PropsWithChildren<MilkQuotaFormComputationProviderProps>> = ({
  year,
  children,
}) => {
  const previousValues = useMilkPreviousValues(year)
  const { startOfYearOffset } = useScenario()
  const { kilogramOfFatPerHectoliter, actualProductionInHectoliters, actualProduction, quotaPrice } =
    useMonetaryRowsProjectionCellData({
      year,
      keys: {
        kilogramOfFatPerHectoliter: 'milk.production.content.kilogram-per-hectoliter.fat',
        actualProductionInHectoliters: 'milk.production.sale.hectoliter',
        actualProduction: 'milk.production.sale.kilogram-of-fat-per-day',
        quotaPrice: 'milk.quota.asset.cost',
      },
    })

  const [computationState, setComputationState] = useState<FormMonthlyState>(initialState)

  const { watch } = useFormContext<MilkQuotaFormData>()
  useEffect(() => {
    const startDateDayjs = dayjs(year, 'YYYY')
      .startOf('year')
      .add(startOfYearOffset ?? 0, 'months')

    const { unsubscribe } = watch((formState) => {
      const { state } = times(12, (i) => startDateDayjs.add(i, 'months').format('YYYY-MM')).reduce(
        (acc, monthKey) => {
          const monthValues = computeMonthlyMilkQuota(
            formState.quota?.[monthKey],
            monthKey,
            acc.previous,
            new BigNumber(actualProduction?.value ?? 0),
            new BigNumber(quotaPrice?.value ?? 0)
          )
          forEach(MilkQuotaComputationTypeZod.options, (type) => {
            acc.state.values[type][monthKey] = monthValues[type].toNumber()
          })
          acc.state.endBalance = monthValues.balance.toNumber()
          acc.previous = monthValues
          return acc
        },
        {
          state: { ...initialState } as FormMonthlyState,
          previous: previousValues,
        }
      )
      const allowedKilogram = sum(Object.values(state.values.allowedKilogram))
      state.allowedVolume = kilogramOfFatPerHectoliter?.value ? allowedKilogram / kilogramOfFatPerHectoliter.value : 0
      state.allowedProduction = allowedKilogram / 365.25
      setComputationState(state)
    })
    return unsubscribe
  }, [
    year,
    startOfYearOffset,
    watch,
    actualProduction?.value,
    kilogramOfFatPerHectoliter?.value,
    previousValues,
    quotaPrice?.value,
  ])

  const contextValue = useMemo(
    () => ({
      ...computationState,
      kilogramOfFatPerHectoliter: kilogramOfFatPerHectoliter?.value ?? 0,
      actualProductionInHectoliters: actualProductionInHectoliters?.value ?? 0,
      actualProduction: actualProduction?.value ?? 0,
      quotaPrice: quotaPrice?.value ?? 0,
    }),
    [
      computationState,
      kilogramOfFatPerHectoliter?.value,
      actualProductionInHectoliters?.value,
      actualProduction?.value,
      quotaPrice?.value,
    ]
  )
  return (
    <MilkQuotaFormComputationContext.Provider value={contextValue}>{children}</MilkQuotaFormComputationContext.Provider>
  )
}
