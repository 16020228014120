import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { dataLine, headerLine } from '../utils'

export const loan2024Blueprint = {
  sections: [
    {
      id: 'finance.loan',
      titleKey: 'table.finance.loan',
      children: [
        {
          ...headerLine('table.finance.loan.agricultural.imported.header', { symbol: 'none' }),
          children: [
            dataLine('finance.loan.agricultural.medium.imported'),
            dataLine('finance.loan.agricultural.long.imported'),
          ],
        },
        dataLine('finance.loan.agricultural.imported.total', { variant: 'total', closingSection: true }),
        {
          ...headerLine('table.finance.loan.shareholders-due.header', {
            symbol: 'none',
            addMonetaryItemButtonType: 'loan.shareholders-due',
          }),
          children: [dataLine('finance.loan.shareholders-due')],
        },
        dataLine('finance.loan.shareholders-due.total', { variant: 'total', closingSection: true }),
        {
          ...headerLine('table.finance.loan.other.header', { symbol: 'none', addMonetaryItemButtonType: 'loan.other' }),
          children: [dataLine('finance.loan.other')],
        },
        dataLine('finance.loan.other.total', { variant: 'total', closingSection: true }),
        {
          ...headerLine('table.finance.loan.agricultural.new.header', {
            symbol: 'none',
            addMonetaryItemButtonType: 'loan.agricultural',
          }),
          children: [dataLine('finance.loan.agricultural.new')],
        },
        dataLine('finance.loan.agricultural.new.total', { variant: 'total', closingSection: true }),
        dataLine('finance.loan.total', { variant: 'finalTotal', symbol: 'none' }),
      ],
    },
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true, className: 'w-60', displayOnTableState: ['default'] },
    {
      id: 'title',
      type: 'title-with-selected-tab',
      sticky: true,
      className: 'w-60',
      displayOnTableState: ['print-with-reference', 'print-without-reference'],
    },
    {
      id: 'loan',
      type: 'finance-loan-starting-balance',
      titleKey: 'table.finance.loan.startingBalance',
      sticky: true,
      className: 'w-24',
    },
    {
      id: 'interest',
      type: 'finance-loan-interest',
      titleKey: `table.finance.loan.interestRate`,
      numberDisplayType: 'interest',
      sticky: true,
      className: 'w-12',
      topHeaderDimension: 'xxs',
    },
    {
      id: 'remaining',
      type: 'finance-loan-duration',
      titleKey: 'table.finance.loan.duration',
      valueMeasureUnitKey: 'duration.month',
      sticky: true,
      withDividerRight: true,
      className: 'w-12',
      topHeaderDimension: 'xxs',
    },
    { id: 'divider', type: 'divider', headerSticky: true, className: 'w-1' },
    {
      id: 'projections',
      type: 'projections',
      headerSticky: true,
      withDividerRight: true,
      className: 'w-24',
    },
  ],
  tabs: [
    { labelKey: 'table.finance.loan.computation.disbursements', value: 'disbursements' },
    { labelKey: 'table.finance.loan.computation.repayment', value: 'repayment' },
    { labelKey: 'table.finance.loan.computation.annuity', value: 'annuity' },
    { labelKey: 'table.finance.loan.computation.interest', value: 'interest' },
    { labelKey: 'table.finance.loan.computation.capital', value: 'capital' },
    { labelKey: 'table.finance.loan.computation.projected-capital', value: 'projectedCapital' },
    { labelKey: 'table.finance.loan.computation.exigible', value: 'exigible' },
    { labelKey: 'table.finance.loan.computation.endingBalance', value: 'endingBalance' },
  ],
  defaultState: 'default',
  defaultTab: 'annuity',
} as const satisfies Except<
  BlueprintTable<
    'default' | 'print-with-reference' | 'print-without-reference',
    | 'disbursements'
    | 'repayment'
    | 'annuity'
    | 'interest'
    | 'capital'
    | 'projectedCapital'
    | 'endingBalance'
    | 'exigible'
  >,
  'version'
>
