import { type FC, useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { LineTitle } from '../../../atoms/LineTitle/LineTitle.tsx'
import { stringIsMessageId } from '../../../l10n'
import { cn } from '../../../lib/utils.ts'
import { AddMonetaryItemButton } from '../../AddMonetaryItem/AddMonetaryItemButton.tsx'
import {
  MonetaryTableCollapseSetterContext,
  MonetaryTableCollapseStateContext,
} from '../contexts/MonetaryTableCollapseProvider.tsx'
import { MonetaryTableBlueprintContext } from '../contexts/MonetaryTableStateProvider.tsx'
import { useMonetaryTablePrintState } from '../contexts/useMonetaryTablePrintState.ts'

import { type MonetaryTableCellProps } from './MonetaryTableCellProps.ts'

export const MonetaryTableTitleCell: FC<MonetaryTableCellProps> = ({ column, line, rowData }) => {
  const printing = useMonetaryTablePrintState()
  const blueprint = useContext(MonetaryTableBlueprintContext)
  const { setOpen } = useContext(MonetaryTableCollapseSetterContext)
  const { isOpen } = useContext(MonetaryTableCollapseStateContext)

  const intl = useIntl()

  const label = useMemo(
    () =>
      rowData.label && stringIsMessageId(rowData.label)
        ? intl.formatMessage({ id: rowData.label })
        : (rowData.label ?? line.name),
    [intl, line.name, rowData.label]
  )

  const onCollapse = useCallback(
    (value: boolean) => {
      setOpen(line.id, value)
    },
    [line.id, setOpen]
  )

  return (
    <LineTitle
      variant={line.variant}
      symbol={line.symbol}
      label={label}
      open={isOpen(line.id)}
      withDividerRight={column.withDividerRight}
      closingSection={line.closingSection}
      bottomBorder={line.bottomBorder}
      onCollapse={onCollapse}
      className={cn('w-84', column.className, line.className)}>
      {line.addMonetaryItemButtonType && !printing && (
        <AddMonetaryItemButton blueprintVersion={blueprint.version} monetaryItemType={line.addMonetaryItemButtonType} />
      )}
    </LineTitle>
  )
}
