import { type DataKey } from '../../data-key-zod'
import { type DataAttribute } from '../types'

export const LoanAttributes = {
  'finance.loan.agricultural.medium.imported': {
    type: 'finance-loan',
    readonly: false,
    multipleValues: true,
  },
  'finance.loan.agricultural.medium.imported.total': {
    type: 'finance-loan-total',
    readonly: true,
    dependencies: ['finance.loan.agricultural.medium.imported'],
    referenceDependencies: {
      remainingDueAmount: ['finance.loan.agricultural.medium.imported.total.remaining-capital'],
      exigibleCapital: ['finance.loan.agricultural.medium.imported.total.exigible-capital'],
    },
  },

  'finance.loan.agricultural.medium.new': {
    type: 'finance-loan',
    readonly: false,
    multipleValues: true,
  },
  'finance.loan.agricultural.medium.new.total': {
    type: 'finance-loan-total',
    readonly: true,
    dependencies: ['finance.loan.agricultural.medium.new'],
  },
  'finance.loan.agricultural.medium.total': {
    type: 'finance-loan-total',
    readonly: true,
    dependencies: ['finance.loan.agricultural.medium.imported.total', 'finance.loan.agricultural.medium.new.total'],
  },
  'finance.loan.agricultural.medium.total.remaining-capital': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.agricultural.medium.total',
    value: 'remainingDueAmount',
  },
  'finance.loan.agricultural.medium.total.exigible-capital': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.agricultural.medium.total',
    value: 'exigibleCapital',
  },

  'finance.loan.agricultural.long.imported': {
    type: 'finance-loan',
    readonly: false,
    multipleValues: true,
  },
  'finance.loan.agricultural.long.imported.total': {
    type: 'finance-loan-total',
    readonly: true,
    dependencies: ['finance.loan.agricultural.long.imported'],
    referenceDependencies: {
      remainingDueAmount: ['finance.loan.agricultural.long.imported.total.remaining-capital'],
      exigibleCapital: ['finance.loan.agricultural.long.imported.total.exigible-capital'],
    },
  },
  'finance.loan.agricultural.long.new': {
    type: 'finance-loan',
    readonly: false,
    multipleValues: true,
  },
  'finance.loan.agricultural.long.new.total': {
    type: 'finance-loan-total',
    readonly: true,
    dependencies: ['finance.loan.agricultural.long.new'],
  },
  'finance.loan.agricultural.long.total': {
    type: 'finance-loan-total',
    readonly: true,
    dependencies: ['finance.loan.agricultural.long.imported.total', 'finance.loan.agricultural.long.new.total'],
  },
  'finance.loan.agricultural.long.total.remaining-capital': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.agricultural.long.total',
    value: 'remainingDueAmount',
  },
  'finance.loan.agricultural.long.total.exigible-capital': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.agricultural.long.total',
    value: 'exigibleCapital',
  },

  'finance.loan.investment.financing': {
    type: 'finance-loan-investment',
    readonly: false,
    multipleValues: true,
  },
  'finance.loan.agricultural.new': {
    type: 'aggregate',
    readonly: false,
    dependencies: [
      'finance.loan.agricultural.medium.new',
      'finance.loan.agricultural.long.new',
      'finance.loan.investment.financing',
    ],
    multipleValues: true,
    sortKey: 'loan.startDate',
    sortDirection: 'asc',
  },
  'finance.loan.agricultural.new.total': {
    type: 'finance-loan-total',
    readonly: true,
    dependencies: [
      'finance.loan.agricultural.medium.new',
      'finance.loan.agricultural.long.new',
      'finance.loan.investment.financing',
    ],
  },

  'finance.loan.agricultural.imported.total': {
    type: 'finance-loan-total',
    readonly: true,
    dependencies: ['finance.loan.agricultural.medium.imported.total', 'finance.loan.agricultural.long.imported.total'],
    referenceDependencies: {
      projectedCapital: ['finance.loan.agricultural.imported.total.projected-capital'],
      capital: ['finance.loan.agricultural.imported.total.capital'],
      interest: ['finance.loan.agricultural.imported.total.interest'],
      newCapitalBorrowed: ['finance.loan.agricultural.imported.total.new-capital'],
    },
  },

  'finance.loan.agricultural.total': {
    type: 'finance-loan-total',
    readonly: true,
    dependencies: ['finance.loan.agricultural.new.total', 'finance.loan.agricultural.imported.total'],
  },
  'finance.loan.agricultural.total.new-capital': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.agricultural.total',
    value: 'newCapitalBorrowed',
  },
  'finance.loan.agricultural.total.early-repayment': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.agricultural.total',
    value: 'earlyRepayment',
  },
  'finance.loan.agricultural.total.projected-capital': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.agricultural.total',
    value: 'projectedCapital',
    total: true,
  },
  'finance.loan.agricultural.total.interest': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.agricultural.total',
    value: 'interest',
    total: true,
    reference: 'selected',
  },
  'finance.loan.agricultural.interest.paid': {
    type: 'total',
    readonly: true,
    dependencies: ['finance.loan.agricultural.total.interest', 'finance.loan.interests.grants'],
    arithmetic: { 'finance.loan.interests.grants': { multiplier: -1 } },
  },

  'finance.loan.other': {
    type: 'finance-loan',
    readonly: false,
    multipleValues: true,
  },
  'finance.loan.other.total': {
    type: 'finance-loan-total',
    readonly: true,
    dependencies: ['finance.loan.other'],
    referenceDependencies: {
      projectedCapital: ['finance.loan.other.imported.total.projected-capital'],
      capital: ['finance.loan.other.imported.total.capital'],
      interest: ['finance.loan.other.imported.total.interest'],
      newCapitalBorrowed: ['finance.loan.other.imported.total.new-capital'],
    },
  },

  'finance.loan.shareholders-due': {
    type: 'finance-loan',
    readonly: false,
    multipleValues: true,
  },
  'finance.loan.shareholders-due.total': {
    type: 'finance-loan-total',
    readonly: true,
    dependencies: ['finance.loan.shareholders-due'],
    referenceDependencies: {
      projectedCapital: ['finance.loan.shareholders-due.imported.total.projected-capital'],
      capital: ['finance.loan.shareholders-due.imported.total.capital'],
      interest: ['finance.loan.shareholders-due.imported.total.interest'],
      newCapitalBorrowed: ['finance.loan.shareholders-due.imported.total.new-capital'],
    },
  },

  /** Repayment */

  'finance.loan.other.total.annuity': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.other.total',
    value: 'annuity',
  },
  'finance.loan.other.total.new-capital': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.other.total',
    value: 'newCapitalBorrowed',
  },
  'finance.loan.shareholders-due.total.projected-capital': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.shareholders-due.total',
    value: 'projectedCapital',
  },
  'finance.loan.shareholders-due.total.liquidity-movement': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.shareholders-due.total',
    value: 'liquidityMovement',
  },
  'finance.loan.outflows.withdrawals': {
    type: 'total',
    readonly: true,
    dependencies: ['finance.loan.shareholders-due.total.projected-capital', 'finance.loan.other.total.annuity'],
  },
  'finance.loan.inflows.contributions': {
    type: 'total',
    readonly: true,
    dependencies: [
      'finance.loan.shareholders-due.total.liquidity-movement',
      'finance.loan.shareholders-due.total.projected-capital',
      'finance.loan.other.total.new-capital',
    ],
  },

  /**  Total */

  'finance.loan.total': {
    type: 'finance-loan-total',
    readonly: true,
    dependencies: [
      'finance.loan.agricultural.total',
      'finance.loan.other.total',
      'finance.loan.shareholders-due.total',
    ],
  },
  'finance.loan.total.new-capital': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.total',
    value: 'newCapitalBorrowed',
    total: true,
  },
  'finance.loan.total.liquidity-movement': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.total',
    value: 'liquidityMovement',
    total: true,
  },
  'finance.loan.total.annuity': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.total',
    value: 'annuity',
    total: true,
  },
  'finance.loan.total.early-repayment': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.total',
    value: 'earlyRepayment',
    total: true,
  },
  'finance.loan.total.interest': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.total',
    value: 'interest',
    total: true,
    reference: 'selected',
  },
  'finance.loan.total.exigible-capital': {
    type: 'composite',
    readonly: true,
    source: 'finance.loan.total',
    value: 'exigibleCapital',
    total: true,
  },
} as const satisfies Partial<Record<DataKey, DataAttribute>>
