import { z } from 'zod'

import { AssetSubTypeZod, AssetTypeZod } from '../enums'

import { YearKeyZod } from './year-key-zod'

export const ImportedAssetDataZod = z.object({
  value: z.string().optional().default('0'),
  amortization: z.string().optional().default('0'),
  contributoryStartValue: z.string().optional().default('0'),
  contributoryValue: z.string().optional().default('0'),
  addedValue: z.string().optional().default('0'),
  fullySoldRemovedValue: z.string().optional().default('0'),
  removedValue: z.string().optional().default('0'),
  startingValue: z.string().optional().default('0'),
  subsidies: z.string().optional().default('0'),
  inflation: z.string().optional().default('0'),
  startingUnitCount: z.string().optional(),
  unitCount: z.string().optional(),
  valuePerUnit: z.string().optional(),
  isAssetSoldDuringTheYear: z.boolean().optional().default(false),
})

export const ImportedAssetZod = z.object({
  id: z.string(),
  label: z.string(),
  type: AssetTypeZod,
  subType: AssetSubTypeZod,
  data: z.record(YearKeyZod, ImportedAssetDataZod),
})
