import React from 'react'
import { useIntl } from 'react-intl'

import dayjs from 'dayjs'
import { type Except } from 'type-fest'

import { Input, type InputProps } from '../../atoms/Input/Input'
import { cn } from '../../lib/utils'

export interface DateInputProps extends Except<InputProps, 'type' | 'value'> {
  readonly value?: string
}

export const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  ({ id, className, disabled, value, ...props }, ref) => {
    const { formatDate } = useIntl()
    return disabled ? (
      <Input
        id={id}
        ref={ref}
        disabled
        className={cn('', className)}
        {...props}
        value={value && formatDate(dayjs(value, 'YYYY-MM-DD').toDate())}
      />
    ) : (
      <div className="relative">
        <Input id={id} ref={ref} value={value} type="date" max="9999-12-31" className={cn('', className)} {...props} />
      </div>
    )
  }
)
