import { type FC, type PropsWithChildren, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'

import { Button } from '../../atoms/Button/Button.tsx'
import { Label } from '../../atoms/Label/Label.tsx'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from '../Dialog/Dialog.tsx'

export interface BudgetReferenceUpdateDialogProps {
  readonly open: boolean
  readonly onCancel?: () => Promise<void> | void
  readonly onConfirm: () => Promise<void>
}

export const BudgetReferenceUpdateDialog: FC<PropsWithChildren<BudgetReferenceUpdateDialogProps>> = ({
  open,
  onCancel,
  onConfirm,
  children,
}) => {
  const intl = useIntl()

  const [cancelLoading, setCancelLoading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const cancel = useCallback(async () => {
    setCancelLoading(true)
    try {
      await onCancel?.()
    } finally {
      setCancelLoading(false)
    }
  }, [onCancel, setCancelLoading])

  const confirm = useCallback(async () => {
    setConfirmLoading(true)
    try {
      await onConfirm()
    } finally {
      setConfirmLoading(false)
    }
  }, [onConfirm, setConfirmLoading])

  return (
    <Dialog open={open}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          className="overflow-auto"
          onEscapeKeyDown={async (event) => {
            await cancel()
            event.preventDefault()
          }}>
          <div className="flex flex-col items-center gap-6 px-12 py-16">
            <div className="flex flex-col items-center justify-center gap-2">
              <DialogTitle asChild>
                <Label className="text-center text-2xl font-semibold">
                  {intl.formatMessage({ id: 'budget.referenceUpdate.budgetReferenceUpdateDialog.title' })}
                </Label>
              </DialogTitle>
              <DialogDescription asChild>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-1.5 text-center text-base font-bold leading-4">
                    <p>{intl.formatMessage({ id: 'budget.referenceUpdate.budgetReferenceUpdateDialog.subtitle1' })}</p>
                    <p>{intl.formatMessage({ id: 'budget.referenceUpdate.budgetReferenceUpdateDialog.subtitle2' })}</p>
                  </div>
                  <div className="flex w-full flex-col items-start gap-2 pb-4 pt-6 text-base font-light leading-4">
                    <div className="pb-2">
                      {intl.formatMessage({ id: 'budget.referenceUpdate.budgetReferenceUpdateDialog.detailsMessage' })}
                    </div>
                    {children}
                  </div>
                </div>
              </DialogDescription>
            </div>
            <div className="flex gap-4">
              <Button
                variant="outline"
                className="focus:outline-none"
                onClick={cancel}
                loading={cancelLoading}
                disabled={cancelLoading || confirmLoading}>
                {intl.formatMessage({ id: 'budget.referenceUpdate.budgetReferenceUpdateDialog.close' })}
              </Button>
              <Button
                className="focus:outline-none"
                onClick={confirm}
                loading={confirmLoading}
                disabled={cancelLoading || confirmLoading}>
                {intl.formatMessage({ id: 'budget.referenceUpdate.budgetReferenceUpdateDialog.confirm' })}
              </Button>
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}
