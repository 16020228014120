import { z } from 'zod'

import { UserRoleZod } from '../../../common'

export const BudgetReportFilesZod = z.object({
  archived: z.boolean(),
  createdBy: z.object({
    userName: z.string(),
    userRole: UserRoleZod,
    userId: z.string(),
  }),
  fileName: z.string(),
  fileUrl: z.string().optional(),
  scenarioStates: z.record(z.string()),
  status: z.enum(['created', 'error', 'generating', 'ready']),
  type: z.enum(['draft', 'final']).optional().default('draft'),
  timestamp: z.date(),
})
