import { type FieldPathByValue, type FieldValues } from 'react-hook-form'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { SwitchWithLabel, type SwitchWithLabelProps } from '../SwitchWithLabel/SwitchWithLabel'

export interface SwitchFormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, boolean>,
> extends Omit<SwitchWithLabelProps, 'value' | 'onChange'> {
  readonly name: TName
  readonly readOnly?: boolean
}

export const SwitchFormField = <
  TFieldValues extends FieldValues = never,
  TName extends FieldPathByValue<TFieldValues, boolean> = never,
>({
  name,
  className,
  disabled,
  readOnly,
  onClick,
  onKeyDown,
  ...props
}: SwitchFormFieldProps<TFieldValues, TName>) => (
  <FormField<TFieldValues, TName>
    name={name}
    disabled={disabled}
    render={({ field }) => (
      <FormItem className={className}>
        <FormControl>
          <SwitchWithLabel
            {...props}
            disabled={field.disabled || readOnly}
            onCheckedChange={field.onChange}
            checked={field.value}
          />
        </FormControl>
        <FormMessage />
      </FormItem>
    )}
  />
)
