import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react'
import { z } from 'zod'

import Brand from '../../assets/images/Brand.png'
import Login from '../../assets/images/Login.jpg'
import { Button } from '../../atoms/Button/Button'
import { Form } from '../../atoms/Form/Form'
import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { InputWithLabel } from '../../molecules/InputWithLabel/InputWithLabel'

export interface LoginViewProps {
  onConnectClick(username: string, password: string): Promise<void>
}

export const LoginView = ({ onConnectClick }: LoginViewProps) => {
  const [loading, setLoading] = React.useState(false)

  const intl = useIntl()

  const formSchema = z.object({
    username: z.string().min(1, { message: intl.formatMessage({ id: 'form.required' }) }),
    password: z.string().min(1, { message: intl.formatMessage({ id: 'form.required' }) }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setLoading(true)
      await onConnectClick(values.username, values.password)
    } catch (error) {
      if (error instanceof Error && error.message === 'Authentication failed') {
        captureException(error, { level: 'info' })
        form.setError('password', { message: intl.formatMessage({ id: 'login.invalidCredentials' }) })
      } else {
        throw error
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex h-screen w-full items-center justify-center bg-sage-50">
          <div className="flex h-[832px] shrink items-start overflow-hidden rounded-[20px] bg-white">
            <div className="flex h-full w-[640px] flex-col items-center justify-center">
              <div className="flex flex-col items-start gap-10">
                <img src={Brand} alt="Brand" className="pointer-events-none select-none" />

                <div className="flex flex-col items-center justify-center gap-8">
                  <FormField
                    name="username"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <InputWithLabel
                            label={intl.formatMessage({ id: 'login.username' })}
                            containerClassName="w-80"
                            autoComplete="off"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <InputWithLabel
                            type="password"
                            label={intl.formatMessage({ id: 'login.password' })}
                            containerClassName="w-80"
                            autoComplete="off"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <Button type="submit" loading={loading} disabled={!form.formState.isValid || loading}>
                    {intl.formatMessage({ id: 'login.connection' })}
                  </Button>
                </div>
              </div>
            </div>

            <img src={Login} alt="Login" className="pointer-events-none h-full w-[640px] select-none" />
          </div>
        </div>
      </form>
    </Form>
  )
}
