import { z } from 'zod'

import { UserRoleZod } from '../../common'

export const BudgetRequestZod = z.object({
  type: z.literal('ownership'),
  from: z.string(), // userId
  fromName: z.string(),
  fromRole: UserRoleZod,
  to: z.string(), // userId
  toName: z.string(),
  toRole: UserRoleZod,
  status: z.enum(['requested', 'rejected', 'rejection-confirmed', 'accepted', 'cancelled', 'timedout']),
  timestamp: z.date(),
})
