import { type FormHTMLAttributes, forwardRef, useCallback } from 'react'

import { captureMessage } from '@sentry/react'
import { type Except } from 'type-fest'
import { type z } from 'zod'

import { type ZodFormProviderProps, ZodFormSubmitProvider } from '../atoms/Form/ZodFormSubmitProvider'
import { type OverridableCellUpdateValue, type OverridableMonetaryCellData } from '../model'

import { type MonetaryCellFormData, MonetaryCellFormZod } from './MonetaryCellFormZod.ts'

export interface MonetaryWidgetFormSubmitProviderProps
  extends Except<
      ZodFormProviderProps<typeof MonetaryCellFormZod>,
      'schema' | 'onFormSubmit' | 'onFormApply' | 'values' | 'defaultValues'
    >,
    FormHTMLAttributes<HTMLFormElement> {
  readonly value?: OverridableMonetaryCellData
  readonly onConfirm: (data: OverridableCellUpdateValue) => Promise<void>
  readonly onApply: (data: OverridableCellUpdateValue) => Promise<void>
}

const fromForm = ({ override, note, growth }: MonetaryCellFormData): OverridableCellUpdateValue => {
  const growthOverride = growth ? { growth } : {}

  const [overrideValue, negativeSymbol = '', , integer = '0', decimals] =
    /^(-)?((\d*)(?:[,.](\d*))?)$/.exec(override.value ?? '') ?? []
  if (!overrideValue) {
    return { note, override: growthOverride }
  }
  const value = decimals
    ? `${negativeSymbol}${Number(integer)}.${decimals}`
    : String(Number(`${negativeSymbol}${integer}`))

  if (!Number.isFinite(Number(value))) {
    captureMessage('MonetaryWidgetForm Invalid value', {
      extra: {
        value,
        overrideValue,
        negativeSymbol,
        integer,
        decimals,
      },
    })
    return { note, override: growthOverride }
  }

  switch (true) {
    case !override.value:
      return { note, override: growthOverride }
    case override.operator === '+' && value !== '0':
      return { note, override: { add: value, ...growthOverride } }
    case override.operator === '-' && value !== '0':
      return { note, override: { subtract: value, ...growthOverride } }
    case override.operator === '=':
      return { note, override: { value, ...growthOverride } }
    default:
      return { note, override: growthOverride }
  }
}

const toForm = ({ note = '', override = {} }: OverridableMonetaryCellData): z.input<typeof MonetaryCellFormZod> => {
  const growth = override?.growth ?? ''
  switch (true) {
    case override?.value !== undefined:
      return { note, growth, override: { operator: '=', value: String(override.value) } }
    case override?.add !== undefined:
      return { note, growth, override: { operator: '+', value: String(override.add) } }
    case override?.subtract !== undefined:
      return { note, growth, override: { operator: '-', value: String(override.subtract) } }
    default:
      return { note, override: { operator: '', value: '' }, growth }
  }
}

export const MonetaryWidgetFormSubmitProvider = forwardRef<HTMLFormElement, MonetaryWidgetFormSubmitProviderProps>(
  ({ children, value, onConfirm, onApply, ...props }, ref) => {
    const onFormSubmit = useCallback(
      async (values: MonetaryCellFormData) => {
        await onConfirm(fromForm(values))
      },
      [onConfirm]
    )

    const onFormApply = useCallback(
      async (values: MonetaryCellFormData) => {
        await onApply(fromForm(values))
      },
      [onApply]
    )

    return (
      <ZodFormSubmitProvider<typeof MonetaryCellFormZod>
        {...props}
        onFormSubmit={onFormSubmit}
        onFormApply={onFormApply}
        defaultValues={MonetaryCellFormZod.parse(value ? toForm(value) : {})}
        autoComplete="off"
        schema={MonetaryCellFormZod}
        ref={ref}>
        {children}
      </ZodFormSubmitProvider>
    )
  }
)
