import { type InventoryDataKey, type YearKey } from '@via/schema'
import { type Except } from 'type-fest'

import { type MonetaryRowData } from '../../model'
import { InventoryProjectionWidget } from '../../monetary/InventoryMovementProjectionWidget/InventoryProjectionWidget.tsx'
import { type InventoryProjectionUpdateValue } from '../../monetary/InventoryMovementProjectionWidget/InventoryProjectionWidgetFormProvider.tsx'

import { PopoverEditableValueCell, type PopoverEditableValueCellProps } from './PopoverEditableValueCell'

export type UpdateInventoryCellValue = (
  data: { readonly type: 'inventory' } & InventoryProjectionUpdateValue
) => Promise<void> | void

export interface EditableInventoryProjectionCellProps extends Except<PopoverEditableValueCellProps, 'children'> {
  readonly rowData: MonetaryRowData
  readonly year: YearKey
  readonly onUpdateCell: UpdateInventoryCellValue
  readonly disabled?: boolean
}
export const EditableInventoryProjectionCell = ({
  rowData,
  cellData,
  year,
  onUpdateCell,
  className,
  disabled,
  ...props
}: EditableInventoryProjectionCellProps) => (
  <PopoverEditableValueCell cellData={cellData} className={className} {...props}>
    {({ onClose }) => (
      <InventoryProjectionWidget
        year={year}
        dataKey={rowData.key as InventoryDataKey}
        disabled={disabled}
        onConfirm={async (value) => {
          await onUpdateCell({ type: 'inventory', ...value })
          onClose()
        }}
        onApply={async (value) => {
          await onUpdateCell({ type: 'inventory', ...value })
        }}
      />
    )}
  </PopoverEditableValueCell>
)
