import { type FC, type HtmlHTMLAttributes, useState } from 'react'
import { useIntl } from 'react-intl'

import { Icons } from '../../atoms'
import { Button } from '../../atoms/Button/Button'
import { Sheet, SheetContent, SheetTrigger } from '../../atoms/Sheet/Sheet'
import { Tooltip } from '../../atoms/Tooltip/Tooltip'
import { cn } from '../../lib/utils'
import { BudgetNotes } from '../BudgetNotes/BudgetNotes'

export interface ReportsActionBarProps extends HtmlHTMLAttributes<HTMLDivElement> {
  readonly disabled?: boolean
}

export const ReportsActionBar: FC<ReportsActionBarProps> = ({ className, disabled = false, ...props }) => {
  const intl = useIntl()
  const [opened, setOpened] = useState<boolean>(false)

  return (
    <div className={cn('relative flex w-full shrink items-center', className)} {...props}>
      <div className="flex w-full select-none items-center justify-between">
        <div className="flex items-center justify-end gap-2 px-4 py-2">
          <Sheet
            open={opened}
            onOpenChange={(open) => {
              if (!disabled) {
                setOpened(open)
              }
            }}>
            <SheetTrigger asChild>
              <div>
                <Tooltip label={intl.formatMessage({ id: 'reportAction.notes.tooltip' })}>
                  <Button variant="outline" dimension="xs" shape="withIcon" disabled={disabled}>
                    <Icons.TextIndentLeft />
                    {intl.formatMessage({ id: 'reportAction.notes' })}
                  </Button>
                </Tooltip>
              </div>
            </SheetTrigger>
            <SheetContent side="right" className="w-fit p-0">
              {opened && (
                <BudgetNotes
                  onClose={() => {
                    setOpened(false)
                  }}
                />
              )}
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </div>
  )
}
