import { z } from 'zod'

export const ParentProductions = ['milk', 'crops', 'calf', 'maple', 'animal', 'vegetal', 'commercial-group'] as const
export const ParentProductionsZod = z.enum(ParentProductions)
export type ParentProductionsType = z.infer<typeof ParentProductionsZod>

export const AnimalProductions = [
  'milk-veal',
  'goat',
  'horse',
  'heavy-turkey',
  'rabbit',
  'weaner',
  'table-egg',
  'ostrich',
  'aquaculture',
  'exotic',
  'replacement-heifer',
  'finished-beef',
  'bison',
  'wapiti',
  'roast-turkey',
  'pork',
  'sheep',
  'grain-veal',
  'incubation-egg',
  'roast-poultry',
  'stag',
] as const

export const AnimalProductionsZod = z.enum(AnimalProductions)
export type AnimalProductionType = z.infer<typeof AnimalProductionsZod>

export const VegetalProductions = [
  'all',
  'asparagus',
  'tobacco',
  'thyme',
  'peat',
  'blueberry',
  'brocoli',
  'beet',
  'zucchini',
  'greenhouse-cucumber',
  'camomille',
  'mushroom',
  'celery',
  'pickle',
  'kale',
  'spinach',
  'fruit',
  'raspberry',
  'strawberry',
  'cannery-bean',
  'cannery-corn',
  'table-potato',
  'cannery-pea',
  'nursery',
  'parsnip',
  'leek',
  'garlic',
  'field-tomato',
  'greenhouse-tomato',
  'other',
  'table-carrot',
  'pumpkin',
  'cauliflower',
  'cabbage',
  'cantaloup',
  'ornamental',
  'winter-cabbage',
  'brussel-sprout',
  'shallots',
  'fava-bean',
  'fresh-bean',
  'vegetable',
  'lettuce',
  'watermelon',
  'sweet-corn',
  'turnip',
  'onion',
  'seed-potato',
  'medicinal',
  'apple',
  'pepper',
  'radish',
  'fir',
] as const
export const VegetalProductionsZod = z.enum(VegetalProductions)
export type VegetalProductionsType = z.infer<typeof VegetalProductionsZod>

export const CommercialProductions = [
  'cheese',
  'quarry',
  'bakery',
  'honey',
  'wood',
  'commercial',
  'agro-tourism',
  'snow-clearing',
  'butchery',
  'garden',
  'nursery',
  'greenhouse',
] as const

export const CommercialProductionsZod = z.enum(CommercialProductions)
export type CommercialProductionsType = z.infer<typeof CommercialProductionsZod>

export const Productions = [
  'milk',
  'crops',
  'calf',
  'maple',
  ...AnimalProductions,
  ...VegetalProductions,
  ...CommercialProductions,
] as const

export const ProductionZod = z.enum(Productions)
export type ProductionType = z.infer<typeof ProductionZod>
