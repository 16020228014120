import { firstValueFrom, map } from 'rxjs'

import { type FirestoreClientDocument } from '../../firestore/snapshot/FirestoreClientSnaphotProvider.tsx'
import { type AppRxDatabase } from '../types.ts'

import { querySnapshotLocalDocumentData$ } from './querySnapshotLocalDocumentData.ts'

export const findClient = (database: AppRxDatabase, predicate: (client: FirestoreClientDocument) => boolean) =>
  firstValueFrom(
    querySnapshotLocalDocumentData$<FirestoreClientDocument>(database, 'clients').pipe(
      map((querySnapshot) => querySnapshot.data.find((d) => predicate(d)))
    )
  )
