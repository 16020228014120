import { type FC, useMemo } from 'react'

import { type Unit } from '@via/schema'
import dayjs from 'dayjs'
import { times } from 'lodash-es'

import { LineTitle } from '../../../atoms/LineTitle/LineTitle.tsx'
import { UnitLabel } from '../../../atoms/UnitLabel/UnitLabel.tsx'
import { type NumberDisplayType } from '../../../hooks/useFormatNumber.ts'
import { PopoverLineCellWithUnitFormField } from '../../../molecules/PopoverInlineFormField/PopoverLineCellWithUnitFormField.tsx'

export interface MonthlyTableDataRowProps {
  readonly title: string
  readonly rowId: string
  readonly unit?: Unit
  readonly numberDisplayType?: NumberDisplayType
  readonly startDate: string
  readonly values?: Record<string, string | number>
  readonly defaultValue?: string | number
}

export const MonthlyTableDataRow: FC<MonthlyTableDataRowProps> = ({
  title,
  rowId,
  unit,
  numberDisplayType,
  startDate,
  values,
  defaultValue,
  ...props
}) => {
  const startDateDayjs = useMemo(() => dayjs(startDate, 'YYYY-MM-DD').startOf('month'), [startDate])

  return (
    <div className="flex shrink-0">
      <LineTitle label={title} className="w-48" variant="groupCalculate" closingSection>
        {unit && <UnitLabel unit={unit} />}
      </LineTitle>
      {times(12, (i) => {
        const key = startDateDayjs.add(i, 'months').format('YYYY-MM')
        return (
          <PopoverLineCellWithUnitFormField
            key={key}
            name={`quota.${key}.${rowId}`}
            value={values?.[key] ?? defaultValue}
            numberDisplayType={numberDisplayType ?? unit}
            cellClassName="border-b-green-400"
            {...props}
          />
        )
      })}
    </div>
  )
}
