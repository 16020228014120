import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'

import { Icons } from '../../atoms'
import { Button } from '../../atoms/Button/Button'
import { Label } from '../../atoms/Label/Label'
import { Tooltip } from '../../atoms/Tooltip/Tooltip'
import { useReport } from '../../context'
import { cn } from '../../lib/utils'
import { InputFormField } from '../../molecules/InputFormField/InputFormField'
import { type ReportData } from '../../types'

type ReportFileFormProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  readonly isCurrentUserBudgetAgronomist: boolean
  readonly onGenerateDraftPdfClick: () => Promise<void>
  readonly onGenerateFinalPdfClick: () => Promise<void>
}

export const ReportFileForm: React.FC<ReportFileFormProps> = ({
  isCurrentUserBudgetAgronomist,
  onGenerateDraftPdfClick,
  onGenerateFinalPdfClick,
  ...props
}) => {
  const intl = useIntl()
  const report = useReport()
  const [generatingDraftPdf, setGeneratingDraftPdf] = useState(false)
  const [generatingFinalPdf, setGeneratingFinalPdf] = useState(false)

  const reportHasScenarios = report.scenarios.length > 0
  const reportHasYears = report.years.length > 0
  const reportIsInValidState = !reportHasScenarios || reportHasYears

  const generateDraftPdf = useCallback(async () => {
    try {
      setGeneratingDraftPdf(true)
      await onGenerateDraftPdfClick()
    } finally {
      setGeneratingDraftPdf(false)
    }
  }, [onGenerateDraftPdfClick])

  const generateFinalPdf = useCallback(async () => {
    try {
      setGeneratingFinalPdf(true)
      await onGenerateFinalPdfClick()
    } finally {
      setGeneratingFinalPdf(false)
    }
  }, [onGenerateFinalPdfClick])

  return (
    <>
      <div className={cn('flex w-full max-w-screen-md flex-row items-end justify-start gap-6 pt-6', props.className)}>
        <InputFormField<ReportData, 'defaultFileName'>
          name="defaultFileName"
          labelDimension="sm"
          label={intl.formatMessage({ id: 'reportFileList.form.fileName' })}
          className="w-full"
        />
        <Tooltip label={intl.formatMessage({ id: 'reportFileList.form.generatePdfDraft.tooltip' })}>
          <Button
            className="w-fit text-nowrap text-left text-xs leading-[0.9]"
            onClick={generateDraftPdf}
            loading={generatingDraftPdf}
            disabled={generatingDraftPdf || !reportIsInValidState}>
            <Icons.FilePdf />
            <div className="pt-1">{intl.formatMessage({ id: 'reportFileList.form.generatePdfDraft' })}</div>
          </Button>
        </Tooltip>

        <Tooltip label={intl.formatMessage({ id: 'reportFileList.form.generatePdfFinal.tooltip' })}>
          <Button
            className="w-fit text-nowrap text-left text-xs leading-[0.9]"
            onClick={generateFinalPdf}
            loading={generatingFinalPdf}
            disabled={generatingFinalPdf || !isCurrentUserBudgetAgronomist || !reportIsInValidState}>
            <Icons.FilePdf />
            <div className="pt-1">{intl.formatMessage({ id: 'reportFileList.form.generatePdfFinal' })}</div>
          </Button>
        </Tooltip>
      </div>
      {!reportIsInValidState && (
        <Label className="pt-1 text-sm text-red-500">
          {intl.formatMessage({ id: 'reportConfiguration.form.years.mandatory' })}
        </Label>
      )}
    </>
  )
}
