import { BudgetUserZod } from '@via/schema'
import { z } from 'zod'

export const BudgetCreationFormZod = z.object({
  billable: z.boolean(),
  year: z.coerce.number().gte(1000, { message: 'form.required' }).lte(9999, { message: 'form.required' }),
  contractId: z.string().optional().default(''),
  name: z.string().min(1, { message: 'form.required' }),
  users: z.object({
    agronomist: BudgetUserZod.optional().nullable(),
    technician: BudgetUserZod.optional().nullable(),
    collaborator: BudgetUserZod.optional().nullable(),
  }),
})

export type BudgetCreationFormData = z.infer<typeof BudgetCreationFormZod>
