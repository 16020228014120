import { type FC } from 'react'

import { MilkAnimalsView } from '@via/components'

import { useMilkAnimalsPage } from './useMilkAnimalsPage.ts'

export const MilkAnimalsPage: FC = () => {
  const props = useMilkAnimalsPage()
  return <MilkAnimalsView key={props.scenario._id} {...props} />
}
