import { z } from 'zod'

export const LoanComputationTypeZod = z.enum([
  'annuity',
  'interest',
  'projectedCapital',
  'capital',
  'earlyRepayment',
  'remainingDueAmount',
  'newCapitalBorrowed',
  'exigibleCapital',
])
export type LoanComputationType = z.infer<typeof LoanComputationTypeZod>

export const LoanTotalComputationTypeZod = z.enum([
  ...LoanComputationTypeZod.options,
  'netNewCapital',
  'liquidityMovement',
])
export type LoanTotalComputationType = z.infer<typeof LoanTotalComputationTypeZod>
