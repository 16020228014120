import { forwardRef, type HtmlHTMLAttributes } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const headerUnitVariants = cva(
  'flex p-1 justify-end items-center w-24 h-[22px] shrink-0 text-black text-center text-xs font-bold select-none',
  {
    variants: {
      variant: {
        default: 'bg-gray-100',
        head: 'bg-sky-50',
        total: 'bg-orange-200',
        alternate: 'bg-white',
      },
      withDividerLeft: {
        false: '',
        true: 'border-l border-l-gray-400',
      },
      withDividerRight: {
        false: '',
        true: 'last:border-r border-r-gray-400',
      },
    },
    defaultVariants: {
      variant: 'default',
      withDividerLeft: true,
      withDividerRight: false,
    },
  }
)

export interface HeaderUnitProps extends HtmlHTMLAttributes<HTMLDivElement>, VariantProps<typeof headerUnitVariants> {
  readonly label: string
}

export const HeaderUnit = forwardRef<HTMLDivElement, HeaderUnitProps>(
  ({ className, label, variant, withDividerLeft, withDividerRight, ...props }, ref) => (
    <div
      className={cn(headerUnitVariants({ variant, withDividerLeft, withDividerRight }), className)}
      ref={ref}
      {...props}>
      <p>{label}</p>
    </div>
  )
)
