import { z } from 'zod'

import { DataKeyZod } from '../data-keys'

import { GrowthValueZod, MonetaryValueZod } from './monetary-value-zod'

export const CellDataOverrideZod = z.object({
  value: MonetaryValueZod.optional(),
  add: MonetaryValueZod.optional(),
  subtract: MonetaryValueZod.optional(),
  growth: GrowthValueZod.optional(),
  weight: DataKeyZod.optional(),
})
