import { useCallback } from 'react'

import {
  type ScenarioUpdateData,
  type ScenarioUpdateViewProps,
  sortScenarios,
  useBudget,
  useScenario,
} from '@via/components'
import { YearKeyZod } from '@via/schema'
import { times } from 'lodash-es'

import { useCurrentBudgetHandler } from '../../contexts/currentBudget/useCurrentBudgetHandler.ts'
import { useCurrentScenarioHandler } from '../../contexts/currentScenario/useCurrentScenarioHandler.ts'
import { useOfflineState } from '../../contexts/offlineState/useOfflineState.ts'
import { useSyncRequired } from '../../contexts/syncRequired/useSyncRequired.ts'
import { useAppNavigation } from '../../navigation/useAppNavigation.ts'

import { getScenarioPreviousPages } from './utils/scenarioPage.ts'

export const useScenarioUpdatePage = (): ScenarioUpdateViewProps => {
  const { offline } = useOfflineState()
  const { onNavigationChange } = useAppNavigation()
  const {
    close: onCloseBudget,
    requestOwnership: onRequestOwnership,
    update: onUpdateBudget,
  } = useCurrentBudgetHandler()
  const { updateReference: onRefreshScenarioReference, update, duplicate } = useCurrentScenarioHandler()
  const syncRequired = useSyncRequired()
  const { updateScenarioReferences: onUpdateScenarioReferences } = useCurrentBudgetHandler()

  const budget = useBudget()
  const scenario = useScenario()

  const onValueChange = useCallback(
    async ({ period, ...scenarioForm }: ScenarioUpdateData) => {
      await update({
        period: {
          ...period,
          projectionYears: YearKeyZod.array().parse(times(period.numberOfYears, (i) => String(budget.year + i))),
          financeProjectionYears: YearKeyZod.array().parse(
            times(Math.max(period.numberOfYears, 10), (i) => String(budget.year + i))
          ),
        },
        ...scenarioForm,
      })
    },
    [budget.year, update]
  )

  const onDuplicate = useCallback(async () => {
    const newScenario = await duplicate()
    await onNavigationChange({
      from: '/budgets/$budgetId',
      to: '/budgets/$budgetId/scenarios/$scenarioId/configuration',
      params: { scenarioId: newScenario._id },
    })
  }, [duplicate, onNavigationChange])

  const findNextScenarioToNavigateTo = () => {
    const sortedScenarios = sortScenarios(budget.scenarios)
    const scenarioIndex = sortedScenarios.findIndex((sortedScenario) => sortedScenario.id === scenario._id)
    return scenarioIndex === sortedScenarios.length - 1 ? sortedScenarios[0].id : sortedScenarios[scenarioIndex + 1].id
  }

  const onErase = async () => {
    await update({ archived: true })

    await onNavigationChange({
      to: '/budgets/$budgetId/scenarios/$scenarioId/configuration',
      params: { budgetId: budget._id, scenarioId: findNextScenarioToNavigateTo() },
    })
  }

  const navigateBack = async () => {
    const previousPages = getScenarioPreviousPages(budget._id, scenario._id)
    const to = previousPages.find((page) => page !== 'configuration') ?? 'results'

    await onNavigationChange({
      to: `/budgets/$budgetId/scenarios/$scenarioId/${to}`,
      params: { budgetId: budget._id, scenarioId: scenario._id },
    })
  }

  return {
    offline,
    budget,
    scenario,
    syncRequired,
    onCancel: navigateBack,
    onCloseBudget,
    onConfirm: navigateBack,
    onDuplicate,
    onErase,
    onNavigationChange,
    onRefreshScenarioReference,
    onRequestOwnership,
    onUpdateScenarioReferences,
    onValueChange,
    onUpdateBudget,
  }
}
