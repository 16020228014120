import { type DataKey } from '../data-key-zod'

import {
  AliasDataKeyAttributes,
  AssetDataKeyAttributes,
  AssetInventoryDataKeyAttributes,
  AssetTotalDataKeyAttributes,
  BalanceTableAttributes,
  BlueprintAliasDataKeyAttributes,
  CalculationDataKeyAttributes,
  CompositeDataKeyAttributes,
  ComputationReferenceDataKeyAttributes,
  FinanceAttributes,
  GrbfDataKeyAttributes,
  InventoryDataKeyAttributes,
  InventoryTotalDataKeyAttributes,
  InvestmentAttributes,
  InvestmentInventoryDataKeyAttributes,
  LoanAttributes,
  MilkProductionDataKeyAttributes,
  MovementOverridableDataKeyAttributes,
  MovementTotalDataKeyAttributes,
  NetMarginTableAttributes,
  OverridableDataKeyAttributes,
  OverridableReferenceDataKeyAttributes,
  ReferenceDataKeyAttributes,
  RepaymentTableAttributes,
  TotalDataKeyAttributes,
} from './specifications'
import { type DataAttribute } from './types'

export const DataAttributes = {
  ...AliasDataKeyAttributes,
  ...BlueprintAliasDataKeyAttributes,
  ...GrbfDataKeyAttributes,
  ...TotalDataKeyAttributes,
  ...CompositeDataKeyAttributes,
  ...OverridableDataKeyAttributes,
  ...ReferenceDataKeyAttributes,
  ...OverridableReferenceDataKeyAttributes,
  ...AssetDataKeyAttributes,
  ...AssetInventoryDataKeyAttributes,
  ...AssetTotalDataKeyAttributes,
  ...InvestmentAttributes,
  ...BalanceTableAttributes,
  ...FinanceAttributes,
  ...MovementOverridableDataKeyAttributes,
  ...MovementTotalDataKeyAttributes,
  ...InventoryDataKeyAttributes,
  ...InvestmentInventoryDataKeyAttributes,
  ...InventoryTotalDataKeyAttributes,
  ...LoanAttributes,
  ...RepaymentTableAttributes,
  ...NetMarginTableAttributes,
  ...CalculationDataKeyAttributes,
  ...MilkProductionDataKeyAttributes,
  ...ComputationReferenceDataKeyAttributes,
} as const satisfies Record<DataKey, DataAttribute>
