import { initScenarioRxDatabase } from '../../rxdb/scenarios/initScenarioRxDatabase.ts'
import { type AppRxDatabase, type ScenarioRxDatabase } from '../../rxdb/types.ts'

const scenarioDatabaseName = (budgetId: string, scenarioId: string) =>
  `via-scenario--${budgetId.toLowerCase()}-${scenarioId.toLowerCase()}--${import.meta.env.VITE_RXDB_VERSION}`

export class ScenarioRxDatabasesManager {
  readonly #scenarioRxDatabase: Record<string, ScenarioRxDatabase> = {}

  constructor(private readonly appDatabase: AppRxDatabase) {}

  scenarioDatabase = async (budgetId: string, scenarioId: string) => {
    const databaseId = scenarioDatabaseName(budgetId, scenarioId)
    if (!this.#scenarioRxDatabase[databaseId]) {
      this.#scenarioRxDatabase[databaseId] = await initScenarioRxDatabase(databaseId, this.appDatabase.storage)
    }
    return this.#scenarioRxDatabase[databaseId]
  }
}
