import { captureException } from '@sentry/react'
import { createFileRoute, notFound, Outlet } from '@tanstack/react-router'
import { ReadonlyBudgetContextProvider, ReportFileContext, ScenarioListContext } from '@via/components'
import { collections } from '@via/frontend-schema'

import { fetchOrThrowFirestoreScenarioData } from '../firestore/scenarios/fetchOrThrowFirestoreScenarioData.ts'

const FileComponent = () => {
  const { file, scenarios } = Route.useRouteContext()

  return (
    <ReadonlyBudgetContextProvider>
      <ReportFileContext.Provider value={file}>
        <ScenarioListContext.Provider value={scenarios}>
          <Outlet />
        </ScenarioListContext.Provider>
      </ReportFileContext.Provider>
    </ReadonlyBudgetContextProvider>
  )
}

export const Route = createFileRoute('/budgets/$budgetId/reports/$reportId/files/$fileId')({
  component: FileComponent,
  beforeLoad: async ({ params: { budgetId, reportId, fileId } }) => {
    try {
      const file = await collections.budgets(budgetId).reports(reportId).files.findByIdOrThrow(fileId)

      const scenarios = await Promise.all(
        Object.entries(file.scenarioStates).map(([scenarioId, stateId]) =>
          fetchOrThrowFirestoreScenarioData(budgetId, scenarioId, stateId)
        )
      )

      return { file, scenarios }
    } catch (error) {
      captureException(error, {
        contexts: { params: { budgetId, reportId, fileId } },
        tags: { route: '/budgets/$budgetId/reports/$reportId/files/$fileId' },
      })
      throw notFound()
    }
  },
})
