import { type FC } from 'react'

import { LineDivider, type LineDividerProps } from '../../../atoms/LineDivider/LineDivider.tsx'
import { LineFormulaStrip } from '../../../atoms/LineTitle/LineFormulaStrip.tsx'

export type MonetaryYearFormFieldTableDividerRowProps = Pick<LineDividerProps, 'variant' | 'border'>

export const MonetaryYearFormFieldTableDividerRow: FC<MonetaryYearFormFieldTableDividerRowProps> = ({
  variant,
  border,
}) => (
  <div className="flex shrink-0">
    <div className="flex w-72 shrink-0">
      <LineFormulaStrip variant="empty" />
      <LineDivider className="flex w-full" variant={variant} border={border} withDividerLeft />
    </div>
    <LineDivider className="flex w-32 shrink-0" variant={variant} border={border} withDividerLeft withDividerRight />
  </div>
)
