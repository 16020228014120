import { BigNumber } from 'bignumber.js'

import { createBudgetDefinition, ViagritelApportRetraitLigneNo } from '../types'
import { isFermeBudgitel2021, isFermeBudgitel2022, sumBy } from '../utils'

export const inflowsOutflows = createBudgetDefinition({
  'inflows.owner.imported.total': {
    testResolver: ({ cri }) => cri('ZZ127'), // Apports personnels total
    databaseResolver: {
      resolve: ({ apportsRetraits }) =>
        sumBy(
          apportsRetraits({
            apport_retrait_ligne_no: [
              ViagritelApportRetraitLigneNo.LocationDeMaison,
              ViagritelApportRetraitLigneNo.RevenusAutresEntreprises,
              ViagritelApportRetraitLigneNo.AutresApportsPerso,
              ViagritelApportRetraitLigneNo.AutresMisesDeFonds,
            ],
          }),
          'montant'
        ), // ZZ127
    },
  },
  'outflows.owner.imported.total': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(2611),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(801),
      },
    ],
    databaseResolver: {
      resolve: ({ apportsRetraits }) =>
        sumBy(
          apportsRetraits({
            apport_retrait_ligne_no: [
              ViagritelApportRetraitLigneNo.SalairesBrutsDesExploitants,
              ViagritelApportRetraitLigneNo.RetraitsDesExploitantsEtDividendes,
              ViagritelApportRetraitLigneNo.Electricite,
              ViagritelApportRetraitLigneNo.Telephone,
              ViagritelApportRetraitLigneNo.Auto,
              ViagritelApportRetraitLigneNo.Assurance,
              ViagritelApportRetraitLigneNo.AutresRetraitsPerso,
            ],
          }),
          'montant'
        ),
    },
  },
  'outflows.investment.imported.personal': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ apportsRetraits }) =>
        sumBy(
          apportsRetraits({
            apport_retrait_ligne_no: ViagritelApportRetraitLigneNo.AutresInvestissementsPersonnels,
          }),
          'montant'
        ),
    },
  },
  'outflows.investment.imported.rrsp': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ apportsRetraits }) =>
        sumBy(
          apportsRetraits({
            apport_retrait_ligne_no: ViagritelApportRetraitLigneNo.ContributionREER,
          }),
          'montant'
        ),
    },
  },
  'outflows.investment.imported.non-agricultural': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ apportsRetraits }) =>
        sumBy(
          apportsRetraits({
            apport_retrait_ligne_no: ViagritelApportRetraitLigneNo.AutresInvestissementsNonAgricoles,
          }),
          'montant'
        ),
    },
  },
  'outflows.tax.imported.total': {
    testResolver: ({ cri }) => cri('ZZ135').plus(cri('ZZ174')),
    databaseResolver: {
      resolve: ({ apportsRetraits, comptesExploitation }) => {
        const sumImpots = sumBy(
          apportsRetraits({ apport_retrait_ligne_no: ViagritelApportRetraitLigneNo.ImpotsPayes }), // ZZ135
          'montant'
        )
        const sumTaxeSurCapital = sumBy(comptesExploitation({ type_ligne: 'GV', ligne: 172_900 }), 'montant') // Taxe sur le capital ZZ174
        return sumImpots.plus(sumTaxeSurCapital)
      },
    },
  },
})
