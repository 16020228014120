import { type UserUpdateZod } from '@via/schema'
import { type JsonSchema } from 'rxdb'
import { type z } from 'zod'

export const UserUpdateJsonSchema = {
  type: 'object',
  required: ['timestamp', 'userId', 'userName', 'userRole'],
  properties: {
    timestamp: {
      type: 'string',
      format: 'date-time',
    },
    userId: {
      type: 'string',
    },
    userName: {
      type: 'string',
    },
    userRole: {
      type: 'string',
    },
  },
} satisfies JsonSchema<z.output<typeof UserUpdateZod>>
