export const ViagritelTypeLigneComptesExploitation = [
  'AX', // Divers animaux
  'BI', // Bouvillons
  'CE', // Céréales
  'CJ', // Centre-jardin
  'CR', // Chèvres
  'DA', // Érablière
  'FI', // Finisseur
  'FO', // Fourrages
  'GD', // Culture non dét
  'GP', // Machinerie
  'GT', // Terre et bâtime
  'GV', // Général
  'IN', // Ovin
  'IS', // Boisé
  'JV', // Veaux de grain
  'LA', // Lait
  'MA', // Maraîcher
  'NA', // Naisseur
  'PO', // Pépinière-orn.
  'SO', // Serre-Orn.
  'TX', // Divers commerce
  'VA', // Vache veaux
] as const
export type ViagritelTypeLigneCompteExploitation = (typeof ViagritelTypeLigneComptesExploitation)[number]

export const ViagritelTypeLigneFicheTechnique = [
  'AX', // Divers animaux
  'BI', // Bouvillons
  'CR', // Chèvres
  'DA', // Érablière
  'FI', // Finisseur
  'GF', // Générale
  'IN', // Ovins
  'IS', // Boisé
  'JV', // Veaux de grain
  'LA', // Lait
  'NA', // Naisseur
  'TX', // Divers commerces
  'VA', // Vache veaux
] as const
export type ViagritelTypeLigneFichesTechnique = (typeof ViagritelTypeLigneFicheTechnique)[number]

export const ViagritelTypeLigneInventairesApprovisionnement = [
  'AX', // Divers animaux
  'BI', // Bouvillons
  'CE', // Céréales
  'CJ', // Centre-Jardin
  'CR', // Chèvres
  'DA', // Érablière
  'FI', // Finisseur
  'FO', // Fourrages
  'GD', // Cultures non détaillées
  'GP', // Machinerie
  'IN', // Ovins
  'JV', // Veaux de grain
  'LA', // Lait
  'MA', // Maraîcher
  'NA', // Naisseur
  'PO', // Pépinière-Ornementale
  'SO', // Serre-Ornementale
  'TX', // Divers commerces
  'VA', // Vache veaux
] as const
export type ViagritelTypeLigneInventaireApprovisionnement =
  (typeof ViagritelTypeLigneInventairesApprovisionnement)[number]

export const ViagritelProductions = [
  'AG', // Asperge
  'AI', // Ail
  'AM', // Tout maraîcher
  'AS', // Avoine semence
  'AV', // Avoine
  'AX', // Exotique
  'B1', // Blé aut. four.
  'B2', // Blé aut. panif.
  'B3', // Blé aut.fou.sem
  'B4', // Blé aut.pan.sem
  'B5', // Blé prin. four.
  'B6', // Blé prin.panif.
  'B7', // Blé pr.fou.sem.
  'B8', // Blé pr.pan.sem.
  'BE', // Bleuet
  'BI', // Bouvillons
  'BR', // Brocoli
  'BS', // Bisons
  'BT', // Betterave
  'C1', // Végétaux(Exclus fleurs coupées)
  'C2', // Fleuristerie(Inclus fleurs coupées)
  'C3', // Matières premières
  'C4', // Autres produits inertes
  'C5', // Autres ventes
  'CA', // Canola
  'CB', // Concombre serre
  'CC', // Carotte marché
  'CD', // Chanvre
  'CE', // Camomille
  'CF', // Chou-fleur
  'CG', // Champignon
  'CH', // Chou
  'CI', // Citrouille
  'CJ', // Centre-Jardin
  'CL', // Céleri
  'CM', // Cantaloup
  'CN', // Cornichon
  'CO', // Cult. ornement.
  'CR', // Chèvres
  'CS', // Chou frisé
  'CU', // Chou d'hiver
  'CV', // Chevaux
  'CX', // Chou bruxelle
  'DA', // Erablière
  'DG', // Dindons de gril
  'DL', // Dindons lourds
  'EC', // Echalotte
  'EP', // Epinard
  'FC', // Fèves de couleur
  'FD', // Fruits divers
  'FI', // Porcs
  'FM', // Framboise
  'FO', // Foin
  'FR', // Fraise
  'FV', // Féverolle
  'GC', // Grandes cultures
  'GD', // Cult. non-détaillées
  'GF', // Général
  'GL', // Global maraîcher
  'GM', // Grains mélangés
  'GO', // Gourgane
  'GP', // Machinerie
  'GQ', // Global Pépinière
  'GS', // Global Serre
  'GT', // Terre et bâtiment
  'GU', // Gourgane
  'GV', // Général
  'HA', // Haricot conser. (Ma)
  'HC', // Haricot conser. (Ce)
  'HF', // Haricot frais
  'HS', // Haricots secs
  'IN', // Ovins
  'IS', // Boisé
  'JV', // Veaux de grain
  'LA', // Lait
  'LD', // Légumes divers
  'LE', // Lentille
  'LI', // Lin
  'LP', // Lapins
  'LT', // Laitue
  'LU', // Lupin
  'MA', // Mais-épi
  'MC', // Mais-sucré con. (Ma)
  'MD', // Melon d'eau
  'ME', // Mais-ensilage
  'MF', // Mais-sucré frais
  'MG', // Mais-grain sec
  'MH', // Mais-grain hum.
  'MP', // Mais-épi hum.
  'MS', // Mais-sucré con. (Ce)
  'NA', // Porcelets
  'NT', // Navet
  'OC', // Oeufs consommation
  'OG', // Oignon
  'OH', // Orge humide
  'OI', // Oeufs incubation
  'OQ', // F.A.Q.
  'OR', // Orge
  'OS', // Orge semence
  'OT', // Ratites
  'P1', // Patate semence
  'P2', // Patate table
  'P3', // Gén. n.d. planche
  'P4', // Arbres fr. planche
  'P5', // Arbres planche
  'P6', // Arbuste planche
  'P7', // Arbust. h. planche
  'P8', // Conifères planche
  'P9', // Plante gr. Planche
  'PA', // Pâturage
  'PB', // Arbres champs
  'PC', // Pois conserv. (Ma)
  'PD', // Pl. médicinales
  'PE', // Pépinière
  'PF', // Arbuste champs
  'PG', // Poulets de gril
  'PH', // Rosiers champs
  'PI', // Pois conserv. (Ce)
  'PJ', // Vivaces>2l planche
  'PK', // Panic érigé
  'PL', // Paille non-détaillée
  'PM', // Pomme
  'PN', // Panais
  'PO', // Pois secs
  'PP', // Autre déf. planche
  'PQ', // Gén. n.d. champs
  'PR', // Arbres fr. champs
  'PS', // Pisciculture
  'PT', // Piment
  'PU', // Arbust. h. champs
  'PV', // Conifères champs
  'PW', // Fleurs c. champs
  'PX', // Poireaux
  'PY', // Vivaces>2l champs
  'PZ', // Autres def. champs
  'RA', // Radis
  'S1', // Gén. et non-défini
  'S2', // Annuelles ornement.
  'S3', // Annuelles potagères
  'S4', // Aquatiques
  'S5', // Boutures et plantules
  'S6', // Cactées  succulentes
  'S7', // Fleurs coupées
  'S8', // Plantes grimpantes
  'S9', // Plantes à feuillage
  'SA', // Sarrasin
  'SB', // Potées fleuries A
  'SC', // Potées fleuries N
  'SD', // Potées fleuries P
  'SE', // Seigle
  'SF', // Autres définies
  'SH', // Vivaces    < 2 litres
  'SO', // Sorgho
  'SP', // Sapin
  'SS', // Soya semence
  'ST', // Cerfs
  'SY', // Soya
  'T1', // Autr. act. comm.
  'T2', // Fromagerie
  'T3', // Agro-tourisme
  'T4', // Carrière
  'T5', // Déneigement
  'T6', // Boulangerie
  'T7', // Boucherie
  'T8', // Miel
  'TA', // Taures d'élevage
  'TB', // Tabac
  'TC', // Tomate champs
  'TH', // Thym
  'TI', // Triticale
  'TL', // Tournesol
  'TR', // Tourbe
  'TS', // Tomate serre
  'TT', // Toutes céréales
  'VA', // Vache-veau
  'VL', // Veaux de lait
  'WA', // Wapitis
  'ZC', // Zucchini
  'ZV', // Autre végétal
  'ZZ', // Entreprise
] as const
export type ViagritelProduction = (typeof ViagritelProductions)[number]

export const ViagritelTypeProductions = [
  'AN',
  'CE',
  'CJ',
  'CO',
  'FO',
  'GC',
  'GJ',
  'GL',
  'GN',
  'GQ',
  'GS',
  'MA',
  'OQ',
  'PL',
  'PO',
  'SO',
  'ZZ',
] as const
export type ViagritelTypeProduction = (typeof ViagritelTypeProductions)[number]

export const CerealProductions: ViagritelProduction[] = [
  'AS',
  'AV',
  'B1',
  'B2',
  'B3',
  'B4',
  'B5',
  'B6',
  'B7',
  'B8',
  'CA',
  'CD',
  'FC',
  'FV',
  'GM',
  'GU',
  'HA',
  'HS',
  'LE',
  'LI',
  'LU',
  'MA',
  'MG',
  'MH',
  'MP',
  'MS',
  'OH',
  'OR',
  'OS',
  'PI',
  'PK',
  'PO',
  'SA',
  'SE',
  'SS',
  'SY',
  'TI',
  'TL',
  'TT',
] as const

export const ViagritelTypeProductionMaraicheres = [
  'AG', // Asperge
  'AI', // Ail
  'AM', // Tout maraîcher
  'BE', // Bleuet
  'BR', // Brocoli
  'BT', // Betterave
  'CB', // Concombre serre
  'CC', // Carotte marché
  'CE', // Camomille
  'CF', // Chou-fleur
  'CG', // Champignon
  'CH', // Chou
  'CI', // Citrouille
  'CL', // Céleri
  'CM', // Cantaloup
  'CN', // Cornichon
  'CO', // Cult. ornement.
  'CS', // Chou frisé
  'CU', // Chou d'hiver
  'CX', // Chou bruxelle
  'EC', // Echalotte
  'EP', // Epinard
  'FD', // Fruits divers
  'FM', // Framboise
  'FR', // Fraise
  'GO', // Gourgane
  'HC', // Haricot conser. (Ce)
  'HF', // Haricot frais
  'LD', // Légumes divers
  'LT', // Laitue
  'MC', // Mais-sucré con. (Ma)
  'MD', // Melon d'eau
  'MF', // Mais-sucré frais
  'NT', // Navet
  'OG', // Oignon
  'P1', // Patate semence
  'P2', // Patate table
  'PC', // Pois conserv. (Ma)
  'PD', // Pl. médicinales
  'PE', // Pépinière
  'PM', // Pomme
  'PN', // Panais
  'PT', // Piment
  'PX', // Poireaux
  'RA', // Radis
  'SP', // Sapin
  'TB', // Tabac
  'TC', // Tomate champs
  'TH', // Thym
  'TR', // Tourbe
  'TS', // Tomate serre
  'ZC', // Zucchini
  'ZV', // Autre végétal
] as const
export type ViagritelTypeProductionMaraichere = (typeof ViagritelTypeProductionMaraicheres)[number]

export const ViagritelAlimentsColonne = ['A', 'Y', 'O'] as const
export type ViagritelAlimentColonne = (typeof ViagritelAlimentsColonne)[number]

export const ViagritelCourtTermeTypes = [
  'CP', // Comptes à payer
  'CR', // Comptes à recevoir
  'CS', // Agri (Qc & Invest.)
  'EP', // Encaisse et placement
  'FP', // Payés d'avance
  'FR', // Reçus d'avance
] as const
export type ViagritelCourtTermeType = (typeof ViagritelCourtTermeTypes)[number]

export const ViagritelCourtTermeTypeLignes = [
  '10', // CP,Marge de crédit | CR,Avances aux actionnaires
  '20', // CP,Paiements anticipés | CR,Agri-Stabilité
  '30', // CP,Agri (Qc & Invest) contrib. estim. Entr | CR,Agri (Qc & Invest) contrib. estim. FADQ
  '40', // CR,ASRA
  '50', // CR,ASRA - Ovins de Lait
  '60', // CR,ASRA - Ovins lourd
  '70', // CR,ASRA-Vaches-veaux
  '80', // CR,ASRA-Bouvillons dans ferme Vaches-veaux
  'AN', // Prod. animale:
  'CO', // Commerce:
  'CT', // Agri (Qc & Invest)
  'EN', // Encaisse
  'GC', // Global grandes cultures
  'GJ', // Centre-Jardin
  'GL', // Global maraîchers
  'GQ', // Pépinière-Ornementale
  'GS', // Serre-Ornementale
  'PL', // Placements court terme
  'ZZ', // Divers
] as const
export type ViagritelCourtTermeTypeLigne = (typeof ViagritelCourtTermeTypeLignes)[number]
