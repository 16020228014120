import { forwardRef, useId } from 'react'

import { FormLabel } from '../../atoms/FormLabel/FormLabel'
import { cn } from '../../lib/utils'
import { DateInput, type DateInputProps } from '../DateInput/DateInput'

export interface DateInputWithLabelProps extends DateInputProps {
  readonly label: string
}

export const DateInputWithLabel = forwardRef<HTMLInputElement, DateInputWithLabelProps>(
  ({ label, id, value, className, ...props }, ref) => {
    const reactId = useId()
    return (
      <div className={cn('flex flex-col gap-1.5 pt-0.5', className)}>
        <FormLabel
          className={cn('select-none', props.dimension === 'xs' ? 'text-xxs' : 'text-xs')}
          htmlFor={id ?? reactId}>
          {label}
        </FormLabel>
        <DateInput id={id ?? reactId} value={value} ref={ref} {...props} />
      </div>
    )
  }
)
