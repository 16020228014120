import { allEnabledKeys, BudgetReferenceZod, type DataAttribute } from '@via/schema'

import { computeRows } from './rows/compute-rows'
import { toRowData } from './bignumber'
import { type BudgetReference, type RowData, type ScenarioState } from './types'

const COMPUTABLE_TYPES = [
  'grbf',
  'projection',
  'total',
  'composite',
  'calculation',
  'overridable',
  'movement-overridable',
  'movement-total',
  'finance-loan',
  'finance-loan-investment',
  'finance-loan-total',
  'finance-asset',
  'finance-asset-inventory',
  'finance-asset-total',
  'finance-investment',
  'finance-investment-inventory',
  'finance-investment-total',
  'milk-quota',
  'milk-income',
  'inventory',
  'inventory-total',
] as const

const EMPTY_REFERENCE = BudgetReferenceZod.parse({})

export const computeAllRows = async (
  state: ScenarioState | undefined,
  budgetReference: BudgetReference | null | undefined,
  types: ReadonlyArray<DataAttribute['type']> = COMPUTABLE_TYPES,
  keys = allEnabledKeys(state?.enabledProductions ?? [], state?.blueprintVersion, types)
): Promise<ReadonlyArray<RowData>> => {
  const rows = await computeRows(keys, state, budgetReference ?? EMPTY_REFERENCE, (keysToFetch) => {
    throw new Error(`Row fetcher should not be invoked in computeAllRows, invoked for ${JSON.stringify(keysToFetch)}`)
  })

  return rows.map((row) => toRowData(row))
}
