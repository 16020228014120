import { createFileRoute, Navigate } from '@tanstack/react-router'
import { useReportFile } from '@via/components'

const FileComponent = () => {
  const params = Route.useParams()
  const file = useReportFile()

  const scenarioIds = Object.keys(file.scenarioStates)

  if (scenarioIds.length === 0) {
    return <Navigate to="/budgets/$budgetId/reports/$reportId/files" params={params} />
  }

  return (
    <Navigate
      to={`/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results`}
      params={{ ...params, scenarioId: scenarioIds[0] }}
    />
  )
}

export const Route = createFileRoute('/budgets/$budgetId/reports/$reportId/files/$fileId/')({
  component: FileComponent,
})
