import { type FC } from 'react'

import { SelectLinks } from '../../atoms/Select/SelectLinks'

interface AppSelectNavigationBarLabels {
  readonly budgets: string
  readonly clients: string
  readonly referencesValues: string
}

export interface AppSelectNavigationBarProps {
  readonly labels: AppSelectNavigationBarLabels
  readonly active?: keyof AppSelectNavigationBarLabels
  readonly offline?: boolean
}

export const AppSelectNavigationBar: FC<AppSelectNavigationBarProps> = ({ labels, active, offline }) => (
  <SelectLinks
    label={active ? labels[active] : ''}
    links={(offline
      ? [{ to: '/budgets', label: labels.budgets }]
      : [
          { to: '/budgets', label: labels.budgets },
          { to: '/clients', label: labels.clients },
          { to: '/', label: labels.referencesValues },
        ]
    ).filter(({ label }) => (active ? label !== labels[active] : true))}
  />
)
