import React from 'react'
import { useFormState } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Button } from '../../atoms/Button/Button'
import { FormSubmitProvider } from '../../atoms/Form/FormSubmitProvider'
import { cn } from '../../lib/utils'
import {
  BudgetConfigurationForm,
  type BudgetConfigurationFormProps,
} from '../BudgetConfigurationForm/BudgetConfigurationForm'

import { type BudgetUpdateFormData } from './BudgetUpdateFormZod.ts'

export interface BudgetUpdateFormActions {
  onCancel(): void
  onValueChange(form: BudgetUpdateFormData): Promise<void>
}

export interface BudgetUpdateFormProps
  extends BudgetUpdateFormActions,
    Pick<BudgetConfigurationFormProps, 'readonly' | 'users'>,
    React.FormHTMLAttributes<HTMLFormElement> {}

export const BudgetUpdateForm = React.forwardRef<HTMLFormElement, BudgetUpdateFormProps>(
  ({ className, onValueChange, readonly, onCancel, users, ...props }, ref) => {
    const intl = useIntl()
    const { isSubmitting } = useFormState()

    return (
      <FormSubmitProvider
        onFormSubmit={onValueChange}
        className={cn('flex h-full w-fit flex-col items-center gap-20 overflow-hidden', className)}
        ref={ref}
        {...props}>
        <BudgetConfigurationForm readonly={readonly} users={users} />
        <div className="flex gap-4">
          <Button onClick={onCancel} variant="outline" disabled={isSubmitting}>
            {intl.formatMessage({ id: 'budgetConfiguration.form.action.cancel' })}
          </Button>
          <Button type="submit" loading={isSubmitting} disabled={isSubmitting || readonly}>
            {intl.formatMessage({ id: 'budgetConfiguration.form.action.confirm' })}
          </Button>
        </div>
      </FormSubmitProvider>
    )
  }
)
