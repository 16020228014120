import { useIntl } from 'react-intl'

import { uniq } from 'lodash-es'

import { SELECT_RESET_FILTER_OPTION } from '../atoms/Select/SelectResetOption'

interface UseClientListDataTableOptions {
  years: number[]
}

export const useClientListDataTableOptions = ({ years }: UseClientListDataTableOptions) => {
  const intl = useIntl()

  const sanitizedYears = uniq(years).sort()
  const latestYearsFilterOptions = [
    SELECT_RESET_FILTER_OPTION(intl.formatMessage({ id: 'budgetList.filter.lastYearViagritel' })),
    ...sanitizedYears.map((latestYear) => ({
      value: String(latestYear),
      text: String(latestYear),
      selectedText: intl.formatMessage(
        {
          id: 'budgetList.filter.lastYearViagritel.display',
        },
        {
          year: String(latestYear),
        }
      ),
    })),
  ]

  return {
    filterOptions: latestYearsFilterOptions,
  }
}
