import { BigNumber } from 'bignumber.js'

import { createBudgetDefinition } from '../types'
import { isFermeBudgitel2021, sumBy } from '../utils'
import { type ViagritelProduction } from '../values'

export const maple = createBudgetDefinition({
  'maple.income.imported.inventory.products': {
    testResolver: ({ cri }) => cri('DA48').minus(cri('DA46')),
    databaseResolver: {
      resolve: ({ fichesTechniques }) => {
        const valeurSiropFinAnnee = sumBy(
          fichesTechniques({
            production: 'DA',
            ligne: [1700, 2755], // Valeur du sirop en inventaire fin d'année (les deux lignes)
          }),
          'valeur'
        )
        const valeurSiropDebutAnnee = sumBy(
          fichesTechniques({
            production: 'DA',
            ligne: [1500, 2730], // Valeur du sirop en inventaire début d'année (les deux lignes)
          }),
          'valeur'
        )
        return valeurSiropFinAnnee.minus(valeurSiropDebutAnnee)
      },
    },
  },
  'maple.income.imported.miscellaneous': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(16_859),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation, courtsTermes }) => {
        const ceLignes: Array<{ production: ViagritelProduction; lignes: number | number[] }> = [
          {
            production: 'DA',
            lignes: [
              1550, // Revenu assurance-récolte, DA35
              1600, // Rev. de repas et activités, DA13
              1700, // Ristournes, DA14
              1800, // Vente de bois, DA15
              1825, // Vente eau érable, DA35
              1850, // Rev. bouillage à forfait, DA35
              1900, // Autres revenus directs, DA16
            ],
          },
        ]
        const ceTotal = ceLignes.reduce(
          (acc, { production, lignes }) =>
            acc.plus(sumBy(comptesExploitation({ production, ligne: lignes }), 'montant')),
          new BigNumber(0)
        )
        const ctTotal = sumBy(courtsTermes({ production: 'DA', type: ['CP', 'FR', 'CR', 'FP'] }), 'profit_perte')

        return ceTotal.plus(ctTotal)
      },
    },
  },
  'maple.income.imported.sales.retail': {
    testResolver: ({ cri }) => cri('DA9').plus(cri('DA10')).plus(cri('DA11')).plus(cri('DA12')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          1200, // Vte au détail à la ferme
          1300, // Vte au détail en magasin
          1400, // Vte de produits transf.à la ferme
          1500, // Vte de produits transf. magasin
        ]
        return sumBy(comptesExploitation({ production: 'DA', ligne: ceLignes }), 'montant')
      },
    },
  },
  'maple.income.imported.sales.wholesale': {
    testResolver: 'DA8',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'DA', ligne: 1100 }), 'montant'), // Vte de sirop en vrac
    },
  },
  'maple.expenses.imported.marketing.wholesale': {
    testResolver: 'DA21',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'DA', ligne: 2800 }), 'montant'), // Frais de mise en marché DA21
    },
  },
  'maple.expenses.imported.supplies.direct': {
    testResolver: ({ cri }) => cri('DA23').plus(cri('DA24')),
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceLignes = [
          3000, // Huile et bois de chauffage DA23
          3100, // Autres approv. directs     DA24
        ]
        const iaLignes = [
          1200, // Huile et bois de chauffage DA23
          1300, // Autres approv. directs     DA24
        ]

        return sumBy(comptesExploitation({ production: 'DA', ligne: ceLignes }), 'montant').plus(
          sumBy(inventairesApprovisionnements({ production: 'DA', ligne: iaLignes }), 'delta_dollars')
        )
      },
    },
  },
  'maple.expenses.imported.supplies.resale': {
    testResolver: 'DA17',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'DA', ligne: 2400 }), 'montant'), // Achat de sirop
    },
  },
  'maple.expenses.imported.supplies.retail': {
    testResolver: ({ cri }) => cri('DA18').plus(cri('DA19')),
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceLignes = [
          2450, // Achat eau érable               DA35
          2500, // Filtres et produits de lavage  DA18
          2600, // Contenants, boîtes, étiquettes DA19
        ]
        const iaLignes = [
          1000, // Filtres et produits de lavage  DA18
          1100, // Contenants, boîtes, étiquettes DA19
        ]
        return sumBy(comptesExploitation({ production: 'DA', ligne: ceLignes }), 'montant').plus(
          sumBy(inventairesApprovisionnements({ production: 'DA', ligne: iaLignes }), 'delta_dollars')
        )
      },
    },
  },
  'maple.expenses.imported.maintenance.harvest': {
    testResolver: 'DA26',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'DA', ligne: 3300 }), 'montant'), // Entretien des tubulures
    },
  },
  'maple.expenses.imported.maintenance.processing': {
    testResolver: 'DA27',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'DA', ligne: 3400 }), 'montant'), // Entretien des équipements
    },
  },
  'maple.expenses.imported.maintenance.installations': {
    testResolver: ({ cri }) => cri('DA22').plus(cri('DA25')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          2900, // Scie mécanique (entr,carb) DA22
          3200, // Entretien de l'érablière   DA25
        ]
        return sumBy(comptesExploitation({ production: 'DA', ligne: ceLignes }), 'montant')
      },
    },
  },
  'maple.expenses.imported.insurance.general': {
    testResolver: false, // TODO: Add test resolver
    databaseResolver: {
      resolve: () => new BigNumber(0), // À venir
    },
  },
  'maple.expenses.imported.miscellaneous.lease': {
    testResolver: 'DA32',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'DA', ligne: 3900 }), 'montant'), // Location érablière
    },
  },
  'maple.expenses.imported.miscellaneous.boiling': {
    testResolver: 'DA37',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'DA', ligne: 3950 }), 'montant'), // Dépense bouillage à forf.
    },
  },
  'maple.expenses.imported.miscellaneous.transport': {
    testResolver: false, // TODO: Add test resolver
    databaseResolver: {
      resolve: () => new BigNumber(0), // À venir
    },
  },
  'maple.expenses.imported.miscellaneous.other': {
    testResolver: ({ cri }) => cri('DA20').plus(cri('DA30')).plus(cri('DA33')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          2700, // Publicité            DA20
          3700, // Dépenses pour repas  DA30
          4000, // Autres frais directs DA33
        ]
        return sumBy(comptesExploitation({ production: 'DA', ligne: ceLignes }), 'montant')
      },
    },
  },
})
