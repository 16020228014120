import type React from 'react'

import { L10NProvider } from '../l10n'

import { PointerStateProvider } from './table/PointerStateProvider'
import { ToastProvider } from './toast/ToastProvider'
import { DialogProvider } from './dialog'

export const ComponentProvider: React.FC<React.PropsWithChildren> = ({ children }) => (
  <L10NProvider>
    <DialogProvider>
      <PointerStateProvider>
        <ToastProvider>{children}</ToastProvider>
      </PointerStateProvider>
    </DialogProvider>
  </L10NProvider>
)
