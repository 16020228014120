import { z } from 'zod'

import { DataKeyZod } from '../data-keys'
import { ProductionZod } from '../enums'

import { CellDataOverrideZod } from './cell-data-override-zod'
import { CurrentProductionsZod } from './current-productions-zod'
import { MonetaryValueZod } from './monetary-value-zod'
import { ScenarioInventoryYearStateZod } from './scenario-inventory-state-zod'
import { ScenarioInvestmentStateZod } from './scenario-investment-state-zod'
import { ScenarioLoanStateZod } from './scenario-loan-state-zod'
import { MilkQuotaPeriodZod, ScenarioMilkQuotaStateZod } from './scenario-milk-quota-state-zod'
import { ScenarioPeriodZod } from './scenario-state-data-zod'
import { YearKeyZod } from './year-key-zod'

export const UpdateValueEventZod = z.object({
  type: z.literal('updateValue'),
  key: DataKeyZod,
  year: YearKeyZod,
  override: CellDataOverrideZod.optional(),
  note: z.string().optional(),
})

export const UpdateMultipleValueEventZod = z.object({
  type: z.literal('updateMultipleValue'),
  year: YearKeyZod,
  values: z.record(DataKeyZod, CellDataOverrideZod.or(z.null())),
})

export const UpdateReferenceEventZod = z.object({
  type: z.literal('updateReference'),
  key: DataKeyZod,
  year: YearKeyZod.or(z.enum(['EMPTY', 'latest', 'selected'])),
  override: CellDataOverrideZod.optional(),
  note: z.string().optional(),
})

export const UpdateProjectionGrowthEventZod = z.object({
  type: z.literal('updateProjectionGrowth'),
  key: DataKeyZod,
  override: CellDataOverrideZod.optional(),
  note: z.string().optional(),
})

export const UpdateProjectionYearsEventZod = z.object({
  type: z.literal('updateProjectionYears'),
  projectionYears: YearKeyZod.array(),
})

export const UpdateReferenceIdEventZod = z.object({
  type: z.literal('updateReferenceId'),
  referenceId: z.string(),
  currentProductions: CurrentProductionsZod,
})

export const UpdateSelectedReferenceYearEventZod = z.object({
  type: z.literal('updateSelectedReferenceYear'),
  year: YearKeyZod,
})

export const UpdateProjectStateEventZod = z.object({
  type: z.literal('updateProjectState'),
  enabledProductions: z.array(ProductionZod).readonly().optional(),
  period: ScenarioPeriodZod.optional(),
  archived: z.boolean().optional(),
})

export const UpdateLoanEventZod = ScenarioLoanStateZod.extend({
  type: z.literal('updateLoan'),
  loanId: z.string(),
})

export const AddLoanEventZod = ScenarioLoanStateZod.extend({
  type: z.literal('addLoan'),
  loanId: z.string(),
})

export const DeleteLoanEventZod = z.object({
  type: z.literal('deleteLoan'),
  loanId: z.string(),
})

export const UpdateInvestmentEventZod = z.object({
  type: z.literal('updateInvestment'),
  investmentId: z.string(),
  investment: ScenarioInvestmentStateZod,
})

export const AddInvestmentEventZod = z.object({
  type: z.literal('addInvestment'),
  investmentId: z.string(),
  investment: ScenarioInvestmentStateZod,
})

export const DeleteInvestmentEventZod = z.object({
  type: z.literal('deleteInvestment'),
  investmentId: z.string(),
})

export const UpdateMilkQuotaEventZod = z.object({
  type: z.literal('updateMilkQuota'),
  year: YearKeyZod,
  monthKeys: MilkQuotaPeriodZod.array().readonly(),
  quota: ScenarioMilkQuotaStateZod,
  quotaPrice: MonetaryValueZod.optional(),
})

export const UpdateInventoryEventZod = z.object({
  type: z.literal('updateInventory'),
  key: DataKeyZod,
  year: YearKeyZod,
  inventory: ScenarioInventoryYearStateZod,
  overrides: z.record(DataKeyZod, CellDataOverrideZod.or(z.null())).optional(),
})

export const DataEventZod = z.discriminatedUnion('type', [
  UpdateValueEventZod,
  UpdateMultipleValueEventZod,
  UpdateReferenceEventZod,
  UpdateProjectionGrowthEventZod,
  UpdateProjectionYearsEventZod,
  UpdateReferenceIdEventZod,
  UpdateSelectedReferenceYearEventZod,
  UpdateProjectStateEventZod,
  UpdateLoanEventZod,
  AddLoanEventZod,
  DeleteLoanEventZod,
  UpdateInvestmentEventZod,
  AddInvestmentEventZod,
  DeleteInvestmentEventZod,
  UpdateMilkQuotaEventZod,
  UpdateInventoryEventZod,
])
