import { useMemo } from 'react'
import { useController } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { InvestmentDatedInputTable } from '../InvestmentDatedInputTable/InvestmentDatedInputTable'

import { type InvestmentFormData } from './InvestmentFormZod'

export const InvestmentSubventionForm = () => {
  const intl = useIntl()
  const { field } = useController<InvestmentFormData, 'subvention'>({ name: 'subvention' })
  const labels = useMemo(
    () =>
      [
        intl.formatMessage({ id: 'investment.form.subvention.index' }),
        intl.formatMessage({ id: 'investment.form.subvention.date' }),
        intl.formatMessage({ id: 'investment.form.subvention.value' }),
      ] as const,
    [intl]
  )

  return <InvestmentDatedInputTable {...field} labels={labels} />
}
