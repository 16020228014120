import { type ClientData } from '@via/components'
import { type Remote } from 'comlink'
import { getAnalytics, logEvent } from 'firebase/analytics'

import { waitForRxBudget } from '../../rxdb/rxjs/waitForRxBudget.ts'
import { type AppRxDatabase } from '../../rxdb/types.ts'

import { type BudgetOperator } from './BudgetOperator.ts'
import { type BudgetCreateData, type BudgetUpdateData } from './types.ts'

export class BudgetHandler {
  constructor(
    private readonly appDatabase: AppRxDatabase,
    private readonly operator: Remote<BudgetOperator>
  ) {}

  createBudget = async (budget: BudgetCreateData, client: ClientData) => {
    logEvent(getAnalytics(), 'create_budget')

    const remoteDocument = await this.operator.createBudget(budget, client)
    return waitForRxBudget(this.appDatabase, remoteDocument._id)
  }

  updateBudget = async (budgetId: string, budget: BudgetUpdateData) => this.operator.updateBudget(budgetId, budget)

  acquireBudget = async (budgetId: string) => {
    await this.operator.acquireBudget(budgetId)
    return waitForRxBudget(this.appDatabase, budgetId)
  }

  closeBudget = (budgetId: string) => this.operator.closeBudget(budgetId)

  requestOwnership = (budgetId: string) => this.operator.requestOwnership(budgetId)

  archiveBudget = (budgetId: string) => this.operator.archiveBudget(budgetId)

  duplicateBudget = (budgetId: string) => this.operator.duplicateBudget(budgetId)
}
