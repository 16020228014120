import { type FC, forwardRef, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { type DataKey } from '@via/schema'
import { sortBy } from 'lodash-es'

import { cn } from '../../lib/utils'
import { NumberWithUnitInput } from '../../molecules/NumberWithUnitInput/NumberWithUnitInput.tsx'
import { SheetFormFooter } from '../SheetFormProvider'

import {
  InvestmentInventoryFormProvider,
  type InvestmentInventoryFormProviderProps,
} from './InvestmentInventoryFormProvider.tsx'
import { useInvestmentInventoryRow } from './useInvestmentInventoryRow.ts'

export type InvestmentInventoryFormProps = InvestmentInventoryFormProviderProps

const InvestmentInventoryFormBody: FC<{ readonly dataKey: DataKey }> = ({ dataKey }) => {
  const intl = useIntl()
  const inventoryRow = useInvestmentInventoryRow(dataKey)

  const model = useMemo(
    () =>
      sortBy(Object.entries(inventoryRow?.values ?? {}), '[0]')
        .map(([year, value]) => ({
          year,
          sale: {
            unit: value.sale.value,
            cost: value.saleCost.value,
          },
          purchase: {
            unit: value.purchase.value,
            cost: value.purchaseCost.value,
          },
        }))
        .filter(
          ({ sale, purchase }) =>
            sale.unit !== '0' || sale.cost !== '0' || purchase.unit !== '0' || purchase.cost !== '0'
        ),
    [inventoryRow]
  )

  return (
    <div className="flex flex-col gap-4">
      {model.map(({ year, sale, purchase }) => (
        <div key={year}>
          <div className="">
            <p className="text-md">{year}</p>
          </div>
          <div className="grid grid-cols-2 gap-2 py-2">
            <p className="select-none justify-self-center text-xs font-bold">
              {intl.formatMessage({ id: 'investment.form.inventory.purchase' })}
            </p>
            <p className="select-none justify-self-center text-xs font-bold">
              {intl.formatMessage({ id: 'investment.form.inventory.sale' })}
            </p>
            <NumberWithUnitInput
              value={purchase?.unit}
              disabled
              readOnly
              variant="gray"
              unit="head-count"
              dimension="sm"
            />
            <NumberWithUnitInput value={sale?.unit} disabled readOnly variant="gray" unit="head-count" dimension="sm" />
            <NumberWithUnitInput
              value={purchase?.cost}
              disabled
              readOnly
              variant="gray"
              unit="currency"
              dimension="sm"
            />
            <NumberWithUnitInput value={sale?.cost} disabled readOnly variant="gray" unit="currency" dimension="sm" />
          </div>
        </div>
      ))}
    </div>
  )
}

export const InvestmentInventoryForm = forwardRef<HTMLFormElement, InvestmentInventoryFormProps>(
  ({ className, dataKey, ...props }, ref) => (
    <InvestmentInventoryFormProvider
      className={cn('w-[614px] bg-gray-100', className)}
      ref={ref}
      dataKey={dataKey}
      {...props}>
      <div className="flex flex-col gap-6 pb-6">
        <main className="bg-gray-100 pb-6 pl-6 pr-4 pt-4">
          <InvestmentInventoryFormBody dataKey={dataKey} />
        </main>
      </div>

      <SheetFormFooter />
    </InvestmentInventoryFormProvider>
  )
)
