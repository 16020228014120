import React from 'react'

import * as RadixDialog from '@radix-ui/react-dialog'

import { cn } from '../../lib/utils'

export const Dialog = RadixDialog.Root
export const DialogPortal = RadixDialog.Portal
export const DialogTitle = RadixDialog.Title
export const DialogDescription = RadixDialog.Description

export const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof RadixDialog.Overlay>,
  React.ComponentPropsWithoutRef<typeof RadixDialog.Overlay>
>(({ className, ...props }, ref) => (
  <RadixDialog.Overlay ref={ref} className={cn('fixed inset-0 z-[100] bg-black opacity-50', className)} {...props} />
))

export const DialogTrigger = React.forwardRef<
  React.ElementRef<typeof RadixDialog.Trigger>,
  React.ComponentPropsWithoutRef<typeof RadixDialog.Trigger>
>(({ ...props }, ref) => <RadixDialog.Trigger ref={ref} {...props} />)

export const DialogContent = React.forwardRef<
  React.ElementRef<typeof RadixDialog.Content>,
  React.ComponentPropsWithoutRef<typeof RadixDialog.Content>
>(({ className, ...props }, ref) => (
  <RadixDialog.Content
    ref={ref}
    className={cn(
      'fixed left-2/4 top-2/4 z-[110] max-h-[100%] w-[608px] max-w-[100%] -translate-x-2/4 -translate-y-2/4 select-none overflow-hidden rounded-3xl bg-white focus:outline-none',
      className
    )}
    {...props}
  />
))
