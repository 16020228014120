import { type CurrentProductionsZod, type ProductionType } from '@via/schema'
import { type ReadonlyDeep } from 'type-fest'
import { type z } from 'zod'

export type CurrentProductions = z.infer<typeof CurrentProductionsZod>

export const getEnabledProductions = (
  currentProductions: ReadonlyDeep<CurrentProductions>,
  enabledProductions: ReadonlyArray<ProductionType> = []
) => [...new Set([...Object.values(currentProductions).flat(), ...enabledProductions])]
