import type React from 'react'

import { BudgetListView } from '@via/components'

import { useBudgetListPage } from './useBudgetListPage.ts'

export const BudgetListPage: React.FC = () => {
  const props = useBudgetListPage()
  return <BudgetListView {...props} />
}
