import { type FormHTMLAttributes, forwardRef, useCallback } from 'react'

import { type DataKeyDependencies, dataKeyDependenciesRecord, type InventoryDataKey, type YearKey } from '@via/schema'
import { type Except } from 'type-fest'
import { type z } from 'zod'

import { type ZodFormProviderProps, ZodFormSubmitProvider } from '../../atoms/Form/ZodFormSubmitProvider.tsx'

import { type InventoryProjectionFormData, InventoryProjectionFormZod } from './InventoryProjectionFormZod.ts'

export interface InventoryProjectionUpdateValue<TDataKey extends InventoryDataKey = InventoryDataKey> {
  readonly dataKey: InventoryDataKey
  readonly year: YearKey
  readonly inventory: {
    readonly purchase?: string
    readonly purchaseCost?: string
    readonly sale?: string
    readonly saleCost?: string
    readonly note?: string
  }
  readonly overrides: Record<DataKeyDependencies<TDataKey>, string | null>
}

export interface InventoryProjectionWidgetFormProviderProps
  extends Except<
      ZodFormProviderProps<typeof InventoryProjectionFormZod>,
      'schema' | 'onFormSubmit' | 'onFormApply' | 'defaultValues' | 'values'
    >,
    FormHTMLAttributes<HTMLFormElement> {
  readonly dataKey: InventoryDataKey
  readonly year: YearKey
  readonly values: z.input<typeof InventoryProjectionFormZod>
  readonly onConfirm: (data: InventoryProjectionUpdateValue) => Promise<void>
  readonly onApply: (data: InventoryProjectionUpdateValue) => Promise<void>
}

const fromForm = <TDataKey extends InventoryDataKey>(
  values: InventoryProjectionFormData,
  dataKey: InventoryDataKey,
  year: YearKey
): InventoryProjectionUpdateValue<TDataKey> => {
  const { unitValue: unitValueDataKey } = dataKeyDependenciesRecord(dataKey)
  return {
    dataKey,
    year,
    inventory: {
      ...(values.purchase ? { purchase: values.purchase } : {}),
      ...(values.purchaseCost ? { purchaseCost: values.purchaseCost } : {}),
      ...(values.sale ? { sale: values.sale } : {}),
      ...(values.saleCost ? { saleCost: values.saleCost } : {}),
      ...(values.note ? { note: values.note } : {}),
    },
    overrides: {
      [unitValueDataKey]: values.unitValue ?? null,
    } as Record<DataKeyDependencies<TDataKey>, string | null>,
  }
}

export const InventoryProjectionWidgetFormProvider = forwardRef<
  HTMLFormElement,
  InventoryProjectionWidgetFormProviderProps
>(({ dataKey, year, children, onConfirm, values, onApply, ...props }, ref) => {
  const onFormSubmit = useCallback(
    async (data: InventoryProjectionFormData) => {
      await onConfirm(fromForm(data, dataKey, year))
    },
    [onConfirm, dataKey, year]
  )

  const onFormApply = useCallback(
    async (data: InventoryProjectionFormData) => {
      await onApply(fromForm(data, dataKey, year))
    },
    [onApply, dataKey, year]
  )

  return (
    <ZodFormSubmitProvider<typeof InventoryProjectionFormZod>
      {...props}
      defaultValues={InventoryProjectionFormZod.parse(values)}
      onFormSubmit={onFormSubmit}
      onFormApply={onFormApply}
      schema={InventoryProjectionFormZod}
      ref={ref}>
      {children}
    </ZodFormSubmitProvider>
  )
})
