import { BigNumber } from 'bignumber.js'

import { type MilkExpensesDependencies } from './milk-expenses-dependencies'

export const computeMilkProductionExpenses = ({
  fatContent = new BigNumber(0), // kg/hl
  proteinContent = new BigNumber(0), // kg/hl
  lactoseContent = new BigNumber(0), // kg/hl
  defense = new BigNumber(0), // $/kg
  advertising = new BigNumber(0), // $/kg
  development = new BigNumber(0), // $/kg
  marketing = new BigNumber(0), // $/hl
  transport = new BigNumber(0), // $/hl
}: Partial<Record<keyof typeof MilkExpensesDependencies, BigNumber>>): BigNumber => {
  const totalSolidContent = BigNumber.sum(fatContent, proteinContent, lactoseContent)
  const priceForSolids = BigNumber.sum(defense, advertising, development)
  return BigNumber.sum(totalSolidContent.times(priceForSolids), marketing, transport)
}
