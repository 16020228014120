import { BigNumber } from 'bignumber.js'
import { type ConditionalKeys } from 'type-fest'

import { type ConditionalTestResolverFunction } from './types'

export const sumBy = <T>(items: T[], key: ConditionalKeys<T, BigNumber>) =>
  items.reduce((total, line) => total.plus(line[key] as BigNumber), new BigNumber(0))

export const isFermeBudgitel2021: ConditionalTestResolverFunction = ({ dossierId, year }) =>
  dossierId === 4506 && year === 2021

export const isFermeBudgitel2022: ConditionalTestResolverFunction = ({ dossierId, year }) =>
  dossierId === 4506 && year === 2022
