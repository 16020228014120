import { type FC } from 'react'
import { useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { Label } from '../../atoms/Label/Label'
import { Radio, RadioGroup } from '../../atoms/Radio/Radio'
import { DateInputFormField } from '../../molecules/DateInputFormField/DateInputFormField'
import { InputFormField } from '../../molecules/InputFormField/InputFormField'
import { SelectFormField } from '../../molecules/SelectFormField/SelectFormField'
import { SwitchFormField } from '../../molecules/SwitchFormField/SwitchFormField'
import { type BudgetData } from '../../types'

import { type ReportFormData } from './ReportFormZod.ts'

export interface ReportConfigurationFormTypeProps {
  readonly budget: BudgetData
  readonly coverImages: Array<{ url: string; name: string; fullPath: string }>
  readonly groupImages: Array<{ url: string; name: string; fullPath: string }>
}

export const ReportConfigurationFormType: FC<ReportConfigurationFormTypeProps> = ({
  budget,
  coverImages,
  groupImages,
}) => {
  const intl = useIntl()

  const displayDate = useWatch<ReportFormData>({ name: 'displayDate' })
  const withCoverPage = useWatch<ReportFormData>({ name: 'withCoverPage' })

  const logoOptions = groupImages.map((groupImage) => ({
    text: groupImage.name,
    value: groupImage.fullPath,
  }))

  return (
    <div>
      <div id="type" className="absolute top-[-132px]" />
      <InputFormField<ReportFormData, 'name'>
        name="name"
        className="mt-16"
        labelDimension="xl"
        label={intl.formatMessage({ id: 'reportConfiguration.form.name' })}
      />
      <div className="mt-8 w-[1068px] border border-gray-300 bg-gray-50 py-8 pl-[71px]">
        <div className="flex flex-col gap-8 pb-11">
          <SwitchFormField<ReportFormData, 'withCoverPage'>
            name="withCoverPage"
            label={intl.formatMessage({ id: 'reportConfiguration.form.withCoverPage' })}
          />
          {withCoverPage && (
            <div className="flex flex-col gap-4">
              <Label className="text-xl">{intl.formatMessage({ id: 'reportConfiguration.form.cover.image' })}</Label>
              <FormField<ReportFormData, 'coverImagePath'>
                name="coverImagePath"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex gap-4"
                        disabled={field.disabled}>
                        {coverImages.map((coverImage) => (
                          <div key={coverImage.fullPath} className="flex flex-col items-center gap-2">
                            <img src={coverImage.url} alt={coverImage.name} className="h-40 w-16 object-cover" />
                            <Radio value={coverImage.fullPath} id={coverImage.url}></Radio>
                          </div>
                        ))}
                        <div className="flex flex-col items-center gap-2">
                          <div className="flex h-40 w-16 items-center justify-center border border-gray-300 bg-green-600 object-cover">
                            <Label className="text-xxs text-white">
                              {intl.formatMessage({ id: 'reportConfiguration.form.cover.image.none' })}
                            </Label>
                          </div>
                          <Radio value=""></Radio>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          )}
        </div>

        {groupImages.length > 0 && (
          <div className="flex items-center gap-4 pb-9">
            <Label className="text-xs">{intl.formatMessage({ id: 'reportConfiguration.form.logo' })}</Label>
            <div className="w-32">
              <SelectFormField<ReportFormData, 'logoImagePath'>
                name="logoImagePath"
                placeholder={intl.formatMessage({ id: 'reportConfiguration.form.logo' })}
                options={logoOptions}
              />
            </div>
          </div>
        )}

        <div className="flex flex-col gap-4 pr-[358px]">
          <InputFormField name="title" label={intl.formatMessage({ id: 'reportConfiguration.form.title' })} />
          <div className="flex items-center gap-4">
            <SwitchFormField<ReportFormData, 'displayDate'>
              name="displayDate"
              label={intl.formatMessage({ id: 'reportConfiguration.form.displayDate' })}
              className="pt-1.5"
            />
            <DateInputFormField name="date" label="" className="w-32" disabled={!displayDate} />
          </div>
          <div className="flex items-center gap-4">
            <SwitchFormField<ReportFormData, 'displayTechnician'>
              name="displayTechnician"
              label={intl.formatMessage({ id: 'reportConfiguration.form.technician' })}
              disabled={!budget.users.technician}
            />
            <Label className="pt-1 font-bold">{budget.users.technician?.name ?? ''}</Label>
          </div>
          <div className="flex items-center gap-4">
            <SwitchFormField<ReportFormData, 'displayCollaborator'>
              name="displayCollaborator"
              label={intl.formatMessage({ id: 'reportConfiguration.form.collaborator' })}
              disabled={!budget.users.collaborator}
            />
            <Label className="pt-1 font-bold">{budget.users.collaborator?.name ?? ''}</Label>
          </div>
        </div>
      </div>
    </div>
  )
}
