import { type DependencyList, useEffect } from 'react'

import { handleNotAwaitedPromise } from '@via/frontend-sentry'

export const useAsyncEffect = (effect: () => Promise<void>, deps: DependencyList) => {
  useEffect(() => {
    handleNotAwaitedPromise(effect())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
