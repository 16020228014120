import { type DependencyList, useCallback, useEffect, useRef } from 'react'

import { captureMessage } from '@sentry/react'
import { useCallbackRef, useLoadingDialog, useScenario } from '@via/components'

import { useIsComputingScenario } from './useIsComputingScenario.ts'

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unnecessary-type-parameters
export const useComputeStateOperationCallback = <RT, TArgs extends any[], T extends (...args: TArgs) => Promise<RT>>(
  callback: T,
  deps: DependencyList,
  label: string,
  timeout = 30_000
) => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const computeLoadingState = useRef<'none' | 'waiting' | 'started' | 'done'>('none')
  const scenario = useScenario()
  const isComputingCurrentScenario = useIsComputingScenario(scenario._id)
  const { setLoadingDialogState } = useLoadingDialog()
  const operation = useCallbackRef(callback, deps)

  useEffect(() => {
    if (computeLoadingState.current === 'waiting' && isComputingCurrentScenario) {
      computeLoadingState.current = 'started'
    }

    if (computeLoadingState.current === 'started' && !isComputingCurrentScenario) {
      computeLoadingState.current = 'none'
      clearTimeout(timeoutRef.current)
      setLoadingDialogState({ open: false, label: '' })
    }
  }, [isComputingCurrentScenario, setLoadingDialogState])

  return useCallback(
    (async (...args: TArgs) => {
      computeLoadingState.current = 'waiting'
      timeoutRef.current = setTimeout(() => {
        captureMessage(`Operation ${label} timed out after ${timeout}ms`)
        setLoadingDialogState({ open: false, label: '' })
      }, timeout)
      setLoadingDialogState({ open: true, label })

      try {
        return await operation(...args)
      } catch (error) {
        computeLoadingState.current = 'none'
        clearTimeout(timeoutRef.current)
        setLoadingDialogState({ open: false, label: '' })
        throw error
      }
    }) as T,
    [setLoadingDialogState, label, operation]
  )
}
