import { type FC } from 'react'

import { useFormComputationState } from '../../atoms/FormComputation/useFormComputationState.ts'
import { MonetaryYearFormFieldTable } from '../MonetaryYearFormFieldTable'

import { type MilkExpensesFormComputationState } from './MilkExpensesFormComputationProvider.tsx'
import { type MilkExpensesFormTableBlueprintKeys, useMilkExpensesBlueprint } from './useMilkExpensesBlueprint.tsx'

export const MilkExpensesFormTable: FC = () => {
  const blueprint = useMilkExpensesBlueprint()
  const values = useFormComputationState<MilkExpensesFormComputationState>()

  return <MonetaryYearFormFieldTable<MilkExpensesFormTableBlueprintKeys> blueprint={blueprint} values={values} />
}
