import { useCallback } from 'react'

import { getAuth, signInWithCustomToken } from 'firebase/auth'

import { type SignInApiVariables, useSignIn } from '../api/useSignIn.ts'

export const useFirebaseSignIn = () => {
  const { mutateAsync } = useSignIn()
  const signIn = useCallback(
    async (variables: SignInApiVariables) => {
      const customToken = await mutateAsync(variables)
      return signInWithCustomToken(getAuth(), customToken)
    },
    [mutateAsync]
  )
  return { signIn }
}
