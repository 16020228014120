import { BigNumber } from 'bignumber.js'

import { type BigNumberCellData } from './types'

export const toBigNumberRecord = <T extends string>(data: Partial<Record<T, string>>): Record<T, BigNumber> =>
  Object.fromEntries<BigNumber>(
    Object.entries<string | undefined>(data).flatMap(([key, value]) => (value ? [[key, new BigNumber(value)]] : []))
  ) as Record<T, BigNumber>

export const toBigNumberCellDataRecord = <T extends string>(
  data: Partial<Record<T, string>>
): Record<T, BigNumberCellData> =>
  Object.fromEntries<BigNumberCellData>(
    Object.entries<string | undefined>(data).flatMap(([key, value]) =>
      value ? [[key, { value: new BigNumber(value) }]] : []
    )
  ) as Record<T, BigNumberCellData>

export const fromBigNumberRecord = <T extends string>(data: Partial<Record<T, BigNumber>>): Record<T, string> =>
  Object.fromEntries<string>(
    Object.entries<BigNumber | undefined>(data).flatMap(([key, value]) => (value ? [[key, value.toString()]] : []))
  ) as Record<T, string>
