import { type collections } from '@via/frontend-schema'
import { type UserUpdate } from '@via/schema'
import { type Remote } from 'comlink'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { type SchemaDocumentOutput } from 'zod-firebase'

import { type AppRxDatabase } from '../../rxdb/types.ts'

import { type ScenarioOperator } from './ScenarioOperator.ts'
import { ScenarioRxDatabasesManager } from './ScenarioRxDatabasesManager.ts'
import { type ScenarioCreateData, type ScenarioUpdateData, type ScenarioUserEvent } from './types.ts'

export class ScenarioHandler {
  readonly #databasesManager: ScenarioRxDatabasesManager

  constructor(
    private readonly appDatabase: AppRxDatabase,
    private readonly operator: Remote<ScenarioOperator>
  ) {
    this.#databasesManager = new ScenarioRxDatabasesManager(this.appDatabase)
  }

  loadScenarioDatabase = async (budgetId: string, scenarioId: string) =>
    this.#databasesManager.scenarioDatabase(budgetId, scenarioId)

  pullScenarioCurrentStateFromFirestore = async (
    scenario: SchemaDocumentOutput<typeof collections.budgets.scenarios>
  ) => this.operator.pullScenarioCurrentStateFromFirestore(scenario)

  createScenario = async (data: ScenarioCreateData) => {
    logEvent(getAnalytics(), 'create_scenario')
    return this.operator.createScenario(data)
  }

  updateScenario = async (budgetId: string, scenarioId: string, data: ScenarioUpdateData) =>
    this.operator.updateScenario(budgetId, scenarioId, data)

  duplicateScenario = async (budgetId: string, scenarioId: string, usesUpdate: UserUpdate) =>
    this.operator.duplicateScenario(budgetId, scenarioId, usesUpdate)

  addScenarioEvent = async (budgetId: string, scenarioId: string, event: ScenarioUserEvent) =>
    this.operator.addScenarioEvent(budgetId, scenarioId, event)
}
