import { useSessionStorageValue } from '@react-hookz/web/useSessionStorageValue/index.js'

export interface UsePersistedTableFilterOptions<T> {
  key: string
  defaultValue?: T
}

export const usePersistedTableFilter = <T extends string = string>({
  key,
  defaultValue,
}: UsePersistedTableFilterOptions<T>): { selectedFilter: T | undefined; onFilterChange: (value: T) => void } => {
  const { value, set } = useSessionStorageValue<T>(key, { defaultValue })

  return {
    selectedFilter: value,
    onFilterChange: set,
  }
}
