import type React from 'react'

import { BudgetListContext } from '@via/components'

import { useLocalBudgetListSnapshotState } from '../../rxdb/localSnapshot/useLocalBudgetListSnapshotState.ts'

export const OnlineBudgetListProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const state = useLocalBudgetListSnapshotState()
  return <BudgetListContext.Provider value={state}>{children}</BudgetListContext.Provider>
}
