import { type ClientInformationZod } from '@via/schema'
import { type JsonSchema } from 'rxdb'
import { type z } from 'zod'

import { ClientContactJsonSchema } from './clientContactJsonSchema.ts'

export const ClientInformationJsonSchema = {
  type: 'object',
  required: ['clientId', 'code', 'address', 'mainContact'],
  properties: {
    viagritelId: {
      type: 'string',
    },
    code: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    nim: {
      type: 'string',
    },
    latestYear: {
      type: 'number',
    },
    startOfYearOffset: {
      type: 'number',
    },
    currentProductions: {
      type: 'object',
    },
    groups: {
      type: 'object',
    },
    address: {
      type: 'object',
      required: ['streetAddress', 'city', 'province', 'postalCode'],
      properties: {
        streetAddress: {
          type: 'string',
        },
        city: {
          type: 'string',
        },
        province: {
          type: 'string',
        },
        postalCode: {
          type: 'string',
        },
      },
    } satisfies JsonSchema<z.output<typeof ClientInformationZod>['address']>,
    users: {
      type: 'object',
    } satisfies JsonSchema<z.output<typeof ClientInformationZod>['users']>,
    contactInformation: ClientContactJsonSchema,
  },
} as const satisfies JsonSchema<z.output<typeof ClientInformationZod>>
