import { createFileRoute } from '@tanstack/react-router'

import { MilkResultsPage } from '../pages/milkResults/MilkResultsPage.tsx'
import { setScenarioCurrentPage } from '../pages/scenarioUpdate/utils/scenarioPage.ts'

export const Route = createFileRoute('/budgets/$budgetId/scenarios/$scenarioId/milk-results')({
  component: MilkResultsPage,
  loader: ({ params }) => {
    setScenarioCurrentPage(params.budgetId, params.scenarioId, 'milk-results')
  },
})
