import { type RowData } from '../types'

export const rowId = (row: RowData) => {
  switch (row.computationType) {
    case 'finance-loan':
      return row.loanId
    case 'finance-loan-investment':
      return `investment-${row.loanId}`
    case 'finance-asset':
    case 'finance-asset-inventory':
      return `asset-${row.assetId}`
    case 'finance-investment':
    case 'finance-investment-inventory':
      return row.investmentId
    default:
      return row.key
  }
}
