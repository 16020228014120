import React from 'react'

import { cn } from '../../lib/utils'
import { AppHeader, type AppHeaderProps } from '../Header/AppHeader'
import { HEADER_HEIGHT_PX } from '../Header/Header'

export interface MainLayoutProps
  extends Pick<AppHeaderProps, 'currentNavigationId' | 'avatar' | 'offline'>,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  readonly version?: string
}

export const MainLayout = React.forwardRef<HTMLDivElement, MainLayoutProps>(
  ({ currentNavigationId, avatar, offline, version, className, children, ...props }, ref) => (
    <div className={cn('flex min-h-screen w-full flex-col items-start bg-sage-50', className)} ref={ref} {...props}>
      <AppHeader
        currentNavigationId={currentNavigationId}
        avatar={avatar}
        offline={offline}
        className="fixed top-0 z-10"
      />

      <div
        className="fixed z-10 w-full overflow-auto"
        style={{
          top: HEADER_HEIGHT_PX,
          height: `calc(100% - ${HEADER_HEIGHT_PX}px)`,
        }}>
        {children}
      </div>

      {version && (
        <p className="fixed bottom-3 left-1 flex w-8 select-none flex-col items-center">
          <span className="text-xxs font-normal leading-tight tracking-[-0.1px]">Version</span>
          <span className="text-xs font-medium leading-none tracking-[-0.36px]">{version}</span>
        </p>
      )}
    </div>
  )
)
