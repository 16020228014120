import { type FC, type PropsWithChildren, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { useCallbackWithConfirmation } from '../dialog'

import { type InvestmentOperations, InvestmentOperationsContext } from './InvestmentOperationsContext'

export type InvestmentOperationProviderProps = InvestmentOperations

export const InvestmentOperationsProvider: FC<PropsWithChildren<InvestmentOperationProviderProps>> = ({
  onAddInvestment,
  onUpdateInvestment,
  onDeleteInvestment,
  children,
}) => {
  const intl = useIntl()

  const onDeleteWithConfirmation = useCallbackWithConfirmation<typeof onDeleteInvestment>(
    (investmentId, onCompleted) => onDeleteInvestment(investmentId, onCompleted),
    {
      title: intl.formatMessage({ id: 'investment.action.erase.confirmationDialog.title' }),
      subtitle: intl.formatMessage({ id: 'investment.action.erase.confirmationDialog.subTitle' }),
    },
    [intl, onDeleteInvestment]
  )

  const providerValue = useMemo(
    () => ({
      onAddInvestment,
      onUpdateInvestment,
      onDeleteInvestment: onDeleteWithConfirmation,
    }),
    [onAddInvestment, onDeleteWithConfirmation, onUpdateInvestment]
  )

  return <InvestmentOperationsContext.Provider value={providerValue}>{children}</InvestmentOperationsContext.Provider>
}
