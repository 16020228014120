import { useCallback, useReducer } from 'react'

import { useCustomCompareEffect } from '@react-hookz/web'
import { type DocumentData, type DocumentReference, onSnapshot } from 'firebase/firestore'

import { type DocumentReducerState, DocumentReducerStateInitial, DocumentStateReducer } from './documentStateReducer.ts'

export interface UseDocumentOptions<AppModelType = DocumentData, DbModelType extends DocumentData = DocumentData> {
  ref: DocumentReference<AppModelType, DbModelType> | null | undefined
  onError?(error: unknown): void
}

export type UseDocumentResult<
  AppModelType = DocumentData,
  DbModelType extends DocumentData = DocumentData,
> = DocumentReducerState<AppModelType, DbModelType>

export const useDocument = <AppModelType = DocumentData, DbModelType extends DocumentData = DocumentData>({
  ref,
  onError,
}: UseDocumentOptions<AppModelType, DbModelType>): UseDocumentResult<AppModelType, DbModelType> => {
  const [state, dispatch] = useReducer(DocumentStateReducer<AppModelType, DbModelType>(), DocumentReducerStateInitial)

  const handleError = useCallback(
    (error: unknown) => {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error('useDocument', error)
      }
      onError?.(error)
      // TODO: Sentry
      dispatch({ type: 'error', error })
    },
    [onError]
  )

  useCustomCompareEffect(
    () => {
      if (!ref) {
        dispatch({ type: 'disable' })
        return
      }
      // eslint-disable-next-line consistent-return
      return onSnapshot(
        ref,
        (snapshot) => {
          const exists = snapshot.exists()
          if (!exists) {
            dispatch({ type: 'doesNotExist', snapshot })
            return
          }
          try {
            const data = snapshot.data()
            dispatch({ type: 'data', snapshot, data })
          } catch (error) {
            handleError(error)
          }
        },
        handleError
      )
    },
    [ref],
    (prev, next) => prev[0]?.path === next[0]?.path
  )

  return state
}
