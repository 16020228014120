import { createRouter } from '@tanstack/react-router'

import { routeTree } from './routeTree.gen.ts'

export const router = createRouter({
  routeTree,
  defaultPreload: false,
  context: {
    queryClient: undefined!,
    auth: undefined!,
    appDatabase: undefined!,
    appRxState: undefined!,
    appWorker: undefined!,
    budgetHandler: undefined!,
    scenarioHandler: undefined!,
    budgetReportsHandler: undefined!,
  },
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
