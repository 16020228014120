import { useObservableState } from 'observable-hooks'
import { map } from 'rxjs'

import { CollectionReducerStateInitial } from '../../firestore/hooks/collectionStateReducer.ts'
import { type FirestoreClientDocument } from '../../firestore/snapshot/FirestoreClientSnaphotProvider.tsx'
import { appRxDatabase } from '../app/appRxDatabase.ts'
import { type SnapshotRxDocument } from '../types.ts'
import { rxLocalDocumentData } from '../utils/rxLocalDocumentData.ts'

const clients$ = appRxDatabase.snapshots
  .getLocal$<SnapshotRxDocument<FirestoreClientDocument>>('clients')
  .pipe(map((a) => (a ? rxLocalDocumentData(a) : CollectionReducerStateInitial)))

export const useLocalClientListSnapshotState = () => useObservableState(clients$, CollectionReducerStateInitial)
