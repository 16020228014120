import { createFileRoute } from '@tanstack/react-router'

import { CropsResultsPage } from '../pages/cropsResults/CropsResultsPage.tsx'
import { setScenarioCurrentPage } from '../pages/scenarioUpdate/utils/scenarioPage.ts'

export const Route = createFileRoute('/budgets/$budgetId/scenarios/$scenarioId/crops-results')({
  component: CropsResultsPage,
  onEnter: ({ params }) => {
    setScenarioCurrentPage(params.budgetId, params.scenarioId, 'crops-results')
  },
})
