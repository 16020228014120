import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

import * as SelectPrimitive from '@radix-ui/react-select'
import { cva, type VariantProps } from 'class-variance-authority'
import { type Except } from 'type-fest'

import { cn } from '../../lib/utils'
import { Divider } from '../Divider/Divider'
import { Icons } from '../Icons'

const SelectValue = SelectPrimitive.Value

const selectTriggerVariants = cva(
  'flex w-full gap-1 items-center justify-between whitespace-nowrap rounded border border-gray-200 bg-gray-50 px-2 text-black placeholder:text-black  focus:outline-none disabled:cursor-not-allowed disabled:border-gray-50 disabled:bg-gray-100 disabled:text-gray-400 disabled:shadow-none [&>span]:line-clamp-1',
  {
    variants: {
      variant: {
        default: 'hover:border-blue-500 focus:border-blue-500',
        calculate: 'hover:border-orange-300 focus:border-orange-300',
        external: 'hover:border-green-300 focus:border-green-300',
      },
      dimension: {
        sm: 'h-8 text-sm font-normal leading-none tracking-[-0.28px]',
        xs: 'h-6 text-xs font-normal leading-none tracking-[-0.24px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      dimension: 'sm',
    },
  }
)

export const SelectTrigger = forwardRef<
  ElementRef<typeof SelectPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & VariantProps<typeof selectTriggerVariants>
>(({ className, children, variant = 'default', dimension = 'sm', ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(selectTriggerVariants({ variant, dimension }), className)}
    {...props}>
    {children}
    <SelectPrimitive.Icon asChild>
      <Icons.ChevronDown className="size-3" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
))

const selectContentVariants = cva(
  'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 relative z-50 max-h-96 min-w-32 overflow-hidden rounded border bg-white',
  {
    variants: {
      variant: {
        default: 'border-blue-500',
        calculate: 'border-orange-300',
        external: 'border-green-300',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export const SelectContent = forwardRef<
  ElementRef<typeof SelectPrimitive.Content>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & VariantProps<typeof selectContentVariants>
>(({ className, children, position = 'popper', variant, ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        selectContentVariants({ variant }),
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className
      )}
      position={position}
      {...props}>
      <SelectPrimitive.Viewport
        className={cn(
          'p-1',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]'
        )}>
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))

const selectItemVariants = cva(
  'relative flex h-8 w-full cursor-default select-none items-center rounded px-3 text-sm font-medium leading-none tracking-[-0.42px] outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50 text-gray-700',
  {
    variants: {
      variant: {
        default: 'focus:bg-blue-100',
        calculate: 'focus:bg-orange-100',
        external: 'focus:bg-green-100',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export const SelectItem = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & VariantProps<typeof selectItemVariants>
>(({ className, children, variant, ...props }, ref) => (
  <SelectPrimitive.Item ref={ref} className={cn(selectItemVariants({ variant }), className)} {...props}>
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
))

export interface SelectOption<T extends string = string> {
  text: ReactNode
  selectedText?: string
  disabled?: boolean
  value: T
}

export interface DividerOption {
  divider: true
}

export interface SelectBodyProps {
  readonly options: Array<SelectOption | DividerOption>
  readonly variant?: 'default' | 'calculate' | 'external'
}

export type SelectProps<T extends string = string> = Except<
  ComponentPropsWithoutRef<typeof SelectPrimitive.Root>,
  'value' | 'onValueChange'
> &
  SelectBodyProps & {
    readonly placeholder: string
    readonly dimension?: 'sm' | 'xs'
    readonly className?: string
    readonly initialValue?: string
    readonly value?: T | null
    readonly onValueChange?: (value: T) => void
    readonly useValueFromProps?: boolean
  }

const isDivider = (option: SelectOption | DividerOption): option is DividerOption => !('value' in option)

const getOption = (options: Array<SelectOption | DividerOption>, value: string) =>
  options.find((option) => !isDivider(option) && option.value === value) as SelectOption | undefined

export const SelectBody = ({ variant, options }: SelectBodyProps) => (
  <SelectContent variant={variant} className="z-[120]">
    {options.map((option, index) =>
      isDivider(option) ? (
        <Divider key={`divider-${index}`} />
      ) : (
        <SelectItem disabled={option.disabled} key={option.value} value={option.value} variant={variant}>
          {option.text}
        </SelectItem>
      )
    )}
  </SelectContent>
)

export const Select = <T extends string = string>({
  placeholder,
  options,
  variant = 'default',
  dimension = 'sm',
  className,
  onValueChange,
  initialValue,
  useValueFromProps = false,
  ...props
}: SelectProps<T>) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(initialValue)

  const internalOnValueChange = useCallback(
    (internalValue: T) => {
      onValueChange?.(internalValue)

      if (!useValueFromProps) {
        setSelectedOption(internalValue)
      }
    },
    [onValueChange, useValueFromProps]
  )

  const selectedValue = useMemo(() => {
    if (useValueFromProps) {
      return props.value ?? ''
    }

    return selectedOption ?? ''
  }, [props.value, selectedOption, useValueFromProps])

  const selectedValueText = useMemo(() => {
    const option = getOption(options, (useValueFromProps ? props.value : selectedOption) ?? '')
    return option?.selectedText ?? option?.text ?? ''
  }, [options, props.value, selectedOption, useValueFromProps])

  return (
    <SelectPrimitive.Root {...props} value={selectedValue} onValueChange={internalOnValueChange}>
      <SelectTrigger className={className} variant={variant} dimension={dimension}>
        <SelectValue placeholder={placeholder}>{selectedValueText}</SelectValue>
      </SelectTrigger>
      <SelectBody options={options} variant={variant} />
    </SelectPrimitive.Root>
  )
}
