import { createContext } from 'react'

import { tupleToRecord } from '@via/compute'
import { type MilkQuotaComputationType, MilkQuotaComputationTypeZod } from '@via/schema'

export interface MilkQuotaFormComputationState {
  values: Record<MilkQuotaComputationType, Record<string, number>>
  startingBalance: number // kgMG/jr
  endBalance: number // kgMG/jr
  allowedProduction: number // kgMG/jr
  allowedVolume: number // hl
  kilogramOfFatPerHectoliter: number // kgMG/hl
  actualProduction: number // kgMG/jr
  actualProductionInHectoliters: number // hl
  quotaPrice: number // kgMG/jr
}

export const initialState = {
  values: tupleToRecord(MilkQuotaComputationTypeZod.options, () => ({})),
  startingBalance: 0,
  endBalance: 0,
  allowedProduction: 0,
  allowedVolume: 0,
  kilogramOfFatPerHectoliter: 0,
  actualProduction: 0,
  actualProductionInHectoliters: 0,
  quotaPrice: 0,
} as const satisfies MilkQuotaFormComputationState

export const MilkQuotaFormComputationContext = createContext<MilkQuotaFormComputationState>(initialState)
