import React from 'react'

import { cn } from '../../lib/utils'

export interface MonetaryTabTriggerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly active?: boolean
}

export const MonetaryTabTrigger = React.forwardRef<HTMLButtonElement, MonetaryTabTriggerProps>(
  ({ className, active, ...props }, ref) => (
    <button
      type="button"
      ref={ref}
      className={cn(
        'inline-flex h-6 select-none items-center justify-center whitespace-nowrap rounded-t-lg border-x border-t border-sky-800 px-3 pt-1 text-sm font-medium leading-none tracking-[-0.42px] ring-offset-sky-800 transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-sky-800 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        active ? 'bg-sky-800 text-white' : 'bg-white text-gray-700',
        className
      )}
      {...props}
    />
  )
)
