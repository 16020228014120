import { createContext } from 'react'

import { type LoanFormSubmitData } from '../../organisms/LoanDetail'

export interface LoanOperations {
  readonly onAddLoan: (value: LoanFormSubmitData) => Promise<void> | void
  readonly onUpdateLoan: (value: LoanFormSubmitData) => Promise<void> | void
  readonly onDeleteLoan: (id: string, onCompleted?: () => void) => Promise<void> | void
}

export const LoanOperationsContext = createContext<LoanOperations>({
  onAddLoan: () => Promise.reject(new Error('LoanOperationsContext not provided')),
  onUpdateLoan: () => Promise.reject(new Error('LoanOperationsContext not provided')),
  onDeleteLoan: () => Promise.reject(new Error('LoanOperationsContext not provided')),
})
