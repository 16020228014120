import { type ApiMutationOptions, useApi } from './mutation/useApi.ts'

export interface RetryReportGenerationVariables {
  readonly budgetId: string
  readonly reportId: string
  readonly fileId: string
}

export const useRetryReportGeneration = (
  options?: ApiMutationOptions<{ fileId: string }, RetryReportGenerationVariables>
) =>
  useApi<{ fileId: string }, RetryReportGenerationVariables>({
    type: 'retryReportGeneration',
    ...options,
  })
