export interface OverridableReferenceDataAttribute {
  readonly type: 'overridable-reference'
  readonly readonly: false
  readonly reference?: 'selected' | 'latest' // default: 'selected'
}

export const OverridableReferenceDataKeyAttributes = {
  'milk.quota.tolerance.kilogram-of-fat.reference.balance': { type: 'overridable-reference', readonly: false },
} as const satisfies Record<string, OverridableReferenceDataAttribute>

export type OverridableReferenceDataKey = keyof typeof OverridableReferenceDataKeyAttributes
