import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { groupBy } from 'lodash-es'

import { Icons } from '../../atoms'
import { Button } from '../../atoms/Button/Button'
import { Tooltip } from '../../atoms/Tooltip/Tooltip'
import { ScenarioListContext } from '../../context'
import { type TextTabData, TextTabList } from '../../molecules/TextTabList/TextTabList'
import { TitleBar } from '../TitleBar/TitleBar'

export interface BudgetNotesProps {
  readonly onClose: () => void
}

interface Note {
  note: string
  isLoan: boolean
  loan?: string
}

export const BudgetNotes = React.forwardRef<HTMLDivElement, BudgetNotesProps>(({ onClose, ...props }, ref) => {
  const intl = useIntl()
  const scenarios = useContext(ScenarioListContext)

  const scenarioNotes = scenarios
    .map((scenario) => ({ scenario: scenario.name, notes: scenario.notes ?? {} }))
    .flatMap((notes) => ({
      scenario: notes.scenario,
      notes: Object.keys(notes.notes).flatMap((noteKey) =>
        Object.keys(notes.notes[noteKey]).map(
          (projectionKey) => ({ note: notes.notes[noteKey][projectionKey], isLoan: false }) as Note
        )
      ),
    }))

  const loanNotes = scenarios
    .map((scenario) => ({ scenario: scenario.name, loans: scenario.loans ?? {}, imported: scenario.imported }))
    .flatMap(({ scenario, loans, imported }) => ({
      scenario,
      notes: Object.entries(loans).flatMap(([loanKey, state]) => {
        const loanName = state.name ?? imported.loans[loanKey]
        return { note: loans[loanKey].note ?? '', isLoan: true, loan: loanName } satisfies Note
      }),
    }))

  const allNotesByScenario = groupBy([...scenarioNotes, ...loanNotes], 'scenario')
  const budgetHasNotes = Object.entries(allNotesByScenario).some(([, value]) =>
    value.some((note) => note.notes.length > 0)
  )

  const tabs: TextTabData[] = [
    {
      title: intl.formatMessage({ id: 'reportNav.notes.all' }),
      tabContent: (
        <div className="mr-4 rounded border border-gray-300 p-2 shadow-inner">
          {!budgetHasNotes && <p>{intl.formatMessage({ id: 'reportNav.notes.empty' })}</p>}
          {budgetHasNotes &&
            Object.entries(allNotesByScenario).map(([scenario, notes]) => (
              <div key={scenario} className="flex flex-col px-2 py-4">
                <div>
                  <p className="font-semibold">{scenario}</p>
                </div>
                <div>
                  <ul className="list-outside list-disc px-4">
                    {notes.flatMap((note) =>
                      note.notes
                        .filter((singleNote) => singleNote.note !== '')
                        .map((singleNote, index) =>
                          singleNote.isLoan ? (
                            <Tooltip
                              key={`${singleNote.note}-${index}`}
                              label={`${intl.formatMessage({ id: 'loan.name' })}: ${singleNote.loan ?? ''}`}>
                              <li className="w-fit py-1">{singleNote.note}</li>
                            </Tooltip>
                          ) : (
                            <li key={singleNote.note} className="py-1">
                              {singleNote.note}
                            </li>
                          )
                        )
                    )}
                  </ul>
                </div>
              </div>
            ))}
        </div>
      ),
    },
  ]

  return (
    <div ref={ref} className="flex min-h-screen w-[614px] flex-col border-l-4 border-l-gray-400 bg-white" {...props}>
      <div className="h-screen overflow-y-auto">
        <div className="flex flex-col-reverse pb-6 pl-6 pr-4 pt-4">
          <TextTabList tabs={tabs} className="mt-4" />

          <TitleBar label={intl.formatMessage({ id: 'reportNav.notes' })}>
            <Tooltip label={intl.formatMessage({ id: 'reportNav.notes.close' })}>
              <Button shape="iconOnly" dimension="xs" onClick={onClose}>
                <Icons.XSm />
              </Button>
            </Tooltip>
          </TitleBar>
        </div>
      </div>
    </div>
  )
})
