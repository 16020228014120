import { createContext, type FC, type PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { computeMilkIncomeValues, mapNonNullValues, tupleToRecord } from '@via/compute'
import {
  type DataKeyDependenciesRecordKey,
  dataKeyRecordDependenciesMapValues,
  type MilkIncomeComputationType,
  MilkIncomeComputationTypeZod,
} from '@via/schema'
import { BigNumber } from 'bignumber.js'
import { mapValues } from 'lodash-es'

import { MilkIncomeFormDependenciesContext } from './MilkIncomeFormDependenciesContext.ts'
import { type MilkIncomeFormData } from './MilkIncomeFormZod.ts'

export interface MilkIncomeFormComputationState
  extends Record<MilkIncomeComputationType, number>,
    Record<DataKeyDependenciesRecordKey<'milk-income'>, number> {}

const initialState = {
  ...tupleToRecord(MilkIncomeComputationTypeZod.options, () => 0),
  ...dataKeyRecordDependenciesMapValues('milk-income', () => 0),
} as const satisfies MilkIncomeFormComputationState

export const MilkIncomeFormComputationContext = createContext<MilkIncomeFormComputationState>(initialState)

export const MilkIncomeFormComputationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [computationState, setComputationState] = useState<MilkIncomeFormComputationState>(initialState)
  const dependencies = useContext(MilkIncomeFormDependenciesContext)

  const { watch } = useFormContext<MilkIncomeFormData>()
  useEffect(() => {
    const { unsubscribe } = watch((formState) => {
      const currentDependenciesFormValues = dataKeyRecordDependenciesMapValues('milk-income', (key) =>
        formState[key] !== '' ? Number(formState[key]) : (dependencies[key]?.value ?? 0)
      )
      const result = computeMilkIncomeValues(
        mapNonNullValues(currentDependenciesFormValues, (number) => new BigNumber(number))
      )

      setComputationState({
        ...currentDependenciesFormValues,
        ...mapValues(result, (value) => value.toNumber()),
      })
    })
    return unsubscribe
  }, [dependencies, watch])

  return (
    <MilkIncomeFormComputationContext.Provider value={computationState}>
      {children}
    </MilkIncomeFormComputationContext.Provider>
  )
}
