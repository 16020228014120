import { type ScenarioState } from '@via/compute'

import { milkAnimalsInventorySummarySheet2024Blueprint } from './2024/milkAnimalsInventorySummarySheet2024Blueprint.ts'

export const milkAnimalsInventorySummaryBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...milkAnimalsInventorySummarySheet2024Blueprint }
  }
}
