import { lineToCellVariant } from '../../../atoms/LineTitle/lineToCellVariant.ts'
import { type LineVariant } from '../../../atoms/LineTitle/LineVariant.ts'
import { type MonetaryValueCellProps } from '../../MonetaryValueCell/MonetaryValueCell.tsx'
import { type MonetaryTableColumn, type MonetaryTableColumnType, type MonetaryTableValueLine } from '../types.ts'

const LINE_CELL_VARIANT_OVERRIDE: Partial<
  Record<MonetaryTableColumnType, Partial<Record<LineVariant, MonetaryValueCellProps['variant']>>>
> = {
  reference: {
    groupCalculate: 'referenceValue',
    groupReference: 'referenceValue',
    calculateDerived: 'referenceValue',
  },
  'all-references': {
    groupCalculate: 'referenceValue',
    groupReference: 'referenceValue',
    calculateDerived: 'referenceValue',
  },
}

export const cellVariant = (
  line: MonetaryTableValueLine,
  column: MonetaryTableColumn,
  type?: MonetaryValueCellProps['type']
): MonetaryValueCellProps['variant'] => {
  switch (type) {
    case 'proportion':
      return lineToCellVariant(line.variant, 'default')
    default:
      return (
        line.cellVariants?.[column.type] ??
        LINE_CELL_VARIANT_OVERRIDE[column.type]?.[line.variant] ??
        lineToCellVariant(line.variant, 'default')
      )
  }
}
