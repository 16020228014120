import { type BalanceSheetViewProps, useBudget, useScenario } from '@via/components'

import { useCurrentBudgetHandler } from '../../contexts/currentBudget/useCurrentBudgetHandler.ts'
import { useCurrentScenarioHandler } from '../../contexts/currentScenario/useCurrentScenarioHandler.ts'
import { useOfflineState } from '../../contexts/offlineState/useOfflineState.ts'
import { useSyncRequired } from '../../contexts/syncRequired/useSyncRequired.ts'
import { useAppNavigation } from '../../navigation/useAppNavigation.ts'

export const useScenarioEditableBalancePage = (): BalanceSheetViewProps => {
  const { offline } = useOfflineState()
  const { onNavigationChange } = useAppNavigation()
  const {
    close: onCloseBudget,
    requestOwnership: onRequestOwnership,
    update: onUpdateBudget,
  } = useCurrentBudgetHandler()
  const { updateReference: onRefreshScenarioReference } = useCurrentScenarioHandler()

  const budget = useBudget()
  const scenario = useScenario()
  const syncRequired = useSyncRequired()
  const { updateScenarioReferences: onUpdateScenarioReferences } = useCurrentBudgetHandler()

  return {
    loading: false,
    offline,
    budget,
    scenario,
    syncRequired,
    onCloseBudget,
    onNavigationChange,
    onRefreshScenarioReference,
    onRequestOwnership,
    onUpdateScenarioReferences,
    onUpdateBudget,
  }
}
