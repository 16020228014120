import React, { useMemo } from 'react'

import { LoadingDialog } from '../../molecules/LoadingDialog/LoadingDialog'

interface LoadingDialogState {
  open: boolean
  label: string
}
type UpdateLoadingDialogState = (state: LoadingDialogState) => void

export const LoadingDialogContext = React.createContext<{
  setLoadingDialogState: UpdateLoadingDialogState
  loadingDialogState: LoadingDialogState
} | null>(null)

export const LoadingDialogProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [loadingDialogState, setLoadingDialogState] = React.useState<LoadingDialogState>({ open: false, label: '' })

  const providerValue = useMemo(() => ({ setLoadingDialogState, loadingDialogState }), [loadingDialogState])

  return (
    <LoadingDialogContext.Provider value={providerValue}>
      <LoadingDialog {...loadingDialogState} />
      {children}
    </LoadingDialogContext.Provider>
  )
}
