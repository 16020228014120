import React from 'react'
import { useIntl } from 'react-intl'

import { useClientListDataTableOptions } from '../../hooks/useClientListDataTableOptions'
import { usePersistedTableSorting } from '../../hooks/usePersistedTableSorting'
import { DataTable, type DataTableColumnDef, DataTableColumnHeader } from '../../molecules/DataTable/DataTable'
import { type ClientData } from '../../types'

export interface ClientListDataTableActions {
  onFilterByChange?(value: string): void
  onNavigateToClient(clientId: string): Promise<void>
}

export interface ClientListDataTableProps extends ClientListDataTableActions {
  readonly data: ClientData[]
}

export const ClientListDataTable = ({ data, onFilterByChange, onNavigateToClient }: ClientListDataTableProps) => {
  const intl = useIntl()

  const years = React.useMemo(() => data.map((client) => client.latestYear), [data])
  const { filterOptions } = useClientListDataTableOptions({ years })

  const { sorting, onSortingChange } = usePersistedTableSorting({
    key: 'clientListSort',
    defaultValue: [{ id: 'latestYear', desc: true }],
  })

  const columns = React.useMemo<Array<DataTableColumnDef<ClientData>>>(
    () => [
      {
        id: 'code',
        accessorFn: (row) => row.code,
        headerClassName: 'px-6 w-28',
        cellClassName: 'px-6',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title={intl.formatMessage({ id: 'clientList.file' })} />
        ),
      },
      {
        id: 'name',
        accessorFn: (row) => row.name,
        cellClassName: 'text-base',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title={intl.formatMessage({ id: 'clientList.enterprise' })} />
        ),
      },
      {
        id: 'contactInformation',
        accessorFn: (row) => row.contactInformation.name || '-',
        cellClassName: 'text-base',
        headerClassName: 'w-64',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title={intl.formatMessage({ id: 'clientList.contact' })} />
        ),
      },
      {
        id: 'lastUpdate',
        headerClassName: 'px-4 w-18',
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({ id: 'clientList.updatedDate' })}
            className="w-18 text-center"
          />
        ),
        cell: ({ row }) =>
          row.original.lastUpdate && (
            <div className="flex justify-center">
              <span className="flex text-center">{intl.formatDate(row.original.lastUpdate.timestamp)}</span>
            </div>
          ),
      },
      {
        id: 'latestYear',
        accessorFn: (row) => String(row.latestYear),
        headerClassName: 'px-2 w-32',
        cellClassName: 'text-center',
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({ id: 'clientList.lastYearViagritel' })}
            className="w-32"
          />
        ),
      },
    ],
    [intl]
  )

  return (
    <DataTable
      tableId="client-list-data-table"
      titleLabel={intl.formatMessage({ id: 'clientList.title' })}
      emptyLabel={intl.formatMessage({ id: 'clientList.empty' })}
      searchPlaceholderLabel={intl.formatMessage({ id: 'clientList.search' })}
      filterOptions={{
        year: {
          options: filterOptions,
          placeholder: intl.formatMessage({ id: 'clientList.filter' }),
          onChange: onFilterByChange,
        },
      }}
      columns={columns}
      data={data}
      filterKeys={[
        'code',
        'name',
        'nim',
        'contactInformation.email',
        'contactInformation.name',
        'contactInformation.phone',
        'address.city',
        'address.postalCode',
        'address.province',
        'address.streetAddress',
      ]}
      sorting={sorting}
      onSortingChange={onSortingChange}
      onRowClick={async (id: string) => {
        const row = data[Number(id)]
        await onNavigateToClient(row.code)
      }}
    />
  )
}
