import { type FC, useContext } from 'react'

import { type BlueprintVersion, type DataKey, isDataKey, isGrowthEnabled } from '@via/schema'
import { omit } from 'lodash-es'
import { type DistributedOmit } from 'type-fest'

import { cn } from '../../../lib/utils.ts'
import { type MonetaryCellData, type MonetaryRowData } from '../../../model'
import { MonetaryReadonlyValueCell } from '../../MonetaryValueCell/MonetaryReadonlyValueCell.tsx'
import { MonetaryValueCell, type MonetaryValueCellProps } from '../../MonetaryValueCell/MonetaryValueCell.tsx'
import { MonetaryTablePropsContext } from '../contexts/MonetaryTablePropsContext.ts'
import { MonetaryTableBlueprintContext } from '../contexts/MonetaryTableStateProvider.tsx'
import { type MonetaryTableColumnType } from '../types.ts'

import { cellVariant } from './cellVariant.ts'
import { type MonetaryTableCellProps } from './MonetaryTableCellProps.ts'

const columnCellData = (
  rowData: MonetaryRowData,
  type: MonetaryTableColumnType,
  blueprintVersion: BlueprintVersion
): MonetaryCellData | undefined => {
  switch (type) {
    case 'reference':
      return rowData.reference
    case 'finance-investment-amount':
      return rowData.total
    case 'finance-investment-total':
      return rowData.total
    case 'finance-loan-starting-balance':
      return rowData.loan?.startingBalance
    case 'finance-loan-interest':
      return rowData.loan?.interestRate
    case 'finance-loan-duration':
      return rowData.loan?.duration
    case 'growth':
      return isGrowthEnabled(rowData.key, blueprintVersion) ? rowData.growth : undefined
    case 'finance-short-term-amount':
      return rowData.startingBalance
    default:
      return undefined
  }
}

export type MonetaryTableValueCellProps = MonetaryTableCellProps &
  DistributedOmit<
    MonetaryValueCellProps,
    | 'variant'
    | 'unit'
    | 'negated'
    | 'bottomBorder'
    | 'closingSection'
    | 'weight'
    | 'blueprintVersion'
    | 'dataKey'
    | 'editable'
    | 'rowData'
  > & {
    readonly editable?: boolean
    readonly dataKey?: string
  }

export const MonetaryTableValueCell: FC<MonetaryTableValueCellProps> = ({
  column,
  line,
  withDividerLeft = true,
  withDividerRight,
  editable,
  cellData,
  className,
  numberDisplayType,
  dataKey = line.id,
  rowData,
  ...props
}) => {
  const blueprint = useContext(MonetaryTableBlueprintContext)
  const { readonly: readonlyTable } = useContext(MonetaryTablePropsContext)
  if (!isDataKey(dataKey)) {
    return (
      <MonetaryReadonlyValueCell
        cellData={cellData ?? columnCellData(rowData, column.type, blueprint.version)}
        variant={cellVariant(line, column, props.type)}
        unit={column.showUnitInCell && line.unit}
        negated={line.negated}
        withDividerLeft={withDividerLeft}
        withDividerRight={withDividerRight !== false && column.withDividerRight}
        bottomBorder={line.bottomBorder}
        closingSection={line.closingSection}
        className={cn(column.className, line.className, className)}
        numberDisplayType={numberDisplayType ?? line.numberDisplayType ?? column.numberDisplayType}
        {...omit(props, 'onUpdate')}
      />
    )
  }

  if (props.type === 'readonly' || editable === false) {
    return (
      <MonetaryValueCell
        editable={false}
        cellData={cellData ?? columnCellData(rowData, column.type, blueprint.version)}
        variant={cellVariant(line, column, props.type)}
        unit={column.showUnitInCell && line.unit}
        negated={line.negated}
        withDividerLeft={withDividerLeft}
        withDividerRight={withDividerRight !== false && column.withDividerRight}
        bottomBorder={line.bottomBorder}
        closingSection={line.closingSection}
        className={cn(column.className, line.className, className)}
        numberDisplayType={numberDisplayType ?? line.numberDisplayType ?? column.numberDisplayType}
        dataKey={dataKey}
        rowData={rowData}
        weight={line.weight as DataKey}
        blueprintVersion={blueprint.version}
        {...props}
      />
    )
  }

  return (
    <MonetaryValueCell
      editable={!readonlyTable && !line.readonly}
      cellData={cellData ?? columnCellData(rowData, column.type, blueprint.version)}
      variant={cellVariant(line, column, props.type)}
      unit={column.showUnitInCell && line.unit}
      negated={line.negated}
      withDividerLeft={withDividerLeft}
      withDividerRight={withDividerRight !== false && column.withDividerRight}
      bottomBorder={line.bottomBorder}
      closingSection={line.closingSection}
      className={cn(column.className, line.className, className)}
      numberDisplayType={numberDisplayType ?? line.numberDisplayType ?? column.numberDisplayType}
      dataKey={dataKey}
      rowData={rowData}
      weight={line.weight as DataKey}
      blueprintVersion={blueprint.version}
      {...props}
    />
  )
}
