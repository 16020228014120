import { useMemo } from 'react'

import { mapNonNullValues } from '@via/compute'
import { type YearKey } from '@via/schema'

import { type OverridableMonetaryCellData } from '../../model'

import { type UseMonetaryRowsValues, useMonetaryRowsValues } from './useMonetaryRowsValues.ts'

interface UseMonetaryRowsProjectionCellDataOptions<Key extends string, Weight extends string>
  extends UseMonetaryRowsValues<Key, Weight> {
  readonly year: YearKey
}

export const useMonetaryRowsProjectionCellData = <Key extends string, Weight extends string = never>({
  year,
  ...options
}: UseMonetaryRowsProjectionCellDataOptions<Key, Weight>): Partial<Record<Key, OverridableMonetaryCellData>> => {
  const rows = useMonetaryRowsValues<Key, Weight>(options)
  return useMemo(() => mapNonNullValues(rows, (values) => values[year]), [rows, year])
}
