import { type RxDocument, type RxQuery } from 'rxdb'

import { type RxCollectionDocumentType } from '../types.ts'

import { rxDocumentData } from './rxDocumentData.ts'

export const rxFetchDocumentData = async <C, T = RxCollectionDocumentType<C>>(
  query: RxQuery<T, RxDocument<T> | null>
) => {
  const document = await query.exec()
  return document ? rxDocumentData(document) : null
}

export const rxFetchOrThrowDocumentData = async <C, T = RxCollectionDocumentType<C>>(
  query: RxQuery<T, RxDocument<T> | null>
) => {
  const document = await query.exec(true)
  return rxDocumentData(document)
}
