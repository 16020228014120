import { useMemo } from 'react'

import { map, sortBy } from 'lodash-es'

import { type BudgetData, type ScenarioData } from '../../types'

interface UseScenarioIndexProps {
  scenario?: ScenarioData
  budget: BudgetData
}

export const useScenarioIndex = ({ scenario, budget }: UseScenarioIndexProps) =>
  useMemo(() => {
    if (!scenario?._id) {
      return 0
    }

    const sortedBudgetScenarios = sortBy(
      map(budget.scenarios, (value, id) => ({ ...value, id })),
      (budgetScenario) => budgetScenario.creationTimestamp
    )
    return sortedBudgetScenarios.findIndex((budgetScenario) => budgetScenario.id === scenario._id) + 1
  }, [budget.scenarios, scenario?._id])
