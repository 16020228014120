import { type PropsWithChildren } from 'react'

import { type DataKey } from '@via/schema'

import { MonetaryTableEditingStateProvider } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import { ScenarioLayoutFooterHeightProvider } from '../../context/layout/ScenarioLayoutFooterHeightProvider.tsx'
import { type NavigationHandler } from '../../navigation'
import { type BudgetData, type ScenarioData } from '../../types'
import { MonetaryTable, type MonetaryTableProps } from '../MonetaryTable'
import { ScenarioMainLayout, type ScenarioMainLayoutProps } from '../ScenarioMainLayout/ScenarioMainLayout.tsx'

export interface ScenarioMonetaryLayoutProps<
  Key extends string = DataKey,
  TableStates extends string = string,
  TabValues extends string = string,
  Weight extends string = string,
> extends Pick<
      ScenarioMainLayoutProps,
      | 'loading'
      | 'syncRequired'
      | 'snapshot'
      | 'snapshotName'
      | 'currentScenarioNavigationId'
      | 'navigationMenuCollapsedState'
      | 'onNavigationMenuCollapse'
      | 'onUpdateBudget'
      | 'onCloseBudget'
      | 'onRequestOwnership'
      | 'onRefreshScenarioReference'
      | 'onUpdateScenarioReferences'
    >,
    Pick<
      MonetaryTableProps<Key, TableStates, TabValues, Weight>,
      | 'blueprint'
      | 'collapsed'
      | 'currentWeight'
      | 'currentBlueprintState'
      | 'onCollapse'
      | 'onWeightChange'
      | 'onBlueprintStateChange'
      | 'onReferenceChange'
      | 'onUpdateCell'
    > {
  readonly budget: BudgetData
  readonly scenario: ScenarioData
  readonly onNavigationChange: NavigationHandler
  readonly loading: boolean
  readonly offline: boolean
}

export const ScenarioMonetaryLayout = <
  Key extends string = DataKey,
  TableStates extends string = string,
  TabValues extends string = string,
  Weight extends string = string,
>({
  budget,
  scenario,
  offline,
  loading,
  syncRequired,
  snapshot,
  snapshotName,
  currentScenarioNavigationId,
  navigationMenuCollapsedState,
  onNavigationMenuCollapse,
  onUpdateBudget,
  onNavigationChange,
  onCloseBudget,
  onRequestOwnership,
  onRefreshScenarioReference,
  onUpdateScenarioReferences,
  children,
  ...tableProps
}: PropsWithChildren<ScenarioMonetaryLayoutProps<Key, TableStates, TabValues, Weight>>) => (
  <ScenarioLayoutFooterHeightProvider>
    <MonetaryTableEditingStateProvider>
      <ScenarioMainLayout
        currentScenarioNavigationId={currentScenarioNavigationId}
        budget={budget}
        scenario={scenario}
        syncRequired={syncRequired}
        offline={offline ?? false}
        loading={loading}
        snapshot={snapshot}
        snapshotName={snapshotName}
        navigationMenuCollapsedState={navigationMenuCollapsedState}
        onNavigationMenuCollapse={onNavigationMenuCollapse}
        onCloseBudget={onCloseBudget}
        onRequestOwnership={onRequestOwnership}
        onNavigationChange={onNavigationChange}
        onUpdateBudget={onUpdateBudget}
        onRefreshScenarioReference={onRefreshScenarioReference}
        onUpdateScenarioReferences={onUpdateScenarioReferences}>
        <div className="flex min-h-[calc(100vh-84px)] flex-1 flex-col pl-32">
          {children}
          <MonetaryTable
            referenceLabel={
              scenario.referenceType === 'empty'
                ? (scenario.customReferenceName ?? '')
                : (scenario.selectedReferenceYear ?? '')
            }
            selectedReferenceYear={scenario.selectedReferenceYear}
            references={scenario.referenceYears}
            projections={scenario.period?.projectionYears}
            readonly={budget.readonly}
            readonlyReferences={budget.readonly || scenario.referenceType !== 'empty'}
            className="w-full bg-white pt-5"
            {...tableProps}
          />
        </div>
      </ScenarioMainLayout>
    </MonetaryTableEditingStateProvider>
  </ScenarioLayoutFooterHeightProvider>
)
