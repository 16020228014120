import { type FieldPathByValue, type FieldValues, useFormContext, type UseFormReturn, useWatch } from 'react-hook-form'

import { LineTitle } from '../../../atoms/LineTitle/LineTitle.tsx'
import { lineToCellVariant } from '../../../atoms/LineTitle/lineToCellVariant.ts'
import { type LineVariant } from '../../../atoms/LineTitle/LineVariant.ts'
import { cn } from '../../../lib/utils.ts'
import {
  type PopoverLineCellFormFieldProps,
  PopoverLineCellWithUnitFormField,
} from '../../../molecules/PopoverInlineFormField/PopoverLineCellWithUnitFormField.tsx'

export interface MonetaryYearFormFieldTableEditableDataRowProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends Omit<PopoverLineCellFormFieldProps<TFieldValues, TName>, 'variant' | 'name' | 'onChange' | 'type'> {
  readonly title: string
  readonly rowId: TName
  readonly variant: Exclude<LineVariant, 'head' | 'divider'>
  readonly onChange?: (value: string | number | undefined, form: UseFormReturn<TFieldValues>) => void
}

export const MonetaryYearFormFieldTableEditableDataRow = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>({
  title,
  rowId,
  variant,
  bottomBorder,
  onChange,
  ...props
}: MonetaryYearFormFieldTableEditableDataRowProps<TFieldValues, TName>) => {
  const form = useFormContext<TFieldValues>()
  const value = useWatch<TFieldValues>({ name: rowId })
  return (
    <div className="flex shrink-0">
      <LineTitle label={title} className="w-72" variant={variant} bottomBorder={bottomBorder} collapsable={false} />
      <PopoverLineCellWithUnitFormField<TFieldValues, TName>
        name={rowId}
        variant={lineToCellVariant(variant)}
        bottomBorder={bottomBorder}
        cellClassName={cn(!value ? 'italic text-gray-600' : 'font-bold')}
        className="w-32"
        onChange={(e) => {
          onChange?.((e.target as HTMLInputElement).value, form)
        }}
        {...props}
      />
    </div>
  )
}
