import type React from 'react'

import { ReportsContext } from '@via/components'

import {
  FirestoreBudgetReportSnapshotContext,
  FirestoreBudgetReportSnapshotProvider,
} from '../../firestore/snapshot/FirestoreBudgetReportSnaphotProvider.tsx'
import { useOfflineState } from '../offlineState/useOfflineState.ts'

export type CurrentBudgetReportListProviderProps = React.PropsWithChildren<{ readonly budgetId: string }>

export const CurrentBudgetReportListProvider: React.FC<CurrentBudgetReportListProviderProps> = ({
  children,
  budgetId,
}) => {
  const { offline } = useOfflineState()
  if (offline) {
    return children
  }

  return (
    <FirestoreBudgetReportSnapshotProvider budgetId={budgetId}>
      <FirestoreBudgetReportSnapshotContext.Consumer>
        {(reports) => <ReportsContext.Provider value={reports}>{children}</ReportsContext.Provider>}
      </FirestoreBudgetReportSnapshotContext.Consumer>
    </FirestoreBudgetReportSnapshotProvider>
  )
}
