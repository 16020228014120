import React, { useEffect } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { collections } from '@via/frontend-schema'
import { type SchemaDocumentOutput } from 'zod-firebase'

import { CollectionReducerStateInitial } from '../hooks/collectionStateReducer.ts'
import { useCollection, type UseCollectionResult } from '../hooks/useCollection.ts'

type FirestoreBudgetReportProviderProps = React.PropsWithChildren<{ readonly budgetId: string }>

export type FirestoreBudgetReportDocument = SchemaDocumentOutput<typeof collections.budgets.reports>

export const FirestoreBudgetReportSnapshotContext =
  React.createContext<UseCollectionResult<FirestoreBudgetReportDocument>>(CollectionReducerStateInitial)

export const FirestoreBudgetReportSnapshotProvider: React.FC<FirestoreBudgetReportProviderProps> = ({
  children,
  budgetId,
}) => {
  const queryClient = useQueryClient()
  const queryState = useCollection({
    query: collections.budgets(budgetId).reports.prepare({
      name: `budget ${budgetId} report list`,
    }),
  })

  useEffect(() => {
    if (!queryState.hasError && !queryState.isLoading) {
      queryClient.setQueryData(['reports', budgetId], queryState)
    }
    return () => {
      queryClient.removeQueries({ queryKey: ['reports', budgetId] })
    }
  }, [budgetId, queryClient, queryState])

  return (
    <FirestoreBudgetReportSnapshotContext.Provider value={queryState}>
      {children}
    </FirestoreBudgetReportSnapshotContext.Provider>
  )
}
