import { useContext, useMemo } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { useBudget } from '@via/components'
import pMap from 'p-map'

import { useCreateBudgetReference } from '../../api/useCreateBudgetReference.ts'
import { useCurrentUserActionTimestamp } from '../../auth/useCurrentUserActionTimestamp.ts'
import { useAuthenticatedFirebaseAuth } from '../../auth/useFirebaseAuth.ts'
import { BudgetRequestConfirmationDialogContext } from '../../requests/BudgetRequestConfirmationDialogContext.ts'
import { type BudgetUpdateData } from '../../worker/budget/types.ts'
import { useBudgetHandler } from '../../worker/budget/useBudgetHandler.ts'
import { useScenarioHandler } from '../../worker/scenario/useScenarioHandler.ts'

export const useCurrentBudgetHandler = () => {
  const budget = useBudget()
  const handler = useBudgetHandler()
  const modificationTimestamp = useCurrentUserActionTimestamp()

  const { user, userName, userRole } = useAuthenticatedFirebaseAuth()
  const scenarioHandler = useScenarioHandler()
  const { mutateAsync } = useCreateBudgetReference()
  const navigate = useNavigate()

  const { request: requestOwnership } = useContext(BudgetRequestConfirmationDialogContext)

  const updateScenarioReferences = async () => {
    const { id: referenceId, currentProductions } = await mutateAsync({ budgetId: budget._id })

    await pMap(Object.keys(budget.scenarios), (scenarioId) =>
      scenarioHandler.addScenarioEvent(budget._id, scenarioId, {
        type: 'updateReferenceId',
        referenceId,
        currentProductions,
        userId: user.uid,
        timestamp: new Date(),
        userName,
        userRole,
      })
    )
  }

  return useMemo(
    () => ({
      open: () => handler.acquireBudget(budget._id),
      close: () => {
        if (budget.readonly) {
          return navigate({ to: '/budgets' })
        }

        return handler.closeBudget(budget._id)
      },
      requestOwnership,
      update: (data: Omit<BudgetUpdateData, 'lastModification'>) =>
        handler.updateBudget(budget._id, { ...data, lastModification: modificationTimestamp() }),
      updateScenarioReferences,
    }),
    [budget._id, handler, modificationTimestamp, requestOwnership]
  )
}
