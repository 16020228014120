import { z } from 'zod'

import { FormFiniteNumberValueZod } from '../../atoms/Form/FormFiniteNumberValueZod.ts'

export const AdjustmentZod = z.discriminatedUnion('operator', [
  z.object({
    operator: z.enum(['+', '-', '=']),
    value: FormFiniteNumberValueZod,
  }),
  z.object({
    operator: z.literal(''),
    value: z.literal('').optional().default(''),
  }),
])
