import { type FC, type PropsWithChildren, useMemo } from 'react'

import { type MilkOperations, MilkOperationsContext } from './MilkOperationsContext.ts'

export type MilkOperationsProviderProps = MilkOperations

export const MilkOperationsProvider: FC<PropsWithChildren<MilkOperationsProviderProps>> = ({
  onUpdateQuota,
  onUpdateIncome,
  onUpdateExpenses,
  children,
}) => {
  const providerValue = useMemo(
    () => ({ onUpdateQuota, onUpdateIncome, onUpdateExpenses }),
    [onUpdateQuota, onUpdateIncome, onUpdateExpenses]
  )
  return <MilkOperationsContext.Provider value={providerValue}>{children}</MilkOperationsContext.Provider>
}
