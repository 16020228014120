import { Badge } from '../../atoms/Badge/Badge'

export type StatusBadgeVariant = 'todo' | 'waiting' | 'completed' | 'inProgress' | 'approving'

export interface StatusBadgeProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  readonly variant: StatusBadgeVariant
  readonly label: string
}

export const StatusBadge = ({ variant = 'todo', label }: StatusBadgeProps) => (
  <Badge
    variant={
      variant === 'todo'
        ? 'orange'
        : variant === 'waiting'
          ? 'gray'
          : variant === 'completed'
            ? 'green'
            : variant === 'inProgress'
              ? 'blue'
              : 'red'
    }>
    {label}
  </Badge>
)
