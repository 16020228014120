import { type FC, type PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { type Except } from 'type-fest'

import { Icons } from '../../atoms'
import { BudgetReferenceUpdateDialogContext } from '../../context'
import { ScenarioMainHeader, type ScenarioMainHeaderProps } from '../ScenarioMainHeader/ScenarioMainHeader'
import { ScenarioNavigation, type ScenarioNavigationProps } from '../ScenarioNavigation/ScenarioNavigation'

export interface ScenarioEditableMainLayoutProps
  extends Except<ScenarioMainHeaderProps, 'onAddScenarioClick'>,
    ScenarioNavigationProps {}

export const ScenarioEditableMainLayout: FC<PropsWithChildren<ScenarioEditableMainLayoutProps>> = ({
  budget,
  scenario,
  syncRequired,
  currentScenarioNavigationId,
  offline,
  loading,
  newScenarioId,
  newScenarioName,
  onUpdateBudget,
  onCloseBudget,
  onNavigationChange,
  onRefreshScenarioReference,
  onRequestOwnership,
  onUpdateScenarioReferences,
  children,
  ...navigationProps
}) => {
  const onAddScenarioClick = useCallback(
    () => onNavigationChange({ to: '/budgets/$budgetId/scenarios/create', params: { budgetId: budget._id } }),
    [onNavigationChange, budget._id]
  )
  const intl = useIntl()
  const displaySyncRequiredWarning = useMemo(
    () =>
      currentScenarioNavigationId !== 'create' &&
      !offline &&
      !budget.readonly &&
      syncRequired &&
      scenario?.referenceType === 'viagritel',
    [budget.readonly, currentScenarioNavigationId, offline, scenario?.referenceType, syncRequired]
  )
  const { open: openBudgetReferenceUpdateDialog } = useContext(BudgetReferenceUpdateDialogContext)

  return (
    <>
      <ScenarioMainHeader
        budget={budget}
        scenario={scenario}
        offline={offline}
        currentScenarioNavigationId={currentScenarioNavigationId}
        loading={loading}
        newScenarioName={newScenarioName}
        newScenarioId={newScenarioId}
        syncRequired={syncRequired}
        onUpdateBudget={onUpdateBudget}
        onAddScenarioClick={onAddScenarioClick}
        onCloseBudget={onCloseBudget}
        onNavigationChange={onNavigationChange}
        onRequestOwnership={onRequestOwnership}
        onUpdateScenarioReferences={onUpdateScenarioReferences}
      />
      <ScenarioNavigation
        scenario={scenario}
        budget={budget}
        offline={offline}
        currentScenarioNavigationId={currentScenarioNavigationId}
        syncRequired={syncRequired}
        onRefreshScenarioReference={onRefreshScenarioReference}
        onUpdateScenarioReferences={onUpdateScenarioReferences}
        {...navigationProps}
      />
      <div className="flex">
        {children}
        {displaySyncRequiredWarning && (
          <div className="fixed bottom-5 left-4 z-[999]">
            <button
              type="button"
              className="flex max-w-24 flex-col items-center justify-center gap-1.5 rounded-md border border-red-300 bg-red-100 p-2 pt-1"
              tabIndex={-1}
              onClick={() => {
                openBudgetReferenceUpdateDialog({
                  onConfirm: onUpdateScenarioReferences,
                })
              }}>
              <Icons.ExclamationTriangle />
              <p className="items-center text-center text-xs font-semibold leading-3">
                {intl.formatMessage({ id: 'scenarioNav.syncRequired' })}
              </p>
            </button>
          </div>
        )}
      </div>
    </>
  )
}
