import { type FC } from 'react'

import { Link } from '@tanstack/react-router'

import Brand from '../../assets/images/Brand.png'
import { ToggleIconsButton, type ToggleIconsButtonProps } from '../ToggleIconsButton/ToggleIconsButton'

export type BudgetLogoHeaderProps = ToggleIconsButtonProps

export const BudgetLogoHeader: FC<BudgetLogoHeaderProps> = (props) => (
  <div className="flex flex-col items-center gap-2">
    <Link to="/" className="select-none items-center justify-center">
      <img src={Brand} alt="Brand" className="h-5 shrink-0" />
    </Link>
    <ToggleIconsButton {...props} />
  </div>
)
