import { type FC, type PropsWithChildren, useCallback, useContext } from 'react'
import { type DeepPartial } from 'react-hook-form'

import { computeMilkProductionExpenses, mapNonNullValues, tupleToRecord } from '@via/compute'
import { keysToTuple } from '@via/schema'
import { BigNumber } from 'bignumber.js'

import { FormComputationProvider } from '../../atoms/FormComputation/FormComputationProvider.tsx'

import {
  MilkExpensesFormDependencies,
  MilkExpensesFormDependenciesContext,
} from './MilkExpensesFormDependenciesContext.ts'
import { type MilkExpensesFormData } from './MilkExpensesFormZod.ts'

export interface MilkExpensesFormComputationState extends Record<keyof typeof MilkExpensesFormDependencies, number> {
  readonly totalSolidContent: number
  readonly totalSolidProduction: number
  readonly milkExpenses: number
}

const initialState = {
  ...tupleToRecord(keysToTuple(MilkExpensesFormDependencies), () => 0),
  totalSolidContent: 0,
  totalSolidProduction: 0,
  milkExpenses: 0,
} as const satisfies MilkExpensesFormComputationState

export const MilkExpensesFormComputationProvider: FC<PropsWithChildren> = ({ children }) => {
  const dependencies = useContext(MilkExpensesFormDependenciesContext)
  const compute = useCallback(
    (formState: DeepPartial<MilkExpensesFormData>): MilkExpensesFormComputationState => {
      const currentDependenciesFormValues = tupleToRecord(keysToTuple(MilkExpensesFormDependencies), (key) =>
        formState[key] !== '' ? Number(formState[key]) : (dependencies[key]?.value ?? 0)
      )
      const milkExpenses = computeMilkProductionExpenses(
        mapNonNullValues(currentDependenciesFormValues, (number) => new BigNumber(number))
      )

      const totalSolidContent =
        currentDependenciesFormValues.fatContent +
        currentDependenciesFormValues.proteinContent +
        currentDependenciesFormValues.lactoseContent
      return {
        ...currentDependenciesFormValues,
        totalSolidContent,
        totalSolidProduction: totalSolidContent * currentDependenciesFormValues.volume,
        milkExpenses: milkExpenses.toNumber(),
      }
    },
    [dependencies]
  )

  return (
    <FormComputationProvider<MilkExpensesFormComputationState, MilkExpensesFormData>
      initialState={initialState}
      compute={compute}>
      {children}
    </FormComputationProvider>
  )
}
