import React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const textareaVariants = cva(
  'flex w-full items-center border border-gray-300 rounded pl-1.5 pr-1 py-2 text-sm font-normal leading-none tracking-[-0.24px] transition-colors placeholder:italic placeholder:text-gray-600 focus-visible:outline-none focus-visible:ring-1 disabled:bg-gray-200 shadow-input disabled:shadow-none disabled:border-gray-200 disabled:cursor-not-allowed disabled:placeholder:text-gray-700 text-black disabled:text-gray-700',
  {
    variants: {
      variant: {
        default: 'focus-visible:border-blue-500 focus-visible:ring-blue-500',
        calculate: 'focus-visible:border-orange-400 focus-visible:ring-orange-400',
        external: 'focus-visible:border-green-400 focus-visible:ring-green-400',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaVariants> {}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, variant = 'default', ...props }, ref) => (
    <textarea className={cn(textareaVariants({ variant }), className)} ref={ref} {...props} />
  )
)
