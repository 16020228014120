import { type RxJsonSchema } from 'rxdb'

import { type RxCollectionDocumentType, type ScenarioRxDatabase } from '../types.ts'

type ScenarioEventRxDocument = RxCollectionDocumentType<ScenarioRxDatabase['events']>

export const ScenarioEventRxSchema = {
  title: 'scenario event',
  description: 'scenario event schema',
  version: 0,
  type: 'object',
  primaryKey: '_id',
  required: ['_id', 'type', 'userId', 'userName', 'userRole', 'previousEventId', 'timestamp', 'epochTimestamp'],
  properties: {
    _id: {
      type: 'string',
      maxLength: 100,
    },
    type: {
      type: 'string',
    },
    userId: {
      type: 'string',
    },
    userName: {
      type: 'string',
    },
    userRole: {
      type: 'string',
    },
    previousEventId: {
      type: 'string',
    },
    timestamp: {
      type: 'string',
      format: 'date-time',
    },
    epochTimestamp: {
      type: 'number',
    },
  },
} as const satisfies RxJsonSchema<
  Pick<
    ScenarioEventRxDocument,
    '_id' | 'type' | 'userId' | 'userName' | 'userRole' | 'previousEventId' | 'timestamp' | 'epochTimestamp'
  >
>
