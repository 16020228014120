import type React from 'react'
import { type ReactElement } from 'react'
import { createContext, useMemo, useState } from 'react'

import { addBreadcrumb } from '@sentry/react'
import { noop } from 'lodash-es'

import {
  BudgetReferenceUpdateDialog,
  type BudgetReferenceUpdateDialogProps,
} from '../../molecules/BudgetReferenceUpdateDialog/BudgetReferenceUpdateDialog.tsx'

export interface BudgetReferenceUpdateDialogOptions {
  cancelLabel?: string
  confirmLabel?: string
  detailsMessage?: string
  subtitleLine1?: string
  subtitleLine2?: string
  title?: string
  onCancel?: () => Promise<unknown> | void
  onConfirm?: () => Promise<void> | void
}
export interface BudgetReferenceUpdateDialogContextValue {
  open: (options: BudgetReferenceUpdateDialogOptions) => void
}

export const BudgetReferenceUpdateDialogContext = createContext<BudgetReferenceUpdateDialogContextValue>({
  open: noop,
})

export const BudgetReferenceUpdateDialogProvider: React.FC<
  React.PropsWithChildren<{ readonly body?: ReactElement }>
> = ({ children, body }) => {
  const defaultOptions = useMemo(
    () => ({
      onConfirm: () => Promise.reject(new Error('open should be set before calling onConfirm')),
      onCancel: () => Promise.reject(new Error('open should be set before calling onCancel')),
    }),
    []
  )

  const [props, setProps] = useState<BudgetReferenceUpdateDialogProps>({
    ...defaultOptions,
    open: false,
  })

  const contextValue = useMemo<BudgetReferenceUpdateDialogContextValue>(
    () => ({
      open: ({ onConfirm, onCancel, ...options }: BudgetReferenceUpdateDialogOptions) => {
        addBreadcrumb({
          category: 'ui',
          message: 'open BudgetReferenceUpdateDialogProvider',
          data: { options },
        })

        const onConfirmAndClose = async () => {
          await onConfirm?.()
          setProps((state) => ({
            ...state,
            ...defaultOptions,
            open: false,
          }))
        }

        const onCancelAndClose = async () => {
          await onCancel?.()
          setProps((state) => ({
            ...state,
            ...defaultOptions,
            open: false,
          }))
        }
        setProps({
          ...defaultOptions,
          open: true,
          onConfirm: onConfirmAndClose,
          onCancel: onCancelAndClose,
          ...options,
        })
      },
    }),
    [defaultOptions]
  )

  return (
    <BudgetReferenceUpdateDialogContext.Provider value={contextValue}>
      <BudgetReferenceUpdateDialog {...props}>{body}</BudgetReferenceUpdateDialog>
      {children}
    </BudgetReferenceUpdateDialogContext.Provider>
  )
}
