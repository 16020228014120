import React from 'react'

import { cn } from '../../lib/utils'
import { type ClientData } from '../../types'
import { ClientHeader, type ClientHeaderProps } from '../Header/ClientHeader'
import { HEADER_HEIGHT_PX } from '../Header/Header'

export interface ClientBudgetListLayoutProps
  extends React.HtmlHTMLAttributes<HTMLDivElement>,
    Pick<ClientHeaderProps, 'avatar'> {
  readonly client: ClientData
  onCloseClient(): Promise<void>
}

export const ClientBudgetListLayout = React.forwardRef<HTMLDivElement, ClientBudgetListLayoutProps>(
  ({ client, className, avatar, onCloseClient, children, ...props }, ref) => (
    <div className={cn('flex min-h-screen w-full flex-col items-start bg-sage-200', className)} ref={ref} {...props}>
      <ClientHeader
        clientName={client.name}
        avatar={avatar}
        onCloseClient={onCloseClient}
        className="fixed top-0 z-10"
      />

      <div
        className="fixed z-10 w-full overflow-auto"
        style={{
          top: HEADER_HEIGHT_PX,
          height: `calc(100% - ${HEADER_HEIGHT_PX}px)`,
        }}>
        {children}
      </div>
    </div>
  )
)
