import { type FC } from 'react'

import { ScenarioUpdateFormProvider } from '../../organisms/ScenarioUpdateForm/ScenarioUpdateFormProvider'

import { ScenarioUpdateViewLayout, type ScenarioUpdateViewLayoutProps } from './ScenarioUpdateViewLayout.tsx'

export type ScenarioUpdateViewProps = ScenarioUpdateViewLayoutProps

export const ScenarioUpdateView: FC<ScenarioUpdateViewProps> = ({ scenario, ...props }) => (
  <ScenarioUpdateFormProvider data={scenario}>
    <ScenarioUpdateViewLayout scenario={scenario} {...props} />
  </ScenarioUpdateFormProvider>
)
