import React from 'react'

import { Input, type InputProps } from '../../atoms/Input/Input'
import { cn } from '../../lib/utils'

export interface PercentageInputProps extends InputProps {}

export const PercentageInput = React.forwardRef<HTMLInputElement, PercentageInputProps>(
  ({ id, className, step = '0.01', type = 'number', ...props }, ref) => (
    <div className="relative">
      <Input id={id} ref={ref} step={step} type={type} className={cn('block w-full pr-[18px]', className)} {...props} />
      <div className="absolute inset-y-0 right-0 flex select-none py-1.5 pr-0.5">
        <p className="inline-flex items-center px-1 text-xs">%</p>
      </div>
    </div>
  )
)
