import { addBreadcrumb } from '@sentry/react'
import pMap from 'p-map'

export const deleteAllViaIndexedDb = async (indexedDB = globalThis.indexedDB) => {
  const idbDatabases = await indexedDB.databases()
  await pMap(idbDatabases, async ({ name }) =>
    !name?.startsWith('via')
      ? Promise.resolve()
      : new Promise<Event>((resolve, reject) => {
          const request = indexedDB.deleteDatabase(name)
          request.addEventListener('success', resolve)
          request.addEventListener('error', reject)
        })
  )
  addBreadcrumb({ category: 'rxdb', message: 'IndexedDB deleted', data: { idbDatabases } })
}
