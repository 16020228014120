import { type FC, useContext } from 'react'
import { useIntl } from 'react-intl'

import { LineTitle } from '../../atoms/LineTitle/LineTitle.tsx'
import { UnitLabel } from '../../atoms/UnitLabel/UnitLabel.tsx'
import { useFormatNumber } from '../../hooks/useFormatNumber.ts'
import { InputFormField } from '../../molecules/InputFormField/InputFormField.tsx'

import { MilkQuotaFormComputationContext } from './MilkQuotaFormComputationContext.tsx'
import { type MilkQuotaFormData } from './MilkQuotaFormZod.ts'

export const MilkQuotaSummaryTable: FC = () => {
  const intl = useIntl()
  const { formatNumber } = useFormatNumber()
  const {
    endBalance,
    allowedProduction,
    allowedVolume,
    startingBalance,
    actualProductionInHectoliters,
    actualProduction,
    quotaPrice,
  } = useContext(MilkQuotaFormComputationContext)

  return (
    <div className="flex flex-row gap-8">
      <div className="flex w-[36rem] flex-col">
        <div className="flex shrink-0">
          <LineTitle
            label={intl.formatMessage({ id: 'milk.quota.form.summary.actual.volume' })}
            variant="calculate"
            withDividerRight
            className="w-full">
            <div className="flex items-baseline gap-2">
              <p className="w-16 text-right">{formatNumber(actualProductionInHectoliters, 'hectoliter')}</p>
              <UnitLabel unit="hectoliter" className="w-12" />
            </div>
          </LineTitle>
        </div>
        <div className="flex shrink-0">
          <LineTitle
            label={intl.formatMessage({ id: 'milk.quota.form.summary.actual.production' })}
            variant="calculateDerived"
            withDividerRight
            className="w-full">
            <div className="flex items-baseline gap-2">
              <p className="w-16 text-right">{formatNumber(actualProduction, 'kilogram-of-fat-per-day')}</p>
              <UnitLabel unit="kilogram-of-fat-per-day" className="w-12" />
            </div>
          </LineTitle>
        </div>
        <div className="flex shrink-0">
          <LineTitle
            label={intl.formatMessage({ id: 'milk.quota.form.summary.start-balance' })}
            variant="total"
            withDividerRight
            className="w-full">
            <div className="flex items-baseline gap-2">
              <p className="w-16 text-right">{formatNumber(startingBalance, 'kilogram-of-fat-per-day')}</p>
              <UnitLabel unit="kilogram-of-fat-per-day" className="w-12" />
            </div>
          </LineTitle>
        </div>
        <div className="flex shrink-0">
          <LineTitle
            label={intl.formatMessage({ id: 'milk.quota.form.summary.end-balance' })}
            variant="subTotal"
            withDividerRight
            className="w-full">
            <div className="flex items-baseline gap-2">
              <p className="w-16 text-right">{formatNumber(endBalance, 'kilogram-of-fat-per-day')}</p>
              <UnitLabel unit="kilogram-of-fat-per-day" className="w-12" />
            </div>
          </LineTitle>
        </div>
        <div className="flex shrink-0">
          <LineTitle
            label={intl.formatMessage({ id: 'milk.quota.form.summary.allowed' })}
            variant="subTotal"
            withDividerRight
            className="w-full">
            <div className="flex items-baseline gap-2">
              <p className="w-16 text-right">{formatNumber(allowedProduction, 'kilogram-of-fat-per-day')}</p>
              <UnitLabel unit="kilogram-of-fat-per-day" className="w-12" />
            </div>
          </LineTitle>
        </div>
        <div className="flex shrink-0">
          <LineTitle
            label={intl.formatMessage({ id: 'milk.quota.form.summary.volume' })}
            variant="calculate"
            withDividerRight
            className="w-full">
            <div className="flex items-baseline gap-2">
              <p className="w-16 text-right">{formatNumber(allowedVolume, 'hectoliter')}</p>
              <UnitLabel unit="hectoliter" className="w-12" />
            </div>
          </LineTitle>
        </div>
      </div>
      <div className="flex flex-col gap-1.5">
        <InputFormField<MilkQuotaFormData, 'quotaPrice'>
          name="quotaPrice"
          type="number"
          label={intl.formatMessage({ id: 'milk.quota.form.summary.quota-price' })}
          placeholder={quotaPrice ? formatNumber(quotaPrice, 'currency-per-kilogram-of-fat-per-day') : undefined}
        />
      </div>
    </div>
  )
}
