import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { Link } from '@tanstack/react-router'

import { Icons } from '../../atoms'
import { Tooltip } from '../../atoms/Tooltip/Tooltip'
import { type BudgetData } from '../../types'

export interface ToggleIconsButtonProps {
  readonly currentPage: 'reports' | 'scenarios'
  readonly budget: BudgetData
  readonly offline?: boolean
}

export const ToggleIconsButton: FC<ToggleIconsButtonProps> = ({ currentPage, budget, offline }) => {
  const intl = useIntl()

  return (
    <div className="flex">
      <div className="flex shrink-0 items-center">
        <Tooltip label={intl.formatMessage({ id: 'budgetNav.calculations' })} side="bottom">
          <Link
            to="/budgets/$budgetId"
            params={{ budgetId: budget._id }}
            className={`rounded-l-lg border px-6 py-1.5 ${currentPage === 'scenarios' ? 'border-green-700 bg-green-600 text-sage-50' : 'border-r-0 border-green-600 bg-sage-50 text-green-600'}`}>
            <Icons.Table />
          </Link>
        </Tooltip>
        <Tooltip label={intl.formatMessage({ id: 'budgetNav.documents' })} side="bottom">
          <Link
            disabled={offline || budget.readonly}
            to="/budgets/$budgetId/reports"
            params={{ budgetId: budget._id }}
            className={`rounded-r-lg border px-6 py-1.5 ${currentPage === 'reports' ? `border-green-700 bg-green-600 text-sage-50 aria-disabled:bg-gray-300` : `border-l-0 border-green-600 bg-sage-50 text-green-600 aria-disabled:bg-gray-300`}`}>
            <Icons.Document />
          </Link>
        </Tooltip>
      </div>
    </div>
  )
}
