import { forwardRef, type HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'

import { type Unit } from '@via/schema'
import { cva, type VariantProps } from 'class-variance-authority'
import { isNumber, isString } from 'lodash-es'

import { cn } from '../../lib/utils.ts'

const unitLabelVariants = cva('select-none text-left font-light italic text-gray-600 truncate', {
  variants: {
    dimension: {
      sm: 'h-8 w-12 text-xs leading-8 tracking-[-0.48px]',
      xs: 'h-6 w-10 text-xxs leading-6 tracking-[-0.48px]',
    },
  },
  defaultVariants: {
    dimension: 'sm',
  },
})

export interface UnitLabelProps extends HTMLAttributes<HTMLParagraphElement>, VariantProps<typeof unitLabelVariants> {
  readonly unit: Unit
  readonly value?: number | string
}

const toNumber = (field: string | number | undefined): number | undefined => {
  switch (true) {
    case isNumber(field):
      return Number.isFinite(field) ? field : undefined
    case isString(field):
      return toNumber(Number(field))
    default:
      return undefined
  }
}

export const UnitLabel = forwardRef<HTMLParagraphElement, UnitLabelProps>(
  ({ className, dimension, unit, value, ...props }, ref) => {
    const intl = useIntl()
    return (
      <p ref={ref} className={cn(unitLabelVariants({ dimension }), className)} {...props}>
        {intl.formatMessage({ id: `unit.${unit}` }, { value: toNumber(value) ?? 0 })}
      </p>
    )
  }
)
