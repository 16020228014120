import type React from 'react'

import { createFileRoute, Outlet } from '@tanstack/react-router'

import { BudgetListProvider } from '../contexts/budgetList/BudgetListProvider.tsx'
import { waitForSnapshot } from '../rxdb/localSnapshot/waitForSnapshot.ts'

export const BudgetListComponent: React.FC = () => (
  <BudgetListProvider>
    <Outlet />
  </BudgetListProvider>
)

export const Route = createFileRoute('/_budgetList')({
  component: BudgetListComponent,
  loader: async ({ context: { isOffline, appDatabase } }) => {
    if (!isOffline) {
      await waitForSnapshot(appDatabase, 'budgets')
    }
  },
})
