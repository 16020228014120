import { type FC, type PropsWithChildren, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { useCallbackWithConfirmation } from '../dialog'

import { type LoanOperations, LoanOperationsContext } from './LoanOperationsContext'

export type LoanOperationsProviderProps = LoanOperations

export const LoanOperationsProvider: FC<PropsWithChildren<LoanOperationsProviderProps>> = ({
  onAddLoan,
  onUpdateLoan,
  onDeleteLoan,
  children,
}) => {
  const intl = useIntl()

  const onDeleteWithConfirmation = useCallbackWithConfirmation<typeof onDeleteLoan>(
    (loanId, onCompleted) => onDeleteLoan(loanId, onCompleted),
    {
      title: intl.formatMessage({ id: 'loan.action.erase.confirmationDialog.title' }),
      subtitle: intl.formatMessage({ id: 'loan.action.erase.confirmationDialog.subTitle' }),
    },
    [intl, onDeleteLoan]
  )

  const providerValue = useMemo(
    () => ({
      onAddLoan,
      onUpdateLoan,
      onDeleteLoan: onDeleteWithConfirmation,
    }),
    [onAddLoan, onDeleteWithConfirmation, onUpdateLoan]
  )

  return <LoanOperationsContext.Provider value={providerValue}>{children}</LoanOperationsContext.Provider>
}
