import { forwardRef, type HtmlHTMLAttributes, useMemo } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const lineDividerVariants = cva('h-2 shrink-0', {
  variants: {
    variant: {
      default: 'bg-sky-100 border-sky-500',
      calculate: 'bg-green-200 border-green-400',
      reference: 'bg-gray-200 border-gray-400',
      head: 'h-7 bg-sky-100 border-sky-500',
    },
    border: {
      none: 'h-1',
      top: 'border-t',
      bottom: 'border-b',
    },
    first: {
      true: 'border-l',
      false: '',
    },
    withDividerLeft: {
      true: 'border-l',
      false: '',
    },
    withDividerRight: {
      true: 'last:border-r border-r-gray-400',
      false: '',
    },
  },
  defaultVariants: {
    variant: 'default',
    border: 'none',
    withDividerLeft: false,
    withDividerRight: false,
  },
  compoundVariants: [
    {
      first: false,
      withDividerLeft: true,
      class: 'border-l-gray-400',
    },
  ],
})

export interface LineDividerProps
  extends HtmlHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof lineDividerVariants> {}

export const LineDivider = forwardRef<HTMLDivElement, LineDividerProps>(
  ({ className, variant, border, withDividerLeft, withDividerRight, first = false, ...props }, ref) => {
    const variantClassName = useMemo(
      () =>
        lineDividerVariants({
          variant,
          border,
          withDividerLeft,
          withDividerRight,
          first,
        }),
      [variant, border, withDividerLeft, withDividerRight, first]
    )

    return <div className={cn(variantClassName, className)} ref={ref} {...props} />
  }
)
