import React from 'react'

import { cn } from '../../lib/utils'

export const TITLE_BAR_HEIGHT_PX = 56

export interface TitleBarData {
  readonly label?: string
  readonly leftElements?: React.ReactNode
}

export interface TitleBarProps extends TitleBarData, React.HtmlHTMLAttributes<HTMLDivElement> {}

export const TitleBar = React.forwardRef<HTMLDivElement, TitleBarProps>(
  ({ className, label, children, leftElements, ...props }, ref) => (
    <div className={cn('relative flex w-full shrink items-center', className)} ref={ref} {...props}>
      <div className="flex w-full select-none items-center justify-between">
        <div className="flex items-center gap-1 py-0">
          {label !== undefined && (
            <p className="text-2xl font-bold uppercase leading-tight tracking-[-0.48px]">{label}</p>
          )}
          {leftElements}
        </div>

        <div className="flex items-center justify-end gap-2 px-4 py-0">{children}</div>
      </div>
    </div>
  )
)
