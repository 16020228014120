import { z } from 'zod'

import { DataKeyZod } from '../data-keys'
import { BlueprintVersionZod, ProductionZod } from '../enums'

import { CellDataOverrideZod } from './cell-data-override-zod'
import { ScenarioInventoryStateZod } from './scenario-inventory-state-zod'
import { ScenarioInvestmentStateZod } from './scenario-investment-state-zod'
import { ScenarioLoanStateZod } from './scenario-loan-state-zod'
import { ScenarioMilkQuotaStateZod } from './scenario-milk-quota-state-zod'
import { YearKeyZod } from './year-key-zod'

export const ScenarioPeriodZod = z.object({
  type: z.literal('annual'),
  numberOfYears: z.number().int().positive(),
  /// denormalized from budget.year
  /// _.times(numberOfYears, (i) => String(budget.year + i)),
  projectionYears: z.array(YearKeyZod).readonly(),
  /// denormalized from budget.year
  /// _.times(numberOfYears, (i) => String(budget.year + i)),
  financeProjectionYears: z.array(YearKeyZod).readonly().optional(),
})

export const ScenarioStateDataZod = z.object({
  enabledProductions: z.array(ProductionZod).readonly().optional().default([]),
  referenceYears: z.array(YearKeyZod).readonly().optional().default([]),
  period: ScenarioPeriodZod,
  startOfYearOffset: z.number().int().optional().default(0), // denormalized from budget.client.startOfYearOffset
  referenceType: z.enum(['viagritel', 'empty']).optional().default('empty'),
  referenceId: z.string().optional(),
  referenceSyncedDate: z.date().optional(),
  selectedReferenceYear: YearKeyZod.or(z.literal('EMPTY')).optional(),
  overrides: z.record(DataKeyZod.or(z.string()), z.record(CellDataOverrideZod)).optional(),
  notes: z.record(DataKeyZod.or(z.string()), z.record(z.string())).optional(),
  loans: z.record(ScenarioLoanStateZod).optional(),
  investments: z.record(ScenarioInvestmentStateZod).optional(),
  inventories: z.record(DataKeyZod.or(z.string()), ScenarioInventoryStateZod).optional(),
  milkQuota: ScenarioMilkQuotaStateZod.optional(),
  blueprintVersion: BlueprintVersionZod.optional().default('simple-2024'), // For backward compatibility
  archived: z.boolean().optional().default(false),
})
