import { useMemo } from 'react'
import { useController } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { type LoanCategory } from '@via/schema'

import { LoanDatedInputTable } from '../LoanDatedInputTable/LoanDatedInputTable'

import { type LoanDetailFormData } from './LoanFormProvider'

interface LoanNewCapitalFormProps {
  readonly category: LoanCategory
}

export const LoanNewCapitalForm: React.FC<LoanNewCapitalFormProps> = ({ category }) => {
  const intl = useIntl()
  const { field } = useController<LoanDetailFormData, 'newCapitalBorrowed'>({ name: 'newCapitalBorrowed' })
  const labels = useMemo(
    () =>
      [
        intl.formatMessage({ id: 'loan.form.new-capital.index' }),
        intl.formatMessage({ id: 'loan.form.new-capital.date' }),
        intl.formatMessage({ id: 'loan.form.new-capital.value' }),
        intl.formatMessage({ id: 'loan.form.new-capital.payments' }),
      ] as const,
    [intl]
  )

  return <LoanDatedInputTable {...field} category={category} labels={labels} />
}
