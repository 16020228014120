import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { type UserData } from '../../types'
import { InputWithLabel } from '../InputWithLabel/InputWithLabel'

export interface UserReadonlyInformationProps {
  readonly user: UserData
}

export const UserReadonlyInformation: FC<UserReadonlyInformationProps> = ({ user }) => {
  const intl = useIntl()

  return (
    <>
      <InputWithLabel label={intl.formatMessage({ id: 'user.firstName' })} value={user.firstName} readOnly />
      <InputWithLabel label={intl.formatMessage({ id: 'user.lastName' })} value={user.lastName} readOnly />
      <InputWithLabel
        label={intl.formatMessage({ id: 'user.title' })}
        value={user.title}
        readOnly
        className="normal-case"
      />
      <InputWithLabel label={intl.formatMessage({ id: 'user.email' })} value={user.email} readOnly />
      <InputWithLabel label={intl.formatMessage({ id: 'user.phone' })} value={user.phone} readOnly />
    </>
  )
}
