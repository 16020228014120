import { type ApiMutationOptions, useApi } from './mutation/useApi.ts'

export interface SignInApiVariables {
  readonly username: string
  readonly password: string
}

export const useSignIn = (options?: ApiMutationOptions<string, SignInApiVariables>) =>
  useApi<string, SignInApiVariables>({
    type: 'signIn',
    reportErrorToSentry: false,
    ...options,
  })
