import { type FC } from 'react'

import { LineCell } from '../../../atoms/LineCell/LineCell.tsx'
import { LineTitle } from '../../../atoms/LineTitle/LineTitle.tsx'
import { lineToCellVariant } from '../../../atoms/LineTitle/lineToCellVariant.ts'
import { type LineVariant } from '../../../atoms/LineTitle/LineVariant.ts'

export interface MonetaryYearFormFieldTableGroupTitleRowProps {
  readonly title: string
  readonly variant: Exclude<LineVariant, 'divider'>
}

export const MonetaryYearFormFieldTableGroupTitleRow: FC<MonetaryYearFormFieldTableGroupTitleRowProps> = ({
  title,
  variant,
  ...props
}) => (
  <div className="flex shrink-0">
    <LineTitle label={title} className="w-72" variant={variant} collapsable={false} />
    <LineCell
      variant={lineToCellVariant(variant, 'readonlyValue')}
      {...props}
      className="w-32"
      tabIndex={-1}
      withDividerRight
    />
  </div>
)
