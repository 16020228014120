import { forwardRef, useId } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { FormLabel } from '../../atoms/FormLabel/FormLabel.tsx'
import { Input, type InputProps } from '../../atoms/Input/Input'
import { cn } from '../../lib/utils'

const inputLabelSize = cva('select-none text-xs', {
  variants: {
    labelDimension: {
      default: '',
      xs: 'text-xxs',
      sm: 'text-xs',
      xl: 'text-xl',
    },
  },
  defaultVariants: {
    labelDimension: 'default',
  },
})

export interface InputWithLabelProps extends InputProps, VariantProps<typeof inputLabelSize> {
  readonly label: string
  readonly containerClassName?: string
}

export const InputWithLabel = forwardRef<HTMLInputElement, InputWithLabelProps>(
  ({ label, id, containerClassName, labelDimension, ...props }, ref) => {
    const reactId = useId()
    return (
      <div className={cn('flex flex-col gap-1.5 pt-0.5', containerClassName)}>
        <FormLabel
          className={cn(inputLabelSize({ labelDimension: labelDimension ?? props.dimension }))}
          htmlFor={id ?? reactId}>
          {label}
        </FormLabel>
        <Input id={id ?? reactId} ref={ref} {...props} />
      </div>
    )
  }
)
