import { type ReportFormData, useBudget, useReport } from '@via/components'

import { useAppNavigation } from '../../navigation/useAppNavigation.ts'
import { useBudgetReportsHandler } from '../../worker/report/useBudgetReportsHandler.ts'

export const useReportHandler = () => {
  const budget = useBudget()
  const report = useReport()
  const handler = useBudgetReportsHandler()
  const { onNavigationChange } = useAppNavigation()

  const onAddReportClick = async () => {
    const newReport = await handler.createBudgetReport(budget._id, {
      name: '',
      scenarios: Object.keys(budget.scenarios),
      years: [],
      includesReference: false,
    })

    await onNavigationChange({
      to: '/budgets/$budgetId/reports/$reportId',
      params: { budgetId: budget._id, reportId: newReport._id },
      replace: true,
    })
  }
  const onValueChange = (data: ReportFormData) => handler.updateBudgetReport(budget._id, report._id, data)

  return {
    onAddReportClick,
    onValueChange,
  }
}
