import React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'
import { Icons } from '../Icons/Icons'

const addItemButtonVariants = cva(
  'flex w-12 py-1.5 justify-center items-center rounded border border-gray-200 active:shadow-none',
  {
    variants: {
      variant: {
        default: 'bg-gray-50 text-black hover:bg-gray-200 active:bg-gray-800 active:text-white',
        sage: 'bg-sage-500 text-white hover:bg-sage-700 active:bg-sage-900',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface AddItemButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof addItemButtonVariants> {}

export const AddItemButton = React.forwardRef<HTMLButtonElement, AddItemButtonProps>(
  ({ className, variant = 'default', children, ...props }, ref) => (
    <button type="button" className={cn(addItemButtonVariants({ variant }), className)} ref={ref} {...props}>
      <Icons.PlusSmall />
    </button>
  )
)
