import { z } from 'zod'

export const PaymentFrequencies = [
  'annual', // Annuelle
  'biannual', // Bi-annuelle ou Semestrielle
  'quarterly', // Trimestrielle
  'monthly', // Mensuelle
  'bimonthly', // Bi-mensuelle
  'weekly', // Hebdomadaire
] as const
export const PaymentFrequencyZod = z.enum(PaymentFrequencies)
export type PaymentFrequency = z.infer<typeof PaymentFrequencyZod>
