import { forwardRef } from 'react'
import { useIntl } from 'react-intl'

import { type Except } from 'type-fest'
import { z } from 'zod'

import { SheetFormProvider, type SheetFormProviderProps } from '../SheetFormProvider'

const MilkQuotaInvestmentFormZod = z.object({
  category: z.enum(['purchase', 'sale']),
})

export interface InvestmentFormProviderProps
  extends Except<
    SheetFormProviderProps<typeof MilkQuotaInvestmentFormZod>,
    'schema' | 'defaultValues' | 'onFormSubmit' | 'onFormApply' | 'title'
  > {
  readonly type: 'purchase' | 'sale'
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

export const MilkQuotaInvestmentFormProvider = forwardRef<HTMLFormElement, InvestmentFormProviderProps>(
  ({ type, children, ...props }, ref) => {
    const intl = useIntl()
    return (
      <SheetFormProvider<typeof MilkQuotaInvestmentFormZod>
        key={type}
        {...props}
        values={{ category: type }}
        readonly
        disabled
        title={intl.formatMessage({ id: `investment.form.milk-quota.${type}.title` })}
        schema={MilkQuotaInvestmentFormZod}
        ref={ref}
        onFormSubmit={noop}
        onFormApply={noop}>
        {children}
      </SheetFormProvider>
    )
  }
)
