import { type FC, type PropsWithChildren } from 'react'

import { useSubscription } from 'observable-hooks'
import { concatMap } from 'rxjs'

import { appRxDatabase } from '../../rxdb/app/appRxDatabase.ts'
import { AppWorker } from '../../worker/AppWorker.ts'
import { pullBudgets$ } from '../../worker/pull/pullBudgets.ts'
import { pullScenarios$ } from '../../worker/pull/pullScenarios.ts'

const pullScenarioCurrentStateFromFirestore$ = pullScenarios$(appRxDatabase).pipe(
  concatMap(async (scenario) => AppWorker.instance.scenarioHandler.pullScenarioCurrentStateFromFirestore(scenario))
)

const pullOfflineBudgets$ = pullBudgets$(appRxDatabase)

export const FirestorePullProvider: FC<PropsWithChildren> = ({ children }) => {
  useSubscription(pullScenarioCurrentStateFromFirestore$)
  useSubscription(pullOfflineBudgets$)
  return children
}
