import { forwardRef, useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { tupleToRecord } from '@via/compute'
import { type DataKeyDependencies, keysToTuple, type YearKey } from '@via/schema'
import { type Except } from 'type-fest'

import { SheetFormProvider, type SheetFormProviderProps } from '../SheetFormProvider'

import {
  MilkExpensesFormDependencies,
  MilkExpensesFormDependenciesContext,
} from './MilkExpensesFormDependenciesContext.ts'
import { type MilkExpensesFormData, MilkExpensesFormZod } from './MilkExpensesFormZod.ts'

export interface MilkExpensesFormSubmitData {
  readonly year: YearKey
  readonly values: Record<
    Exclude<
      DataKeyDependencies<'milk.marketing.currency-per-hectoliter'>,
      | 'milk.production.content.kilogram-per-hectoliter.fat'
      | 'milk.production.content.kilogram-per-hectoliter.protein'
      | 'milk.production.content.kilogram-per-hectoliter.lactose'
    >,
    string | null
  >
}

export interface MilkExpensesFormProviderProps
  extends Except<
    SheetFormProviderProps<typeof MilkExpensesFormZod>,
    'schema' | 'defaultValues' | 'onFormSubmit' | 'onFormApply' | 'title' | 'onDelete'
  > {
  readonly year: YearKey
  readonly onSubmit: (value: MilkExpensesFormSubmitData) => Promise<void>
  readonly onApply: (value: MilkExpensesFormSubmitData) => Promise<void>
}

const milkExpensesSubmitData = (year: YearKey, data: MilkExpensesFormData): MilkExpensesFormSubmitData => ({
  year,
  values: Object.fromEntries(
    Object.entries(MilkExpensesFormDependencies)
      .filter(
        ([, dataKey]) =>
          ![
            'milk.sale.hectoliter',
            'milk.production.volume.hectoliter.total',
            'milk.production.content.kilogram-per-hectoliter.fat',
            'milk.production.content.kilogram-per-hectoliter.protein',
            'milk.production.content.kilogram-per-hectoliter.lactose',
          ].includes(dataKey)
      )
      .map(([key, dataKey]) => [dataKey, data[key as keyof typeof MilkExpensesFormDependencies] ?? null])
  ) as Record<DataKeyDependencies<'milk.marketing.currency-per-hectoliter'>, string | null>,
})

export const MilkExpensesFormProvider = forwardRef<HTMLFormElement, MilkExpensesFormProviderProps>(
  ({ year, onSubmit, onApply, children, ...props }, ref) => {
    const intl = useIntl()

    const onFormSubmit = useCallback(
      async (values: MilkExpensesFormData) => {
        await onSubmit(milkExpensesSubmitData(year, values))
      },
      [year, onSubmit]
    )

    const onFormApply = useCallback(
      async (values: MilkExpensesFormData) => {
        await onApply(milkExpensesSubmitData(year, values))
      },
      [year, onApply]
    )

    const dependencies = useContext(MilkExpensesFormDependenciesContext)
    const formValues = useMemo(
      () =>
        MilkExpensesFormZod.parse({
          ...tupleToRecord(keysToTuple(MilkExpensesFormDependencies), (key) =>
            String(dependencies?.[key]?.override?.value ?? '')
          ),
        }),
      [dependencies]
    )
    return (
      <SheetFormProvider<typeof MilkExpensesFormZod>
        {...props}
        title={intl.formatMessage({ id: 'milk.expenses.form.title' }, { year })}
        values={formValues}
        schema={MilkExpensesFormZod}
        ref={ref}
        onFormSubmit={onFormSubmit}
        onFormApply={onFormApply}>
        {children}
      </SheetFormProvider>
    )
  }
)
