import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { useControllableState } from '@radix-ui/react-use-controllable-state'
import { type Except } from 'type-fest'

import { Label } from '../../atoms/Label/Label'
import { RadioGroup } from '../../atoms/Radio/Radio.tsx'
import { type CropsWeights } from '../../blueprints/cropsWeights.ts'
import { MonetaryTableEditingStateProvider } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import { RadioWithLabel } from '../../molecules/RadioWithLabel/RadioWithLabel.tsx'
import {
  ScenarioMonetaryLayout,
  type ScenarioMonetaryLayoutProps,
} from '../../organisms/ScenarioMonetaryLayout/ScenarioMonetaryLayout.tsx'

import { useCropsResultsBlueprint } from './useCropsResultsBlueprint.ts'

type CropsWeight = keyof typeof CropsWeights | 'none'

export type CropsResultsViewProps = Except<ScenarioMonetaryLayoutProps, 'currentScenarioNavigationId' | 'blueprint'>

export const CropsResultsView: FC<CropsResultsViewProps> = ({ scenario, currentWeight, onWeightChange, ...props }) => {
  const intl = useIntl()
  const blueprint = useCropsResultsBlueprint(scenario)
  const [weight, setWeight] = useControllableState({
    prop: currentWeight,
    onChange: onWeightChange,
    defaultProp: 'none',
  })

  return (
    <MonetaryTableEditingStateProvider>
      <ScenarioMonetaryLayout
        currentScenarioNavigationId="crops-results"
        scenario={scenario}
        blueprint={blueprint}
        currentWeight={weight}
        onWeightChange={setWeight}
        {...props}>
        <div className="flex">
          <div className="sticky left-36 pl-8 pt-6">
            <div className="flex items-center">
              <div className="flex flex-col pr-8">
                <Label className="text-2xl font-normal text-black">
                  {intl.formatMessage({ id: 'crops.results.title' })}
                </Label>
                <Label className="text-2xl font-bold text-black">
                  {intl.formatMessage({ id: 'crops.results.subTitle' })}
                </Label>
              </div>
              <div>
                <RadioGroup
                  onValueChange={(v) => {
                    setWeight(v as CropsWeight)
                  }}
                  value={weight}
                  className="flex flex-wrap gap-x-8">
                  <RadioWithLabel value="none" label={intl.formatMessage({ id: 'weight.none' })} size="sm" />
                  <RadioWithLabel value="ha" label={intl.formatMessage({ id: 'weight.crops.yield.ha' })} size="sm" />
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
      </ScenarioMonetaryLayout>
    </MonetaryTableEditingStateProvider>
  )
}
