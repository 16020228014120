import { createFileRoute, redirect } from '@tanstack/react-router'

import { getScenarioPreviousPage } from '../pages/scenarioUpdate/utils/scenarioPage.ts'

export const Route = createFileRoute('/budgets/$budgetId/scenarios/$scenarioId/')({
  beforeLoad: ({ params }) => {
    const to = getScenarioPreviousPage(params.budgetId, params.scenarioId)
    return redirect({
      from: '/budgets/$budgetId/scenarios/$scenarioId/',
      to,
      params,
      replace: true,
    })
  },
})
