import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint.ts'
import { dataLine, headerLine } from '../utils.ts'

export const milkAnimalsSheet2024Blueprint = {
  hideReferenceLabel: true,
  sections: [
    {
      id: 'animals.income.section',
      titleKey: 'table.milk.animals.sale.section',
      showBottomHeader: false,
      collapsible: false,
      containerClassName: 'pb-2',
      children: [
        {
          ...headerLine('table.milk.animals.sale.header'),
          children: [
            {
              ...dataLine('milk.animals.income.cull.head-count', {
                alwaysVisible: true,
                unit: 'head-count',
                variant: 'section',
              }),
              children: [
                dataLine('milk.animals.income.cull.proportion', { unit: 'percent' }),
                dataLine('milk.animals.income.cull.value-per-head-count', { unit: 'currency-per-head-count' }),
                dataLine('milk.animals.income.cull.value', { unit: 'currency' }),
              ],
            },
            {
              ...dataLine('milk.animals.income.breed.head-count', {
                alwaysVisible: true,
                unit: 'head-count',
                variant: 'section',
              }),
              children: [
                dataLine('milk.animals.income.breed.value-per-head-count', { unit: 'currency-per-head-count' }),
                dataLine('milk.animals.income.breed.value', { unit: 'currency' }),
              ],
            },
            {
              ...dataLine('milk.animals.income.calf.head-count', {
                alwaysVisible: true,
                unit: 'head-count',
                variant: 'section',
              }),
              children: [
                dataLine('milk.animals.income.calf.females-kept-proportion', { unit: 'percent' }),
                dataLine('milk.animals.income.calf.value-per-head-count', { unit: 'currency-per-head-count' }),
                dataLine('milk.animals.income.calf.value', { unit: 'currency' }),
              ],
            },
            {
              ...dataLine('milk.animals.income.other.head-count', {
                alwaysVisible: true,
                unit: 'head-count',
                variant: 'section',
              }),
              children: [
                dataLine('milk.animals.income.other.value-per-head-count', { unit: 'currency-per-head-count' }),
                dataLine('milk.animals.income.other.value', { unit: 'currency' }),
              ],
            },
          ],
        },
        dataLine('milk.animals.income.total', { closingSection: true, unit: 'currency', variant: 'total' }),
      ],
    },
    {
      id: 'animals.expenses.section',
      collapsible: false,
      showBottomHeader: false,
      children: [
        {
          ...headerLine('table.milk.animals.expenses.header'),
          children: [
            {
              ...dataLine('milk.animals.expenses.cows.head-count', {
                alwaysVisible: true,
                unit: 'head-count',
                variant: 'section',
              }),
              children: [
                dataLine('milk.animals.expenses.cows.value-per-head-count', { unit: 'currency-per-head-count' }),
                dataLine('milk.animals.expenses.cows.value', { unit: 'currency' }),
              ],
            },
            {
              ...dataLine('milk.animals.expenses.other.head-count', {
                alwaysVisible: true,
                unit: 'head-count',
                variant: 'section',
              }),
              children: [
                dataLine('milk.animals.expenses.other.value-per-head-count', { unit: 'currency-per-head-count' }),
                dataLine('milk.animals.expenses.other.value', { unit: 'currency' }),
              ],
            },
          ],
        },
        dataLine('milk.animals.expenses.total', { closingSection: true, unit: 'currency', variant: 'total' }),
      ],
    },
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true, className: 'w-84' },
    {
      id: 'selected-reference',
      type: 'reference',
      sticky: true,
      className: 'w-[7.5rem]',
      topHeaderClassName: 'w-[7.5rem] h-14 border-t border-t-gray-400',
      withDividerRight: true,
      showUnitInCell: true,
    },
    {
      id: 'divider',
      type: 'divider',
      className: 'w-1',
    },
    {
      id: 'projections',
      type: 'projections',
      withDividerRight: true,
      className: 'w-[7.5rem]',
      topHeaderVariant: 'head',
      topHeaderClassName: 'w-[7.5rem] h-14 border-t border-t-gray-400',
      showUnitInCell: true,
    },
  ],
} as const satisfies Except<BlueprintTable, 'version'>
