import type React from 'react'

import { type ReadonlyDeep } from 'type-fest'

import { ZodForm } from '../../atoms/Form/ZodForm'

import { type ReportFormData, ReportFormZod } from './ReportFormZod.ts'

export type ReportFormProviderProps = React.PropsWithChildren<{
  readonly data: Partial<ReadonlyDeep<ReportFormData>>
}>

export const ReportFormProvider: React.FC<ReportFormProviderProps> = ({ data, children }) => {
  const defaultData = ReportFormZod.parse(data)

  return (
    <ZodForm schema={ReportFormZod} defaultValues={defaultData}>
      {children}
    </ZodForm>
  )
}
