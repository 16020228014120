import { z } from 'zod'

import { FormFiniteNumberValueZod } from '../../atoms/Form/FormFiniteNumberValueZod.ts'
import { FormSafeIntegerValueZod } from '../../atoms/Form/FormSafeIntegerValueZod.ts'

export const InventoryProjectionFormZod = z.object({
  unitBalance: FormFiniteNumberValueZod,
  unitValue: FormFiniteNumberValueZod,
  value: FormFiniteNumberValueZod,
  purchase: FormSafeIntegerValueZod,
  purchaseCost: FormFiniteNumberValueZod,
  sale: FormSafeIntegerValueZod,
  saleCost: FormFiniteNumberValueZod,
  note: z.string().optional().default(''),
})

export type InventoryProjectionFormData = z.infer<typeof InventoryProjectionFormZod>
