import { type FC, forwardRef, Fragment, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { yearKeyFromDate } from '@via/compute'
import { sortBy } from 'lodash-es'

import { Label } from '../../atoms/Label/Label.tsx'
import { useScenario } from '../../context'
import { useMonetaryRowValues } from '../../context/monetary/useMonetaryRowValues.ts'
import { cn } from '../../lib/utils'
import { FormatedNumberReadonlyInput } from '../../molecules/FormatedNumberReadonlyInput/FormatedNumberReadonlyInput.tsx'
import { InvestmentCategoryRadioGroupFormField } from '../InvestmentCategoryRadioGroupFormField/InvestmentCategoryRadioGroupFormField.tsx'
import { SheetFormFooter } from '../SheetFormProvider'

import {
  type InvestmentFormProviderProps,
  MilkQuotaInvestmentFormProvider,
} from './MilkQuotaInvestmentFormProvider.tsx'

export type MilkQuotaInvestmentFormProps = InvestmentFormProviderProps

const MilkQuotaTransaction: FC<{ readonly type: 'purchase' | 'sale' }> = ({ type }) => {
  const intl = useIntl()
  const { milkQuota = {}, startOfYearOffset } = useScenario()
  const quotaPrice = useMonetaryRowValues({ key: 'milk.quota.asset.cost' })

  const model = useMemo(
    () =>
      sortBy(Object.entries(milkQuota), '[0]')
        .filter(([, value]) => value[type])
        .map(([month, value]) => {
          const year = yearKeyFromDate(month, startOfYearOffset)
          const unit = value[type] ? Number(value[type]) : 0
          const price = quotaPrice?.[year]?.value ?? 0
          return {
            month,
            unit,
            price,
            value: price * unit,
          }
        }),
    [type, milkQuota, quotaPrice, startOfYearOffset]
  )

  return (
    <div className="grid grid-cols-10 gap-0.5">
      <Label className="col-span-2 bg-gray-100 p-1.5 font-bold">
        {intl.formatMessage({ id: 'investment.form.milk-quota.date' })}
      </Label>
      <Label className="col-span-2 bg-gray-100 p-1.5 text-end font-bold">
        {intl.formatMessage({ id: 'investment.form.milk-quota.unit' })}
      </Label>
      <Label className="col-span-3 bg-gray-100 p-1.5 text-end font-bold">
        {intl.formatMessage({ id: 'investment.form.milk-quota.price' })}
      </Label>
      <Label className="col-span-3 bg-gray-100 p-1.5 text-end font-bold">
        {intl.formatMessage({ id: 'investment.form.milk-quota.value' })}
      </Label>

      {model.map(({ month, unit, price, value }) => (
        <Fragment key={month}>
          <div className="col-span-2 mb-px flex self-stretch justify-self-stretch bg-white">
            <div className="self-center px-1.5">
              <p className="text-sm">{month}</p>
            </div>
          </div>
          <FormatedNumberReadonlyInput
            align="right"
            value={unit}
            displayType="kilogram-of-fat-per-day"
            className="col-span-2 rounded-none border-0 border-b border-b-gray-200 bg-white shadow-none focus:border-0 focus:border-b focus:border-blue-600 focus:ring-0 focus-visible:border-0 focus-visible:border-b focus-visible:border-blue-600 focus-visible:ring-0"
          />
          <FormatedNumberReadonlyInput
            align="right"
            value={price}
            displayType="currency-with-symbol"
            className="col-span-3 rounded-none border-0 border-b border-b-gray-200 bg-white shadow-none focus:border-0 focus:border-b focus:border-blue-600 focus:ring-0 focus-visible:border-0 focus-visible:border-b focus-visible:border-blue-600 focus-visible:ring-0"
          />
          <FormatedNumberReadonlyInput
            align="right"
            value={value}
            displayType="currency-with-symbol"
            className="col-span-3 rounded-none border-0 border-b border-b-gray-200 bg-white shadow-none focus:border-0 focus:border-b focus:border-blue-600 focus:ring-0 focus-visible:border-0 focus-visible:border-b focus-visible:border-blue-600 focus-visible:ring-0"
          />
        </Fragment>
      ))}
    </div>
  )
}

export const MilkQuotaInvestmentForm = forwardRef<HTMLFormElement, MilkQuotaInvestmentFormProps>(
  ({ className, type, ...props }, ref) => (
    <MilkQuotaInvestmentFormProvider
      className={cn('w-[614px] bg-gray-100', className)}
      ref={ref}
      type={type}
      {...props}>
      <div className="flex flex-col gap-6 pb-6">
        <nav className="px-6 pt-6">
          <InvestmentCategoryRadioGroupFormField name="category" />
        </nav>

        <main className="bg-gray-100 pb-6 pl-6 pr-4 pt-4">
          <MilkQuotaTransaction type={type} />
        </main>
      </div>

      <SheetFormFooter />
    </MilkQuotaInvestmentFormProvider>
  )
)
