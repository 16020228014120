import { type BlueprintVersion, type DataKey, resolveAttributes } from '@via/schema'
import { BigNumber } from 'bignumber.js'
import { type Except } from 'type-fest'

import { cn } from '../../lib/utils'
import { type OverridableMonetaryCellData } from '../../model'
import { InlineEditingCellForm } from '../../molecules/InlineEditingCell/InlineEditingCellForm.tsx'
import { MonetaryProjectionWidget } from '../../monetary/MonetaryProjectionWidget/MonetaryProjectionWidget'

import { PopoverEditableValueCell, type PopoverEditableValueCellProps } from './PopoverEditableValueCell'
import { type UpdateCellValue } from './UpdateCellValue.ts'

interface EditableProjectionValueCellProps extends Except<PopoverEditableValueCellProps, 'children'> {
  readonly dataKey: DataKey
  readonly blueprintVersion: BlueprintVersion
  readonly onUpdateCell: UpdateCellValue<'result-projections'>
  readonly weight?: DataKey
}

const computeOverride = (
  key: DataKey,
  blueprintVersion: BlueprintVersion,
  cellData?: OverridableMonetaryCellData,
  value?: string,
  weight?: DataKey
) => {
  if (!value) {
    return {}
  }
  const attributes = resolveAttributes(key, blueprintVersion)
  if (attributes?.type === 'overridable' && cellData?.baseValue) {
    const diff = new BigNumber(cellData?.baseValue).minus(value)
    return diff.gte(0) ? { subtract: diff.toString() } : { add: diff.negated().toString() }
  }
  return { value, ...(weight ? { weight } : {}) }
}

export const EditableProjectionValueCell = ({
  dataKey,
  blueprintVersion,
  cellData,
  onUpdateCell,
  weight,
  className,
  ...props
}: EditableProjectionValueCellProps) => (
  <PopoverEditableValueCell cellData={cellData} className={className} {...props}>
    {({ type, onClose }) =>
      type === 'inline' ? (
        <InlineEditingCellForm
          value={cellData.value}
          onConfirm={async (value) => {
            await onUpdateCell({
              type: 'result-projections',
              dataKey,
              override: computeOverride(dataKey, blueprintVersion, cellData, value, weight),
            })
            onClose()
          }}
          className={cn('-mt-6', className)}
        />
      ) : (
        <MonetaryProjectionWidget
          dataKey={dataKey}
          blueprintVersion={blueprintVersion}
          value={cellData}
          onConfirm={async (value) => {
            await onUpdateCell({ type: 'result-projections', dataKey, ...value })
            onClose()
          }}
          onApply={async (value) => {
            await onUpdateCell({ type: 'result-projections', dataKey, ...value })
          }}
        />
      )
    }
  </PopoverEditableValueCell>
)
