import { useMemo } from 'react'
import { useController } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { InvestmentDatedInputTable } from '../InvestmentDatedInputTable/InvestmentDatedInputTable'

import { type InvestmentFormData } from './InvestmentFormZod'

export const InvestmentDisbursementForm = () => {
  const intl = useIntl()
  const { field } = useController<InvestmentFormData, 'disbursement'>({ name: 'disbursement' })
  const labels = useMemo(
    () =>
      [
        intl.formatMessage({ id: 'investment.form.disbursement.index' }),
        intl.formatMessage({ id: 'investment.form.disbursement.date' }),
        intl.formatMessage({ id: 'investment.form.disbursement.value' }),
      ] as const,
    [intl]
  )

  return <InvestmentDatedInputTable {...field} labels={labels} />
}
