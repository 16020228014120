import { z } from 'zod'

import { ClientInformationZod, UserRoleZod, UserUpdateZod } from '../common'
import { YearKeyZod } from '../monetary'

export const BudgetUserZod = z.object({
  id: z.string(),
  name: z.string(),
  role: UserRoleZod,
})

export const BudgetZod = z.object({
  archived: z.boolean(),
  billable: z.boolean().optional().default(false),
  clientId: z.string(),
  client: ClientInformationZod,

  contractId: z.string().optional(),
  name: z.string(),
  year: z.number().positive(),
  status: z.enum(['todo', 'waiting', 'completed', 'inProgress', 'approving']),
  referenceVersion: z.number().int().optional(),
  referenceYears: z.array(YearKeyZod).optional().default([]),
  users: z
    .object({
      agronomist: BudgetUserZod.optional(),
      technician: BudgetUserZod.optional(),
      collaborator: BudgetUserZod.optional(),
    })
    .optional()
    .default({}),
  members: z.array(z.string()).optional().default([]),

  owner: z
    .object({
      userId: z.string(),
      userName: z.string(),
      userRole: UserRoleZod,
      timestamp: z.date(),
    })
    .optional(),

  scenarios: z
    .record(
      z.object({
        name: z.string(),
        creationTimestamp: z.date(),
        numberOfYears: z.number().positive(),
      })
    )
    .optional()
    .default({}),

  creation: UserUpdateZod,
  lastModification: UserUpdateZod,
})
