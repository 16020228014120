import { type HtmlHTMLAttributes } from 'react'

import { useSessionStorageValue } from '@react-hookz/web/useSessionStorageValue/index.js'
import {
  type Column,
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  type SortingState,
  type Updater,
  useReactTable,
} from '@tanstack/react-table'
import { deburr, get, isString } from 'lodash-es'

import { Icons } from '../../atoms'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../atoms/Table/Table'
import { cn } from '../../lib/utils'
import { ListHeader, type ListHeaderData } from '../ListHeader/ListHeader'

// https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
type Leaves<T> = T extends object
  ? { [K in keyof T]: `${Exclude<K, symbol>}${Leaves<T[K]> extends never ? '' : `.${Leaves<T[K]>}`}` }[keyof T]
  : never

export type DataTableColumnDef<TData, TValue = unknown> = ColumnDef<TData, TValue> & {
  headerClassName?: string
  cellClassName?: string
}

export interface DataTableData<TData, TValue = unknown> extends ListHeaderData {
  readonly tableId: string
  readonly emptyLabel: string
  readonly columns: Array<DataTableColumnDef<TData, TValue>>
  readonly data: TData[]
  readonly filterKeys?: Array<Leaves<TData>>
  readonly sorting?: SortingState
}

export interface DataTableActions {
  onSortingChange(sorting: Updater<SortingState>): void
  readonly onNewClick?: () => void
  readonly onRowClick?: (id: string) => void
}

export interface DataTableProps<TData, TValue>
  extends DataTableData<TData, TValue>,
    DataTableActions,
    HtmlHTMLAttributes<HTMLDivElement> {}

export const DataTable = <TData, TValue>({
  tableId,
  titleLabel,
  emptyLabel,
  filterOptions,
  searchPlaceholderLabel,
  newButtonLabel,
  newOptions,
  columns,
  data,
  onNewClick,
  onRowClick,
  className,
  filterKeys,
  sorting,
  onSortingChange,
  ...props
}: DataTableProps<TData, TValue>) => {
  const { value: globalFilter, set: setGlobalFilter } = useSessionStorageValue<string>(`${tableId}-global-filter`)

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: (newSorting) => {
      onSortingChange(newSorting)
    },
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: filterKeys
      ? (row, _, filterValue) => {
          if (!isString(filterValue)) {
            return true
          }

          const item = data[row.index]
          const valuesToApplyFilterValue = filterKeys.map((key) => get(item, key))

          const filters = filterValue.split(/\s+/)

          return filters.every((filter) =>
            valuesToApplyFilterValue.some((value) => {
              if (!isString(value)) {
                return false
              }

              return deburr(value.toLowerCase()).includes(deburr(filter.toLowerCase()))
            })
          )
        }
      : undefined,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter,
    },
  })

  return (
    <div className={cn('w-full overflow-hidden rounded-lg', className)} {...props}>
      <ListHeader
        titleLabel={titleLabel}
        filterOptions={filterOptions}
        searchPlaceholderLabel={searchPlaceholderLabel}
        newButtonLabel={newButtonLabel}
        initialSearchValue={globalFilter}
        onSearchChange={
          filterKeys
            ? (searchTerm) => {
                setGlobalFilter(searchTerm)
              }
            : undefined
        }
        onNewClick={onNewClick}
        newOptions={newOptions}
      />
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead
                  key={header.id}
                  className={(header.column.columnDef as DataTableColumnDef<TData, TValue>).headerClassName}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className={onRowClick && 'cursor-pointer'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    onClick={
                      onRowClick && !cell.id.includes('actions')
                        ? () => {
                            onRowClick?.(row.id)
                          }
                        : undefined
                    }
                    className={(cell.column.columnDef as DataTableColumnDef<TData, TValue>).cellClassName}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {emptyLabel}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

interface DataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  readonly column: Column<TData, TValue>
  readonly title: string
}

export const DataTableColumnHeader = <TData, TValue>({
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) => {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>
  }

  return (
    <button
      type="button"
      onClick={() => {
        column.toggleSorting(column.getIsSorted() === 'asc')
      }}
      className={cn('flex w-full items-center gap-1', className)}>
      <span>{title}</span>

      {column.getIsSorted() === 'desc' ? (
        <Icons.ArrowDownShort className="size-4 shrink-0" />
      ) : column.getIsSorted() === 'asc' ? (
        <Icons.ArrowUpShort className="size-4 shrink-0" />
      ) : (
        <div />
      )}
    </button>
  )
}
