import { BigNumber } from 'bignumber.js'

import {
  createBudgetDefinition,
  type CriReference,
  type CriReferenceFunction,
  type DatabaseResolverFnAliments,
  type DatabaseResolverFnAnimaux,
  type DatabaseResolverFnCompteExploitation,
  type DatabaseResolverFnCourtTerme,
  type DatabaseResolverFnFicheTechnique,
  type DatabaseResolverFnInventaireApprovisionnement,
} from '../types'
import { isFermeBudgitel2021, sumBy } from '../utils'
import { type ViagritelCourtTermeType, type ViagritelCourtTermeTypeLigne, type ViagritelProduction } from '../values'

import { foodExpenses } from './utils'

const genericProductions = new Set<ViagritelProduction>([
  'AX',
  'BS',
  'CV',
  'DG',
  'DL',
  'LP',
  'OC',
  'OI',
  'OT',
  'PG',
  'PS',
  'ST',
  'TA',
  'VL',
  'WA',
])

const expectedValueTestResolver = (testValueForFermeBudgitel2021?: number) =>
  testValueForFermeBudgitel2021
    ? [
        {
          condition: isFermeBudgitel2021,
          expectedValue: new BigNumber(testValueForFermeBudgitel2021),
        },
      ]
    : (false as const)

const criOrExpectedValueTestResolver = (
  testCris?: CriReference | CriReference[],
  testValueForFermeBudgitel2021?: number,
  only = false
) => ({
  test: testCris
    ? ({ cri }: { cri: CriReferenceFunction }) => {
        if (Array.isArray(testCris)) {
          return testCris.reduce((acc, testCri) => acc.plus(cri(testCri)), new BigNumber(0))
        }

        return cri(testCris)
      }
    : expectedValueTestResolver(testValueForFermeBudgitel2021),
  only,
})

// animal.[production].income.sales.products.amount
// [CE CR]
//  + SOMME
//    + Vente de lait et primes sur lait livré (CR)
//    + Vente chevreaux lait
//    + Vente chevreaux lourds (CR)
// [Aux CE (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA)]
//  + Produits des animaux
//  + Vente d'élevage jeunes
//  + Vente d'élevage de finition
//  + Vente autres animaux
// [CE IN]
//  + Vente agneaux lait
//  + Vente agneaux légers
//  + Vente agneaux lourds
//  + Vente lait
//  + Vente de laine
// [CE NA]
//  + Vente de cochettes
//  + Vente de porcelets
//  + Vente de porcelets à forfait (NA)
// [CE FI]
//  + Vente de porcs
//  + Vente de porcs à forfait
//  + Vente de porc autres (FI)
// [CE JV]
//  + Vente aux enchères
//  + Ventes aux particuliers
//  + Vente rejets (JV)
// [CE BI]
//  + Vente pour abattage
//  + Vente semi-finition (BI) avec code production = [production]
const incomeSalesProductsAmountResolver = (
  production: ViagritelProduction,
  testCris?: CriReference | CriReference[]
) => ({
  testResolver: criOrExpectedValueTestResolver(
    genericProductions.has(production) ? incomeSalesProductsAmountGenericCris(production) : testCris
  ),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) => {
      const ceLignes = [
        190_010, // Vte de lait (CR8)
        190_011, // Primes sur lait livré (CR39)
        190_040, // Vte chevreaux lait (CR14)
        190_050, // Vte chevreaux lourds (CR16)
        60_100, // Produits des animaux (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) ($production34)
        60_500, // Vte d'élevage jeunes (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) ($production15)
        60_600, // Vte d'élevage de finition (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) ($production17)
        60_700, // Vte autres animaux (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) ($production19)
        110_100, // Vte agneaux de lait (IN9)
        110_200, // Vte agneaux légers (IN11)
        110_300, // Vte agneaux lourds (IN13)
        111_250, // Vente de lait (IN448)
        111_400, // Vente de laine (IN27)
        40_050, // Vte de cochettes (NA404)
        40_100, // Vte de porcelets (NA9)
        40_200, // Vte porcelets à forfait (NA11)
        50_100, // Vte de porcs (FI9)
        50_200, // Vte porcs à forfait (FI11)
        50_250, // Vte porcs autres (FI444)
        180_100, // Vte à l'enchère (JV9)
        180_200, // Vte aux particuliers (JV11)
        180_300, // Vte de rejets (JV13)
        70_100, // Vte pour abattage (BI9)
        70_200, // Vte de semi-finis (BI11)
      ]
      return sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
    },
  },
})

const incomeSalesProductsAmountGenericCris = (production: ViagritelProduction): CriReference[] => [
  `${production}9`, // Produits des animaux (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA)
  `${production}15`, // Vte d'élevage jeunes (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA)
  `${production}17`, // Vte d'élevage de finition (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA)
  `${production}19`, // Vte autres animaux (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA)
]

// animal.[production].income.sales.cull.amount
//   Au CE, Somme (
//     Vente pour réforme (CR),
//     Vente adulte fem. reforme (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA)+
//   Au CE IN,
//     ligne Vente réforme brebis+belier (IN), +
//   Au CE NA
//     ligne Vente truies réforme,+
//     ligne  Vente verrats (NA)
const incomeSalesCullAmountResolver = (production: ViagritelProduction, testCris?: CriReference | CriReference[]) => ({
  testResolver: criOrExpectedValueTestResolver(genericProductions.has(production) ? `${production}11` : testCris),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) => {
      const ceLignes = [
        190_020, // Vte pour réforme (CR10)
        60_300, // Vte adultes fem.réform. (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA)
        110_700, // Vte réf.brebis+bélier (IN21)
        40_300, // Vte truies de réforme (NA13)
        40_400, // Vte de verrats (NA14)
      ]
      return sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
    },
  },
})

// animal.[production].income.sales.breeders.amount
// Au CE CR
//   ligne Vente reproducteurs (CR) +
// Aux CE  (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA)
//   ligne Vente adultes femelles reproduction +
// Au CE IN
//   ligne Vente brebis product.+
//   ligne vente agnelles product +
//   ligne Ventes béliers product. (IN) +
// Au CE FI,
//   ligne Vente verrats reprod +
//   ligne vente de truies reprod. (FI),
// Au CE JV
//   Vente veaux sevrés (JV)
const incomeSalesBreedersAmountResolver = (
  production: ViagritelProduction,
  testCris?: CriReference | CriReference[]
) => ({
  testResolver: criOrExpectedValueTestResolver(genericProductions.has(production) ? `${production}13` : testCris),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) => {
      const ceLignes = [
        190_030, // Vte de reproducteurs (CR12)
        60_400, // Vte adultes fem. reprod. (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) ($production13)
        110_400, // Vte brebis product. (IN15)
        110_500, // Vte agnelles produc. (IN17)
        110_600, // Vte béliers  product. (IN19)
        50_300, // Vte verrats reprod. (FI13)
        50_400, // Vte truies reprod. (FI15)
        180_350, // Vte veaux sevrés (JV403)
      ]
      return sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
    },
  },
})

// animal.[production].income.insurance.stabilization.amount
// AU CE (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS,ST, TA, VL, WA),
//   ligne Revenus de stabilisation ,
// Au CE IN,
//   ligne Revenu stabilisation lait +
//   ligne revenu stabilisation lourds (IN), +
// Au CE NA,
//   ligne Revenu de stabllisation (NA)  +
// Au CE FI
//   ligne Revenu de stabilisation (FI) +
// Au CE JV
//   ligne, Revenus stabilisation (JV),
// Au CE BI,
//   ligne Revenu stabilisation (BI) avec  codes de production
const incomeInsuranceStabilizationAmountCris: Partial<Record<ViagritelProduction, CriReference[]>> = {
  IN: ['IN22'],
  NA: ['NA15'],
  FI: ['FI17'],
  JV: ['JV15'],
  BI: ['BI15'],
}
const incomeInsuranceStabilizationAmountResolver = (production: ViagritelProduction) => ({
  testResolver: criOrExpectedValueTestResolver(
    genericProductions.has(production) ? `${production}21` : incomeInsuranceStabilizationAmountCris[production]
  ),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) => {
      const ceLignes = [
        60_900, // Revenus stabilisation (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL, WA) ($production21)
        110_900, // Rev. ass. Stabilisation ( lait ) (IN22)
        110_950, // Rev. ass. Stabilisation (lourd) (IN22)
        40_600, // Rev. de stabilisation (NA15)
        50_600, // Rev.  stabilisation (FI17)
        180_500, // Rev. stabilisation (JV15)
        70_500, // Rev. de stabilisation (BI15)
      ]
      return sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
    },
  },
})

// animal.[production].income.inventory.breeders.amount
// Fiches animaux:
//   Somme des variations d'inventaire qté animaux par catégorie d'animaux de reproduction  soit
//   (Nb  de têtes fin - nb têtes début)x Prix /tête fin pour productions CR, IN, NA, LP,CV, OC,OI, TA, Comme calcul lait, vache-veau
const incomeInventoryBreedersAmount = (
  production: ViagritelProduction | ViagritelProduction[],
  testCris?: CriReference | CriReference[]
) => ({
  testResolver: criOrExpectedValueTestResolver(testCris),
  databaseResolver: {
    resolve: ({ animaux }: { animaux: DatabaseResolverFnAnimaux }) =>
      animaux({ production, type: 'breeding' }).reduce(
        (total, animal) => total.plus(animal.prix_tete_fin.times(animal.inventaire_fin.minus(animal.inventaire_debut))),
        new BigNumber(0)
      ),
  },
})

// animal.[production].income.inventory.slaughter.amount
//   Fiche animaux:
//     Somme des valeurs calculées fin des catégories d'animaux d'abattage par production animale
//       (1,. Poids par tête fin x $/poids moyen = $ fin par tête 2, Nb de têtes fin x $/tête fin  = Valeur de la catégorie fin)-
//     Somme des valeurs calculées début des catégories d'animaux d'abattage par production animale
//       (1. Poids par tête début  x $/poids moyen = $ début par tête 2, Nb de têtes début x $/tête début= Valeur de la catégorie début) pour les productions CR, AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA, IN, NA, FI,JV et BI
const incomeInventorySlaughterAmount = (
  production: ViagritelProduction | ViagritelProduction[],
  testValueForFermeBudgitel2021?: number
) => ({
  testResolver: expectedValueTestResolver(testValueForFermeBudgitel2021),
  databaseResolver: {
    resolve: ({ animaux }: { animaux: DatabaseResolverFnAnimaux }) =>
      animaux({ production, type: 'market' }).reduce(
        (total, animal) => total.plus(animal.valeur_totale_fin.minus(animal.valeur_totale_debut)),
        new BigNumber(0)
      ),
  },
})

// animal.[production].income.miscellaneous.amount
// Defined in 3 parts: deductions, operational and short term
// - animal.[production].income.deductions.amount
// - animal.[production].income.operational.amount
// - animal.[production].income.short.amount

// [CE: CR, AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL, WA]
//  + Autres revenus directs
// [CE IN]
//  + Subvention sur animaux reprod
//  + Autres subventions directes
//  + Autres revenus directs (IN)
// [CE NA]
//  + Revenus transport animaux
//  + Autres revenus directs (NA)
// [CE FI]
//  + Prime spécifique
//  + Revenus transport animaux
//  + Autres revenus directs (FI)
// [CE JV]
//  + Subvention directes animaux
//  + Autres revenus directs (JV)
// [CE BI]
//  + Engraissement à forfait
//  + Autres revenus directs (BI)
// [Court terme]
//  + Somme G/P sur C/P et R/A et GP sur C/R et P/A codes de production CR, AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL, WA, IN, NA, FI, JV, BI
//  + C/R G/P sur ASRA Bouvillons
//  + C/R G/P sur ASRA Porcs
//  + C/R G/P sur ASRA veaux de grain
//  + C/R G/P sur ASRA Porcelets
//  + C/R G/P sur ASRA Veaux de lait
//  + C/R G/P sur ASRA Ovins de lait
//  + C/R G/P sur ASRA Ovins lourds
// [Fiches techniques des productions AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL, WA]
//  + Prélèvement animaux stade jeunes $
//  + Prélèvements d'animaux stade finition $
//  + Prélèvements d'autres animaux $
// [Fiche technique Chèvre (CR)]
//  + Prélèvement d'animaux famille $
// [Fiche technique Bouvillons (BI)]
//  + Prélèvement bouvillons $
// [Fiche technique IN]
//  + Prélèv. agneaux lait famille $
//  + Prélèv. agneaux légers famille $
//  + Prélèv. agneaux lourds par famille $
//  + Prélèv. autres animaux pour la famille $
// [Fiche technique Porcelets (NA)]
//  + Prélèvement d'animaux par la famille $
// [Fiche technique Porcs (FI)]
//  + Prélèvement d'animaux famille $
// [Prélèvement veau de grain (JV)]
//  + Fiche technique veau de grain Prélèvement d'animaux pour la famille kg carc x
//  + (au CE veau grain, ligne Vte à l'enchère $ / Fiche technique veau de grain ligne Poids total de veaux vendus enchère kg carc.)

const incomeDeductionsAmountResolver = (production: ViagritelProduction, testValueForFermeBudgitel2021?: number) => ({
  testResolver: {
    test: expectedValueTestResolver(testValueForFermeBudgitel2021),
  },
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      fichesTechniques,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      fichesTechniques: DatabaseResolverFnFicheTechnique
    }) => {
      const ftLignes = [
        61_100, // Prélèvement d'animaux stade jeune ($production60)
        61_300, // Prélèvement d'animaux stade finition ($production62)
        61_400, // Prélèvement d'autres animaux ($production63))
        130_100, // Prélèvement d'animaux par la famille $ (CR54)
        92_300, // Prélèvement de bouvillons $ (BI59)
        83_600, // Prélèv. agneaux de lait pour la famille $ (IN93)
        83_900, // Prélèv. agneaux légers pour la famille $ (IN96)
        84_200, // Prélèv. agneaux lourds pour la famille $ (IN99)
        84_400, // Prélèv. autres animaux pour la famille $ (IN101)
        40_300, // Prélèvement d'animaux par la famille $ (NA79)
        50_300, // Prélèvement d'animaux par la famille $ (FI40)
      ]

      let jvDeduction = new BigNumber(0)
      if (production === 'JV') {
        // Prélèvement veau de grain (JV) , ligne de la fiche technique veau de grain
        //   Prélèvement d'animaux pour la famille kg carc x (au CE veau grain, ligne Vte à l'enchère $/ Fiche technique veau de grain ligne Poids total de veaux vendus enchère kg carc.)
        const auctionSaleAmount = sumBy(comptesExploitation({ production: 'JV', ligne: 180_100 }), 'montant')
        const auctionSaleWeight = fichesTechniques({ production: 'JV', ligne: 70_100 }).valeur
        const familyDeduction = fichesTechniques({ production, ligne: 72_100 }).valeur
        if (!auctionSaleWeight.isZero()) {
          jvDeduction = auctionSaleAmount.dividedBy(auctionSaleWeight).times(familyDeduction)
        }
      }

      const ftTotal = sumBy(fichesTechniques({ production, ligne: ftLignes }), 'valeur')

      return jvDeduction.plus(ftTotal)
    },
  },
})

const incomeOperationalAmountResolver = (production: ViagritelProduction, testValueForFermeBudgitel2021?: number) => ({
  testResolver: {
    test: expectedValueTestResolver(testValueForFermeBudgitel2021),
  },
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) => {
      const ceLignes = [
        190_060, // Autres revenus directs (CR18)
        61_100, // Autres revenus directs (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL, WA) ($production22)
        111_100, // Subv. sur animaux reprod. (IN23)
        111_200, // Autres subv. directes (IN24)
        111_300, // Autres revenus directs (IN25)
        40_700, // Revenu de transport d'animaux (NA340)
        40_800, // Autres revenus directs (NA16)
        50_650, // Prime spécifique (FI91)
        50_700, // Revenu de transport d'animaux (FI357)
        50_900, // Autres revenus directs (FI18)
        180_700, // Subv. directes animaux (JV16)
        180_800, // Autres revenus directs (JV17)
        70_300, // Engraiss. à forfait (BI13)
        70_700, // Autres revenus directs (BI16)
      ]

      return sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
    },
  },
})

const incomeShortAmountResolver = (production: ViagritelProduction, testValueForFermeBudgitel2021?: number) => ({
  testResolver: {
    test: expectedValueTestResolver(testValueForFermeBudgitel2021),
  },
  databaseResolver: {
    resolve: ({ courtsTermes }: { courtsTermes: DatabaseResolverFnCourtTerme }) => {
      const ctLignes: Array<{
        type: ViagritelCourtTermeType | ViagritelCourtTermeType[]
        type_ligne?: ViagritelCourtTermeTypeLigne
      }> = [
        { type: ['CP', 'FR', 'CR', 'FP'], type_ligne: 'AN' }, // G/P sur C/P et R/A et GP sur C/R et P/A
        { type: 'CR', type_ligne: '40' }, // C/R G/P sur ASRA Bouvillons, Porcs, Veaux de grain, Porcelets, Veaux de lait
        { type: 'CR', type_ligne: '50' }, // C/R G/P sur ASRA Ovins de lait
        { type: 'CR', type_ligne: '60' }, // C/R G/P sur ASRA Ovins lourds
      ]

      return ctLignes.reduce(
        (total, { type, type_ligne }) =>
          total.plus(sumBy(courtsTermes({ production, type, type_ligne }), 'profit_perte')),
        new BigNumber(0)
      )
    },
  },
})

// animal.[production].expenses.marketing.amount
// Au CE chèvre (CR),
//   ligne Transport &m.marché lait +
//   ligne Mise en marché animaux +
// Aux CE (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA),
//   ligne Mise en marché et publicité+
// Au CE BI,
//   ligne Mise en marché et publicité +
// Au CE IN,
//   ligne Mise en marché et pub. +
// Au CE Porcelets (NA),
//   ligne Mie en marché +
//   ligne Transport des truies/verrats +
//   ligne Transport des porcelets +
// Au CE FI,
//   ligne Mise en marché +
//   ligne Transport des porcs +
//   Transport des porcelets +
// Au CE JV,
//   ligne Mise en marché et publicité
const expensesMarketingAmountResolver = (
  production: ViagritelProduction,
  testCris?: CriReference | CriReference[]
) => ({
  testResolver: criOrExpectedValueTestResolver(testCris),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) => {
      const ceLignes = [
        190_110, // Transport. & m.marché lait  CR19
        190_120, // Mise en marché animaux      CR20
        63_000, // Mise en marché et publicité (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) AX37
        72_300, // Mise en marché et publicité  BI27
        113_600, // Mise en marché et pub.      IN47
        41_500, // Mise en marché               NA84
        41_510, // Transport des truies/verrats NA341
        41_515, // Transport des porcelets      NA353
        52_400, // Mise en marché               FI26
        52_500, // Transport des Porcs          FI358
        52_505, // Transport des porcelets      FI359
        182_700, // Mise en marché et publicité JV30
      ]
      return sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
    },
  },
})

// animal.[production].expenses.livestock.maintenance.amount
// Au CE CR,
//   ligne Achat de chèvres +
//   ligne Achat de boucs +
// Au CE BI,
//   ligne achat veaux d'embouche +
// Au CE IN,
//   ligne Achat de brebis +
//   ligne Achat d'agnelles +
//   ligne Achat agneaux liait +
//   ligne Achat agn. légers +
//   ligne Achat agn lourds +
//   ligne Achat brebis +
// Au CE porcelets (NA),
//   ligne Achat de cochettes 5-25 kg +
//   ligne Achat de porcelets +
//   ligne Achat de truies +
//   ligne Achat de verrats +
// Au CE Porcs (FI)
//   ligne Achats de porcelets +
// Au CE JV,
//   ligne Achats de veaux sevrés +
//   ligne Achat de veaux naissants
const expensesLivestockMaintenanceAmountResolver = (
  production: ViagritelProduction,
  testValueForFermeBudgitel2021?: number,
  testCris?: CriReference | CriReference[]
) => ({
  testResolver: criOrExpectedValueTestResolver(testCris, testValueForFermeBudgitel2021),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) => {
      const ceLignes = [
        61_600, // Achat fem. adultes repr.   (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) AX11
        61_700, // Achat autres reprod.       (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) AX12
        61_800, // Achat d'élevages jeunes    (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) AX13
        61_900, // Achat d'élevage finition   (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) AX14
        190_130, // Achat de chèvres          CR21
        190_140, // Achat de boucs            CR23
        71_200, // Achat veaux d'embouche     BI20
        111_900, // Achat de brebis           IN29
        112_000, // Achat d'agnelles          IN31
        112_100, // Achat agneaux lait        IN33
        112_200, // Achat agn. légers         IN35
        112_300, // Achat agn. lourds         IN37
        112_400, // Achat de béliers          IN39
        41_230, // Achat de cochettes 5-25 kg NA405
        41_250, // Achat de porcelets         NA414
        41_300, // Achat de truies            NA18
        41_400, // Achat de verrats           NA19
        51_400, // Achats de porcelets        FI20
        181_300, // Achats de veaux  sevrés   JV20
        181_350, // Achat de veaux naissants  JV18
      ]
      return sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
    },
  },
})

// animal.[production].expenses.feed.adults.amount
// Vient de fiche aliments.
// Voir la formule de calcul dans le fichier. Etat des résultats.onglet alimentation,
//   en CR, BI, NA, IN, FI, JV, AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA
const expensesFeedAdultsAmountResolver = (
  production: ViagritelProduction,
  testCris?: CriReference | CriReference[],
  testValueForFermeBudgitel2021?: number
) => ({
  testResolver: criOrExpectedValueTestResolver(testCris, testValueForFermeBudgitel2021),
  databaseResolver: {
    resolve: ({ aliments }: { aliments: DatabaseResolverFnAliments }) =>
      foodExpenses(aliments({ production, colonne: 'A' })),
  },
})

// animal.[production].expenses.feed.young.amount
// Vient de fiche aliments.
// Voir la formule de calcul dans le fichier. Etat des résultats.onglet alimentation,
//   en CR, BI, NA, IN, FI, JV, AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA
const expensesFeedYoungAmountResolver = (
  production: ViagritelProduction,
  testCris?: CriReference | CriReference[],
  testValueForFermeBudgitel2021?: number
) => ({
  testResolver: criOrExpectedValueTestResolver(testCris, testValueForFermeBudgitel2021),
  databaseResolver: {
    resolve: ({ aliments }: { aliments: DatabaseResolverFnAliments }) =>
      foodExpenses(aliments({ production, colonne: 'Y' })),
  },
})

// animal.[production].expenses.feed.miscellaneous.amount
// Vient de fiche aliments.
// Voir la formule de calcul dans le fichier. Etat des résultats.onglet alimentation,
//   en CR, BI, NA, IN, FI, JV, AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA+
// Au CE CR,
//   ligne Pourdre de lait $-
// Inv approv. Chèvres début $ ligne Poudre de lait -
// Inv approv. Chèvres fin $ ligne Poudre de lait +
// Au CE JV,
//   ligne Poudre de lait +
//   ligne Autres aliments dans le CE +
// Inv. approv. Veaux de grain début $ - Fin $,
// ligne Poudre de lait et ligne Autres aliments (excluant fiche aliment)
const expensesFeedMiscellaneousAmountResolver = (
  production: ViagritelProduction,
  testCris?: CriReference | CriReference[],
  testValueForFermeBudgitel2021?: number
) => ({
  testResolver: criOrExpectedValueTestResolver(testCris, testValueForFermeBudgitel2021),
  databaseResolver: {
    resolve: ({
      aliments,
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      aliments: DatabaseResolverFnAliments
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        190_180, // Poudre de lait  CR28 (but the formula is incorrect as it subtracts and adds the end amount)
        181_500, // Poudre de lait  JV22
        182_000, // Aliments autres JV26. Not a mistake, there really are two separate lines with the same label
      ]
      const iaLignes = [
        160_000, // Poudre de lait CR28
        130_000, // Poudre de lait JV22
        130_100, // Autres aliments (excluant fiche aliment) JV26
      ]
      const ceTotal = sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
      const iaTotal = sumBy(inventairesApprovisionnements({ production, ligne: iaLignes }), 'delta_dollars')
      const feTotal = foodExpenses(aliments({ production, colonne: 'O' }))

      return ceTotal.plus(iaTotal).plus(feTotal)
    },
  },
})

// animal.[production].expenses.fees.veterinary.amount
// Au CE CR,
//   ligne Vétérinaire, médicaments et reproduction +
// Inv. approv. Chèvres, début $ ligne Médicaments - Inv approv. Chèvres, fin $
//   ligne Médicaments +
// Aux CE (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA),
//   ligne Vet.et médicaments +
// Inv.approv (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA) début $ - Fin $,
//   ligne Médicaments +
// Au CE BI,
//   ligne Vét et médicaments +
//   ligne Implants +
// Inv approv. Bouvillons, début $ -Fin $,
//   ligne Médicaments et
//   ligne Implants +
// Au CE IN,
//   ligne Vét. & médicaments +
// Inv. approv. IN début $ - Fin $,
//   ligne Médicaments +
// Au CE NA,
//   ligne Médicaments, vétérinaire +
// Inv. approv. Porcelets début $ - Fin $,
//   ligne Médicaments +
// Au CE FI,
//   ligne Vét. et médicaments +
// Inv. Approv. FI début $ - fin $,
//   ligne Médicaments +
// Au CE JV,
//   ligne Vet et médicaments +
//   ligne Implants +
// Inv. approv. Veaux de grain début $ - Fin $,
//   ligne Médicaments et
//   ligne Implants
const expensesFeesVeterinaryAmountResolver = (
  production: ViagritelProduction,
  testCris?: CriReference | CriReference[],
  testValueForFermeBudgitel2021?: number
) => ({
  testResolver: criOrExpectedValueTestResolver(testCris, testValueForFermeBudgitel2021),
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        62_700, // Vét. et médicaments (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA) AX35
        72_000, // Vét. et médicaments      BI25
        72_100, // Implants                 BI26
        113_200, // Vét.& médicaments       IN44
        42_200, // Médicaments, vétérinaire NA24
        52_200, // Vét. et médicaments      FI25
        182_400, // Vét. et médicaments     JV28
        182_500, // Implants                JV29
        190_190, // Vétérinaire, médicaments et reproduction CR30
      ]
      const iaLignes = [
        50_100, // Médicaments  AX35
        60_000, // Médicaments  BI25
        160_100, // Médicaments CR30 (but the formula is incorrect as it subtracts and adds the end amount)
        40_000, // Médicaments  FI25
        100_100, // Médicaments IN44
        130_200, // Médicaments JV28
        30_100, // Médicaments  NA24
        60_100, // Implants     BI26
        130_300, // Implants    JV29
      ]
      const ceTotal = sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
      const iaTotal = sumBy(inventairesApprovisionnements({ production, ligne: iaLignes }), 'delta_dollars')

      return ceTotal.plus(iaTotal)
    },
  },
})

// animal.[production].expenses.fees.reproduction.amount
// Au CE  (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA),
//   ligne Insémination =
// Inv approv des(AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA) début $ - Fin $,
//   ligne Produits pour insémination +
// Au CE IN,
//   ligne Echographie +
//   ligne Frais de reproduction +
// Inv approv. IN début $ - Fin $,
//   ligne Produits pour insémination +
// Au CE Porcelets (NA),
//   ligne Insémination +
// Inv. approv. Porcelets début $ - Fin  $ ,
//   ligne Produits pour insémination
const expensesFeesReproductionAmountResolver = (
  production: ViagritelProduction,
  testCris?: CriReference | CriReference[],
  testValueForFermeBudgitel2021?: number
) => ({
  testResolver: criOrExpectedValueTestResolver(testCris, testValueForFermeBudgitel2021),
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        62_800, // Insémination (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) AX36
        113_300, // Echographie           IN45
        113_400, // Frais de reproduction IN46
        42_300, // Insémination           NA25
      ]
      const iaLignes = [
        50_000, // Produits pour insémination (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) AX36
        100_000, // Produits pour insémination IN46
        30_000, // Produits pour insémination  NA25
      ]
      const ceTotal = sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
      const iaTotal = sumBy(inventairesApprovisionnements({ production, ligne: iaLignes }), 'delta_dollars')

      return ceTotal.plus(iaTotal)
    },
  },
})

// animal.[production].expenses.fees.insurance.amount
// Aux CE (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA), et BI,NA, JV,
//   ligne Cot. stabilisation et
// IN,
//   ligne cot. Stabilisation lait +
//   ligne Cot. Stabilisation lourd +
// Au CE FI Porcs,
//   ligne Cot. stabilisation
const expensesFeesInsuranceAmountResolver = (
  production: ViagritelProduction,
  testValueForFermeBudgitel2021?: number
) => ({
  testResolver: expectedValueTestResolver(testValueForFermeBudgitel2021),
  databaseResolver: {
    resolve: ({ comptesExploitation }: { comptesExploitation: DatabaseResolverFnCompteExploitation }) => {
      const ceLignes = [
        62_500, // Cot. stabilisation (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA)
        71_800, // Cot. Stabilisation (BI)
        41_600, // Cot. stabilisation (NA)
        182_200, // Cot. stabilisation (JV)
        113_000, // Cot. Stabilisation lait (IN)
        113_100, // Cot. Stabilisation lourd (IN)
        51_600, // Cot. stabilisation (FI)
      ]
      return sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
    },
  },
})

// animal.[production].expenses.fees.miscellaneous.amount
// Au CE CR,
//   ligne Approv de laiterie +
// Inv. approv. Chèvres, début $ ligne Approvisionnements de laiterie - Inv approv. chèvres, fin $,
//   ligne Approvisionnements de laiterie+
// Au CE CR,
//   ligne publicité +
//   ligne Régie de troupeau.
//   ligne Litière autre que paille +
//   ligne Autres charges directes +
// Inv. approv. chèvres, début $ Ligne Litière autre que paille - Inv. approv. Chèvre fin $
//   ligne Litière autre que paille +
// Aux CE (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA),
//   ligne Propane pour chauffage,+
//   ligne Location de quota +
//   ligne Litière autre que paille +
//   ligne Autres approv. directs +
// Inv. approv. (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA), début $ - fin $ ,
//   ligne Litière autre que paille,
//   ligne Propane,
//   ligne Autres +
// Au CE BI,
//   ligne Itière autre que paille, +
//   ligne Frais d'élevage à forfait +
//   Autres approv. directs +
// Inv. approv Bouvillons, début $ - Fin $,
//   ligne Litière autre que paille et
//   ligne Autres +
// Au CE IN,
//   ligne Frais de tonte +
//   ligne Litière autre paille +
//   ligne Autres approv. directs +
// Inv. approv début $ - Fin $,
//   ligne Litière autre que paille et
//   ligne Autres +
// Au CE NA,
//   ligne Frais de récupération  des animaux morts +
//   ligne Propane pour incinération +
//   ligne Main d'oeuvre pour incinération +
//   ligne Autres frais directs pour incinération +
//   ligne Achat de litière pour compostage +
//   ligne Main d'oeuvre pour compostage +
//   ligne Autres frais directs pour compostage +
//   ligne Propane pour chauffage +
//   ligne Litière autre que paille +
//   Autres approv. directs +
// Inv. approv. NA début $ - Fin $,
//   ligne Litière autre que paille,
//   ligne Propane +
//   ligne Autres +
// Au CE FI,
//   ligne Frais de récupération des animaux morts +
//   ligne Propane pour incinération +
//   ligne Main d'oeuvre pour incinération +
//   ligne Autres frais directs incinération +
//   ligne Achat de litière pour compostage +
//   ligne Main d'oeuve pour compostage +
//   ligne Autres frais directs pour compostage +
//   ligne Propane pour chauffage de bâtiement +
//   ligne Litière autre que paille +
//   ligne Autres approv. divers +
// Inv. approv. FI début $ - Fin $ ,
//   ligne Litière autre que paille,
//   ligne Propane,
//   ligne Autres +
// Au CE JV,
//   ligne Propane pour chauffage +
//   ligne Assurance animaux +
//   Ligne Litère autre que paille +
//   Ligne Déd. foies condamnés +
//   ligne Frais d'élevage à forfait pouponnière +
//   ligne Frais d'élevage à forfait engraissement +
//   ligne Autres approv. directs +
// Inv approv. Veaux de grain début$ - Fin $,
//   ligne Litière autre que paille,
//   ligne Propane et
//   ligne Autres
const expensesFeesMiscellaneousAmountResolver = (
  production: ViagritelProduction,
  testValueForFermeBudgitel2021?: number
) => ({
  testResolver: expectedValueTestResolver(testValueForFermeBudgitel2021),
  databaseResolver: {
    resolve: ({
      comptesExploitation,
      inventairesApprovisionnements,
    }: {
      comptesExploitation: DatabaseResolverFnCompteExploitation
      inventairesApprovisionnements: DatabaseResolverFnInventaireApprovisionnement
    }) => {
      const ceLignes = [
        190_200, // Approv. de laiterie      CR34
        190_210, // Publicité                CR31
        190_220, // Régie de troupeau        CR35
        190_230, // Litière autre que paille CR32
        190_240, // Autres charges directes  CR36
        63_200, // Propane pour chauffage (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA)   AX38
        63_400, // Location de quota (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA)        AX39
        63_600, // Litière autre que paille (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA) AX40
        63_700, // Autres approv. directs (AX,BS,CV,DG,DL,LP,OC,OI,OT,PG,PS,ST,TA,VL et WA)   AX41
        72_500, // Litière autre que paille  BI28
        72_600, // Frais d'élevage à forfait BI29
        72_700, // Autres approv. Directs    BI30
        113_800, // Frais de tonte           IN48
        113_900, // Litière autre paille     IN49
        114_000, // Autres approv. directs   IN50
        41_520, // Frais de récupération des animaux morts  NA342
        41_540, // Propane pour incinération                NA344
        41_560, // Main d'oeuvre pour incinération          NA346
        41_570, // Autres frais directs pour incinération   NA347
        41_585, // Achat de litière pour compostage         NA350
        41_590, // Main d'oeuvre pour compostage            NA351
        41_595, // Autres frais directs pour compostage     NA352
        42_500, // Propane pour chauffage                   NA26
        42_700, // Litière autre que paille                 NA27
        42_800, // Autres approv. Directs                   NA30
        52_510, // Frais de récupération des animaux morts  FI360
        52_530, // Propane pour incinération                FI362
        52_550, // Main d'oeuvre pour incinération          FI364
        52_560, // Autres frais directs incinération        FI365
        52_585, // Achat de litière pour compostage         FI368
        52_590, // Main d'œuvre pour compostage             FI369
        52_595, // Autres frais directs pour compostage     FI370
        52_600, // Propane pour chauffage de bâtiment       FI27
        52_800, // Litière autre que paille                 FI28
        52_900, // Autres approv. Divers                    FI31
        182_900, // Propane pour chauffage                  JV31
        183_100, // Assurance animaux                       JV32
        183_300, // Litière autre que paille (JV)           Pas de cri
        183_400, // Déd. foies condamnés                    JV35
        183_500, // Frais d'élevage à forfait pouponnière   JV39
        183_550, // Frais d'élevage à forfait engraissement JV40
        183_600, // Autres approv. directs                  JV36
      ]
      const iaLignes = [
        160_200, // Approvisionnements de laiterie CR34
        160_300, // Litière autre que paille       CR32
        50_200, // Litière autre que paille (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA) AX40
        50_300, // Propane (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA)                  AX38
        50_400, // Autres (AX, BS, CV, DG, DL, LP, OC, OI, OT, PG, PS, ST, TA, VL et WA)                   AX41
        60_200, // Litière autre que paille  BI28
        60_300, // Autres (BI)               BI30
        100_200, // Litière autre que paille IN49
        100_300, // Autres                   IN50
        30_200, // Litière autre que paille  NA27
        30_300, // Propane                   NA26
        30_400, // Autres                    NA30
        40_100, // Litière autre que paille  FI28
        40_200, // Propane                   FI27
        40_300, // Autres                    FI31
        130_400, // Litière autre que paille (JV) Pas de cri
        130_500, // Propane                  JV31
        130_600, // Autres                   JV36
      ]
      const ceTotal = sumBy(comptesExploitation({ production, ligne: ceLignes }), 'montant')
      const iaTotal = sumBy(inventairesApprovisionnements({ production, ligne: iaLignes }), 'delta_dollars')

      return ceTotal.plus(iaTotal)
    },
  },
})

export const animal = createBudgetDefinition({
  'animal.aquaculture.income.imported.sales.products': incomeSalesProductsAmountResolver('PS'),
  'animal.aquaculture.income.imported.sales.cull': incomeSalesCullAmountResolver('PS'),
  'animal.aquaculture.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('PS'),
  'animal.aquaculture.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('PS'),
  'animal.aquaculture.income.imported.inventory.breeders': incomeInventoryBreedersAmount('PS'),
  'animal.aquaculture.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('PS'),
  'animal.aquaculture.income.imported.deductions': incomeDeductionsAmountResolver('PS'),
  'animal.aquaculture.income.imported.short': incomeShortAmountResolver('PS'),
  'animal.aquaculture.income.imported.operational': incomeOperationalAmountResolver('PS'),
  'animal.aquaculture.expenses.imported.marketing': expensesMarketingAmountResolver('PS', 'PS37'),
  'animal.aquaculture.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('PS', 0),
  'animal.aquaculture.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('PS', 'PS31'),
  'animal.aquaculture.expenses.imported.feed.young': expensesFeedYoungAmountResolver('PS', 'PS32'),
  'animal.aquaculture.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('PS', 'PS33'),
  'animal.aquaculture.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('PS', 'PS35'),
  'animal.aquaculture.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('PS', 'PS36'),
  'animal.aquaculture.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('PS', 18_107),
  'animal.aquaculture.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('PS', 72_741),
  'animal.bison.income.imported.sales.products': incomeSalesProductsAmountResolver('BS'),
  'animal.bison.income.imported.sales.cull': incomeSalesCullAmountResolver('BS'),
  'animal.bison.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('BS'),
  'animal.bison.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('BS'),
  'animal.bison.income.imported.inventory.breeders': incomeInventoryBreedersAmount('BS'),
  'animal.bison.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('BS'),
  'animal.bison.income.imported.deductions': incomeDeductionsAmountResolver('BS'),
  'animal.bison.income.imported.short': incomeShortAmountResolver('BS'),
  'animal.bison.income.imported.operational': incomeOperationalAmountResolver('BS'),
  'animal.bison.expenses.imported.marketing': expensesMarketingAmountResolver('BS', 'BS37'),
  'animal.bison.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('BS', 0),
  'animal.bison.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('BS', 'BS31'),
  'animal.bison.expenses.imported.feed.young': expensesFeedYoungAmountResolver('BS', 'BS32'),
  'animal.bison.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('BS', 'BS33'),
  'animal.bison.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('BS', 'BS35'),
  'animal.bison.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('BS', 'BS36'),
  'animal.bison.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('BS', 1107),
  'animal.bison.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('BS', 4741),
  'animal.exotic.income.imported.sales.products': incomeSalesProductsAmountResolver('AX'),
  'animal.exotic.income.imported.sales.cull': incomeSalesCullAmountResolver('AX'),
  'animal.exotic.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('AX'),
  'animal.exotic.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('AX'),
  'animal.exotic.income.imported.inventory.breeders': incomeInventoryBreedersAmount('AX'),
  'animal.exotic.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('AX'),
  'animal.exotic.income.imported.deductions': incomeDeductionsAmountResolver('AX'),
  'animal.exotic.income.imported.short': incomeShortAmountResolver('AX'),
  'animal.exotic.income.imported.operational': incomeOperationalAmountResolver('AX'),
  'animal.exotic.expenses.imported.marketing': expensesMarketingAmountResolver('AX', 'AX37'),
  'animal.exotic.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('AX', 0),
  'animal.exotic.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('AX', 'AX31'),
  'animal.exotic.expenses.imported.feed.young': expensesFeedYoungAmountResolver('AX', 'AX32'),
  'animal.exotic.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('AX', 'AX33'),
  'animal.exotic.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('AX', 'AX35'),
  'animal.exotic.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('AX', 'AX36'),
  'animal.exotic.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('AX', 8107),
  'animal.exotic.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('AX', 32_741),
  'animal.finished-beef.income.imported.sales.products': incomeSalesProductsAmountResolver('BI', ['BI9', 'BI11']),
  'animal.finished-beef.income.imported.sales.cull': incomeSalesCullAmountResolver('BI'),
  'animal.finished-beef.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('BI'),
  'animal.finished-beef.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('BI'),
  'animal.finished-beef.income.imported.inventory.breeders': incomeInventoryBreedersAmount('BI'),
  'animal.finished-beef.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('BI'),
  'animal.finished-beef.income.imported.deductions': incomeDeductionsAmountResolver('BI', 600),
  'animal.finished-beef.income.imported.short': incomeShortAmountResolver('BI'),
  'animal.finished-beef.income.imported.operational': incomeOperationalAmountResolver('BI', 1606),
  'animal.finished-beef.expenses.imported.marketing': expensesMarketingAmountResolver('BI', 'BI27'),
  'animal.finished-beef.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver(
    'BI',
    undefined,
    'BI20'
  ),
  'animal.finished-beef.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('BI', 'BI21'),
  'animal.finished-beef.expenses.imported.feed.young': expensesFeedYoungAmountResolver('BI', 'BI22'),
  'animal.finished-beef.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('BI', 'BI23'),
  'animal.finished-beef.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('BI', [
    'BI25',
    'BI26',
  ]),
  'animal.finished-beef.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('BI', 'BI36'),
  'animal.finished-beef.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('BI', 2004),
  'animal.finished-beef.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('BI', 6222),
  'animal.goat.income.imported.sales.products': incomeSalesProductsAmountResolver('CR', [
    'CR8',
    'CR39',
    'CR14',
    'CR16',
  ]),
  'animal.goat.income.imported.sales.cull': incomeSalesCullAmountResolver('CR', ['CR10']),
  'animal.goat.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('CR', ['CR12']),
  'animal.goat.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('CR'),
  'animal.goat.income.imported.inventory.breeders': incomeInventoryBreedersAmount('CR', ['CR101']),
  'animal.goat.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('CR', 21_025),
  'animal.goat.income.imported.deductions': incomeDeductionsAmountResolver('CR', 700),
  'animal.goat.income.imported.short': incomeShortAmountResolver('CR', 0),
  'animal.goat.income.imported.operational': incomeOperationalAmountResolver('CR', 4006),
  'animal.goat.expenses.imported.marketing': expensesMarketingAmountResolver('CR', ['CR19', 'CR20']),
  'animal.goat.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('CR', undefined, [
    'CR21',
    'CR23',
  ]),
  'animal.goat.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('CR', 'CR25'),
  'animal.goat.expenses.imported.feed.young': expensesFeedYoungAmountResolver('CR', 'CR26'),
  'animal.goat.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver(
    'CR',
    undefined,
    5842.959_724_57 // CR28 normally, but the formula is incorrect
  ),
  'animal.goat.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('CR', undefined, 4107),
  'animal.goat.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('CR', undefined, 0),
  'animal.goat.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('CR', 0),
  'animal.goat.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('CR', 20_750),
  'animal.grain-veal.income.imported.sales.products': incomeSalesProductsAmountResolver('JV', ['JV9', 'JV11', 'JV13']),
  'animal.grain-veal.income.imported.sales.cull': incomeSalesCullAmountResolver('JV'),
  'animal.grain-veal.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('JV', ['JV403']),
  'animal.grain-veal.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('JV'),
  'animal.grain-veal.income.imported.inventory.breeders': incomeInventoryBreedersAmount('JV'),
  'animal.grain-veal.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('JV'),
  'animal.grain-veal.income.imported.deductions': incomeDeductionsAmountResolver('JV', 6000),
  'animal.grain-veal.income.imported.short': incomeShortAmountResolver('JV', 0),
  'animal.grain-veal.income.imported.operational': incomeOperationalAmountResolver('JV', 209),
  'animal.grain-veal.expenses.imported.marketing': expensesMarketingAmountResolver('JV', 'JV30'),
  'animal.grain-veal.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver(
    'JV',
    undefined,
    ['JV18', 'JV20']
  ),
  'animal.grain-veal.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('JV', 'JV23'),
  'animal.grain-veal.expenses.imported.feed.young': expensesFeedYoungAmountResolver('JV', 'JV24'),
  'animal.grain-veal.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('JV', [
    'JV22',
    'JV25',
    'JV26',
  ]),
  'animal.grain-veal.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('JV', ['JV28', 'JV29']),
  'animal.grain-veal.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('JV', undefined, 0),
  'animal.grain-veal.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('JV', 25_007),
  'animal.grain-veal.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('JV', 176_083),
  'animal.heavy-turkey.income.imported.sales.products': incomeSalesProductsAmountResolver('DL'),
  'animal.heavy-turkey.income.imported.sales.cull': incomeSalesCullAmountResolver('DL'),
  'animal.heavy-turkey.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('DL'),
  'animal.heavy-turkey.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('DL'),
  'animal.heavy-turkey.income.imported.inventory.breeders': incomeInventoryBreedersAmount('DL'),
  'animal.heavy-turkey.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('DL'),
  'animal.heavy-turkey.income.imported.deductions': incomeDeductionsAmountResolver('DL'),
  'animal.heavy-turkey.income.imported.short': incomeShortAmountResolver('DL'),
  'animal.heavy-turkey.income.imported.operational': incomeOperationalAmountResolver('DL'),
  'animal.heavy-turkey.expenses.imported.marketing': expensesMarketingAmountResolver('DL', 'DL37'),
  'animal.heavy-turkey.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('DL', 0),
  'animal.heavy-turkey.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('DL', 'DL31'),
  'animal.heavy-turkey.expenses.imported.feed.young': expensesFeedYoungAmountResolver('DL', 'DL32'),
  'animal.heavy-turkey.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('DL', 'DL33'),
  'animal.heavy-turkey.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('DL', 'DL35'),
  'animal.heavy-turkey.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('DL', 'DL36'),
  'animal.heavy-turkey.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('DL', 7107),
  'animal.heavy-turkey.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('DL', 28_741),
  'animal.horse.income.imported.sales.products': incomeSalesProductsAmountResolver('CV'),
  'animal.horse.income.imported.sales.cull': incomeSalesCullAmountResolver('CV'),
  'animal.horse.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('CV'),
  'animal.horse.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('CV'),
  'animal.horse.income.imported.inventory.breeders': incomeInventoryBreedersAmount('CV'),
  'animal.horse.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('CV'),
  'animal.horse.income.imported.deductions': incomeDeductionsAmountResolver('CV'),
  'animal.horse.income.imported.short': incomeShortAmountResolver('CV'),
  'animal.horse.income.imported.operational': incomeOperationalAmountResolver('CV'),
  'animal.horse.expenses.imported.marketing': expensesMarketingAmountResolver('CV', 'CV37'),
  'animal.horse.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('CV', 0),
  'animal.horse.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('CV', 'CV31'),
  'animal.horse.expenses.imported.feed.young': expensesFeedYoungAmountResolver('CV', 'CV32'),
  'animal.horse.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('CV', 'CV33'),
  'animal.horse.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('CV', 'CV35'),
  'animal.horse.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('CV', 'CV36'),
  'animal.horse.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('CV', 4107),
  'animal.horse.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('CV', 16_741),
  'animal.incubation-egg.income.imported.sales.products': incomeSalesProductsAmountResolver('OI'),
  'animal.incubation-egg.income.imported.sales.cull': incomeSalesCullAmountResolver('OI'),
  'animal.incubation-egg.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('OI'),
  'animal.incubation-egg.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('OI'),
  'animal.incubation-egg.income.imported.inventory.breeders': incomeInventoryBreedersAmount('OI'),
  'animal.incubation-egg.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('OI'),
  'animal.incubation-egg.income.imported.deductions': incomeDeductionsAmountResolver('OI'),
  'animal.incubation-egg.income.imported.short': incomeShortAmountResolver('OI'),
  'animal.incubation-egg.income.imported.operational': incomeOperationalAmountResolver('OI'),
  'animal.incubation-egg.expenses.imported.marketing': expensesMarketingAmountResolver('OI', 'OI37'),
  'animal.incubation-egg.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('OI', 0),
  'animal.incubation-egg.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('OI', 'OI31'),
  'animal.incubation-egg.expenses.imported.feed.young': expensesFeedYoungAmountResolver('OI', 'OI32'),
  'animal.incubation-egg.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('OI', 'OI33'),
  'animal.incubation-egg.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('OI', 'OI35'),
  'animal.incubation-egg.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('OI', 'OI36'),
  'animal.incubation-egg.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('OI', 16_107),
  'animal.incubation-egg.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('OI', 64_741),
  'animal.milk-veal.income.imported.sales.products': incomeSalesProductsAmountResolver('VL'),
  'animal.milk-veal.income.imported.sales.cull': incomeSalesCullAmountResolver('VL'),
  'animal.milk-veal.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('VL'),
  'animal.milk-veal.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('VL'),
  'animal.milk-veal.income.imported.inventory.breeders': incomeInventoryBreedersAmount('VL'),
  'animal.milk-veal.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('VL'),
  'animal.milk-veal.income.imported.deductions': incomeDeductionsAmountResolver('VL'),
  'animal.milk-veal.income.imported.short': incomeShortAmountResolver('VL'),
  'animal.milk-veal.income.imported.operational': incomeOperationalAmountResolver('VL'),
  'animal.milk-veal.expenses.imported.marketing': expensesMarketingAmountResolver('VL', 'VL37'),
  'animal.milk-veal.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('VL', 0),
  'animal.milk-veal.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('VL', 'VL31'),
  'animal.milk-veal.expenses.imported.feed.young': expensesFeedYoungAmountResolver('VL', 'VL32'),
  'animal.milk-veal.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('VL', 'VL33'),
  'animal.milk-veal.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('VL', 'VL35'),
  'animal.milk-veal.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('VL', 'VL36'),
  'animal.milk-veal.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('VL', 26_107),
  'animal.milk-veal.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('VL', 104_741),
  'animal.ostrich.income.imported.sales.products': incomeSalesProductsAmountResolver('OT'),
  'animal.ostrich.income.imported.sales.cull': incomeSalesCullAmountResolver('OT'),
  'animal.ostrich.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('OT'),
  'animal.ostrich.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('OT'),
  'animal.ostrich.income.imported.inventory.breeders': incomeInventoryBreedersAmount('OT'),
  'animal.ostrich.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('OT'),
  'animal.ostrich.income.imported.deductions': incomeDeductionsAmountResolver('OT'),
  'animal.ostrich.income.imported.short': incomeShortAmountResolver('OT'),
  'animal.ostrich.income.imported.operational': incomeOperationalAmountResolver('OT'),
  'animal.ostrich.expenses.imported.marketing': expensesMarketingAmountResolver('OT', 'OT37'),
  'animal.ostrich.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('OT', 0),
  'animal.ostrich.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('OT', 'OT31'),
  'animal.ostrich.expenses.imported.feed.young': expensesFeedYoungAmountResolver('OT', 'OT32'),
  'animal.ostrich.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('OT', 'OT33'),
  'animal.ostrich.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('OT', 'OT35'),
  'animal.ostrich.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('OT', 'OT36'),
  'animal.ostrich.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('OT', 22_107),
  'animal.ostrich.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('OT', 88_741),
  'animal.pork.income.imported.sales.products': incomeSalesProductsAmountResolver('FI', ['FI9', 'FI11', 'FI444']),
  'animal.pork.income.imported.sales.cull': incomeSalesCullAmountResolver('FI'),
  'animal.pork.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('FI', ['FI13', 'FI15']),
  'animal.pork.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('FI'),
  'animal.pork.income.imported.inventory.breeders': incomeInventoryBreedersAmount('FI'),
  'animal.pork.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('FI'),
  'animal.pork.income.imported.deductions': incomeDeductionsAmountResolver('FI', 400),
  'animal.pork.income.imported.short': incomeShortAmountResolver('FI', 0),
  'animal.pork.income.imported.operational': incomeOperationalAmountResolver('FI', 2121),
  'animal.pork.expenses.imported.marketing': expensesMarketingAmountResolver('FI', ['FI26', 'FI358', 'FI359']),
  'animal.pork.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver(
    'FI',
    undefined,
    'FI20'
  ),
  'animal.pork.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('FI', 'FI22'),
  'animal.pork.expenses.imported.feed.young': expensesFeedYoungAmountResolver('FI', 'FI23'),
  'animal.pork.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('FI', 'FI24'),
  'animal.pork.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('FI', 'FI25'),
  'animal.pork.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('FI', undefined, 0),
  'animal.pork.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('FI', 20_103),
  'animal.pork.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('FI', 201_449),
  'animal.rabbit.income.imported.sales.products': incomeSalesProductsAmountResolver('LP'),
  'animal.rabbit.income.imported.sales.cull': incomeSalesCullAmountResolver('LP'),
  'animal.rabbit.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('LP'),
  'animal.rabbit.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('LP'),
  'animal.rabbit.income.imported.inventory.breeders': incomeInventoryBreedersAmount('LP', ['LP88']),
  'animal.rabbit.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('LP'),
  'animal.rabbit.income.imported.deductions': incomeDeductionsAmountResolver('LP'),
  'animal.rabbit.income.imported.short': incomeShortAmountResolver('LP'),
  'animal.rabbit.income.imported.operational': incomeOperationalAmountResolver('LP'),
  'animal.rabbit.expenses.imported.marketing': expensesMarketingAmountResolver('LP', 'LP37'),
  'animal.rabbit.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('LP', 0),
  'animal.rabbit.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('LP', 'LP31'),
  'animal.rabbit.expenses.imported.feed.young': expensesFeedYoungAmountResolver('LP', 'LP32'),
  'animal.rabbit.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('LP', 'LP33'),
  'animal.rabbit.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('LP', 'LP35'),
  'animal.rabbit.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('LP', 'LP36'),
  'animal.rabbit.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('LP', 10_107),
  'animal.rabbit.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('LP', 40_738),
  'animal.replacement-heifer.income.imported.sales.products': incomeSalesProductsAmountResolver('TA'),
  'animal.replacement-heifer.income.imported.sales.cull': incomeSalesCullAmountResolver('TA'),
  'animal.replacement-heifer.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('TA'),
  'animal.replacement-heifer.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('TA'),
  'animal.replacement-heifer.income.imported.inventory.breeders': incomeInventoryBreedersAmount('TA'),
  'animal.replacement-heifer.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('TA'),
  'animal.replacement-heifer.income.imported.deductions': incomeDeductionsAmountResolver('TA'),
  'animal.replacement-heifer.income.imported.short': incomeShortAmountResolver('TA'),
  'animal.replacement-heifer.income.imported.operational': incomeOperationalAmountResolver('TA'),
  'animal.replacement-heifer.expenses.imported.marketing': expensesMarketingAmountResolver('TA', 'TA37'),
  'animal.replacement-heifer.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver(
    'TA',
    0
  ),
  'animal.replacement-heifer.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('TA', 'TA31'),
  'animal.replacement-heifer.expenses.imported.feed.young': expensesFeedYoungAmountResolver('TA', 'TA32'),
  'animal.replacement-heifer.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver(
    'TA',
    'TA33'
  ),
  'animal.replacement-heifer.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('TA', 'TA35'),
  'animal.replacement-heifer.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('TA', 'TA36'),
  'animal.replacement-heifer.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('TA', 23_107),
  'animal.replacement-heifer.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver(
    'TA',
    92_741
  ),
  'animal.roast-poultry.income.imported.sales.products': incomeSalesProductsAmountResolver('PG'),
  'animal.roast-poultry.income.imported.sales.cull': incomeSalesCullAmountResolver('PG'),
  'animal.roast-poultry.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('PG'),
  'animal.roast-poultry.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('PG'),
  'animal.roast-poultry.income.imported.inventory.breeders': incomeInventoryBreedersAmount('PG'),
  'animal.roast-poultry.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('PG'),
  'animal.roast-poultry.income.imported.deductions': incomeDeductionsAmountResolver('PG'),
  'animal.roast-poultry.income.imported.short': incomeShortAmountResolver('PG'),
  'animal.roast-poultry.income.imported.operational': incomeOperationalAmountResolver('PG'),
  'animal.roast-poultry.expenses.imported.marketing': expensesMarketingAmountResolver('PG', 'PG37'),
  'animal.roast-poultry.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('PG', 0),
  'animal.roast-poultry.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('PG', 'PG31'),
  'animal.roast-poultry.expenses.imported.feed.young': expensesFeedYoungAmountResolver('PG', 'PG32'),
  'animal.roast-poultry.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('PG', 'PG33'),
  'animal.roast-poultry.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('PG', 'PG35'),
  'animal.roast-poultry.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('PG', 'PG36'),
  'animal.roast-poultry.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('PG', 21_107),
  'animal.roast-poultry.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('PG', 84_741),
  'animal.roast-turkey.income.imported.sales.products': incomeSalesProductsAmountResolver('DG'),
  'animal.roast-turkey.income.imported.sales.cull': incomeSalesCullAmountResolver('DG'),
  'animal.roast-turkey.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('DG'),
  'animal.roast-turkey.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('DG'),
  'animal.roast-turkey.income.imported.inventory.breeders': incomeInventoryBreedersAmount('DG'),
  'animal.roast-turkey.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('DG'),
  'animal.roast-turkey.income.imported.deductions': incomeDeductionsAmountResolver('DG'),
  'animal.roast-turkey.income.imported.short': incomeShortAmountResolver('DG'),
  'animal.roast-turkey.income.imported.operational': incomeOperationalAmountResolver('DG'),
  'animal.roast-turkey.expenses.imported.marketing': expensesMarketingAmountResolver('DG', 'DG37'),
  'animal.roast-turkey.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('DG', 0),
  'animal.roast-turkey.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('DG', 'DG31'),
  'animal.roast-turkey.expenses.imported.feed.young': expensesFeedYoungAmountResolver('DG', 'DG32'),
  'animal.roast-turkey.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('DG', 'DG33'),
  'animal.roast-turkey.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('DG', 'DG35'),
  'animal.roast-turkey.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('DG', 'DG36'),
  'animal.roast-turkey.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('DG', 6107),
  'animal.roast-turkey.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('DG', 24_741),
  'animal.sheep.income.imported.sales.products': incomeSalesProductsAmountResolver('IN', [
    'IN9',
    'IN11',
    'IN13',
    'IN448',
    'IN27',
  ]),
  'animal.sheep.income.imported.sales.cull': incomeSalesCullAmountResolver('IN', ['IN21']),
  'animal.sheep.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('IN', ['IN15', 'IN17', 'IN19']),
  'animal.sheep.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('IN'),
  'animal.sheep.income.imported.inventory.breeders': incomeInventoryBreedersAmount('IN', ['IN127']),
  'animal.sheep.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('IN'),
  'animal.sheep.income.imported.deductions': incomeDeductionsAmountResolver('IN', 3500),
  'animal.sheep.income.imported.short': incomeShortAmountResolver('IN', 0),
  'animal.sheep.income.imported.operational': incomeOperationalAmountResolver('IN', 1531),
  'animal.sheep.expenses.imported.marketing': expensesMarketingAmountResolver('IN', 'IN47'),
  'animal.sheep.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('IN', undefined, [
    'IN29',
    'IN31',
    'IN33',
    'IN35',
    'IN37',
    'IN39',
  ]),
  'animal.sheep.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('IN', 'IN40'),
  'animal.sheep.expenses.imported.feed.young': expensesFeedYoungAmountResolver('IN', 'IN41'),
  'animal.sheep.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('IN', 'IN42'),
  'animal.sheep.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('IN', 'IN44'),
  'animal.sheep.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('IN', ['IN45', 'IN46']),
  'animal.sheep.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('IN', 34_219),
  'animal.sheep.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('IN', 51_553),
  'animal.stag.income.imported.sales.products': incomeSalesProductsAmountResolver('ST'),
  'animal.stag.income.imported.sales.cull': incomeSalesCullAmountResolver('ST'),
  'animal.stag.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('ST'),
  'animal.stag.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('ST'),
  'animal.stag.income.imported.inventory.breeders': incomeInventoryBreedersAmount('ST'),
  'animal.stag.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('ST'),
  'animal.stag.income.imported.deductions': incomeDeductionsAmountResolver('ST'),
  'animal.stag.income.imported.short': incomeShortAmountResolver('ST'),
  'animal.stag.income.imported.operational': incomeOperationalAmountResolver('ST'),
  'animal.stag.expenses.imported.marketing': expensesMarketingAmountResolver('ST', 'ST37'),
  'animal.stag.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('ST', 0),
  'animal.stag.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('ST', 'ST31'),
  'animal.stag.expenses.imported.feed.young': expensesFeedYoungAmountResolver('ST', 'ST32'),
  'animal.stag.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('ST', 'ST33'),
  'animal.stag.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('ST', 'ST35'),
  'animal.stag.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('ST', 'ST36'),
  'animal.stag.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('ST', 3107),
  'animal.stag.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('ST', 12_741),
  'animal.table-egg.income.imported.sales.products': incomeSalesProductsAmountResolver('OC'),
  'animal.table-egg.income.imported.sales.cull': incomeSalesCullAmountResolver('OC'),
  'animal.table-egg.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('OC'),
  'animal.table-egg.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('OC'),
  'animal.table-egg.income.imported.inventory.breeders': incomeInventoryBreedersAmount('OC'),
  'animal.table-egg.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('OC'),
  'animal.table-egg.income.imported.deductions': incomeDeductionsAmountResolver('OC'),
  'animal.table-egg.income.imported.short': incomeShortAmountResolver('OC'),
  'animal.table-egg.income.imported.operational': incomeOperationalAmountResolver('OC'),
  'animal.table-egg.expenses.imported.marketing': expensesMarketingAmountResolver('OC', 'OC37'),
  'animal.table-egg.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('OC', 0),
  'animal.table-egg.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('OC', 'OC31'),
  'animal.table-egg.expenses.imported.feed.young': expensesFeedYoungAmountResolver('OC', 'OC32'),
  'animal.table-egg.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('OC', 'OC33'),
  'animal.table-egg.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('OC', 'OC35'),
  'animal.table-egg.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('OC', 'OC36'),
  'animal.table-egg.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('OC', 15_107),
  'animal.table-egg.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('OC', 60_741),
  'animal.wapiti.income.imported.sales.products': incomeSalesProductsAmountResolver('WA'),
  'animal.wapiti.income.imported.sales.cull': incomeSalesCullAmountResolver('WA'),
  'animal.wapiti.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('WA'),
  'animal.wapiti.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('WA'),
  'animal.wapiti.income.imported.inventory.breeders': incomeInventoryBreedersAmount('WA'),
  'animal.wapiti.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('WA'),
  'animal.wapiti.income.imported.deductions': incomeDeductionsAmountResolver('WA'),
  'animal.wapiti.income.imported.short': incomeShortAmountResolver('WA'),
  'animal.wapiti.income.imported.operational': incomeOperationalAmountResolver('WA'),
  'animal.wapiti.expenses.imported.marketing': expensesMarketingAmountResolver('WA', 'WA37'),
  'animal.wapiti.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('WA', 0),
  'animal.wapiti.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('WA', 'WA31'),
  'animal.wapiti.expenses.imported.feed.young': expensesFeedYoungAmountResolver('WA', 'WA32'),
  'animal.wapiti.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('WA', 'WA33'),
  'animal.wapiti.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('WA', 'WA35'),
  'animal.wapiti.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('WA', 'WA36'),
  'animal.wapiti.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('WA', 27_107),
  'animal.wapiti.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('WA', 108_741),
  'animal.weaner.income.imported.sales.products': incomeSalesProductsAmountResolver('NA', ['NA404', 'NA9', 'NA11']),
  'animal.weaner.income.imported.sales.cull': incomeSalesCullAmountResolver('NA', ['NA13', 'NA14']),
  'animal.weaner.income.imported.sales.breeders': incomeSalesBreedersAmountResolver('NA'),
  'animal.weaner.income.imported.insurance.stabilization': incomeInsuranceStabilizationAmountResolver('NA'),
  'animal.weaner.income.imported.inventory.breeders': incomeInventoryBreedersAmount('NA'),
  'animal.weaner.income.imported.inventory.slaughter': incomeInventorySlaughterAmount('NA'),
  'animal.weaner.income.imported.deductions': incomeDeductionsAmountResolver('NA', 245),
  'animal.weaner.income.imported.short': incomeShortAmountResolver('NA', 0),
  'animal.weaner.income.imported.operational': incomeOperationalAmountResolver('NA', 1213),
  'animal.weaner.expenses.imported.marketing': expensesMarketingAmountResolver('NA', ['NA84', 'NA341', 'NA353']),
  'animal.weaner.expenses.imported.livestock.maintenance': expensesLivestockMaintenanceAmountResolver('NA', undefined, [
    'NA18',
    'NA19',
    'NA405',
    'NA414',
  ]),
  'animal.weaner.expenses.imported.feed.adults': expensesFeedAdultsAmountResolver('NA', 'NA21'),
  'animal.weaner.expenses.imported.feed.young': expensesFeedYoungAmountResolver('NA', 'NA22'),
  'animal.weaner.expenses.imported.feed.miscellaneous': expensesFeedMiscellaneousAmountResolver('NA', 'NA23'),
  'animal.weaner.expenses.imported.fees.veterinary': expensesFeesVeterinaryAmountResolver('NA', 'NA24'),
  'animal.weaner.expenses.imported.fees.reproduction': expensesFeesReproductionAmountResolver('NA', 'NA25'),
  'animal.weaner.expenses.imported.fees.insurance': expensesFeesInsuranceAmountResolver('NA', 19_116),
  'animal.weaner.expenses.imported.fees.miscellaneous': expensesFeesMiscellaneousAmountResolver('NA', 191_144),
})
