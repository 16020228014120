import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { Icons } from '../../atoms'
import { Button } from '../../atoms/Button/Button'
import { Label } from '../../atoms/Label/Label'
import { LoadingBar } from '../../atoms/LoadingBar/LoadingBar'
import { type NavigationHandler } from '../../navigation'
import { type BudgetData, type ScenarioData } from '../../types'
import { BudgetReadonlyHeader } from '../Header/BudgetReadonlyHeader'
import { ScenarioTabList } from '../ScenarioTabList/ScenarioTabList'

export interface ScenarioSnapshotMainHeaderProps {
  readonly budget: BudgetData
  readonly loading: boolean
  readonly scenario?: ScenarioData
  readonly snapshotTitle?: string
  readonly onNavigationChange: NavigationHandler
}

export const ScenarioSnapshotMainHeader: FC<ScenarioSnapshotMainHeaderProps> = ({
  budget,
  loading,
  scenario,
  snapshotTitle = '',
  onNavigationChange,
}) => {
  const intl = useIntl()

  return (
    <>
      <div className="fixed inset-x-0 top-0 z-30 gap-2">
        <div className="flex h-[84px] items-center gap-4 overflow-hidden bg-sage-100">
          <div className="flex flex-1 flex-col overflow-hidden">
            <div className="bg-yellow-100 px-4 py-2">
              <div className="flex justify-between">
                <div className="flex items-center gap-1.5">
                  <Button
                    variant="outline"
                    shape="iconOnly"
                    dimension="xs"
                    onClick={() =>
                      onNavigationChange({
                        to: '/budgets/$budgetId/reports/$reportId/files',
                      })
                    }>
                    <Icons.XSm />
                  </Button>
                  <Label className="pt-1 text-sm font-bold">{snapshotTitle}</Label>
                </div>
                <Label className="pt-1 text-sm font-medium">
                  {intl.formatMessage({ id: 'reports.file.readonly' })}
                </Label>
              </div>
            </div>
            <div className="flex h-11 items-end justify-between">
              <div className="flex flex-1 overflow-x-scroll" style={{ scrollbarWidth: 'none' }}>
                <ScenarioTabList tabId={scenario?._id} budget={budget} onNavigationChange={onNavigationChange} />
              </div>
              <div className="flex h-full">
                <BudgetReadonlyHeader offline={false} budget={budget} />
              </div>
            </div>
          </div>
        </div>
        <div className="-mt-0.5 h-0.5 bg-sage-300" />
        <div className="z-40 mt-[-84px] h-2">{loading && <LoadingBar />}</div>
      </div>
      <div className="z-20 h-[84px]" />
    </>
  )
}
