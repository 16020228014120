import { keysToTuple } from '@via/schema'
import { z } from 'zod'

import { FormFiniteNumberValueZod } from '../../atoms/Form/FormFiniteNumberValueZod.ts'

import { MilkExpensesFormDependencies } from './MilkExpensesFormDependenciesContext.ts'

export const MilkExpensesFormZod = z.record(
  z.enum([...keysToTuple(MilkExpensesFormDependencies), 'totalContent', 'totalProduction', 'milkExpenses']),
  FormFiniteNumberValueZod
)

export type MilkExpensesFormData = z.infer<typeof MilkExpensesFormZod>
