import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { FormSubmitProvider } from '../../atoms/Form/FormSubmitProvider'
import { ZodForm } from '../../atoms/Form/ZodForm'
import { InputFormField } from '../../molecules/InputFormField/InputFormField'
import { type UserData } from '../../types'

import { type UserProfileFormData, UserProfileFormDataZod } from './UserProfileFormData'

const UserProfileFormContent: FC = () => {
  const intl = useIntl()

  return (
    <InputFormField<UserProfileFormData, 'oaqNumber'>
      name="oaqNumber"
      label={intl.formatMessage({ id: 'profileView.form.oaqNumber' })}
      className="w-full"
    />
  )
}

export interface UserProfileFormProps {
  readonly data: UserData
  readonly onValueChange: (form: UserProfileFormData) => Promise<void>
}

export const UserProfileForm: FC<UserProfileFormProps> = ({ data, onValueChange }) => {
  const defaultData = UserProfileFormDataZod.parse(data)

  return (
    <ZodForm schema={UserProfileFormDataZod} defaultValues={defaultData}>
      <FormSubmitProvider autoSubmit onFormSubmit={onValueChange}>
        <UserProfileFormContent />
      </FormSubmitProvider>
    </ZodForm>
  )
}
