import { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { useNavigate } from '@tanstack/react-router'
import { Icons } from '@via/components'

import { useAuthenticatedFirebaseAuth } from '../auth/useFirebaseAuth.ts'
import { useOfflineState } from '../contexts/offlineState/useOfflineState.ts'
import { AppWorkerContext } from '../worker/AppWorkerContext.ts'

export const useCurrentUserAvatarProps = () => {
  const { resetRxDB, toggleForceOffline, signOut: workerSignOut, appDatabase } = useContext(AppWorkerContext)
  const { offline, forceOffline } = useOfflineState()
  const { userName, userRole, signOut } = useAuthenticatedFirebaseAuth()
  const navigate = useNavigate()

  const intl = useIntl()

  return useMemo(
    () => ({
      userName,
      userRole,
      options: [
        {
          value: 'profil',
          disabled: offline || forceOffline,
          text: intl.formatMessage({ id: 'mainNav.avatar.profil' }),
          onItemSelect: () => navigate({ to: '/profile' }),
        },
        {
          value: 'signOut',
          text: intl.formatMessage({ id: 'mainNav.avatar.signOut' }),
          onItemSelect: async () => {
            await signOut()
            await workerSignOut()
          },
        },
        {
          value: 'toggleForceOffline',
          disabled: offline && !forceOffline,
          text: (
            <div className="flex items-center gap-1.5">
              <p>{intl.formatMessage({ id: 'mainNav.avatar.offline' })}</p>
              {forceOffline && <Icons.Check className="size-6" />}
            </div>
          ),
          onItemSelect: toggleForceOffline,
        },
        ...(import.meta.env.MODE === 'development'
          ? [
              {
                value: 'cleanup',
                text: intl.formatMessage({ id: 'mainNav.avatar.cleanup' }),
                onItemSelect: resetRxDB,
                disabled: !appDatabase.leaderElector().isLeader,
              },
            ]
          : []),
      ],
    }),
    [
      userName,
      userRole,
      offline,
      forceOffline,
      intl,
      toggleForceOffline,
      resetRxDB,
      appDatabase,
      navigate,
      signOut,
      workerSignOut,
    ]
  )
}
