import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { dataLine } from '../utils'

export const loanSummary2024Blueprint = {
  sections: [
    {
      id: 'finance.loan.summary',
      noHeader: true,
      children: [
        dataLine('finance.loan.total.interest', { variant: 'finalSubTotal', symbol: 'none' }),
        dataLine('finance.loan.interests.grants', { variant: 'basic' }),
        dataLine('finance.loan.cdr', { variant: 'finalSubTotal', symbol: 'none' }),
        dataLine('finance.loan.payments', { variant: 'finalSubTotal', symbol: 'none' }),
        dataLine('finance.loan.balance', { variant: 'finalTotal', symbol: 'none' }),
      ],
    },
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true, withDividerRight: true, className: 'w-[27rem]' },
    { id: 'divider', type: 'divider', className: 'w-1' },
    { id: 'projection', type: 'result-projections', withDividerRight: true, className: 'w-24' },
  ],
} as const satisfies Except<BlueprintTable, 'version'>
