import { useMemo } from 'react'

import { type BudgetReferenceVersionData, useBudget } from '@via/components'
import { collections } from '@via/frontend-schema'

import { useCollection } from '../../firestore/hooks/useCollection.ts'

const latestBudgetReferenceVersion = Number(import.meta.env.VITE_BUDGET_REFERENCE_VERSION)

export const useBudgetReferenceChanges = () => {
  const { referenceVersion: currentReferenceVersion } = useBudget()

  const budgetReferenceVersionsQueryState = useCollection<BudgetReferenceVersionData>({
    query: collections.budgetReferenceVersions.prepare({
      name: `budget reference versions`,
      where: [
        ['version', '>', currentReferenceVersion],
        ['version', '<=', latestBudgetReferenceVersion],
      ],
      orderBy: [['version', 'asc']],
      limit: 10,
    }),
  })

  return useMemo(
    () =>
      // scenarioReferenceVersion can be 0, which is falsy, but also a valid value. Must compare explicitly to undefined.
      currentReferenceVersion === undefined || budgetReferenceVersionsQueryState.empty
        ? []
        : budgetReferenceVersionsQueryState.data.flatMap((entry) => entry.changesDescription),
    [budgetReferenceVersionsQueryState, currentReferenceVersion]
  )
}
