import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/')({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results',
      params,
    })
  },
})
