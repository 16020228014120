import { type RxJsonSchema } from 'rxdb'

import { type RxCollectionDocumentType, type ScenarioRxDatabase } from '../types.ts'

import { UserUpdateJsonSchema } from './common/userUpdateJsonSchema.ts'

type ScenarioRxDocument = RxCollectionDocumentType<ScenarioRxDatabase['scenario']>

export const ScenarioRxSchema = {
  title: 'scenario',
  description: 'scenario schema',
  version: 0,
  type: 'object',
  primaryKey: '_id',
  required: ['_id', 'budgetId'],
  properties: {
    _id: {
      type: 'string',
      maxLength: 100,
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    budgetId: {
      type: 'string',
    },
    currentStateId: {
      type: 'string',
    },
    creation: UserUpdateJsonSchema,
    lastModification: UserUpdateJsonSchema,
    archived: UserUpdateJsonSchema,
  },
} as const satisfies RxJsonSchema<
  Pick<
    ScenarioRxDocument,
    '_id' | 'name' | 'description' | 'budgetId' | 'currentStateId' | 'creation' | 'lastModification' | 'archived'
  >
>
