import { forwardRef, useCallback } from 'react'
import { useIntl } from 'react-intl'

import { PaymentFrequencyToAnnualEventCount } from '@via/compute'
import { type LoanCategory, type PaymentFrequency, type ScenarioLoanStateZod } from '@via/schema'
import dayjs from 'dayjs'
import { isEmpty, sortBy } from 'lodash-es'
import { type Except } from 'type-fest'
import { z } from 'zod'

import { type ZodFormProviderProps, ZodFormSubmitProvider } from '../../atoms/Form/ZodFormSubmitProvider'
import { useStartOfProjectionDate } from '../../context/scenario/useStartOfProjectionDate'
import { localDate } from '../../date'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { type MonetaryRowData } from '../../model'

import { convertLoanFormToScenarioState } from './convertLoanFormToScenarioState'
import { LoanFormZod } from './LoanFormZod'

export type LoanFormSubmitData = z.input<typeof ScenarioLoanStateZod> & {
  readonly type: 'finance-loan'
  readonly loanId: string
}

const minimumLoanPayment = (rate: number, amount: number, frequency: PaymentFrequency): number | null =>
  Number.isFinite(rate) && Number.isFinite(amount)
    ? (rate / PaymentFrequencyToAnnualEventCount[frequency]) * amount
    : null

export type LoanDetailFormData = z.infer<typeof LoanFormZod>

export interface LoanFormProviderProps
  extends Except<
    ZodFormProviderProps<typeof LoanFormZod>,
    'schema' | 'defaultValues' | 'onFormSubmit' | 'onFormApply'
  > {
  readonly data: Except<MonetaryRowData, 'key' | 'computationType'>
  readonly category: LoanCategory

  onSubmit(value: LoanFormSubmitData): Promise<void>
  onApply(value: LoanFormSubmitData): Promise<void>
}

const loanFormData = ({ label, loan }: LoanFormProviderProps['data']): LoanDetailFormData =>
  LoanFormZod.parse({
    ...loan,
    source: loan?.source ?? 'scenario',
    name: label,
    startDate: loan?.startDate?.value ? localDate(loan.startDate.value) : '',
    amount: loan?.amount?.value.toString(),
    interestRate: loan?.interestRate?.override?.value?.toString(),
    duration: loan?.duration?.override?.value?.toString(),
    paymentPlan: {
      amount: loan?.paymentPlan?.amount?.override?.value?.toString(),
      frequency: loan?.paymentPlan?.frequency?.value,
    },
    newCapitalBorrowed: sortBy(Object.entries(loan?.newCapitalBorrowed ?? {}), '[1].date').reduce(
      (acc, [id, { date, value }], index) => ({
        ...acc,
        [id]: {
          index,
          date,
          value,
          payment: loan?.paymentPlan?.paymentAmounts?.[id],
        },
      }),
      {} as LoanDetailFormData['newCapitalBorrowed']
    ),
    earlyRepayment: sortBy(Object.entries(loan?.earlyRepayment ?? {}), '[1].date').reduce(
      (acc, [id, { date, value, withdrawal = '' }], index) => ({
        ...acc,
        [id]: {
          index,
          date,
          value,
          withdrawal,
          remainingDueAmount: loan?.earlyRepaymentRemainingDueAmount?.[id],
          payment: loan?.paymentPlan?.paymentAmounts?.[id],
        },
      }),
      {} as LoanDetailFormData['earlyRepayment']
    ),
    interestRateChange: sortBy(Object.entries(loan?.interestRateChange ?? {}), '[1].date').reduce(
      (acc, [id, { date, value }], index) => ({
        ...acc,
        [id]: {
          index,
          date,
          value,
          payment: loan?.paymentPlan?.paymentAmounts?.[id],
        },
      }),
      {} as LoanDetailFormData['interestRateChange']
    ),
    enabledOptions: [
      ...(loan?.durationWithoutCapitalPaid ? ['duration-without-capital'] : []),
      ...(loan?.durationWithoutInterestPaid ? ['duration-without-interest'] : []),
      ...(loan?.fixedCapitalPaymentAmount ? ['fixed-capital'] : []),
      ...(!isEmpty(loan?.newCapitalBorrowed) ? ['new-capital'] : []),
      ...(!isEmpty(loan?.earlyRepayment) ? ['early-repayment'] : []),
      ...(!isEmpty(loan?.interestRateChange) ? ['interest-rate-change'] : []),
    ],
  })

const loanFormSubmitData = (
  loanId: string,
  category: LoanCategory,
  values: LoanDetailFormData
): LoanFormSubmitData => ({
  type: 'finance-loan',
  loanId,
  category,
  ...convertLoanFormToScenarioState(values),
})

export const LoanFormProvider = forwardRef<HTMLFormElement, LoanFormProviderProps>(
  ({ data, category, onSubmit, onApply, children, ...props }, ref) => {
    const rowId = data.id
    const intl = useIntl()
    const startOfProjectionDate = useStartOfProjectionDate()
    const { formatNumber } = useFormatNumber()

    const onFormSubmit = useCallback(
      async (values: LoanDetailFormData) => {
        await onSubmit(loanFormSubmitData(rowId, category, values))
      },
      [onSubmit, rowId, category]
    )

    const onFormApply = useCallback(
      async (values: LoanDetailFormData) => {
        await onApply(loanFormSubmitData(rowId, category, values))
      },
      [onApply, rowId, category]
    )

    const LoanWithValidationFormZod = LoanFormZod.superRefine((loan, ctx) => {
      const startDate = loan.startDate ? dayjs(loan.startDate, 'YYYY-MM-DD') : null
      const startOfProjection = startOfProjectionDate && dayjs(startOfProjectionDate)
      const earliestDate = startDate ?? startOfProjection

      if (loan.source === 'scenario' && startOfProjection) {
        if (!startDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intl.formatMessage({ id: 'form.required' }),
            path: ['startDate'],
          })
        } else {
          if (startDate.isBefore(startOfProjection)) {
            const afterDate = startOfProjection.subtract(1, 'day').toDate()
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intl.formatMessage({ id: 'form.date.after' }, { date: intl.formatDate(afterDate) }),
              path: ['startDate'],
            })
          }
        }
      }
      if (
        loan.enabledOptions.includes('new-capital') &&
        earliestDate &&
        Object.values(loan.newCapitalBorrowed).some(({ date }) => earliestDate.isAfter(date))
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage({ id: 'form.date.after' }, { date: intl.formatDate(earliestDate?.toDate()) }),
          path: ['newCapitalBorrowed'],
        })
      }
      if (
        loan.enabledOptions.includes('interest-rate-change') &&
        earliestDate &&
        Object.values(loan.interestRateChange).some(({ date }) => earliestDate.isAfter(date))
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage({ id: 'form.date.after' }, { date: intl.formatDate(earliestDate?.toDate()) }),
          path: ['interestRateChange'],
        })
      }
      if (loan.paymentPlan.amount && Number.isFinite(Number(loan.paymentPlan.amount))) {
        const minimum = minimumLoanPayment(
          Number(loan.interestRate || loan.computed.interestRate || loan.estimated.interestRate) / 100,
          Number(loan.amount),
          loan.paymentPlan.frequency
        )
        if (minimum !== null && minimum >= Number(loan.paymentPlan.amount)) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_small,
            message: intl.formatMessage(
              { id: 'form.payment.below.minimum' },
              { minimum: formatNumber(minimum, 'currency', { roundingMode: 'ceil' }) }
            ),
            minimum,
            inclusive: true,
            type: 'number',
            path: ['paymentPlan', 'amount'],
          })
        }
      }
    })

    return (
      <ZodFormSubmitProvider
        key={rowId}
        {...props}
        values={loanFormData(data)}
        autoComplete="off"
        schema={LoanWithValidationFormZod}
        ref={ref}
        onFormSubmit={onFormSubmit}
        onFormApply={onFormApply}>
        {children}
      </ZodFormSubmitProvider>
    )
  }
)
