import { type FC } from 'react'

import { Label } from '../../atoms/Label/Label'
import { UserAvatar, type UserAvatarProps } from '../UserAvatar/UserAvatar'

export type UserAvatarWithNameProps = UserAvatarProps & {
  readonly order: 'avatar-first' | 'avatar-last'
}

export const UserAvatarWithName: FC<UserAvatarWithNameProps> = ({ order, userName, ...props }) => (
  <div className="align-center flex gap-1">
    {order === 'avatar-first' && <UserAvatar userName={userName} {...props} />}
    <Label className="pt-[0.15rem] text-base font-bold">{userName}</Label>
    {order === 'avatar-last' && <UserAvatar userName={userName} {...props} />}
  </div>
)
