import React, { Fragment, useCallback, useMemo } from 'react'

import { useControllableState } from '@radix-ui/react-use-controllable-state'
import { size, sortBy } from 'lodash-es'
import { nanoid } from 'nanoid'

import { AddItemButton } from '../../atoms/AddItemButton/AddItemButton'
import { FormFieldContext } from '../../atoms/Form/FormFieldContext'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { Input } from '../../atoms/Input/Input'
import { Label } from '../../atoms/Label/Label'
import { DateInput } from '../../molecules/DateInput/DateInput'

type InvestmentDatedInputTableState = Record<
  string,
  {
    readonly index: number
    readonly date?: string
    readonly value?: string
  }
>

interface InvestmentDatedInputTableProps {
  readonly name: string
  readonly value?: InvestmentDatedInputTableState
  readonly defaultValue?: InvestmentDatedInputTableState
  onChange?(rows: InvestmentDatedInputTableState): void
  readonly labels: readonly [string, string, string]
  readonly step?: string | number
}

export const InvestmentDatedInputTable = React.forwardRef<HTMLDivElement, InvestmentDatedInputTableProps>(
  ({ name, value, defaultValue, onChange, step, labels: [indexLabel, dateLabel, valueLabel] }, ref) => {
    const [state, setState] = useControllableState({ prop: value, defaultProp: defaultValue, onChange })

    const addNewItem = useCallback(() => {
      setState((prevState) => ({
        ...prevState,
        [nanoid()]: {
          index: size(prevState),
          date: '',
          value: '',
        },
      }))
    }, [setState])

    const fieldContext = useMemo(() => ({ name }), [name])

    return (
      <FormFieldContext.Provider value={fieldContext}>
        <div ref={ref} className="grid grid-cols-11 gap-0.5">
          <Label className="col-span-3 bg-gray-100 p-1.5 text-center font-bold">{indexLabel}</Label>
          <Label className="col-span-4 bg-gray-100 p-1.5 text-end font-bold">{dateLabel}</Label>
          <Label className="col-span-4 bg-gray-100 p-1.5 text-end font-bold">{valueLabel}</Label>

          {sortBy(Object.entries(state ?? {}), '[1].index').map(([id, row], index) => (
            <Fragment key={id}>
              <div className="col-span-3 self-center justify-self-center">{index + 1}</div>
              <div className="col-span-4">
                <DateInput
                  align="right"
                  onChange={(event) => {
                    setState((prevState) => ({ ...prevState, [id]: { ...row, date: event.target.value } }))
                  }}
                  value={row.date}
                  className="rounded-none border-0 border-b border-b-gray-200 shadow-none focus:border-0 focus:border-b focus:border-blue-600 focus:ring-0 focus-visible:border-0 focus-visible:border-b focus-visible:border-blue-600 focus-visible:ring-0"
                />
              </div>
              <Input
                align="right"
                type="number"
                step={step}
                onChange={(event) => {
                  setState((prevState) => ({ ...prevState, [id]: { ...row, value: event.target.value } }))
                }}
                value={row.value}
                className="col-span-4 rounded-none border-0 border-b border-b-gray-200 shadow-none focus:border-0 focus:border-b focus:border-blue-600 focus:ring-0 focus-visible:border-0 focus-visible:border-b focus-visible:border-blue-600 focus-visible:ring-0"
              />
            </Fragment>
          ))}
        </div>
        <FormMessage className="pt-2" />
        <AddItemButton className="m-2" onClick={addNewItem} />
      </FormFieldContext.Provider>
    )
  }
)
