import { mapNonNullValues, type RowData } from '@via/compute'

import { type MonetaryCellData, type MonetaryRowData } from '../MonetaryRowData'

const computeMonetaryCellProportion = (cell: MonetaryCellData, denominator: string | undefined) => {
  const proportion = denominator ? (100 * cell.value) / Number(denominator) : undefined
  return Number.isFinite(proportion) ? { proportion } : {}
}

export const computeMonetaryRowProportions = (
  row: MonetaryRowData,
  { references, values, reference }: RowData<'total' | 'composite'>
): MonetaryRowData => {
  switch (row.computationType) {
    case 'total':
    case 'composite':
    case 'grbf':
      return {
        ...row,
        references: mapNonNullValues(row.references, (cell, year) => ({
          ...cell,
          ...computeMonetaryCellProportion(cell, references?.[year]?.value),
        })),
        values: mapNonNullValues(row.values, (cell, year) => ({
          ...cell,
          ...computeMonetaryCellProportion(cell, values?.[year]?.value),
        })),
        ...(row.reference
          ? {
              reference: {
                ...row.reference,
                ...computeMonetaryCellProportion(row.reference, reference?.value),
              },
            }
          : {}),
      }
    default:
      return row
  }
}
