import React from 'react'

import { cn } from '../../lib/utils'

export const Table = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => (
    <div className="relative w-full overflow-auto">
      <table ref={ref} className={cn('w-full', className)} {...props} />
    </div>
  )
)

export const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <thead ref={ref} className={cn('bg-gray-100 [&_tr]:border-y [&_tr]:border-gray-200', className)} {...props} />
  )
)

export const TableHead = React.forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <th
      ref={ref}
      className={cn('select-none p-3 text-left align-middle text-xs font-medium leading-none text-gray-700', className)}
      {...props}
    />
  )
)

export const TableHeadWithSpacing = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <TableHead ref={ref} className={cn('border-r-2 border-r-white p-1.5 font-bold', className)} {...props} />
))

export const TableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => <tbody ref={ref} className={cn('bg-white', className)} {...props} />
)

export const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => (
    <tr ref={ref} className={cn('border-b border-gray-200 transition-colors', className)} {...props} />
  )
)

export const TableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <td
      ref={ref}
      className={cn('p-3 align-middle text-sm font-light leading-none tracking-[-0.32px] text-black', className)}
      {...props}
    />
  )
)

export const TableCellWithSpacing = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <TableCell
    ref={ref}
    className={cn('text-normal border-r-2 border-r-white p-1 tracking-[-0.28px]', className)}
    {...props}
  />
))
