import { type FC, type ReactNode } from 'react'

import * as RadixToast from '@radix-ui/react-toast'

interface ToastProps {
  readonly title: string | ReactNode
  readonly description: string
  readonly open: boolean
  readonly setOpen: (open: boolean) => void
  readonly duration?: number
}

export const Toast: FC<ToastProps> = ({ title, open, setOpen, description, duration = 5000 }) => (
  <RadixToast.Provider swipeDirection="down" swipeThreshold={10}>
    <RadixToast.Root
      className="max-w-md rounded-lg border border-fuschia-800 bg-fuschia-100 p-3 data-[swipe=cancel]:translate-x-0 data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[state=closed]:animate-[hide_350ms] data-[state=open]:animate-[slideInY_150ms] data-[swipe=end]:animate-[swipeOutY_100ms] data-[swipe=cancel]:transition-transform"
      open={open}
      onOpenChange={setOpen}
      duration={duration}>
      <RadixToast.Title className="font-bold">{title}</RadixToast.Title>
      <RadixToast.Description className="text-sm text-gray-800">{description}</RadixToast.Description>
    </RadixToast.Root>
    <RadixToast.Viewport className="fixed bottom-0 left-[calc(50vw-224px-48px)] z-[200] p-12" />
  </RadixToast.Provider>
)
