import { type FC, type PropsWithChildren, type ReactNode, type RefObject, useContext } from 'react'

import { useEventListener } from '@react-hookz/web/useEventListener/index.js'
import { handleNotAwaitedPromise } from '@via/frontend-sentry'
import { uniq } from 'lodash-es'

import { useKeyDown } from '../../hooks/useKeyDown'
import { MonetaryTableEditingStateContext } from '../editing/MonetaryTableEditingStateProvider.tsx'

import { useTableNavigationInsets } from './useTableNavigationInsets'

interface TableWithNavigationProvider {
  readonly containerRef: RefObject<HTMLElement>
  readonly nbCellsPerRow: number
  readonly children: ReactNode
}

const TableWithNavigationProvider: FC<PropsWithChildren<TableWithNavigationProvider>> = ({
  containerRef,
  nbCellsPerRow,
  children,
}) => {
  const editing = useContext(MonetaryTableEditingStateContext)

  useEventListener(document, 'mousemove', () => {
    document.exitPointerLock()
  })
  const { topInset, bottomInset, rightInset, leftInset } = useTableNavigationInsets(containerRef)

  const goToCell = (movement: number, event: KeyboardEvent) => {
    const activeCell = document?.activeElement

    const hasInputFocused = document.activeElement?.tagName === 'INPUT'
    if (!activeCell || editing || hasInputFocused) {
      return
    }

    const cellsNodes = containerRef.current?.querySelectorAll<HTMLElement>('[tabIndex="0"]') ?? []
    const cells = [...cellsNodes]
    const currentCellIndex = cells.indexOf(activeCell as HTMLDivElement)

    if (currentCellIndex === -1) {
      return
    }

    // Prevents going to the previous or next row when the current cell is the first or last of the row
    if (nbCellsPerRow > 1 && currentCellIndex % nbCellsPerRow === nbCellsPerRow - 1 && movement === 1) {
      return
    }
    if (nbCellsPerRow > 1 && currentCellIndex % nbCellsPerRow === 0 && movement === -1) {
      return
    }

    const index = currentCellIndex + movement
    const cell = cells[index]
    if (!cell) {
      if (movement > 1) {
        // Moving down to next table
        const allNodes = document.querySelectorAll<HTMLElement>('[tabIndex="0"]') ?? []
        const allCells = [...allNodes]

        const activeCellRect = activeCell.getBoundingClientRect()
        const minX = activeCellRect.x
        const maxX = activeCellRect.x + activeCellRect.width

        const allLinesYs = uniq(allCells.map((c) => c.getBoundingClientRect().y))
        const allLowerLinesYs = allLinesYs.filter((y) => y > activeCellRect.y)
        const lowerLineY = Math.min(...allLowerLinesYs)

        const lowerNextCell = allCells.find((c) => {
          const otherRect = c.getBoundingClientRect()
          return otherRect.x >= minX && otherRect.x < maxX && otherRect.y === lowerLineY
        })

        if (lowerNextCell) {
          //  setTimeout is used to ensure that this focus event happens last in the event loop
          setTimeout(() => {
            lowerNextCell.focus({ preventScroll: true })
          }, 0)
        } else {
          // There is no cell below in the next table, so we focus the first cell of the next table
          const firstCellInNextLine = allCells.find((c) => c.getBoundingClientRect().y === lowerLineY)
          //  setTimeout is used to ensure that this focus event happens last in the event loop
          setTimeout(() => {
            firstCellInNextLine?.focus({ preventScroll: true })
          }, 0)
        }
      } else if (movement < -1) {
        // Moving up to previous table
        const allNodes = document.querySelectorAll<HTMLElement>('[tabIndex="0"]') ?? []
        const allCells = [...allNodes]

        const activeCellRect = activeCell.getBoundingClientRect()
        const minX = activeCellRect.x
        const maxX = activeCellRect.x + activeCellRect.width

        const allLinesYs = uniq(allCells.map((c) => c.getBoundingClientRect().y))
        const allUpperLinesYs = allLinesYs.filter((y) => y < activeCellRect.y)
        const upperLineY = Math.max(...allUpperLinesYs)

        const upperPreviousCell = allCells.find((c) => {
          const otherRect = c.getBoundingClientRect()
          return otherRect.x >= minX && otherRect.x < maxX && otherRect.y === upperLineY
        })

        if (upperPreviousCell) {
          //  setTimeout is used to ensure that this focus event happens last in the event loop
          setTimeout(() => {
            upperPreviousCell.focus({ preventScroll: true })
          }, 0)
        } else {
          // There is no cell above in the previous table, so we focus the first cell of the previous table
          const firstCellInPreviousLine = allCells.find((c) => c.getBoundingClientRect().y === upperLineY)
          //  setTimeout is used to ensure that this focus event happens last in the event loop
          setTimeout(() => {
            firstCellInPreviousLine?.focus({ preventScroll: true })
          }, 0)
        }
      }

      return
    }

    handleNotAwaitedPromise(document.body.requestPointerLock())
    event.preventDefault()
    cell.focus({ preventScroll: true })

    const { top, bottom, left, right } = cell.getBoundingClientRect()

    if (top < topInset) {
      window.scrollBy(0, top - topInset)
    }

    if (bottom > bottomInset) {
      window.scrollBy(0, bottom - bottomInset)
    }

    if (left < leftInset) {
      window.scrollBy(left - leftInset, 0)
    }

    if (right > rightInset) {
      window.scrollBy(right - rightInset, 0)
    }
  }

  useKeyDown(
    (event) => {
      goToCell(-1, event)
    },
    ['ArrowLeft']
  )
  useKeyDown(
    (event) => {
      goToCell(1, event)
    },
    ['ArrowRight']
  )
  useKeyDown(
    (event) => {
      goToCell(nbCellsPerRow, event)
    },
    ['ArrowDown']
  )
  useKeyDown(
    (event) => {
      goToCell(-nbCellsPerRow, event)
    },
    ['ArrowUp']
  )

  return children
}

interface TableNavigationProviderProps extends TableWithNavigationProvider {
  readonly withNavigation?: boolean
}

export const TableNavigationProvider: FC<PropsWithChildren<TableNavigationProviderProps>> = ({
  containerRef,
  nbCellsPerRow,
  withNavigation = true,
  children,
}) =>
  withNavigation ? (
    <TableWithNavigationProvider containerRef={containerRef} nbCellsPerRow={nbCellsPerRow}>
      {children}
    </TableWithNavigationProvider>
  ) : (
    children
  )
