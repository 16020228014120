import { useState } from 'react'

import { captureException } from '@sentry/react'
import { type GroupData, type ProfileViewProps, useAsyncEffect } from '@via/components'
import { collections } from '@via/frontend-schema'
import { getDownloadURL, getStorage, ref, StorageError, uploadBytes } from 'firebase/storage'

import { useAuthenticatedFirebaseAuth } from '../../auth/useFirebaseAuth'
import { useCollection } from '../../firestore/hooks/useCollection'
import { useCurrentUserAvatarProps } from '../../navigation/useCurrentUserAvatarProps'

export const useProfilePage = (): ProfileViewProps => {
  const { user, userDocument } = useAuthenticatedFirebaseAuth()
  const avatar = useCurrentUserAvatarProps()
  const [userSignatureUrl, setUserSignatureUrl] = useState<string | undefined>()

  useAsyncEffect(async () => {
    if (!userDocument?.signaturePath) {
      setUserSignatureUrl(undefined)
      return
    }

    try {
      const storage = getStorage()
      const fileRef = ref(storage, userDocument?.signaturePath)
      const downloadUrl = await getDownloadURL(fileRef)
      setUserSignatureUrl(downloadUrl)
    } catch (error) {
      if (error instanceof StorageError && error.code === 'storage/object-not-found') {
        setUserSignatureUrl(undefined)
      } else {
        captureException(error)
      }
    }
  }, [userDocument])

  const onUserUpdate = async ({ oaqNumber }: { oaqNumber: string }) => {
    await collections.users.update(user.uid, { oaqNumber })
  }

  const { data: userGroups } = useCollection<GroupData>({
    query: collections.groups.prepare({
      name: 'user group list',
      where: [['members', 'array-contains', user.uid]],
    }),
  })

  const onSignatureSelect = async (file: File) => {
    const extension = file.type.split('/')[1]?.split('.')[0]?.split('+')[0] ?? 'jpg'
    const uploadFilePath = `users/${user.uid}/signature.${extension}`

    const storage = getStorage()
    const fileRef = ref(storage, uploadFilePath)

    await uploadBytes(fileRef, file)
    setUserSignatureUrl(await getDownloadURL(fileRef))

    await collections.users.update(user.uid, { signaturePath: uploadFilePath })
  }

  return {
    avatar,
    userGroups,
    user: userDocument,
    userSignatureUrl,
    onUserUpdate,
    onSignatureSelect,
  }
}
