import { scenarioData } from '@via/components'
import { addSentryBreadcrumb } from '@via/frontend-sentry'
import { concatMap, retry, tap, timeout } from 'rxjs'

import { AppWorker } from '../../worker/AppWorker.ts'
import { type ScenarioRxDatabase } from '../types.ts'

import { rxdbScenarioData$ } from './rxdbScenarioData.ts'

export const rxdbScenarioDataWithRows$ = (database: ScenarioRxDatabase) =>
  rxdbScenarioData$(database).pipe(
    tap(({ scenario }) => {
      if (import.meta.env.DEV) {
        performance.mark(`rxdb-scenario-data:${scenario.currentStateId}:start-compute`)
      }
    }),
    concatMap(async ({ scenario, scenarioState, reference }) => {
      const rows = await AppWorker.instance.computeAllRows(scenario.budgetId, scenario._id, scenarioState, reference)
      return {
        scenario: scenarioData(scenario, scenarioState, reference),
        rows,
      }
    }),
    tap(({ scenario }) => {
      if (import.meta.env.DEV) {
        performance.mark(`rxdb-scenario-data:${scenario.currentStateId}:end`)
        const measure = performance.measure(
          `rxdb-scenario-data:${scenario.currentStateId}`,
          `rxdb-scenario-data:${scenario.currentStateId}:start`,
          `rxdb-scenario-data:${scenario.currentStateId}:end`
        )
        // eslint-disable-next-line no-console
        console.info('rxdbScenarioData', measure)
      }
    }),
    timeout({ first: 3000 }),
    retry({ count: 1 }),
    addSentryBreadcrumb((data) => ({
      level: 'debug',
      category: 'rxdb',
      message: 'scenario data',
      data,
    }))
  )
