import { BigNumber } from 'bignumber.js'

import { createBudgetDefinition, type ViagritelRowCourtTerme } from '../types'
import { isFermeBudgitel2021, isFermeBudgitel2022, sumBy } from '../utils'

const RECEIVABLES_CRITERIA = [
  {
    type: ['CR', 'FP'],
    type_ligne: [
      'GC', // Global Grandes cultures
      'GL', // Global maraîcher
      'GQ', // Pépinière ornementale
      'GS', // Serre ornementale
      'ZZ', // Divers
    ],
  },
  {
    type: ['CR', 'FP'],
    type_ligne: 'AN',
    production: [
      'AX', // Exotique
      'BI', // Bouvillons
      'BS', // Bisons
      'CR', // Chèvres
      'CV', // Chevaux
      'DG', // Dindons de gril
      'DL', // Dindons lourds
      'FI', // Porcs
      'JV', // Veaux de grain
      'IN', // Ovins
      'LA', // Lait
      'LP', // Lapins
      'NA', // Porcelets
      'OC', // Oeufs consommation
      'OI', // Oeufs incubation
      'OT', // Ratites
      'PG', // Poulets de gril
      'PS', // Pisciculture
      'ST', // Cerfs
      'TA', // Taures d'élevage
      'VA', // Vache-veau
      'VL', // Veaux de lait
      'WA', // Wapitis
    ],
  },
  {
    type: ['CR', 'FP'],
    production: [
      'DA', // Érablière
      'T1', // Autr. act. comm.
      'T2', // Fromagerie
      'T3', // Agro-tourisme
      'T4', // Carrière
      'T5', // Déneigement
      'T6', // Boulangerie
      'T7', // Boucherie
      'T8', // Miel
      'IS', // Boisé
    ],
  },
  {
    type: 'CR',
    type_ligne: [
      '40', // ASRA
    ],
    production: [
      'BI', // Bouvillons
      'FI', // Porcs
      'GC', // Grandes cultures GC102
      'JV', // Veaux de grain
      'NA', // Porcelets
      'VA', // Vache-veau
      'VL', // Veaux de lait
    ],
  },
  {
    type: 'CR',
    type_ligne: [
      '20', // Agri-stabilité
      '30', // Agri (Qc & Invest) contrib. estim. FADQ
      '50', // ASRA - Ovins de Lait
      '60', // ASRA - Ovins lourd
      '70', // ASRA-Vaches-veaux
      '80', // ASRA-Bouvillons dans ferme Vaches-veaux
    ],
  },
] as const

const PAYABLES_CRITERIA = [
  {
    type: 'CP',
    type_ligne: '30', // Agri (Qc & Invest) contrib. estim. Entr ZZ429
  },
  {
    type: ['CP', 'FR'],
    type_ligne: [
      'GC', // Global Grandes cultures GC100
      'GL', // Global maraîcher        GL53
      'GQ', // Pépinière ornementale   ZZ536 + ZZ537
      'GS', // Serre ornementale       ZZ534 + ZZ535
      'ZZ', // Divers                  Pas de cri
    ],
  },
  {
    type: ['CP', 'FR'],
    type_ligne: 'AN',
    production: [
      'AX', // Exotique
      'BI', // Bouvillons
      'BS', // Bisons
      'CR', // Chèvres
      'CV', // Chevaux
      'DG', // Dindons de gril
      'DL', // Dindons lourds
      'FI', // Porcs
      'JV', // Veaux de grain
      'IN', // Ovins
      'LA', // Lait
      'LP', // Lapins
      'NA', // Porcelets
      'OC', // Oeufs consommation
      'OI', // Oeufs incubation
      'OT', // Ratites
      'PG', // Poulets de gril
      'PS', // Pisciculture
      'ST', // Cerfs
      'TA', // Taures d'élevage
      'VA', // Vache-veau
      'VL', // Veaux de lait
      'WA', // Wapitis
    ],
  },
  {
    type: ['CP', 'FR'],
    production: [
      'DA', // Érablière
      'T1', // Autr. act. comm.
      'T2', // Fromagerie
      'T3', // Agro-tourisme
      'T4', // Carrière
      'T5', // Déneigement
      'T6', // Boulangerie
      'T7', // Boucherie
      'T8', // Miel
      'IS', // Boisé
    ],
  },
] as const

const movement = (rows: ViagritelRowCourtTerme[]) => sumBy(rows, 'valeur_fin').minus(sumBy(rows, 'valeur_depart'))

export const finance = createBudgetDefinition({
  'finance.short.imported.liquidities.balance': {
    testResolver: 'ZZ16', // Encaisse & placement CT fin
    databaseResolver: {
      resolve: ({ courtsTermes }) => sumBy(courtsTermes({ type: 'EP' }), 'valeur_fin'),
    },
  },
  'finance.short.imported.assets.programs.balance': {
    testResolver: 'ZZ434',
    databaseResolver: {
      resolve: ({ courtsTermes }) => sumBy(courtsTermes({ type: 'CS' }), 'valeur_fin'),
    },
  },
  'finance.short.imported.assets.programs.movement': {
    testResolver: ({ cri }) =>
      cri('ZZ434') // CS Agri (Qc & Invest.) fin g ct
        .minus(cri('ZZ432')), // CS Agri (Qc & Invest.) déb g ct
    databaseResolver: {
      resolve: ({ courtsTermes }) => movement(courtsTermes({ type: 'CS' })),
    },
  },
  'finance.short.imported.assets.receivables.balance': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(1_189_214.6208),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(1_293_300),
      },
    ],
    databaseResolver: {
      resolve: ({ courtsTermes }) => sumBy(courtsTermes(...RECEIVABLES_CRITERIA), 'valeur_fin'),
    },
  },
  'finance.short.imported.assets.receivables.movement': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ courtsTermes }) => movement(courtsTermes(...RECEIVABLES_CRITERIA)),
    },
  },
  'finance.short.imported.liabilities.credit.balance': {
    testResolver: ({ cri }) =>
      cri('ZZ91') // Marge de crédit fin
        .plus(cri('ZZ93')), // Paiement anticipé fin
    databaseResolver: {
      resolve: ({ courtsTermes }) =>
        sumBy(
          courtsTermes({
            type: 'CP',
            type_ligne: [
              '10', // Marge de crédit     ZZ91
              '20', // Paiements anticipés ZZ93
            ],
          }),
          'valeur_fin'
        ),
    },
  },
  'finance.short.imported.liabilities.credit.movement': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ courtsTermes }) =>
        movement(
          courtsTermes({
            type: 'CP',
            type_ligne: [
              '10', // Marge de crédit     ZZ91
              '20', // Paiements anticipés ZZ93
            ],
          })
        ),
    },
  },
  'finance.short.imported.liabilities.payables.balance': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(551_228.1604),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(523_250),
      },
    ],
    databaseResolver: {
      resolve: ({ courtsTermes }) => sumBy(courtsTermes(...PAYABLES_CRITERIA), 'valeur_fin'),
    },
  },
  'finance.short.imported.liabilities.payables.movement': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ courtsTermes }) => movement(courtsTermes(...PAYABLES_CRITERIA)),
    },
  },
  'finance.asset.inventory.imported.breeders.amount': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ animaux }) =>
        sumBy(
          animaux({
            production: [
              'CR', // Chèvres
              'CV', // Chevaux
              'LA', // Vache
              'LP', // Lapin
              'IN', // Agneau
              'NA', // Porc
              'OC', // Poule pour oeuf de consommation
              'OI', // Poule pour oeuf d'incubation
              'VA', // Vache veau
              'VL', // Veau
              'TA', // Taure
            ],
            type: 'breeding',
          }),
          'valeur_totale_fin'
        ),
    },
  },
  'finance.asset.imported.crops.total.inflation': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ cerealesFourrages }) =>
        cerealesFourrages({ type_production: 'CE' }).reduce(
          (acc, item) => acc.plus(item.prix_unite_fin.minus(item.prix_unite_debut).times(item.inventaire_debut)),
          new BigNumber(0)
        ),
    },
  },
  'finance.asset.imported.forage.total.inflation': {
    testResolver: false,
    databaseResolver: {
      resolve: ({ cerealesFourrages }) =>
        cerealesFourrages({ type_production: ['FO', 'PL'] }).reduce(
          (acc, item) => acc.plus(item.prix_unite_fin.minus(item.prix_unite_debut).times(item.inventaire_debut)),
          new BigNumber(0)
        ),
    },
  },
})
