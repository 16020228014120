import { forwardRef } from 'react'

import { type Unit } from '@via/schema'
import { isNumber, round } from 'lodash-es'
import { z } from 'zod'

import { FormFiniteNumberValueZod } from '../../atoms/Form/FormFiniteNumberValueZod.ts'
import { ZodFormSubmitProvider } from '../../atoms/Form/ZodFormSubmitProvider'

import { InlineEditingCellFormField } from './InlineEditingCellFormField.tsx'

const InlineEditingCellFormZod = z.object({
  value: FormFiniteNumberValueZod,
})

export interface InlineEditingCellProps {
  readonly value: number | string | undefined
  readonly onConfirm: (value: string) => Promise<void> | void
  readonly className?: string
  readonly unit?: Unit
}

export const InlineEditingCellForm = forwardRef<HTMLFormElement, InlineEditingCellProps>(
  ({ value, onConfirm, ...props }, ref) => (
    <ZodFormSubmitProvider<typeof InlineEditingCellFormZod>
      schema={InlineEditingCellFormZod}
      defaultValues={isNumber(value) ? { value: String(round(value, 2)) } : { value }}
      onFormSubmit={(data) => onConfirm(data.value)}
      ref={ref}>
      <InlineEditingCellFormField<z.infer<typeof InlineEditingCellFormZod>, 'value'> name="value" {...props} />
    </ZodFormSubmitProvider>
  )
)
