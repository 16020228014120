import { forwardRef, type HTMLAttributes } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const labelVariants = cva('select-none tracking-[-0.28px] text-gray-700', {
  variants: {
    dimension: {
      xs: 'leading-[0.9] text-xxs',
      sm: 'leading-[0.5] text-xs',
    },
  },
  defaultVariants: {
    dimension: 'sm',
  },
})

export interface LabelProps extends HTMLAttributes<HTMLParagraphElement>, VariantProps<typeof labelVariants> {}

export const Label = forwardRef<HTMLParagraphElement, LabelProps>(({ className, dimension, ...props }, ref) => (
  <p ref={ref} className={cn(labelVariants({ dimension }), className)} {...props} />
))
