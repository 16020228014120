import { type KeyboardEvent, useCallback, useContext, useState } from 'react'
import { type FieldPathByValue, type FieldPathValue, type FieldValues, useFormContext } from 'react-hook-form'

import * as Popover from '@radix-ui/react-popover'
import { type Except } from 'type-fest'

import { MonetaryTableEditingContext } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import { cn } from '../../lib/utils.ts'
import {
  InlineEditingCellFormField,
  type InlineEditingCellFormFieldProps,
} from '../InlineEditingCell/InlineEditingCellFormField.tsx'

export interface PopoverInlineCellFormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends Except<InlineEditingCellFormFieldProps<TFieldValues, TName>, 'onKeyDown'> {
  readonly editable?: boolean
}

export const PopoverInlineCellFormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>({
  name,
  defaultValue = '' as FieldPathValue<TFieldValues, TName>,
  editable = true,
  min,
  max,
  className,
  children,
  ...props
}: PopoverInlineCellFormFieldProps<TFieldValues, TName>) => {
  const { setValue, formState } = useFormContext<TFieldValues>()

  const [open, setOpen] = useState<boolean>(false)
  const { setEditing: setContextEditing } = useContext(MonetaryTableEditingContext)

  const setEditing = useCallback(
    (state: boolean) => {
      setOpen(state)
      setContextEditing(state)
    },
    [setOpen, setContextEditing]
  )

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        setEditing(true)
        e.preventDefault()
      }
      if (e.code === 'Backspace') {
        setValue(name, '' as FieldPathValue<TFieldValues, TName>)
        e.preventDefault()
      }
      if (['-', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
        setEditing(true)
      }
    },
    [name, setEditing, setValue]
  )

  const isCurrentInputValueValid = useCallback(
    (e: KeyboardEvent): boolean => {
      if (!(e.target instanceof HTMLInputElement)) {
        return false
      }
      if (e.target.value === '') {
        return true
      }
      const currentValue = Number(e.target.value)
      return (
        Number.isFinite(currentValue) &&
        !(Number.isFinite(Number(min)) && currentValue <= Number(min)) &&
        !(Number.isFinite(Number(max)) && currentValue >= Number(max))
      )
    },
    [min, max]
  )

  const onInputKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Enter' && isCurrentInputValueValid(e)) {
        setEditing(false)
        e.preventDefault()
      }
    },
    [setEditing, isCurrentInputValueValid]
  )

  if (!editable || formState.disabled || formState.isSubmitting) {
    return children
  }

  return (
    <Popover.Root open={open} onOpenChange={setEditing}>
      <Popover.Trigger onKeyDown={onKeyDown} asChild>
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="z-[9999]">
          <InlineEditingCellFormField
            name={name}
            className={cn('-mt-6', className)}
            onKeyDown={onInputKeyDown}
            defaultValue={defaultValue}
            min={min}
            max={max}
            {...props}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
