import { useCallback, useContext } from 'react'

import { type Except } from 'type-fest'

import { LoanOperationsContext } from '../../context'
import { type MonetaryRowData } from '../../model'
import { LoanDetail } from '../LoanDetail/LoanDetail'

import { type MonetaryReadonlyValueCellProps } from './MonetaryReadonlyValueCell'
import { SheetEditableValueCell } from './SheetEditableValueCell'

export interface LoanDetailValueCellProps extends Except<MonetaryReadonlyValueCellProps, 'children'> {
  readonly rowData: MonetaryRowData
  readonly disabled?: boolean
}

export const LoanDetailValueCell = ({ rowData, cellData, disabled, ...props }: LoanDetailValueCellProps) => {
  const { onDeleteLoan, onUpdateLoan } = useContext(LoanOperationsContext)
  const { stateDataId, id } = rowData
  const onDelete = useCallback(
    async (closeSheet: () => void) => {
      await onDeleteLoan(stateDataId ?? id, closeSheet)
    },
    [stateDataId, id, onDeleteLoan]
  )

  return (
    <SheetEditableValueCell cellData={cellData} {...props}>
      {({ onClose }) => (
        <LoanDetail
          data={rowData}
          category={rowData.loan?.category ?? 'agricultural'}
          disabled={disabled}
          onSubmit={async (value) => {
            await onUpdateLoan(value)
            onClose()
          }}
          onApply={async (value) => onUpdateLoan(value)}
          onDelete={rowData.loan?.source !== 'imported' ? () => onDelete(onClose) : undefined}
        />
      )}
    </SheetEditableValueCell>
  )
}
