import { type FC, useContext } from 'react'

import { type YearKey } from '@via/schema'
import { type Except } from 'type-fest'

import { MilkOperationsContext } from '../../context'
import { MilkExpensesForm } from '../MilkExpensesForm/MilkExpensesForm.tsx'

import { type MonetaryReadonlyValueCellProps } from './MonetaryReadonlyValueCell'
import { SheetEditableValueCell } from './SheetEditableValueCell'

export interface MilkExpensesValueCellProps extends Except<MonetaryReadonlyValueCellProps, 'children'> {
  readonly year: YearKey
}

export const MilkExpensesValueCell: FC<MilkExpensesValueCellProps> = ({ year, cellData, ...props }) => {
  const { onUpdateExpenses } = useContext(MilkOperationsContext)
  return (
    <SheetEditableValueCell cellData={cellData} {...props}>
      {({ onClose }) => (
        <MilkExpensesForm
          year={year}
          onSubmit={async (values) => {
            await onUpdateExpenses(values)
            onClose()
          }}
          onApply={onUpdateExpenses}
        />
      )}
    </SheetEditableValueCell>
  )
}
