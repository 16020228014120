import { CUMIPMT, CUMPRINC, EFFECT, NPER, PMT } from '@formulajs/formulajs'
import { addBreadcrumb } from '@sentry/core'
import { BigNumber } from 'bignumber.js'

type Value = BigNumber | string | number

const fromBigNumber = <T extends string>(data: Record<T, Value>): Record<T, number> =>
  Object.entries(data).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value instanceof BigNumber ? value.toNumber() : Number(value) }),
    {} as Record<T, number>
  )

const enum PaymentsAreDue {
  AtTheEndOfThePeriod = 0,
  AtTheBeginningOfThePeriod = 1,
}

function toBigNumber(name: string, args: Record<string, unknown>, result: number | Error) {
  if (result instanceof Error) {
    addBreadcrumb({
      level: 'error',
      message: `could not compute ${name}`,
      category: 'compute',
      data: { args, result },
    })

    throw new Error(`could not compute ${name}`, { cause: result })
  }
  return new BigNumber(result)
}

export const nper = (
  rate: Value,
  pmt: Value,
  pv: Value,
  fv: Value = 0,
  type: PaymentsAreDue = PaymentsAreDue.AtTheEndOfThePeriod
) => {
  const args = fromBigNumber({ rate, pmt, pv, fv, type })
  return toBigNumber('NPER', args, NPER(args.rate, args.pmt, args.pv, args.fv, type))
}

export const pmt = (
  rate: Value,
  periods: Value,
  pv: Value,
  fv: Value = 0,
  type: PaymentsAreDue = PaymentsAreDue.AtTheEndOfThePeriod
) => {
  const args = fromBigNumber({ rate, periods, pv, fv, type })
  return toBigNumber('PMT', args, PMT(args.rate, args.periods, args.pv, args.fv, type))
}

export const cumipmt = (
  rate: Value,
  periods: Value,
  pv: Value,
  start_period: Value,
  end_period: Value,
  type: PaymentsAreDue = PaymentsAreDue.AtTheEndOfThePeriod
) => {
  const args = fromBigNumber({ rate, periods, pv, start_period, end_period, type })

  if (args.rate <= 0) {
    return new BigNumber(0)
  }

  return toBigNumber(
    'CUMIPMT',
    args,
    CUMIPMT(args.rate, args.periods, args.pv, args.start_period, args.end_period, type)
  )
}

export const cumprinc = (
  rate: Value,
  periods: Value,
  pv: Value,
  start_period: Value,
  end_period: Value,
  type: PaymentsAreDue = PaymentsAreDue.AtTheEndOfThePeriod
) => {
  const args = fromBigNumber({ rate, periods, pv, start_period, end_period, type })

  if (args.rate <= 0) {
    const payments = args.pv / args.periods
    const totalPeriods = args.end_period - args.start_period + 1

    return new BigNumber(payments * totalPeriods).negated()
  }

  return toBigNumber(
    'CUMPRINC',
    args,
    CUMPRINC(args.rate, args.periods, args.pv, args.start_period, args.end_period, type)
  )
}

export const effect = (rate: Value, npery: Value) => {
  const args = fromBigNumber({ rate, npery })
  return toBigNumber('EFFECT', args, EFFECT(args.rate, args.npery))
}
