import { type CellData, convertRowData } from '@via/compute'
import { type BaseRowData, type BaseWeightedRowData } from '@via/schema'

import { type OverridableMonetaryCellData } from '../MonetaryRowData.ts'

import { overridableMonetaryCellData } from './cell'

export const monetaryFromCellRowData = <TRowData extends BaseRowData<CellData>>(
  rowData: TRowData,
  convert = overridableMonetaryCellData
) => convertRowData<CellData, OverridableMonetaryCellData, TRowData>(rowData, convert)

export const monetaryFromWeightedCellRowData = <TRowData extends BaseWeightedRowData<CellData>>(
  { weighted, ...rowData }: TRowData,
  weight?: string,
  convert = overridableMonetaryCellData
) =>
  convertRowData<CellData, OverridableMonetaryCellData, Omit<TRowData, 'weighted'>>(
    {
      ...rowData,
      ...(weight ? weighted?.[weight] : {}),
    },
    convert
  )

export const monetaryFromRecordRowData = <
  Key extends string,
  TCell extends CellData,
  TRowData extends BaseRowData<Record<Key, TCell>>,
>(
  rowData: TRowData,
  key: Key,
  convert = overridableMonetaryCellData
) => convertRowData<Record<Key, TCell>, OverridableMonetaryCellData, TRowData>(rowData, (value) => convert(value[key]))
