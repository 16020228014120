import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint.ts'
import { dataLine } from '../utils.ts'

export const milkAnimalsAveragesSheet2024Blueprint = {
  sections: [
    {
      id: 'milk-animals.averages',
      noHeader: true,
      children: [
        dataLine('milk.animals.cows.average-head-count', {
          variant: 'calculateTotal',
          unit: 'head-count',
        }),
      ],
    },
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true, withDividerRight: true, className: 'w-84' },
    {
      id: 'selected-reference',
      type: 'reference',
      sticky: true,
      className: 'w-[7.5rem]',
      withDividerRight: true,
      showUnitInCell: true,
    },
    { id: 'divider', type: 'divider', className: 'w-1' },
    {
      id: 'projections',
      type: 'projections',
      withDividerRight: true,
      className: 'w-[7.5rem]',
      showUnitInCell: true,
    },
  ],
} as const satisfies Except<BlueprintTable, 'version'>
