import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react'

import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const labelVariants = cva('select-none tracking-[-0.28px] text-gray-700', {
  variants: {
    dimension: {
      xs: 'leading-[0.9] text-xxs',
      sm: 'leading-[0.5] text-xs',
    },
  },
  defaultVariants: {
    dimension: 'sm',
  },
})

export interface LabelProps
  extends ComponentPropsWithoutRef<typeof LabelPrimitive.Root>,
    VariantProps<typeof labelVariants> {}

export const FormLabel = forwardRef<ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, dimension, ...props }, ref) => (
    <LabelPrimitive.Root ref={ref} className={cn(labelVariants({ dimension }), className)} {...props} />
  )
)
