import dayjs from 'dayjs'
import { min } from 'lodash-es'

import { type ScenarioState } from '../types'

export const startOfProjectionsDate = (
  state: ScenarioState | undefined,
  year = min(state?.period?.projectionYears)
): Date | null => {
  if (!year) {
    return null
  }
  return dayjs(year, 'YYYY')
    .startOf('year')
    .add(state?.startOfYearOffset ?? 0, 'months')
    .toDate()
}
