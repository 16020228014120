import { z } from 'zod'

export const UserZod = z.object({
  viagritel: z.object({
    instance: z.string().optional().default('default'),
    username: z.string(),
    id: z.number(),
  }),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string().optional().default(''),
  title: z.string().optional().default(''),
  isActive: z.boolean().optional().default(true),
  overrides: z.object({}).partial().optional(),
  canCreateCompleteScenario: z.boolean().optional().default(false),
  sysAdmin: z.boolean().optional().default(false),

  signaturePath: z.string().optional(),
  oaqNumber: z.string().optional().default(''),
})

export const UserConfidentialInformationZod = z.object({
  passwordHash: z.string(),
  passwordOverride: z.string().optional(),
})

export type User = z.infer<typeof UserZod>
export type UserConfidentialInformation = z.infer<typeof UserConfidentialInformationZod>
