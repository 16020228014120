import { type FC } from 'react'

import { ProfileView } from '@via/components'

import { useProfilePage } from './useProfilePage'

export const ProfilePage: FC = () => {
  const props = useProfilePage()

  return <ProfileView {...props} />
}
