import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { Icons, useToast } from '@via/components'

import { useOfflineState } from './useOfflineState.ts'

export const OfflineToastProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const intl = useIntl()
  const { offline } = useOfflineState()
  const [offlineToastShown, setOfflineToastShown] = useState(false)
  const { showToast } = useToast()

  React.useEffect(() => {
    if (offline && !offlineToastShown) {
      setOfflineToastShown(true)
      setTimeout(() => {
        showToast(
          <div className="flex h-8 items-center gap-2">
            <Icons.Offline className="text-fuschia-800" />
            {intl.formatMessage({ id: 'general.toast.offline.title' })}
          </div>,
          intl.formatMessage({ id: 'general.toast.offline.description' })
        )
      })
    }

    if (!offline) {
      setOfflineToastShown(false)
    }
  }, [intl, offline, offlineToastShown, showToast])

  return children
}
