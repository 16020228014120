import { type OverridableReferenceDataKey } from '@via/schema'
import { type Except } from 'type-fest'

import { cn } from '../../lib/utils'
import { InlineEditingCellForm } from '../../molecules/InlineEditingCell/InlineEditingCellForm.tsx'
import { MonetaryOverrideWidget } from '../../monetary/MonetaryOverrideWidget/MonetaryOverrideWidget.tsx'

import { PopoverEditableValueCell, type PopoverEditableValueCellProps } from './PopoverEditableValueCell'
import { type UpdateCellValue } from './UpdateCellValue.ts'

interface EditableOverridableReferenceValueCellProps extends Except<PopoverEditableValueCellProps, 'children'> {
  readonly onUpdateCell: UpdateCellValue<'overridable-reference'>
  readonly dataKey: OverridableReferenceDataKey
}

export const EditableOverridableReferenceValueCell = ({
  cellData,
  onUpdateCell,
  className,
  dataKey,
  ...props
}: EditableOverridableReferenceValueCellProps) => (
  <PopoverEditableValueCell cellData={cellData} className={className} {...props}>
    {({ type, onClose }) =>
      type === 'inline' ? (
        <InlineEditingCellForm
          value={cellData.value}
          onConfirm={async (value) => {
            await onUpdateCell({ type: 'overridable-reference', dataKey, override: { value } })
            onClose()
          }}
          className={cn('-mt-6', className)}
        />
      ) : (
        <MonetaryOverrideWidget
          operationDisabled
          value={cellData}
          onConfirm={async (value) => {
            await onUpdateCell({ type: 'overridable-reference', dataKey, ...value })
            onClose()
          }}
          onApply={async (value) => {
            await onUpdateCell({ type: 'overridable-reference', dataKey, ...value })
          }}
        />
      )
    }
  </PopoverEditableValueCell>
)
