import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { ActionDialog } from '../../molecules/ActionDialog/ActionDialog'

interface RequestSentDialogProps {
  readonly open: boolean
  readonly onCancel: () => void
}

export const RequestSentDialog: FC<RequestSentDialogProps> = ({ open, onCancel }) => {
  const intl = useIntl()

  return (
    <ActionDialog
      loading
      open={open}
      title={intl.formatMessage({ id: 'budget.requestOwnership.requestSentDialog.title' })}
      subTitle={intl.formatMessage({ id: 'budget.requestOwnership.requestSentDialog.subtitle' })}
      action={{
        label: intl.formatMessage({ id: 'budget.requestOwnership.requestSentDialog.close' }),
        onClick: onCancel,
      }}
    />
  )
}
