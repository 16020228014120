type NullSafeObjectIterator<TObject, TResult> = (
  value: NonNullable<TObject[keyof TObject]>,
  key: keyof TObject,
  collection: TObject
) => TResult

export const mapNonNullValues = <TObject extends object, TResult>(
  obj: TObject | null | undefined,
  callback: NullSafeObjectIterator<TObject, TResult>
): Record<keyof TObject, TResult> => {
  if (!obj) return {} as Record<keyof TObject, TResult>

  return Object.fromEntries<TResult>(
    Object.entries(obj).flatMap(([key, value]) =>
      !value ? [] : [[key, callback(value as NonNullable<TObject[keyof TObject]>, key as keyof TObject, obj)]]
    )
  ) as Record<keyof TObject, TResult>
}
