import { z } from 'zod'

import { YearKeyZod } from '../../monetary'

export const BudgetReportContentsZod = z.record(z.object({ order: z.number(), content: z.string() }))

export const BudgetReportSectionZod = z.object({
  enabled: z.boolean().optional().default(true),
})

export const WithDefaultBudgetReportSectionZod = BudgetReportSectionZod.optional().default({})

export const BudgetReportsZod = z.object({
  name: z.string(),
  defaultFileName: z.string(),
  preambles: BudgetReportContentsZod.optional().default({}),
  contents: BudgetReportContentsZod.optional().default({}),
  scenarios: z.array(z.string()),
  creationTimestamp: z.date(),
  years: YearKeyZod.array(),
  includesReference: z.boolean(),

  title: z.string().optional().default(''),
  displayDate: z.boolean().optional().default(false),
  date: z.string().optional().default(''),

  withCoverPage: z.boolean().optional().default(true),

  displayTechnician: z.boolean().optional().default(false),
  displayCollaborator: z.boolean().optional().default(false),

  coverImagePath: z.string().optional().default(''),
  logoImagePath: z.string().optional().default(''),

  sections: z
    .object({
      results: WithDefaultBudgetReportSectionZod,
      repayment: WithDefaultBudgetReportSectionZod,
      balance: WithDefaultBudgetReportSectionZod,
      finance: WithDefaultBudgetReportSectionZod.and(
        z.object({
          investments: WithDefaultBudgetReportSectionZod,
          shortTerms: WithDefaultBudgetReportSectionZod.and(
            z
              .object({
                movement: WithDefaultBudgetReportSectionZod,
                balance: WithDefaultBudgetReportSectionZod,
              })
              .optional()
              .default({})
          ),
          loans: WithDefaultBudgetReportSectionZod.and(
            z
              .object({
                annuity: WithDefaultBudgetReportSectionZod,
                interest: WithDefaultBudgetReportSectionZod,
                capital: WithDefaultBudgetReportSectionZod,
                projectedCapital: WithDefaultBudgetReportSectionZod,
                endingBalance: WithDefaultBudgetReportSectionZod,
                disbursements: WithDefaultBudgetReportSectionZod,
                repayment: WithDefaultBudgetReportSectionZod,
                exigible: WithDefaultBudgetReportSectionZod,
              })
              .optional()
              .default({})
          ),
        })
      )
        .optional()
        .default({}),
    })
    .optional()
    .default({}),
})
