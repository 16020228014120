import { type FC } from 'react'

import { Button } from '../../atoms/Button/Button'
import { Label } from '../../atoms/Label/Label'
import { LoadingBar } from '../../atoms/LoadingBar/LoadingBar'
import { Dialog, DialogContent, DialogDescription, DialogOverlay, DialogPortal, DialogTitle } from '../Dialog/Dialog'

interface ActionDialogProps {
  readonly title: string
  readonly subTitle?: string
  readonly open: boolean
  readonly loading?: boolean
  readonly action?: { label: string; onClick: () => void | Promise<void> }
}

export const ActionDialog: FC<ActionDialogProps> = ({ title, subTitle, open, action, loading = false }) => (
  <Dialog open={open}>
    <DialogPortal>
      <DialogOverlay />
      <DialogContent>
        {loading && (
          <div className="absolute top-0 w-full">
            <LoadingBar />
          </div>
        )}
        <div className="flex flex-col items-center gap-6 px-12 py-16">
          <div className="flex flex-col items-center justify-center gap-2">
            <DialogTitle asChild>
              <Label className="text-2xl font-bold">{title}</Label>
            </DialogTitle>
            {subTitle && (
              <DialogDescription asChild>
                <Label className="text-base font-light">{subTitle}</Label>
              </DialogDescription>
            )}
          </div>
          {!!action && (
            <div>
              <Button variant="outline" className="focus:outline-none" onClick={action.onClick}>
                {action.label}
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </DialogPortal>
  </Dialog>
)
