import { type FC, type PropsWithChildren, useMemo } from 'react'

import { Navigate } from '@tanstack/react-router'
import { ScenarioContext, type ScenarioData, ScenarioRowsContext } from '@via/components'
import { type RowData } from '@via/compute'
import { useObservable, useObservableState } from 'observable-hooks'
import { map, switchMap } from 'rxjs'

import { CurrentBudgetScenarioDatabaseContext } from '../../rxdb/contexts/CurrentBudgetScenarioDatabaseContext.ts'
import { rxdbScenarioDataWithRows$ } from '../../rxdb/scenarios/rxdbScenarioDataWithRows.ts'
import { type ScenarioRxDatabase } from '../../rxdb/types.ts'

export interface RxdbScenarioProviderProps {
  readonly scenarioDatabase: ScenarioRxDatabase
  readonly scenario: ScenarioData
}

export interface RxdbCurrentScenarioObservableState {
  readonly scenario: ScenarioData
  readonly rows: ReadonlyArray<RowData>
  readonly isLoading: boolean
}

const state$ = (scenarioDatabase: ScenarioRxDatabase) =>
  rxdbScenarioDataWithRows$(scenarioDatabase).pipe(
    map((data) => ({
      ...data,
      isLoading: false,
    }))
  )

export const RxdbCurrentScenarioProvider: FC<PropsWithChildren<RxdbScenarioProviderProps>> = ({
  scenarioDatabase,
  scenario: initialScenarioData,
  children,
}) => {
  const scenarioObservableState$ = useObservable(
    (inputs$) => inputs$.pipe(switchMap(([db]) => state$(db))),
    [scenarioDatabase]
  )

  const { scenario, rows, isLoading } = useObservableState<RxdbCurrentScenarioObservableState>(
    scenarioObservableState$,
    { scenario: initialScenarioData, rows: [], isLoading: false }
  )

  const rowsContextValue = useMemo(() => ({ rows, loading: isLoading }), [rows, isLoading])

  if (scenario?.archived) {
    return <Navigate to="/budgets/$budgetId" params={{ budgetId: initialScenarioData.budgetId }} />
  }

  return (
    <CurrentBudgetScenarioDatabaseContext.Provider value={scenarioDatabase}>
      <ScenarioContext.Provider value={scenario}>
        <ScenarioRowsContext.Provider value={rowsContextValue}>{children}</ScenarioRowsContext.Provider>
      </ScenarioContext.Provider>
    </CurrentBudgetScenarioDatabaseContext.Provider>
  )
}
