import { BigNumber } from 'bignumber.js'

import { type ViagritelRowAliment } from '../types'

export const foodExpenses = (lines: ViagritelRowAliment[]) =>
  lines.reduce((total, line) => {
    if (line.consommation_totale.isZero()) return total
    return total.plus(
      line.achat_dollars
        .plus(line.debut_kg.dividedBy(1000).times(line.debut_dollars_ton))
        .minus(line.fin_kg.dividedBy(1000).times(line.fin_dollars_ton))
        .times(line.quantite)
        .dividedBy(line.consommation_totale)
    )
  }, new BigNumber(0))
