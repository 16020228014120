import { z } from 'zod'

import {
  AnimalsSubTypes as AnimalsInvestmentSubTypes,
  AssetTypeZod,
  BaseAssetTypes,
  BuildingAssetSubTypes as BuildingInvestmentSubTypes,
  LandAssetSubTypes as LandInvestmentSubTypes,
  MachineryAssetSubTypes as MachineryInvestmentsSubTypes,
  OtherAssetSubTypes as OtherInvestmentSubTypes,
  QuotaAssetSubTypes as QuotaInvestmentSubTypes,
} from './asset-types-data-zod'

export {
  AnimalsSubTypes as AnimalsInvestmentSubTypes,
  BuildingAssetSubTypes as BuildingInvestmentSubTypes,
  LandAssetSubTypes as LandInvestmentSubTypes,
  MachineryAssetSubTypes as MachineryInvestmentsSubTypes,
  OtherAssetSubTypes as OtherInvestmentSubTypes,
  BaseAssetTypes as PurchaseSaleInvestmentsTypes,
  QuotaAssetSubTypes as QuotaInvestmentSubTypes,
} from './asset-types-data-zod'

export const LandInvestmentSubTypeZod = z.enum(LandInvestmentSubTypes)
export type LandInvestmentSubType = z.infer<typeof LandInvestmentSubTypeZod>

export const BuildingInvestmentSubTypeZod = z.enum(BuildingInvestmentSubTypes)
export type BuildingInvestmentSubType = z.infer<typeof BuildingInvestmentSubTypeZod>

export const AnimalsInvestmentSubTypeZod = z.enum(AnimalsInvestmentSubTypes)
export type AnimalsInvestmentSubType = z.infer<typeof AnimalsInvestmentSubTypeZod>

export const QuotaInvestmentSubTypeZod = z.enum(QuotaInvestmentSubTypes)
export type QuotaInvestmentSubType = z.infer<typeof QuotaInvestmentSubTypeZod>

export const MachineryInvestmentSubTypeZod = z.enum(MachineryInvestmentsSubTypes)
export type MachineryInvestmentSubType = z.infer<typeof MachineryInvestmentSubTypeZod>

export const OtherInvestmentSubTypeZod = z.enum(OtherInvestmentSubTypes)
export type OtherInvestmentSubType = z.infer<typeof OtherInvestmentSubTypeZod>

export const InvestmentTypeZod = z.enum(BaseAssetTypes)

export const InvestmentSubTypeZod = z.union([
  LandInvestmentSubTypeZod,
  BuildingInvestmentSubTypeZod,
  AnimalsInvestmentSubTypeZod,
  QuotaInvestmentSubTypeZod,
  MachineryInvestmentSubTypeZod,
  OtherInvestmentSubTypeZod,
])

export const MachineryInvestmentTypingZod = z.object({
  type: z.literal(AssetTypeZod.enum.machinery),
  subtype: MachineryInvestmentSubTypeZod,
})

export const BuildingInvestmentTypingZod = z.object({
  type: z.literal(AssetTypeZod.enum.building),
  subtype: BuildingInvestmentSubTypeZod,
})

export const LandInvestmentTypingZod = z.object({
  type: z.literal(AssetTypeZod.enum.land),
  subtype: LandInvestmentSubTypeZod,
})

export const AnimalsInvestmentTypingZod = z.object({
  type: z.literal(AssetTypeZod.enum.animals),
  subtype: AnimalsInvestmentSubTypeZod,
})

export const QuotaInvestmentTypingZod = z.object({
  type: z.literal(AssetTypeZod.enum.quota),
  subtype: QuotaInvestmentSubTypeZod,
})

export const OtherInvestmentTypingZod = z.object({
  type: z.enum([AssetTypeZod.enum.other]),
  subtype: OtherInvestmentSubTypeZod,
})

export const InvestmentTypingZod = z.discriminatedUnion('type', [
  MachineryInvestmentTypingZod,
  BuildingInvestmentTypingZod,
  LandInvestmentTypingZod,
  AnimalsInvestmentTypingZod,
  QuotaInvestmentTypingZod,
  OtherInvestmentTypingZod,
])

export type InvestmentTyping = z.infer<typeof InvestmentTypingZod>
