import { forwardRef, useId } from 'react'

import { FormLabel } from '../../atoms/FormLabel/FormLabel.tsx'
import { cn } from '../../lib/utils'
import { NumberWithUnitInput, type NumberWithUnitInputProps } from '../NumberWithUnitInput/NumberWithUnitInput.tsx'

export interface NumberWithUnitInputWithLabelProps extends NumberWithUnitInputProps {
  readonly label?: string
}

export const NumberWithUnitInputWithLabel = forwardRef<HTMLInputElement, NumberWithUnitInputWithLabelProps>(
  ({ label, id, value, className, ...props }, ref) => {
    const reactId = useId()
    if (!label) {
      return <NumberWithUnitInput id={id ?? reactId} value={value} ref={ref} {...props} />
    }

    return (
      <div className={cn('flex flex-col gap-1.5 pt-0.5', className)}>
        <FormLabel dimension={props.dimension} htmlFor={id ?? reactId}>
          {label}
        </FormLabel>
        <NumberWithUnitInput id={id ?? reactId} value={value} ref={ref} {...props} />
      </div>
    )
  }
)
