import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { ActionDialog } from '../../molecules/ActionDialog/ActionDialog'

interface RequestDeclinedDialogProps {
  readonly open: boolean
  readonly onClose: () => void
}

export const RequestDeclinedDialog: FC<RequestDeclinedDialogProps> = ({ open, onClose }) => {
  const intl = useIntl()

  return (
    <ActionDialog
      open={open}
      title={intl.formatMessage({ id: 'budget.requestOwnership.requestDeclinedDialog.title' })}
      subTitle={intl.formatMessage({ id: 'budget.requestOwnership.requestDeclinedDialog.subtitle' })}
      action={{
        label: intl.formatMessage({ id: 'budget.requestOwnership.requestDeclinedDialog.close' }),
        onClick: onClose,
      }}
    />
  )
}
