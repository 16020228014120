import type React from 'react'

import { createFileRoute, Navigate, notFound, Outlet, redirect } from '@tanstack/react-router'
import { ClientContext } from '@via/components'
import { z } from 'zod'

import { CurrentClientBudgetListProvider } from '../contexts/currentClientBudgetList/CurrentClientBudgetListProvider.tsx'
import { useOfflineState } from '../contexts/offlineState/useOfflineState.ts'
import { findClient } from '../rxdb/localSnapshot/findClient.ts'
import { useLocalClientSnapshotData } from '../rxdb/localSnapshot/useLocalClientSnapshotData.ts'
import { waitForSnapshot } from '../rxdb/localSnapshot/waitForSnapshot.ts'

export const CurrentClientComponent: React.FC = () => {
  const { offline } = useOfflineState()

  const { currentClient } = Route.useRouteContext()
  const client = useLocalClientSnapshotData(currentClient)

  if (offline || !client) {
    return <Navigate from="/clients/$clientId" to="/budgets" params={{}} />
  }

  return (
    <ClientContext.Provider value={client}>
      <CurrentClientBudgetListProvider clientId={currentClient._id}>
        <Outlet />
      </CurrentClientBudgetListProvider>
    </ClientContext.Provider>
  )
}

export const Route = createFileRoute('/clients/$clientId')({
  parseParams: (params) => ({
    clientId: z.string().parse(params.clientId),
  }),
  beforeLoad: async ({ context: { appDatabase }, params }) => {
    if (navigator.onLine) {
      const { clientId } = params
      const currentClient = await findClient(appDatabase, ({ code }) => code === clientId)

      if (!currentClient) {
        throw notFound()
      }

      await waitForSnapshot(appDatabase, 'budgets')
      return { currentClient }
    }
    throw redirect({ to: '/budgets' })
  },
  component: CurrentClientComponent,
})
