import type React from 'react'

import { ConfirmationDialogProvider } from './ConfirmationDialogProvider'
import { LoadingDialogProvider } from './LoadingDialogProvider'

export const DialogProvider: React.FC<React.PropsWithChildren> = ({ children }) => (
  <LoadingDialogProvider>
    <ConfirmationDialogProvider>{children}</ConfirmationDialogProvider>
  </LoadingDialogProvider>
)
