import { type JsonSchema, type RxJsonSchema } from 'rxdb'

import { type RxCollectionDocumentType, type ScenarioRxDatabase } from '../types.ts'

type ScenarioStateRxDocument = RxCollectionDocumentType<ScenarioRxDatabase['scenario-states']>

export const ScenarioStateRxSchema = {
  title: 'scenario state',
  description: 'scenario state schema',
  version: 0,
  type: 'object',
  primaryKey: '_id',
  required: ['_id'],
  properties: {
    _id: {
      type: 'string',
      maxLength: 100,
    },
    referenceId: {
      type: 'string',
    },
    blueprintVersion: {
      type: 'string',
    },
    referenceSyncedDate: {
      type: 'string',
      format: 'date-time',
    } satisfies JsonSchema<ScenarioStateRxDocument['referenceSyncedDate']>,
  },
} as const satisfies RxJsonSchema<
  Pick<ScenarioStateRxDocument, '_id' | 'referenceId' | 'blueprintVersion' | 'referenceSyncedDate'>
>
