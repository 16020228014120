import { type ScenarioState } from '@via/compute'
import { type ProductionType, SortedProductions } from '@via/schema'
import { type Except } from 'type-fest'

import { type BlueprintSection, type BlueprintTable } from '../blueprint'
import { dataLine, headerLine } from '../utils'

const incomeSection = (enabledProductions: ReadonlyArray<ProductionType>): BlueprintSection => {
  const enabledProductionsConfig = SortedProductions.filter(({ production }) => enabledProductions.includes(production))

  return {
    id: 'income.section',
    titleKey: 'table.income.section',
    children: [
      {
        ...headerLine('table.income.section'),
        children: [
          ...enabledProductionsConfig.flatMap(({ parent, production }) => {
            switch (parent) {
              case 'milk':
                return [
                  {
                    ...dataLine('result.milk.income.total'),
                    children: [
                      dataLine('result.milk.income.sales.products', { displayAsProjection: true }),
                      dataLine('result.milk.income.sales.animals', { displayAsProjection: true }),
                      dataLine('result.milk.income.inventory.breeders'),
                      dataLine('result.milk.income.miscellaneous'),
                    ],
                  },
                ]
              case 'crops':
                return [
                  {
                    ...dataLine('result.crops.income.total'),
                    children: [
                      dataLine('result.crops.income.sales.products'),
                      dataLine('result.crops.income.insurance.general'),
                      dataLine('result.crops.income.insurance.stabilization'),
                      dataLine('result.crops.income.inventory.crops'),
                      dataLine('result.crops.income.inventory.forage'),
                      dataLine('result.crops.income.miscellaneous'),
                    ],
                  },
                ]
              case 'calf':
                return [
                  {
                    ...dataLine('result.calf.income.total'),
                    children: [
                      dataLine('result.calf.income.sales.products'),
                      dataLine('result.calf.income.insurance.stabilization'),
                      dataLine('result.calf.income.inventory.breeders'),
                      dataLine('result.calf.income.inventory.market'),
                      dataLine('result.calf.income.miscellaneous'),
                    ],
                  },
                ]
              case 'maple':
                return [
                  {
                    ...dataLine('result.maple.income.total'),
                    children: [
                      dataLine('result.maple.income.sales.wholesale'),
                      dataLine('result.maple.income.sales.retail'),
                      dataLine('result.maple.income.inventory.products'),
                      dataLine('result.maple.income.miscellaneous'),
                    ],
                  },
                ]
              case 'animal':
                return [
                  {
                    ...dataLine(`result.animal.${production}.income.total`),
                    children: [
                      dataLine(`result.animal.${production}.income.sales.products`),
                      dataLine(`result.animal.${production}.income.sales.cull`),
                      dataLine(`result.animal.${production}.income.sales.breeders`),
                      dataLine(`result.animal.${production}.income.insurance.stabilization`),
                      dataLine(`result.animal.${production}.income.inventory.breeders`),
                      dataLine(`result.animal.${production}.income.inventory.slaughter`),
                      dataLine(`result.animal.${production}.income.miscellaneous`),
                    ],
                  },
                ]
              case 'vegetal':
                return [
                  {
                    ...dataLine(`result.vegetal.${production}.income.total`),
                    children: [
                      dataLine(`result.vegetal.${production}.income.sales.products`),
                      dataLine(`result.vegetal.${production}.income.insurance.general`),
                      dataLine(`result.vegetal.${production}.income.insurance.stabilization`),
                      dataLine(`result.vegetal.${production}.income.inventory.products`),
                      dataLine(`result.vegetal.${production}.income.miscellaneous`),
                    ],
                  },
                ]
              case 'commercial-group':
                return [
                  {
                    ...dataLine(`result.commercial.${production}.income.total`),
                    children: [
                      dataLine(`result.commercial.${production}.income.sales.products`),
                      dataLine(`result.commercial.${production}.income.inventory`),
                      dataLine(`result.commercial.${production}.income.miscellaneous`),
                    ],
                  },
                ]
              default:
                return []
            }
          }),
          dataLine('result.company.income.miscellaneous', { variant: 'group' }),
          dataLine('result.company.income.programs', { variant: 'group' }),
          dataLine('result.company.income.custom', { variant: 'group' }),
        ],
      },
      dataLine('result.income.total', { variant: 'total', closingSection: true }),
    ],
  }
}

const expensesSection = (enabledProductions: ReadonlyArray<ProductionType>): BlueprintSection => {
  const enabledProductionsConfig = SortedProductions.filter(({ production }) => enabledProductions.includes(production))

  return {
    id: 'expenses.section',
    titleKey: 'table.expenses.section',
    children: [
      {
        ...headerLine('table.result.company.expenses.variable.section'),
        children: [
          ...enabledProductionsConfig.flatMap(({ parent, production }) => {
            switch (parent) {
              case 'milk':
                return [
                  {
                    ...dataLine('result.milk.expenses.total'),
                    children: [
                      {
                        ...dataLine('result.milk.expenses.marketing.total'),
                        children: [
                          dataLine('result.milk.expenses.marketing.products'),
                          dataLine('result.milk.expenses.marketing.animals'),
                        ],
                      },
                      {
                        ...dataLine('result.milk.expenses.livestock.total'),
                        children: [
                          dataLine('result.milk.expenses.livestock.maintenance', { displayAsProjection: true }),
                          dataLine('result.milk.expenses.livestock.investment'),
                        ],
                      },
                      {
                        ...dataLine('result.milk.expenses.feed.total'),
                        children: [
                          dataLine('result.milk.expenses.feed.cow'),
                          dataLine('result.milk.expenses.feed.young'),
                          dataLine('result.milk.expenses.feed.miscellaneous'),
                        ],
                      },
                      {
                        ...dataLine('result.milk.expenses.fees.total'),
                        children: [
                          dataLine('result.milk.expenses.fees.veterinary'),
                          dataLine('result.milk.expenses.fees.reproduction', { displayAsProjection: true }),
                          dataLine('result.milk.expenses.fees.miscellaneous', { displayAsProjection: true }),
                        ],
                      },
                      {
                        ...dataLine('result.milk.expenses.maintenance.total'),
                        children: [
                          dataLine('result.milk.expenses.maintenance.milking'),
                          dataLine('result.milk.expenses.maintenance.equipment'),
                        ],
                      },
                    ],
                  },
                ]
              case 'crops':
                return [
                  {
                    ...dataLine('result.crops.expenses.total'),
                    children: [
                      {
                        ...dataLine('result.crops.expenses.purchases.total'),
                        children: [
                          dataLine('result.crops.expenses.purchases.straw'),
                          dataLine('result.crops.expenses.purchases.forage'),
                          dataLine('result.crops.expenses.purchases.crops'),
                        ],
                      },
                      {
                        ...dataLine('result.crops.expenses.supplies.total'),
                        children: [
                          dataLine('result.crops.expenses.supplies.seed'),
                          dataLine('result.crops.expenses.supplies.fertilizer'),
                          dataLine('result.crops.expenses.supplies.pesticides'),
                          dataLine('result.crops.expenses.supplies.lime'),
                          dataLine('result.crops.expenses.supplies.miscellaenous'),
                        ],
                      },
                      {
                        ...dataLine('result.crops.expenses.insurance.total'),
                        children: [
                          dataLine('result.crops.expenses.insurance.general'),
                          dataLine('result.crops.expenses.insurance.stabilization'),
                        ],
                      },
                    ],
                  },
                ]
              case 'calf':
                return [
                  {
                    ...dataLine('result.calf.expenses.total'),
                    children: [
                      dataLine('result.calf.expenses.marketing'),
                      {
                        ...dataLine('result.calf.expenses.livestock.total'),
                        children: [
                          dataLine('result.calf.expenses.livestock.maintenance'),
                          dataLine('result.calf.expenses.livestock.investment'),
                        ],
                      },
                      {
                        ...dataLine('result.calf.expenses.feed.total'),
                        children: [
                          dataLine('result.calf.expenses.feed.cow'),
                          dataLine('result.calf.expenses.feed.calf'),
                          dataLine('result.calf.expenses.feed.finished'),
                        ],
                      },
                      {
                        ...dataLine('result.calf.expenses.fees.total'),
                        children: [
                          dataLine('result.calf.expenses.fees.veterinary'),
                          dataLine('result.calf.expenses.fees.reproduction'),
                          dataLine('result.calf.expenses.fees.insurance'),
                          dataLine('result.calf.expenses.fees.miscellaneous'),
                        ],
                      },
                    ],
                  },
                ]
              case 'maple':
                return [
                  {
                    ...dataLine('result.maple.expenses.total'),
                    children: [
                      dataLine('result.maple.expenses.marketing.wholesale'),
                      {
                        ...dataLine('result.maple.expenses.supplies.total'),
                        children: [
                          dataLine('result.maple.expenses.supplies.direct'),
                          dataLine('result.maple.expenses.supplies.resale'),
                          dataLine('result.maple.expenses.supplies.retail'),
                        ],
                      },
                      {
                        ...dataLine('result.maple.expenses.maintenance.total'),
                        children: [
                          dataLine('result.maple.expenses.maintenance.harvest'),
                          dataLine('result.maple.expenses.maintenance.processing'),
                          dataLine('result.maple.expenses.maintenance.installations'),
                        ],
                      },
                      {
                        ...dataLine('result.maple.expenses.insurance.total'),
                        children: [dataLine('result.maple.expenses.insurance.general')],
                      },
                      {
                        ...dataLine('result.maple.expenses.miscellaneous.total'),
                        children: [
                          dataLine('result.maple.expenses.miscellaneous.lease'),
                          dataLine('result.maple.expenses.miscellaneous.boiling'),
                          dataLine('result.maple.expenses.miscellaneous.transport'),
                          dataLine('result.maple.expenses.miscellaneous.other'),
                        ],
                      },
                    ],
                  },
                ]
              case 'animal':
                return [
                  {
                    ...dataLine(`result.animal.${production}.expenses.total`),
                    children: [
                      dataLine(`result.animal.${production}.expenses.marketing`),
                      {
                        ...dataLine(`result.animal.${production}.expenses.livestock.total`),
                        children: [
                          dataLine(`result.animal.${production}.expenses.livestock.maintenance`),
                          dataLine(`result.animal.${production}.expenses.livestock.investment`),
                        ],
                      },
                      {
                        ...dataLine(`result.animal.${production}.expenses.feed.total`),
                        children: [
                          dataLine(`result.animal.${production}.expenses.feed.adults`),
                          dataLine(`result.animal.${production}.expenses.feed.young`),
                          dataLine(`result.animal.${production}.expenses.feed.miscellaneous`),
                        ],
                      },
                      {
                        ...dataLine(`result.animal.${production}.expenses.fees.total`),
                        children: [
                          dataLine(`result.animal.${production}.expenses.fees.veterinary`),
                          dataLine(`result.animal.${production}.expenses.fees.reproduction`),
                          dataLine(`result.animal.${production}.expenses.fees.insurance`),
                          dataLine(`result.animal.${production}.expenses.fees.miscellaneous`),
                        ],
                      },
                    ],
                  },
                ]
              case 'vegetal':
                return [
                  {
                    ...dataLine(`result.vegetal.${production}.expenses.total`),
                    children: [
                      dataLine(`result.vegetal.${production}.expenses.marketing`),
                      {
                        ...dataLine(`result.vegetal.${production}.expenses.supplies.total`),
                        children: [
                          dataLine(`result.vegetal.${production}.expenses.supplies.resale`),
                          dataLine(`result.vegetal.${production}.expenses.supplies.seed`),
                          dataLine(`result.vegetal.${production}.expenses.supplies.fertilizer`),
                          dataLine(`result.vegetal.${production}.expenses.supplies.pesticides`),
                          dataLine(`result.vegetal.${production}.expenses.supplies.miscellaneous`),
                        ],
                      },
                      {
                        ...dataLine(`result.vegetal.${production}.expenses.insurance.total`),
                        children: [
                          dataLine(`result.vegetal.${production}.expenses.insurance.harvest`),
                          dataLine(`result.vegetal.${production}.expenses.insurance.stabilization`),
                        ],
                      },
                      dataLine(`result.vegetal.${production}.expenses.miscellaneous`),
                    ],
                  },
                ]
              case 'commercial-group':
                return [
                  {
                    ...dataLine(`result.commercial.${production}.expenses.total`),
                    children: [
                      dataLine(`result.commercial.${production}.expenses.marketing`),
                      {
                        ...dataLine(`result.commercial.${production}.expenses.supplies.total`),
                        children: [
                          dataLine(`result.commercial.${production}.expenses.supplies.direct`),
                          dataLine(`result.commercial.${production}.expenses.supplies.resale`),
                        ],
                      },
                      dataLine(`result.commercial.${production}.expenses.miscellaneous`),
                    ],
                  },
                ]
              default:
                return []
            }
          }),
          {
            ...dataLine('result.company.expenses.machinery.total', { variant: 'subSection' }),
            children: [
              dataLine('result.company.expenses.machinery.tractors'),
              dataLine('result.company.expenses.machinery.maintenance'),
              dataLine('result.company.expenses.machinery.trucks'),
              dataLine('result.company.expenses.machinery.thresher'),
              dataLine('result.company.expenses.machinery.dryer'),
              dataLine('result.company.expenses.machinery.miscellaneous'),
            ],
          },
          {
            ...dataLine('result.company.expenses.fuel.total', { variant: 'subSection' }),
            children: [
              dataLine('result.company.expenses.fuel.tractors'),
              dataLine('result.company.expenses.fuel.trucks'),
              dataLine('result.company.expenses.fuel.thresher'),
              dataLine('result.company.expenses.fuel.miscellaneous'),
            ],
          },
          {
            ...dataLine('result.company.expenses.custom.total', { variant: 'subSection' }),
            children: [
              dataLine('result.company.expenses.custom.threshing'),
              dataLine('result.company.expenses.custom.drying'),
              dataLine('result.company.expenses.custom.fertilization'),
              dataLine('result.company.expenses.custom.miscellaneous'),
            ],
          },
          dataLine('result.company.expenses.finance.short.interests'),
        ],
      },
      dataLine('result.expenses.variable.total', { variant: 'total', closingSection: true }),
      {
        ...headerLine('table.result.company.expenses.fixed.section', { symbol: 'add' }),
        children: [
          {
            ...dataLine('result.company.expenses.fixed.land.total', { variant: 'subSection' }),
            children: [
              dataLine('result.company.expenses.fixed.land.mechanical'),
              dataLine('result.company.expenses.fixed.land.maintenance'),
              dataLine('result.company.expenses.fixed.land.lease'),
              dataLine('result.company.expenses.fixed.land.miscellaneous'),
            ],
          },
          {
            ...dataLine('result.company.expenses.fixed.buildings.total', { variant: 'subSection' }),
            children: [
              dataLine('result.company.expenses.fixed.buildings.maintenance'),
              dataLine('result.company.expenses.fixed.buildings.lease'),
              dataLine('result.company.expenses.fixed.buildings.miscellaneous'),
            ],
          },
          {
            ...dataLine('result.company.expenses.fixed.salaries.total', { variant: 'subSection' }),
            children: [
              dataLine('result.company.expenses.fixed.salaries.permanent'),
              dataLine('result.company.expenses.fixed.salaries.seasonal'),
              dataLine('result.company.expenses.fixed.salaries.grants', { negated: true }),
            ],
          },
          {
            ...dataLine('result.company.expenses.fixed.general.total', { variant: 'subSection' }),
            children: [
              dataLine('result.company.expenses.fixed.general.power'),
              dataLine('result.company.expenses.fixed.general.communications'),
              dataLine('result.company.expenses.fixed.general.taxes'),
              dataLine('result.company.expenses.fixed.general.insurance'),
              dataLine('result.company.expenses.fixed.general.administration'),
              dataLine('result.company.expenses.fixed.general.professionals'),
              dataLine('result.company.expenses.fixed.general.miscellaneous'),
            ],
          },
        ],
      },
      dataLine('result.expenses.fixed.total', { variant: 'total', closingSection: true }),
      {
        ...headerLine('table.result.company.expenses.finance.section', { symbol: 'add' }),
        children: [
          dataLine('result.company.expenses.finance.interests.due', { variant: 'group' }),
          dataLine('result.company.expenses.finance.interests.grants', { variant: 'group', negated: true }),
          dataLine('result.company.expenses.finance.amortization', { variant: 'group' }),
        ],
      },
      dataLine('result.expenses.finance.total', { variant: 'total', closingSection: true }),
      dataLine('result.expenses.total', { variant: 'finalTotal', symbol: 'total' }),
    ],
  }
}

const EnabledComplete2024Productions = new Set(['milk', 'crops'])

export const scenariosResults2024Blueprint = ({ enabledProductions = [], blueprintVersion }: ScenarioState) =>
  ({
    sections: [
      incomeSection(
        enabledProductions.filter(
          (prod) => blueprintVersion !== 'complete-2024' || EnabledComplete2024Productions.has(prod)
        )
      ),
      expensesSection(
        enabledProductions.filter(
          (prod) => blueprintVersion !== 'complete-2024' || EnabledComplete2024Productions.has(prod)
        )
      ),
    ],
    summary: [
      dataLine('result.income.total', { variant: 'finalSubTotal' }),
      dataLine('result.expenses.total', {
        variant: 'finalSubTotal',
        symbol: 'minus',
      }),
      dataLine('result.profit', { variant: 'finalTotal', symbol: 'total' }),
    ],
    callouts: {
      select: 'financialResults.selectReferenceYear',
    },
    columns: [
      { id: 'title', type: 'title', sticky: true, className: 'w-84' },
      {
        id: 'reference',
        type: 'reference',
        sticky: true,
        className: 'w-24',
        topNavClassName: blueprintVersion === 'simple-2024' ? 'w-[6.0625rem]' : 'w-[6rem]',
        withDividerRight: true,
        displayOnTableState: ['selected-reference', 'print-with-reference'],
      },
      {
        id: 'references',
        type: 'all-references',
        sticky: true,
        className: 'w-24',
        withDividerRight: blueprintVersion !== 'simple-2024',
        displayOnTableState: ['all-references'],
      },
      {
        id: 'references',
        type: 'all-references',
        className: 'w-24',
        withDividerRight: true,
        displayOnTableState: ['select'],
      },
      {
        id: 'growth',
        type: 'growth',
        titleKey: 'monetaryTable.growth',
        topHeaderDimension: 'xxs',
        sticky: true,
        withDividerRight: true,
        className: 'w-16',
        numberDisplayType: 'percent',
        displayOnTableState: ['selected-reference', 'all-references', 'print-with-reference'],
        enabled: blueprintVersion === 'simple-2024',
      },
      {
        id: 'divider',
        type: 'divider',
        className: 'w-1',
        displayOnTableState: ['selected-reference', 'all-references', 'print-with-reference'],
      },
      {
        id: 'projections',
        type: 'result-projections',
        withDividerRight: true,
        className: 'w-24',
        topHeaderVariant: 'head',
        displayOnTableState: [
          'selected-reference',
          'all-references',
          'print-with-reference',
          'print-without-reference',
        ],
      },
    ],
    defaultState: 'selected-reference',
    expendedState: 'all-references',
  }) as const as Except<
    BlueprintTable<
      'selected-reference' | 'all-references' | 'select' | 'print-with-reference' | 'print-without-reference'
    >,
    'version'
  >
