import { type FC, type PropsWithChildren, useContext, useEffect, useMemo } from 'react'

import { collections } from '@via/frontend-schema'
import { handleNotAwaitedPromise } from '@via/frontend-sentry'
import { omit } from 'lodash-es'
import { type DistributedOmit } from 'type-fest'

import { useAuthenticatedFirebaseAuth } from '../../auth/useFirebaseAuth.ts'
import { AppRxDatabaseContext } from '../../rxdb/contexts/AppRxDatabaseContext.ts'
import { deepDateToString } from '../../rxdb/utils/deepDateToString.ts'
import { useCollection, type UseCollectionResult } from '../hooks/useCollection.ts'

import {
  type FirestoreBudgetDocument,
  FirestoreBudgetListSnapshotContext,
} from './FirestoreBudgetListSnapshotContext.ts'

export const FirestoreBudgetListSnapshotProvider: FC<PropsWithChildren> = ({ children }) => {
  const appDatabase = useContext(AppRxDatabaseContext)
  const { user } = useAuthenticatedFirebaseAuth()
  const queryState = useCollection({
    query: collections.budgets.prepare({
      name: 'current user budget list',
      where: [
        ['members', 'array-contains', user.uid],
        ['archived', '==', false],
      ],
      orderBy: [['client.code', 'asc']],
    }),
  })

  const state = useMemo(
    () =>
      ({
        ...omit(queryState, 'snapshot'),
        data: queryState.data.map((budget) => ({
          ...budget,
          ...(budget.owner ? { readonly: user.uid !== budget.owner?.userId } : {}),
          currentUserIsOwner: user.uid === budget.owner?.userId,
        })),
      }) as DistributedOmit<UseCollectionResult<FirestoreBudgetDocument>, 'snapshot'>,
    [user.uid, queryState]
  )

  useEffect(() => {
    if (!state.hasError && !state.isLoading) {
      handleNotAwaitedPromise(
        appDatabase.snapshots.upsertLocal('budgets', {
          ...deepDateToString(state),
          epochTimestamp: Date.now(),
        })
      )
    }
  }, [state, appDatabase])

  return (
    <FirestoreBudgetListSnapshotContext.Provider value={state}>{children}</FirestoreBudgetListSnapshotContext.Provider>
  )
}
