import { useObservableState } from 'observable-hooks'
import { map } from 'rxjs'

import { CollectionReducerStateInitial } from '../../firestore/hooks/collectionStateReducer.ts'
import { type FirestoreBudgetDocument } from '../../firestore/snapshot/FirestoreBudgetListSnapshotContext.ts'
import { appRxDatabase } from '../app/appRxDatabase.ts'
import { type SnapshotRxDocument } from '../types.ts'
import { rxLocalDocumentData } from '../utils/rxLocalDocumentData.ts'

const budgets$ = appRxDatabase.snapshots
  .getLocal$<SnapshotRxDocument<FirestoreBudgetDocument>>('budgets')
  .pipe(map((a) => (a ? rxLocalDocumentData(a) : CollectionReducerStateInitial)))

export const useLocalBudgetListSnapshotState = () => useObservableState(budgets$, CollectionReducerStateInitial)
