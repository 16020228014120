import type React from 'react'

import { BalanceSheetView } from '@via/components'

import { useScenarioEditableBalancePage } from './useScenarioEditableBalancePage.ts'
import { useScenarioReadonlyBalancePage } from './useScenarioReadonlyBalancePage.ts'

const ScenarioReadonlyBalancePage = () => {
  const props = useScenarioReadonlyBalancePage()

  return <BalanceSheetView key={props.scenario._id} snapshot {...props} />
}

const ScenarioEditableBalancePage = () => {
  const props = useScenarioEditableBalancePage()

  return <BalanceSheetView key={props.scenario._id} {...props} />
}

export const ScenarioBalancePage: React.FC<{ readonly readonly?: boolean }> = ({ readonly }) =>
  readonly ? <ScenarioReadonlyBalancePage /> : <ScenarioEditableBalancePage />
