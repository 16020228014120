import { useCallback } from 'react'

import { Icons } from '../../atoms/Icons/Icons'
import { type UserAvatarVariant } from '../../molecules/UserAvatar/UserAvatar'
import { UserAvatarWithTooltip } from '../../molecules/UserAvatarWithTooltip/UserAvatarWithTooltip'
import { type NavigationHandler } from '../../navigation/types'
import { type BudgetConfigurationData } from '../../organisms/BudgetConfigurationForm/BudgetConfigurationData'
import { BudgetCreateDialogProvider } from '../../organisms/BudgetCreationDialog/BudgetCreateDialogProvider'
import {
  ClientBudgetListDataTable,
  type ClientBudgetListDataTableProps,
} from '../../organisms/ClientBudgetListDataTable/ClientBudgetListDataTable'
import {
  ClientBudgetListLayout,
  type ClientBudgetListLayoutProps,
} from '../../organisms/ClientBudgetListLayout/ClientBudgetListLayout'
import { type BudgetData, type ClientData, type UserData } from '../../types'

export interface ContactInfo {
  id: string
  name: string
  role: UserAvatarVariant
  email?: string
  phone?: string
  cell?: string
}

export interface ProductionInfo {
  name: string
  stat?: string
}

export interface ClientBudgetListViewProps
  extends Pick<ClientBudgetListLayoutProps, 'onCloseClient' | 'avatar'>,
    Pick<ClientBudgetListDataTableProps, 'onActionSelect'> {
  readonly client: ClientData
  readonly contacts: ContactInfo[]
  readonly productions: ProductionInfo[]
  readonly budgets: BudgetData[]
  readonly offline: boolean
  readonly onNavigationChange: NavigationHandler
  createBudget(form: BudgetConfigurationData): Promise<void>
  readonly user: UserData | null
  readonly userId: string
}

export const ClientBudgetListView = ({
  avatar,
  client,
  contacts,
  productions,
  budgets,
  offline,
  onNavigationChange,
  onCloseClient,
  onActionSelect,
  user,
  userId,
  createBudget,
}: ClientBudgetListViewProps) => {
  const onNavigateToBudget = useCallback(
    (budgetId: string) => onNavigationChange({ to: '/budgets/$budgetId', params: { budgetId } }),
    [onNavigationChange]
  )

  return (
    <BudgetCreateDialogProvider client={client} userId={userId} onConfirm={createBudget}>
      <ClientBudgetListLayout client={client} avatar={avatar} onCloseClient={onCloseClient}>
        <div className="flex w-full flex-col items-start gap-4 px-10 py-4">
          <div className="flex w-full items-start gap-4">
            <div className="flex w-full min-w-[900px] flex-col items-start gap-3 rounded-lg bg-white p-4">
              <div className="flex w-full items-start justify-between">
                <div className="flex flex-col items-start justify-center gap-1">
                  <p className="text-xl font-bold leading-tight tracking-[-0.4px] text-gray-800">{client.name}</p>
                  <div className="flex items-center gap-2">
                    <p className="text-right text-sm font-normal leading-none tracking-[-0.28px] text-black">
                      {client.address.streetAddress}
                    </p>
                    <p className="text-right text-sm font-normal leading-none tracking-[-0.28px] text-black">
                      {client.address.city}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-start justify-center gap-3">
                  <p className="text-right text-base font-light leading-none tracking-[-0.32px] text-black">
                    {client.contactInformation.name}
                  </p>
                  <div className="flex items-center gap-6 text-black">
                    {client.contactInformation.email && (
                      <a href={`mailto:${client.contactInformation.email}`}>
                        <Icons.Mail />
                      </a>
                    )}
                    {client.contactInformation.phone && (
                      <a href={`tel:${client.contactInformation.phone}`}>
                        <Icons.Phone />
                      </a>
                    )}
                  </div>
                </div>

                <div className="flex flex-col items-start gap-3">
                  <p className="text-base font-light leading-none tracking-[-0.32px] text-black">
                    <span className="select-none">NIM&nbsp;-&nbsp;</span>
                    {client.nim}
                  </p>
                  <p className="text-base font-light leading-none tracking-[-0.32px] text-black">
                    <span className="select-none">Dossier&nbsp;-&nbsp;</span>
                    {client.code}
                  </p>
                </div>
              </div>

              <div className="flex w-full items-center gap-2 border-t border-gray-300 pt-4">
                {contacts.map((contact) => (
                  <UserAvatarWithTooltip key={contact.id} userName={contact.name} userRole={contact.role} />
                ))}
              </div>
            </div>

            <div className="flex w-[340px] min-w-[340px] flex-col items-start justify-center gap-1 rounded-lg bg-white px-2.5 py-2">
              {productions.map((production) => (
                <div
                  key={production.name}
                  className="flex w-full flex-col items-start justify-center border-b border-gray-300 last:border-b-0">
                  <div className="flex w-full items-center justify-between px-2.5 py-1">
                    <p className="text-xs font-bold leading-none text-black">{production.name}</p>
                    <p className="text-right text-xs font-medium leading-none tracking-[-0.36px] text-black">
                      {production.stat ?? ''}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <ClientBudgetListDataTable
            offline={offline}
            clientCode={client.code}
            data={budgets}
            user={user}
            onActionSelect={onActionSelect}
            onNavigateToBudget={onNavigateToBudget}
          />
        </div>
      </ClientBudgetListLayout>
    </BudgetCreateDialogProvider>
  )
}
