import { forwardRef, type HtmlHTMLAttributes, useState } from 'react'

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

import { Button } from '../../atoms/Button/Button'
import { DropdownMenuContent, type DropdownMenuOption } from '../../atoms/DropdownMenuContent/DropdownMenuContent'
import { Icons } from '../../atoms/Icons/Icons'
import { Search } from '../../atoms/Search/Search'
import { Select, type SelectOption } from '../../atoms/Select/Select'
import { cn } from '../../lib/utils'

interface FilterOption {
  readonly options: SelectOption[]
  readonly initialValue?: string
  readonly placeholder: string
  readonly onChange?: (value: string) => void
}

export interface ListHeaderData {
  readonly titleLabel: string
  readonly filterOptions?: Record<string, FilterOption>
  readonly searchPlaceholderLabel?: string
  readonly newButtonLabel?: string
  readonly newOptions?: DropdownMenuOption[]
  readonly initialSearchValue?: string
}

export interface ListHeaderActions {
  readonly onSearchChange?: (searchTerm: string) => void
  readonly onNewClick?: (value?: string) => void
}

export interface ListHeaderProps extends ListHeaderData, ListHeaderActions, HtmlHTMLAttributes<HTMLDivElement> {}

export const ListHeader = forwardRef<HTMLDivElement, ListHeaderProps>(
  (
    {
      className,
      titleLabel,
      filterOptions,
      searchPlaceholderLabel,
      newButtonLabel,
      initialSearchValue,
      newOptions,
      onSearchChange,
      onNewClick,
      ...props
    },
    ref
  ) => {
    const [optionsShown, setOptionsShown] = useState(false)

    return (
      <div
        className={cn('flex h-16 w-full items-center justify-between bg-white px-6 py-5', className)}
        ref={ref}
        {...props}>
        <div className="flex items-center gap-4">
          <p className="select-none text-nowrap text-xl font-bold leading-tight tracking-[-0.4px] text-gray-800">
            {titleLabel}
          </p>
          {filterOptions &&
            Object.entries(filterOptions).map(([id, { options, initialValue, placeholder, onChange }]) => (
              <Select
                key={id}
                options={options}
                initialValue={initialValue}
                placeholder={placeholder}
                onValueChange={onChange}
              />
            ))}
        </div>

        <div className="flex items-center gap-4">
          {onSearchChange && (
            <Search
              placeholder={searchPlaceholderLabel ?? ''}
              onChange={onSearchChange}
              initialValue={initialSearchValue}
            />
          )}
          {onNewClick && (
            <div className="relative">
              <DropdownMenuPrimitive.Root open={optionsShown} onOpenChange={setOptionsShown}>
                {newOptions && <DropdownMenuPrimitive.Trigger className="absolute inset-0" />}
                <Button
                  shape="withIcon"
                  onClick={
                    newOptions
                      ? () => {
                          setOptionsShown(!optionsShown)
                        }
                      : () => {
                          onNewClick()
                        }
                  }>
                  <Icons.PlusLarge />
                  {newButtonLabel}
                  {newOptions && (
                    <DropdownMenuContent
                      onItemSelect={(selected) => {
                        onNewClick(selected)
                      }}
                      options={newOptions}
                    />
                  )}
                </Button>
              </DropdownMenuPrimitive.Root>
            </div>
          )}
        </div>
      </div>
    )
  }
)
