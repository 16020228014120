import { type ScenarioState } from '@via/compute'

import { milkAnimalsAveragesBlueprint } from '../../blueprints/milkAnimalsAveragesBlueprint.ts'
import { milkAnimalsBlueprint } from '../../blueprints/milkAnimalsBlueprint.ts'
import { milkAnimalsInventorySummaryBlueprint } from '../../blueprints/milkAnimalsInventorySummaryBlueprint.ts'
import { milkAnimalsMovementsBlueprint } from '../../blueprints/milkAnimalsMovementsBlueprint.ts'
import { useMonetaryTableBlueprint, useScenarioStateMemo } from '../../hooks'

export const useMilkAnimalsBlueprint = (state: ScenarioState) => {
  const animalsBlueprint = useScenarioStateMemo(milkAnimalsBlueprint, state, ['blueprintVersion'])
  const averagesBlueprint = useScenarioStateMemo(milkAnimalsAveragesBlueprint, state, ['blueprintVersion'])
  const movementsBlueprint = useScenarioStateMemo(milkAnimalsMovementsBlueprint, state, ['blueprintVersion'])
  const inventorySummaryBlueprint = useScenarioStateMemo(milkAnimalsInventorySummaryBlueprint, state, [
    'blueprintVersion',
  ])
  return {
    animalsBlueprint: useMonetaryTableBlueprint(animalsBlueprint, state),
    averagesBlueprint: useMonetaryTableBlueprint(averagesBlueprint, state),
    movementsBlueprint: useMonetaryTableBlueprint(movementsBlueprint, state),
    inventorySummaryBlueprint: useMonetaryTableBlueprint(inventorySummaryBlueprint, state),
  }
}
