import React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const avatarVariants = cva('inline-flex justify-center items-center rounded-full border-2', {
  variants: {
    variant: {
      green: 'border-green-600 bg-green-100',
      blue: 'border-sky-400 bg-sky-100',
      orange: 'border-orange-300 bg-orange-100',
    },
    dimension: {
      sm: 'size-[25px] pt-[7.5px] pb-[5.5px] px-[5.5px]',
      lg: 'size-8 pt-[10.5px] pr-[9.5px] pb-[9.5px] pl-[8.5px]',
    },
  },
  defaultVariants: {
    variant: 'green',
    dimension: 'sm',
  },
})

export interface AvatarProps extends VariantProps<typeof avatarVariants>, React.HtmlHTMLAttributes<HTMLDivElement> {
  readonly label: string
}

export const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  ({ className, variant = 'green', dimension = 'sm', label, ...props }, ref) => (
    <div className={cn(avatarVariants({ variant, dimension }), className)} ref={ref} {...props}>
      <p className="absolute select-none text-center text-xs font-medium leading-none tracking-[-0.36px] text-black">
        {label}
      </p>
    </div>
  )
)
