import { useObservable, useObservableState } from 'observable-hooks'
import { type RxDocument, type RxQuery } from 'rxdb'
import { switchMap } from 'rxjs'

import { isDocumentDeleted$ } from './rxjs/isDocumentDeleted$.ts'

export const useRxDocumentDeleted = <T>(query: RxQuery<T, RxDocument<T> | null>) => {
  const state$ = useObservable((inputs$) => inputs$.pipe(switchMap(([q]) => isDocumentDeleted$(q))), [query])
  return useObservableState(state$, false)
}
