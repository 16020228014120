import { DataAttributes } from './attributes'

export const dataKeyAttributes = (key: keyof typeof DataAttributes) => {
  const attribute = DataAttributes[key]
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!attribute) {
    throw new Error(`Data key attributes not found for key: ${key}`)
  }
  return attribute
}
