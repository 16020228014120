import type React from 'react'

import { ScenarioCreateView } from '@via/components'

import { useScenarioCreatePage } from './useScenarioCreatePage.ts'

export const ScenarioCreatePage: React.FC = () => {
  const props = useScenarioCreatePage()
  return <ScenarioCreateView key={props.budget._id} {...props} />
}
