import { type FC, useContext } from 'react'

import { type YearKey } from '@via/schema'
import { type Except } from 'type-fest'

import { MilkOperationsContext } from '../../context'
import { MilkQuotaForm } from '../MilkQuotaForm/MilkQuotaForm.tsx'

import { type MonetaryReadonlyValueCellProps } from './MonetaryReadonlyValueCell'
import { SheetEditableValueCell } from './SheetEditableValueCell'

export interface MilkQuotaValueCellProps extends Except<MonetaryReadonlyValueCellProps, 'children'> {
  readonly year: YearKey
}

export const MilkQuotaValueCell: FC<MilkQuotaValueCellProps> = ({ year, cellData, ...props }) => {
  const { onUpdateQuota } = useContext(MilkOperationsContext)
  return (
    <SheetEditableValueCell cellData={cellData} {...props}>
      {({ onClose }) => (
        <MilkQuotaForm
          year={year}
          onSubmit={async (values) => {
            await onUpdateQuota(values)
            onClose()
          }}
          onApply={onUpdateQuota}
        />
      )}
    </SheetEditableValueCell>
  )
}
