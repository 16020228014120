import React from 'react'

import * as Accordion from '@radix-ui/react-accordion'

import { cn } from '../../lib/utils'
import { SwitchWithLabel } from '../SwitchWithLabel/SwitchWithLabel'

export const SwitchAccordion = Accordion.Root

export const SwitchAccordionItem = React.forwardRef<
  React.ElementRef<typeof Accordion.Item>,
  React.ComponentPropsWithoutRef<typeof Accordion.Item>
>(({ className, ...props }, ref) => (
  <Accordion.Item ref={ref} className={cn('border-b border-b-gray-300 py-3', className)} {...props} />
))

export const SwitchAccordionTrigger = React.forwardRef<
  React.ElementRef<typeof Accordion.Trigger>,
  React.ComponentPropsWithoutRef<typeof Accordion.Trigger> & { readonly label: string }
>(({ id, label, className, ...props }, ref) => (
  <Accordion.Header>
    <Accordion.Trigger asChild ref={ref} className={className} {...props}>
      <SwitchWithLabel id={id} label={label} />
    </Accordion.Trigger>
  </Accordion.Header>
))

export const SwitchAccordionContent = React.forwardRef<
  React.ElementRef<typeof Accordion.Content>,
  React.ComponentPropsWithoutRef<typeof Accordion.Content>
>(({ className, children, ...props }, ref) => (
  <Accordion.Content ref={ref} className={cn('pt-4', className)} {...props}>
    {children}
  </Accordion.Content>
))
