import {type ClassValue, clsx} from 'clsx'
import {extendTailwindMerge} from 'tailwind-merge'

// https://github.com/dcastil/tailwind-merge/issues/418#issuecomment-2119278555
const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': ['text-xxs'],
    },
  },
})

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs))
}
