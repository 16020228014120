import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { dataLine } from '../utils'

export const netMargin2024Blueprint = {
  sections: [
    {
      id: 'finance.net-margin.total',
      noHeader: true,
      children: [dataLine('finance.net-margin.total', { variant: 'calculateTotal', symbol: 'none' })],
    },
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true, withDividerRight: true, className: 'w-[27rem]' },
    { id: 'divider', type: 'divider', className: 'w-1' },
    { id: 'projection', type: 'projections', className: 'w-24', withDividerRight: true },
  ],
} as const satisfies Except<BlueprintTable, 'version'>
