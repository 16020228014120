import { useMemo } from 'react'

import { type BlueprintVersion, type DataKey, resolveAlias, type YearKey } from '@via/schema'
import { mapValues } from 'lodash-es'
import { type Except } from 'type-fest'

import { type OverridableMonetaryCellData } from '../../model'

import { type UseMonetaryRowDataOptions } from './types.ts'
import { useMonetaryRowData } from './useMonetaryRowData.ts'

export interface UseMonetaryRowsValues<Key extends string, Weight extends string>
  extends Except<UseMonetaryRowDataOptions<Weight>, 'id'> {
  readonly keys: Record<Key, DataKey>
  readonly blueprintVersion?: BlueprintVersion
}

export const useMonetaryRowsValues = <Key extends string, Weight extends string = string>({
  keys,
  blueprintVersion,
  ...options
}: UseMonetaryRowsValues<Key, Weight>): Partial<Record<Key, Partial<Record<YearKey, OverridableMonetaryCellData>>>> => {
  const { rows, loading } = useMonetaryRowData({ id: Object.values(keys), ...options })
  return useMemo(
    () =>
      rows.length === 0 || loading
        ? {}
        : mapValues(keys, (dataKey) => {
            const resolvedKey = resolveAlias(dataKey, blueprintVersion)
            return rows.find((row) => row.id === resolvedKey)?.values
          }),
    [rows, loading, keys, blueprintVersion]
  )
}
