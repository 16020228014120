import { createContext, type FC, type PropsWithChildren, useMemo, useState } from 'react'

export interface MonetaryTableEditingStateChange {
  setEditing: (editing: boolean) => void
}

export const MonetaryTableEditingStateContext = createContext<boolean>(false)

export const MonetaryTableEditingContext = createContext<MonetaryTableEditingStateChange>({
  setEditing: () => {
    throw new Error('Cannot invoke "setEditing()" on the default context')
  },
})

export const MonetaryTableEditingStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [editing, setEditing] = useState(false)

  const contextValue = useMemo<MonetaryTableEditingStateChange>(
    () => ({
      setEditing,
    }),
    [setEditing]
  )

  return (
    <MonetaryTableEditingContext.Provider value={contextValue}>
      <MonetaryTableEditingStateContext.Provider value={editing}>{children}</MonetaryTableEditingStateContext.Provider>
    </MonetaryTableEditingContext.Provider>
  )
}
