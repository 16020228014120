import { useMemo } from 'react'
import { useController } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { LoanDatedInputTable } from '../LoanDatedInputTable/LoanDatedInputTable'

import { type LoanDetailFormData } from './LoanFormProvider'

export const LoanChangeRateForm = () => {
  const intl = useIntl()
  const { field } = useController<LoanDetailFormData, 'interestRateChange'>({ name: 'interestRateChange' })
  const labels = useMemo(
    () =>
      [
        intl.formatMessage({ id: 'loan.form.interest-rate-change.index' }),
        intl.formatMessage({ id: 'loan.form.interest-rate-change.date' }),
        intl.formatMessage({ id: 'loan.form.interest-rate-change.value' }),
        intl.formatMessage({ id: 'loan.form.interest-rate-change.payments' }),
      ] as const,
    [intl]
  )

  return <LoanDatedInputTable {...field} labels={labels} step="0.01" category="agricultural" />
}
