import { AnimalProductions, type AnimalProductionType, type ProductionType } from '../../../enums'
import { type DataKey } from '../../data-key-zod'
import { GrbfAttributesJson } from '../../generated'

import { type ReferenceDataKey } from './reference-attributes'

export interface GrbfDataAttribute {
  readonly type: 'grbf'
  readonly readonly: boolean
  readonly referenceDependencies?: ReadonlyArray<ReferenceDataKey>
  readonly reference?: 'selected' | 'latest' | 'none' // default: 'selected'
  readonly projectionReference?: 'selected' | 'latest' | 'none' // default: 'selected'
  readonly growth?: false // default: true
  readonly production?: ProductionType
  readonly weights?: ReadonlyArray<DataKey>
}

const CustomGrbfAttributes = {
  empty: {
    type: 'grbf',
    readonly: true,
    reference: 'none',
    growth: false,
  },
  'calf.income.grbf.miscellaneous': {
    type: 'grbf',
    production: 'calf',
    readonly: false,
    referenceDependencies: [
      'calf.income.imported.deductions',
      'calf.income.imported.operational',
      'calf.income.imported.short',
    ],
  },
  ...(Object.fromEntries<GrbfDataAttribute>(
    AnimalProductions.map((production) => [
      `animal.${production}.income.grbf.miscellaneous`,
      {
        type: 'grbf',
        readonly: false,
        production,
        referenceDependencies: [
          `animal.${production}.income.imported.deductions`,
          `animal.${production}.income.imported.operational`,
          `animal.${production}.income.imported.short`,
        ],
      },
    ])
  ) as Record<`animal.${AnimalProductionType}.income.grbf.miscellaneous`, GrbfDataAttribute>),
} as const satisfies Record<string, GrbfDataAttribute>

export const GrbfDataKeyAttributes = {
  ...(GrbfAttributesJson as Record<keyof typeof GrbfAttributesJson, GrbfDataAttribute>),
  ...CustomGrbfAttributes,
} as const

export type GrbfDataKey = keyof typeof GrbfDataKeyAttributes
