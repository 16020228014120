import { type FC, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { type CurrentProductions, getEnabledProductions } from '@via/compute'
import { type ProductionType } from '@via/schema'
import { pull } from 'lodash-es'
import { type ReadonlyDeep } from 'type-fest'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { RadioGroup } from '../../atoms/Radio/Radio'
import { InputWithLabel } from '../../molecules/InputWithLabel/InputWithLabel'
import { RadioWithLabel } from '../../molecules/RadioWithLabel/RadioWithLabel'
import { TextareaWithLabel } from '../../molecules/TextareaWithLabel/TextareaWithLabel'
import { ProductionCheckbox } from '../ProductionCheckbox/ProductionCheckbox'
import { ProductionDialogSelector } from '../ProductionDialogSelector/ProductionDialogSelector'

const getYearsLabelFromCurrentProduction = (
  currentProductions: ReadonlyDeep<CurrentProductions>,
  production: ProductionType
) =>
  Object.entries(currentProductions)
    .filter(([, value]) => value.includes(production))
    .map(([key]) => key)
    .join('-')

const isCurrentProduction = (currentProductions: ReadonlyDeep<CurrentProductions>, production: ProductionType) =>
  Object.values(currentProductions).flat().includes(production)

export interface ScenarioConfigurationFormProps {
  readonly formAction: 'create' | 'update'
  readonly currentProductions: ReadonlyDeep<CurrentProductions>
  readonly offline?: boolean
  readonly readonly?: boolean
  readonly canCreateCompleteScenario?: boolean
}

interface ScenarioConfigurationFormData {
  type: 'simple' | 'complete'
  name: string
  projectionYear: number
  customReferenceName: string
  description: string
  referenceType: 'empty' | 'viagritel'
  enabledProductions: ProductionType[]
}

export const ScenarioConfigurationForm: FC<ScenarioConfigurationFormProps> = ({
  formAction,
  currentProductions,
  offline,
  readonly,
  canCreateCompleteScenario,
}) => {
  const intl = useIntl()
  const { setValue } = useFormContext<ScenarioConfigurationFormData>()

  const [referenceType, enabledProductions] = useWatch<
    ScenarioConfigurationFormData,
    ['referenceType', 'enabledProductions']
  >({ name: ['referenceType', 'enabledProductions'] })

  useEffect(() => {
    if (referenceType !== 'empty') {
      setValue('enabledProductions', getEnabledProductions(currentProductions, enabledProductions))
    }
    // This effect should only be run when the reference type changes from empty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceType, currentProductions])

  return (
    <div className="flex items-start justify-center pt-16">
      <div className="w-[560px] flex-col items-start gap-9">
        <FormField<ScenarioConfigurationFormData, 'type'>
          name="type"
          disabled={readonly}
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <RadioGroup
                  disabled={formAction === 'update'}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex items-start gap-4">
                  <RadioWithLabel
                    value="simple"
                    label={intl.formatMessage({ id: 'scenarioConfiguration.form.type.simplifiedBudget' })}
                  />
                  {import.meta.env.VITE_ENABLE_COMPLETE_SCENARIO === 'true' || canCreateCompleteScenario ? (
                    <RadioWithLabel
                      value="complete"
                      label={intl.formatMessage({ id: 'scenarioConfiguration.form.type.fullBudget' })}
                    />
                  ) : (
                    <RadioWithLabel
                      value="complete"
                      label={intl.formatMessage({ id: 'scenarioConfiguration.form.type.fullBudgetDisabled' })}
                      disabled
                    />
                  )}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField<ScenarioConfigurationFormData, 'name'>
          name="name"
          disabled={readonly}
          render={({ field }) => (
            <FormItem className="w-full pt-9">
              <FormControl>
                <InputWithLabel
                  label={intl.formatMessage({ id: 'scenarioConfiguration.form.name' })}
                  autoComplete="off"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex items-end gap-12 pt-9">
          <FormField<ScenarioConfigurationFormData, 'projectionYear'>
            name="projectionYear"
            disabled={readonly}
            render={({ field }) => (
              <FormItem className="w-[115px]">
                <FormControl>
                  <InputWithLabel
                    type="number"
                    label={intl.formatMessage({ id: 'scenarioConfiguration.form.projectionYear' })}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField<ScenarioConfigurationFormData, 'description'>
          name="description"
          disabled={readonly}
          render={({ field }) => (
            <FormItem className="w-full pt-9">
              <FormControl>
                <TextareaWithLabel
                  label={intl.formatMessage({ id: 'scenarioConfiguration.form.description' })}
                  placeholder={intl.formatMessage({ id: 'scenarioConfiguration.form.description.placeholder' })}
                  className="w-full"
                  rows={6}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="w-[144px] min-w-4" />
      <div className="w-[560px] flex-col items-start pr-16">
        <div className="flex flex-col items-start gap-2">
          <p className="select-none text-xl font-bold leading-tight tracking-[-0.4px] text-black">
            {intl.formatMessage({ id: 'scenarioConfiguration.form.reference' })}
          </p>

          <FormField<ScenarioConfigurationFormData, 'referenceType'>
            name="referenceType"
            disabled={readonly}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <RadioGroup
                    disabled={formAction === 'update'}
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="flex items-start gap-4">
                    <RadioWithLabel
                      value="viagritel"
                      label={intl.formatMessage({ id: 'scenarioConfiguration.form.reference.viagritel' })}
                      disabled={offline || Object.keys(currentProductions).length === 0}
                    />
                    <RadioWithLabel
                      value="empty"
                      label={intl.formatMessage({ id: 'scenarioConfiguration.form.reference.empty' })}
                    />
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {referenceType === 'empty' && (
          <FormField<ScenarioConfigurationFormData, 'customReferenceName'>
            name="customReferenceName"
            disabled={readonly}
            render={({ field }) => (
              <FormItem className="w-[115px] pt-9">
                <FormControl>
                  <InputWithLabel
                    label={intl.formatMessage({ id: 'scenarioConfiguration.form.emptyReferenceYearName' })}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <div className="flex w-full flex-col items-start gap-2 pt-9">
          <div className="flex w-full items-center gap-4">
            <p className="select-none text-xl font-bold leading-tight tracking-[-0.4px] text-black">
              {intl.formatMessage({ id: 'scenarioConfiguration.form.chooseProductions' })}
            </p>
          </div>

          <div className="flex w-full flex-col items-start">
            <FormField<ScenarioConfigurationFormData, 'enabledProductions'>
              disabled={readonly}
              name="enabledProductions"
              render={({
                field,
              }: {
                readonly field: { value: ProductionType[]; onChange: (value: ProductionType[]) => void }
              }) => (
                <>
                  {field.value.map((value: ProductionType) => (
                    <ProductionCheckbox
                      key={value}
                      readonly={readonly}
                      label={intl.formatMessage({ id: `scenarioConfiguration.form.production.${value}` })}
                      years={
                        referenceType !== 'empty'
                          ? getYearsLabelFromCurrentProduction(currentProductions, value) || 'Non disponible'
                          : ''
                      }
                      forceChecked={isCurrentProduction(currentProductions, value) && referenceType !== 'empty'}
                      checked
                      onCheckedChange={() => {
                        field.onChange(pull(field.value, value))
                      }}
                    />
                  ))}
                  {!readonly && (
                    <ProductionDialogSelector
                      selectedProductions={field.value}
                      onSelect={(production) => {
                        field.onChange([...field.value, production])
                      }}
                    />
                  )}
                </>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
