import { type FC, useEffect, useMemo, useState } from 'react'
import { type IntlShape, useIntl } from 'react-intl'

import { type ParentProductionsType, ProductionsConfig, type ProductionType } from '@via/schema'
import { map, sortBy, uniq } from 'lodash-es'

import { AddItem } from '../../atoms/AddItem/AddItem'
import { Button } from '../../atoms/Button/Button'
import { Icons } from '../../atoms/Icons/Icons'
import { Select } from '../../atoms/Select/Select'
import { cn } from '../../lib/utils'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
} from '../../molecules/Dialog/Dialog'

const DEFAULT_ANIMATION_STATE = {
  parent: '-translate-x-[-4.75rem]',
  production: 'translate-x-[-4.75rem] opacity-0',
  status: 'closed',
}

interface ProductionDialogSelectorProps {
  readonly selectedProductions?: ProductionType[]
  readonly onSelect: (production: ProductionType) => void
}

const getSortedSelectProductions = (productions: ProductionType[], intl: IntlShape) => {
  const allProductions = productions.filter((production) => ProductionsConfig[production].type === 'all')
  const subAllProductions = productions.filter((production) => ProductionsConfig[production].type === 'sub-all')
  const otherProductions = productions.filter((production) => ProductionsConfig[production].type === 'other')
  const itemProductions = productions.filter((production) => ProductionsConfig[production].type === 'item')

  const headerProductions = [...allProductions, ...subAllProductions, ...otherProductions]

  if (headerProductions.length === 0) {
    return sortBy(
      itemProductions.map((production) => ({
        text: intl.formatMessage({ id: `scenarioConfiguration.form.production.${production}` }),
        value: production,
      })),
      'text'
    )
  }
  return [
    ...headerProductions.map((production) => ({
      text: intl.formatMessage({ id: `scenarioConfiguration.form.production.${production}` }),
      value: production,
    })),
    { divider: true } as const,
    ...sortBy(
      itemProductions.map((production) => ({
        text: intl.formatMessage({ id: `scenarioConfiguration.form.production.${production}` }),
        value: production,
      })),
      'text'
    ),
  ]
}

export const ProductionDialogSelector: FC<ProductionDialogSelectorProps> = ({ selectedProductions = [], onSelect }) => {
  const [open, setOpen] = useState(false)
  const [selectedParent, setSelectedParent] = useState<ParentProductionsType | undefined>()
  const [selectedProduction, setSelectedProduction] = useState<ProductionType | undefined>()
  const [animationState, setAnimationState] = useState(DEFAULT_ANIMATION_STATE)

  const availableProductions = Object.fromEntries(
    Object.entries(ProductionsConfig).filter(([key]) => !selectedProductions.includes(key as ProductionType))
  )
  const availableParentProductions: ParentProductionsType[] = uniq(map(Object.values(availableProductions), 'parent'))

  const availableProductionsForCurrentParent = useMemo(
    () =>
      Object.entries(availableProductions)
        .filter(([, { parent }]) => parent === selectedParent)
        .map(([production]) => production as ProductionType),
    [selectedParent, availableProductions]
  )

  const intl = useIntl()

  useEffect(() => {
    const entranceParentAnimation = 'animate-[parent-production-select-entrance_0.3s_ease-in]'
    const entranceProductionAnimation = 'animate-[production-select-entrance_0.3s_ease-in]'

    const exitParentAnimation = 'animate-[parent-production-select-exit_0.3s_ease-in] -translate-x-[-4.75rem]'
    const exitProductionAnimation = 'animate-[production-select-exit_0.3s_ease-in] translate-x-[-4.75rem] opacity-0'

    if (selectedParent && availableProductionsForCurrentParent.length > 1 && animationState.status === 'closed') {
      setAnimationState({
        parent: entranceParentAnimation,
        production: entranceProductionAnimation,
        status: 'open',
      })
    }

    if (selectedParent && availableProductionsForCurrentParent.length <= 1 && animationState.status === 'open') {
      setAnimationState({
        parent: exitParentAnimation,
        production: exitProductionAnimation,
        status: 'closed',
      })
    }
    // We don't want to run and maybe update the animation on animation status change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParent, availableProductionsForCurrentParent])

  useEffect(() => {
    if (selectedParent) {
      if (availableProductionsForCurrentParent.length === 1) {
        setSelectedProduction(availableProductionsForCurrentParent[0])
      } else {
        setSelectedProduction(undefined)
      }
    }
    // This effect should only run when the selectedParent changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParent])

  const onOpenChange = (value: boolean) => {
    setOpen(value)

    if (!value) {
      setAnimationState(DEFAULT_ANIMATION_STATE)
      setSelectedParent(undefined)
      setSelectedProduction(undefined)
    }
  }

  const onConfirm = () => {
    if (selectedProduction) {
      onSelect(selectedProduction)
      onOpenChange(false)
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger className="mt-9 flex flex-row gap-4">
        <AddItem />
        <p className={cn('select-none text-sm')}>
          {intl.formatMessage({ id: 'scenarioConfiguration.form.selectProductionTriggerTitle' })}
        </p>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent>
          <Icons.XLg
            className="absolute right-4 top-4 cursor-pointer"
            onClick={() => {
              onOpenChange(false)
            }}
          />
          <div className="flex flex-col items-center gap-1 pt-12">
            <DialogTitle asChild>
              <p className="text-2xl font-bold">
                {intl.formatMessage({ id: 'scenarioConfiguration.form.selectProductionTitle' })}
              </p>
            </DialogTitle>
            <DialogDescription asChild>
              <p className="text-base font-light">
                {intl.formatMessage({ id: 'scenarioConfiguration.form.selectProductionSubTitle' })}
              </p>
            </DialogDescription>
          </div>
          <div className="flex justify-center gap-2 pt-4">
            <Select
              className={`z-[120] w-36 ${animationState.parent}`}
              placeholder={intl.formatMessage({ id: 'scenarioConfiguration.form.selectParentProductionPlaceholder' })}
              onValueChange={(value) => {
                setSelectedParent(value as ParentProductionsType)
              }}
              options={sortBy(
                availableParentProductions.map((parentProduction) => ({
                  text: intl.formatMessage({ id: `scenarioConfiguration.form.production.${parentProduction}` }),
                  value: parentProduction,
                })),
                'text'
              )}
            />
            <Select
              className={`z-[115] w-36 ${animationState.production}`}
              placeholder={intl.formatMessage({ id: 'scenarioConfiguration.form.selectProductionPlaceholder' })}
              value={selectedProduction ?? ''}
              onValueChange={(value) => {
                setSelectedProduction(value as ProductionType)
              }}
              options={getSortedSelectProductions(availableProductionsForCurrentParent, intl)}
            />
          </div>
          <div className="flex justify-center pb-12 pt-9">
            <Button disabled={!selectedProduction} onClick={onConfirm}>
              <Icons.Check />
              {intl.formatMessage({ id: 'scenarioConfiguration.form.selectProductionAdd' })}
            </Button>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}
