import { BudgetReferenceZod } from '@via/schema'
import { type ReadonlyDeep } from 'type-fest'
import { type z } from 'zod'

import { type ScenarioState } from './types'

const EMPTY_REFERENCE = BudgetReferenceZod.parse({})

export type BudgetReferenceFetcher = (referenceId: string) => Promise<ReadonlyDeep<z.output<typeof BudgetReferenceZod>>>

export const fetchReference = async (
  state: ScenarioState | undefined,
  referenceFetcher: BudgetReferenceFetcher
): Promise<ReadonlyDeep<z.output<typeof BudgetReferenceZod>>> =>
  state?.referenceId ? referenceFetcher(state.referenceId) : EMPTY_REFERENCE
