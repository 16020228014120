import { mapValues } from 'lodash-es'
import { type EmptyObject } from 'type-fest'

import { type DataKey } from '../../data-key-zod'
import { type DataAttributes } from '../attributes'

import { type DataKeyDependencies } from './data-key-dependencies'
import { dataKeyDependenciesRecord, type DataKeyDependenciesRecordKey } from './data-key-dependencies-record'

export type DataKeyRecordDependenciesMapValuesResult<
  T,
  TDataKey extends DataKey,
> = (typeof DataAttributes)[TDataKey] extends {
  dependencies: Record<infer K, DataKey>
}
  ? Record<K, T>
  : EmptyObject

export const dataKeyRecordDependenciesMapValues = <T, TDataKey extends DataKey>(
  dataKey: TDataKey,
  iterator: (key: DataKeyDependenciesRecordKey<TDataKey>, value: DataKeyDependencies<TDataKey>) => T
): DataKeyRecordDependenciesMapValuesResult<T, TDataKey> =>
  mapValues(dataKeyDependenciesRecord(dataKey), (value, key) =>
    iterator(key as DataKeyDependenciesRecordKey<TDataKey>, value as DataKeyDependencies<TDataKey>)
  ) as DataKeyRecordDependenciesMapValuesResult<T, TDataKey>
