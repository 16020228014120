import { type ScenarioData, scenarioData } from '@via/components'
import { EMPTY, groupBy, map, mergeMap, scan, switchMap } from 'rxjs'

import { AppWorker } from '../../../worker/AppWorker.ts'
import { rxdbScenarioData$ } from '../../scenarios/rxdbScenarioData.ts'
import { rxDocumentData } from '../../utils/rxDocumentData.ts'
import { appRxDatabase } from '../appRxDatabase.ts'

export const rxdbBudgetScenarioList$ = (budgetId: string) =>
  appRxDatabase.budgets.findOne(budgetId).$.pipe(
    mergeMap((budget) => (budget ? Object.keys(rxDocumentData(budget).scenarios) : EMPTY)),
    groupBy((scenarioId) => scenarioId),
    mergeMap((group) =>
      group.pipe(
        mergeMap((scenarioId) => AppWorker.instance.scenarioHandler.loadScenarioDatabase(budgetId, scenarioId)),
        switchMap((scenarioDatabase) => rxdbScenarioData$(scenarioDatabase)),
        map(({ scenarioState, scenario, reference }) => scenarioData(scenario, scenarioState, reference))
      )
    ),
    scan((acc, curr) => ({ ...acc, [curr._id]: curr }), {} as Record<string, ScenarioData>),
    map((scenarios) => Object.values(scenarios))
  )
