import { forwardRef, type HtmlHTMLAttributes } from 'react'
import { useFormContext } from 'react-hook-form'

import { Button } from '../Button/Button'
import { Icons } from '../Icons'

import { useFormSubmit } from './useFormSubmit'

interface FormSubmitButtonsProps extends HtmlHTMLAttributes<HTMLDivElement> {
  readonly disabled?: boolean
}

export const FormSubmitButtons = forwardRef<HTMLDivElement, FormSubmitButtonsProps>(({ disabled, ...props }, ref) => {
  const { formState } = useFormContext()
  const { apply } = useFormSubmit()

  return (
    <div ref={ref} {...props}>
      {apply && (
        <Button dimension="xs" shape="iconOnly" onClick={apply} disabled={disabled || formState.isSubmitting}>
          <Icons.ArrowLeftRight />
        </Button>
      )}
      <Button type="submit" dimension="xs" shape="iconOnly" disabled={disabled} loading={formState.isSubmitting}>
        <Icons.CheckLarge className="size-4" />
      </Button>
    </div>
  )
})
