import { type CalculationDataKey, type DataKeyDependencies } from '@via/schema'
import { BigNumber } from 'bignumber.js'
import { mapValues } from 'lodash-es'

import { protectedDivision } from '../../utils'
import { computeMilkProductionExpenses, MilkExpensesDependencies } from '../milk-expenses'

import { type Calculation } from './types'

const calculation = <TDataKey extends CalculationDataKey>(key: TDataKey, calc: Calculation<TDataKey>) =>
  ({ [key]: calc }) as Record<TDataKey, Calculation<TDataKey>>

const calculationWithSpecifiedDependencies = <TDataKey extends CalculationDataKey, K extends string>(
  key: TDataKey,
  deps: Record<K, DataKeyDependencies<TDataKey>>,
  compute: (values: Partial<Record<K, BigNumber>>) => BigNumber
) => {
  const calc: Calculation<TDataKey> = (val) => compute(mapValues(deps, (dep) => val(dep)))
  return { [key]: calc } as Record<TDataKey, Calculation<TDataKey>>
}

export const Calculations: Record<CalculationDataKey, Calculation> = {
  ...calculation('milk.animals.income.breed.value', (val) =>
    val('milk.animals.income.breed.head-count').times(val('milk.animals.income.breed.value-per-head-count'))
  ),
  ...calculation('milk.animals.income.calf.head-count', (val) => {
    const halfAverageCows = val('milk.animals.cows.average-head-count').dividedBy(2)
    return halfAverageCows.plus(
      halfAverageCows
        .times(new BigNumber(100).minus(val('milk.animals.income.calf.females-kept-proportion')))
        .shiftedBy(-2)
    )
  }),
  ...calculation('milk.animals.income.calf.value', (val) =>
    val('milk.animals.income.calf.head-count').times(val('milk.animals.income.calf.value-per-head-count'))
  ),
  ...calculation('milk.animals.income.cull.head-count', (val) =>
    val('milk.animals.cows.average-head-count').times(val('milk.animals.income.cull.proportion').shiftedBy(-2))
  ),
  ...calculation('milk.animals.income.cull.value', (val) =>
    val('milk.animals.income.cull.head-count').times(val('milk.animals.income.cull.value-per-head-count'))
  ),
  ...calculation('milk.animals.income.other.value', (val) =>
    val('milk.animals.income.other.head-count').times(val('milk.animals.income.other.value-per-head-count'))
  ),
  ...calculation('milk.animals.expenses.cows.value', (val) =>
    val('milk.animals.expenses.cows.head-count').times(val('milk.animals.expenses.cows.value-per-head-count'))
  ),
  ...calculation('milk.animals.expenses.other.value', (val) =>
    val('milk.animals.expenses.other.head-count').times(val('milk.animals.expenses.other.value-per-head-count'))
  ),
  ...calculation('milk.production.volume.hectoliter.total', (val) =>
    val('milk.production.cows.head-count').times(val('milk.production.cows.liter-per-head-count')).shiftedBy(-2)
  ),
  ...calculation('milk.production.sale.hectoliter', (val) =>
    val('milk.production.volume.hectoliter.total').minus(val('milk.production.volume.liter.excluded').shiftedBy(-2))
  ),
  ...calculation('milk.production.sale.kilogram-of-fat', (val) =>
    val('milk.production.sale.hectoliter').times(val('milk.production.content.kilogram-per-hectoliter.fat'))
  ),
  ...calculation('milk.production.sale.kilogram-of-fat-per-day', (val) =>
    val('milk.production.sale.kilogram-of-fat').div(365.25)
  ),
  ...calculation('milk.quota.allowed-production.hectoliter', (val) =>
    protectedDivision(
      val('milk-quota.allowed-kilogram-of-fat'),
      val('milk.production.content.kilogram-per-hectoliter.fat')
    )
  ),
  ...calculation('milk.quota.analysis.percentage.usage', (val) =>
    protectedDivision(
      val('milk.production.sale.hectoliter'),
      val('milk.quota.allowed-production.hectoliter')
    ).shiftedBy(2)
  ),
  ...calculation('milk.quota.analysis.kilogram-of-fat-per-day.allowed.difference', (val) =>
    val('milk.production.sale.hectoliter')
      .minus(val('milk.quota.allowed-production.hectoliter'))
      .times(val('milk.production.content.kilogram-per-hectoliter.fat'))
      .div(365.25)
  ),
  ...calculation('milk.quota.analysis.head-count.equilibrium', (val) =>
    protectedDivision(
      val('milk.quota.allowed-production.hectoliter'),
      val('milk.production.cows.liter-per-head-count').shiftedBy(-2)
    )
  ),
  ...calculation('milk.quota.analysis.liter-per-head-count.equilibrium', (val) =>
    protectedDivision(
      val('milk.quota.allowed-production.hectoliter'),
      val('milk.production.cows.head-count')
    ).shiftedBy(2)
  ),
  ...calculation('milk.sale.hectoliter', (val) =>
    BigNumber.min(val('milk.production.sale.hectoliter'), val('milk.quota.allowed-production.hectoliter'))
  ),
  ...calculationWithSpecifiedDependencies(
    'milk.marketing.currency-per-hectoliter',
    MilkExpensesDependencies,
    computeMilkProductionExpenses
  ),
  ...calculation('milk.marketing.total', (val) =>
    val('milk.production.sale.hectoliter').times(val('milk.marketing.currency-per-hectoliter'))
  ),
}
