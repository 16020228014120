import React, { useCallback, useContext } from 'react'

import * as Popover from '@radix-ui/react-popover'
import { type Except } from 'type-fest'

import { MonetaryTableEditingContext } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import { useEditableCellKeyDown } from '../../hooks/useEditableCellKeyDown'

import { MonetaryReadonlyValueCell, type MonetaryReadonlyValueCellProps } from './MonetaryReadonlyValueCell'
import { type CellEditionMode } from './types'

export interface PopoverEditableValueCellProps
  extends Except<MonetaryReadonlyValueCellProps, 'cellData' | 'children'>,
    Required<Pick<MonetaryReadonlyValueCellProps, 'cellData'>> {
  readonly children: (props: { type: 'inline' | 'modal'; onClose: () => void }) => React.ReactNode
}

export const PopoverEditableValueCell: React.FC<PopoverEditableValueCellProps> = ({
  className,
  children,
  ...props
}) => {
  const { setEditing: setContextEditing } = useContext(MonetaryTableEditingContext)
  const [editing, setEditing] = React.useState<CellEditionMode>(false)
  const { onKeyDown } = useEditableCellKeyDown(setEditing, setContextEditing)

  const onClose = useCallback(() => {
    setEditing(false)
    setContextEditing(false)
  }, [setContextEditing])

  const onPopoverChange = useCallback(
    (open: boolean) => {
      setEditing(open && 'modal')
      setContextEditing(open)
    },
    [setContextEditing]
  )

  return (
    <Popover.Root open={!!editing} onOpenChange={onPopoverChange}>
      <Popover.Trigger asChild>
        <MonetaryReadonlyValueCell className={className} editing={!!editing} onKeyDown={onKeyDown} {...props} />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="z-[9999]">{editing && children({ type: editing, onClose })}</Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
