import type React from 'react'
import { useIntl } from 'react-intl'

import { type CurrentProductions, getEnabledProductions } from '@via/compute'
import { YearKeyZod } from '@via/schema'
import { z } from 'zod'

import { ZodForm } from '../../atoms/Form/ZodForm'
import { useBudget } from '../../context'

import { ScenarioCreationFormZod } from './ScenarioCreationFormZod.ts'

export interface ScenarioCreationFormProviderProps {
  readonly currentProductions: CurrentProductions
  readonly referenceType?: 'viagritel' | 'empty'
}

export const ScenarioCreationFormProvider: React.FC<React.PropsWithChildren<ScenarioCreationFormProviderProps>> = ({
  currentProductions,
  referenceType = 'viagritel',
  children,
}) => {
  const defaultValues = {
    type: 'simple',
    name: '',
    configuration: 'annual',
    projectionYear: 4,
    description: '',
    referenceType: Object.keys(currentProductions).length > 0 ? referenceType : 'empty',
    customReferenceName: '',
    enabledProductions: getEnabledProductions(currentProductions),
  } as const

  const intl = useIntl()
  const budget = useBudget()

  const ScenarioCreationWithValidationFormZod = ScenarioCreationFormZod.superRefine((scenario, ctx) => {
    const yearKeyValues = YearKeyZod.options
    const maxProjectionYears = Number(yearKeyValues.at(-1)) - budget.year

    if (scenario.projectionYear > maxProjectionYears) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: intl.formatMessage({ id: 'form.projectionYear.maximum' }, { count: maxProjectionYears }),
        path: ['projectionYear'],
      })
    }
  })

  return (
    <ZodForm schema={ScenarioCreationWithValidationFormZod} defaultValues={defaultValues}>
      {children}
    </ZodForm>
  )
}
