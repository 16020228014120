import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { type FormatNumberOptions } from '@formatjs/intl'
import { keysToTuple, type Unit } from '@via/schema'

const FormatOptions = {
  currency: {
    currencySign: 'accounting',
    currency: 'CAD',
    style: 'currency',
    signDisplay: 'negative',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
  },
  'currency-with-symbol': {
    currencySign: 'accounting',
    currency: 'CAD',
    style: 'currency',
    signDisplay: 'negative',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
  },
  'currency-movement': {
    signDisplay: 'never',
    maximumFractionDigits: 0,
  },
  'currency-per-kilogram-of-fat-per-day': {
    maximumFractionDigits: 0,
  },
  'currency-per-head-count': {
    maximumFractionDigits: 0,
  },
  growth: {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
  percent: {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
  interest: {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
  integer: {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  },
  proportion: {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  },
  day: {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  },
  'head-count': {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  },
  liter: {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  },
  hectoliter: {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  },
  'kilogram-of-fat-per-day': {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  },
  'kilogram-per-hectoliter': {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  },
  'kilogram-of-fat': {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  },
} as const satisfies Record<string, FormatNumberOptions>

const movementPrefix = (value: number) => {
  switch (true) {
    case value < 0:
      return '(-)\u00A0'
    case value > 0:
      return '(+)\u00A0'
    default:
      return ''
  }
}

export type NumberDisplayType = keyof typeof FormatOptions | Unit
export const NumberDisplayTypes = keysToTuple(FormatOptions)

const postFormat = (value: string, rawValue: number, type?: NumberDisplayType) => {
  switch (type) {
    case 'currency':
      // eslint-disable-next-line unicorn/prefer-string-replace-all
      return value.replace(/\s*\$/g, '')
    case 'currency-movement':
      return `${movementPrefix(rawValue)}${value}`
    case 'proportion':
      return `${value}\u2009%`
    default:
      return value
  }
}

export const useFormatNumber = () => {
  const intl = useIntl()

  return useMemo(
    () => ({
      formatNumber: (value: number, type?: NumberDisplayType, options?: FormatNumberOptions) =>
        postFormat(
          intl.formatNumber(value, {
            ...(type
              ? ((FormatOptions as Record<string, FormatNumberOptions>)[type] ?? {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : {}),
            ...options,
          }),
          value,
          type
        ),
    }),
    [intl]
  )
}
