import { forwardRef } from 'react'
import { useIntl } from 'react-intl'

import { type DataKey, dataKeyTypedAttributes } from '@via/schema'
import { type Except } from 'type-fest'
import { z } from 'zod'

import { SheetFormProvider, type SheetFormProviderProps } from '../SheetFormProvider'

const InvestmentInventoryFormZod = z.object({})

export interface InvestmentInventoryFormProviderProps
  extends Except<
    SheetFormProviderProps<typeof InvestmentInventoryFormZod>,
    'schema' | 'defaultValues' | 'onFormSubmit' | 'onFormApply' | 'title'
  > {
  readonly dataKey: DataKey
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

export const InvestmentInventoryFormProvider = forwardRef<HTMLFormElement, InvestmentInventoryFormProviderProps>(
  ({ dataKey, children, ...props }, ref) => {
    const intl = useIntl()
    const { inventory } = dataKeyTypedAttributes(dataKey, 'finance-investment-inventory')
    return (
      <SheetFormProvider<typeof InvestmentInventoryFormZod>
        key={dataKey}
        {...props}
        values={{}}
        readonly
        disabled
        title={intl.formatMessage({ id: `table.${inventory}` })}
        schema={InvestmentInventoryFormZod}
        ref={ref}
        onFormSubmit={noop}
        onFormApply={noop}>
        {children}
      </SheetFormProvider>
    )
  }
)
