import { forwardRef, type HtmlHTMLAttributes } from 'react'

import { Link } from '@tanstack/react-router'

import { cn } from '../../lib/utils'

import { DisabledLink } from './DisabledLink'
import { navigationBarLinkVariants } from './NavigationBarLinkVariants'

interface AppNavigationBarLabels {
  readonly budgets: string
  readonly clients: string
  readonly referencesValues: string
}

export interface AppNavigationBarProps extends HtmlHTMLAttributes<HTMLDivElement> {
  readonly active?: keyof AppNavigationBarLabels
  readonly labels: AppNavigationBarLabels
  readonly offline?: boolean
}

export const AppNavigationBar = forwardRef<HTMLDivElement, AppNavigationBarProps>(
  ({ active, labels, className, offline = false, ...props }, ref) => (
    <div className={cn('flex items-start gap-1.5', className)} ref={ref} {...props}>
      <Link to="/budgets" className={navigationBarLinkVariants({ active: active === 'budgets' })}>
        {labels.budgets}
      </Link>

      {offline ? (
        <DisabledLink>{labels.clients}</DisabledLink>
      ) : (
        <Link to="/clients" className={navigationBarLinkVariants({ active: active === 'clients' })}>
          {labels.clients}
        </Link>
      )}
      {offline ? (
        <DisabledLink>{labels.referencesValues}</DisabledLink>
      ) : (
        <DisabledLink>{labels.referencesValues}</DisabledLink>
      )}
    </div>
  )
)
