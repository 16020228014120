import React from 'react'

import * as SeparatorPrimitive from '@radix-ui/react-separator'

import { cn } from '../../lib/utils'

export const Divider = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SeparatorPrimitive.Root
    ref={ref}
    decorative
    orientation="horizontal"
    className={cn('h-[1px] w-full shrink-0 bg-gray-300', className)}
    {...props}
  />
))
