import { type FC, useContext, useMemo } from 'react'

import { type YearKey } from '@via/schema'
import dayjs from 'dayjs'

import { useScenario } from '../../context'
import { MonthlyTable } from '../MonthlyTable'

import { MilkQuotaFormComputationContext } from './MilkQuotaFormComputationContext.tsx'
import {
  type MilkQuotaFormMonthlyTableBlueprintKeys,
  useMilkQuotaFormMonthlyTableBlueprint,
} from './useMilkQuotaBlueprint.tsx'

interface MilkQuotaFormMonthlyTableProps {
  readonly year: YearKey
}

export const MilkQuotaFormMonthlyTable: FC<MilkQuotaFormMonthlyTableProps> = ({ year }) => {
  const { startOfYearOffset } = useScenario()
  const blueprint = useMilkQuotaFormMonthlyTableBlueprint()
  const startDate = useMemo(
    () =>
      dayjs(year, 'YYYY')
        .startOf('year')
        .add(startOfYearOffset ?? 0, 'months')
        .format('YYYY-MM-DD'),
    [year, startOfYearOffset]
  )
  const { values } = useContext(MilkQuotaFormComputationContext)

  return (
    <MonthlyTable<MilkQuotaFormMonthlyTableBlueprintKeys> startDate={startDate} blueprint={blueprint} values={values} />
  )
}
