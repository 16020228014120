import React from 'react'

import { type UserRole } from '@via/schema'
import { type Except } from 'type-fest'

import { Avatar, type AvatarProps } from '../../atoms/Avatar/Avatar'
import { cn } from '../../lib/utils'

import { userAvatarLabel } from './userAvatarLabel'

export type UserAvatarVariant = UserRole

export interface UserAvatarProps extends Except<AvatarProps, 'variant' | 'label'> {
  readonly userName: string
  readonly userRole?: UserAvatarVariant
}

export const UserAvatar = React.forwardRef<HTMLDivElement, UserAvatarProps>(
  ({ className, userName, userRole = 'other', ...props }, ref) => (
    <Avatar
      className={cn(className)}
      ref={ref}
      label={userAvatarLabel(userName)}
      variant={userRole === 'technician' ? 'blue' : userRole === 'agronomist' ? 'green' : 'orange'}
      {...props}
    />
  )
)
