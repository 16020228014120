import { type JsonSchema, type RxJsonSchema } from 'rxdb'

import { type AppRxDatabase, type RxCollectionDocumentType } from '../types.ts'

type ScenarioSyncStateRxDocument = RxCollectionDocumentType<AppRxDatabase['scenario-sync-states']>

export const ScenarioSyncStateRxSchema = {
  title: 'scenario sync state',
  description: 'scenario sync state schema',
  version: 0,
  type: 'object',
  primaryKey: '_id',
  required: ['_id', 'budgetId'],
  properties: {
    _id: {
      type: 'string',
      maxLength: 100,
    },
    budgetId: {
      type: 'string',
    },
    scenarioId: {
      type: 'string',
    },
    mustSync: {
      type: 'boolean',
      default: false,
    },
    lastPushEpochTimestamp: {
      type: 'number',
    },
    snapshot: {
      type: 'object',
      required: ['epochTimestamp', 'stateId'],
      properties: {
        epochTimestamp: {
          type: 'number',
        },
        stateId: {
          type: 'string',
        },
      },
    } satisfies JsonSchema<ScenarioSyncStateRxDocument['snapshot']>,
  },
} as const satisfies RxJsonSchema<ScenarioSyncStateRxDocument>
