import { type ComponentProps } from 'react'

import * as SelectPrimitive from '@radix-ui/react-select'
import { Link } from '@tanstack/react-router'

import { SelectContent, SelectTrigger } from './Select'

type LinkType = ComponentProps<typeof Link> & { label: string }

interface SelectLinksProps {
  readonly label: string
  readonly links: LinkType[]
}

export const SelectLinks = ({ label, links }: SelectLinksProps) => (
  <SelectPrimitive.Root value="">
    <SelectTrigger>
      <SelectPrimitive.Value placeholder={label} />
    </SelectTrigger>
    <SelectContent className="flex gap-1">
      {links.map(({ label: linkLabel, ...link }) => (
        <Link
          {...link}
          key={linkLabel}
          className="flex h-8 w-full items-center rounded-sm px-3 text-sm hover:bg-blue-200 focus:border-blue-200 focus-visible:border-blue-200 focus-visible:bg-blue-200 focus-visible:outline-none">
          {linkLabel}
        </Link>
      ))}
    </SelectContent>
  </SelectPrimitive.Root>
)
