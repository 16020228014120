import { useCallback } from 'react'

import { useAuthenticatedFirebaseAuth } from './useFirebaseAuth.ts'

export const useCurrentUserActionTimestamp = () => {
  const { user, userName, userRole } = useAuthenticatedFirebaseAuth()
  return useCallback(
    (timestamp = new Date()) => ({
      timestamp,
      userId: user.uid,
      userName,
      userRole,
    }),
    [user.uid, userName, userRole]
  )
}
