import type React from 'react'
import { useContext } from 'react'

import { AppRxDatabaseContext } from './AppRxDatabaseContext.ts'
import { AppRxLeadershipProvider } from './AppRxLeadershipProvider.tsx'

export const AppRxDatabaseProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const database = useContext(AppRxDatabaseContext)
  return <AppRxLeadershipProvider database={database}>{children}</AppRxLeadershipProvider>
}
