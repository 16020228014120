import { type ProductionType } from '../../../enums'
import { type DataKey } from '../../data-key-zod'
import { type BlueprintAliasAttributesJson, type CompositeAttributesJson, TotalAttributesJson } from '../../generated'

import { type AliasDataKey } from './alias-attributes'
import { type ArithmeticAttribute } from './arithmetic-attribute'
import { type CalculationDataKey } from './calculation-attributes'
import { type GrbfDataKey } from './grbf-attributes'

export interface TotalDataAttribute<Deps extends DataKey = DataKey> {
  readonly type: 'total'
  readonly readonly: true
  readonly dependencies: ReadonlyArray<Deps>
  readonly arithmetic?: ArithmeticAttribute<Deps>
  readonly production?: ProductionType
  readonly weights?: ReadonlyArray<DataKey>
}

type TotalDeps =
  | keyof typeof BlueprintAliasAttributesJson
  | keyof typeof TotalAttributesJson
  | keyof typeof CompositeAttributesJson
  | AliasDataKey
  | GrbfDataKey
  | CalculationDataKey

const FinancialResultTotalAttributes = {
  'result.profit': {
    type: 'total',
    readonly: true,
    dependencies: ['result.income.total', 'result.expenses.total'],
    arithmetic: {
      'result.expenses.total': { multiplier: -1 },
    },
  },

  'result.company.expenses.fixed.salaries.total': {
    type: 'total',
    readonly: true,
    dependencies: [
      'result.company.expenses.fixed.salaries.permanent',
      'result.company.expenses.fixed.salaries.seasonal',
      'result.company.expenses.fixed.salaries.grants',
    ],
    arithmetic: {
      'result.company.expenses.fixed.salaries.grants': { multiplier: -1 },
    },
  },

  'result.expenses.finance.total': {
    type: 'total',
    readonly: true,
    dependencies: [
      'result.company.expenses.finance.interests.due',
      'result.company.expenses.finance.interests.grants',
      'result.company.expenses.finance.amortization',
    ],
    arithmetic: {
      'result.company.expenses.finance.interests.grants': { multiplier: -1 },
    },
  },
} as const satisfies Partial<Record<string, TotalDataAttribute<TotalDeps>>>

export const TotalDataKeyAttributes = {
  ...(TotalAttributesJson as Record<keyof typeof TotalAttributesJson, TotalDataAttribute<TotalDeps>>),
  ...FinancialResultTotalAttributes,
} as const

export type TotalDataKeys = keyof typeof TotalDataKeyAttributes
