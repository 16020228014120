import { type KeyboardEvent, useCallback } from 'react'

import { type CellEditionMode } from '../organisms/MonetaryValueCell/types'

const INLINE_EDITION_KEYS = new Set(['-', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'])

export const useEditableCellKeyDown = (
  setEditing: (editing: CellEditionMode) => void,
  onChangeEditing: (editing: boolean) => void
) => {
  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        setEditing(e.shiftKey ? 'modal' : 'inline')
        onChangeEditing(true)
        e.preventDefault()
      } else {
        const wasAnyKeyPressed = INLINE_EDITION_KEYS.has(e.key)
        if (wasAnyKeyPressed) {
          setEditing('inline')
          onChangeEditing(true)
        }
      }
    },
    [setEditing, onChangeEditing]
  )

  return { onKeyDown }
}
