import { type AnimalProductionType, type CommercialProductionsType, type VegetalProductionsType } from '@via/schema'

export const CommercialProductionsFrench: Record<CommercialProductionsType, string> = {
  cheese: 'Fromagerie',
  quarry: 'Carrière',
  bakery: 'Boulangerie',
  honey: 'Miel',
  wood: 'Boisé',
  commercial: 'Autres activités commerciales',
  'agro-tourism': 'Agro-tourisme',
  'snow-clearing': 'Déneigement',
  butchery: 'Boucherie',
  garden: 'Centre-Jardin',
  nursery: 'Pépinière-ornemental',
  greenhouse: 'Serre-ornementale',
} as const

export const VegetalProductionsFrench: Record<VegetalProductionsType, string> = {
  asparagus: 'Asperge',
  all: 'Tout maraîcher',
  tobacco: 'Tabac',
  thyme: 'Thym',
  peat: 'Tourbe',
  blueberry: 'Bleuet',
  brocoli: 'Brocoli',
  beet: 'Betterave',
  zucchini: 'Zucchini',
  'greenhouse-cucumber': 'Concombre serre',
  camomille: 'Camomille',
  mushroom: 'Champignon',
  celery: 'Céleri',
  pickle: 'Cornichon',
  kale: 'Chou frisé',
  spinach: 'Epinard',
  fruit: 'Fruits divers',
  raspberry: 'Framboise',
  strawberry: 'Fraise',
  'cannery-bean': 'Haricot conserverie',
  'cannery-corn': 'Mais-sucré conserverie',
  'table-potato': 'Patate table',
  'cannery-pea': 'Pois conserverie',
  nursery: 'Pépinière',
  parsnip: 'Panais',
  leek: 'Poireaux',
  garlic: 'Ail',
  'field-tomato': 'Tomate champs',
  'greenhouse-tomato': 'Tomate serre',
  other: 'Autre végétal',
  'table-carrot': 'Carotte marché',
  pumpkin: 'Citrouille',
  cauliflower: 'Chou-fleur',
  cabbage: 'Chou',
  cantaloup: 'Cantaloup',
  ornamental: 'Culture ornementale',
  'winter-cabbage': 'Chou d’hiver',
  'brussel-sprout': 'Chou bruxelle',
  shallots: 'Echalotte',
  'fava-bean': 'Gourgane',
  'fresh-bean': 'Haricot frais',
  vegetable: 'Légumes divers',
  lettuce: 'Laitue',
  watermelon: 'Melon d’eau',
  'sweet-corn': 'Mais-sucré frais',
  turnip: 'Navet',
  onion: 'Oignon',
  'seed-potato': 'Patate semence',
  medicinal: 'Plantes médicinales',
  apple: 'Pomme',
  pepper: 'Piment',
  radish: 'Radis',
  fir: 'Sapin',
} as const

export const AnimalProductionsFrench: Record<AnimalProductionType, string> = {
  'milk-veal': 'Veaux de lait',
  goat: 'Chèvres',
  horse: 'Chevaux',
  'heavy-turkey': 'Dindons lourds',
  rabbit: 'Lapins',
  weaner: 'Porcelets',
  'table-egg': 'Oeufs consommation',
  ostrich: 'Ratites',
  aquaculture: 'Pisciculture',
  exotic: 'Exotique',
  'replacement-heifer': 'Taures d’élevage',
  'finished-beef': 'Bouvillons',
  bison: 'Bisons',
  wapiti: 'Wapitis',
  'roast-turkey': 'Dindons de gril',
  pork: 'Porcs',
  sheep: 'Ovins',
  'grain-veal': 'Veaux de grain',
  'incubation-egg': 'Oeufs incubation',
  'roast-poultry': 'Poulets de gril',
  stag: 'Cerfs',
} as const
