import { forwardRef, type HtmlHTMLAttributes } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

const lineTabVariants = cva('w-[17px]', {
  variants: {
    variant: {
      default: 'bg-gray-100',
      pass: 'bg-green-400',
      alert: 'bg-red-400',
      empty: '',
    },
  },
  defaultVariants: {
    variant: 'empty',
  },
})

export interface LineTabProps extends HtmlHTMLAttributes<HTMLDivElement>, VariantProps<typeof lineTabVariants> {}

export const LineTab = forwardRef<HTMLDivElement, LineTabProps>(({ className, variant, ...props }, ref) => (
  <div className={cn(lineTabVariants({ variant }), className)} ref={ref} {...props} />
))
