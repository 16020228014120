import type React from 'react'

import { addBreadcrumb, captureException } from '@sentry/react'
import { handleNotAwaitedPromise } from '@via/frontend-sentry'
// eslint-disable-next-line import-x/no-unresolved
import { pwaInfo } from 'virtual:pwa-info'
// eslint-disable-next-line import-x/no-unresolved
import { useRegisterSW } from 'virtual:pwa-register/react'

export const PWAStateProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  useRegisterSW({
    immediate: true,
    onNeedRefresh: () => {
      addBreadcrumb({ category: 'pwa', message: 'onNeedRefresh', data: { version: __COMMIT_HASH__ } })
    },
    onRegisteredSW(swUrl, registration) {
      addBreadcrumb({
        category: 'pwa',
        message: 'onRegisteredSW',
        data: { swUrl, pwaInfo, version: __COMMIT_HASH__ },
      })

      if (registration) {
        setInterval(() => {
          addBreadcrumb({
            level: 'debug',
            category: 'pwa',
            message: 'Checking for sw update',
            data: { swUrl, pwaInfo, version: __COMMIT_HASH__ },
          })

          handleNotAwaitedPromise(registration.update())
        }, 60_000 /* 60s */)
      }
    },
    onRegisterError(error) {
      captureException(error, { tags: { pwa: true } })
    },
  })

  return children
}
