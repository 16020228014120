import { forwardRef, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { mapNonNullValues } from '@via/compute'
import { type ScenarioMilkQuotaStateZod, type YearKey } from '@via/schema'
import dayjs from 'dayjs'
import { times } from 'lodash-es'
import { type Except } from 'type-fest'
import { type z } from 'zod'

import { useScenario, useScenarioProjectionOverride } from '../../context'
import { SheetFormProvider, type SheetFormProviderProps } from '../SheetFormProvider'

import { type MilkQuotaFormData, MilkQuotaFormZod } from './MilkQuotaFormZod.ts'

type ScenarioMilkQuotaState = z.input<typeof ScenarioMilkQuotaStateZod>

export interface MilkQuotaFormSubmitData {
  readonly year: YearKey
  readonly monthKeys: ReadonlyArray<string>
  readonly quota: ScenarioMilkQuotaState
  readonly quotaPrice?: string
}

export interface MilkQuotaFormProviderProps
  extends Except<
    SheetFormProviderProps<typeof MilkQuotaFormZod>,
    'schema' | 'defaultValues' | 'onFormSubmit' | 'onFormApply' | 'title' | 'onDelete'
  > {
  readonly year: YearKey
  readonly onSubmit: (value: MilkQuotaFormSubmitData) => Promise<void>
  readonly onApply: (value: MilkQuotaFormSubmitData) => Promise<void>
}

const milkQuotaSubmitData = (
  year: YearKey,
  monthKeys: ReadonlyArray<string>,
  { quota, quotaPrice }: MilkQuotaFormData
): MilkQuotaFormSubmitData => ({
  year,
  monthKeys,
  quota: mapNonNullValues(quota, (monthValues) =>
    Object.fromEntries(Object.entries(monthValues).filter(([, value]) => value !== undefined && value !== ''))
  ),
  quotaPrice,
})

export const MilkQuotaFormProvider = forwardRef<HTMLFormElement, MilkQuotaFormProviderProps>(
  ({ year, onSubmit, onApply, children, ...props }, ref) => {
    const intl = useIntl()
    const { milkQuota: quota, startOfYearOffset } = useScenario()
    const quotaPrice = useScenarioProjectionOverride({ key: 'milk.quota.grbf.asset.cost', year })

    const monthKeys = useMemo(() => {
      const startOfYearDayjs = dayjs(year, 'YYYY').startOf('year').add(startOfYearOffset, 'month')
      return times(12, (i) => startOfYearDayjs.add(i, 'months').format('YYYY-MM'))
    }, [year, startOfYearOffset])

    const onFormSubmit = useCallback(
      async (values: MilkQuotaFormData) => {
        await onSubmit(milkQuotaSubmitData(year, monthKeys, values))
      },
      [year, monthKeys, onSubmit]
    )

    const onFormApply = useCallback(
      async (values: MilkQuotaFormData) => {
        await onApply(milkQuotaSubmitData(year, monthKeys, values))
      },
      [year, monthKeys, onApply]
    )

    const formValues = useMemo(
      () => MilkQuotaFormZod.parse({ quota, quotaPrice: quotaPrice?.value }),
      [quota, quotaPrice]
    )
    return (
      <SheetFormProvider<typeof MilkQuotaFormZod>
        {...props}
        className="w-[991px] bg-white"
        title={intl.formatMessage({ id: 'milk.quota.form.title' }, { year })}
        values={formValues}
        schema={MilkQuotaFormZod}
        ref={ref}
        onFormSubmit={onFormSubmit}
        onFormApply={onFormApply}>
        {children}
      </SheetFormProvider>
    )
  }
)
