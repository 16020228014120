import { forwardRef, useCallback } from 'react'

import {
  computeInventoryValues,
  InventoryComputationSourceTypeZod,
  mapNonNullValues,
  tupleToRecord,
} from '@via/compute'
import { type InventoryComputationType } from '@via/schema'
import { BigNumber } from 'bignumber.js'

import { FormSubmitButtons } from '../../atoms/Form/FormSubmitButtons'
import { FormComputationProvider } from '../../atoms/FormComputation/FormComputationProvider.tsx'
import { useFormatNumber } from '../../hooks/useFormatNumber.ts'
import { cn } from '../../lib/utils'

import { type InventoryProjectionFormData } from './InventoryProjectionFormZod.ts'
import { InventoryProjectionWidgetBody } from './InventoryProjectionWidgetBody.tsx'
import {
  InventoryProjectionWidgetFormProvider,
  type InventoryProjectionWidgetFormProviderProps,
} from './InventoryProjectionWidgetFormProvider.tsx'
import { useInventoryProjectionData } from './useInventoryProjectionData.ts'

export type InventoryProjectionWidgetProps = Omit<InventoryProjectionWidgetFormProviderProps, 'values'>

export const InventoryProjectionWidget = forwardRef<HTMLFormElement, InventoryProjectionWidgetProps>(
  ({ className, dataKey, year, ...props }, ref) => {
    const rowValues = useInventoryProjectionData({ year, dataKey })

    const { formatNumber } = useFormatNumber()
    const computeCurrentValues = useCallback(
      (formState: Partial<InventoryProjectionFormData>) =>
        mapNonNullValues(
          computeInventoryValues(
            tupleToRecord(InventoryComputationSourceTypeZod.exclude(['unitBalance']).options, (type) => {
              switch (type) {
                case 'startingUnitBalance':
                case 'startingUnitValue':
                  return rowValues[type] ? new BigNumber(rowValues[type]) : undefined
                default:
                  return formState?.[type] ? new BigNumber(formState?.[type]) : undefined
              }
            })
          ),
          (value, key) => {
            switch (key) {
              case 'unitValue':
                return formatNumber(value.toNumber(), 'currency-per-head-count')
              default:
                return value.toString()
            }
          }
        ),
      []
    )

    return (
      <InventoryProjectionWidgetFormProvider
        className={cn(
          'flex w-[18rem] flex-col items-stretch gap-2.5 border-2 border-white bg-orange-200 p-2.5 shadow-md',
          className
        )}
        dataKey={dataKey}
        year={year}
        values={rowValues}
        ref={ref}
        {...props}>
        <FormComputationProvider<Record<InventoryComputationType, string>, InventoryProjectionFormData>
          initialState={computeCurrentValues(rowValues)}
          compute={computeCurrentValues}>
          <InventoryProjectionWidgetBody />
        </FormComputationProvider>
        <FormSubmitButtons className="flex w-full items-start justify-center gap-2" />
      </InventoryProjectionWidgetFormProvider>
    )
  }
)
