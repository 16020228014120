import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { type z } from 'zod'

import { Icons } from '../../atoms'
import { Button } from '../../atoms/Button/Button'
import { FormSubmitProvider } from '../../atoms/Form/FormSubmitProvider'
import { cn } from '../../lib/utils'
import {
  ScenarioConfigurationForm,
  type ScenarioConfigurationFormProps,
} from '../ScenarioConfigurationForm/ScenarioConfigurationForm'
import { TitleBar } from '../TitleBar/TitleBar'

import { type ScenarioCreateData } from './ScenarioCreateData'
import { type ScenarioCreationFormZod } from './ScenarioCreationFormZod.ts'

export type ScenarioCreationFormProps = Pick<
  ScenarioConfigurationFormProps,
  'canCreateCompleteScenario' | 'currentProductions' | 'offline'
> &
  React.FormHTMLAttributes<HTMLFormElement> & {
    onConfirm(form: ScenarioCreateData): Promise<void>
    onCancel(): Promise<void>
  }

export const ScenarioCreationForm = React.forwardRef<HTMLFormElement, ScenarioCreationFormProps>(
  ({ className, canCreateCompleteScenario, currentProductions, offline, onConfirm, onCancel, ...props }, ref) => {
    const intl = useIntl()
    const submit = useCallback(
      ({ projectionYear, type, ...formValues }: z.output<typeof ScenarioCreationFormZod>) =>
        onConfirm({
          ...formValues,
          blueprintVersion: type === 'complete' ? 'complete-2024' : 'simple-2024',
          period: {
            type: 'annual',
            numberOfYears: projectionYear,
          },
        }),
      [onConfirm]
    )

    return (
      <FormSubmitProvider
        onFormSubmit={submit}
        className={cn('flex flex-col items-start overflow-hidden bg-white', className)}
        ref={ref}
        {...props}>
        <div className="flex-around flex w-full flex-col pl-8 pt-6">
          <TitleBar label={intl.formatMessage({ id: 'scenarioConfiguration.form.title' })}>
            <div className="flex gap-2 pr-12">
              <Button type="submit">
                <Icons.Stars />
                {intl.formatMessage({ id: 'scenarioConfiguration.form.action.confirm' })}
              </Button>
            </div>
          </TitleBar>

          <ScenarioConfigurationForm
            formAction="create"
            currentProductions={currentProductions}
            offline={offline}
            canCreateCompleteScenario={canCreateCompleteScenario}
          />
        </div>
      </FormSubmitProvider>
    )
  }
)
