import { type FieldPathByValue, type FieldValues } from 'react-hook-form'

import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'

import { CheckboxWithLabel, type CheckboxWithLabelProps } from './CheckboxWithLabel'

export type CheckBoxWithLabelFormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPathByValue<TFieldValues, boolean> = FieldPathByValue<TFieldValues, boolean>,
> = CheckboxWithLabelProps & {
  readonly name: TName
}

export const CheckBoxWithLabelFormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPathByValue<TFieldValues, boolean> = FieldPathByValue<TFieldValues, boolean>,
>({
  name,
  className,
  disabled,
  onCheckedChange,
  ...props
}: CheckBoxWithLabelFormFieldProps<TFieldValues, TName>) => (
  <FormField<TFieldValues, TName>
    name={name}
    disabled={disabled}
    render={({ field: { onChange: formFieldOnChange, ...field } }) => (
      <FormItem className={className}>
        <FormControl>
          <CheckboxWithLabel
            {...props}
            onCheckedChange={(state) => {
              formFieldOnChange(state)
              onCheckedChange?.(state)
            }}
            checked={field.value}
            data-key={name}
            disabled={disabled}
            {...field}
          />
        </FormControl>
      </FormItem>
    )}
  />
)
