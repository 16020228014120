import { forwardRef, useMemo } from 'react'

import { type Unit } from '@via/schema'
import { type Except } from 'type-fest'

import { Input, type InputProps } from '../../atoms/Input/Input'
import { UnitLabel } from '../../atoms/UnitLabel/UnitLabel.tsx'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { cn } from '../../lib/utils.ts'

export interface NumberWithUnitInputProps extends Except<InputProps, 'type' | 'align' | 'value'> {
  readonly unit: Unit
  readonly value?: string | number
}

export const NumberWithUnitInput = forwardRef<HTMLInputElement, NumberWithUnitInputProps>(
  ({ id, value, unit, variant, dataStatus, dimension, readOnly, className, ...props }, ref) => {
    const { formatNumber } = useFormatNumber()
    const formated = useMemo(() => {
      switch (true) {
        case value === undefined || value === null:
          return ''
        case typeof value === 'number' && Number.isFinite(value):
          return formatNumber(value, unit)
        case typeof value === 'string':
          return formatNumber(Number(value), unit)
        default:
          return ''
      }
    }, [value, formatNumber, unit])

    return (
      <div className="relative">
        <Input
          id={id}
          ref={ref}
          type={readOnly ? 'text' : 'number'}
          value={readOnly ? formated : value}
          variant={variant}
          dataStatus={dataStatus}
          dimension={dimension}
          readOnly={readOnly}
          align="right"
          className={cn('block w-full placeholder:pr-0.5', dimension === 'xs' ? 'pr-[3rem]' : 'pr-[3.5rem]', className)}
          {...props}
        />
        <div className="absolute inset-y-0 right-[calc(0.5rem-4px)] select-none">
          <UnitLabel unit={unit} dimension={dimension} value={value} />
        </div>
      </div>
    )
  }
)
